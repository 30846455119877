import "../../css/ProfilePage/ProfilePage.css";
import { memo, useCallback, useMemo, useRef, useState } from "react";
import { AccountData } from "@markit/common.types";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Colors } from "../../utils/colors";
import { TextField } from "@mui/material";
import { Icon } from "@iconify/react";
import {
  MEDIA_UPLOAD_CODES,
  errorAlertText,
  errorSubAlertText,
  uploadMedia,
} from "../../utils/photoUtils";
import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import {
  INSTAGRAM_DEFAULT,
  LINKEDIN_DEFAULT,
  SPOTIFY_DEFAULT,
  TIKTOK_DEFAULT,
  X_DEFAULT,
  hasSubscriptionUnlockedAdvancedData,
} from "@markit/common.utils";
import { useProfileUpdates } from "../../hooks/useProfileUpdates";
import { isDesktop } from "react-device-detect";
import SwitchToggleItem from "../SwitchToggleItem";
import ProfilePic from "../ProfilePic";
import AlertContainer from "../Containers/AlertContainer";

type ProfilePageEditProfileProps = {
  setIsEditing: (isEditing: boolean) => void;
  otherUserData?: AccountData;
};

const ProfilePageEditProfile = memo(function ProfilePageEditProfileFn({
  setIsEditing,
  otherUserData,
}: ProfilePageEditProfileProps) {
  const { account } = useSelector(getAccountState);
  const { accountData } = account;

  const styles = {
    subContainer: {
      backgroundColor: Colors.GRAY11,
      padding: 14,
      borderRadius: 12,
    },
  };

  const userData = useMemo(
    () => (otherUserData ? otherUserData : accountData),
    [accountData, otherUserData]
  );

  const [editedUserData, setEditedUserData] = useState(userData); // used to show updated profile picture when editng
  const [fullName, setFullName] = useState<string>(userData.fullName);
  const [bio, setBio] = useState<string>(userData.bio);
  const [profilePic, setProfilePic] = useState<string>(userData.profilePicURL);
  const [instagram, setInstagram] = useState<string>(userData.instagram);
  const [linkedin, setLinkedin] = useState<string>(userData.linkedin);
  const [twitter, setTwitter] = useState<string>(userData.twitter);
  const [tiktok, setTiktok] = useState<string>(userData.tiktok);
  const [spotify, setSpotify] = useState<string>(userData.spotify);
  const [hideProfileEvents, setHideProfileEvents] = useState<boolean>(
    userData.hideProfileEvents
  );

  // States for image picker
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [alertText, setAlertText] = useState("");
  const [alertSubText, setAlertSubText] = useState("");

  const {
    updateProfilePic,
    updateFullName,
    updateBio,
    updateInstagram,
    updateLinkedin,
    updateTwitter,
    updateTiktok,
    updateSpotify,
    updateHideProfileEvents,
  } = useProfileUpdates({
    profilePic,
    fullName,
    bio,
    instagram,
    linkedin,
    twitter,
    tiktok,
    spotify,
    hideProfileEvents,
  });

  const saveEditsOnPress = useCallback(() => {
    updateProfilePic();
    updateFullName();
    updateBio();
    updateInstagram();
    updateLinkedin();
    updateTwitter();
    updateTiktok();
    updateSpotify();
    updateHideProfileEvents();
    setIsEditing(false);
  }, [
    setIsEditing,
    updateBio,
    updateFullName,
    updateHideProfileEvents,
    updateInstagram,
    updateLinkedin,
    updateProfilePic,
    updateSpotify,
    updateTiktok,
    updateTwitter,
  ]);

  const handleProfilePicChange = useCallback(
    (url: string) => {
      if (setProfilePic) {
        setProfilePic(url);
        setEditedUserData({ ...editedUserData, ...{ profilePicURL: url } });
      }
    },
    [editedUserData]
  );

  const handleNameChange = useCallback(
    (e: any) => {
      if (setFullName) {
        setFullName(e.target.value);
      }
    },
    [setFullName]
  );

  const handleBioChange = useCallback(
    (e: any) => {
      if (setBio) {
        setBio(e.target.value);
      }
    },
    [setBio]
  );

  const handleInstagramChange = useCallback(
    (e: any) => {
      setInstagram(e.target.value);
      if (setInstagram) {
        setInstagram(e.target.value);
      }
    },
    [setInstagram]
  );

  const handleLinkedinChange = useCallback(
    (e: any) => {
      setLinkedin(e.target.value);
      if (setLinkedin) {
        setLinkedin(e.target.value);
      }
    },
    [setLinkedin]
  );

  const handleTwitterChange = useCallback(
    (e: any) => {
      setTwitter(e.target.value);
      if (setTwitter) {
        setTwitter(e.target.value);
      }
    },
    [setTwitter]
  );

  const handleTiktokChange = useCallback(
    (e: any) => {
      setTiktok(e.target.value);
      if (setTiktok) {
        setTiktok(e.target.value);
      }
    },
    [setTiktok]
  );

  const handleSpotifyChange = useCallback(
    (e: any) => {
      setSpotify(e.target.value);
      if (setSpotify) {
        setSpotify(e.target.value);
      }
    },
    [setSpotify]
  );

  const uploadImage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      return new Promise<void>(async (resolve, reject) => {
        const uploadData = await uploadMedia(event, true, true);
        if (uploadData.uploadCode !== MEDIA_UPLOAD_CODES.SUCCESS) {
          setAlertText(errorAlertText(uploadData.uploadCode, true));
          setAlertSubText(errorSubAlertText(uploadData.uploadCode));
          reject();
        } else {
          setProfilePic(uploadData.blobUrl);
          setEditedUserData({
            ...editedUserData,
            ...{ profilePicURL: uploadData.blobUrl },
          });
          handleProfilePicChange(uploadData.blobUrl);
          resolve();
        }
      });
    },
    [editedUserData, handleProfilePicChange]
  );

  const renderSocialMediaEdit = useCallback(
    (
      link: string,
      placeholder: string,
      iconName: string,
      onChange: (e: any) => void
    ) => (
      <div style={{ position: "relative", width: "100%" }}>
        <Icon
          icon={iconName}
          height={22}
          className="ProfileBrowserEditSocialMediaIcon"
          style={{
            opacity: link === "" ? 0.3 : 1.0,
          }}
        />
        <input
          className="ProfileBrowserEditSocialMedia"
          type="text"
          defaultValue={link}
          placeholder={placeholder}
          onChange={(e) => onChange(e)}
        />
      </div>
    ),
    []
  );

  return (
    <div
      className="AlignedColumn"
      style={{ alignSelf: "center", width: isDesktop ? 362 : "100%", gap: 10 }}
    >
      <AlertContainer
        headerComp={
          <div className="ColumnNormal" style={{ gap: 4 }}>
            <Icon icon="mdi-image-remove" height={40} />
            <span style={{ fontSize: 16, fontWeight: "500" }}>{alertText}</span>
          </div>
        }
        subHeaderComp={alertSubText}
        closeModal={() => {
          setAlertText("");
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        }}
        hideModal={alertText === ""}
      />
      <div style={{ position: "relative" }}>
        <label>
          <>
            <input
              type="file"
              accept="image/*"
              onChange={uploadImage}
              style={{ color: "transparent" }}
            />
            <>
              <ProfilePic user={editedUserData} size={90} />
              <div
                style={{
                  position: "absolute",
                  left: 65,
                  bottom: 0,
                  paddingInline: 5,
                  paddingTop: 3,
                  paddingBottom: 2,
                  borderRadius: 5,
                  backgroundColor: Colors.GRAY6,
                  cursor: "pointer",
                }}
              >
                <Icon icon={"feather:edit"} height={16} />
              </div>
            </>
          </>
        </label>
      </div>

      <input
        className="ProfileBrowserEditFullName"
        type="text"
        defaultValue={userData.fullName}
        onChange={(e) => handleNameChange(e)}
        style={{ width: "100%", textAlign: "center" }}
      />
      <div className="AlignedRow" style={{ gap: 3, justifyContent: "center" }}>
        <span style={{ fontSize: 14, color: Colors.GRAY1 }}>
          @{userData.username}
        </span>
        {hasSubscriptionUnlockedAdvancedData(userData) ? (
          <VerifiedIcon
            style={{ color: Colors.BLUE5, marginLeft: 4 }}
            fontSize={"small"}
          />
        ) : null}
      </div>
      <div className="ProfileBrowserEditSocialMediaContainer">
        {renderSocialMediaEdit(
          instagram,
          INSTAGRAM_DEFAULT,
          "mdi:instagram",
          handleInstagramChange
        )}
        <hr className="ProfileBrowserEditSocialMediaDivider" />
        {renderSocialMediaEdit(
          linkedin,
          LINKEDIN_DEFAULT,
          "ri:linkedin-fill",
          handleLinkedinChange
        )}
        <hr className="ProfileBrowserEditSocialMediaDivider" />
        {renderSocialMediaEdit(
          twitter,
          X_DEFAULT,
          "ri:twitter-x-fill",
          handleTwitterChange
        )}
        <hr className="ProfileBrowserEditSocialMediaDivider" />
        {renderSocialMediaEdit(
          tiktok,
          TIKTOK_DEFAULT,
          "ic:baseline-tiktok",
          handleTiktokChange
        )}
        <hr className="ProfileBrowserEditSocialMediaDivider" />
        {renderSocialMediaEdit(
          spotify,
          SPOTIFY_DEFAULT,
          "mdi:spotify",
          handleSpotifyChange
        )}
      </div>
      <TextField
        className="ProfileBrowserEditBio"
        defaultValue={userData.bio}
        onChange={(e) => handleBioChange(e)}
        placeholder={"Edit bio..."}
        multiline={true}
        InputProps={{ disableUnderline: true }}
        inputProps={{
          style: {
            fontSize: 14,
            fontWeight: "600",
            textAlign: "center",
            paddingInline: 10,
          },
        }}
        variant="standard"
      />
      <div style={styles.subContainer}>
        <SwitchToggleItem
          title={"Hide Events Section"}
          description={"Hide the events section of your profile"}
          toggleValue={hideProfileEvents}
          onChange={() => setHideProfileEvents(!hideProfileEvents)}
        />
      </div>
      <div
        className="AlignedRow"
        style={{ paddingTop: 10, gap: 10, justifyContent: "center" }}
      >
        <div
          className="ButtonProfile"
          style={{
            color: Colors.BLACK,
            backgroundColor: Colors.GRAY11,
            cursor: "pointer",
          }}
          onClick={() => setIsEditing(false)}
        >
          <span>Cancel</span>
        </div>
        <div
          className="ButtonProfile"
          style={{
            color: "#E8F8FF",
            backgroundColor: Colors.BLUE5,
            cursor: "pointer",
          }}
          onClick={saveEditsOnPress}
        >
          <span>Save</span>
        </div>
      </div>
    </div>
  );
});

export default ProfilePageEditProfile;
