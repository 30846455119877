import {
  EmailAuthProvider,
  firebaseAuth,
  PhoneAuthProvider,
  reauthenticateWithCredential,
  updateEmail,
  updatePhoneNumber,
  linkWithCredential,
  sendPasswordResetEmail,
} from "../firebase";

// must reauthenticate user login to change email if user has not recently logged in
export const ReauthenticateEmail = async (currentPassword: string) => {
  const currentUser: any = firebaseAuth.currentUser;
  const cred = EmailAuthProvider.credential(currentUser.email, currentPassword);
  await reauthenticateWithCredential(currentUser, cred);
};

export const ReauthenticatePhone = async (
  verificationId: string,
  verificationCode: string
) => {
  const credential = PhoneAuthProvider.credential(
    verificationId,
    verificationCode
  );
  const user = firebaseAuth.currentUser;

  if (user) {
    if (user.phoneNumber) {
      return updatePhoneNumber(user, credential);
    } else {
      return linkWithCredential(user, credential);
    }
  }

  return Promise.reject();
};

export const ChangeAuthEmail = (email: string) => {
  const user = firebaseAuth.currentUser;
  if (user) {
    return updateEmail(user, email);
  }
  return undefined;
};

// called if user presses forgot password button
export const OnForgotPasswordPress = (
  email: string,
  setEmailError?: (emailError: any) => void
) => {
  const emailAddress = email;

  sendPasswordResetEmail(firebaseAuth, emailAddress)
    .then(function () {
      alert(`Password reset instructions sent to: ${email}`);
      if (setEmailError) {
        setEmailError({ status: false, message: "" });
      }
    })
    .catch(function (error: any) {
      alert(
        `Email (${email}) is incorrect or some unforeseen error occured: ${error.message}`
      );
      if (setEmailError) {
        setEmailError({
          status: true,
          message: `Email (${email}) is incorrect or some unforeseen error occured: ${error.message}`,
        });
      }
    });
};
