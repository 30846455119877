import { AccountData } from "@markit/common.types";
import { Colors } from "../../../utils/colors";
import ProfileSubItemsDisplay from "../ProfileSubItemsDisplay";

type EventOrganizerSubItemProps = {
  organizers: AccountData[];
};

const EventOrganizerSubItem = (props: EventOrganizerSubItemProps) => {
  const { organizers } = props;
  return (
    <div className="AlignedRowSelect">
      <ProfileSubItemsDisplay
        users={organizers.slice(0, 3)}
        size={17}
        rightComp={
          <span
            className="OneLineText"
            style={{
              fontSize: 12,
              color: Colors.GRAY1,
              position: "relative",
              left: 14,
            }}
          >
            By {organizers.map((organizer) => organizer.fullName).join(", ")}
          </span>
        }
      />
    </div>
  );
};

export default EventOrganizerSubItem;
