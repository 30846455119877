import { useCallback, useState } from "react";
import { isDesktop } from "react-device-detect";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { AccountData, Event } from "@markit/common.types";
import { isExternalGenericLink, truncateString } from "@markit/common.utils";
import { MARKITAI } from "@markit/common.utils";
import { useNavigate } from "../../hooks/useNavigate";
import { GetLongDate } from "../../utils/GetLongDate";
import { GetTime } from "../../utils/GetTime";
import { Colors } from "../../utils/colors";
import { ThemeStyle } from "../../hooks/useTheme";
import { DataLoaders } from "../../redux/slices/dataSlice";
import EventItemPreviewType from "../DisplayItem/EventItem/EventItemPreviewType";
import EventOrganizerSubItem from "../DisplayItem/EventItem/EventOrganizerSubItem";

type FeaturedEventCardProps = {
  event: Event;
  eventOrganizers?: AccountData[];
  addToSearchHistory?: (eventId: string) => void;
  altNavigationPath?: string;
  myEvent?: boolean;
};

export const FeaturedEventCard = (props: FeaturedEventCardProps) => {
  const dispatch = useDispatch();
  const { event: e, eventOrganizers } = props;
  const [onHover, setOnHover] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);

  const handleImageLoad = useCallback(() => {
    setLoading(false);
  }, []);

  const styles = {
    description: {
      fontSize: 12,
      color: Colors.GRAY1,
    },
  };
  return (
    <Link
      style={{
        textDecoration: "none",
        padding: 14,
        cursor: "pointer",
        backgroundColor: onHover ? Colors.GRAY6 : "transparent",
        borderRadius: 20,
      }}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
      onClick={() => {
        dispatch(DataLoaders.event(e.id));
      }}
      key={e.id}
      to={`${MARKITAI}/e/${e.id}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div style={{ width: "100%" }}>
        {loading && (
          <div
            className={isDesktop ? "EventCardImageDesktop" : "CardImageMobile"}
            style={{ backgroundColor: Colors.GRAY6 }}
          />
        )}
        <img
          className={isDesktop ? "EventCardImageDesktop" : "CardImageMobile"}
          src={e.photoURL}
          alt={"event fullImage"}
          onLoad={handleImageLoad}
          style={{ display: loading ? "none" : "block" }}
        />
      </div>
      <div
        className="CardEventTitle"
        style={{
          fontSize: "18px",
          color: "#000",
          marginTop: "7px",
          cursor: "pointer",
        }}
      >
        {truncateString(e.title, 20, "...")}
      </div>
      <div style={{ marginBottom: isDesktop ? 10 : 0 }}>
        <div className="CardDateTextContainer">
          <span
            className="CardDateTextProfile"
            style={{
              ...styles.description,
              fontSize: isDesktop ? "14px" : "12px",
            }}
          >
            {GetLongDate(e.start, true, true).slice(0, -6)}, {GetTime(e.start)}{" "}
          </span>
        </div>
      </div>
      {eventOrganizers ? (
        <EventOrganizerSubItem organizers={eventOrganizers} />
      ) : null}
    </Link>
  );
};

export const EventContentMobile = (props: {
  event: Event;
  altSize?: string;
  altFontSize?: number;
  eventTypeColor?: string;
  theme?: ThemeStyle;
  myEvent?: boolean;
}) => {
  const {
    event: e,
    altSize,
    altFontSize,
    eventTypeColor,
    theme,
    myEvent,
  } = props;

  return (
    <div className="AlignedRowSelect">
      {e.photoURL ? (
        <img
          alt="RenderedEvent"
          src={e.photoURL}
          width={altSize ?? "84px"}
          height={altSize ?? "84px"}
          style={{ borderRadius: 8, marginRight: "14px", cursor: "pointer" }}
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: Colors.GRAY4,
            width: altSize ?? "84px",
            height: altSize ?? "84px",
            borderRadius: "8px",
            marginRight: "14px",
            cursor: "pointer",
          }}
        >
          <Icon icon="ion:image" height={35} style={{ color: Colors.GRAY1 }} />
        </div>
      )}
      <div className="ColumnNormalSelect" style={{ gap: "4px" }}>
        <span
          className="OneLineText"
          style={{
            fontSize: altFontSize ?? 14,
            fontWeight: 500,
            ...theme?.PrimaryText,
          }}
        >
          {e.title !== "" ? e.title : "Untitled"}
        </span>
        <span
          className="OneLineText"
          style={{
            fontSize: altFontSize ?? 12,
            color: Colors.GRAY1,
            width: isExternalGenericLink(e.eventType) ? 200 : "auto",
          }}
        >
          {isExternalGenericLink(e.eventType)
            ? e.externalLink
            : e.start
            ? `${GetLongDate(e.start, true, true).slice(0, -6)}, ${GetTime(
                e.start
              )}`
            : "Date Unselected"}
        </span>

        {myEvent ? (
          <EventItemPreviewType
            eventItem={e}
            altFontSize={altFontSize}
            altBackgroundColor={eventTypeColor}
          />
        ) : (
          <span
            className="OneLineText"
            style={{ fontSize: altFontSize ?? 12, color: Colors.GRAY1 }}
          >
            {e.isVirtual
              ? "Virtual Event"
              : e.formattedAddress
              ? e.formattedAddress
              : "Location Unselected"}
          </span>
        )}
      </div>
    </div>
  );
};

function timeout(delay: number) {
  return new Promise((res) => setTimeout(res, delay));
}

export const FeaturedEventCardMobile = (props: FeaturedEventCardProps) => {
  const { event: e, addToSearchHistory, altNavigationPath, myEvent } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div
      className="AlignedRowSpaced"
      onClick={async () => {
        if (addToSearchHistory) {
          addToSearchHistory(e.id);
          // Artificial delay to save clicked event to search history before nav
          await timeout(1000);
        }
        dispatch(DataLoaders.event(e.id));
        navigate(altNavigationPath || `/e/${e.id}`, {
          state: { eventId: e.id },
        });
      }}
    >
      <EventContentMobile event={e} myEvent={myEvent} />
      <Icon
        icon={"mdi:chevron-right"}
        height={24}
        color={Colors.GRAY1}
        style={{ marginRight: "14px", cursor: "pointer" }}
      />
    </div>
  );
};

type FeaturedEventCardMobileSearchProps = {
  event: Event;
  deleteOnClick: () => void;
};

export const FeaturedEventCardMobileSearch = (
  props: FeaturedEventCardMobileSearchProps
) => {
  const { event: e, deleteOnClick } = props;

  return (
    <div className="AlignedRowSpaced">
      <Link
        reloadDocument
        style={{ textDecoration: "none", color: "black", cursor: "pointer" }}
        to={`/e/${e.id}`}
        key={e.id}
      >
        <EventContentMobile event={e} />
      </Link>
      <Icon
        icon={"ion:close-outline"}
        height={24}
        color={Colors.GRAY1}
        style={{ marginRight: "14px", cursor: "pointer" }}
        onClick={deleteOnClick}
      />
    </div>
  );
};

type RenderedEventsMassTextsModalProps = {
  event: Event;
  disabled?: boolean;
  hideType?: boolean;
};

export const RenderedEventMassTextsModal = (
  props: RenderedEventsMassTextsModalProps
) => {
  const { event: e, disabled, hideType } = props;

  const styles = {
    truncatedText: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      width: 360,
    },
    description: {
      fontSize: 12,
      color: Colors.GRAY1,
    },
  };

  return (
    <div
      className="AlignedRowSpacedSelect"
      style={{ pointerEvents: disabled ? "none" : "all" }}
    >
      <div className="AlignedRow">
        {e.photoURL ? (
          <img
            alt="RenderedEvent"
            src={e.photoURL}
            width={48}
            height={48}
            style={{ borderRadius: 8, marginRight: "14px", cursor: "pointer" }}
          />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: Colors.GRAY4,
              width: 48,
              height: 48,
              borderRadius: "8px",
              marginRight: "14px",
              cursor: "pointer",
            }}
          >
            <Icon
              icon="ion:image"
              height={35}
              style={{ color: Colors.GRAY1 }}
            />
          </div>
        )}
        <div className="ColumnNormalSelect" style={{ gap: "4px" }}>
          <p style={{ ...styles.truncatedText, fontSize: 12, fontWeight: 500 }}>
            {e.title ?? "Untitled"}
          </p>
          <span style={{ fontSize: 12, color: Colors.GRAY1 }}>
            {e.start
              ? `${GetLongDate(e.start, true, true).slice(0, -6)}, ${GetTime(
                  e.start
                )}`
              : "Date Unselected"}
          </span>
          {!hideType ? <EventItemPreviewType eventItem={e} /> : null}
        </div>
      </div>
      {!disabled ? (
        <Icon
          icon={"ion:chevron-right"}
          height={16}
          color={Colors.GRAY1}
          style={{ cursor: "pointer" }}
        />
      ) : null}
    </div>
  );
};
