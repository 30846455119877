import { AccountData, Conversation, Follower } from "@markit/common.types";
import {
  getUserConversationsRef,
  getUserData,
  getUserFollowerData,
} from "./FirebaseUtils";
import { getDocs, limit, query, where } from "../firebase";

export const fetchSingleUser = async (
  userId: string,
  followingAccountData: AccountData[],
  callbackFn?: (userData: AccountData) => void,
  failFn?: () => void
) => {
  let userData = followingAccountData.find(
    (userData) => userData.uid === userId
  );
  if (!userData) {
    userData = await getUserData(userId);
  }

  if (callbackFn && userData) callbackFn(userData);
  if (failFn && userData === undefined) failFn();
  return userData;
};

export const fetchSingleFollower = async (
  creatorId: string,
  userId: string,
  followersData: Follower[],
  callbackFn?: (followerData: Follower) => void,
  failFn?: () => void
) => {
  let followerData = followersData.find(
    (followerData) => followerData.uid === userId
  );
  if (!followerData) {
    followerData = await getUserFollowerData(creatorId, userId);
  }

  if (callbackFn && followerData) callbackFn(followerData);
  if (failFn && followerData === undefined) failFn();
  return followerData;
};

export const fetchSingleConversation = async (
  userId: string,
  conversationUserId: string,
  userConversations: Conversation[],
  callbackFn?: (conversation: Conversation) => void
) => {
  let foundConversation = userConversations.find(
    (convo) => convo.participantUids[0] === conversationUserId
  );
  if (!foundConversation) {
    const conversationsRef = getUserConversationsRef(userId);
    const query_ = query(
      conversationsRef,
      where("participantUids", "array-contains", conversationUserId),
      limit(1)
    );
    const snapshot = await getDocs(query_);
    foundConversation = snapshot.docs.map((doc) => doc.data())[0];
  }
  if (callbackFn && foundConversation) callbackFn(foundConversation);
  return foundConversation;
};
