import "../../css/Login.css";
import { memo, useCallback, useContext, useMemo, useState } from "react";
import TopHeader from "../../components/TopHeader";
import { useTheme } from "../../hooks/useTheme";
import { Colors } from "../../utils/colors";
import { isDesktop } from "react-device-detect";
import {
  LoginState,
  accountActions,
  finishInitialStartup,
  getAccountState,
  toggleInCreatorMode,
} from "../../redux/slices/accountSlice";
import { useDispatch, useSelector } from "react-redux";
import { MixpanelContext } from "../../context/AnalyticsService";
import Footer from "../../components/Footer";
import { API } from "../../API";
import { Icon } from "@iconify/react";
import { useLocation } from "react-router-dom";
import RectangleButton from "../../components/Buttons/RectangleButton";
import { DataLoaders } from "../../redux/slices/dataSlice";
import { getEventState } from "../../redux/slices/eventSlice";
import ExpandableBox from "../../components/Containers/ExpandableBox";
import { useNavigate } from "../../hooks/useNavigate";
import LoginOnboardingProfile from "./LoginOnboardingProfile";
import { usePartialAccountUpdates } from "../../hooks/usePartialAccountUpdates";
import LoginOnboardingAccountType from "../../components/Login/LoginOnboardingAccountType";
import { BackButton } from "../../components/Buttons/BackButton";
import { useOnMount } from "../../utils/useOnMount";

export enum OnboardingStage {
  ONBOARD = "Onboarding",
  CREATE_OR_EXPLORE = "Create or Explore",
  PROFILE_SETUP = "Profile Setup",
  FINISH_SETUP = "Finish Setup",
}

const LoginOnboarding = memo(function LoginOnboardingFn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mixpanel = useContext(MixpanelContext);
  const { theme } = useTheme();
  const { account } = useSelector(getAccountState);
  const { events } = useSelector(getEventState);
  const { events: allEvents } = events;
  const { accountData, currentRoleTicket, loggedIn } = account;
  const { acceleratedSetup } = useLocation().state || {}; // acceleratedSetup = start on profile setup

  // some cases we want to start user off without asking first questions
  const [onboardingStage, setOnboardingStage] = useState<OnboardingStage>(
    acceleratedSetup ? OnboardingStage.PROFILE_SETUP : OnboardingStage.ONBOARD
  );
  const [loading, setLoading] = useState(false);

  const [image, setImage] = useState("");
  const { accountSettings, updateAccountSettings } = usePartialAccountUpdates();

  const hostEvents = useMemo(() => {
    return allEvents.filter((e) => e.createdBy === accountData.uid);
  }, [accountData.uid, allEvents]);

  const styles = {
    itemHeader: {
      fontSize: 16,
      fontWeight: "500",
    },
    itemDescription: {
      color: Colors.GRAY2,
      marginTop: 5,
      marginRight: 14,
      fontSize: 14,
    },
    labelView: { marginBottom: 7 },
    labelText: { fontSize: 12, fontWeight: "600", color: Colors.BLACK },
    labelTagText: { fontSize: 12, fontWeight: "600", color: Colors.GRAY2 },
    labelTagTextError: {
      fontSize: 12,
      fontWeight: "600",
      color: theme.ErrorBorderColor.borderColor,
    },
  };

  useOnMount(() => {
    if (loggedIn !== LoginState.LOGGED_IN) {
      navigate("/welcome");
      return;
    }
    if (accountSettings.email !== "") {
      setOnboardingStage(OnboardingStage.PROFILE_SETUP);
    }
    if (hostEvents.length > 0 || currentRoleTicket !== undefined) {
      createOnPress();
    }
  });

  const createOnPress = useCallback(() => {
    if (!accountData.inCreatorMode) {
      updateAccountSettings({ inCreatorMode: true });
      dispatch(toggleInCreatorMode(accountData.uid, false));
    }
    setOnboardingStage(OnboardingStage.PROFILE_SETUP);
    mixpanel.track("Sign Up: Chose Host Events");
  }, [
    accountData.inCreatorMode,
    accountData.uid,
    dispatch,
    mixpanel,
    updateAccountSettings,
  ]);

  const exploreOnPress = useCallback(() => {
    setLoading(true);
    mixpanel.track("Sign Up: Did Not Choose Host Events");
    // function sets creator mode to opposite of second parameter (since it's switching it)
    updateAccountSettings({ inCreatorMode: false });
    dispatch(toggleInCreatorMode(accountData.uid, true));
    setOnboardingStage(OnboardingStage.PROFILE_SETUP);
    setLoading(false);
  }, [accountData.uid, dispatch, mixpanel, updateAccountSettings]);

  const logSignUp = useCallback(async () => {
    setLoading(true);
    mixpanel.track("Finished Initial User Sign Up", {
      distinct_id: accountData.uid,
      fullName: accountSettings.fullName,
      username: accountSettings.username.toLowerCase(),
      email: accountSettings.email,
      phoneNumber: accountSettings.phoneNumber,
      inCreatorMode: accountSettings.inCreatorMode,
      instagram: accountSettings.instagram,
      linkedin: accountSettings.linkedin,
      twitter: accountSettings.twitter,
      tiktok: accountSettings.tiktok,
      spotify: accountSettings.spotify,
    });
    dispatch(finishInitialStartup(accountData.uid));

    // trigger Slack bot notification if creator is signing up
    if (accountData.inCreatorMode) {
      await API.monitor
        .creatorSignUp({
          uid: accountData.uid,
        })
        .catch((e: any) => {
          console.error("Unable to trigger Slack bot for creator sign up");
        });
    }
    setLoading(false);
  }, [
    accountData.inCreatorMode,
    accountData.uid,
    accountSettings.email,
    accountSettings.fullName,
    accountSettings.inCreatorMode,
    accountSettings.instagram,
    accountSettings.linkedin,
    accountSettings.phoneNumber,
    accountSettings.spotify,
    accountSettings.tiktok,
    accountSettings.twitter,
    accountSettings.username,
    dispatch,
    mixpanel,
  ]);

  // Redirect the user to the confirmation page to accept their event role
  const redirectAcceptRole = useCallback(async () => {
    // for role redeem, don't show last screen
    if (currentRoleTicket) {
      await logSignUp();
      // Take user to confirmation page to accept role after sign up
      window.location.replace(
        `https://markitai.com/e/${currentRoleTicket.eventId}/i/${accountData.uid}?ticketId=${currentRoleTicket.id}`
      );
      setTimeout(
        () => dispatch(accountActions.setCurrentRoleTicket(undefined)),
        500
      );
    }
  }, [accountData.uid, currentRoleTicket, dispatch, logSignUp]);

  const finalContinueOnPress = useCallback(async () => {
    await logSignUp();
    if (hostEvents.length > 0) {
      // might not be needed because we are storing it into creator events not the data slice events
      dispatch(DataLoaders.event(hostEvents[0].id));
      navigate(`/e/${hostEvents[0].id}/dashboard`, {
        state: { eventId: hostEvents[0].id },
      });
    } else {
      navigate("/home");
    }
  }, [dispatch, hostEvents, logSignUp, navigate]);

  const finishProfileSetUp = useCallback(async () => {
    if (accountSettings.inCreatorMode) {
      setOnboardingStage(OnboardingStage.FINISH_SETUP);
      window.scrollTo(0, 0);
    } else {
      await logSignUp();
      dispatch(DataLoaders.user(accountSettings.uid));
      navigate(`/u/${accountSettings.username}`, {
        state: { username: accountSettings.username },
      });
    }
  }, [
    accountSettings.inCreatorMode,
    accountSettings.uid,
    accountSettings.username,
    dispatch,
    logSignUp,
    navigate,
  ]);

  const renderItemRow = useCallback(
    (
      title: string,
      description: string,
      icon: JSX.Element,
      showLine: boolean,
      showCheck?: boolean
    ) => (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          marginBottom: 4,
        }}
      >
        <div style={{ width: 30, alignItems: "center" }}>
          {icon}
          {showLine ? (
            <div
              style={{
                height: 50,
                width: 1,
                marginLeft: 14,
                backgroundColor: Colors.GRAY2,
              }}
            />
          ) : null}
        </div>
        <div style={{ paddingLeft: 14, marginTop: -5 }}>
          <div className="AlignedRow">
            <span style={styles.itemHeader}>{title}</span>
            {showCheck ? (
              <Icon
                icon="ion:checkmark"
                height={18}
                color={Colors.GREEN2}
                style={{ marginLeft: 7 }}
              />
            ) : null}
          </div>
          <span style={styles.itemDescription}>{description}</span>
        </div>
      </div>
    ),
    [styles.itemDescription, styles.itemHeader]
  );

  return (
    <div
      className="LoginContainer"
      style={{
        height:
          onboardingStage === OnboardingStage.PROFILE_SETUP
            ? "160vh"
            : isDesktop
            ? "120vh"
            : "100vh",
      }}
    >
      <TopHeader hideLogin />
      <div
        className="LoginBody"
        style={{
          justifyContent: "center",
          width: isDesktop ? "362px" : "85%",
          marginTop: isDesktop ? 70 : 30,
          paddingBlock:
            onboardingStage === OnboardingStage.ONBOARD ? 14 : undefined,
          paddingTop: 20,
          paddingBottom: 14,
        }}
      >
        <ExpandableBox>
          {onboardingStage === OnboardingStage.PROFILE_SETUP ? (
            <LoginOnboardingProfile
              accountSettings={accountSettings}
              updateAccountSettings={updateAccountSettings}
              image={image}
              setImage={setImage}
              loading={loading}
              onFinishSetUp={finishProfileSetUp}
            />
          ) : onboardingStage === OnboardingStage.FINISH_SETUP ? (
            <div className="ColumnNormal">
              <div style={{ marginBottom: 14 }}>
                <span style={{ fontSize: 35 }}>🥳</span>
              </div>
              <span className="LoginHeaderText" style={{ color: Colors.BLACK }}>
                You're all set
              </span>
              <div style={{ paddingTop: 7, paddingBottom: 24 }}>
                <span className="LoginHeaderTextDescription">
                  {`Welcome to Markit, ${accountData.fullName}!`}
                </span>
              </div>
              <div style={{ paddingInline: 10, paddingBottom: 24 }}>
                {renderItemRow(
                  "Account set up",
                  "You just did this! Edit your profile anytime.",
                  <Icon
                    icon="ion:person-circle-sharp"
                    height={30}
                    color={Colors.GRAY2}
                  />,
                  true,
                  true
                )}
                {currentRoleTicket
                  ? renderItemRow(
                      "Accept your event role",
                      "After this, you can manage the event.",
                      <Icon
                        icon="ion:person-add"
                        height={30}
                        color={
                          hostEvents.length > 0 ? Colors.GRAY2 : Colors.BLACK
                        }
                      />,
                      false
                    )
                  : renderItemRow(
                      "Grow your audience",
                      "Share your profile, create events & links to grow your textable audience.",
                      <Icon
                        icon="ion:trending-up"
                        height={30}
                        color={Colors.BLACK}
                      />,
                      false
                    )}
              </div>
              <RectangleButton
                buttonLabel={
                  <span>
                    {currentRoleTicket
                      ? "Go to Accept Role"
                      : hostEvents.length > 0
                      ? "View First Event"
                      : "Continue"}
                  </span>
                }
                onPress={
                  currentRoleTicket ? redirectAcceptRole : finalContinueOnPress
                }
                altColor={Colors.BLACK}
                altTextColor={Colors.WHITE}
                altPaddingVert={12}
                disabled={loading}
                loading={loading}
              />
            </div>
          ) : onboardingStage === OnboardingStage.CREATE_OR_EXPLORE ? (
            <div className="ColumnNormal" style={{ gap: 24 }}>
              <BackButton
                onPress={() => setOnboardingStage(OnboardingStage.ONBOARD)}
              />
              <div className="ColumnNormal">
                <span
                  className="LoginHeaderText"
                  style={{ color: Colors.BLACK }}
                >
                  Sign up to create or explore?
                </span>
                <div style={{ paddingTop: 7 }}>
                  <span className="LoginHeaderTextDescription">
                    Sign up with &apos;create&apos; if you want to text your
                    audience or sign up with &apos;explore&apos; to find
                    profiles and events.
                  </span>
                </div>
              </div>
              <div className="ColumnNormal" style={{ gap: 10 }}>
                <RectangleButton
                  buttonLabel={<span>Create</span>}
                  iconRight={
                    <Icon
                      icon="ion:trending-up"
                      height={17}
                      style={{ color: Colors.WHITE }}
                    />
                  }
                  onPress={createOnPress}
                  theme={theme}
                  altPaddingVert={12}
                  disabled={loading}
                  loading={loading}
                />
                <RectangleButton
                  buttonLabel={<span>Explore</span>}
                  iconRight={
                    <Icon
                      icon="ion:compass"
                      height={17}
                      style={{ color: Colors.BLACK }}
                    />
                  }
                  onPress={exploreOnPress}
                  altColor={Colors.GRAY6}
                  altTextColor={Colors.BLACK1}
                  altPaddingVert={12}
                  disabled={loading}
                  loading={loading}
                />
              </div>
            </div>
          ) : (
            <LoginOnboardingAccountType
              individualOnPress={() =>
                setOnboardingStage(OnboardingStage.CREATE_OR_EXPLORE)
              }
            />
          )}
        </ExpandableBox>
      </div>
      {isDesktop ? (
        <div style={{ marginTop: "auto" }}>
          <Footer forceLight />
        </div>
      ) : null}
    </div>
  );
});

export default LoginOnboarding;
