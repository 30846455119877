import "../../css/App.css";
import "../../css/FullEvent/FullEvent.css";
import "../../css/FullEvent/FullEventDesktop.css";
import "../../css/DefaultLandingPage.css";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import {
  Event,
  AccountData,
  DiscussionPost,
  PromoCode,
  TicketV2,
  CustomTicketV2,
  SavedFormQuestion,
} from "@markit/common.types";
import BuyBanner from "../../components/FullEventSubComponents/BuyBanner";
import Footer from "../../components/Footer";
import { useOnMount } from "../../utils/useOnMount";
import {
  getVisibleCustomTickets,
  isEventTicketsPaid,
} from "@markit/common.utils";
import mixpanel from "mixpanel-browser";
import FullEventInfo from "../../components/FullEvent/FullEventInfo";
import FullEventHeader from "../../components/FullEvent//FullEventHeader";
import TopHeader from "../../components/TopHeader";
import StickyBox from "react-sticky-box";
import CheckoutContainer from "../../components/FullEventSubComponents/CheckoutContainer";
import { Colors } from "../../utils/colors";
import { Icon } from "@iconify/react";
import { LazyMotion, domAnimation, m } from "framer-motion";
import { useTheme } from "../../hooks/useTheme";
import OrganizersInfoDisplay from "../../components/FullEventSubComponents/OrganizersInfoDisplay";

type FullEventDesktopProps = {
  event: Event;
  cohostsList: AccountData[];
  host: AccountData | undefined;
  posts: DiscussionPost[];
  address: string;
  tixId: string;
  ticketData: TicketV2 | undefined;
  ticketToRedeem: CustomTicketV2 | undefined;
  promoterId: string;
  promoCode?: PromoCode;
  isEventAtCapacity: boolean;
  setSubmitted: (submitted: boolean) => void;
  userReferrer: string;
  submitted: boolean;
  selectedCustomTicket: CustomTicketV2;
  setSelectedCustomTicket: (selectedTicket: CustomTicketV2 | undefined) => void;
  eventFormQuestions: SavedFormQuestion[];
};

const FullEventDesktop = memo(function FullEventDesktopFn(
  props: FullEventDesktopProps
) {
  const {
    event,
    cohostsList,
    host,
    posts,
    address,
    tixId,
    ticketData,
    promoterId, // promoterId
    promoCode, // promo
    isEventAtCapacity,
    ticketToRedeem,
    userReferrer,
    selectedCustomTicket,
    setSelectedCustomTicket,
    submitted,
    setSubmitted,
    eventFormQuestions,
  } = props;
  const [showCheckoutForm, setShowCheckoutForm] = useState(false);
  const [showExpandedPhoto, setShowExpandedPhoto] = useState(false);

  const hostAndCohosts: AccountData[] =
    cohostsList && host ? [host, ...cohostsList] : host ? [host] : [];

  const refContainer = useRef<HTMLDivElement>(null);

  const { theme } = useTheme();

  useOnMount(() => {
    mixpanel.track("Webapp: Viewed Event On Browser", {
      event_id: event.id,
      event_type: isEventTicketsPaid(event) ? "paid" : "free",
      host_id: event.createdBy,
    });
  });

  const visibleTickets = useMemo(
    () => getVisibleCustomTickets(event.customTickets, ticketToRedeem?.id),
    [event.customTickets, ticketToRedeem?.id]
  );

  // prevent background scrolling when the checkout modal is open
  useEffect(() => {
    if (showCheckoutForm) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showCheckoutForm]);

  const lowestTicketPrice = useMemo(() => {
    let lowestTicket: number = visibleTickets[0].price;
    if (visibleTickets.length === 1) {
      return lowestTicket / 100;
    }
    // eslint-disable-next-line array-callback-return
    visibleTickets.map((customTicket) => {
      if (customTicket.price < lowestTicket) {
        lowestTicket = customTicket.price;
      }
    });
    return lowestTicket / 100;
  }, [visibleTickets]);

  const highestTicketPrice = useMemo(() => {
    let highestTicket: number = visibleTickets[0].price;
    if (visibleTickets.length === 1) {
      return highestTicket / 100;
    }
    // eslint-disable-next-line array-callback-return
    visibleTickets.map((customTicket) => {
      if (customTicket.price > highestTicket) {
        highestTicket = customTicket.price;
      }
    });
    return highestTicket / 100;
  }, [visibleTickets]);

  return (
    <div>
      {showExpandedPhoto && (
        <div className="ExpandedPhotoDesktop" style={theme.TransparentBG}>
          <div className={"ExpandedPhotoHeader"}>
            <p className={"CheckoutTitleDesktop"} style={theme.PrimaryText}>
              Expanded Photo
            </p>
            <Icon
              icon="gridicons:cross-circle"
              height={30}
              color={Colors.GRAY1}
              onClick={() => setShowExpandedPhoto(false)}
              style={{ cursor: "pointer" }}
            />
          </div>
          <LazyMotion features={domAnimation}>
            <m.div
              initial={{ y: "20px", opacity: 0, filter: "blur(2px)" }}
              animate={{ y: "0px", opacity: 1, filter: "blur(0px)" }}
              exit={{ y: "20px", opacity: 0, filter: "blur(2px)" }}
              transition={{ duration: 0.3, ease: "easeOut" }}
              className="HideScrollbar"
            >
              <img
                className="ExpandedPhoto"
                src={event.photoURL}
                alt={"event cover"}
              />
            </m.div>
          </LazyMotion>
        </div>
      )}
      {showCheckoutForm && host ? (
        <CheckoutContainer
          event={event}
          host={host}
          tixId={tixId}
          ticketData={ticketData}
          ticketToRedeem={ticketToRedeem}
          promoterId={promoterId}
          promoCode={promoCode}
          userReferrer={userReferrer}
          submitted={submitted}
          setSubmitted={setSubmitted}
          selectedCustomTicket={selectedCustomTicket ?? visibleTickets[0]}
          setSelectedCustomTicket={setSelectedCustomTicket}
          eventFormQuestions={eventFormQuestions}
          isEventAtCapacity={isEventAtCapacity}
          setShowCheckoutForm={setShowCheckoutForm}
        />
      ) : null}
      <div ref={refContainer} className="WebApp" style={theme.PrimaryBG}>
        <div className="WebContainer">
          <TopHeader showRCA />
          <div className="WebColumnContainer">
            <StickyBox offsetTop={20} offsetBottom={20}>
              <div className="WebImageContainer">
                <img
                  className="WebImage"
                  src={event.photoURL}
                  alt={"event cover"}
                  style={{ marginBottom: "14px", cursor: "pointer" }}
                  onClick={() => setShowExpandedPhoto(true)}
                />
                <BuyBanner
                  event={event}
                  tixId={tixId}
                  ticketData={ticketData}
                  promoCode={promoCode}
                  lowestTicketPrice={lowestTicketPrice}
                  highestTicketPrice={highestTicketPrice}
                  isEventAtCapacity={isEventAtCapacity}
                  setShowCheckoutForm={setShowCheckoutForm}
                  visibleTickets={visibleTickets}
                />
                <div
                  className="PageDesktop"
                  style={{ ...theme.SecondaryBG, marginTop: "14px" }}
                >
                  <div style={{ paddingInline: 14, paddingTop: 14 }}>
                    <h3
                      className="AboutSubtitle"
                      style={{ ...theme.PrimaryText, paddingBottom: 10 }}
                    >
                      Contact Host
                    </h3>
                    <hr style={{ ...theme.DividerColor, margin: 0 }} />
                  </div>
                  {host ? (
                    <OrganizersInfoDisplay
                      host={host}
                      organizers={hostAndCohosts}
                    />
                  ) : null}
                </div>
              </div>
            </StickyBox>
            <div className="WebInfo">
              <FullEventHeader
                event={event}
                host={host}
                cohostsList={cohostsList ?? []}
                confirmationScreen={false}
              />
              <FullEventInfo
                event={event}
                host={host}
                posts={posts}
                address={address}
                visibleTickets={visibleTickets}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer showRCA host={host} />
    </div>
  );
});

export default FullEventDesktop;
