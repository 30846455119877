import { useEffect, useState } from "react";
import { getUserAudienceListMembersRef } from "../../utils/FirebaseUtils";
import { onSnapshot } from "../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";

type useLiveUpdatingListAnalyticsProps = {
  listId: string;
};

export const useLiveUpdatingListAnalytics = (
  props: useLiveUpdatingListAnalyticsProps
) => {
  const { listId } = props;
  const dispatch = useDispatch();
  const { accountData } = useSelector(getAccountState).account;
  const [liveTotalListContacts, setLiveTotalListContacts] = useState<number>(0);
  const [liveListContactUids, setLiveListContactUids] = useState<string[]>([]);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      if (!loaded && listId) {
        // total list contacts
        const audienceListMembersRef = getUserAudienceListMembersRef(
          accountData.uid,
          listId
        );
        onSnapshot(audienceListMembersRef, (snapshot) => {
          const documentCount = snapshot.size;
          setLiveTotalListContacts(documentCount);
          if (snapshot.docs.length === 0) {
            setLiveListContactUids([]);
          } else {
            setLiveListContactUids(snapshot.docs.map((doc) => doc.data().uid));
          }
          setLoaded(true);
        });
      }
    })();
  }, [accountData.uid, dispatch, listId, loaded]);

  return {
    liveTotalListContacts,
    liveListContactUids,
    loaded,
  };
};
