import React, { memo } from "react";
import StandardBorderedContainer from "../Containers/StandardBorderedContainer";
import { useTheme } from "../../hooks/useTheme";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";
import { Colors } from "../../utils/colors";

type EventDashboardLinkAnalyticsBoxProps = {
  totalLinkViews: number;
  totalSubmissions?: number;
};

// Used as a showcase box for link analytics (and submissions) for external event links
const EventDashboardLinkAnalyticsBox = memo(
  function EventDashboardLinkAnalyticsBoxFn(
    props: EventDashboardLinkAnalyticsBoxProps
  ) {
    const { totalLinkViews, totalSubmissions } = props;
    const { theme } = useTheme();

    const styles = {
      bigNumbers: { fontSize: 32 },
      analyticsTitle: { fontSize: 12, color: Colors.GRAY1 },
      headerTitle: {
        fontSize: 16,
        fontWeight: 600,
      },
      overallContainerView: {
        width: "100%",
        backgroundColor: theme.SecondaryBG.backgroundColor,
        borderRadius: 20,
        paddingBlock: 14,
      },
      containerView: {
        paddingInline: 14,
      },
    };

    return (
      <div className="ColumnNormal" style={{ gap: 14 }}>
        <span style={styles.headerTitle}>Analytics</span>
        <StandardBorderedContainer
          containerStyles={styles.overallContainerView}
        >
          <div className="ColumnNormal">
            <div style={{ ...styles.headerTitle, ...styles.containerView }}>
              Link Analytics
            </div>
            <HorizontalDivider altMargin={14} />
            <div className="AlignedRow" style={styles.containerView}>
              <div className="ColumnNormal" style={{ marginRight: 24 }}>
                <span style={styles.bigNumbers}>{totalLinkViews}</span>
                <span style={styles.analyticsTitle}>Total Link Views</span>
              </div>
              {totalSubmissions !== undefined ? (
                <div className="ColumnNormal">
                  <span style={styles.bigNumbers}>{totalSubmissions}</span>
                  <span style={styles.analyticsTitle}>Total Submissions</span>
                </div>
              ) : null}
            </div>
          </div>
        </StandardBorderedContainer>
      </div>
    );
  }
);

export default EventDashboardLinkAnalyticsBox;
