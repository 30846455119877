import { memo } from "react";
import { Colors } from "../../utils/colors";
import { useTheme } from "../../hooks/useTheme";
import { VerticalDivider } from "../Dividers/VerticalDivider";

/* flexSections and sectionTitles must be same length */
type GridTableHeaderProps = {
  flexSections: number[];
  sectionTitles: string[];
};

const GridTableHeader = memo(function GridTableHeaderFn(
  props: GridTableHeaderProps
) {
  const { flexSections, sectionTitles } = props;

  const { theme } = useTheme();

  const styles = {
    ticketHeaders: {
      backgroundColor: Colors.WHITE1,
    },

    ticketHeaderText: {
      color: theme.SubText.color,
      fontSize: 14,
      fontWeight: 500,
    },
  };

  if (flexSections.length !== sectionTitles.length) {
    return null;
  }

  return (
    <div className="AlignedRow" style={styles.ticketHeaders}>
      {sectionTitles.map((title, index) => (
        <div className="AlignedRow" style={{ flex: flexSections[index] }}>
          {index !== 0 ? <VerticalDivider height={38} /> : null}
          <div
            style={{
              ...styles.ticketHeaderText,
              marginLeft: 14,
            }}
          >
            {title}
          </div>
        </div>
      ))}
    </div>
  );
});

export default GridTableHeader;
