import {
  Event,
  FormResponseV2,
  AccountData,
  SavedFormQuestion,
} from "@markit/common.types";
import FlatList from "flatlist-react/lib";
import { Colors } from "../../utils/colors";
import { Icon } from "@iconify/react";
import { useTheme } from "../../hooks/useTheme";
import { CircularProgress, TextField } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import {
  getEventFormQuestions,
  getEventFormResponses,
  questionInputName,
} from "../../utils/eventUtils/formUtils";
import { FormResponseContainer } from "./FormResponseContainer";
import { useLoadUserList } from "../../hooks/useLoadUserList";
import { filter } from "lodash";
import { DotWave } from "@uiball/loaders";
import PopupModalContainer from "../Containers/PopupModalContainer";
import { DataLoaders } from "../../redux/slices/dataSlice";
import StandardBorderedContainer from "../Containers/StandardBorderedContainer";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";
import EmptyStateButton from "../Buttons/EmptyStateButton";
import { isEventExternalLink } from "@markit/common.utils";
import { useNavigate } from "../../hooks/useNavigate";

type FormsPanelProps = {
  event: Event;
  dashboardVisibility: number;
};

export const FormsPanel = (props: FormsPanelProps) => {
  const { event, dashboardVisibility } = props;
  const { theme } = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { account } = useSelector(getAccountState);
  const { accountData } = account;

  const [formQuestions, setFormQuestions] = useState<SavedFormQuestion[]>([]);
  const [responses, setResponses] = useState<FormResponseV2[]>([]);
  const [loadingResponses, setLoadingResponses] = useState<boolean>(true);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(true);

  const [searchTerm, setSearchTerm] = useState("");

  const [currFormQuestion, setCurrFormQuestion] = useState<SavedFormQuestion>();
  const [currUser, setCurrUser] = useState<FormResponseV2>();
  const [currUserName, setCurrUserName] = useState<string>("");

  const [isUserResponseModalOpen, setIsUserResponseModalOpen] =
    useState<boolean>(false);

  const styles = {
    bigNumberText: {
      fontSize: 24,
    },

    containerView: {
      paddingInline: 14,
    },

    formResponsesInfo: {
      gap: 24,
      color: Colors.BLACK,
    },

    headerTitle: {
      fontSize: 16,
      fontWeight: 600,
      marginBottom: 4,
    },

    listView: {
      height: 560,
      marginTop: 18,
      paddingBottom: 50,
    },

    noFormQuestionsView: {
      paddingBlock: 12,
      paddingInline: 14,
      borderRadius: 12,
      backgroundColor: Colors.GRAY6,
      fontSize: 12,
    },

    overallContainerView: {
      width: "100%",
      backgroundColor: theme.SecondaryBG.backgroundColor,
      borderRadius: 20,
    },

    subText: {
      color: theme.SubText.color,
      fontSize: 12,
    },
  };

  useEffect(() => {
    (async () => {
      const [formQuestions, responses] = await Promise.all([
        getEventFormQuestions(event.createdBy, event.formQuestions),
        getEventFormResponses(event.id),
      ]);
      setFormQuestions(formQuestions);
      setResponses(responses);
      setCurrFormQuestion(formQuestions[0]);
      setLoadingResponses(false);
    })();
  }, [event]);

  const hideCreationAndCopying = useMemo(
    () => !accountData.isAdmin && dashboardVisibility < 2,
    [accountData.isAdmin, dashboardVisibility]
  );

  const userIdList = useMemo(
    () => responses.map((response) => response.uid),
    [responses]
  );

  const {
    isFinished,
    isLoading,
    fetchedUserData,
    loadMoreUsers,
    loadSearchResultsThrottled,
  } = useLoadUserList({
    userIdList: !loadingResponses ? userIdList : [],
    windowSize: 40,
  });

  const contains = useCallback((user: AccountData, query: string) => {
    return user.fullName.toLowerCase().includes(query);
  }, []);

  const handleSearch = useCallback(
    (text: string) => {
      setSearchTerm(text.toLowerCase());
      loadSearchResultsThrottled(text.toLowerCase());
    },
    [loadSearchResultsThrottled]
  );

  const responsesToShow: AccountData[] = useMemo(() => {
    let userList: AccountData[] = [];
    userList = fetchedUserData;
    if (searchTerm !== "") {
      userList = filter(userList, (user: AccountData) => {
        return contains(user, searchTerm);
      });
    }
    return userList;
  }, [fetchedUserData, searchTerm, contains]);

  useEffect(() => {
    if (!isLoading && fetchedUserData.length === 0 && !isFinished) {
      loadMoreUsers();
      setLoadingUsers(false);
    }
  }, [fetchedUserData, isFinished, isLoading, loadMoreUsers, loadingUsers]);

  const navigateEmptyFormQuestions = useCallback(() => {
    dispatch(DataLoaders.event(event.id));
    if (isEventExternalLink(event.eventType)) {
      navigate(`/e/${event.id}/editLink`, {
        state: { confirmState: true, event: event, enableCustomData: true },
      });
    } else {
      navigate(`/e/${event.id}/edit`, {
        state: { editEvent: event },
      });
    }
  }, [dispatch, event, navigate]);

  if (loadingResponses) {
    return (
      <div className="FormResponsesLoading">
        <DotWave size={47} speed={1} color={Colors.BLACK} />
      </div>
    );
  }

  return (
    <div className="RowNormal" style={{ gap: 30 }}>
      <div className="ColumnNormal" style={{ width: 355, minWidth: 355 }}>
        <StandardBorderedContainer
          containerStyles={{
            ...styles.overallContainerView,
            paddingBottom: 14,
          }}
        >
          <div className="ColumnNormal">
            <div style={{ ...styles.containerView, paddingTop: 14 }}>
              <div style={styles.headerTitle}>Manage Forms & Data</div>
              <div style={styles.subText}>
                Collect and manage data/responses.
              </div>
            </div>
            <HorizontalDivider altMargin={14} />
            {formQuestions.length > 0 ? (
              <>
                <div style={styles.containerView}>
                  <div className="RowNormal" style={styles.formResponsesInfo}>
                    <div>
                      <div style={styles.bigNumberText}>
                        {formQuestions.length}
                      </div>
                      <div style={styles.subText}>
                        Active Question{formQuestions.length > 1 && "s"}
                      </div>
                    </div>
                    <div>
                      <div style={styles.bigNumberText}>{responses.length}</div>
                      <div style={styles.subText}>
                        Unique Response{responses.length > 1 && "s"}
                      </div>
                    </div>
                  </div>
                </div>
                <HorizontalDivider altMargin={14} />
              </>
            ) : null}
            <div style={styles.containerView}>
              {formQuestions.length > 0 ? (
                <div className="ColumnNormal" style={{ gap: 12 }}>
                  <FlatList
                    list={formQuestions}
                    scrollEnabled={false}
                    keyExtractor={(item: SavedFormQuestion) => item.id}
                    renderItem={(item) => {
                      return (
                        <div
                          className="ColumnNormal"
                          style={{
                            gap: 7,
                            padding: "12px",
                            borderRadius: "8px",
                            backgroundColor:
                              item.id === currFormQuestion?.id
                                ? Colors.GRAY6
                                : Colors.WHITE,
                            cursor: "pointer",
                          }}
                          onClick={() => setCurrFormQuestion(item)}
                        >
                          <span
                            style={{
                              color: Colors.BLACK,
                              fontSize: 14,
                              fontWeight: "600",
                            }}
                          >
                            {item.formInput.label}
                          </span>
                          <span style={{ fontSize: "12px" }}>
                            {questionInputName(item.formInput.inputType)}
                          </span>
                        </div>
                      );
                    }}
                  />
                </div>
              ) : (
                <div style={styles.noFormQuestionsView}>
                  No form questions to show
                </div>
              )}
            </div>
          </div>
        </StandardBorderedContainer>
      </div>
      <div className="ColumnNormal" style={{ flex: 1 }}>
        <StandardBorderedContainer
          containerStyles={{
            ...styles.overallContainerView,
            paddingBottom: 14,
          }}
        >
          <div
            className="ColumnNormal"
            style={{
              paddingBlock: 14,
              minHeight: 770,
            }}
          >
            {formQuestions.length > 0 ? (
              <>
                <FormResponseContainer
                  eventId={event.id}
                  item={currFormQuestion}
                  clicked={true}
                  onPress={() => {}}
                  containerStyles={{ marginInline: 14 }}
                />
                <HorizontalDivider altMargin={14} />
                <div style={styles.containerView}>
                  <TextField
                    placeholder="Search by name"
                    variant="standard"
                    onChange={(event) => handleSearch(event.target.value)}
                    style={{
                      backgroundColor: Colors.GRAY11,
                      borderRadius: "12px",
                      border: "none",
                      width: "100%",
                    }}
                    inputProps={{
                      style: {
                        padding: "10px",
                        paddingLeft: "7px",
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <Icon
                          icon="ion:search"
                          height={24}
                          style={{ marginLeft: "10px", color: Colors.GRAY2 }}
                        />
                      ),
                      disableUnderline: true,
                    }}
                  />
                  <div
                    className="HideScrollbar"
                    style={{
                      ...styles.listView,
                      overflowY: "scroll",
                    }}
                  >
                    <FlatList
                      list={responsesToShow}
                      keyExtractor={(item: AccountData) => item.uid}
                      renderItem={(item) => {
                        const userFound = responses.find(
                          (response) => response.uid === item.uid
                        );

                        if (
                          userFound &&
                          currFormQuestion &&
                          userFound.answers[currFormQuestion.id]
                        ) {
                          const foundAnswer =
                            userFound.answers[currFormQuestion.id];
                          const formattedAnswer =
                            foundAnswer &&
                            foundAnswer.every((response) => response === "")
                              ? "No Response Collected"
                              : foundAnswer.join(", ");
                          return (
                            <div
                              style={{
                                paddingInline: "4px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setCurrUser(userFound);
                                setCurrUserName(item.fullName);
                                setIsUserResponseModalOpen(true);
                              }}
                            >
                              <div
                                style={{
                                  ...(formattedAnswer ===
                                  "No Response Collected"
                                    ? theme.SubText
                                    : theme.PrimaryText),
                                  fontSize: 14,
                                  cursor: "pointer",
                                }}
                              >
                                {formattedAnswer}
                              </div>
                              <div
                                className="AlignedRow"
                                style={{ cursor: "pointer", marginTop: 7 }}
                              >
                                <div
                                  className="AlignedRow"
                                  style={{
                                    ...styles.subText,
                                    cursor: "pointer",
                                  }}
                                >
                                  {item.fullName}
                                </div>
                                <Icon
                                  icon={"mdi:chevron-right"}
                                  height={20}
                                  color={Colors.GRAY2}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                />
                              </div>
                              {responsesToShow[responsesToShow.length - 1] !==
                                item && <hr />}
                            </div>
                          );
                        } else {
                          return null;
                        }
                      }}
                      renderWhenEmpty={() => (
                        <div>
                          <span style={theme.SubText}>
                            {isFinished || userIdList.length === 0
                              ? "No users to show"
                              : "Loading..."}
                          </span>
                        </div>
                      )}
                      hasMoreItems={!isFinished}
                      loadMoreItems={() => {
                        loadMoreUsers();
                      }}
                      paginationLoadingIndicator={() =>
                        isLoading && !isFinished ? (
                          <CircularProgress
                            style={{
                              color: "#929292",
                              alignSelf: "center",
                              marginTop: "24px",
                            }}
                            size={30}
                          />
                        ) : null
                      }
                      paginationLoadingIndicatorPosition="center"
                    />
                  </div>
                </div>
              </>
            ) : (
              <EmptyStateButton
                title={"No Form Questions"}
                btnText={hideCreationAndCopying ? "" : "Add Questions"}
                description={
                  hideCreationAndCopying
                    ? "You can't add form questions unless you are the host"
                    : "Collect additional info from your guests like email, phone number, or social media links"
                }
                icon={
                  <Icon
                    icon={"ion:documents"}
                    height={49}
                    style={{ color: Colors.GRAY1 }}
                  />
                }
                iconBox={73}
                onPress={navigateEmptyFormQuestions}
                containerStyles={{ paddingTop: 120 }}
              />
            )}
          </div>
        </StandardBorderedContainer>
        {isUserResponseModalOpen ? (
          <PopupModalContainer
            headerComp={<div>{currUserName}'s Form Responses</div>}
            valueComp={
              <div className="ColumnNormal" style={{ gap: 14 }}>
                <FlatList
                  list={formQuestions}
                  keyExtractor={(item: SavedFormQuestion) => item.id}
                  renderItem={(item) => {
                    return (
                      <FormResponseContainer
                        eventId={event.id}
                        item={item}
                        individualUserResponse={currUser}
                        clicked={true}
                        onPress={() => {}}
                      />
                    );
                  }}
                />
              </div>
            }
            closeModal={() => setIsUserResponseModalOpen(false)}
          />
        ) : null}
      </div>
    </div>
  );
};
