import { useState } from "react";
import MassTextsPanel from "../../../components/CreatorDashboard/MassTexts/MassTextsPanel";
import TextingPanelUsage from "../../../components/CreatorDashboard/TextingPanelUsage";
import { MassText } from "@markit/common.types";
import { Colors } from "../../../utils/colors";
import MassTextsPopupPanel from "../../../components/CreatorDashboard/MassTexts/MassTextsPopupPanel";
import { TabPanel } from "../../../components/FullEventSubComponents/TabPanel";
import RectangleButtonCreatorPanel from "../../../components/Buttons/RectangleButtonCreatorPanel";
import ConfirmActionPopup from "../../../components/Containers/ConfirmPopups/ConfirmActionPopup";
import MarkitPlusActionButton from "../../../components/Subscription/MarkitPlusActionButton";
import CreatorModePanelWrapper from "../../../components/CreatorDashboard/CreatorModePanelWrapper";

enum TABS {
  MANAGE_TEXTS = 0,
  TEXTING_USAGE = 1,
}

export enum TextPopupConfirmActions {
  SENT_SUCCESS = "Text Sent",
  SENT_FAILURE = "Text Failed to Send",
  SCHEDULED_SUCCESS = "Text Scheduled",
  SCHEDULED_FAILURE = "Text Failed to Schedule",
  TEXT_DELETE = "Text Deleted",
  NONE = "",
}

const CampaignsPanel = () => {
  const [tabValue, setTabValue] = useState(TABS.MANAGE_TEXTS);
  const [massTextsPopupPanelVisible, setMassTextsPopupPanelVisible] =
    useState<boolean>(false);
  const [confirmPopupTextConfirmation, setConfirmPopupTextConfirmation] =
    useState<TextPopupConfirmActions>(TextPopupConfirmActions.NONE);
  const [editingMassText, setEditingMassText] = useState<MassText>();

  const styles = {
    textingPanelMainContentContainer: {
      backgroundColor: Colors.WHITE,
      borderRadius: 20,
    },
  };

  return (
    <CreatorModePanelWrapper
      title="Campaigns"
      headerRight={
        <div className="AlignedRow" style={{ gap: 14 }}>
          <MarkitPlusActionButton />
          <RectangleButtonCreatorPanel
            title="New Text"
            iconName="ion:create"
            onPress={() => {
              setMassTextsPopupPanelVisible(true);
            }}
          />
        </div>
      }
      headerTabs={{
        tabNames: ["Manage", "Texting Usage"],
        tabValue: tabValue,
        setTabValue: setTabValue,
      }}
    >
      <div style={{ padding: 30, paddingBottom: 100 }}>
        <div style={styles.textingPanelMainContentContainer}>
          <MassTextsPanel
            massTextPreviewOnPress={(massText: MassText) => {
              setMassTextsPopupPanelVisible(true);
              setEditingMassText(massText);
            }}
            setMassTextsPopupPanelVisible={setMassTextsPopupPanelVisible}
            tabValue={tabValue}
          />
          <TabPanel value={tabValue} index={1}>
            <TextingPanelUsage />
          </TabPanel>
        </div>
      </div>
      {/* Mass Texts Popup Panel */}
      {massTextsPopupPanelVisible ? (
        <MassTextsPopupPanel
          isVisible={massTextsPopupPanelVisible}
          setIsVisible={setMassTextsPopupPanelVisible}
          editingMassText={editingMassText}
          setEditingMassText={setEditingMassText}
          setConfirmPopupTextConfirmation={setConfirmPopupTextConfirmation}
        />
      ) : null}
      {confirmPopupTextConfirmation ? (
        <ConfirmActionPopup
          title={confirmPopupTextConfirmation}
          negativeTitle={confirmPopupTextConfirmation}
          isNegative={
            confirmPopupTextConfirmation ===
              TextPopupConfirmActions.SCHEDULED_FAILURE ||
            confirmPopupTextConfirmation ===
              TextPopupConfirmActions.SENT_FAILURE ||
            confirmPopupTextConfirmation === TextPopupConfirmActions.TEXT_DELETE
          }
          altIcon={
            confirmPopupTextConfirmation === TextPopupConfirmActions.TEXT_DELETE
              ? "ion:trash-outline"
              : undefined
          }
          onDisappear={() =>
            setConfirmPopupTextConfirmation(TextPopupConfirmActions.NONE)
          }
        />
      ) : null}
    </CreatorModePanelWrapper>
  );
};

export default CampaignsPanel;
