import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import { Colors } from "../../utils/colors";
import PopupModalContainer, {
  PopupModalContainerWidth,
} from "../Containers/PopupModalContainer";
import { Icon } from "@iconify/react";
import { EventDashboardExternalLinkCopy } from "../Links/CopyCustomLinkify";
import { formatPhoneNumber } from "../../utils/FormatPhoneNumber";
import { hasSubscription } from "@markit/common.utils";
import { MarkitPlusModal } from "./MarkitPlusModal";

type MarkitPlusNumberModalProps = {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
};

// Modal which displays the user's markit plus number if they have a subscription, or the get markit+ modal if not
const MarkitPlusNumberModal = (props: MarkitPlusNumberModalProps) => {
  const { isVisible, setIsVisible } = props;
  const { accountData } = useSelector(getAccountState).account;

  const styles = {
    subtext: {
      fontSize: 14,
      color: Colors.GRAY1,
    },
    headerRightButton: {
      paddingInline: 12,
      paddingBlock: 10,
      borderRadius: 100,
      whiteSpace: "nowrap",
    },
  };

  return isVisible ? (
    hasSubscription(accountData) ? (
      <PopupModalContainer
        headerComp={
          <div className="ColumnNormal" style={{ gap: 14 }}>
            <Icon icon="ion:call" height={40} />
            <span style={{ fontWeight: 500 }}>Share Number</span>
          </div>
        }
        subHeaderComp={
          <div style={{ marginTop: 7 }}>
            <span style={styles.subtext}>
              Share your unique Markit number. When people text your number they
              can join your audience.
            </span>
          </div>
        }
        valueComp={
          <div className="ColumnNormal">
            <EventDashboardExternalLinkCopy
              copyText={formatPhoneNumber(accountData.customer.phone)}
              containerStyles={{
                display: "flex",
                padding: 14,
                backgroundColor: Colors.GRAY6,
                borderColor: Colors.GRAY1,
                borderWidth: 0.5,
              }}
              altTextColor={Colors.BLACK}
            />
          </div>
        }
        noExit
        closeOnOutsidePress
        closeModal={() => setIsVisible(false)}
        modalContentWidth={PopupModalContainerWidth.SMALL}
      />
    ) : (
      <MarkitPlusModal closeModal={() => setIsVisible(false)} />
    )
  ) : null;
};

export default MarkitPlusNumberModal;
