import { AccountData, GradientColors } from "@markit/common.types";
import { InitialsPic } from "./InitialsPic";
import { Colors } from "../utils/colors";
import { useMemo } from "react";

type ProfilePicProps = {
  user: AccountData | undefined;
  size: number;
  onPress?: () => void;
  forcePointer?: boolean;
  borderStyle?: string;
  gradientBackground?: boolean;
};

const ProfilePic = (props: ProfilePicProps) => {
  const { user, size, onPress, borderStyle, forcePointer, gradientBackground } =
    props;

  const randomGradientColor: string[] = useMemo(
    () =>
      Object.values(GradientColors)[
        Math.floor(Math.random() * Object.values(GradientColors).length)
      ],
    []
  );

  if (user && !!user.profilePicURL) {
    return (
      <div
        onClick={onPress}
        style={{
          cursor: onPress || forcePointer ? "pointer" : "auto",
          width: size,
          height: size,
          borderRadius: size / 2,
          border: borderStyle,
        }}
      >
        <img
          src={user.profilePicURL}
          alt="ProfilePicURL"
          style={{
            borderRadius: size / 2,
            objectFit: "cover",
            height: "100%",
            width: "100%",
            overflow: "hidden",
          }}
        />
      </div>
    );
  } else {
    return (
      <div
        onClick={onPress}
        style={{
          cursor: onPress || forcePointer ? "pointer" : "auto",
          width: size,
          height: size,
          borderRadius: size / 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: Colors.GRAY13,
          background: gradientBackground
            ? `linear-gradient(to bottom, ${randomGradientColor[0]}, ${randomGradientColor[1]}`
            : Colors.GRAY13,
        }}
      >
        <InitialsPic fullName={user?.fullName} size={size / 3} />
      </div>
    );
  }
};

export default ProfilePic;
