import "../css/GlobalStyles.css";
import "../css/Footer.css";
import { APPSTORE, GOOGLEPLAY } from "@markit/common.utils";
import { Colors } from "../utils/colors";
import { AiFillAndroid, AiFillApple } from "react-icons/ai";
import { ThemeType } from "@markit/common.types";
import { LightTheme, useTheme } from "../hooks/useTheme";

type OSPillsProps = {
  eventTheme?: ThemeType;
  forceLight?: boolean;
  forceDark?: boolean;
  alternateTextSize?: number;
};

const OSPills = (props: OSPillsProps) => {
  const { eventTheme, forceLight, forceDark, alternateTextSize } = props;
  const { theme } = useTheme(eventTheme, forceLight, forceDark);

  return (
    <div className={"OSContainer"}>
      <a href={APPSTORE} target="_blank" rel="noreferrer" style={{ flex: 1 }}>
        <div className="OSPill" style={theme.TertiaryBG}>
          <AiFillApple
            color={theme === LightTheme ? Colors.BLACK : Colors.WHITE}
            size={alternateTextSize ?? 20}
          />
          <p className="OSText" style={theme.PrimaryText}>
            iOS
          </p>
        </div>
      </a>
      <a href={GOOGLEPLAY} target="_blank" rel="noreferrer" style={{ flex: 1 }}>
        <div className="OSPill" style={theme.TertiaryBG}>
          <AiFillAndroid
            color={theme === LightTheme ? Colors.BLACK : Colors.WHITE}
            size={alternateTextSize ?? 20}
          />
          <p className="OSText" style={theme.PrimaryText}>
            Android
          </p>
        </div>
      </a>
    </div>
  );
};

export default OSPills;
