import { Colors } from "../../utils/colors";
import StandardBorderedContainer from "../Containers/StandardBorderedContainer";
import ContactSupportButton from "../Buttons/ContactSupportButton";
import SwitchToggleItem from "../SwitchToggleItem";
import EventbritePhoneTemplate from "../../../src/assets/EventbritePhoneTemplate.png";

type IntegrationConfigureProps = {
  autoCollectPhone: boolean;
  setAutoCollectPhone: (autoCollectPhone: boolean) => void;
};

const IntegrationConfigure = (props: IntegrationConfigureProps) => {
  const { autoCollectPhone, setAutoCollectPhone } = props;

  const styles = {
    headerText: { fontSize: 20, fontWeight: 500 },
    container: { paddingBlock: 14, backgroundColor: Colors.WHITE1 },
  };

  return (
    <div
      className="ColumnNormal Centering"
      style={{ gap: 40, paddingBlock: 30 }}
    >
      <span style={styles.headerText}>Configure Connection</span>
      <div className="ColumnNormal" style={{ gap: 40, width: 547 }}>
        <div className="ColumnNormal" style={{ gap: 7 }}>
          <StandardBorderedContainer
            containerStyles={{ ...styles.container, paddingInline: 14 }}
          >
            <SwitchToggleItem
              title="Automatically Collect Attendee Phone Numbers"
              description="Automatically add a host question that collects my attendee's phone numbers to all my upcoming Eventbrite Events."
              toggleValue={autoCollectPhone}
              onChange={() => setAutoCollectPhone(!autoCollectPhone)}
            />
          </StandardBorderedContainer>
          {!autoCollectPhone ? (
            <span
              className="smallBodySubtext"
              style={{ color: Colors.RED3, textAlign: "center" }}
            >
              Collect attendee phone numbers for the best results from Markit
            </span>
          ) : null}
        </div>
        <div className="ColumnNormal Centering" style={{ gap: 10 }}>
          <span className="bodySubtext">
            This is what auto-added questions will look like on your Eventbrite
            checkout:
          </span>
          <img
            style={{ height: "auto", width: "90%" }}
            alt="EventbritePhoneTemplate"
            src={EventbritePhoneTemplate}
          />
        </div>
      </div>
      <ContactSupportButton />
    </div>
  );
};

export default IntegrationConfigure;
