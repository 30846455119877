import {
  parseISO,
  formatDistanceToNow,
  differenceInDays,
  format,
  getYear,
} from "date-fns";

export const TimeAgo = (timestamp: string): string => {
  if (timestamp) {
    const date = parseISO(timestamp);
    const curDate = new Date();
    const difDays = differenceInDays(curDate, date);
    if (difDays < 7) {
      const timePeriod = formatDistanceToNow(date);
      return `${timePeriod} ago`;
    } else if (getYear(curDate) !== getYear(date)) {
      return format(date, "PP");
    } else {
      return format(date, "MMM") + " " + format(date, "d");
    }
  } else {
    return "";
  }
};
