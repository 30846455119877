import React, { createContext, ReactNode, useMemo } from "react";
import { MixpanelAdapter } from "@markit/common.api";
import mixpanel from "mixpanel-browser";

export type Mixpanel = MixpanelAdapter<typeof mixpanel.people>;
export const MixpanelContext = createContext<Mixpanel>({} as Mixpanel);

interface Props {
  children: ReactNode;
}

export const MixpanelProvider = (props: Props) => {
  const { children } = props;
  const analytics = useMemo(() => {
    mixpanel.init("9bd1dd8403dd00bdf14bb3e4d3c9b96b");
    // mixpanel.init('7bde76f0214b991ed334e03eda139837');
    return new MixpanelAdapter(mixpanel);
  }, []);

  return (
    <MixpanelContext.Provider value={analytics}>
      {children}
    </MixpanelContext.Provider>
  );
};
