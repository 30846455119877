import React, { useCallback, useContext, useMemo, useState } from "react";
import { Event, OrganizerType, TicketV2 } from "@markit/common.types";
import {
  isEventExternalLink,
  isEventTicketsPaid,
  isExternalGenericLink,
} from "@markit/common.utils";
import { useDispatch, useSelector } from "react-redux";
import { Colors } from "../../utils/colors";
import { updateEventManualSoldOut } from "../../utils/dashboardUtils";
import { deleteEventData } from "../../redux/slices/eventSlice";
import { MixpanelContext } from "../../context/AnalyticsService";
import { getAccountState } from "../../redux/slices/accountSlice";
import StandardBorderedContainer from "../Containers/StandardBorderedContainer";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";
import HelpModal from "../../components/Containers/HelpModal";
import SettingsOption from "./SettingsOption";
import { exportEventData } from "../../utils/eventUtils/formUtils";
import AlertContainer from "../Containers/AlertContainer";
import ConfirmDeleteModal from "../Containers/ConfirmPopups/ConfirmDeleteModal";
import { Icon } from "@iconify/react";
import ConfirmActionPopup from "../Containers/ConfirmPopups/ConfirmActionPopup";
import { useNavigate } from "../../hooks/useNavigate";
import CloneEventModal from "./DashboardModals/CloneEventModal";

type SettingsPanelProps = {
  event: Event;
  userTicket: TicketV2 | undefined;
  totalGuests: number;
  dashboardVisibility: number;
  isHost: boolean;
};

export const SettingsPanel = (props: SettingsPanelProps) => {
  const { event, userTicket, totalGuests, dashboardVisibility, isHost } = props;
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const mixpanel = useContext(MixpanelContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [optionsVisible, setOptionsVisible] = useState(false);
  const [cloneModalVisible, setCloneModalVisible] = useState(false);
  const [isDownloading, setDownloading] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState<boolean>(false);
  const [confirmExportAlert, setConfirmExportAlert] = useState<boolean>(false);
  const [alertText, setAlertText] = useState({ heading: "", subHeading: "" });
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const styles = {
    container: { width: "100%" },

    contentHeader: {
      fontSize: 16,
      fontWeight: 600,
      marginBottom: 14,
    },

    optionsContainer: {
      backgroundColor: Colors.WHITE,
      width: "100%",
    },
  };

  const isExternalLink = useMemo(
    () => isEventExternalLink(event.eventType),
    [event.eventType]
  );

  const changeTicketSales = useCallback(() => {
    updateEventManualSoldOut(event);
  }, [event]);

  const cloneOnPress = useCallback(async () => {
    setCloneModalVisible(true);
  }, []);

  const showConfirmDialog = useCallback(async () => {
    // todo (jonathan) don't allow deletion of an event over 100 attendees for risk of batch size
    if (userTicket && userTicket.role.type === OrganizerType.HOST) {
      if (
        isEventTicketsPaid(event) &&
        totalGuests !== null &&
        totalGuests > 0 &&
        !accountData.isAdmin
      ) {
        return setAlertText({
          heading: "Cannot delete paid or custom ticketed event.",
          subHeading: "This feature is not supported at this time.",
        });
      } else if (totalGuests !== null && totalGuests > 100) {
        return setAlertText({
          heading: "Cannot delete an event with over 100 attendees.",
          subHeading: "This feature is not supported at this time.",
        });
      }
      setDeleteConfirm(true);
    }
  }, [event, userTicket, totalGuests, accountData.isAdmin]);

  const onExportFinish = useCallback(
    (success: boolean) => {
      if (success) {
        if (alertText.heading !== "Export Failed") {
          setConfirmExportAlert(true);
        }
      } else {
        setAlertText({
          heading: "Export Failed",
          subHeading: "Please try again or contact our hotline.",
        });
      }
    },
    [alertText.heading]
  );

  return (
    <>
      <HelpModal showModal={showHelpModal} setShowModal={setShowHelpModal} />
      <div
        className={isExternalLink ? "RowNormal" : "RowNormalSpaced"}
        style={{ gap: 30 }}
      >
        {!isExternalLink ? (
          <div style={styles.container}>
            <div style={styles.contentHeader}>Event Options</div>
            <StandardBorderedContainer
              containerStyles={styles.optionsContainer}
            >
              <div>
                <SettingsOption
                  title={
                    (event.isManualSoldOut ? "Resume " : "Pause ") +
                    (isEventTicketsPaid(event) ? "Ticket Sales" : "RSVPs")
                  }
                  subtext={""}
                  iconName={
                    event.isManualSoldOut
                      ? "ion:play-circle"
                      : "ion:pause-circle"
                  }
                  onPress={changeTicketSales}
                  disabled={dashboardVisibility < 2}
                />

                {isEventTicketsPaid(event) ? (
                  <>
                    <HorizontalDivider />
                    <SettingsOption
                      title={"Refund Event (available in-app)"}
                      subtext={"This will refund all ticket purchases"}
                      iconName={"ion:return-up-back"}
                      onPress={() => {}}
                      disabled={true}
                    />
                  </>
                ) : null}
              </div>
            </StandardBorderedContainer>
          </div>
        ) : null}
        <div style={styles.container}>
          <div style={styles.contentHeader}>Data</div>
          <StandardBorderedContainer containerStyles={styles.optionsContainer}>
            <div>
              <SettingsOption
                title={"Export Data"}
                subtext={"Download CSV file of all data"}
                iconName={"ion:download"}
                onPress={() =>
                  exportEventData(
                    event,
                    isDownloading,
                    setDownloading,
                    onExportFinish
                  )
                }
                disabled={dashboardVisibility < 2}
              />
              <HorizontalDivider />
              <SettingsOption
                title={"Help"}
                subtext={""}
                iconName={"ion:help"}
                onPress={() => setShowHelpModal(true)}
                disabled={false}
              />
            </div>
          </StandardBorderedContainer>
        </div>
        <div style={styles.container}>
          <div style={styles.contentHeader}>More</div>
          <StandardBorderedContainer containerStyles={styles.optionsContainer}>
            <div>
              {!isExternalLink ? (
                <>
                  <SettingsOption
                    title={"Clone Event"}
                    subtext={"Existing organizer roles won’t be copied"}
                    iconName={"ion:copy"}
                    onPress={cloneOnPress}
                    disabled={dashboardVisibility < 2}
                  />
                  <HorizontalDivider />
                </>
              ) : null}
              <SettingsOption
                title={`Delete ${
                  isEventExternalLink(event.eventType) ? "Link" : "Event"
                }`}
                subtext={
                  !isHost
                    ? "Only available for event owner"
                    : "This can’t be undone"
                }
                iconName={"ion:trash"}
                onPress={showConfirmDialog}
                disabled={!isHost}
                altColor={!isHost ? undefined : Colors.RED1}
              />
            </div>
          </StandardBorderedContainer>
        </div>
        {isExternalLink ? <div style={styles.container} /> : null}
      </div>
      {cloneModalVisible ? (
        <CloneEventModal
          event={event}
          isModalVisible={cloneModalVisible}
          setIsModalVisible={setCloneModalVisible}
        />
      ) : null}
      <AlertContainer
        headerComp={
          <div style={{ display: "flex", flexDirection: "column", gap: 7 }}>
            <Icon icon={"ion:close-circle"} color={Colors.RED3} height={30} />
            {alertText.heading}
          </div>
        }
        subHeaderComp={
          alertText.subHeading !== "" ? alertText.subHeading : undefined
        }
        closeModal={() => setAlertText({ heading: "", subHeading: "" })}
        hideModal={alertText.heading === "" && alertText.subHeading === ""}
        altButtonLabel={
          alertText.heading === "Export Failed" ? "Got it" : undefined
        }
      />
      <ConfirmDeleteModal
        heading="Are you sure you want to delete?"
        subtext="You cannot undo this."
        deleteButtonText="Delete"
        hideModal={!deleteConfirm}
        setIsVisible={setDeleteConfirm}
        deleteOnPress={async () => {
          setOptionsVisible(!optionsVisible);
          dispatch(deleteEventData(event, accountData.uid, mixpanel));
          if (accountData.inCreatorMode) {
            navigate(
              `/home/${
                isExternalGenericLink(event.eventType) ? "links" : "events"
              }`
            );
          } else {
            navigate(`/u/${accountData.username}`, {
              state: { username: accountData.username },
            });
          }
        }}
      />
      {confirmExportAlert ? (
        <ConfirmActionPopup
          title="Data Exported"
          onDisappear={() => setConfirmExportAlert(false)}
        />
      ) : null}
    </>
  );
};
