import "../../css/FullEvent/SelectCircleRow.css";
import "../../css/GlobalStyles.css";
import React, { memo } from "react";
import { isString } from "@markit/common.utils";
import { Colors } from "../../utils/colors";
import { useTheme } from "../../hooks/useTheme";

type SelectCircleRowProps = {
  onPress: () => void;
  title: string | JSX.Element;
  description: string | JSX.Element;
  priceTag: JSX.Element;
  minimumQuantity: number;
  multiTicketText: JSX.Element;
  isSelected: boolean;
  isDisabled: boolean;
  isSoldOut: boolean;
  rightIcon: JSX.Element;
};

export const SelectCircleRow = memo(function SelectCircleRowFn(
  props: SelectCircleRowProps
) {
  const {
    title,
    description,
    priceTag,
    minimumQuantity,
    multiTicketText,
    isSelected,
    isDisabled,
    isSoldOut,
    onPress,
    rightIcon,
  } = props;

  const { theme } = useTheme();

  return (
    <>
      <div
        className="SelectContainer"
        onClick={isDisabled ? undefined : onPress}
        style={{
          borderColor:
            isSelected && !isDisabled
              ? theme.FocusedTextFieldBorder.borderColor
              : theme.TextFieldBorder.borderColor,
          borderWidth: isSelected && !isDisabled ? 2 : 1,
          backgroundColor: theme.SecondaryBG.backgroundColor,
          pointerEvents: isDisabled ? "none" : "all",
        }}
      >
        <div className="SelectDetails">
          <div style={{ width: "100%" }}>
            <div
              className="AlignedRowSpaced"
              style={{ gap: "15px", opacity: isDisabled ? 0.3 : 1 }}
            >
              <div style={{ ...theme.PrimaryText }}>
                {isString(title) ? (
                  <p className="TicketText" style={{ paddingBlock: 5 }}>
                    {title}
                  </p>
                ) : (
                  title
                )}
              </div>
              {isSoldOut ? (
                <div
                  className="AlignedRow"
                  style={{
                    backgroundColor: Colors.GRAY1,
                    paddingBlock: 5,
                    paddingInline: 8,
                    borderRadius: 8,
                  }}
                >
                  <span
                    style={{
                      ...theme.PrimaryText,
                      fontSize: 14,
                      fontWeight: "600",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Sold Out
                  </span>
                </div>
              ) : (
                <div className="AlignedRow">
                  {isSelected && !isDisabled ? <div>{rightIcon}</div> : null}
                </div>
              )}
            </div>
            <hr
              style={{
                marginBlock: 10,
                borderTop: `dashed 1px ${theme.TextFieldBorder.borderColor}`,
              }}
            />
            <div className="ColumnNormal" style={{ gap: 4 }}>
              <span
                style={{ ...theme.PrimaryText, opacity: isDisabled ? 0.3 : 1 }}
              >
                {priceTag}
              </span>
              {multiTicketText}
              <span
                className="TicketDescription"
                style={{ color: Colors.GRAY1, opacity: isDisabled ? 0.3 : 1 }}
              >
                {isString(description)
                  ? description.substring(0, description.indexOf("."))
                  : null}
              </span>
              {minimumQuantity && minimumQuantity > 1 ? (
                <div
                  className="MinTicketText"
                  style={{ opacity: isDisabled ? 0.3 : 1 }}
                >
                  Min Quantity: {minimumQuantity}
                </div>
              ) : null}
              <span
                className="TicketDescription"
                style={{ color: "#929292", opacity: isDisabled ? 0.3 : 1 }}
              >
                {isString(description)
                  ? description.substring(
                      description.indexOf(".") + 1,
                      description.length
                    )
                  : null}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
