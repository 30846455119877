import React, { memo } from "react";
import { useTheme } from "../../hooks/useTheme";

type HorizontalDividerProps = {
  altMargin?: number;
};

export const HorizontalDivider = memo(function HorizontalDividerFn(
  props: HorizontalDividerProps
) {
  const { altMargin } = props;
  const { theme } = useTheme();

  return (
    <hr
      style={{
        marginBlock: altMargin ?? 0,
        borderColor: theme.DividerColor.borderColor,
        borderWidth: 1.5,
      }}
    />
  );
});
