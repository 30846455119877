import { useCallback, useContext, useState } from "react";
import { useSelector } from "react-redux";
import {
  getAccountState,
  logoutUser,
  toggleInCreatorMode,
  updateAccountData,
} from "../../../redux/slices/accountSlice";
import LargePopupModalContainer from "../../Containers/LargePopupModalContainer";
import "../../../css/CreatorDashboardMassTextsPanel.css";
import { Icon } from "@iconify/react";
import { Colors } from "../../../utils/colors";
import {
  deepCopy,
  detectedCensored,
  handleLinkPressed,
  hasSubscriptionUnlockedAdvancedData,
} from "@markit/common.utils";
import ProfilePic from "../../ProfilePic";
import { useDispatch } from "react-redux";
import { DataLoaders } from "../../../redux/slices/dataSlice";
import { MixpanelContext } from "../../../context/AnalyticsService";
import ShareProfilePanel from "./ShareProfilePanel";
import ShareProfilePanelHeader from "./ShareProfilePanelHeader";
import EditProfilePanel from "./EditProfilePanel";
import { AccountData } from "@markit/common.types";
import { isEqual } from "lodash";
import ConfirmDeleteModal from "../../Containers/ConfirmPopups/ConfirmDeleteModal";
import { firebaseAuth } from "../../../firebase";
import VerifiedIcon from "@mui/icons-material/Verified";
import {
  getUserDataByUsername,
  getUsernameSnap,
} from "../../../utils/FirebaseUtils";
import { SocialMediaType } from "../../../hooks/useProfileUpdates";
import ProfileAccountDetails from "./ProfileAccountDetails";
import { isValidUserName } from "../../../utils/stringUtils";
import { checkValidSocialMediaLink } from "@markit/common.utils/dist/displayUtils";
import AlertContainer from "../../Containers/AlertContainer";
import { useNavigate } from "../../../hooks/useNavigate";
import StandardBorderedContainer from "../../Containers/StandardBorderedContainer";

type ProfilePopupPanelProps = {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
};

const ProfilePopupPanel = (props: ProfilePopupPanelProps) => {
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const { isVisible, setIsVisible } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mixpanel = useContext(MixpanelContext);

  const [shareProfileOpen, setShareProfileOpen] = useState(false);
  const [editProfileOpen, setEditProfileOpen] = useState(false);
  const [confirmEditsModal, setConfirmEditsModal] = useState(false);
  const [alertText, setAlertText] = useState({ heading: "", subHeading: "" });

  const [accountSettings, setAccountSettings] =
    useState<AccountData>(accountData);

  const updateAccountSettings = useCallback(
    (newAccountSettings: Partial<AccountData>) => {
      setAccountSettings((accountSettings) => {
        return { ...accountSettings, ...newAccountSettings };
      });
    },
    []
  );

  const resetAccountSettings = useCallback(() => {
    setAccountSettings(accountData);
  }, [accountData]);

  const styles = {
    headerText: { fontSize: 14, fontWeight: "500" },
    subtext: { fontSize: 12, color: Colors.GRAY1 },
  };

  const closeOnPress = useCallback(() => {
    if (shareProfileOpen) {
      setShareProfileOpen(false);
    } else if (editProfileOpen) {
      if (!isEqual(accountData, accountSettings)) {
        setConfirmEditsModal(true);
        return;
      }
      resetAccountSettings();
      setEditProfileOpen(false);
    } else {
      setIsVisible(false);
    }
  }, [
    accountData,
    accountSettings,
    editProfileOpen,
    resetAccountSettings,
    setIsVisible,
    shareProfileOpen,
  ]);

  // checks all conditions for a valid profile
  const isValidProfileDetails = (accountSettings: AccountData) => {
    if (accountSettings.fullName === "") {
      return "Full name cannot be empty.";
    } else if (detectedCensored(accountSettings.fullName)) {
      return "Full name contains banned words and must be removed.";
    } else if (accountSettings.username === "") {
      return "Username cannot be empty";
    } else if (
      !checkValidSocialMediaLink("instagram", accountSettings.instagram)
    ) {
      return "Invalid instagram url";
    } else if (
      !checkValidSocialMediaLink("linkedin", accountSettings.linkedin)
    ) {
      return "Invalid linkedin url";
    } else if (!checkValidSocialMediaLink("twitter", accountSettings.twitter)) {
      return "Invalid X/twitter url";
    } else if (!checkValidSocialMediaLink("tiktok", accountSettings.tiktok)) {
      return "Invalid tiktok url";
    } else if (!checkValidSocialMediaLink("spotify", accountSettings.spotify)) {
      return "Invalid spotify url";
    }
    return "";
  };

  const saveEditProfile = useCallback(async () => {
    const usernameLowercase = accountSettings.username.toLowerCase();
    if (!isValidUserName(usernameLowercase)) {
      setAlertText({
        heading:
          "Username can only consist of alphanumeric characters, 3-30 characters in length, no spaces",
        subHeading: "",
      });
      return;
    }
    if (usernameLowercase !== accountData.username) {
      const usernameSnap = await getUsernameSnap(usernameLowercase);
      if (usernameSnap.exists()) {
        const usernameData = usernameSnap.data();
        const existingUserData = await getUserDataByUsername(
          usernameData.username
        );
        if (existingUserData && existingUserData.uid !== accountData.uid) {
          setAlertText({
            heading: "Username already exists.",
            subHeading: "Try another.",
          });
          return;
        }
      }
    }
    const isValidProfile = isValidProfileDetails(accountSettings);
    if (isValidProfile !== "") {
      setAlertText({ heading: isValidProfile, subHeading: "" });
      return;
    }
    let finalAccountSettings: AccountData = deepCopy(accountSettings);
    if (accountSettings.profileLink !== "") {
      // append web protocol if input url is not well formatted
      const url = finalAccountSettings.profileLink;
      finalAccountSettings.profileLink = /^(https|http):\/\/.*/.test(url)
        ? url
        : `https://${url}`;
    }
    finalAccountSettings.username = usernameLowercase;
    dispatch(updateAccountData(finalAccountSettings));
    setEditProfileOpen(false);
  }, [accountData.uid, accountData.username, accountSettings, dispatch]);

  const shareProfileOnPress = useCallback(() => {
    setShareProfileOpen(true);
  }, []);

  const switchModeOnPress = useCallback(() => {
    dispatch(toggleInCreatorMode(accountData.uid, accountData.inCreatorMode));
    dispatch(DataLoaders.user(accountData.uid));
    setIsVisible(false);
    navigate(`/u/${accountData.username}`, {
      state: { username: accountData.username },
    });
  }, [
    accountData.inCreatorMode,
    accountData.uid,
    accountData.username,
    dispatch,
    navigate,
    setIsVisible,
  ]);

  const logout = useCallback(() => {
    try {
      mixpanel.track("Webapp: User Logout", {
        distinct_id: accountData.uid,
      });
      dispatch(logoutUser());
      setIsVisible(false);
      navigate("/welcome");
    } catch (e: any) {
      setAlertText({
        heading: "There was an error logging out.",
        subHeading: "Please try again.",
      });
      console.log(e.message);
    }
  }, [accountData.uid, dispatch, mixpanel, navigate, setIsVisible]);

  const deleteAccount = useCallback(async () => {
    const lastSignIn = firebaseAuth.currentUser?.metadata.lastSignInTime;
    if (
      lastSignIn === undefined ||
      (new Date().getTime() - new Date(lastSignIn).getTime()) / 60 / 1000 > 5
    ) {
      setAlertText({
        heading: "You must sign out and sign in again to do this operation",
        subHeading: "",
      });
      return false;
    }
  }, []);

  const renderSocialMedia = useCallback(
    (type: SocialMediaType, link: string, iconName: string) => {
      return (
        <div
          className="ProfileBrowserSocialMediaIcon"
          onClick={() => window.open(handleLinkPressed(type, link), "_blank")}
        >
          <Icon icon={iconName} height={22} style={{ color: Colors.GRAY1 }} />
        </div>
      );
    },
    []
  );

  return (
    <>
      {shareProfileOpen ? (
        <LargePopupModalContainer
          showModal={shareProfileOpen}
          headerComp={
            <ShareProfilePanelHeader showBackIcon closeOnPress={closeOnPress} />
          }
          valueComp={<ShareProfilePanel />}
        />
      ) : (
        <LargePopupModalContainer
          showModal={isVisible}
          headerComp={
            <div className="AlignedRowSpaced">
              <div className="LargePopupPanelClose" onClick={closeOnPress}>
                <Icon
                  icon={editProfileOpen ? "ion:chevron-back" : "mdi:close"}
                  height={24}
                />
              </div>
              <div className="AlignedRow" style={{ gap: 10 }}>
                <div
                  className="LargePopupPanelBlackButton AlignedRowSelect"
                  onClick={
                    editProfileOpen ? saveEditProfile : shareProfileOnPress
                  }
                  style={{ gap: 7 }}
                >
                  {editProfileOpen ? (
                    <span
                      className="AboutSubtitle"
                      style={{ color: Colors.WHITE }}
                    >
                      Save
                    </span>
                  ) : (
                    <>
                      <span
                        className="AboutSubtitle"
                        style={{ color: Colors.WHITE }}
                      >
                        Share Profile
                      </span>
                      <Icon icon="ion:share" height={16} color={Colors.WHITE} />
                    </>
                  )}
                </div>
              </div>
            </div>
          }
          valueComp={
            editProfileOpen ? (
              <EditProfilePanel
                accountSettings={accountSettings}
                updateAccountSettings={updateAccountSettings}
              />
            ) : (
              <div
                className="HideScrollbar"
                style={{ height: "calc(100vh - 166px)", overflow: "scroll" }}
              >
                <div className="AlignedColumn" style={{ paddingBlock: 14 }}>
                  <ProfilePic user={accountData} size={90} />
                  <div className="AlignedColumn" style={{ gap: 4 }}>
                    <h4 style={{ fontSize: 22, paddingTop: 10 }}>
                      {accountData.fullName}
                    </h4>
                    <div className="ProfileBrowserSocialMedia">
                      {accountData.instagram !== ""
                        ? renderSocialMedia(
                            SocialMediaType.INSTAGRAM,
                            accountData.instagram,
                            "mdi:instagram"
                          )
                        : null}
                      {accountData.twitter !== ""
                        ? renderSocialMedia(
                            SocialMediaType.TWITTER,
                            accountData.twitter,
                            "ri:twitter-x-fill"
                          )
                        : null}
                      {accountData.linkedin !== ""
                        ? renderSocialMedia(
                            SocialMediaType.LINKEDIN,
                            accountData.linkedin,
                            "ri:linkedin-fill"
                          )
                        : null}
                      {accountData.tiktok !== ""
                        ? renderSocialMedia(
                            SocialMediaType.TIKTOK,
                            accountData.tiktok,
                            "ic:baseline-tiktok"
                          )
                        : null}
                      {accountData.spotify !== ""
                        ? renderSocialMedia(
                            SocialMediaType.SPOTIFY,
                            accountData.spotify,
                            "mdi:spotify"
                          )
                        : null}
                    </div>
                    <div className="AlignedRow" style={{ gap: 7 }}>
                      <span style={{ color: Colors.GRAY1, fontWeight: "500" }}>
                        @{accountData.username}
                      </span>
                      {hasSubscriptionUnlockedAdvancedData(accountData) ? (
                        <VerifiedIcon
                          style={{ color: Colors.BLUE5 }}
                          fontSize={"inherit"}
                        />
                      ) : null}
                    </div>
                  </div>
                  {accountData.bio !== "" ? (
                    <div
                      className="AlignedColumn"
                      style={{ paddingInline: 30, paddingTop: 10 }}
                    >
                      <span
                        style={{ ...styles.headerText, textAlign: "center" }}
                      >
                        {accountData.bio}
                      </span>
                    </div>
                  ) : null}
                  <StandardBorderedContainer
                    onPress={() => setEditProfileOpen(true)}
                    containerStyles={{
                      display: "flex",
                      marginTop: 14,
                      cursor: "pointer",
                      gap: 7,
                      padding: 14,
                      backgroundColor: Colors.GRAY6,
                      borderRadius: 14,
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      boxSizing: "border-box",
                    }}
                  >
                    <Icon icon={"feather:edit"} height={16} />
                    <span style={styles.headerText}>Edit Profile</span>
                  </StandardBorderedContainer>
                </div>
                <ProfileAccountDetails />
                <div style={{ paddingBottom: 100 }} />
              </div>
            )
          }
          fixedBottomComp={
            !editProfileOpen ? (
              <div>
                <hr style={{ margin: 0 }} />
                <div
                  className="AlignedRowSpaced"
                  style={{ paddingBlock: 10, paddingInline: 14, gap: 7 }}
                >
                  <div
                    style={{
                      border: "1px solid black",
                      padding: 10,
                      cursor: "pointer",
                    }}
                    className="RectangleButton"
                    onClick={switchModeOnPress}
                  >
                    <span style={styles.headerText}>Switch to Attending</span>
                  </div>
                  <div
                    style={{
                      backgroundColor: Colors.BLACK,
                      padding: 10,
                      cursor: "pointer",
                    }}
                    className="RectangleButton"
                    onClick={logout}
                  >
                    <span style={{ ...styles.headerText, color: Colors.WHITE }}>
                      Sign out
                    </span>
                  </div>
                </div>
              </div>
            ) : null
          }
          closeOnOutsidePress={
            alertText.heading === "" && !confirmEditsModal
              ? () => setIsVisible(false)
              : undefined
          }
        />
      )}
      <ConfirmDeleteModal
        heading="Are you sure you want to exit?"
        subtext="Your edits won't be saved. This cannot be undone."
        deleteButtonText="Exit"
        hideModal={!confirmEditsModal}
        setIsVisible={setConfirmEditsModal}
        deleteOnPress={() => {
          if (editProfileOpen) {
            resetAccountSettings();
            setEditProfileOpen(false);
          }
          setConfirmEditsModal(false);
        }}
        icon={<Icon icon="ion:exit-outline" height={40} />}
      />
      <AlertContainer
        headerComp={alertText.heading}
        subHeaderComp={
          alertText.subHeading !== "" ? alertText.subHeading : undefined
        }
        closeModal={() => setAlertText({ heading: "", subHeading: "" })}
        hideModal={alertText.heading === "" && alertText.subHeading === ""}
      />
    </>
  );
};

export default ProfilePopupPanel;
