import { AccountData, Follower, SpreadsheetStatus } from "@markit/common.types";
import { useCallback, useMemo } from "react";
import { Colors } from "../../utils/colors";
import { useTheme } from "../../hooks/useTheme";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";
import ProfileItem from "../ProfileItem";
import { formatPhoneNumber } from "../../utils/FormatPhoneNumber";
import { Icon } from "@iconify/react";
import { formatNumericDate } from "@markit/common.utils";

export const SPREADSHEET_USER_FLEX_SECTIONS = [2, 1, 1, 1];

type ProfileSpreadsheetItemProps = {
  item: AccountData;
  setProfilePreviewSelected: (
    profilePreviewSelected: AccountData | undefined
  ) => void;
  fetchedFollowerData: Follower[];
};

const ProfileSpreadsheetItem = ({
  item,
  setProfilePreviewSelected,
  fetchedFollowerData,
}: ProfileSpreadsheetItemProps) => {
  const { theme } = useTheme();

  const styles = {
    rowSubtext: {
      fontSize: 14,
      color: theme.SubText.color,
      fontWeight: 500,
    },
  };

  const followerData = useMemo(
    () => fetchedFollowerData.find((follower) => follower.uid === item.uid),
    [fetchedFollowerData, item.uid]
  );

  const viewProfilePreview = useCallback(() => {
    setProfilePreviewSelected(item);
  }, [setProfilePreviewSelected, item]);

  const uploadDate = useMemo(
    () =>
      followerData ? (
        <span style={{ fontSize: 12, color: Colors.GRAY2 }}>
          Upload Date {formatNumericDate(new Date(followerData.createdAt))}
        </span>
      ) : undefined,
    [followerData]
  );

  const status = useMemo(() => {
    const uploadStatus = followerData
      ? followerData.spreadsheetData.status
      : SpreadsheetStatus.ACCEPTED;
    return uploadStatus === SpreadsheetStatus.REJECTED
      ? "Rejected"
      : uploadStatus === SpreadsheetStatus.PENDING
      ? "Pending"
      : "Opted-In";
  }, [followerData]);

  return (
    <div>
      <div className="AlignedRow" style={{ paddingBlock: 10 }}>
        <div
          style={{ marginLeft: 14, flex: SPREADSHEET_USER_FLEX_SECTIONS[0] }}
        >
          <ProfileItem
            profileItem={item}
            onItemPress={viewProfilePreview}
            subtext={uploadDate}
          />
        </div>
        <div style={{ flex: SPREADSHEET_USER_FLEX_SECTIONS[1] }}>
          <span
            className="TextOverflowEllipsis"
            style={{ marginLeft: 14, width: "14vw", ...styles.rowSubtext }}
          >
            {followerData && followerData.spreadsheetData.uploadFullName
              ? followerData.spreadsheetData.uploadFullName
              : item.fullName}
          </span>
        </div>
        <div style={{ flex: SPREADSHEET_USER_FLEX_SECTIONS[2] }}>
          <span
            className="TextOverflowEllipsis"
            style={{ marginLeft: 26, width: "10vw", ...styles.rowSubtext }}
          >
            {formatPhoneNumber(item.phoneNumber)}
          </span>
        </div>
        <div
          className="AlignedRowSpaced"
          style={{ flex: SPREADSHEET_USER_FLEX_SECTIONS[3], marginRight: 20 }}
        >
          <div
            className="AlignedRow"
            style={{
              ...styles.rowSubtext,
              fontSize: 12,
              backgroundColor: Colors.WHITE1,
              borderRadius: 100,
              marginLeft: 28,
              paddingBlock: 7,
              paddingInline: 10,
              gap: 4,
            }}
          >
            <span>{status}</span>
            <Icon
              icon="ion:checkmark-circle"
              height={14}
              color={Colors.GREEN2}
            />
          </div>
        </div>
      </div>
      <HorizontalDivider />
    </div>
  );
};

export default ProfileSpreadsheetItem;
