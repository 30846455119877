import { m } from "framer-motion";
import { Colors } from "../../../utils/colors";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import FormBuilderWrapper from "../../Form/FormBuilderWrapper";
import FollowTextPopup from "../FollowTextPopup";
import PreviewBoxContainer from "../../Containers/PreviewBoxContainer";
import BlackHoverButton from "../../Buttons/BlackHoverButton";

export type ShareProfilePanelProps = {};

const ShareProfilePanel = (props: ShareProfilePanelProps) => {
  const { accountData } = useSelector(getAccountState).account;
  const [followTextVisible, setFollowTextVisible] = useState(false);

  const styles = {
    headerText: { fontSize: 14, fontWeight: "500" },
    subtext: { fontSize: 12, color: Colors.GRAY1 },
  };

  return (
    <m.div
      key="masstextstextsummary"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2, type: "tween" }}
      className="HideScrollbar ColumnNormal"
      style={{
        height: "calc(100vh - 120px)",
        overflow: "scroll",
        gap: 14,
      }}
    >
      <div className="ColumnNormal" style={{ gap: 7 }}>
        <span className="AboutSubtitle" style={{ fontSize: 18 }}>
          Share Profile
        </span>
        <span style={styles.subtext}>
          Customize the experience of following your profile and collect info
          upon follow.
        </span>
      </div>
      <PreviewBoxContainer
        message={accountData.contactCustomMessage}
        headerText={"Follow Text"}
        subHeaderComp={
          <span style={{ fontSize: 12, color: Colors.GRAY1 }}>
            Sent when someone follows you (Required)
          </span>
        }
        headerRightComp={
          <BlackHoverButton
            leftIconName="feather:edit"
            leftIconSize={18}
            onPress={() => setFollowTextVisible(true)}
            containerStyles={{ padding: 8 }}
          />
        }
      />
      <div
        className="PageDesktop Container"
        style={{ backgroundColor: Colors.GRAY6 }}
      >
        <FormBuilderWrapper isProfileQuestions hostId={accountData.uid} />
      </div>
      {followTextVisible ? (
        <FollowTextPopup
          isVisible={followTextVisible}
          setIsVisible={setFollowTextVisible}
        />
      ) : null}
    </m.div>
  );
};

export default ShareProfilePanel;
