import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import { useCallback, useMemo, useState } from "react";
import { hasSubscription, isBusinessSubscription } from "@markit/common.utils";
import { Colors } from "../../utils/colors";
import { MarkitPlusModal } from "./MarkitPlusModal";
import { useNavigate } from "../../hooks/useNavigate";
import { NavigationId } from "../../navigation/AppParamList";
import FatHeaderActionButton from "../Buttons/FatHeaderActionButton";

type MarkitPlusActionButtonProps = { mainAction?: boolean };

const MarkitPlusActionButton = (props: MarkitPlusActionButtonProps) => {
  const { mainAction } = props;
  const { accountData } = useSelector(getAccountState).account;
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);

  const styles = {
    title: {
      fontSize: 14,
      fontWeight: 500,
      color: hasSubscription(accountData)
        ? Colors.WHITE
        : mainAction
        ? Colors.WHITE
        : Colors.BLUE5,
    },
    headerRightButton: {
      paddingInline: 20,
      paddingBlock: 14,
      borderRadius: 100,
      whiteSpace: "nowrap",
    },
  };

  const buttonTitle = useMemo(
    () =>
      hasSubscription(accountData) ? (
        <span style={styles.title}>
          Markit<span style={{ color: Colors.BLUE5 }}>+</span>
        </span>
      ) : (
        <span style={styles.title}>Get Markit+</span>
      ),
    [accountData, styles.title]
  );

  const showUpgradeButton = useMemo(
    () => hasSubscription(accountData) && !isBusinessSubscription(accountData),
    [accountData]
  );

  const showMainButton = useMemo(
    () => !hasSubscription(accountData) || mainAction,
    [accountData, mainAction]
  );

  const onPress = useCallback(() => {
    if (hasSubscription(accountData)) {
      navigate(NavigationId.HOME_BILLING);
    } else {
      setModalVisible(true);
    }
  }, [accountData, navigate]);

  const onUpgradePress = useCallback(() => {
    navigate("/subscription", { state: { changePlan: true } });
  }, [navigate]);

  return showMainButton || showUpgradeButton ? (
    <>
      <div className="AlignedRow" style={{ gap: 14 }}>
        {showUpgradeButton ? (
          <FatHeaderActionButton title="Upgrade" onPress={onUpgradePress} />
        ) : null}
        {showMainButton ? (
          <div
            className="AlignedRowSelect"
            onClick={onPress}
            style={{
              ...styles.headerRightButton,
              backgroundColor: hasSubscription(accountData)
                ? Colors.BLACK
                : mainAction
                ? Colors.BLUE5
                : Colors.GRAY6,
            }}
          >
            {buttonTitle}
          </div>
        ) : null}
      </div>
      {modalVisible ? (
        <MarkitPlusModal closeModal={() => setModalVisible(false)} />
      ) : null}
    </>
  ) : null;
};

export default MarkitPlusActionButton;
