import "../css/CreateEvent.css";
import { TextField } from "@mui/material";
import { ThemeStyle } from "../hooks/useTheme";

type QuantityTextFieldProps = {
  quantity: number | string;
  placeholder: string;
  minimum: number;
  maximum: number;
  onChange: (newQuantity: number) => void;
  theme: ThemeStyle;
  disabled?: boolean;
};

const QuantityTextField = (props: QuantityTextFieldProps) => {
  const { quantity, placeholder, theme, minimum, maximum, onChange, disabled } =
    props;

  return (
    <div style={{ width: "30%" }}>
      <TextField
        size="small"
        type="number"
        placeholder={placeholder}
        value={quantity === 0 ? "" : quantity}
        onChange={(e) => onChange(e as any)}
        inputProps={{
          style: { ...theme.PrimaryText },
          min: minimum,
          max: maximum,
        }}
        InputProps={{ style: { borderRadius: 12 } }}
        style={{
          width: "100%",
          borderRadius: 12,
          ...theme.TertiaryBG,
        }}
        disabled={disabled}
      />
    </div>
  );
};

export default QuantityTextField;
