import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  Event,
  MassText,
  SubSelectionItem,
  SubSelectionType,
} from "@markit/common.types";
import { getAccountState } from "../../../redux/slices/accountSlice";
import MassTextsTextSummary from "./Summary/MassTextsTextSummary";
import MassTextsSendSummary from "./Summary/MassTextsSendSummary";
import MassTextsSendSchedule from "./Summary/MassTextsSendSchedule";
import MassTextsRecipients from "./MassTextsRecipients";
import MassTextMessagingHeader from "./MassTextsMessagingHeader";
import {
  MassTextMessagingScreenType,
  MassTextsSharedProps,
} from "./MassTextsPopupPanel";
import isEqual from "lodash.isequal";
import { useOnMount } from "../../../utils/useOnMount";
import { API } from "../../../API";
import {
  LessThanDate,
  massTextPlaceholder,
  massTextUpcomingEventPlaceholder,
} from "@markit/common.utils";
import { GetLongDate } from "../../../utils/GetLongDate";
import { useTheme } from "../../../hooks/useTheme";
import { TextPopupConfirmActions } from "../../../screens/Profile/CreatorPanels/CampaignsPanel";

export type ScheduleTextInfo = {
  sendTime: Date;
  scheduleToggle: boolean;
  catchUpChecked: boolean;
};

type MassTextsMessagingProps = MassTextsSharedProps & {
  event: Event | undefined;
  netSelectedPeople: string[];
  editingMassText: MassText | undefined;
  resetState: () => void;
  suggestedAction: SubSelectionItem | undefined;

  // for text send/schedule success/fail/delete popup
  setConfirmPopupTextConfirmation?: (
    confirmType: TextPopupConfirmActions
  ) => void;
};

const MassTextsMessaging = (props: MassTextsMessagingProps) => {
  const { theme } = useTheme();
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const {
    event,
    massTextSettings,
    updateMassTextSettings,
    netSelectedPeople,
    setProfileSelected,
    editingMassText,
    suggestedAction,
    setMainScreen,
    resetState,
    setConfirmPopupTextConfirmation,
  } = props;

  const [screen, setScreen] = useState<MassTextMessagingScreenType>(
    editingMassText
      ? MassTextMessagingScreenType.SEND_SUMMARY
      : MassTextMessagingScreenType.SUMMARY
  );
  // Local state for the Sending_Schedule screen
  const [scheduleTextInfo, setScheduleTextInfo] = useState<ScheduleTextInfo>({
    sendTime: new Date(),
    scheduleToggle: false,
    catchUpChecked: false,
  });
  const [totalDailyMessages, setTotalDailyMessages] = useState(0);
  const [loadingDailyMessages, setLoadingDailyMessages] = useState(true);
  const [categoryRecipients, setCategoryRecipients] = useState<string[]>([]);
  const [loadingCategoryRecipients, setLoadingCategoryRecipients] =
    useState(true);

  const isEditing = useMemo(
    () => editingMassText !== undefined,
    [editingMassText]
  );

  const defaultMessage = useMemo(
    () =>
      event && suggestedAction?.type === SubSelectionType.ALL_FOLLOWERS
        ? massTextUpcomingEventPlaceholder(
            accountData.fullName,
            GetLongDate(event.start, false, true, true, false),
            event.id
          )
        : massTextPlaceholder(accountData.fullName),
    [accountData.fullName, event, suggestedAction]
  );

  // Returns the net categories from the selected and excluded categories
  const finalSubSelectionIds = useMemo(
    () =>
      suggestedAction
        ? [suggestedAction]
        : massTextSettings.subSelectionIds || [],
    [massTextSettings.subSelectionIds, suggestedAction]
  );

  // Displays the FINAL recipients whether it's for categories or for individuals
  const finalRecipients = useMemo(
    () =>
      finalSubSelectionIds.length > 0 ? categoryRecipients : netSelectedPeople,
    [netSelectedPeople, finalSubSelectionIds, categoryRecipients]
  );

  const sharedSummaryPropsPack = {
    finalRecipients: finalRecipients,
    netIndividualRecipients: netSelectedPeople,
    massText: massTextSettings,
    updateMassTextSettings: updateMassTextSettings,
    screen: screen,
    setScreen: setScreen,
    setMainScreen: setMainScreen,
    isEditing: isEditing,
    suggestedAction: suggestedAction,
    theme: theme,
  };

  useOnMount(() => {
    (async () => {
      const { totalMessages } =
        await API.monitor.checkTotalDailyTwilioMessagesSent({});
      setTotalDailyMessages(totalMessages);
      setLoadingDailyMessages(false);
    })();
  });

  useEffect(() => {
    (async () => {
      // Fetch the recipients from the categories to display the number it will be sent to
      if (event && finalSubSelectionIds.length > 0) {
        setLoadingCategoryRecipients(true);
        const { recipients } = await API.text.convertTextCategories({
          selectedCategories: finalSubSelectionIds,
          excludedCategories: massTextSettings.excludedSubSelectionIds,
          userId: accountData.uid,
          eventId: event.id,
        });
        setCategoryRecipients(recipients);
      }
      setLoadingCategoryRecipients(false);
    })();
  }, [
    event,
    accountData.uid,
    finalSubSelectionIds,
    massTextSettings.excludedSubSelectionIds,
  ]);

  const madeChanges = useMemo(() => {
    if (screen === MassTextMessagingScreenType.SUMMARY) {
      return (
        (!suggestedAction && finalRecipients.length > 0) ||
        massTextSettings.message !== defaultMessage ||
        massTextSettings.mediaUrl !== ""
      );
    } else if (screen === MassTextMessagingScreenType.SEND_SUMMARY) {
      return (
        LessThanDate(new Date().toISOString(), massTextSettings.sentAt) &&
        isEditing &&
        !isEqual(massTextSettings, editingMassText)
      );
    } else if (screen === MassTextMessagingScreenType.SEND_SCHEDULE) {
      return (
        massTextSettings.scheduled !== scheduleTextInfo.scheduleToggle ||
        massTextSettings.catchUpEnabled !== scheduleTextInfo.catchUpChecked ||
        (massTextSettings.sentAt !== "" &&
          massTextSettings.sentAt !== scheduleTextInfo.sendTime.toISOString())
      );
    }
    return false;
  }, [
    defaultMessage,
    editingMassText,
    finalRecipients.length,
    isEditing,
    massTextSettings,
    scheduleTextInfo.catchUpChecked,
    scheduleTextInfo.scheduleToggle,
    scheduleTextInfo.sendTime,
    screen,
    suggestedAction,
  ]);

  return (
    <div style={{ paddingInline: 14 }}>
      <MassTextMessagingHeader
        screen={screen}
        setScreen={setScreen}
        massText={massTextSettings}
        updateMassTextSettings={updateMassTextSettings}
        resetState={resetState}
        madeChanges={madeChanges}
        finalRecipients={finalRecipients}
        suggestedAction={suggestedAction}
        totalDailyMessages={totalDailyMessages}
        loadingDailyMessages={loadingDailyMessages}
        loadingCategoryRecipients={loadingCategoryRecipients}
        scheduleTextInfo={scheduleTextInfo}
        setScheduleTextInfo={setScheduleTextInfo}
        isEditing={isEditing}
        setConfirmPopupTextConfirmation={setConfirmPopupTextConfirmation}
      />
      {/* Mass Texts Text Summary Screen */}
      {screen === MassTextMessagingScreenType.SUMMARY ? (
        <MassTextsTextSummary {...sharedSummaryPropsPack} event={event} />
      ) : null}
      {/* Mass Texts Send Summary Screen */}
      {screen === MassTextMessagingScreenType.SEND_SUMMARY ||
      screen === MassTextMessagingScreenType.LINK_ANALYTICS ? (
        <MassTextsSendSummary {...sharedSummaryPropsPack} />
      ) : null}
      {/* Mass Texts Send Schedule Screen */}
      {screen === MassTextMessagingScreenType.SEND_SCHEDULE ? (
        <MassTextsSendSchedule
          massText={massTextSettings}
          scheduleTextInfo={scheduleTextInfo}
          setScheduleTextInfo={setScheduleTextInfo}
          isEditing={isEditing}
        />
      ) : null}
      {/* Mass Text Recipients List */}
      {screen === MassTextMessagingScreenType.RECIPIENTS_SEND_SUMMARY ? (
        <MassTextsRecipients
          massText={massTextSettings}
          allSelectedPeople={netSelectedPeople}
          setProfileSelected={setProfileSelected}
        />
      ) : null}
    </div>
  );
};

export default MassTextsMessaging;
