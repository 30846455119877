import { useCallback, useEffect, useState } from "react";
import "../../css/GlobalStyles.css";
import "../../css/ExplorePage.css";
import { isDesktop } from "react-device-detect";
import { Icon } from "@iconify/react";
import { AnimatePresence, LazyMotion, domAnimation, m } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { Colors, addAlpha } from "../../utils/colors";
import FeaturedCities from "../../components/Explore/FeaturedCities";
import {
  AccountData,
  Event,
  ThemeType,
  Visibility,
} from "@markit/common.types";
import {
  addSearchHistory,
  deleteSearchHistory,
  getAccountState,
} from "../../redux/slices/accountSlice";
import { useOnMount } from "../../utils/useOnMount";
import { fetchFeaturedEvents } from "../../utils/ExploreUtils";
import { getEventData, getUserData } from "../../utils/FirebaseUtils";
import { algoliaClient } from "../../utils/algoliaUtils";
import { fetchFeaturedUsers } from "../../utils/ExploreUtils";
import { useNavigate } from "../../hooks/useNavigate";
import TopHeader from "../../components/TopHeader";
import ExploreHero from "../../components/Explore/ExploreHero";
import ExploreHeroImage from "../../assets/ExplorePageBackgrounds/ExploreHeroImage.png";
import ExploreHeroImageMobile from "../../assets/ExplorePageBackgrounds/ExploreHeroImageMobile.png";
import FeaturedPeople from "../../components/Explore/FeaturedPeople";
import {
  FeaturedEventCardMobile,
  FeaturedEventCardMobileSearch,
} from "../../components/LandingPage/RenderedEvents";
import { EmptySearchState } from "../../components/EmptyStates/EmptySearchState";
import { RenderedUser } from "../../components/LandingPage/RenderedUsers";
import ExploreNavCard from "../../components/Explore/ExploreNavCard";
import NightlifeHeroImage from "../../assets/ExplorePageBackgrounds/NightLifeHeroImage.jpg";
import TechNavImage from "../../assets/ExplorePageBackgrounds/TechNavImage.png";
import FeaturedEvents from "../../components/Explore/FeaturedEvents";
import LoadingScreen from "../LoadingScreen";
import Footer from "../../components/Footer";
import { fetchEventOrganizers } from "../../utils/eventUtils/eventUtils";

enum SearchOptionType {
  RECENT = "Recent",
  EVENTS = "Events",
  PEOPLE = "People",
}

enum Collection {
  USERS = "users",
  EVENTS = "events",
}

const ExplorePage = () => {
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const navigate = useNavigate();
  const { searchHistory } = accountData;
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [featuredEvents, setFeaturedEvents] = useState<Event[]>([]);
  const [featuredEventOrganizers, setFeaturedEventOrganizers] = useState<
    AccountData[]
  >([]);
  const [featuredPeople, setFeaturedPeople] = useState<AccountData[]>([]);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [displaySearchModal, setDisplaySearchModal] = useState<boolean>(false);
  const [selectedSearchOption, setSelectedSearchOption] =
    useState<SearchOptionType>(SearchOptionType.RECENT);
  const [searchedEventsHistory, setSearchedEventsHistory] = useState<Event[]>(
    []
  );
  const [searchedEvents, setSearchedEvents] = useState<Event[]>([]);
  const [searchedUsers, setSearchedUsers] = useState<AccountData[]>([]);

  // only handles deleting events from search bar
  const deleteSearchedItem = useCallback(
    (id: string) => {
      dispatch(deleteSearchHistory(accountData.uid, "marks", id));
    },
    [accountData.uid, dispatch]
  );
  const addSearchedEvent = useCallback(
    (id: string) => {
      dispatch(addSearchHistory(accountData.uid, "marks", id));
    },
    [accountData.uid, dispatch]
  );

  useOnMount(() => {
    (async () => {
      const [featuredEvents, featuredPeople] = await Promise.all([
        fetchFeaturedEvents({ isBasePage: true }),
        fetchFeaturedUsers({
          numDisplay: isDesktop ? 6 : 3,
          onBaseExplore: true,
        }),
      ]);

      setFeaturedEvents(featuredEvents);
      setFeaturedPeople(featuredPeople);

      // Get the unique organizers for the events found
      const featuredEventOrganizers = await fetchEventOrganizers(
        featuredEvents
      );
      setFeaturedEventOrganizers(featuredEventOrganizers);
      setIsLoading(false);
    })();
  });

  const enterOnSearchBar = (e: any) => {
    if (e.key === "Enter" && searchTerm.trim() !== "") {
      if (selectedSearchOption === SearchOptionType.RECENT) {
        setSelectedSearchOption(SearchOptionType.EVENTS);
      }
      handleSearch(searchTerm);
    }
  };

  // Retrieve recent events searched
  useEffect(() => {
    // TODO - Sort events by search date once we support showing more searches
    (async () => {
      const existing = searchedEventsHistory.map((event) => event.id);
      const deleted = existing.filter(
        (eid) => !searchHistory.marks.includes(eid)
      );
      const added = searchHistory.marks.filter(
        (eid) => !existing.includes(eid)
      );
      if (deleted.length === 0 && added.length === 0) {
        return;
      }

      let prev: Event[] = [...searchedEventsHistory];
      let data: Event[] = [];
      if (deleted.length > 0) {
        prev = prev.filter((event) => !deleted.includes(event.id));
      }

      if (added.length > 0) {
        const promises = added.map((eid) => getEventData(eid));
        const results = await Promise.all(promises);
        data = results.filter((user): user is Event => {
          return user !== undefined;
        });
        prev = searchedEventsHistory.filter((event) =>
          searchHistory.marks.includes(event.id)
        );
      }

      setSearchedEventsHistory(
        data
          .concat(prev)
          .filter(
            (event) => !event.isDraft && event.visibility === Visibility.Public
          )
      );
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchHistory.marks]);

  const renderSelectedComponent = useCallback(() => {
    switch (selectedSearchOption) {
      case SearchOptionType.RECENT:
        return (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "14px" }}
          >
            {searchedEventsHistory.length > 0 ? (
              searchedEventsHistory.map((event) => {
                return (
                  <FeaturedEventCardMobileSearch
                    event={event}
                    deleteOnClick={() => deleteSearchedItem(event.id)}
                  />
                );
              })
            ) : (
              <EmptySearchState mainText={"No recents to show"} />
            )}
          </div>
        );
      case SearchOptionType.EVENTS:
        return (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "14px" }}
          >
            {searchedEvents.length > 0 ? (
              searchedEvents.map((event) => {
                return (
                  <FeaturedEventCardMobile
                    event={event}
                    addToSearchHistory={addSearchedEvent}
                    eventOrganizers={featuredEventOrganizers}
                  />
                );
              })
            ) : (
              <EmptySearchState mainText={"No events to show"} />
            )}
          </div>
        );
      case SearchOptionType.PEOPLE:
        return (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "14px" }}
          >
            {searchedUsers.length > 0 ? (
              searchedUsers.map((user) => {
                return <RenderedUser user={user} />;
              })
            ) : (
              <EmptySearchState mainText={"No people to show"} />
            )}
          </div>
        );
      // @TODO: To implement when location-based search is ready
      // case 'Places':
      //   return ...
      default:
        return null;
    }
  }, [
    selectedSearchOption,
    searchedEventsHistory,
    searchedEvents,
    searchedUsers,
    deleteSearchedItem,
    addSearchedEvent,
    featuredEventOrganizers,
  ]);

  const SearchOption = (props: {
    option: SearchOptionType;
    mobile?: boolean;
  }) => {
    const { option, mobile } = props;

    return (
      <div
        style={{
          backgroundColor:
            selectedSearchOption === option
              ? Colors.BLACK
              : mobile
              ? Colors.WHITE
              : Colors.GRAY6,
          paddingBlock: "7px",
          paddingInline: "12px",
          borderRadius: "8px",
          cursor: "pointer",
        }}
        onClick={() => setSelectedSearchOption(option)}
      >
        <span
          style={{
            color:
              selectedSearchOption === option ? Colors.WHITE : Colors.BLACK,
            cursor: "pointer",
            fontSize: "15px",
            fontWeight: 500,
          }}
        >
          {option}
        </span>
      </div>
    );
  };

  const [timeoutEvent, setTimeoutEvent] = useState<
    NodeJS.Timeout | undefined
  >();

  const search = useCallback(async (text: string, target: Collection) => {
    const index = algoliaClient.initIndex(target);
    const ids = (await index.search(text)).hits.map((obj) => obj.objectID);
    return ids;
  }, []);

  const handlePeople = useCallback(
    async (text: string) => {
      if (text.length <= 1) {
        setSearchedUsers([]);
        return;
      }
      const userIds = await search(text, Collection.USERS);
      const promises = userIds.map((uid) => getUserData(uid));
      Promise.all(promises).then((results) => {
        setSearchedUsers(
          results.filter((user): user is AccountData => user !== undefined)
        );
      });
    },
    [search]
  );

  const handleEvents = useCallback(
    async (text: string) => {
      if (text.length <= 2) {
        setSearchedEvents([]);
        return;
      }
      const eventIds = await search(text, Collection.EVENTS);
      const promises = eventIds.map((eid) => getEventData(eid));
      Promise.all(promises).then((results) => {
        setSearchedEvents(
          results.filter(
            (event): event is Event =>
              event !== undefined &&
              !event.isDraft &&
              event.visibility === Visibility.Public
          )
        );
      });
    },
    [search]
  );

  const handleSearch = useCallback(
    async (text: string) => {
      setSearchTerm(text);
      if (timeoutEvent) {
        clearTimeout(timeoutEvent);
      }
      setTimeoutEvent(
        setTimeout(async () => {
          await handleEvents(text);
          await handlePeople(text);
        }, 500)
      );
    },
    [handleEvents, handlePeople, timeoutEvent]
  );

  const styles = {
    gradientHeader: {
      backgroundImage: isDesktop
        ? `url(${ExploreHeroImage})`
        : `url(${ExploreHeroImageMobile})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      height: isDesktop ? 400 : 300,
    },
    mainContentContainer: {
      padding: isDesktop ? "40px 18vw 60px 18vw" : "24px 14px 40px 14px",
      backgroundColor: Colors.WHITE1,
    },
    HeroContainer: {
      padding: isDesktop ? "0px 18vw 60px 18vw" : "0px 14px 40px 14px",
    },
    title: {
      fontSize: isDesktop ? 32 : 24,
      fontWeight: 600,
    },
    subtitle: {
      fontSize: isDesktop ? 16 : 14,
      color: Colors.GRAY1,
    },
  };
  if (isLoading) {
    return <LoadingScreen />;
  } else {
    return (
      <div
        style={{
          overflow: "hidden",
          position: "relative",
          height: displaySearchModal ? "100vh" : "auto",
        }}
      >
        <div style={styles.gradientHeader}>
          <TopHeader eventTheme={ThemeType.Light} whiteLogo />
          <div style={styles.HeroContainer}>
            <ExploreHero />
            <div>
              <div
                className="SearchBarContainer"
                style={{
                  backgroundColor: isDesktop
                    ? addAlpha(Colors.GRAY11, 0.1)
                    : Colors.WHITE,
                  borderRadius: isDesktop ? 100 : 14,
                  borderStyle: "solid",
                  borderColor: addAlpha(Colors.GRAY11, 0.2),
                }}
              >
                <div
                  className="SearchBarIconAndInputContainer"
                  style={{ paddingBlock: isDesktop ? 12 : 8 }}
                >
                  <Icon
                    icon="ion:search"
                    height={20}
                    style={{
                      color: isDesktop ? Colors.WHITE : Colors.GRAY2,
                      marginLeft: 5,
                    }}
                  />
                  <input
                    className={`SearchBarInput ${
                      isDesktop ? "LightSearchBarInput" : "DarkSearchBarInput"
                    }`}
                    placeholder={"Search people, events, places..."}
                    onKeyDown={enterOnSearchBar}
                    onChange={(e) => handleSearch(e.target.value)}
                    onFocus={() => setDisplaySearchModal(true)}
                    value={searchTerm}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={styles.mainContentContainer}>
          <div
            className="AlignedRow EventCategoriesContainer"
            style={{ gap: 14, width: "100%", justifyContent: "center" }}
          >
            <ExploreNavCard
              title="Nightlife"
              onPress={() => navigate("/explore/nightlife")}
              src={NightlifeHeroImage}
            />
            <ExploreNavCard
              title="Tech"
              onPress={() => navigate("/explore/tech")}
              src={TechNavImage}
            />
          </div>
          <FeaturedPeople featuredPeople={featuredPeople} />
          <FeaturedCities />
          <FeaturedEvents
            featuredEvents={featuredEvents.slice(0, 4)}
            featuredEventOrganizers={featuredEventOrganizers}
          />
        </div>
        <AnimatePresence>
          {displaySearchModal && isDesktop && (
            <>
              <LazyMotion features={domAnimation}>
                <m.div
                  key="searchModalBg"
                  className="SearchBarBgDesktop HideScrollbar"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 0.4 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                  onClick={() => setDisplaySearchModal(false)}
                  style={{ zIndex: 10 }}
                />
              </LazyMotion>
              <LazyMotion features={domAnimation}>
                <m.div
                  key="searchModalContainerDesktop"
                  className="SearchBarContainerDesktop HideScrollbar"
                  initial={{ height: 0, scale: 1.01 }}
                  animate={{ height: "80vh", scale: 1 }}
                  exit={{ height: 0, scale: 1.01 }}
                  transition={{ duration: 0.4 }}
                  style={{ zIndex: 10 }}
                >
                  <m.div
                    className="AlignedRowSpaced"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.4 }}
                  >
                    <div
                      className="SearchBarContainer"
                      style={{ marginTop: 0 }}
                    >
                      <div className="SearchBarIconAndInputContainer">
                        <Icon
                          icon="ion:search"
                          height={18}
                          style={{ color: Colors.BLACK }}
                        />
                        <input
                          autoFocus
                          className="SearchBarInput DarkSearchBarInput"
                          placeholder="Search Events, People, Places..."
                          onKeyDown={enterOnSearchBar}
                          onChange={(e) => handleSearch(e.target.value)}
                          value={searchTerm}
                        />
                        <Icon
                          icon="ion:close-outline"
                          height={18}
                          style={{ color: Colors.GRAY1, cursor: "pointer" }}
                          onClick={() => {
                            setSearchTerm("");
                            handleSearch("");
                          }}
                        />
                      </div>
                    </div>
                    <span
                      style={{ marginLeft: "14px", cursor: "pointer" }}
                      onClick={() => setDisplaySearchModal(false)}
                    >
                      Cancel
                    </span>
                  </m.div>
                  <m.div
                    className="SearchOptionsContainer"
                    key="searchModalContainerOptions"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.4 }}
                  >
                    <SearchOption option={SearchOptionType.RECENT} />
                    <SearchOption option={SearchOptionType.EVENTS} />
                    <SearchOption option={SearchOptionType.PEOPLE} />
                    {/* @TODO: Implement location-based search */}
                    {/* <SearchOption option="Places" /> */}
                  </m.div>
                  <m.div
                    key="searchModalContainerDivider"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.4 }}
                  >
                    <hr />
                  </m.div>
                  <m.div
                    key="searchModalContainerContent"
                    style={{
                      overflowY: "scroll",
                      height: "67vh",
                      scrollbarWidth: "none",
                    }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.4 }}
                  >
                    {renderSelectedComponent()}
                    <div style={{ paddingBottom: 225 }}></div>
                  </m.div>
                </m.div>
              </LazyMotion>
            </>
          )}
        </AnimatePresence>
        {/* Search modal for mobile */}
        <AnimatePresence>
          {displaySearchModal && !isDesktop && (
            <div className="SearchBgMobile">
              <LazyMotion features={domAnimation}>
                <m.div
                  key="searchBarContainerMobile"
                  className="SearchBarContainerMobile HideScrollbar"
                  initial={{ top: "90.5px", opacity: 0 }}
                  animate={{ top: "48px", opacity: 1 }}
                  exit={{ top: "90.5px", opacity: 0 }}
                  transition={{ duration: 0.3, type: "tween" }}
                >
                  <div className="AlignedRowSpaced">
                    <div className="SearchBarContainer">
                      <div className="SearchBarIconAndInputContainer">
                        <Icon
                          icon="ion:search"
                          height={18}
                          style={{ color: Colors.BLACK }}
                        />
                        <input
                          autoFocus
                          className="SearchBarInput DarkSearchBarInput"
                          placeholder="Search Events, People, Places..."
                          onKeyDown={enterOnSearchBar}
                          onChange={(e) => handleSearch(e.target.value)}
                          value={searchTerm}
                        />
                        <Icon
                          icon="ion:close-outline"
                          height={18}
                          style={{ color: Colors.GRAY1, cursor: "pointer" }}
                          onClick={() => {
                            setSearchTerm("");
                            handleSearch("");
                          }}
                        />
                      </div>
                    </div>
                    <span
                      style={{ marginLeft: "14px", cursor: "pointer" }}
                      onClick={() => setDisplaySearchModal(false)}
                    >
                      Cancel
                    </span>
                  </div>
                  <div className="SearchOptionsContainer">
                    <SearchOption option={SearchOptionType.RECENT} mobile />
                    <SearchOption option={SearchOptionType.EVENTS} mobile />
                    <SearchOption option={SearchOptionType.PEOPLE} mobile />
                    {/* @TODO: Implement location-based search */}
                    {/* <SearchOption option="Places" /> */}
                  </div>
                  <hr />
                  <div
                    style={{
                      height: "75vh",
                      overflow: "scroll",
                    }}
                  >
                    {renderSelectedComponent()}
                  </div>
                </m.div>
              </LazyMotion>
            </div>
          )}
        </AnimatePresence>

        <Footer />
      </div>
    );
  }
};
export default ExplorePage;
