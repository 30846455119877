import { Icon } from "@iconify/react";
import { Colors } from "../../../utils/colors";
import {
  Event,
  MassText,
  MassTextAttachment,
  SubSelectionItem,
  SubSelectionType,
} from "@markit/common.types";
import {
  generateSuggestedActionItem,
  getTimezone,
  isEventExternalLink,
  isExternalGenericLink,
} from "@markit/common.utils";
import { useEffect, useMemo, useState } from "react";
import PopupModalContainer from "../../Containers/PopupModalContainer";
import { isMultipleDays } from "../../../utils/SetStartEndDate";
import { GetLongDate } from "../../../utils/GetLongDate";
import { GetTime } from "../../../utils/GetTime";
import RectangleButton from "../../Buttons/RectangleButton";
import { EventPercentSetUpChecklist } from "../../EventDashboard/EventPercentSetUpChecklist";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import { fetchNumSubscribedFollowersNotFromEvent } from "../../../utils/userUtils";

type EventPublishedModalProps = {
  event: Event;
  roleName: string;
  eventMassTexts: MassText[];
  regularMassTextsInRange: MassText[];
  massTextAttachments: MassTextAttachment[];
  setMassTextsPopupPanelVisible: (massTextsPopupPanelVisible: boolean) => void;
  openEventTexts: () => void;
  setSuggestedAction: (suggestedAction: SubSelectionItem) => void;
};

export const EventPublishedModal = (props: EventPublishedModalProps) => {
  const {
    event,
    roleName,
    eventMassTexts,
    regularMassTextsInRange,
    massTextAttachments,
    setMassTextsPopupPanelVisible,
    openEventTexts,
    setSuggestedAction,
  } = props;
  const { numFollowers, accountData } = useSelector(getAccountState).account;

  const { fromCreate } = useLocation().state || {};

  const [numFollowersNotFromEvent, setNumFollowersNotFromEvent] = useState(0);
  const [publishedModalVisible, setPublishedModalVisible] = useState(
    fromCreate !== undefined && fromCreate ? true : false
  );

  useEffect(() => {
    (async () => {
      if (publishedModalVisible) {
        const numFollowersNotFromEvent =
          await fetchNumSubscribedFollowersNotFromEvent(
            accountData.uid,
            event.id
          );
        setNumFollowersNotFromEvent(numFollowersNotFromEvent);
      }
    })();
  }, [accountData.uid, event.id, publishedModalVisible]);

  const isExternalLink = useMemo(
    () => isEventExternalLink(event.eventType),
    [event.eventType]
  );

  return (
    <>
      {publishedModalVisible ? (
        <PopupModalContainer
          headerComp={
            <Icon
              icon={"ion:checkmark-circle"}
              height={35}
              style={{ color: Colors.GREEN2 }}
            />
          }
          subHeaderComp={
            <div className="PercentModalText">
              {isExternalLink ? "Link" : "Event"} Published
            </div>
          }
          noDivider
          valueComp={
            <div>
              <div className="AlignedRow" style={{ paddingBlock: 14 }}>
                <img
                  className="EventDashboardEventPhoto"
                  src={event.photoURL}
                  alt={"event cover"}
                />
                <div
                  className="EventDashboardEventDescription"
                  style={{ gap: 5 }}
                >
                  <div style={{ fontSize: 14, fontWeight: "500" }}>
                    {event.title}
                  </div>
                  <div style={{ fontSize: 12, color: Colors.GRAY2 }}>
                    {isExternalLink
                      ? isExternalGenericLink(event.eventType)
                        ? "Link"
                        : "Linked Event"
                      : `You are ${roleName}`}
                  </div>
                  {!isExternalGenericLink(event.eventType) ? (
                    <div style={{ fontSize: 12, color: Colors.GRAY2 }}>
                      {event.isVirtual
                        ? "Virtual Location"
                        : event.formattedAddress}
                    </div>
                  ) : null}
                  <div style={{ fontSize: 12, color: Colors.GRAY2 }}>
                    {GetLongDate(event.start, true, true, true, false)}{" "}
                    {GetTime(event.start)} to{" "}
                    {isMultipleDays(event.start, event.end) &&
                      GetLongDate(event.end, true, true, true, false)}{" "}
                    {GetTime(event.end)} {getTimezone()}.
                  </div>
                </div>
              </div>
              <EventPercentSetUpChecklist
                event={event}
                eventTexts={eventMassTexts}
                userMassTexts={regularMassTextsInRange}
                attachments={massTextAttachments}
                fromSideModal={false}
                setPublishedModalVisible={setPublishedModalVisible}
                setMassTextsPopupPanelVisible={setMassTextsPopupPanelVisible}
                setSuggestedAction={setSuggestedAction}
                openEventTexts={openEventTexts}
                numFollowersNotFromEvent={numFollowersNotFromEvent}
              />
              <div
                className="AlignedColumn"
                style={{ gap: 10, paddingTop: 14 }}
              >
                {numFollowers > 0 ? (
                  <RectangleButton
                    buttonLabel="Invite Following"
                    onPress={() => {
                      setSuggestedAction(
                        generateSuggestedActionItem(
                          SubSelectionType.ALL_FOLLOWERS,
                          ""
                        )
                      );
                      setPublishedModalVisible(false);
                      setMassTextsPopupPanelVisible(true);
                    }}
                    altColor={Colors.BLACK}
                  />
                ) : null}
                <RectangleButton
                  buttonLabel="Done"
                  onPress={() => setPublishedModalVisible(false)}
                  altColor={Colors.GRAY6}
                  altTextColor={Colors.BLACK}
                />
              </div>
            </div>
          }
          noExit
          closeModal={() => setPublishedModalVisible(false)}
        />
      ) : null}
    </>
  );
};
