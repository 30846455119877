import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { memo } from "react";
import SubscriptionPlan from "../screens/Subscription/SubscriptionPlan";
import SubscriptionCancel from "../screens/Subscription/SubscriptionCancel";
import { useSelector } from "react-redux";
import {
  LoginState,
  accountActions,
  getAccountState,
} from "../redux/slices/accountSlice";
import { useDispatch } from "react-redux";

const SubscriptionRoute = memo(function SubscriptionRouteFn() {
  const { account } = useSelector(getAccountState);
  const { loggedIn } = account;
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  if (loggedIn === LoginState.LOGGED_IN) {
    return (
      <Routes>
        <Route index element={<SubscriptionPlan />} />
        <Route path="cancel" element={<SubscriptionCancel />} />
        <Route path="freeze" element={<SubscriptionCancel />} />
      </Routes>
    );
  } else {
    dispatch(accountActions.setRedirectPath(pathname));
    return <Navigate to="/welcome" />;
  }
});

export default SubscriptionRoute;
