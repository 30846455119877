import React from "react";
import { CircularProgress } from "@mui/material";
import EmptyStateButton from "../Buttons/EmptyStateButton";
import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";

type EmptyStateFlatlistprops = {
  searchTerm: string;
  isLoading: boolean;
  nonSearchEmptyView: JSX.Element;
  btnText?: string;
  onPress?: () => void;
  containerStyles?: React.CSSProperties;
};

// To be used for the ListEmptyComponent prop for flatlists if there is a search bar present
export const EmptyStateFlatlist = (props: EmptyStateFlatlistprops) => {
  const {
    searchTerm,
    nonSearchEmptyView,
    isLoading,
    btnText,
    onPress,
    containerStyles,
  } = props;

  return searchTerm === "" && !isLoading ? (
    nonSearchEmptyView
  ) : !isLoading ? (
    <EmptyStateButton
      title="No Results"
      description="Check for typos or search for something else"
      icon={<Icon icon="ion:search" height={40} color={Colors.GRAY1} />}
      btnText={btnText}
      onPress={onPress}
      containerStyles={containerStyles}
    />
  ) : isLoading ? (
    <div
      style={{
        ...containerStyles,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <CircularProgress style={{ color: Colors.GRAY1 }} size={30} />
    </div>
  ) : null;
};
