import { Icon } from "@iconify/react";
import { Colors } from "../../../utils/colors";
import { useCallback, useMemo, useState } from "react";
import PopupModalContainer from "../../Containers/PopupModalContainer";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import { RWebShare } from "react-web-share";
import { MARKITAI } from "@markit/common.utils";
import { ThemeStyle } from "../../../hooks/useTheme";
import ConfirmActionPopup from "../../Containers/ConfirmPopups/ConfirmActionPopup";
import AlertButtonWrapper from "../../Containers/AlertButtonWrapper";

export type ShareProfileLinkModalProps = {
  theme?: ThemeStyle;
  setShareLinkModal: (shareLinkModal: boolean) => void;
};

const ShareProfileLinkModal = (props: ShareProfileLinkModalProps) => {
  const { theme, setShareLinkModal } = props;
  const { username } = useSelector(getAccountState).account.accountData;
  const [confirmCopyAlert, setConfirmCopyAlert] = useState(false);

  const [alertText, setAlertText] = useState("");

  const styles = {
    smallText: { fontSize: 11, paddingTop: 7 },
  };
  const shareURL = useMemo(() => MARKITAI + "/u/" + username, [username]);

  const onCopyClick = useCallback(() => {
    const el = document.createElement("input");
    el.value = shareURL;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    setConfirmCopyAlert(true);
  }, [shareURL]);

  const onMessagesClick = useCallback(() => {
    const url = `sms:&body=${encodeURIComponent(shareURL)}`;
    window.location.href = url;
  }, [shareURL]);

  return (
    <PopupModalContainer
      headerComp={
        <div>
          <Icon icon={"mdi:link"} height={24} />
          <h1 className="AboutSubtitle" style={{ fontSize: 16, paddingTop: 4 }}>
            Share Profile Link
          </h1>
        </div>
      }
      valueComp={
        <div>
          <AlertButtonWrapper
            buttonComp={
              <div className="ShareEventModalLinks" style={theme?.PrimaryText}>
                <RWebShare data={{ url: MARKITAI + "/u/" + username }}>
                  <div>
                    <div
                      className="ShareEventModalLinkIcon"
                      style={
                        theme
                          ? theme.TertiaryBG
                          : { backgroundColor: Colors.GRAY6 }
                      }
                    >
                      <Icon icon="mdi:share-outline" height={26} />
                    </div>
                    <div style={styles.smallText}>Share to...</div>
                  </div>
                </RWebShare>
                <div>
                  <div
                    className="ShareEventModalLinkIcon"
                    style={
                      theme
                        ? theme.TertiaryBG
                        : { backgroundColor: Colors.GRAY6 }
                    }
                    onClick={onCopyClick}
                  >
                    <Icon icon="ep:link" height={26} />
                  </div>
                  <div style={styles.smallText}>Copy Link</div>
                </div>
                <div>
                  <div
                    className="ShareEventModalLinkIcon"
                    style={
                      theme
                        ? theme.TertiaryBG
                        : { backgroundColor: Colors.GRAY6 }
                    }
                    onClick={onMessagesClick}
                  >
                    <Icon icon="ion:chatbubble-outline" height={22} />
                  </div>
                  <div style={styles.smallText}>Messages</div>
                </div>
                <div>
                  <div
                    className="ShareEventModalLinkIcon"
                    style={
                      theme
                        ? theme.TertiaryBG
                        : { backgroundColor: Colors.GRAY6 }
                    }
                    onClick={() => setAlertText("Coming soon...")}
                  >
                    <Icon icon="mdi:instagram" height={24} />
                  </div>
                  <div style={styles.smallText}>Instagram</div>
                </div>
                <div>
                  <div
                    className="ShareEventModalLinkIcon"
                    style={
                      theme
                        ? theme.TertiaryBG
                        : { backgroundColor: Colors.GRAY6 }
                    }
                    onClick={() =>
                      setAlertText("More share options coming soon!")
                    }
                  >
                    <Icon icon="mdi:ellipsis-horizontal" height={22} />
                  </div>
                  <div style={{ ...styles.smallText, textAlign: "center" }}>
                    More
                  </div>
                </div>{" "}
              </div>
            }
            alertTextHeader={alertText}
            clearAlert={() => setAlertText("")}
          />
          {confirmCopyAlert ? (
            <ConfirmActionPopup
              title="Copied Link!"
              onDisappear={() => setConfirmCopyAlert(false)}
            />
          ) : null}
        </div>
      }
      theme={theme}
      closeModal={() => setShareLinkModal(false)}
    />
  );
};

export default ShareProfileLinkModal;
