import "../../css/GlobalStyles.css";
import { ReactNode, memo } from "react";
import { Colors } from "../../utils/colors";
import { CreatorPanelTabs } from "../Tabs/CreatorPanelTabs";

export type CreatorModeHeaderTab = {
  tabNames: string[];
  tabValue: number;
  setTabValue: (tabValue: number) => void;
};

type CreatorModePanelWrapperProps = {
  title: string;
  children: ReactNode;
  headerRight: ReactNode;
  headerTabs?: CreatorModeHeaderTab;
};

const CreatorModePanelWrapper = (props: CreatorModePanelWrapperProps) => {
  const { title, children, headerRight, headerTabs } = props;

  return (
    <div className="ColumnNormal">
      <div
        className="ColumnNormal"
        style={{
          padding: 30,
          gap: 20,
          borderBottom: `0.5px solid ${Colors.GRAY11}`,
        }}
      >
        <div className="AlignedRowSpaced">
          <span style={{ fontSize: 32, fontWeight: 600 }}>{title}</span>
          {headerRight}
        </div>
        {headerTabs ? (
          <CreatorPanelTabs
            tabValue={headerTabs.tabValue}
            setTabValue={headerTabs.setTabValue}
            tabNames={headerTabs.tabNames}
          />
        ) : null}
      </div>
      <div
        style={{
          backgroundColor: Colors.WHITE1,
          minHeight: "80vh",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default memo(CreatorModePanelWrapper);
