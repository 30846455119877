import "../../css/App.css";
import "../../css/FullEvent/FullEvent.css";
import "../../css/FullEvent/FullEventDesktop.css";
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  CustomTicketV2,
  AccountData,
  SavedFormQuestion,
} from "@markit/common.types";
import { isBrowser } from "react-device-detect";
import FullEventDesktop from "./FullEventDesktop";
import FullEvent from "./FullEvent";
import { MixpanelContext } from "../../context/AnalyticsService";
import {
  getVisibleCustomTickets,
  isEventExternalLink,
  isEventTicketsPaid,
  isExternalEventbrite,
  isExternalLinkNoData,
} from "@markit/common.utils";
import axios from "axios";
import { eventAtCapacity } from "../../utils/eventUtils/eventUtils";
import {
  useEventSelector,
  useParametricSelector,
} from "../../hooks/useParametricSelector";
import {
  DataLoaders,
  getLoadedEvent,
  getLoadedTicket,
  getLoadedUser,
  getLoadedUsers,
} from "../../redux/slices/dataSlice";
import LoadingScreen from "../LoadingScreen";
import { useDispatch, useSelector } from "react-redux";
import { getUrlParamState } from "../../redux/slices/urlParamSlice";
import {
  LoginState,
  accountActions,
  getAccountState,
} from "../../redux/slices/accountSlice";
import { isRCA } from "../../utils/globalUtils";
import {
  updateLinkTrackerOpens,
  updateTotalEventLinkViews,
} from "../../utils/trackingUtils";
import { getEventFormQuestions } from "../../utils/eventUtils/formUtils";
import { firebaseMapsApiKey } from "../../firebase";
import FullEventExternal from "./FullEventExternal";
import { useNavigate } from "../../hooks/useNavigate";

type FullEventDeciderProps = {};

const FullEventDecider = memo(function FullEventDeciderFn(
  props: FullEventDeciderProps
) {
  const dispatch = useDispatch();
  const { accountData, loggedIn } = useSelector(getAccountState).account;
  const mixpanel = useContext(MixpanelContext);
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [address, setAddress] = useState("");
  const [isEventAtCapacity, setIsEventAtCapacity] = useState(false);
  const [eventFormQuestions, setEventFormQuestions] = useState<
    SavedFormQuestion[]
  >([]);
  const [loading, setLoading] = useState(true);
  const {
    ticketId,
    promoCodeId: pCodeId,
    promoter: promoterId,
    l: currLinkId,
    linkId: oldLinkId,
    userReferrer,
  } = useSelector(getUrlParamState);

  const eventId = useEventSelector();
  const {
    isLoading: isLoadingEventData,
    data: event,
    isError: isErrorEventData,
  } = useParametricSelector(getLoadedEvent, eventId);
  const {
    isLoading: isLoadingTicketData,
    data: redeemTicket,
    isError: isErrorTicketData,
  } = useParametricSelector(getLoadedTicket, ticketId);
  const {
    isLoading: isLoadingHostData,
    data: host,
    isError: isErrorHostData,
  } = useParametricSelector(getLoadedUser, event?.createdBy ?? "");

  // Handle backsupport for old linkId param replaced for just l
  const linkId = useMemo(
    () => currLinkId || oldLinkId,
    [currLinkId, oldLinkId]
  );

  const isLoadingPage = useMemo(
    () =>
      isLoadingEventData ||
      (ticketId && isLoadingTicketData) ||
      isLoadingHostData ||
      loading,
    [
      isLoadingEventData,
      isLoadingHostData,
      isLoadingTicketData,
      loading,
      ticketId,
    ]
  );

  const cohostsData: AccountData[] = useParametricSelector(
    getLoadedUsers,
    event?.cohosts ?? []
  )
    .map((user) => user?.data)
    .filter((user) => user !== undefined) as AccountData[];

  const [selectedCustomTicket, setSelectedCustomTicket] =
    useState<CustomTicketV2>();

  const isUserRCA = useMemo(
    () => (event && isRCA(event.createdBy, event.cohosts)) ?? false,
    [event]
  );

  const customTicketToRedeem = useMemo(
    () =>
      event &&
      redeemTicket &&
      event.customTickets.find(
        (ticket) => ticket.id === redeemTicket.customTicketId
      ),
    [event, redeemTicket]
  );

  const visibleTickets = useMemo(
    () =>
      event
        ? getVisibleCustomTickets(event.customTickets, customTicketToRedeem?.id)
        : [],
    [customTicketToRedeem?.id, event]
  );

  const promoCode = useMemo(() => {
    if (pCodeId && event) {
      const found = event.promoCodes.find(
        (promoCode) => promoCode.id === pCodeId
      );
      if (
        found &&
        found.redeemedBy !== "" &&
        found.redeemedBy !== "pending" &&
        found.redeemedBy !== "unlimited"
      ) {
        alert("Promo Code is already redeemed");
        return undefined;
      }
      return found;
    }
    return undefined;
  }, [pCodeId, event]);

  const getAddress = useCallback(async () => {
    try {
      let response: any;
      if (event !== undefined && event.googlePlaceId !== undefined) {
        if (event.formattedAddress !== undefined) {
          setAddress(event.formattedAddress);
        } else {
          const url =
            "https://maps.googleapis.com/maps/api/place/details/json?place_id=" +
            event?.googlePlaceId +
            "&key=" +
            firebaseMapsApiKey;
          response = await axios.get(url);
          response.data.result !== undefined
            ? setAddress(response.data.result["formatted_address"])
            : console.log("Error fetching address data.");
        }
      }
    } catch (err: any) {
      console.error(err.message);
    }
  }, [event]);

  // Handles redirecting based on draft or live event
  // Handles redirecting if it's a role ticket
  useEffect(() => {
    if (event && event.isDraft) {
      if (accountData.inCreatorMode) {
        navigate("/home/events");
      } else {
        navigate("/");
      }
    }
    // redirect if this is a role ticket
    if (
      ticketId &&
      redeemTicket &&
      redeemTicket.customTicketId === "" &&
      event
    ) {
      dispatch(accountActions.setCurrentRoleTicket([redeemTicket, event]));
      if (loggedIn === LoginState.LOGGED_IN) {
        window.location.replace(
          `https://markitai.com/e/${redeemTicket.eventId}/i/${accountData.uid}?ticketId=${ticketId}`
        );
      } else {
        navigate("/welcome");
      }
    }
  }, [
    accountData.inCreatorMode,
    accountData.uid,
    dispatch,
    event,
    loggedIn,
    navigate,
    redeemTicket,
    ticketId,
  ]);

  // Handles the mixpanel tracking logic
  useEffect(() => {
    if (!event) {
      return;
    }
    dispatch(accountActions.setIsRCA(isUserRCA));
    if (isEventExternalLink(event.eventType)) {
      mixpanel.track("Webapp: Viewed External Event Link", {
        event_id: eventId,
        external_link: event.externalLink,
        event_type: event.eventType,
      });
    } else if (ticketId) {
      mixpanel.track("Webapp: Viewed Redeeming Ticket", {
        event_id: eventId,
        tix_id: ticketId,
        event_type: "paid",
      });
    } else if (pCodeId) {
      mixpanel.track("Webapp: Viewed Redeeming Promo Code", {
        event_id: eventId,
        pCode_id: pCodeId,
        event_type: "paid",
      });
    } else if (promoterId) {
      mixpanel.track("Webapp: Viewed Promoter Link", {
        event_id: eventId,
        pCode_id: promoterId,
        event_type: "paid",
      });
    } else {
      mixpanel.track("Webapp: Viewed Event", {
        event_id: eventId,
        event_type: isEventTicketsPaid(event) ? "paid" : "free",
        host_id: event.createdBy,
      });
    }
  }, [
    dispatch,
    event,
    eventId,
    isUserRCA,
    mixpanel,
    pCodeId,
    promoterId,
    ticketId,
  ]);

  // Handles the initial loading and refetch event attempt
  // Handles the loading of event related data (event capacity, link views, fetch event form questions)
  useEffect(() => {
    (async () => {
      if (!event) {
        if (isErrorEventData) {
          // attempt a refetch of the event and ticket data. Host data will retry if event gets populated
          dispatch(DataLoaders.event(eventId));
          if (ticketId) {
            dispatch(
              DataLoaders.ticket({ eventId: eventId, itemId: ticketId })
            );
          }

          // mixpanel.track("Webapp: Refetch Attempt For Error Loading Event", {
          //   event_id: eventId,
          //   tix_id: ticketId,
          // });
          // let new data attempt to load before it sets loading to false
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        }
        return;
      }

      // track the open linkId
      if (linkId) {
        dispatch(accountActions.setLinkTrackerId(linkId));
        await updateLinkTrackerOpens(eventId, event.createdBy, linkId);
      } else {
        dispatch(accountActions.setLinkTrackerId(""));
      }

      // track for total views of event (w/out linkId)
      await updateTotalEventLinkViews(eventId, event.createdBy);

      // redirect if it's an external event and phone number collection is off
      if (
        event &&
        (isExternalLinkNoData(event.eventType) ||
          isExternalEventbrite(event.eventType))
      ) {
        window.location.replace(event.externalLink);
        return;
      }

      const [formQuestions, atCapacity] = await Promise.all([
        getEventFormQuestions(event.createdBy, event.formQuestions),
        eventAtCapacity(event, visibleTickets),
        getAddress(),
      ]);
      setEventFormQuestions(formQuestions);
      setIsEventAtCapacity(atCapacity);
      setLoading(false);
    })();
  }, [
    customTicketToRedeem,
    dispatch,
    event,
    eventId,
    getAddress,
    isErrorEventData,
    linkId,
    ticketId,
    visibleTickets,
  ]);

  if (isLoadingPage) {
    return <LoadingScreen isLoadingPage={isLoadingPage} />;
  }
  if (
    isErrorEventData ||
    isErrorTicketData ||
    isErrorHostData ||
    !event ||
    !host
  ) {
    return <LoadingScreen error />;
  }

  // External Event Links
  if (isEventExternalLink(event.eventType)) {
    return (
      <FullEventExternal
        event={event}
        host={host}
        address={address}
        eventFormQuestions={eventFormQuestions}
      />
    );
  }

  if (isBrowser) {
    return (
      <FullEventDesktop
        event={event}
        cohostsList={cohostsData}
        host={host}
        posts={[]}
        address={address}
        tixId={ticketId}
        ticketData={redeemTicket}
        ticketToRedeem={customTicketToRedeem}
        promoCode={promoCode}
        promoterId={promoterId ?? ""}
        isEventAtCapacity={isEventAtCapacity}
        userReferrer={userReferrer ?? ""}
        submitted={submitted}
        setSubmitted={setSubmitted}
        selectedCustomTicket={selectedCustomTicket ?? visibleTickets[0]}
        setSelectedCustomTicket={setSelectedCustomTicket}
        eventFormQuestions={eventFormQuestions}
      />
    );
  } else {
    return (
      <FullEvent
        event={event}
        cohostsList={cohostsData}
        host={host}
        posts={[]}
        address={address}
        tixId={ticketId}
        ticketData={redeemTicket}
        ticketToRedeem={customTicketToRedeem}
        promoCode={promoCode}
        promoterId={promoterId ?? ""}
        isEventAtCapacity={isEventAtCapacity}
        userReferrer={userReferrer ?? ""}
        submitted={submitted}
        setSubmitted={setSubmitted}
        selectedCustomTicket={selectedCustomTicket ?? visibleTickets[0]}
        setSelectedCustomTicket={setSelectedCustomTicket}
        eventFormQuestions={eventFormQuestions}
      />
    );
  }
});

export default FullEventDecider;
