import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import "../../css/GlobalStyles.css";
import { ThemeStyle } from "../../hooks/useTheme";
import { Colors } from "../../utils/colors";

type CreateEventSectionHeaderProps = {
  title: string;
  icon?: JSX.Element;
  description?: string;
  isMinimized: boolean;
  setIsMinimized: (isMinimized: boolean) => void;
  hideDropdownArrow?: boolean;
  theme?: ThemeStyle;
};

const CreateEventSectionHeader = (props: CreateEventSectionHeaderProps) => {
  const {
    title,
    description,
    isMinimized,
    setIsMinimized,
    hideDropdownArrow,
    icon,
    theme,
  } = props;

  return (
    <div className="AlignedRowSpaced">
      <div>
        <div className="AlignedRow">
          <h2 className="AboutSubtitle" style={theme?.PrimaryText}>
            {title}
          </h2>
          {icon}
        </div>
        {description ? (
          <span
            style={{
              color: theme ? theme.SubText.color : Colors.GRAY1,
              fontSize: 12,
            }}
          >
            {description}
          </span>
        ) : null}
      </div>
      {!hideDropdownArrow ? (
        <>
          {isMinimized ? (
            <IoIosArrowDown
              onClick={() => setIsMinimized(!isMinimized)}
              style={{ cursor: "pointer" }}
              size={24}
              color={Colors.GRAY2}
            />
          ) : (
            <IoIosArrowUp
              onClick={() => setIsMinimized(!isMinimized)}
              style={{ cursor: "pointer" }}
              size={24}
              color={Colors.GRAY2}
            />
          )}
        </>
      ) : null}
    </div>
  );
};

export default CreateEventSectionHeader;
