import { memo } from "react";
import { InitialsPic } from "../InitialsPic";
import { useTheme } from "../../hooks/useTheme";
import { handleLinkPressed } from "@markit/common.utils";
import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import { AccountData } from "@markit/common.types";

type FullEventHostsProps = {
  final?: boolean;
  isProfilePage?: boolean;
  host: AccountData | undefined;
  hostAndCohosts: AccountData[];
};

const FullEventHosts = memo(function FullEventHostsFn(
  props: FullEventHostsProps
) {
  const { final, isProfilePage, host, hostAndCohosts } = props;

  const { theme } = useTheme();

  return (
    <>
      {host && (
        <div className="Guests" style={{ paddingInline: final ? 14 : 0 }}>
          {hostAndCohosts.map((guest, index) => (
            <div key={index} className="GuestLine">
              <a href={`/u/${guest.username}`} className="HostCohostListLink">
                <div className="GuestInfo">
                  {guest.profilePicURL && guest.profilePicURL !== "" ? (
                    <img
                      className="GuestPic"
                      src={guest.profilePicURL}
                      alt="guest profile pic"
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    <div
                      className="GuestPic InitialsPic"
                      style={{ cursor: "pointer" }}
                    >
                      <InitialsPic fullName={guest.fullName} />
                    </div>
                  )}
                  <div>
                    <div
                      style={{
                        ...theme.PrimaryText,
                        fontSize: final ? 14 : 16,
                        fontWeight: "500",
                        cursor: "pointer",
                        marginBottom: final ? 2 : 0,
                      }}
                    >
                      {guest.fullName}
                    </div>
                    <div
                      className="HostCohostsHeader"
                      style={{ fontSize: final ? 12 : 13 }}
                    >
                      {isProfilePage
                        ? `@${guest.username}`
                        : index === 0
                        ? "Host"
                        : "Cohost"}
                    </div>
                  </div>
                </div>
              </a>

              <div className="AlignedRow" style={{ gap: "7px" }}>
                {guest.instagram && (
                  <div
                    className="ProfileBrowserSocialMediaIcon"
                    onClick={() =>
                      window.open(
                        handleLinkPressed("instagram", guest.instagram),
                        "_blank"
                      )
                    }
                  >
                    <Icon
                      icon="mdi:instagram"
                      height={final ? 20 : 22}
                      style={{ color: final ? Colors.GRAY2 : Colors.GRAY1 }}
                    />
                  </div>
                )}
                {guest.twitter && (
                  <div
                    className="ProfileBrowserSocialMediaIcon"
                    onClick={() =>
                      window.open(
                        handleLinkPressed("twitter", guest.twitter),
                        "_blank"
                      )
                    }
                  >
                    <Icon
                      icon="ri:twitter-x-fill"
                      height={final ? 20 : 22}
                      style={{
                        paddingBottom: 1.5,
                        color: final ? Colors.GRAY2 : Colors.GRAY1,
                      }}
                    />
                  </div>
                )}
                {guest.linkedin && (
                  <div
                    className="ProfileBrowserSocialMediaIcon"
                    onClick={() =>
                      window.open(
                        handleLinkPressed("linkedin", guest.linkedin),
                        "_blank"
                      )
                    }
                  >
                    <Icon
                      icon="ri:linkedin-fill"
                      height={final ? 20 : 22}
                      style={{
                        paddingBottom: 1.5,
                        color: final ? Colors.GRAY2 : Colors.GRAY1,
                      }}
                    />
                  </div>
                )}
                {guest.tiktok && (
                  <div
                    className="ProfileBrowserSocialMediaIcon"
                    onClick={() =>
                      window.open(
                        handleLinkPressed("tiktok", guest.tiktok),
                        "_blank"
                      )
                    }
                  >
                    <Icon
                      icon="ic:baseline-tiktok"
                      height={final ? 20 : 22}
                      style={{
                        paddingBottom: 1,
                        color: final ? Colors.GRAY2 : Colors.GRAY1,
                      }}
                    />
                  </div>
                )}
                {guest.spotify && (
                  <div
                    className="ProfileBrowserSocialMediaIcon"
                    onClick={() =>
                      window.open(
                        handleLinkPressed("spotify", guest.spotify),
                        "_blank"
                      )
                    }
                  >
                    <Icon
                      icon="mdi:spotify"
                      height={final ? 20 : 22}
                      style={{ color: final ? Colors.GRAY2 : Colors.GRAY1 }}
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
});

export default FullEventHosts;
