import {
  AccountData,
  Conversation,
  MemberIndicator,
} from "@markit/common.types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Query,
  getCountFromServer,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import { AppState, AppThunk } from "../store";
import { API_BASE } from "../../API";
import {
  Batch,
  getConversationRef,
  getUserConversationRef,
  getUserConversationsRef,
  getUserData,
} from "../../utils/FirebaseUtils";

export type ConversationState = {
  userConversations: Conversation[];
};

export const initialConversationState: ConversationState = {
  userConversations: [],
};

// TODO (PETER): Currently, userConversations redux state is not used for anything, since the logic for fetching conversations was moved to a useLoad hook.
// In the future, modify the logic to fetch from redux instead of firebase when fetching conversations in the useLoad hook logic
export const conversationSlice = createSlice({
  name: "conversation",
  initialState: initialConversationState,
  reducers: {
    addMultipleConversations: (
      state,
      action: PayloadAction<Conversation[]>
    ) => {
      state.userConversations = Array.from(
        [...state.userConversations, ...action.payload]
          .reduce((map, item) => {
            map.set(item.conversationSid, item);
            return map;
          }, new Map())
          .values()
      );
    },
    removeConversation: (state, action: PayloadAction<string>) => {
      state.userConversations = state.userConversations.filter(
        (c) => c.conversationSid !== action.payload
      );
    },
    removeAllConversations: (state) => {
      state.userConversations = [];
    },
    updateReadConversationIndicator: (
      state,
      action: PayloadAction<{
        conversation: Conversation;
        uid: string;
        markRead: boolean;
      }>
    ) => {
      // for all conversations
      const index = state.userConversations.findIndex(
        (c) => c.conversationSid === action.payload.conversation.conversationSid
      );
      if (index !== -1) {
        const newChatIndicator: MemberIndicator[] = state.userConversations[
          index
        ].chatIndicator.map((m: { uid: string; indicator: boolean }) => {
          if (m.uid === action.payload.uid) {
            return { uid: m.uid, indicator: action.payload.markRead };
          } else {
            return m;
          }
        });
        state.userConversations[index].chatIndicator = newChatIndicator;
      }
    },
  },
});

export const conversationActions = conversationSlice.actions;
export const conversationReducer = conversationSlice.reducer;

// Mark the message that was previously unread as read if markRead is true
// Mark the message that was previously read as unread if markRead is false
export const updateChatConversationIndicator =
  (conversationSid: string, uid: string, markRead: boolean): AppThunk =>
  async (dispatch) => {
    const batch = new Batch("Error updating chat conversation indicator");
    const conversationRef = getConversationRef(conversationSid);
    const conversationData = (await getDoc(conversationRef)).data();
    if (conversationData) {
      const newChatIndicator: MemberIndicator[] =
        conversationData.chatIndicator.map(
          (m: { uid: string; indicator: boolean }) => {
            if (m.uid === uid) {
              return { uid: m.uid, indicator: markRead };
            } else {
              return m;
            }
          }
        );

      const userConversationRef = getUserConversationRef(
        conversationData.creatorUid,
        conversationSid
      );
      batch.update(userConversationRef, { chatIndicator: newChatIndicator });
      // Update read indicator in Firebase
      dispatch(
        conversationActions.updateReadConversationIndicator({
          conversation: conversationData,
          uid: uid,
          markRead: markRead,
        })
      );

      for (let i = 0; i < conversationData.participantUids.length; i++) {
        const otherUserConversationRef = getUserConversationRef(
          conversationData.participantUids[i],
          conversationSid
        );
        batch.update(otherUserConversationRef, {
          chatIndicator: newChatIndicator,
        });
      }

      batch.update(conversationRef, { chatIndicator: newChatIndicator });
    }

    try {
      await batch.commit();
    } catch (e: any) {
      alert("Error updating chat indicator: " + e.message);
    }
  };

/**
 * Gets the userData of the participants from all of a user's conversations.
 * This only works while the participantUids field only contains one participant
 */
export const getConversationParticipantsData = async (
  userConversations: Conversation[]
): Promise<AccountData[]> => {
  const participantsData = await Promise.all(
    userConversations.map(async (conversation) => {
      const userData = await getUserData(conversation.participantUids[0]);
      if (userData) {
        return userData;
      }
    })
  );
  const definedParticipants: AccountData[] = [];
  for (let i = 0; i < participantsData.length; i++) {
    if (participantsData[i] as AccountData) {
      definedParticipants.push(participantsData[i] as AccountData);
    }
  }
  return definedParticipants;
};

// set the conversationIdToDelete to the Conversation SID found on Twilio
// Example: CH099996154e3a4a77b9e004a5695bba21
export const adminDeleteConversation =
  (conversationId: string, userId: string): AppThunk =>
  async (dispatch) => {
    console.log("Deleting conversation");
    await fetch(API_BASE + "creatorText/deleteTwilioConversation", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        conversationIdToDelete: conversationId,
        userId: userId,
      }),
    }).then(() => {
      console.log("Deleted conversation successfully!");
      dispatch(conversationActions.removeConversation(conversationId));
    });
  };

// Deletes the User from Twilio Conversation (for deleting accounts)
export const deleteTwilioConversationUser = async (userId: string) => {
  await fetch(API_BASE + "creatorText/deleteTwilioConversationUser", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      userId: userId,
    }),
  });
};

export const getUnreadConversationsQuery = async (
  userId: string
): Promise<Query<Conversation>> => {
  const userConversationsRef = getUserConversationsRef(userId);
  const query_ = query(
    userConversationsRef,
    where("creatorUid", "==", userId),
    where("chatIndicator", "array-contains", { indicator: true, uid: userId })
  );

  return query_;
};

export const fetchNumTotalConversations = async (
  userId: string
): Promise<number> => {
  const userConversationsRef = getUserConversationsRef(userId);
  const query_ = query(
    userConversationsRef,
    where("recentTimestamp", "!=", "")
  );
  const snapshot = await getCountFromServer(query_);

  return snapshot.data().count;
};

export const getConversationState = (state: AppState) => state;
