import { Icon } from "@iconify/react";
import React, { memo } from "react";
import { Colors } from "../../utils/colors";
import { ThemeStyle } from "../../hooks/useTheme";

type BackButtonProps = {
  onPress: () => void;
  iconName?: string;
  theme?: ThemeStyle;
};

// Used as header back button on containers and (in future refactor for side modals)
export const BackButton = memo(function BackButtonFn(props: BackButtonProps) {
  const { onPress, iconName, theme } = props;

  return (
    <div className="LargePopupPanelClose" onClick={onPress}>
      <Icon
        icon={iconName ?? "ion:chevron-back"}
        height={24}
        style={{ color: theme ? theme.PrimaryText.color : Colors.GRAY3 }}
      />
    </div>
  );
});
