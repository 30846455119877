import { Icon } from "@iconify/react";
import MarkitLogoBlack from "../../assets/MarkitLogoBlack.png";
import MarkitLogoWhite from "../../assets/MarkitLogoWhite.png";
import { memo } from "react";
import { MARKIT_ATTENDEE_FAQ } from "@markit/common.utils";
import { LightTheme, useTheme } from "../../hooks/useTheme";

/**
 * To be used with the phone number verification, especially in the case of the external event link flow
 */
const MarkitSecuredContainer = () => {
  const { theme } = useTheme();

  return (
    <div className="Centering" style={{ gap: 7, ...theme.PrimaryText }}>
      <Icon
        icon="ion:lock-closed-outline"
        height={14}
        color={theme.PrimaryText.color}
      />
      <span style={{ fontSize: 12 }}>Secured by </span>
      <img
        style={{ height: 14, width: "auto" }}
        alt="MarkitAppIconBlack"
        src={theme === LightTheme ? MarkitLogoBlack : MarkitLogoWhite}
      />
      <span style={{ fontSize: 14, fontWeight: "600" }}>markit</span>
      <span
        style={{
          textDecorationLine: "underline",
          fontWeight: "500",
          fontSize: 12,
        }}
        onClick={() => window.open(MARKIT_ATTENDEE_FAQ)}
      >
        Learn more
      </span>
    </div>
  );
};

export default memo(MarkitSecuredContainer);
