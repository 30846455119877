import "../../css/App.css";
import "../../css/FullEvent/FullEvent.css";
import "../../css/GlobalStyles.css";
import { memo, useMemo, useState, useRef, useCallback } from "react";
import {
  Event,
  AccountData,
  DiscussionPost,
  PromoCode,
  TicketV2,
  CustomTicketV2,
  SavedFormQuestion,
} from "@markit/common.types";
import FullEventInfo from "../../components/FullEvent/FullEventInfo";
import FullEventHeader from "../../components/FullEvent/FullEventHeader";
import BuyBanner from "../../components/FullEventSubComponents/BuyBanner";
import TopHeader from "../../components/TopHeader";
import CheckoutContainer from "../../components/FullEventSubComponents/CheckoutContainer";
import { useOnMount } from "../../utils/useOnMount";
import { m, LazyMotion, domAnimation } from "framer-motion";
import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import { onShareLink } from "../../utils/shareLink";
import shareOutline from "@iconify/icons-mdi/share-outline";
import { useTheme } from "../../hooks/useTheme";
import { getVisibleCustomTickets } from "@markit/common.utils";

type FullEventProps = {
  event: Event;
  host: AccountData;
  cohostsList: AccountData[];
  posts: DiscussionPost[];
  address: string;
  tixId: string;
  ticketData: TicketV2 | undefined;
  ticketToRedeem: CustomTicketV2 | undefined;
  promoterId: string;
  promoCode?: PromoCode;
  isEventAtCapacity: boolean;
  setSubmitted: (submitted: boolean) => void;
  userReferrer: string;
  submitted: boolean;
  selectedCustomTicket: CustomTicketV2;
  setSelectedCustomTicket: (selectedTicket: CustomTicketV2 | undefined) => void;
  eventFormQuestions: SavedFormQuestion[];
};

const FullEvent = memo(function FullEventFn(props: FullEventProps) {
  const {
    event,
    host,
    cohostsList,
    posts,
    address,
    tixId,
    ticketData,
    ticketToRedeem,
    promoterId, // promoterId
    promoCode, // promo
    isEventAtCapacity,
    userReferrer,
    selectedCustomTicket,
    setSelectedCustomTicket,
    submitted,
    setSubmitted,
    eventFormQuestions,
  } = props;

  const [showExpandedPhoto, setShowExpandedPhoto] = useState(false);
  const [showCheckoutForm, setShowCheckoutForm] = useState(false);

  const refContainer = useRef<HTMLDivElement>(null);

  const { theme } = useTheme();

  useOnMount(() => {
    // return back to main event screen if the ticket has already been redeemed or scanned
    if (ticketData && (ticketData.redeemedBy !== "" || ticketData.scanned)) {
      alert(
        "This ticket has already been used. You will need to purchase a ticket."
      );
      window.location.replace(`/e/${event.id}`);
      return;
    }
  });

  const visibleTickets = useMemo(
    () => getVisibleCustomTickets(event.customTickets, ticketToRedeem?.id),
    [event.customTickets, ticketToRedeem?.id]
  );

  const lowestTicketPrice = useMemo(() => {
    let lowestTicket: number = visibleTickets[0].price;
    if (visibleTickets.length === 1) {
      return lowestTicket / 100;
    }
    // eslint-disable-next-line array-callback-return
    visibleTickets.map((customTicket) => {
      if (customTicket.price < lowestTicket) {
        lowestTicket = customTicket.price;
      }
    });
    return lowestTicket / 100;
  }, [visibleTickets]);

  const highestTicketPrice = useMemo(() => {
    let highestTicket: number = visibleTickets[0].price;
    if (visibleTickets.length === 1) {
      return highestTicket / 100;
    }
    // eslint-disable-next-line array-callback-return
    visibleTickets.map((customTicket) => {
      if (customTicket.price > highestTicket) {
        highestTicket = customTicket.price;
      }
    });
    return highestTicket / 100;
  }, [visibleTickets]);

  const onShareEvent = useCallback(async () => {
    await onShareLink(
      "Share this event with your friends!",
      "https://markitai.com/e/" + event.id
    );
  }, [event.id]);

  return (
    <div style={{ ...theme.SubComponentBG }}>
      {showExpandedPhoto && (
        <div
          className="ExpandedPhotoMobileContainer"
          style={theme.TransparentBG}
        >
          <div className="AlignedRowSpaced" style={{ padding: 10 }}>
            <div
              className="ExpandedPhotoShareButton"
              style={theme.SignInBG}
              onClick={onShareEvent}
            >
              <Icon
                icon={shareOutline}
                height={20}
                style={{ paddingRight: 5, color: Colors.GRAY3 }}
              />
              <span
                style={{
                  fontWeight: "500",
                  color: Colors.GRAY3,
                  paddingRight: "4px",
                }}
              >
                Share
              </span>
            </div>
            <Icon
              icon="gridicons:cross-circle"
              height={30}
              color={Colors.GRAY1}
              onClick={() => setShowExpandedPhoto(false)}
              style={{ cursor: "pointer" }}
            />
          </div>
          <LazyMotion features={domAnimation}>
            <m.div
              initial={{ y: "20px", opacity: 0, filter: "blur(2px)" }}
              animate={{ y: "0px", opacity: 1, filter: "blur(0px)" }}
              exit={{ y: "20px", opacity: 0, filter: "blur(2px)" }}
              transition={{ duration: 0.3, ease: "easeOut" }}
              className="HideScrollbar"
            >
              <div style={{ marginInline: 10 }}>
                <img
                  className="ExpandedPhotoMobile"
                  src={event.photoURL}
                  alt={"event cover"}
                />
              </div>
            </m.div>
          </LazyMotion>
        </div>
      )}
      {showCheckoutForm ? (
        <LazyMotion features={domAnimation}>
          <m.div
            initial={{ y: "20px", opacity: 0, filter: "blur(2px)" }}
            animate={{ y: "0px", opacity: 1, filter: "blur(0px)" }}
            exit={{ y: "20px", opacity: 0, filter: "blur(2px)" }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            className="HideScrollbar"
          >
            <CheckoutContainer
              event={event}
              host={host}
              tixId={tixId}
              ticketData={ticketData}
              ticketToRedeem={ticketToRedeem}
              promoterId={promoterId}
              promoCode={promoCode}
              userReferrer={userReferrer}
              submitted={submitted}
              setSubmitted={setSubmitted}
              selectedCustomTicket={selectedCustomTicket ?? visibleTickets[0]}
              setSelectedCustomTicket={setSelectedCustomTicket}
              eventFormQuestions={eventFormQuestions}
              isEventAtCapacity={isEventAtCapacity}
              setShowCheckoutForm={setShowCheckoutForm}
            />
          </m.div>
        </LazyMotion>
      ) : null}
      <div
        style={showCheckoutForm ? { display: "none" } : { display: "block" }}
      >
        <div ref={refContainer} style={theme.PrimaryBG}>
          {/* ENTRY POINT */}
          <div className="Wrapper" style={theme.GradientBG}>
            <TopHeader showRCA />
            <div style={{ marginInline: "14px" }}>
              <img
                className="ImageEvent"
                src={event.photoURL}
                alt={"event cover"}
                onClick={() => setShowExpandedPhoto(true)}
              />
            </div>
            <FullEventHeader
              event={event}
              host={host}
              cohostsList={cohostsList ?? []}
              confirmationScreen={false}
            />
            <FullEventInfo
              event={event}
              host={host}
              cohostsList={cohostsList ?? []}
              posts={posts}
              address={address}
              visibleTickets={visibleTickets}
            />
          </div>
        </div>
      </div>
      {!showCheckoutForm ? (
        <BuyBanner
          event={event}
          tixId={tixId}
          ticketData={ticketData}
          promoCode={promoCode}
          lowestTicketPrice={lowestTicketPrice}
          highestTicketPrice={highestTicketPrice}
          isEventAtCapacity={isEventAtCapacity}
          setShowCheckoutForm={setShowCheckoutForm}
          visibleTickets={visibleTickets}
        />
      ) : null}
    </div>
  );
});

export default FullEvent;
