import { useEffect, useState } from "react";
import {
  getTicketsRef,
  getUserEventLinkTrackersRef,
} from "../utils/FirebaseUtils";
import { useOnMount } from "../utils/useOnMount";
import { onSnapshot, query, where } from "../firebase";
import {
  DefaultLinkTrackerAttachmentId,
  DefaultLinkTrackerName,
  OrganizerType,
  RequestStatus,
} from "@markit/common.types";

type useLiveUpdatingEventAnalyticsProps = {
  eventId: string;
  userId?: string; // for total link views
};

export const useLiveUpdatingEventAnalytics = (
  props: useLiveUpdatingEventAnalyticsProps
) => {
  const { eventId, userId } = props;
  const [liveTotalGuests, setLiveTotalGuests] = useState<number>(0);
  const [uniqueGuestsUids, setUniqueGuestsUids] = useState<string[]>([]);
  const [liveTicketsScanned, setLiveTicketsScanned] = useState<number>(0);
  const [liveTotalRequested, setLiveTotalRequested] = useState<number>(0);
  const [liveTotalOrganizers, setLiveTotalOrganizers] = useState<number>(0);
  const [liveTotalLinkViews, setLiveTotalLinkViews] = useState(0);

  useOnMount(() => {
    (async () => {
      if (!eventId) {
        return;
      }
      // total attendees
      const ticketAttendeesRef = getTicketsRef(eventId);
      const totalAttendeesQuery = query(
        ticketAttendeesRef,
        where("uid", "!=", ""),
        where("requestStatus", "==", RequestStatus.ACCEPTED),
        where("role.type", "==", OrganizerType.ATTENDEE)
      );
      onSnapshot(totalAttendeesQuery, (snapshot) => {
        const documentCount = snapshot.size;
        setLiveTotalGuests(documentCount);
      });

      // total unique attendees
      const uniqueAttendeesRef = getTicketsRef(eventId);
      const uniqueAttendeesQuery = query(
        uniqueAttendeesRef,
        where("redeemedBy", "!=", ""),
        where("requestStatus", "==", RequestStatus.ACCEPTED),
        where("role.type", "==", OrganizerType.ATTENDEE)
      );

      onSnapshot(uniqueAttendeesQuery, (snapshot) => {
        if (snapshot.docs.length === 0) {
          setUniqueGuestsUids([]);
        } else {
          setUniqueGuestsUids(snapshot.docs.map((doc) => doc.data().uid));
        }
      });

      // tickets scanned
      const ticketsRef = getTicketsRef(eventId);
      const ticketsScannedQuery = query(
        ticketsRef,
        where("scanned", "==", true)
      );
      onSnapshot(ticketsScannedQuery, (snapshot) => {
        const documentCount = snapshot.size;
        setLiveTicketsScanned(documentCount);
      });

      // total requested
      const requestedAttendeesRef = getTicketsRef(eventId);
      const totalRequestedQuery = query(
        requestedAttendeesRef,
        where("uid", "!=", ""),
        where("requestStatus", "==", RequestStatus.PENDING),
        where("role.type", "==", OrganizerType.ATTENDEE)
      );
      onSnapshot(totalRequestedQuery, (snapshot) => {
        const documentCount = snapshot.size;
        setLiveTotalRequested(documentCount);
      });

      // total organizers
      const organizersRef = getTicketsRef(eventId);
      const totalOrganizersQuery = query(
        organizersRef,
        where("redeemedBy", "!=", ""),
        where("customTicketId", "==", "")
      );
      onSnapshot(totalOrganizersQuery, (snapshot) => {
        const documentCount = snapshot.size;
        setLiveTotalOrganizers(documentCount);
      });
    })();
  });

  useEffect(() => {
    if (!eventId) {
      return;
    }
    // total link views
    if (userId) {
      const eventLinkTrackersRef = getUserEventLinkTrackersRef(userId, eventId);
      const totalLinkViewsQuery = query(
        eventLinkTrackersRef,
        where("alias", "==", DefaultLinkTrackerName.DEFAULT_EVENT_LINK_TRACKER)
      );
      onSnapshot(totalLinkViewsQuery, (snapshot) => {
        const eventLinkTracker = snapshot.docs.map((doc) => doc.data())[0];
        if (eventLinkTracker) {
          setLiveTotalLinkViews(
            eventLinkTracker.trackingOverviews[DefaultLinkTrackerAttachmentId]
              .numOpens
          );
        }
      });
    }
  }, [eventId, userId]);

  return {
    liveTotalGuests,
    uniqueGuestsUids,
    liveTicketsScanned,
    liveTotalRequested,
    liveTotalOrganizers,
    liveTotalLinkViews,
  };
};
