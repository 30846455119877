import React, { useCallback, useMemo, useState } from "react";
import "../../css/CreateEvent.css";
import {
  CustomTicketV2,
  Event,
  TicketOptionName,
  TicketType,
} from "@markit/common.types";
import { Icon } from "@iconify/react";
import { LightTheme, useTheme } from "../../hooks/useTheme";
import { Colors } from "../../utils/colors";
import { TextField } from "@mui/material";
import SwitchToggleItem from "../SwitchToggleItem";
import PriceTextField from "../PriceTextField";
import { useOnMount } from "../../utils/useOnMount";
import {
  getTotalTicketsDistributedInGroup,
  getTotalTicketsSoldInGroup,
} from "../../utils/eventUtils/eventUtils";
import QuantityTextField from "../QuantityTextField";
import { makeDefaultTicket } from "../../utils/makeEvent";
import { validateCustomTickets } from "@markit/common.utils";
import RectangleButton from "../Buttons/RectangleButton";
import AlertButtonWrapper from "../Containers/AlertButtonWrapper";

interface CreateEventCustomTicketOptionProps {
  eventSettings: Event;
  updateEventSettings: (update: Partial<Event>) => void;
  ticketOption: number;
  onExit: (isAdding: boolean) => void;
}

const CreateEventCustomTicketOption = (
  props: CreateEventCustomTicketOptionProps
) => {
  const { eventSettings, updateEventSettings, ticketOption, onExit } = props;
  const { theme } = useTheme(eventSettings.theme);
  const [disableTicketGroup, setDisableTicketGroup] = useState(false);
  const [alertText, setAlertText] = useState("");

  const ticket = useMemo(
    () =>
      ticketOption !== -1
        ? eventSettings.customTickets[ticketOption]
        : undefined,
    [eventSettings.customTickets, ticketOption]
  );

  const [label, setLabel] = useState<string>(
    ticket ? ticket.label : TicketOptionName.FREE
  );
  const [type, setType] = useState<TicketType>(
    ticket ? ticket.type : TicketType.FREE
  );
  const [price, setPrice] = useState<number>(ticket ? ticket.price : 0);
  const [quantityAvailable, setQuantityAvailable] = useState<number>(
    ticket ? ticket.quantityAvailable : 0
  );
  const [minQuantity, setMinQuantity] = useState<number>(
    ticket ? ticket.minQuantity : 1
  );
  const [maxQuantity, setMaxQuantity] = useState<number>(
    ticket ? ticket.maxQuantity : 8
  );
  const [hideTicket, setHideTicket] = useState(
    ticket ? ticket.hideTicket : false
  );

  const styles = {
    textLabel: {
      fontSize: 14,
      fontWeight: 600,
    },
  };

  const labelName = useMemo(
    () =>
      type === TicketType.PAID
        ? "purchases"
        : type === TicketType.REQUEST
        ? "requests"
        : "registrations",
    [type]
  );

  const addCustomTicket = useCallback(() => {
    const defaultTicket = makeDefaultTicket(
      label,
      price,
      type,
      quantityAvailable,
      minQuantity,
      maxQuantity,
      hideTicket
    );
    const existingTickets = [...eventSettings.customTickets, defaultTicket];
    const validate = validateCustomTickets(existingTickets);
    if (validate !== "") {
      setAlertText(validate);
      return;
    }

    updateEventSettings({
      customTickets: existingTickets,
    });
    onExit(true);
  }, [
    eventSettings.customTickets,
    label,
    maxQuantity,
    minQuantity,
    onExit,
    price,
    quantityAvailable,
    hideTicket,
    type,
    updateEventSettings,
  ]);

  const updateCustomTicket = useCallback(() => {
    const updatedItems = [...eventSettings.customTickets];
    updatedItems[ticketOption] = {
      ...updatedItems[ticketOption],
      type: type,
      price: price,
      label: label,
      quantityAvailable: quantityAvailable,
      minQuantity: minQuantity,
      maxQuantity: maxQuantity,
      hideTicket: hideTicket,
    };
    const validate = validateCustomTickets(updatedItems);
    if (validate !== "") {
      setAlertText(validate);
      return;
    }

    updateEventSettings({
      customTickets: updatedItems,
    });
    onExit(false);
  }, [
    eventSettings.customTickets,
    label,
    maxQuantity,
    minQuantity,
    onExit,
    price,
    quantityAvailable,
    hideTicket,
    ticketOption,
    type,
    updateEventSettings,
  ]);

  useOnMount(() => {
    (async () => {
      // check if the user cannot delete the specified ticket option
      if (ticketOption !== -1) {
        const totalTicketsSoldInGroup = await getTotalTicketsSoldInGroup(
          eventSettings.id,
          eventSettings.customTickets[ticketOption].id
        );
        const totalTicketsDistributedInGroup =
          await getTotalTicketsDistributedInGroup(
            eventSettings.id,
            eventSettings.customTickets[ticketOption].id
          );
        if (totalTicketsSoldInGroup + totalTicketsDistributedInGroup > 0) {
          setDisableTicketGroup(true);
        }
      }
    })();
  });

  const isDefaultTicketOptionName = useMemo(
    () =>
      label === TicketOptionName.FREE ||
      label === TicketOptionName.PAID ||
      label === TicketOptionName.REQUEST ||
      label === "",
    [label]
  );

  const updateTicket = useCallback(
    (update: Partial<CustomTicketV2>, index: number) => {
      let newTicketGroups = [...eventSettings.customTickets];
      if (update.type !== undefined) {
        newTicketGroups = newTicketGroups.map((t, i) =>
          i === index ? { ...t, type: update.type! } : t
        );
        setType(update.type!);
      }
      if (update.label !== undefined) {
        newTicketGroups = newTicketGroups.map((elem) => {
          return { ...elem, label: update.label! };
        });
        setLabel(update.label!);
      }
      if (update.price !== undefined) {
        newTicketGroups = newTicketGroups.map((t, i) =>
          i === index ? { ...t, price: update.price! } : t
        );
        setPrice(update.price!);
      }
      if (update.quantityAvailable !== undefined) {
        newTicketGroups = newTicketGroups.map((t, i) =>
          i === index
            ? { ...t, quantityAvailable: update.quantityAvailable! }
            : t
        );
        setQuantityAvailable(update.quantityAvailable!);
      }
      if (update.minQuantity !== undefined) {
        newTicketGroups = newTicketGroups.map((t, i) =>
          i === index ? { ...t, minQuantity: update.minQuantity! } : t
        );
        setMinQuantity(update.minQuantity!);
      }
      if (update.maxQuantity !== undefined) {
        newTicketGroups = newTicketGroups.map((t, i) =>
          i === index ? { ...t, maxQuantity: update.maxQuantity! } : t
        );
        setMaxQuantity(update.maxQuantity!);
      }

      updateEventSettings({
        customTickets: newTicketGroups,
      });
    },
    [eventSettings.customTickets, updateEventSettings]
  );

  const checkmarkCircle = useCallback(
    (showCheck: boolean, disabled: boolean) => (
      <Icon
        icon={"ion:checkmark-circle"}
        height={19}
        style={{
          opacity: showCheck ? 1 : 0,
          color: disabled
            ? Colors.GRAY1
            : theme === LightTheme
            ? Colors.BLACK
            : Colors.WHITE,
          transition: "0.3s",
        }}
      />
    ),
    [theme]
  );

  return (
    <div>
      <TextField
        size="small"
        type="text"
        placeholder={"Customize Ticket Name"}
        inputProps={{ style: { ...theme.PrimaryText, borderRadius: "12px" } }}
        InputProps={{
          style: { borderRadius: "12px" },
        }}
        style={{
          width: "100%",
          borderRadius: "12px",
          ...theme.TertiaryBG,
        }}
        value={label}
        onChange={(e) => {
          setLabel(e.target.value);
        }}
      />
      <div
        style={{
          ...theme.TertiaryBG,
          padding: "10px",
          borderRadius: "12px",
          marginTop: "14px",
        }}
      >
        <div
          className={
            "AlignedRowSpaced TicketType " +
            (theme === LightTheme ? "TicketTypeLight " : "TicketTypeDark ") +
            (type === TicketType.FREE &&
              (theme === LightTheme
                ? "TicketTypeSelectedLight"
                : disableTicketGroup
                ? "TicketTypeSelectedDarkDisabled"
                : "TicketTypeSelectedDark"))
          }
          style={{
            pointerEvents: disableTicketGroup ? "none" : "all",
            borderWidth: disableTicketGroup ? 0 : 1,
          }}
          onClick={() => {
            setType(TicketType.FREE);
            setPrice(0);
            setLabel(isDefaultTicketOptionName ? TicketOptionName.FREE : label);
          }}
        >
          <div style={{ cursor: "pointer" }}>
            <h4
              className="AboutSubtitle"
              style={{
                color: disableTicketGroup
                  ? Colors.GRAY1
                  : theme.PrimaryText.color,
              }}
            >
              Free
            </h4>
          </div>
          {checkmarkCircle(type === TicketType.FREE, disableTicketGroup)}
        </div>
        <div className="AlignedRowSpaced" style={{ marginTop: 14 }}>
          <div
            className={
              "AlignedRowSpacedSelect TicketType " +
              (theme === LightTheme ? "TicketTypeLight " : "TicketTypeDark ") +
              (type === TicketType.PAID &&
                (theme === LightTheme
                  ? "TicketTypeSelectedLight"
                  : disableTicketGroup
                  ? "TicketTypeSelectedDarkDisabled"
                  : "TicketTypeSelectedDark"))
            }
            style={{
              width: "100%",
              pointerEvents: disableTicketGroup ? "none" : "all",
              borderWidth: disableTicketGroup ? 0 : 1,
            }}
            onClick={() => {
              setType(TicketType.PAID);
              setPrice(500);
              setLabel(
                isDefaultTicketOptionName ? TicketOptionName.PAID : label
              );
            }}
          >
            <div style={{ cursor: "pointer" }}>
              <h4
                className="AboutSubtitle"
                style={{
                  color: disableTicketGroup
                    ? Colors.GRAY1
                    : theme.PrimaryText.color,
                }}
              >
                Paid
              </h4>
            </div>
            {checkmarkCircle(type === TicketType.PAID, disableTicketGroup)}
          </div>
        </div>
        <div
          className={
            "AlignedRowSpaced TicketType " +
            (theme === LightTheme
              ? "TicketTypeLight "
              : disableTicketGroup
              ? "TicketTypeDarkDisabled "
              : "TicketTypeDark ") +
            (type === TicketType.REQUEST &&
              (theme === LightTheme
                ? "TicketTypeSelectedLight"
                : disableTicketGroup
                ? "TicketTypeSelectedDarkDisabled"
                : "TicketTypeSelectedDark"))
          }
          style={{
            marginTop: 14,
            pointerEvents: disableTicketGroup ? "none" : "all",
            borderWidth: disableTicketGroup ? 0 : 1,
          }}
          onClick={() => {
            setType(TicketType.REQUEST);
            setPrice(0);
            setLabel(
              isDefaultTicketOptionName ? TicketOptionName.REQUEST : label
            );
          }}
        >
          <div style={{ cursor: "pointer" }}>
            <h4
              className="AboutSubtitle"
              style={{
                color: disableTicketGroup
                  ? Colors.GRAY1
                  : theme.PrimaryText.color,
              }}
            >
              Request
            </h4>
            <h4
              style={{
                fontWeight: "400",
                color: Colors.GRAY2,
                fontSize: 12,
                paddingTop: "5px",
              }}
            >
              Attendees must request approval
            </h4>
          </div>
          {checkmarkCircle(type === TicketType.REQUEST, disableTicketGroup)}
        </div>
      </div>
      {type === TicketType.PAID ? (
        <div className="AlignedRowSpaced" style={{ marginTop: "14px" }}>
          <div style={{ ...theme.PrimaryText, fontWeight: "600" }}>
            Set ticket price
          </div>
          <PriceTextField
            theme={theme}
            price={price}
            onChange={(newPrice: number) => {
              setPrice(newPrice);
            }}
            disabled={type !== TicketType.PAID}
          />
        </div>
      ) : null}
      <div style={{ marginTop: 14 }}>
        <SwitchToggleItem
          title={
            type === TicketType.REQUEST
              ? "Set Request Capacity"
              : "Set Capacity"
          }
          description={`Auto-end ${labelName} for this ticket type when the capacity is reached. ${
            type === TicketType.REQUEST
              ? "You control how many you accept."
              : ""
          }`}
          toggleValue={quantityAvailable > 0}
          onChange={() => {
            setQuantityAvailable(quantityAvailable > 0 ? 0 : 10);
          }}
          theme={theme}
        />
      </div>
      {quantityAvailable > 0 ? (
        <div className="AlignedRowSpaced" style={{ marginTop: "14px" }}>
          <div style={{ ...theme.PrimaryText, fontWeight: "600" }}>
            {"Capacity"}
          </div>
          <div style={{ width: "35%" }}>
            <TextField
              size="small"
              type="number"
              placeholder={"100"}
              value={quantityAvailable}
              onChange={(e) => {
                if (e.target.value !== "") {
                  setQuantityAvailable(parseInt(e.target.value));
                }
                if (
                  parseInt(e.target.value) !== 0 &&
                  maxQuantity > parseInt(e.target.value)
                ) {
                  updateTicket(
                    { maxQuantity: parseInt(e.target.value) },
                    ticketOption
                  );
                }
              }}
              inputProps={{ min: 1, style: { ...theme.PrimaryText } }}
              InputProps={{ style: { borderRadius: "12px" } }}
              style={{
                width: "100%",
                borderRadius: "12px",
                ...theme.TertiaryBG,
              }}
            />
          </div>
        </div>
      ) : null}
      <div style={{ marginTop: 14 }}>
        <div style={{ ...theme.PrimaryText, ...styles.textLabel }}>
          {`Set min/max ${labelName} per person`}
        </div>
        <div
          style={{
            fontWeight: "400",
            color: Colors.GRAY1,
            fontSize: 12,
            paddingTop: 4,
            width: "90%",
          }}
        >
          {`This is the number of ${labelName} one account can place`}
        </div>
      </div>
      <div className="AlignedRow" style={{ marginTop: 14, gap: 14 }}>
        <div style={{ ...theme.PrimaryText, ...styles.textLabel }}>Min</div>
        <QuantityTextField
          quantity={minQuantity}
          placeholder="1"
          minimum={1}
          maximum={maxQuantity}
          onChange={(e: any) => {
            let minValue = e.target.value as unknown as number;
            if (parseInt(e.target.value) >= maxQuantity) {
              minValue = maxQuantity;
            }
            if (parseInt(e.target.value) <= 0) {
              minValue = 1;
            }
            updateTicket({ minQuantity: minValue }, ticketOption);
          }}
          theme={theme}
        />
        <div style={{ ...theme.PrimaryText, ...styles.textLabel }}>Max</div>
        <QuantityTextField
          quantity={maxQuantity}
          placeholder="8"
          minimum={minQuantity}
          maximum={24}
          onChange={(e: any) => {
            let maxValue = e.target.value as unknown as number;
            if (parseInt(e.target.value) < minQuantity) {
              maxValue = minQuantity;
            }
            if (parseInt(e.target.value) > 24) {
              maxValue = 24;
            }
            if (
              quantityAvailable !== 0 &&
              parseInt(e.target.value) > quantityAvailable
            ) {
              maxValue = quantityAvailable;
            }
            updateTicket({ maxQuantity: maxValue }, ticketOption);
          }}
          theme={theme}
        />
      </div>
      <div style={{ marginTop: 14 }}>
        <SwitchToggleItem
          title={"Hide Ticket Type"}
          description={"Hide this ticket type from attendees"}
          toggleValue={hideTicket}
          onChange={() => {
            setHideTicket(!hideTicket);
          }}
          theme={theme}
        />
      </div>
      <hr style={theme.DividerColor} />
      <AlertButtonWrapper
        buttonComp={
          <RectangleButton
            buttonLabel={ticketOption !== -1 ? "Save" : "Create Ticket Type"}
            onPress={() => {
              ticketOption === -1 ? addCustomTicket() : updateCustomTicket();
            }}
            altColor={theme === LightTheme ? Colors.BLACK : Colors.WHITE}
            altTextColor={theme === LightTheme ? Colors.WHITE : Colors.BLACK}
          />
        }
        alertTextHeader={alertText}
        clearAlert={() => setAlertText("")}
      />
    </div>
  );
};

export default CreateEventCustomTicketOption;
