import React, { memo } from "react";
import { useTheme } from "../../hooks/useTheme";
import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import CustomTextField from "../../components/CustomTextField";

type CheckoutEmailAccountNoticeProps = {
  email: string;
  logoutEmailOnPress: () => void;
};

export const CheckoutEmailAccountNotice = memo(
  function CheckoutEmailAccountNoticeFn(
    props: CheckoutEmailAccountNoticeProps
  ) {
    const { email, logoutEmailOnPress } = props;
    const { theme } = useTheme();

    return (
      <>
        <CustomTextField
          value={email}
          inputMode="email"
          placeholder="Email"
          borderRadius={12}
          onChange={(phone: any) => {}}
          disabled
          endAdornment={
            <Icon
              icon="ion:checkmark-circle"
              height={20}
              style={{ marginRight: 10, color: Colors.GREEN2 }}
            />
          }
        />
        <span
          style={{
            textAlign: "center",
            fontSize: 14,
            fontWeight: "500",
            ...theme.PrimaryText,
          }}
        >
          You are signed in with an email account.
          <br />
          <span
            style={{ color: Colors.BLUE5, cursor: "pointer" }}
            onClick={logoutEmailOnPress}
          >
            Logout
          </span>{" "}
          to switch to a phone based account.
        </span>
      </>
    );
  }
);
