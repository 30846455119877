import {
  AccountData,
  AudienceList,
  AudienceListVisibility,
  Event,
  SavedFormResponse,
  SavedFormQuestion,
  Follower,
  FollowType,
  FollowerStatus,
} from "@markit/common.types";
import {
  getEventData,
  getPhoneNumberData,
  getUserAudienceListMembersRef,
  getUserAudienceListsRef,
  getUserAudienceListsSnap,
  getUserData,
  getUserEventsRef,
  getUserFollowerData,
  getUserFollowersRef,
  getUserSavedFormQuestionsRef,
  getUserWishlistRef,
} from "./FirebaseUtils";
import {
  getCountFromServer,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "../firebase";
import {
  FAVORITES_LIST_NAME,
  filterUndefinedValues,
  isExternalGenericLink,
} from "@markit/common.utils";
import { userCurrentNumberOfTickets } from "./eventUtils/userTicketUtils";
import { API } from "../API";
import { RecentEventAnalytics } from "../components/FollowerProfile/FollowerProfileHeader";
import { Query } from "firebase/firestore";

// fetches the user's public audience lists
export const fetchUserPublicAudienceLists = async (
  userId: string
): Promise<AudienceList[]> => {
  const audienceListsRef = getUserAudienceListsRef(userId);
  const audienceListsQuery = query(
    audienceListsRef,
    where("visibility", "==", AudienceListVisibility.PUBLIC)
  );
  const audienceListsSnapshot = await getDocs(audienceListsQuery);
  const audienceLists = await Promise.all(
    audienceListsSnapshot.docs.map((snapshot) => {
      const audienceListData = snapshot.data();
      return audienceListData;
    })
  );
  return audienceLists;
};

// checks if a user exists in an audience list
export const checkAudienceListMembership = async (
  userId: string,
  audienceListId: string,
  creatorId: string
): Promise<boolean> => {
  if (audienceListId === "") {
    return false;
  }
  const audienceListRef = getUserAudienceListMembersRef(
    creatorId,
    audienceListId
  );
  const query_ = query(audienceListRef, where("uid", "==", userId));
  const querySnapshot = await getDocs(query_);
  if (querySnapshot.docs.length === 0) {
    return false;
  }
  return true;
};

// Check to see if the user has a favorites list
// This can be removed once we create favorite list on account creation
export const checkUserFavoritesListExists = async (userId: string) => {
  const audienceListsRef = getUserAudienceListsRef(userId);
  const query_ = query(
    audienceListsRef,
    where("name", "==", FAVORITES_LIST_NAME),
    limit(1)
  );
  const snapshot = await getCountFromServer(query_);
  return snapshot.data().count > 0;
};

// Check if contact already has an account, and return the user data if it exists
export const fetchUserDataFromPhoneNumber = async (
  phoneNumber: string
): Promise<AccountData | undefined> => {
  const phoneData = await getPhoneNumberData(phoneNumber);

  return phoneData ? await getUserData(phoneData.userId) : undefined;
};

/**
 * Fetch for follower data using a phone number
 */
export const fetchFollowerDataFromPhoneNumber = async (
  uid: string,
  phoneNumber: string
): Promise<Follower | undefined> => {
  const phoneData = await getPhoneNumberData(phoneNumber);

  if (phoneData) {
    return getUserFollowerData(uid, phoneData.userId);
  } else {
    // No accountData exists yet for this phone number
    return undefined;
  }
};

// Checks if a specified user is subscribed to a designated user already
export const checkIfUserIsSubscribed = async (
  userId: string,
  userToFollowUid: string
): Promise<boolean> => {
  const followerData = await getUserFollowerData(userToFollowUid, userId);

  return followerData
    ? followerData.status === FollowerStatus.SUBSCRIBED
    : false;
};

/**
 * Checks if the specified user can follow or is blocked by a condition
 */
export const checkIfUserCanFollow = async (
  userId: string,
  userToFollowUid: string
): Promise<boolean> => {
  // if userId is not defined, the user can follow
  if (userId === "") {
    return true;
  }

  const followerData = await getUserFollowerData(userToFollowUid, userId);

  // IF: followerData exists, can only follow if FollowerStatus is REMOVED.
  // Otherwise, it's subscribed, unsubscribed, blocked, or an invalid phone number verified by Twilio
  // ELSE: no followerData exists yet for this phone number for this creator, so can follow
  return followerData ? followerData.status === FollowerStatus.REMOVED : true;
};

/**
 * Checks if the specified phone number can follow or is blocked by a condition
 * Note: a bit redundant, but saves one firebase call compared to fetchUserDataFromPhoneNumber and checkIfUserCanFollow back-to-back
 */
export const checkIfPhoneNumberCanFollow = async (
  uid: string,
  phoneNumber: string
): Promise<boolean> => {
  const phoneData = await getPhoneNumberData(phoneNumber);

  if (phoneData) {
    return checkIfUserCanFollow(uid, phoneData.userId);
  } else {
    // No accountData exists yet for this phone number
    return true;
  }
};

/*
 * Checks if the specified user is unsubscribed
 * if userId is not defined, that means they aren't unsubscribed since we don't have record.
 */
export const checkIfUserIsUnsubscribed = async (
  userId: string,
  userToFollowUid: string
): Promise<boolean> => {
  // if userId is not defined, the user is not unsubscribed
  if (userId === "") {
    return false;
  }

  // check if they're unsubscribed to the free creator number if this is a free tier creator
  const creatorData = await getUserData(userToFollowUid);
  if (creatorData && creatorData.customer.phone === "") {
    const userData = await getUserData(userId);
    if (userData) {
      const phoneNumberData = await getPhoneNumberData(userData.phoneNumber);
      if (phoneNumberData && phoneNumberData.optOutFreeCreatorPhone) {
        return true;
      }
    }
  }

  const followerData = await getUserFollowerData(userToFollowUid, userId);

  return followerData
    ? followerData.status === FollowerStatus.UNSUBSCRIBED
    : false;
};

/*
 * Checks if the specified phone number is unsubscribed
 * if phone number is not defined, that means they aren't unsubscribed since we don't have record.
 */
export const checkIfPhoneNumberIsUnsubscribed = async (
  phoneNumber: string,
  userToFollowUid: string
): Promise<boolean> => {
  // if phone number is not defined, the user is not unsubscribed
  if (phoneNumber === "") {
    return false;
  }

  // check if they're unsubscribed to the free creator number if this is a free tier creator
  const creatorData = await getUserData(userToFollowUid);
  if (creatorData && creatorData.customer.phone === "") {
    const phoneNumberData = await getPhoneNumberData(phoneNumber);
    if (phoneNumberData && phoneNumberData.optOutFreeCreatorPhone) {
      return true;
    }
  }

  const followerData = await fetchFollowerDataFromPhoneNumber(
    userToFollowUid,
    phoneNumber
  );

  return followerData
    ? followerData.status === FollowerStatus.UNSUBSCRIBED
    : false;
};

/*
 * Checks if the specified user is unsubscribed from markit number
 * if userId is not defined, that means they aren't unsubscribed since we don't have record.
 */
export const checkIfUserIsUnsubscribedMarkit = async (
  phoneNumber: string
): Promise<boolean> => {
  // if userId is not defined, the user is not unsubscribed
  if (phoneNumber === "") {
    return false;
  }

  // Even if using firebase OTP, we need them opted in so they can get confirmation texts
  const phoneNumberData = await getPhoneNumberData(phoneNumber);
  if (phoneNumberData) {
    return phoneNumberData.optOut;
  } else {
    return false;
  }
};

export const fetchUserFollowerData = async (
  creatorUid: string,
  userId: string
): Promise<{ follower: Follower | undefined; event: Event | undefined }> => {
  const followerData = await getUserFollowerData(creatorUid, userId);

  if (followerData) {
    if (followerData.eventId !== "") {
      const eventData = await getEventData(followerData.eventId);
      return { follower: followerData, event: eventData };
    } else {
      return { follower: followerData, event: undefined };
    }
  } else {
    console.log("No follower user found: " + userId);
    return { follower: undefined, event: undefined };
  }
};

export const fetchMostRecentUserAttendedEvents = async (
  creatorUid: string,
  userId: string,
  numTicketsToDisplay?: number
) => {
  const userWishlistRef = getUserWishlistRef(userId);
  const userWishlistQuery = query(
    userWishlistRef,
    orderBy("createdAt", "desc")
  );
  const snapshot = await getDocs(userWishlistQuery);
  const eventAnalytics: ({ event: Event; numTickets: number } | undefined)[] =
    await Promise.all(
      snapshot.docs.map(async (doc) => {
        const wishMark = doc.data();
        if (!wishMark.eventId) {
          return undefined;
        }
        const eventsRef = getUserEventsRef(creatorUid);
        const eventsQuery = query(
          eventsRef,
          where("id", "==", wishMark.eventId),
          limit(1)
        );
        const eventSnapshot = await getDocs(eventsQuery);
        if (eventSnapshot.empty) {
          return undefined;
        }
        const event = eventSnapshot.docs.map((doc) => doc.data())[0];
        // return undefined if a link instead of event
        if (isExternalGenericLink(event.eventType)) {
          return undefined;
        }
        const numTickets = await userCurrentNumberOfTickets(
          wishMark.eventId,
          userId
        );
        return { event: event, numTickets: numTickets };
      })
    );
  const definedEventAnalytics: RecentEventAnalytics[] =
    filterUndefinedValues(eventAnalytics);
  const sortedDefinedEventAnalytics = definedEventAnalytics.sort((x, y) => {
    return new Date(y.event.end).getTime() - new Date(x.event.end).getTime();
  });
  return numTicketsToDisplay
    ? sortedDefinedEventAnalytics.slice(0, numTicketsToDisplay)
    : sortedDefinedEventAnalytics;
};

export const fetchUserJoinedAudienceLists = async (
  creatorUid: string,
  userId: string,
  numListsToDisplay?: number
) => {
  const creatorAudienceListsSnap = await getUserAudienceListsSnap(creatorUid);
  const audienceLists = await Promise.all(
    creatorAudienceListsSnap.docs.map(async (doc) => {
      const audienceList = doc.data();
      const membersRef = getUserAudienceListMembersRef(
        creatorUid,
        audienceList.id
      );
      const membersQuery = query(membersRef, where("uid", "==", userId));
      const snapshot = await getDocs(membersQuery);
      if (!snapshot.empty) {
        return audienceList;
      }
      return undefined;
    })
  );
  const definedAudienceLists: AudienceList[] =
    filterUndefinedValues(audienceLists);

  return numListsToDisplay
    ? definedAudienceLists.slice(0, numListsToDisplay)
    : definedAudienceLists;
};

// Fetches for the specified user's essential question
export const fetchUserEssentialQuestion = async (
  uid: string
): Promise<SavedFormQuestion | undefined> => {
  const savedFormQuestionsRef = getUserSavedFormQuestionsRef(uid);

  const essentialQuestionQuery = query(
    savedFormQuestionsRef,
    where("isEssential", "==", true)
  );

  const essentialQuestionSnap = await getDocs(essentialQuestionQuery);
  if (essentialQuestionSnap.empty) {
    return undefined;
  }
  return essentialQuestionSnap.docs.map((question) => question.data())[0];
};

// Fetches for the specified user's essential form response
export const fetchUserEssentialFormResponse = async (
  userId: string,
  followerId: string,
  essentialQuestionId: string
): Promise<SavedFormResponse> => {
  const { formResponse } = await API.user.fetchUserEssentialFormResponse({
    uid: userId,
    followerId: followerId,
    essentialFormQuestionId: essentialQuestionId,
  });
  return formResponse;
};

// Fetches for the specified user's email form response, if applicable
export const fetchUserEmailFormResponse = async (
  userId: string,
  followerId: string
): Promise<SavedFormResponse> => {
  const { formResponse } = await API.user.fetchUserEmailFormResponse({
    uid: userId,
    followerId: followerId,
  });
  return formResponse;
};

// Fetches for the specified user's essential form response
export const fetchMultipleUserFormResponses = async (
  userId: string,
  followerId: string,
  questionIds: string[]
): Promise<(SavedFormResponse | undefined)[]> => {
  const { formResponses } = await API.user.fetchMultipleUserFormResponses({
    uid: userId,
    followerId: followerId,
    questionIds: questionIds,
  });
  return formResponses;
};

// Fetches the user's that aren't from this specified event
export const fetchNumSubscribedFollowersNotFromEvent = async (
  userId: string,
  eventId: string
) => {
  const followersRef = getUserFollowersRef(userId);
  const query_ = query(
    followersRef,
    where("eventId", "!=", eventId),
    where("status", "==", FollowerStatus.SUBSCRIBED)
  );
  const snapshot = await getCountFromServer(query_);
  return snapshot.data().count;
};

export const fetchNumFollowersNoComplianceSent = async (
  userId: string,
  recipients: string[]
) => {
  const followersRef = getUserFollowersRef(userId);
  const query_ = query(
    followersRef,
    where("complianceMessageSent", "==", false)
  );
  const snapshot = await getDocs(query_);
  const numFollowersNotCompliant = snapshot.docs.reduce((count, snapshot) => {
    if (recipients.includes(snapshot.id)) {
      return count + 1;
    }
    return count;
  }, 0);
  return numFollowersNotCompliant;
};

// Returns the number of followers imported from eventbrite
export const fetchNumEventbriteFollowersQuery = async (
  userId: string
): Promise<Query<Follower>> => {
  const followersRef = getUserFollowersRef(userId);
  const query_ = query(
    followersRef,
    where("followType", "==", FollowType.EVENTBRITE)
  );

  return query_;
};
