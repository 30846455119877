import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Colors } from "../utils/colors";

export const AntTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: Colors.BLACK,
  },
  margin: 0,
});

export const AntTab = styled(
  (props: { label: string; isDisabled?: boolean }) => (
    <Tab disabled={props.isDisabled} disableRipple {...props} />
  )
)(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  fontWeight: 500,
  fontSize: 15,
  color: "rgba(0, 0, 0, 0.85)",
  "&:hover": {
    color: Colors.BLACK,
    opacity: 0.5,
  },
  "&.Mui-selected": {
    color: Colors.BLACK,
    fontWeight: theme.typography.fontWeightMedium,
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#d1eaff",
  },
}));
