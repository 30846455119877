import { query, where, getDocs, getCountFromServer } from "../../firebase";
import {
  getTicketsRef,
  getTicketData,
  getEventData,
} from "../../utils/FirebaseUtils";
import { CustomTicketV2, TicketV2 } from "@markit/common.types";

/**
 * Get user's redeemed ticket
 */
export const getUserTicket = async (
  eventId: string,
  uid: string
): Promise<TicketV2 | undefined> => {
  const ticketsRef = getTicketsRef(eventId);
  const query_ = query(ticketsRef, where("redeemedBy", "==", uid));
  const querySnapshot = await getDocs(query_);

  if (querySnapshot.docs.length === 0) {
    return undefined;
  }
  const userTicket = querySnapshot.docs.map((doc) => doc.data());

  if (userTicket.length > 1) {
    console.error("Somehow there is more or less than one redeemedBy");
  }
  return userTicket[0];
};

/**
 * Get the user's extra tickets that have been redeemed and request accepted
 */
export const getUserRedeemedTickets = async (
  eventId: string,
  uid: string
): Promise<TicketV2[]> => {
  const ticketsRef = getTicketsRef(eventId);
  const queryRedeemed_ = query(
    ticketsRef,
    where("originalOwner", "==", uid),
    where("uid", "!=", uid),
    where("requestStatus", "==", "accepted")
  );
  const queryRedeemedSnapshot = await getDocs(queryRedeemed_);

  if (queryRedeemedSnapshot.docs.length === 0) {
    console.log("no redeemed tickets found for: ", uid);
    return [];
  }
  const userTicket = queryRedeemedSnapshot.docs.map((doc) => doc.data());
  const finalUserTickets = userTicket.filter((ticket) => ticket.uid !== "");

  return finalUserTickets;
};

/**
 * Get the user's extra tickets that haven't been redeemed
 */
export const getUserUnredeemedTickets = async (
  eventId: string,
  uid: string
): Promise<TicketV2[]> => {
  const ticketsRef = getTicketsRef(eventId);
  const queryUnredeemed_ = query(
    ticketsRef,
    where("uid", "==", uid),
    where("redeemedBy", "==", ""),
    where("requestStatus", "==", "accepted")
  );
  const queryUnredeemedSnapshot = await getDocs(queryUnredeemed_);

  if (queryUnredeemedSnapshot.docs.length === 0) {
    console.log("no unredeemed tickets found for: ", uid);
    return [];
  }
  const userTicket = queryUnredeemedSnapshot.docs.map((doc) => doc.data());

  return userTicket;
};

export const getUserCurrentPendingTickets = async (
  eventId: string,
  uid: string
): Promise<TicketV2[]> => {
  const ticketsRef = getTicketsRef(eventId);
  const queryPending_ = query(
    ticketsRef,
    where("uid", "==", uid),
    where("requestStatus", "==", "pending")
  );
  const queryPendingSnapshot = await getDocs(queryPending_);

  if (queryPendingSnapshot.docs.length === 0) {
    console.log("no pending tickets found for: ", uid);
    return [];
  }
  const userTicket = queryPendingSnapshot.docs.map((doc) => doc.data());

  return userTicket;
};
/**
 * Gets the custom ticket object from the user's ticket
 */
export const getCustomTicketFromUserTicket = async (
  eventId: string,
  uid: string
): Promise<CustomTicketV2 | undefined> => {
  const ticket = await getUserTicket(eventId, uid);
  const eventData = await getEventData(eventId);
  if (ticket && eventData) {
    const foundCustomTicket = eventData.customTickets.find(
      (customTicket) => customTicket.id === ticket.customTicketId
    );
    if (foundCustomTicket) {
      return foundCustomTicket;
    }
  }
};

/**
 * Gets the custom ticket object from the tixId
 */
export const getCustomTicketFromTixId = async (
  eventId: string,
  tixId: string
): Promise<CustomTicketV2 | undefined> => {
  const ticket = await getTicketData(eventId, tixId);
  const eventData = await getEventData(eventId);
  if (ticket && eventData) {
    const foundCustomTicket = eventData.customTickets.find(
      (customTicket) => customTicket.id === ticket.customTicketId
    );
    if (foundCustomTicket) {
      return foundCustomTicket;
    }
  }
};

/**
 * Check if user's ticket was redeemed from someone else
 */
export const isUserTicketRedeemed = async (
  eventId: string,
  uid: string
): Promise<boolean> => {
  const ticketsRef = getTicketsRef(eventId);
  const query_ = query(ticketsRef, where("redeemedBy", "==", uid));
  const querySnapshot = await getDocs(query_);

  if (querySnapshot.docs.length === 0) {
    console.error("no redeemed ticket found for: ", uid);
    return false;
  }
  const userTicket = querySnapshot.docs.map((doc) => doc.data());

  if (userTicket.length > 1) {
    console.error("Somehow there is more or less than one redeemedBy");
  }
  return userTicket[0].uid !== userTicket[0].redeemedBy;
};

/**
 * Check if the specific user has their own redeemed ticket
 */
export const userHasOwnTicket = async (
  eventId: string,
  uid: string
): Promise<boolean> => {
  const ticketsRef = getTicketsRef(eventId);
  const query_ = query(ticketsRef, where("redeemedBy", "==", uid));
  const querySnapshot = await getDocs(query_);

  if (querySnapshot.docs.length === 0) {
    console.log("no redeemed ticket found for: ", uid);
    return false;
  }

  if (querySnapshot.docs.length > 1) {
    console.error("Somehow there is more or less than one redeemedBy");
  }

  return true;
};

/**
 * Check how many tickets a user has bought and/or redeemed
 */
export const userCurrentNumberOfTickets = async (
  eventId: string,
  uid: string
): Promise<number> => {
  const ticketsRef = getTicketsRef(eventId);
  const query_ = query(
    ticketsRef,
    where("uid", "==", uid),
    where("requestStatus", "==", "accepted")
  );

  const snapshot = await getCountFromServer(query_);

  return snapshot.data().count;
};

/**
 * Check how many tickets a user has bought and/or redeemed
 */
export const userCurrentNumberOfPendingTickets = async (
  eventId: string,
  uid: string
): Promise<number> => {
  const ticketsRef = getTicketsRef(eventId);
  const query_ = query(
    ticketsRef,
    where("uid", "==", uid),
    where("requestStatus", "==", "pending")
  );
  const snapshot = await getCountFromServer(query_);

  return snapshot.data().count;
};

/**
 * Get how many tickets a user has purchased in a group
 */
export const userNumberOfTicketsInGroup = async (
  eventId: string,
  uid: string,
  customTicketId: string
): Promise<number> => {
  if (uid === "") {
    return 0;
  }

  const ticketsRef = getTicketsRef(eventId);
  const query_ = query(
    ticketsRef,
    where("uid", "==", uid),
    where("customTicketId", "==", customTicketId)
  );
  const snapshot = await getCountFromServer(query_);

  return snapshot.data().count;
};

/**
 * Get user's redeemed ticket
 */
export const getUserRoleTicket = async (
  eventId: string,
  uid: string
): Promise<TicketV2 | undefined> => {
  const ticketsRef = getTicketsRef(eventId);
  const query_ = query(
    ticketsRef,
    where("uid", "==", uid),
    where("customTicketId", "==", "")
  );
  const querySnapshot = await getDocs(query_);

  if (querySnapshot.docs.length === 0) {
    console.error("no role ticket found for: ", uid);
    return undefined;
  }
  const userTicket = querySnapshot.docs.map((doc) => doc.data());

  if (userTicket.length > 1) {
    console.error("Somehow there is more or less than one role ticket");
  }
  return userTicket[0];
};

/**
 * Gets the tickets that a user owns
 */
export const getAllUsersTickets = async (
  eventId: string,
  uid: string
): Promise<TicketV2[]> => {
  const ticketsRef = getTicketsRef(eventId);

  const queryUserTickets_ = query(ticketsRef, where("uid", "==", uid));
  const queryUserTicketsSnapshot = await getDocs(queryUserTickets_);

  if (queryUserTicketsSnapshot.docs.length === 0) {
    return [];
  }

  const userTickets = queryUserTicketsSnapshot.docs.map((doc) => doc.data());
  return userTickets;
};

/**
 * Gets all user tickets for an event mapped to type and number
 */
export const getUserEventTicketsMapped = async (
  eventId: string,
  uid: string
): Promise<Map<string, number>> => {
  const ticketsRef = getTicketsRef(eventId);

  const query_ = query(ticketsRef, where("uid", "==", uid));
  const querySnapshot = await getDocs(query_);

  const userTickets = querySnapshot.docs.map((doc) => doc.data());

  const event = await getEventData(eventId);

  const userTicketsMap = new Map();
  for (const userTicket of userTickets) {
    const ticketLabel = event?.customTickets.find(
      (customTicket) => customTicket.id === userTicket.customTicketId
    )?.label;
    if (ticketLabel !== undefined && !userTicketsMap.has(ticketLabel)) {
      const numberOfTicketsInGroup = await userNumberOfTicketsInGroup(
        eventId,
        uid,
        userTicket.customTicketId
      );
      userTicketsMap.set(ticketLabel, numberOfTicketsInGroup);
    }
  }

  return userTicketsMap;
};

/**
 * Get user's role ticket from ticket Array
 **/
export const getUserRoleTicketFromUserTickets = (
  eventId: string,
  uid: string,
  userTickets: TicketV2[]
): TicketV2 | undefined => {
  return userTickets.find((ticket) => {
    return ticket.eventId === eventId && ticket.redeemedBy === uid;
  });
};
