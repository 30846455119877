import { useCallback, useEffect, useMemo, useState } from "react";
import {
  AccountData,
  AudienceList,
  ImportData,
  SavedFormQuestion,
  SelectRecipientGroupType,
  SelectRecipientType,
} from "@markit/common.types";
import SelectRecipientsEvent from "./SelectEvents/SelectRecipientsEvent";
import SelectRecipientsAudienceList from "./SelectAudienceLists/SelectRecipientsAudienceList";
import SelectRecipientsCollectedData from "./SelectCollectedData/SelectRecipientsCollectedData";
import SelectRecipientsOverview from "./SelectRecipients";
import { HorizontalDivider } from "../../../Dividers/HorizontalDivider";
import FullProfilePreviewModal from "../../../FollowerProfile/FullProfilePreviewModal";
import SelectRecipientHeader from "./SelectRecipientsHeader";
import SelectedRecipientsList from "./SelectedRecipientsList";
import SelectRecipientsImportLibrary from "./SelectImports/SelectRecipientsImportLibrary";
import SelectRecipientsImport from "./SelectImports/SelectRecipientsImport";
import SelectRecipientsAudienceListLibrary from "./SelectAudienceLists/SelectRecipientsAudienceListLibrary";
import SelectRecipientsEventLibrary from "./SelectEvents/SelectRecipientsEventLibrary";
import SelectRecipientsFollowers from "./SelectRecipientsFollowers";
import isEqual from "lodash.isequal";

export type SimpleSelectRecipientsMainSharedProps = {
  excludingMode: boolean;
  setExcludingMode: (excludingMode: boolean) => void;
  showDoneButton: boolean;
  setShowDoneButton: (showDoneButton: boolean) => void;
};

type SimpleSelectRecipientsPanelProps = {
  allSelectedPeople: string[];
  setAllSelectedPeople: (allSelectedPeople: string[]) => void;
  allExcludedPeople: string[];
  setAllExcludedPeople: (allExcludedPeople: string[]) => void;
  setIsVisible: (isVisible: boolean) => void;
};

// Used for if we just want the select recipients part of the flow, ie. audience list members selection
const SimpleSelectRecipientsPanel = (
  props: SimpleSelectRecipientsPanelProps
) => {
  const {
    allSelectedPeople,
    setAllSelectedPeople,
    allExcludedPeople,
    setAllExcludedPeople,
    setIsVisible,
  } = props;
  const [unsavedRecipients, setUnsavedRecipients] = useState<string[]>([]);
  const [profileSelected, setProfileSelected] = useState<AccountData>();

  const [selectedType, setSelectedType] = useState<SelectRecipientGroupType>({
    type: SelectRecipientType.NONE,
    selectedItem: undefined,
    eventTabValue: 0,
  });
  const [excludingMode, setExcludingMode] = useState<boolean>(false);
  const [showDoneButton, setShowDoneButton] = useState(false);

  useEffect(() => {
    // Update the unsavedRecipients whenever you go to a selection screen again
    if (selectedType.type !== SelectRecipientType.NONE) {
      setUnsavedRecipients(
        excludingMode ? allExcludedPeople : allSelectedPeople
      );
    }
  }, [allExcludedPeople, allSelectedPeople, excludingMode, selectedType.type]);

  const updateSelectType = useCallback(
    (newSelectedType: Partial<SelectRecipientGroupType>) => {
      setSelectedType((prevSelectedType) => {
        return { ...prevSelectedType, ...newSelectedType };
      });
    },
    []
  );

  const sharedPropsPack = {
    allRecipients: excludingMode ? allExcludedPeople : allSelectedPeople,
    unsavedRecipients: unsavedRecipients,
    setUnsavedRecipients: setUnsavedRecipients,
    unsavedCategories: [],
    setUnsavedCategories: () => {},
    excludingMode: excludingMode,
    showCategories: false,
    setProfileSelected: setProfileSelected,
  };

  const mainSharedPropsPack: SimpleSelectRecipientsMainSharedProps = {
    excludingMode: excludingMode,
    setExcludingMode: setExcludingMode,
    showDoneButton: showDoneButton,
    setShowDoneButton: setShowDoneButton,
  };

  const backOnPress = useCallback(() => {
    if (selectedType.type === SelectRecipientType.NONE) {
      setIsVisible(false);
    } else {
      setSelectedType({
        type: SelectRecipientType.NONE,
        selectedItem: undefined,
        eventTabValue: 0,
      });
    }
  }, [selectedType.type, setIsVisible]);

  const resetOnPress = useCallback(() => {
    setAllExcludedPeople([]);
    setAllSelectedPeople([]);
    updateSelectType({ type: SelectRecipientType.NONE });
  }, [setAllExcludedPeople, setAllSelectedPeople, updateSelectType]);

  const madeChanges = useMemo(() => {
    if (selectedType.type !== SelectRecipientType.NONE) {
      return !isEqual(
        excludingMode ? allExcludedPeople : allSelectedPeople,
        unsavedRecipients
      );
    }
    return false;
  }, [
    allExcludedPeople,
    allSelectedPeople,
    excludingMode,
    selectedType.type,
    unsavedRecipients,
  ]);

  return (
    <div>
      <div style={{ paddingInline: 14 }}>
        <SelectRecipientHeader
          {...mainSharedPropsPack}
          backToOverview={backOnPress}
          selectType={selectedType}
          updateSelectType={updateSelectType}
          unsavedRecipients={unsavedRecipients}
          setUnsavedRecipients={setUnsavedRecipients}
          unsavedCategories={[]}
          setUnsavedCategories={() => {}}
          setAllExcludedPeople={setAllExcludedPeople}
          setAllSelectedPeople={setAllSelectedPeople}
          alternateTitle="People"
          madeChanges={madeChanges}
        />
      </div>
      <HorizontalDivider />
      <div style={{ padding: 14 }}>
        {selectedType.type === SelectRecipientType.NONE ? (
          <SelectRecipientsOverview
            {...mainSharedPropsPack}
            allSelectedPeople={allSelectedPeople}
            setAllSelectedPeople={setAllSelectedPeople}
            allExcludedPeople={allExcludedPeople}
            setAllExcludedPeople={setAllExcludedPeople}
            updateSelectType={updateSelectType}
            setProfileSelected={setProfileSelected}
            backToOverview={() => {}}
          />
        ) : null}
        {/* Select by Followers Screen */}
        {selectedType.type === SelectRecipientType.PEOPLE ? (
          <SelectRecipientsFollowers {...sharedPropsPack} />
        ) : null}
        {/* Select by Event Screen */}
        {selectedType.type === SelectRecipientType.EVENTS ? (
          selectedType.selectedItem ? (
            <SelectRecipientsEvent
              selectedType={selectedType}
              {...sharedPropsPack}
            />
          ) : (
            <SelectRecipientsEventLibrary updateSelectType={updateSelectType} />
          )
        ) : null}
        {/* Select by Audience List Screen */}
        {selectedType.type === SelectRecipientType.LISTS ? (
          selectedType.selectedItem ? (
            <SelectRecipientsAudienceList
              selectedList={selectedType.selectedItem as AudienceList}
              {...sharedPropsPack}
            />
          ) : (
            <SelectRecipientsAudienceListLibrary
              excludingMode={excludingMode}
              updateSelectType={updateSelectType}
            />
          )
        ) : null}
        {/* Select by Collected Data Screen */}
        {selectedType.type === SelectRecipientType.COLLECTED_DATA ? (
          <SelectRecipientsCollectedData
            selectedQuestion={selectedType.selectedItem as SavedFormQuestion}
            updateSelectType={updateSelectType}
            {...sharedPropsPack}
          />
        ) : null}
        {/* Select by Import Screen */}
        {selectedType.type === SelectRecipientType.IMPORTS ? (
          selectedType.selectedItem ? (
            <SelectRecipientsImport
              selectedImport={selectedType.selectedItem as ImportData}
              {...sharedPropsPack}
            />
          ) : (
            <SelectRecipientsImportLibrary
              updateSelectType={updateSelectType}
            />
          )
        ) : null}
        {/* Selected Recipients Screen */}
        {selectedType.type === SelectRecipientType.RECIPIENTS ? (
          <SelectedRecipientsList
            {...mainSharedPropsPack}
            unsavedRecipients={unsavedRecipients}
            setUnsavedRecipients={setUnsavedRecipients}
            unsavedCategories={[]}
            setUnsavedCategories={() => {}}
            allSelectedPeople={allSelectedPeople}
            allExcludedPeople={allExcludedPeople}
            setProfileSelected={setProfileSelected}
            resetOnPress={resetOnPress}
          />
        ) : null}
      </div>
      {profileSelected ? (
        <FullProfilePreviewModal
          profileSelected={profileSelected}
          setProfileSelected={setProfileSelected}
        />
      ) : null}
    </div>
  );
};

export default SimpleSelectRecipientsPanel;
