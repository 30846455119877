import "../../../css/GlobalStyles.css";
import { useCallback, useState } from "react";
import ShareProfilePopupPanel from "../../../components/CreatorDashboard/CreatorProfile/ShareProfilePopupPanel";
import { PeoplePanel } from "../../../components/HomePage/PeoplePanel";
import { greetingMessage, hasSubscription } from "@markit/common.utils";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import { useNavigate } from "../../../hooks/useNavigate";
import MarkitPlusActionButton from "../../../components/Subscription/MarkitPlusActionButton";
import { ImportantAnnouncementModal } from "../../../components/DisplayModal/ImportantAnnouncementModal";
import { MarkitPlusModal } from "../../../components/Subscription/MarkitPlusModal";
import CreatorModePanelWrapper from "../../../components/CreatorDashboard/CreatorModePanelWrapper";
import FatHeaderActionButton from "../../../components/Buttons/FatHeaderActionButton";
import { NavigationId } from "../../../navigation/AppParamList";

const HomePanel = () => {
  const { accountData } = useSelector(getAccountState).account;
  const navigate = useNavigate();
  const [shareProfileOpen, setShareProfileOpen] = useState(false);
  const [subModalVisible, setSubModalVisible] = useState(false);

  const navigateNewUpload = useCallback(() => {
    if (!hasSubscription(accountData)) {
      setSubModalVisible(true);
      return;
    }
    navigate("/import");
  }, [accountData, navigate]);

  const navigateShareProfile = useCallback(() => {
    setShareProfileOpen(true);
  }, []);

  return (
    <CreatorModePanelWrapper
      title={greetingMessage()}
      headerRight={
        <div className="AlignedRow" style={{ gap: 14 }}>
          <FatHeaderActionButton
            title="Integrations"
            onPress={() => navigate(NavigationId.HOME_INTEGRATIONS)}
          />
          <MarkitPlusActionButton mainAction />
        </div>
      }
    >
      <div
        className="CreatorPanelContainer"
        style={{ padding: 30, paddingBottom: 100 }}
      >
        <PeoplePanel
          shareProfileOnPress={navigateShareProfile}
          importOnPress={navigateNewUpload}
        />
      </div>
      {shareProfileOpen ? (
        <ShareProfilePopupPanel
          isVisible={shareProfileOpen}
          setIsVisible={setShareProfileOpen}
        />
      ) : null}
      {subModalVisible ? (
        <MarkitPlusModal closeModal={() => setSubModalVisible(false)} />
      ) : null}
      <ImportantAnnouncementModal />
    </CreatorModePanelWrapper>
  );
};

export default HomePanel;
