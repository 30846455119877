import { WhiteLabelUsers } from "../macros/WhiteLabelUsers";

export const isRCA = (hostId: string, cohosts: string[]) => {
  return (
    hostId === WhiteLabelUsers.RCA || cohosts.includes(WhiteLabelUsers.RCA)
  );
};

export const isProfileRCA = (userId: string) => {
  return userId === WhiteLabelUsers.RCA;
};
