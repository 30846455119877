export const DisplayGroupMembers = (members: string[]): string => {
  let displayGroupMembers = '';

  for (let i = 0; i < members.length; i++) {
    if (i === members.length - 1) {
      displayGroupMembers += members[i];
    } else {
      displayGroupMembers += members[i] + ', ';
    }
  }

  return displayGroupMembers;
};
