import "../../css/App.css";
import "../../css/GlobalStyles.css";
import "../../css/FullEvent/ShareTickets.css";
import React, { memo, useCallback, useState } from "react";
import { TicketV2 } from "@markit/common.types";
import { isDesktop } from "react-device-detect";
import { FaRegTimesCircle } from "react-icons/fa";
import { API } from "../../API";
import { useTheme } from "../../hooks/useTheme";
import StandardContainer from "../Containers/StandardContainer";
import { Colors } from "../../utils/colors";
import { RWebShare } from "react-web-share";
import { onShareLink } from "../../utils/shareLink";
import { MARKITAI } from "@markit/common.utils";
import ConfirmActionModal from "../Containers/ConfirmPopups/ConfirmActionModal";

type TransferMainTicketProps = {
  eventId: string;
  userId: string;
  mainUserTicket: TicketV2;
  fullName: string;
  setShowTransferTicket: (showTickets: boolean) => void;
};

const TransferMainTicket = memo(function TransferMainTicketFn(
  props: TransferMainTicketProps
) {
  const { eventId, userId, mainUserTicket, fullName, setShowTransferTicket } =
    props;
  const { theme } = useTheme();

  const [transferTicketConfirm, setTransferTicketConfirm] = useState(false);

  const shareTransferTicket = useCallback(() => {
    onShareLink(
      fullName +
        " is transferring their ticket to you! Click this link to redeem and receive the ticket.",
      `${MARKITAI}/e/${eventId}?ticketId=${mainUserTicket.id}`
    ).then(async () => {
      await API.modifyTicket.transferMainTicket({
        eventId: eventId,
        userId: userId,
        ticketId: mainUserTicket.id,
      });
      window.location.reload();
    });
  }, [eventId, fullName, mainUserTicket.id, userId]);

  const shareTransferTicketDesktop = useCallback(async () => {
    await API.modifyTicket.transferMainTicket({
      eventId: eventId,
      userId: userId,
      ticketId: mainUserTicket.id,
    });
    window.location.reload();
  }, [eventId, mainUserTicket.id, userId]);

  const renderTransferButton = (
    <button
      onClick={() => setTransferTicketConfirm(true)}
      className="RectangleButton"
      style={{
        backgroundColor: Colors.BLACK,
        height: 40,
        cursor: "pointer",
      }}
    >
      <p
        style={{
          color: Colors.WHITE,
          fontWeight: "600",
          fontSize: 15,
          cursor: "pointer",
        }}
      >
        Share Transfer Ticket Link
      </p>
    </button>
  );

  return (
    <div className="ShareTicketsContainer" style={theme.SubGradientBG}>
      <div
        className="AlignedRowSpaced"
        style={{ width: "100%", boxSizing: "border-box", padding: 14 }}
      >
        <span className="ShareTicketsTitleDesktop" style={theme.PrimaryText}>
          Transfer Ticket
        </span>
        <FaRegTimesCircle
          style={theme.PrimaryText}
          className="CloseButtonDesktop"
          onClick={() => setShowTransferTicket(false)}
        />
      </div>
      <div
        style={{
          width: isDesktop ? "30%" : "100%",
          boxSizing: "border-box",
          paddingInline: 10,
        }}
      >
        <StandardContainer
          headerComp={
            isDesktop ? (
              <span style={{ fontWeight: "500" }}>Transfer Ticket</span>
            ) : null
          }
          divider={isDesktop}
          theme={theme}
          valueComp={
            <div className="ColumnNormal" style={{ gap: 14 }}>
              <span>
                To transfer your main ticket, share your transfer ticket link
                below.
              </span>
              <span>
                Please Note: Once you click this button and transfer your
                ticket, your original ticket will be invalidated.
              </span>
              {isDesktop ? (
                <RWebShare
                  onClick={shareTransferTicketDesktop}
                  data={{
                    title:
                      fullName +
                      " is transferring their ticket to you! Click this link to redeem and receive the ticket.",
                    url:
                      "https://markitai.com/e/" +
                      eventId +
                      "?ticketId=" +
                      mainUserTicket.id,
                  }}
                >
                  {renderTransferButton}
                </RWebShare>
              ) : (
                renderTransferButton
              )}
            </div>
          }
        />
      </div>
      <ConfirmActionModal
        heading="Are you sure you want to transfer your ticket?"
        subtext="Once you click 'Yes', your ticket will no longer be valid."
        confirmButtonText="Yes"
        hideModal={!transferTicketConfirm}
        setIsVisible={setTransferTicketConfirm}
        confirmOnPress={shareTransferTicket}
        theme={theme}
      />
    </div>
  );
});

export default TransferMainTicket;
