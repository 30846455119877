import { useCallback, useMemo, useState } from "react";
import {
  getDocs,
  onSnapshot,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { LessThanDate, uniqueVals } from "@markit/common.utils";
import { MassText } from "@markit/common.types";
import { getUserMassTextsRef } from "../utils/FirebaseUtils";

type useLoadMassTextItemsProps = {
  userId: string;
  windowSize: number;
  scheduled: boolean;
};
export const useLoadMassTextItems = (props: useLoadMassTextItemsProps) => {
  const { userId, windowSize, scheduled } = props;

  const [fetchedMassTextData, setFetchedMassTextData] = useState<MassText[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);

  const [lastVisible, setLastVisible] = useState<string>(
    new Date().toISOString()
  );
  const [loadedAllMessages, setLoadedAllMessages] = useState(false);

  const addMassTextData = useCallback(
    (newMassTextData: MassText, addNew?: boolean) => {
      setFetchedMassTextData((fetchedMassTextData) => {
        const index = fetchedMassTextData.findIndex(
          (fetched) => fetched.id === newMassTextData.id
        );
        if (index !== -1) {
          fetchedMassTextData.splice(index, 1, newMassTextData);
        } else {
          if (addNew) {
            fetchedMassTextData.unshift(newMassTextData);
          } else {
            fetchedMassTextData = fetchedMassTextData.concat(newMassTextData);
          }
        }

        return uniqueVals(
          fetchedMassTextData,
          (fetchedMassTextData) => fetchedMassTextData.id
        );
      });
    },
    []
  );

  const removeMassTextData = useCallback((newMassText: MassText) => {
    setFetchedMassTextData((fetchedMassTextData) => {
      const index = fetchedMassTextData.findIndex(
        (fetched) => fetched.id === newMassText.id
      );
      if (index !== -1) {
        fetchedMassTextData.splice(index, 1);
      }

      return uniqueVals(
        fetchedMassTextData,
        (fetchedMassTextData) => fetchedMassTextData.id
      );
    });
  }, []);

  const isFinished = useMemo(() => loadedAllMessages, [loadedAllMessages]);

  const loadMassTextItems = useCallback(async () => {
    if (!isFinished && !isLoading) {
      setIsLoading(true);
      const massTextsRef = getUserMassTextsRef(userId);
      const massTextItemsQuery = scheduled
        ? query(
            massTextsRef,
            where("sentAt", ">", new Date().toISOString()),
            orderBy("sentAt", "asc"),
            limit(windowSize)
          )
        : query(
            massTextsRef,
            where("sentAt", "<=", new Date().toISOString()),
            orderBy("sentAt", "desc"),
            limit(windowSize)
          );
      const uploadedItems = (await getDocs(massTextItemsQuery)).docs.map(
        (document) => document.data().sentAt
      );

      setLastVisible(uploadedItems[uploadedItems.length - 1]);
      if (uploadedItems.length < windowSize) {
        setLoadedAllMessages(true);
      }

      const unsubscribe = onSnapshot(massTextItemsQuery, (snapshot) => {
        snapshot.docChanges().forEach(async (message, index) => {
          const massText = message.doc.data();
          if (message.type === "added" || message.type === "modified") {
            if (
              !scheduled ||
              (scheduled &&
                LessThanDate(new Date().toISOString(), massText.sentAt))
            ) {
              addMassTextData(massText);
            }
          } else if (message.type === "removed") {
            removeMassTextData(massText);
          }
        });
      });
      // TODO (Peter): hacky way to wait for data to load before setting isLoading to false.
      // Better solution is to set isLoading to false when we know the data has actually been loaded
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  }, [
    addMassTextData,
    isFinished,
    isLoading,
    removeMassTextData,
    scheduled,
    userId,
    windowSize,
  ]);

  const loadMoreMassTextItems = useCallback(async () => {
    if (!isFinished && !isLoading) {
      setIsLoading(true);
      const massTextsRef = getUserMassTextsRef(userId);
      const massTextItemsQuery = scheduled
        ? query(
            massTextsRef,
            where("sentAt", ">", new Date().toISOString()),
            orderBy("sentAt", "asc"),
            startAfter(lastVisible),
            limit(windowSize)
          )
        : query(
            massTextsRef,
            where("sentAt", "<=", new Date().toISOString()),
            orderBy("sentAt", "desc"),
            startAfter(lastVisible),
            limit(windowSize)
          );
      const uploadedItems = (await getDocs(massTextItemsQuery)).docs.map(
        (document) => document.data().sentAt
      );

      setLastVisible(uploadedItems[uploadedItems.length - 1]);
      if (uploadedItems.length < windowSize) {
        setLoadedAllMessages(true);
      }

      const unsubscribe = onSnapshot(massTextItemsQuery, (snapshot) => {
        snapshot.docChanges().forEach(async (message, index) => {
          const massText = message.doc.data();
          if (message.type === "added" || message.type === "modified") {
            if (
              !scheduled ||
              (scheduled &&
                LessThanDate(new Date().toISOString(), massText.sentAt))
            ) {
              addMassTextData(massText);
            }
          } else if (message.type === "removed") {
            removeMassTextData(massText);
          }
        });
      });
      // TODO (Peter): hacky way to wait for data to load before setting isLoading to false.
      // Better solution is to set isLoading to false when we know the data has actually been loaded
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  }, [
    addMassTextData,
    isFinished,
    isLoading,
    lastVisible,
    removeMassTextData,
    scheduled,
    userId,
    windowSize,
  ]);

  return {
    isFinished,
    isLoading,
    fetchedMassTextData,
    loadMassTextItems,
    loadMoreMassTextItems,
    addMassTextData,
  };
};
