import FullEventDecider from "../screens/Event/FullEventDecider";
import FullEventFinal from "../screens/Event/FullEventFinal";
import { Route, Routes } from "react-router-dom";
import { memo } from "react";
import DisplayQRCode from "../screens/Event/DisplayQRCode";
import EventQRCode from "../screens/Event/EventQRCode";
import OrganizersPage from "../screens/Event/OrganizersPage";
import { isDesktop } from "react-device-detect";
import FullEventFinalDesktop from "../screens/Event/FullEventFinalDesktop";
import EventDashboard from "../screens/Event/EventDashboard";
import { CreateEvent } from "../screens/CreateEvent/CreateEvent";
import CreateExternalLink from "../screens/CreateEvent/CreateExternalLink";

const EventRoute = memo(function EventRouteFn() {
  return (
    <Routes>
      <Route index element={<FullEventDecider />} />
      <Route path="eventQRCode" element={<EventQRCode />} />
      <Route path="i">
        <Route
          index
          element={isDesktop ? <FullEventFinalDesktop /> : <FullEventFinal />}
        />
        <Route
          path=":userId"
          element={isDesktop ? <FullEventFinalDesktop /> : <FullEventFinal />}
        />
        <Route path=":userId/myTicket" element={<DisplayQRCode />} />
        <Route path=":userId/organizers" element={<OrganizersPage />} />
      </Route>
      <Route path="dashboard" element={<EventDashboard />} />
      <Route path="edit" element={<CreateEvent />} />
      <Route path="editLink" element={<CreateExternalLink />} />
    </Routes>
  );
});

export default EventRoute;
