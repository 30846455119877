import { AudienceList, AudienceListVisibility } from "@markit/common.types";
import { Colors } from "../../utils/colors";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";
import { Icon } from "@iconify/react";
import { FAVORITES_LIST_NAME } from "@markit/common.utils";

export const AUDIENCE_LIST_FLEX_SECTIONS = [4, 1, 1, 2];

type AudienceListItemProps = {
  item: AudienceList;
  selectOnPress: () => void;
};

const AudienceListItem = ({ item, selectOnPress }: AudienceListItemProps) => {
  const styles = {
    title: { fontSize: 14, fontWeight: 500 },
    subtext: { fontSize: 14, color: Colors.GRAY1 },
  };

  return (
    <div onClick={selectOnPress} style={{ cursor: "pointer" }}>
      <div className="AlignedRowSelect" style={{ padding: 14 }}>
        <div
          className="AlignedRowSelect"
          style={{ gap: 7, flex: AUDIENCE_LIST_FLEX_SECTIONS[0] }}
        >
          {item.name === FAVORITES_LIST_NAME ? (
            <Icon icon="mdi:star-circle" height={28} />
          ) : null}
          <span style={styles.title}>{item.name}</span>
        </div>
        <div
          className="AlignedRowSelect"
          style={{ flex: AUDIENCE_LIST_FLEX_SECTIONS[1], marginLeft: 28 }}
        >
          <span style={{ marginLeft: 28, ...styles.subtext }}>
            {item.visibility === AudienceListVisibility.PRIVATE
              ? "Private"
              : "Public"}
          </span>
        </div>
        <div
          className="AlignedRowSelect"
          style={{ flex: AUDIENCE_LIST_FLEX_SECTIONS[2], marginLeft: 28 }}
        >
          <span style={{ ...styles.subtext, marginLeft: 14 }}>
            {item.numberMembers}
          </span>
        </div>
        <div
          className="AlignedRowSpacedSelect"
          style={{
            flex: AUDIENCE_LIST_FLEX_SECTIONS[3],
            marginLeft: 28,
            gap: 14,
          }}
        >
          <span
            className="TwoLineText"
            style={{ ...styles.subtext, width: "15vw" }}
          >
            {item.description !== "" ? item.description : "No Description"}
          </span>
          <div style={{ cursor: "pointer" }}>
            <Icon icon="mdi:chevron-right" height={22} color={Colors.GRAY1} />
          </div>
        </div>
      </div>
      <HorizontalDivider altMargin={20} />
    </div>
  );
};

export default AudienceListItem;
