import { memo } from "react";
import { GetLongDate, getDay, getMonthName } from "../../utils/GetLongDate";
import { isMultipleDays } from "../../utils/SetStartEndDate";
import { GetTime } from "../../utils/GetTime";
import { Event } from "@markit/common.types";
import { WhiteLabelColors } from "../../macros/WhiteLabelUsers";
import { getTimezone } from "@markit/common.utils";
import { useTheme } from "../../hooks/useTheme";

type FullEventTimeCardProps = {
  event: Event;
  isRCA: boolean;
};

const FullEventTimeCard = memo(function FullEventTimeCardFn(
  props: FullEventTimeCardProps
) {
  const { event, isRCA } = props;

  const { theme } = useTheme();

  return (
    <>
      <div className="InfoContainerIcon" style={theme.TertiaryBG}>
        <p style={{ ...theme.PrimaryText, fontWeight: 600, fontSize: "12px" }}>
          {getMonthName(event.start, true).slice(0, -1)}
        </p>
        <p
          style={{
            color: "#FE296C",
            fontWeight: 600,
            fontSize: "14px",
          }}
        >
          {getDay(event.start)}
        </p>
      </div>
      <div style={{ marginLeft: "14px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "3px",
          }}
        >
          <p
            className="FullEventHeaderSectionTitle"
            style={
              isRCA
                ? {
                    color: WhiteLabelColors.RCA_SECONDARY,
                  }
                : { ...theme.PrimaryText }
            }
          >
            {GetLongDate(event.start, false, true).slice(0, -6)}
          </p>
          <p className="FullEventHeaderSectionSubtext" style={theme.SubText}>
            {GetTime(event.start)} to{" "}
            {isMultipleDays(event.start, event.end) &&
              GetLongDate(event.end, false, false, false, false)}{" "}
            {GetTime(event.end)} {getTimezone()}
          </p>
        </div>
      </div>
    </>
  );
});

export default FullEventTimeCard;
