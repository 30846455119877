import "../../css/GlobalStyles.css";
import { Helmet } from "react-helmet";
import { memo, useContext, useCallback, useState, useMemo } from "react";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import { useLocation, useSearchParams } from "react-router-dom";
import { MixpanelContext } from "../../context/AnalyticsService";
import StandardBorderedContainer from "../../components/Containers/StandardBorderedContainer";
import { Colors } from "../../utils/colors";
import CustomTextField from "../../components/CustomTextField";
import { createExternalEventLink, makeEmptyEvent } from "../../utils/makeEvent";
import RectangleButton from "../../components/Buttons/RectangleButton";
import ConfirmExternalLink from "../../components/CreateEvent/ExternalLinks/ConfirmExternalLink";
import { Event } from "@markit/common.types";
import { useNavigate } from "../../hooks/useNavigate";
import { onCreateNavigatePath } from "../../utils/navigationUtils";
import PaymentFailedButtonModal from "../../components/DisplayModal/UpdatePaymentInfoModal";
import { useOnMount } from "../../utils/useOnMount";
import { isSubscriptionPaymentFailed } from "@markit/common.utils";
import CreatorModeWrapper from "../../components/CreatorDashboard/CreatorModeWrapper";

const CreateExternalLink = () => {
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const navigate = useNavigate();
  const mixpanel = useContext(MixpanelContext);
  const [searchParams] = useSearchParams();
  const { confirmState, event } = useLocation().state || {};
  const [externalLink, setExternalLink] = useState("");
  const [error, setError] = useState(false);
  const [showLinkStatus, setShowLinkStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmLinkScreen, setConfirmLinkScreen] = useState(
    confirmState ?? false
  );
  const [paymentFailedVisible, setPaymentFailedVisible] = useState(false);

  // Check if the subscription is Payment Failed
  useOnMount(() => {
    if (isSubscriptionPaymentFailed(accountData.customer.state)) {
      setPaymentFailedVisible(true);
      return;
    }
  });

  const isGenericLink = useMemo(
    () => !searchParams.has("event"),
    [searchParams]
  );

  const [externalEvent, setExternalEvent] = useState<Event>(
    event ??
      makeEmptyEvent(
        accountData.uid,
        accountData.fullName,
        accountData.isAdmin,
        undefined,
        isGenericLink
      )
  );

  const styles = {
    subtext: { fontSize: 14, color: Colors.GRAY1 },
    standardContainer: {
      backgroundColor: Colors.WHITE,
      padding: "24px 14px 14px 14px",
      width: 390,
    },
  };

  const onChangeText = useCallback(
    (e: string) => {
      if (error) {
        setError(false);
      }
      if (e.startsWith("https://")) {
        setShowLinkStatus(true);
      } else {
        setShowLinkStatus(false);
      }
      setExternalLink(e);
    },
    [error]
  );

  const createLinkOnPress = useCallback(async () => {
    if (!externalLink.startsWith("https://")) {
      setShowLinkStatus(true);
      setError(true);
      return;
    }
    setLoading(true);
    const { event: externalEventData, dataScraped } =
      await createExternalEventLink(externalLink, accountData, isGenericLink);
    if (!externalEventData) {
      setLoading(false);
      setError(true);
      return;
    }

    if (mixpanel) {
      mixpanel.track("Generate External Event Link", {
        event_id: externalEventData.id,
        external_link: externalEventData.externalLink,
        type: externalEventData.eventType,
        scraped_data: dataScraped,
        platform: "webapp",
      });
    }
    if (dataScraped) {
      setExternalEvent(externalEventData);
      setConfirmLinkScreen(true);
    } else {
      navigate(onCreateNavigatePath(externalEventData.eventType), {
        state: {
          duplicatedEvent: externalEventData,
          externalNotScraped: true,
        },
      });
    }

    setLoading(false);
  }, [accountData, externalLink, isGenericLink, mixpanel, navigate]);

  const backOnPress = useCallback(() => {
    setConfirmLinkScreen(false);
    setExternalEvent(
      event ??
        makeEmptyEvent(
          accountData.uid,
          accountData.fullName,
          accountData.isAdmin,
          undefined,
          isGenericLink
        )
    );
    setExternalLink("");
    setShowLinkStatus(false);
  }, [
    accountData.fullName,
    accountData.isAdmin,
    accountData.uid,
    event,
    isGenericLink,
  ]);

  return (
    <div>
      <Helmet>
        <title>{"Create External Link"}</title>
        <meta name="og:title" content={"Create External Link"} />
        <meta name="og:description" content={"Create External Link"} />
      </Helmet>
      <CreatorModeWrapper>
        <div className="Centering" style={{ paddingBlock: 100 }}>
          {confirmLinkScreen ? (
            <ConfirmExternalLink
              event={externalEvent}
              backOnPress={backOnPress}
            />
          ) : (
            <StandardBorderedContainer
              containerStyles={{
                ...styles.standardContainer,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  backgroundColor: Colors.BLACK1,
                  height: 47,
                  width: 47,
                  borderRadius: 100,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 24,
                }}
              >
                <Icon
                  icon={"mdi:link-variant"}
                  style={{ color: Colors.WHITE }}
                  height={34}
                />
              </div>
              <div className="ColumnNormal" style={{ gap: 7 }}>
                <span style={{ fontWeight: "600", fontSize: 17 }}>
                  {isGenericLink ? "Create a Link" : "Link an Event"}
                </span>
                <span style={styles.subtext}>
                  Create a unique link that will track open analytics and
                  collect info like name and phone number before redirecting
                  users to your{" "}
                  {isGenericLink ? "provided link" : "linked event"}.
                </span>
              </div>
              <div style={{ paddingBlock: 30 }}>
                <CustomTextField
                  placeholder="https://..."
                  inputMode="url"
                  value={externalLink}
                  onChange={(change: any) => onChangeText(change.target.value)}
                  error={error}
                  backgroundColor={Colors.GRAY6}
                />
                {showLinkStatus ? (
                  <div
                    className="AlignedRow"
                    style={{ paddingTop: 14, gap: 7 }}
                  >
                    <Icon
                      icon={"ion:checkmark-circle"}
                      height={18}
                      color={error ? Colors.RED2 : Colors.GREEN2}
                    />
                    <span
                      style={{
                        fontSize: 14,
                        color: error ? Colors.RED2 : Colors.GREEN2,
                      }}
                    >
                      {error ? "Invalid URL" : "Valid URL"}
                    </span>
                  </div>
                ) : null}
              </div>
              <div className="ColumnNormal" style={{ gap: 10 }}>
                <span style={{ ...styles.subtext, fontWeight: "500" }}>
                  Example URLs:
                </span>
                {isGenericLink ? (
                  <>
                    <span style={styles.subtext}>Websites</span>
                    <span style={styles.subtext}>Online Files</span>
                    <span style={styles.subtext}>Forms</span>
                  </>
                ) : (
                  <>
                    <span style={styles.subtext}>Eventbrite</span>
                    <span style={styles.subtext}>Luma</span>
                    <span style={styles.subtext}>Partiful</span>
                    <span style={styles.subtext}>Posh</span>
                    <span style={styles.subtext}>Markit</span>
                  </>
                )}
              </div>
              <RectangleButton
                buttonLabel={isGenericLink ? "Generate" : "Link Event"}
                onPress={createLinkOnPress}
                disabled={externalLink === ""}
                loading={loading}
                containerStyles={{ marginTop: 100 }}
                altColor={Colors.BLACK}
                altTextColor={Colors.WHITE}
              />
            </StandardBorderedContainer>
          )}
        </div>
      </CreatorModeWrapper>
      {paymentFailedVisible ? (
        <PaymentFailedButtonModal
          location="Create Event"
          isVisible={paymentFailedVisible}
          setIsVisible={() => {
            setPaymentFailedVisible(false);
          }}
          addOnClose={() => navigate("/home")}
        />
      ) : null}
    </div>
  );
};

export default memo(CreateExternalLink);
