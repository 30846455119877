import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  arrayRemove,
  arrayUnion,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { AppThunk } from "../store";
import { WishMark } from "@markit/common.types";
import { eventActions } from "./eventSlice";
import { deepCopy } from "@markit/common.utils";
import { API } from "../../API";
import {
  Batch,
  getUserWishlistRef,
  getUserWishMarkRef,
} from "../../utils/FirebaseUtils";

export type WishMarkState = {
  wishlist: WishMark[];
  currUserWishList: WishMark[];
};

export const initialWishMarkState: WishMarkState = {
  wishlist: [],
  currUserWishList: [],
};

const wishmarkCompareFn = (x: WishMark, y: WishMark) => {
  return new Date(y.createdAt).getTime() - new Date(x.createdAt).getTime();
};

export const wishMarkSlice = createSlice({
  name: "wishMark",
  initialState: initialWishMarkState,
  reducers: {
    addToCurrUserWishList: (state, action: PayloadAction<WishMark>) => {
      if (
        !state.currUserWishList.some(
          (wishMark) => wishMark.id === action.payload.id
        )
      ) {
        state.currUserWishList = state.currUserWishList.concat(action.payload);
      } else {
        state.currUserWishList = state.currUserWishList.filter(
          (wishMark) => wishMark.id !== action.payload.id
        );
        state.currUserWishList = state.currUserWishList.concat(action.payload);
      }
      state.currUserWishList.sort(wishmarkCompareFn);
    },
    fetchCurrUserWishList: (state, action: PayloadAction<WishMark[]>) => {
      state.currUserWishList = state.currUserWishList
        .concat(action.payload)
        .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
      state.currUserWishList.sort(wishmarkCompareFn);
    },
  },
});

export const wishMarkActions = wishMarkSlice.actions;
export const wishMarkReducer = wishMarkSlice.reducer;

export const createWishMarkData =
  (wishMark: WishMark, uid: string, isCurrentUser: boolean): AppThunk =>
  async (dispatch) => {
    const batch = new Batch("Error creating wish mark data");
    const userWishMarkRef = getUserWishMarkRef(uid, wishMark.id);
    batch.set(userWishMarkRef, wishMark);

    try {
      await batch.commit();
      if (isCurrentUser) {
        dispatch(wishMarkActions.addToCurrUserWishList(wishMark));
      }
    } catch (e: any) {
      alert("Error adding wish mark: ");
      console.error("Error adding wish mark: " + e.message);
    }
  };

export const rsvpToWishMark =
  (wishMark: WishMark, uid: string, going?: boolean): AppThunk =>
  async (dispatch) => {
    const batch = new Batch("Error rsvping to wish mark");
    const userWishMarkRef = getUserWishMarkRef(uid, wishMark.id);
    const wishMarkData = (await getDoc(userWishMarkRef)).data();

    if (wishMarkData !== undefined) {
      let newWishMark: WishMark = deepCopy(wishMark);
      // todo (jonathan): ugly because I was dumb and formatted rsvp field poorly
      batch.update(userWishMarkRef, {
        ...wishMark,
        rsvp: arrayRemove({
          going: true,
          uid: uid,
        }),
      });
      batch.update(userWishMarkRef, {
        ...wishMark,
        rsvp: arrayRemove({
          going: false,
          uid: uid,
        }),
      });
      batch.update(userWishMarkRef, {
        ...wishMark,
        rsvp: arrayRemove({
          uid: uid,
        }),
      });

      if (going !== undefined) {
        batch.update(userWishMarkRef, {
          ...wishMark,
          rsvp: arrayUnion({
            going: going,
            uid: uid,
          }),
        });
        newWishMark = {
          ...newWishMark,
          rsvp: [
            {
              going: going,
              uid: uid,
            },
          ],
        };
      } else {
        batch.update(userWishMarkRef, {
          ...wishMark,
          rsvp: arrayUnion({ uid: uid }),
        });
        newWishMark = {
          ...newWishMark,
          rsvp: [{ uid: uid }],
        };
      }

      try {
        await batch.commit();
        dispatch(wishMarkActions.addToCurrUserWishList(newWishMark));
      } catch (e: any) {
        alert("Error sending RSVP to wish mark");
        console.error("Error sending RSVP to wish mark: " + e.message);
      }
    }
  };

export const fetchCurrUserWishList =
  (uid: string): AppThunk =>
  async (dispatch) => {
    await API.user
      .wishList({ uid })
      .then((resp) => {
        const { wishlist, events, tickets, errorFetching } = resp;
        if (errorFetching) {
          console.error("Error fetching wishlist on initial app load");
          return;
        }
        dispatch(eventActions.initialize({ events: events, feed: false }));
        dispatch(eventActions.initializeTickets(tickets));
        dispatch(wishMarkActions.fetchCurrUserWishList(wishlist));
      })
      .catch((error) => console.error(error.message));
  };

export const addToCurrUserWishList =
  (eid: string, uid: string): AppThunk =>
  async (dispatch) => {
    const wishMarkQuery = query(
      getUserWishlistRef(uid),
      where("eventId", "==", eid)
    );
    const wishMarkSnap = await getDocs(wishMarkQuery);
    if (wishMarkSnap.empty) {
      return;
    }
    const wishmark = wishMarkSnap.docs[0].data();
    dispatch(wishMarkActions.addToCurrUserWishList(wishmark));
  };
