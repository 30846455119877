import {
  Event,
  RequestStatus,
  TicketType,
  TicketV2,
} from "@markit/common.types";
import { IoIosArrowDown } from "react-icons/io";
import { Colors } from "../../utils/colors";
import { useCallback, useMemo, useState } from "react";
import { DropdownMenu, DropdownMenuItem } from "../DropdownMenu";
import { isFreeTicket } from "@markit/common.utils";
import { API } from "../../API";
import { LightTheme, useTheme } from "../../hooks/useTheme";
import ConfirmDeleteModal from "../Containers/ConfirmPopups/ConfirmDeleteModal";

type FullEventManageTicketsProps = {
  event: Event;
  userId: string;
  mainUserTicket?: TicketV2;
  numOfTickets: number;
  isTicketStatusPending: boolean;
};

export const FullEventManageTickets = (props: FullEventManageTicketsProps) => {
  const { event, userId, mainUserTicket, numOfTickets, isTicketStatusPending } =
    props;

  const { theme } = useTheme();

  const [removeTicketConfirm, setRemoveTicketConfirm] = useState(false);

  const dropdownItems: DropdownMenuItem[] = useMemo(
    () => [
      {
        title: isTicketStatusPending ? "Cancel Request" : "Cancel Ticket",
        icon: "ion:exit-outline",
        key: "cancel_ticket",
        onPress: () => setRemoveTicketConfirm(true),
        isDestructive: true,
      },
    ],
    [isTicketStatusPending]
  );

  // Show cancel button if it's a non-paid ticket type and also check that it's not a free redeem ticket as well
  const showCancelTicketButton = useMemo(() => {
    const foundCustomTicket = event.customTickets.find(
      (ticket) => ticket.id === mainUserTicket?.customTicketId
    );
    return (
      mainUserTicket &&
      foundCustomTicket?.type !== TicketType.PAID &&
      isFreeTicket(event, mainUserTicket)
    );
  }, [event, mainUserTicket]);

  const removeFreeTicketOnPress = useCallback(async () => {
    if (event && mainUserTicket) {
      await API.payment.removeFreeTicket({
        hostId: event.createdBy,
        userId: userId,
        eventId: event.id,
        ticket: mainUserTicket,
        sendNotification: false,
      });
      if (numOfTickets === 1) {
        window.location.reload();
      }
    }
  }, [event, mainUserTicket, numOfTickets, userId]);

  return (
    <>
      {(numOfTickets === 1 || isTicketStatusPending) &&
      showCancelTicketButton ? (
        <div style={{ flex: 1 }}>
          <DropdownMenu
            dropdownItems={dropdownItems}
            trigger={["click"]}
            placement="bottom"
          >
            <div style={{ justifyContent: "center", gap: "7px" }}>
              <div
                className="FullEventFinalWhiteButton"
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "7px",
                  backgroundColor: isTicketStatusPending
                    ? numOfTickets === 1
                      ? theme.PrimaryText.color
                      : Colors.GRAY6
                    : theme.TertiaryBG.backgroundColor,
                  color: isTicketStatusPending
                    ? numOfTickets === 1
                      ? theme === LightTheme
                        ? Colors.WHITE
                        : Colors.BLACK
                      : Colors.GRAY2
                    : theme.PrimaryText.color,
                  pointerEvents:
                    isTicketStatusPending && numOfTickets > 1 ? "none" : "auto",
                }}
              >
                <span>
                  {mainUserTicket &&
                  mainUserTicket.requestStatus === RequestStatus.ACCEPTED
                    ? "Going"
                    : isTicketStatusPending
                    ? "Requested"
                    : "Cancel Request"}
                </span>
                <IoIosArrowDown
                  size={14}
                  style={{
                    color: isTicketStatusPending
                      ? numOfTickets === 1
                        ? theme === LightTheme
                          ? Colors.WHITE
                          : Colors.BLACK
                        : Colors.GRAY2
                      : theme.PrimaryText.color,
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          </DropdownMenu>
        </div>
      ) : null}
      <ConfirmDeleteModal
        heading="Are you sure you want to remove this ticket?"
        subtext="You cannot undo this."
        deleteButtonText="Remove"
        hideModal={!removeTicketConfirm}
        setIsVisible={setRemoveTicketConfirm}
        deleteOnPress={removeFreeTicketOnPress}
        theme={theme}
      />
    </>
  );
};
