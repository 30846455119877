import {
  SubSelectionItem,
  SubSelectionStatusId,
  SubSelectionType,
  Event,
} from "@markit/common.types";
import { useMemo, useState } from "react";
import { Colors } from "../../../../utils/colors";
import { MassTextsActionItem } from "../Items/MassTextsActionItem";
import { Icon } from "@iconify/react";
import { HorizontalDivider } from "../../../Dividers/HorizontalDivider";
import { isEventExternalLink } from "@markit/common.utils";
import TextCategoriesTipsModal from "../../../DisplayModal/TextCategoriesTipsModal";

type SelectRecipientsSuggestedCategoriesProps = {
  event: Event;
  excludingMode: boolean;
  selectedCategories: SubSelectionItem[];
  selectOnPress: (
    tabValue: number,
    isAllAttendees?: boolean,
    isAllPeople?: boolean
  ) => void;
  hideAllAttendees: boolean;
};

const SelectRecipientsSuggestedCategories = (
  props: SelectRecipientsSuggestedCategoriesProps
) => {
  const {
    event,
    selectedCategories,
    excludingMode,
    selectOnPress,
    hideAllAttendees,
  } = props;
  const [showTipsModal, setShowTipsModal] = useState(false);

  const iconColor = useMemo(
    () => (excludingMode ? Colors.RED3 : Colors.PURPLE7),
    [excludingMode]
  );

  const isExternalLink = useMemo(
    () => isEventExternalLink(event.eventType),
    [event.eventType]
  );

  const ActionItemDivider = (
    <div style={{ paddingLeft: 62 }}>
      <HorizontalDivider />
    </div>
  );

  return (
    <>
      <div className="ColumnNormal" style={{ gap: 7 }}>
        <div className="AlignedRow" style={{ gap: 7 }}>
          <span className="bodySubtext">
            Browse {isEventExternalLink(event.eventType) ? "" : "Event "}
            Categories
          </span>
          <Icon
            icon="mdi:information-outline"
            onClick={() => setShowTipsModal(true)}
            height={14}
            style={{ marginBottom: -1, cursor: "pointer", color: Colors.GRAY1 }}
          />
        </div>
        {!excludingMode && !hideAllAttendees ? (
          <>
            <MassTextsActionItem
              title={isExternalLink ? "All People" : "All Attendees"}
              icon={<Icon icon="ion:people" height={32} color={iconColor} />}
              onPress={() =>
                isExternalLink
                  ? selectOnPress(0, false, true)
                  : selectOnPress(0, true)
              }
              checkColor={iconColor}
              isCheckSelected={selectedCategories.some((category) =>
                isExternalLink
                  ? category.type === SubSelectionType.ALL_PEOPLE
                  : category.type === SubSelectionType.STATUS &&
                    category.id === SubSelectionStatusId.ATTENDEES
              )}
            />
            {ActionItemDivider}
          </>
        ) : null}
        {!isExternalLink ? (
          <>
            <MassTextsActionItem
              title={"Status"}
              icon={<Icon icon="ion:scan" height={30} color={iconColor} />}
              onPress={() => selectOnPress(1)}
              alternateRightIcon={
                <Icon
                  icon="ion:chevron-right"
                  height={16}
                  color={Colors.GRAY1}
                />
              }
            />
            {ActionItemDivider}
          </>
        ) : null}
        <MassTextsActionItem
          title={"Form Responses"}
          icon={<Icon icon="ion:document" height={30} color={iconColor} />}
          onPress={() => selectOnPress(isExternalLink ? 1 : 2)}
          alternateRightIcon={
            <Icon icon="ion:chevron-right" height={16} color={Colors.GRAY1} />
          }
        />
        {!isExternalLink ? (
          <>
            {ActionItemDivider}
            <MassTextsActionItem
              title={"Ticket Type"}
              icon={<Icon icon="ion:ticket" height={30} color={iconColor} />}
              onPress={() => selectOnPress(3)}
              alternateRightIcon={
                <Icon
                  icon="ion:chevron-right"
                  height={16}
                  color={Colors.GRAY1}
                />
              }
            />
            {ActionItemDivider}
          </>
        ) : null}
      </div>
      {showTipsModal ? (
        <TextCategoriesTipsModal setModalVisible={setShowTipsModal} />
      ) : null}
    </>
  );
};

export default SelectRecipientsSuggestedCategories;
