export type AsyncLoadingState<T> =
  | LoadedState<T>
  | LoadingState
  | ErrorState
  | NotLoadedState;
interface NotLoadedState {
  isLoading: false;
  isError: false;
  data: undefined;
}
interface LoadingState {
  isLoading: true;
  isError: false;
  data: undefined;
}
interface LoadedState<T> {
  isLoading: false;
  isError: false;
  data: T;
}
interface ErrorState {
  isLoading: false;
  isError: true;
  data: undefined;
}

export const createAsyncNotLoadedState = <T>(): AsyncLoadingState<T> => {
  return {
    isLoading: false,
    isError: false,
    data: undefined,
  };
};

export const createAsyncLoadingState = <T>(): AsyncLoadingState<T> => {
  return {
    isLoading: true,
    isError: false,
    data: undefined,
  };
};

export const createAsyncErrorState = <T>(): AsyncLoadingState<T> => {
  return {
    isLoading: false,
    isError: true,
    data: undefined,
  };
};

export const createAsyncLoadedState = <T>(data: T) => {
  return {
    isLoading: false,
    isError: false,
    data,
  };
};

export function isLoading<T>(
  value: AsyncLoadingState<T>
): value is LoadingState {
  return value.isLoading;
}

export function isLoaded<T>(
  value: AsyncLoadingState<T>
): value is LoadedState<T> {
  return !value.isLoading && !value.isError;
}

export function isError<T>(value: AsyncLoadingState<T>): value is ErrorState {
  return value.isError;
}

export function getLoadedValue<T>(value: AsyncLoadingState<T>): T | undefined {
  if (isLoaded(value)) {
    return value.data;
  }
  return undefined;
}
