import { isDesktop } from "react-device-detect";
import { Colors } from "../../utils/colors";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";

const FeaturedEventsEmptyState = () => {
  const styles = {
    emptyCard: {
      backgroundColor: Colors.GRAY6,
      borderRadius: 20,
      paddingBottom: isDesktop ? "136%" : "23.5%",
      marginRight: isDesktop ? 0 : 14,
    },
  };
  return (
    <div
      className={isDesktop ? "FeaturedEventList" : "ColumnNormal"}
      style={{
        gridTemplateRows: `repeat(4, auto)`,
        overflow: isDesktop ? "hidden" : "scroll",
        position: "relative",
      }}
    >
      {isDesktop ? (
        <>
          <div style={styles.emptyCard} />
          <div style={styles.emptyCard} />
          <div style={styles.emptyCard} />
        </>
      ) : (
        <HorizontalDivider altMargin={20} />
      )}
      <div style={styles.emptyCard} />
      <span
        style={{
          color: Colors.GRAY1,
          position: "absolute",
          top: isDesktop ? "45%" : "64%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        No Trending Events
      </span>
    </div>
  );
};

export default FeaturedEventsEmptyState;
