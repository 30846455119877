import { useState } from "react";
import { Colors } from "../../utils/colors";
import { Icon } from "@iconify/react";
import StandardBorderedContainer from "../Containers/StandardBorderedContainer";

type EventDashboardActionProps = {
  title: string;
  iconName: string;
  iconSize: number;
  onPress: () => void;
  visible: boolean;
  containerWidth?: string | number;
};

const EventDashboardAction = (props: EventDashboardActionProps) => {
  const { title, iconName, iconSize, onPress, visible, containerWidth } = props;

  const [onHover, setOnHover] = useState(false);

  const styles = {
    iconContainer: {
      width: 45,
      height: 45,
      borderRadius: 12,
      backgroundColor: onHover ? Colors.BLACK : Colors.GRAY6,
      marginRight: 14,
    },

    option: {
      padding: 7,
    },

    optionsContainer: {
      backgroundColor: onHover ? Colors.BLACK : Colors.WHITE,
      width: containerWidth ?? 377,
      borderRadius: 14,
    },
  };

  return (
    <>
      {visible ? (
        <StandardBorderedContainer containerStyles={styles.optionsContainer}>
          <div
            className="AlignedRowSpaced"
            onClick={onPress}
            onMouseEnter={() => setOnHover && setOnHover(true)}
            onMouseLeave={() => setOnHover && setOnHover(false)}
            style={{ ...styles.option, cursor: "pointer" }}
          >
            <div className="AlignedRow" style={{ cursor: "pointer" }}>
              <div
                className="Centering"
                style={{ ...styles.iconContainer, cursor: "pointer" }}
              >
                <Icon
                  icon={iconName}
                  height={iconSize}
                  color={onHover ? Colors.WHITE : Colors.BLACK}
                />
              </div>
              <div>
                <span
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: onHover ? Colors.WHITE : Colors.BLACK,
                    cursor: "pointer",
                  }}
                >
                  {title}
                </span>
              </div>
            </div>
            <Icon
              icon="mdi:chevron-right"
              height={18}
              color={Colors.GRAY1}
              style={{ marginRight: 7 }}
            />
          </div>
        </StandardBorderedContainer>
      ) : null}
    </>
  );
};

export default EventDashboardAction;
