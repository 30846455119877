import "../../css/Containers/ContainerStyles.css";
import { Colors } from "../../utils/colors";

type GenericButtonProps = {
  children: React.ReactNode;
  onPress: () => void;
  width?: number | string;
  altColor?: string;
  altPadding?: number;
  altPaddingHorizontal?: number;
  altBorderRadius?: number;
  disabled?: boolean;
};

const GenericButton = (props: GenericButtonProps) => {
  const {
    children,
    onPress,
    altColor,
    width,
    altPadding,
    altPaddingHorizontal,
    altBorderRadius,
    disabled,
  } = props;

  return (
    <div
      style={{
        backgroundColor: altColor ?? Colors.BLUE5,
        paddingBlock: altPadding || 12,
        paddingInline: altPaddingHorizontal || 0,
        borderRadius: altBorderRadius || 10,
        width: width || "100%",
        cursor: disabled ? "auto" : "pointer",
        pointerEvents: disabled ? "none" : "all",
      }}
      onClick={onPress}
    >
      {children}
    </div>
  );
};

export default GenericButton;
