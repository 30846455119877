import { useCallback, useMemo, useState } from "react";
import {
  FormInputType,
  FormResponseV2,
  SavedFormQuestion,
  SubSelectionItem,
  SubSelectionType,
} from "@markit/common.types";
import FlatList from "flatlist-react/lib";
import { EmptySearchState } from "../../../../EmptyStates/EmptySearchState";
import { RecipientsAddAllItem } from "../../Items/RecipientsAddAllItem";
import { RecipientsAddAllItemDropdown } from "../../Items/RecipientsAddAllItemDropdown";
import { Colors } from "../../../../../utils/colors";
import {
  formQuestionHasOptions,
  questionInputName,
} from "../../../../../utils/eventUtils/formUtils";
import { HorizontalDivider } from "../../../../Dividers/HorizontalDivider";
import {
  addOrFilterCategories,
  addOrFilterRecipients,
  isAllPeopleCategorySelected,
  isItemGroupSelected,
  isQuestionCategorySelected,
} from "@markit/common.utils";

type SelectRecipientItemsFormQuestionProps = {
  formQuestion: SavedFormQuestion;
  formQuestionResponses: FormResponseV2[];
  excludingMode: boolean;
  recipients: string[];
  setRecipients: (recipients: string[]) => void;
  categoriesToShow: SubSelectionItem[];
  setCategoriesToShow: (categoriesToShow: SubSelectionItem[]) => void;
  showCategories: boolean;
};

const SelectRecipientItemsFormQuestion = (
  props: SelectRecipientItemsFormQuestionProps
) => {
  const {
    formQuestion,
    formQuestionResponses,
    excludingMode,
    recipients,
    setRecipients,
    categoriesToShow,
    setCategoriesToShow,
    showCategories,
  } = props;
  const [selectedFormQuestion, setSelectedFormQuestion] =
    useState<SavedFormQuestion>();

  const styles = {
    optionSubtext: { fontSize: 12, marginTop: 3, color: Colors.GRAY1 },
  };

  const questionSelected = useMemo(
    () => isQuestionCategorySelected(categoriesToShow, formQuestion.id),
    [categoriesToShow, formQuestion.id]
  );

  const formResponseAttendees = useMemo(
    () =>
      formQuestionResponses
        .filter(
          (response) =>
            response.answers[formQuestion.id] &&
            response.answers[formQuestion.id].length > 0
        )
        .map((response) => response.uid),
    [formQuestion.id, formQuestionResponses]
  );

  const showFormQuestionDropdown = useMemo(
    () =>
      (formQuestion.formInput.inputType === FormInputType.CHECK_BOX ||
        formQuestion.formInput.inputType === FormInputType.RADIO ||
        formQuestion.formInput.inputType === FormInputType.PHONE) &&
      formQuestion.formInput.options.length > 1,
    [formQuestion]
  );

  const selectedItemsToShow = useMemo(
    () =>
      selectedFormQuestion &&
      (selectedFormQuestion.formInput.inputType === FormInputType.CHECK_BOX ||
        selectedFormQuestion.formInput.inputType === FormInputType.RADIO ||
        selectedFormQuestion.formInput.inputType === FormInputType.PHONE) &&
      selectedFormQuestion.formInput.options.length > 1
        ? selectedFormQuestion.formInput.options
        : [],
    [selectedFormQuestion]
  );

  const formResponsesOnPress = useCallback(
    (formQuestion: SavedFormQuestion) => {
      if (selectedFormQuestion && selectedFormQuestion.id === formQuestion.id) {
        setSelectedFormQuestion(undefined);
      } else {
        setSelectedFormQuestion(formQuestion);
      }
    },
    [selectedFormQuestion]
  );

  const onSelectAllQuestionUsers = useCallback(() => {
    if (showCategories) {
      const newSubSelectionItem: SubSelectionItem = {
        type: SubSelectionType.FORM_QUESTION,
        id: formQuestion.id,
        formOption: "",
      };
      const foundQuestionCategories = categoriesToShow.filter(
        (category) => category.id !== formQuestion.id
      );
      addOrFilterCategories(
        isQuestionCategorySelected(categoriesToShow, formQuestion.id)
          ? categoriesToShow
          : foundQuestionCategories,
        setCategoriesToShow,
        newSubSelectionItem
      );
    } else {
      addOrFilterRecipients(recipients, setRecipients, formResponseAttendees);
    }
  }, [
    categoriesToShow,
    formQuestion.id,
    formResponseAttendees,
    recipients,
    setCategoriesToShow,
    setRecipients,
    showCategories,
  ]);

  const onSelectAllOptionUsers = useCallback(
    (option: string, responseAttendees: string[]) => {
      if (showCategories && selectedFormQuestion) {
        const newSubSelectionItem: SubSelectionItem = {
          type: SubSelectionType.FORM_QUESTION,
          id: selectedFormQuestion.id,
          formOption: option,
        };
        addOrFilterCategories(
          categoriesToShow,
          setCategoriesToShow,
          newSubSelectionItem
        );
      } else {
        addOrFilterRecipients(recipients, setRecipients, responseAttendees);
      }
    },
    [
      showCategories,
      selectedFormQuestion,
      categoriesToShow,
      setCategoriesToShow,
      recipients,
      setRecipients,
    ]
  );

  return (
    <div>
      <RecipientsAddAllItemDropdown
        mainText={formQuestion.formInput.label ?? "No Label"}
        subText={
          <div className="ColumnNormal">
            <span style={styles.optionSubtext}>
              {questionInputName(formQuestion.formInput.inputType)}
            </span>
            {!showCategories ? (
              <span style={styles.optionSubtext}>
                {formResponseAttendees.length} Response
                {formResponseAttendees.length !== 1 ? "s" : ""}
              </span>
            ) : null}
          </div>
        }
        excludingMode={excludingMode}
        isAllSelected={isItemGroupSelected(
          showCategories ? categoriesToShow : formResponseAttendees,
          recipients,
          showCategories,
          {
            type: SubSelectionType.FORM_QUESTION,
            id: formQuestion.id,
            formOption: "",
          }
        )}
        selectRecipients={formResponseAttendees}
        onPress={() => formResponsesOnPress(formQuestion)}
        onCheckPress={onSelectAllQuestionUsers}
        showDropdown={selectedFormQuestion?.id === formQuestion.id}
        hideDropdownArrow={!showFormQuestionDropdown}
        isCategories={showCategories}
        disabled={
          (!showCategories && formResponseAttendees.length === 0) ||
          isAllPeopleCategorySelected(categoriesToShow)
        }
      />
      {selectedFormQuestion &&
      selectedFormQuestion.id === formQuestion.id &&
      formQuestionHasOptions(formQuestion.formInput.inputType) ? (
        <FlatList
          list={selectedItemsToShow}
          renderItem={(item) => {
            const responseAttendees = formQuestionResponses
              .filter(
                (response) =>
                  response.answers[formQuestion.id] &&
                  response.answers[selectedFormQuestion.id].includes(item)
              )
              .map((response) => response.uid);
            return (
              <RecipientsAddAllItem
                label={item}
                sublabel={
                  !showCategories
                    ? `Response${responseAttendees.length !== 1 ? "s" : ""}`
                    : undefined
                }
                selectRecipients={responseAttendees}
                isAllSelected={
                  isItemGroupSelected(
                    showCategories ? categoriesToShow : responseAttendees,
                    recipients,
                    showCategories,
                    {
                      type: SubSelectionType.FORM_QUESTION,
                      id: formQuestion.id,
                      formOption: item,
                    }
                  ) || questionSelected
                }
                onPress={() => onSelectAllOptionUsers(item, responseAttendees)}
                isCategories={showCategories}
                excludingMode={excludingMode}
                disabled={questionSelected}
                noDivider
              />
            );
          }}
          renderWhenEmpty={<EmptySearchState mainText="No responses to show" />}
        />
      ) : null}
      <HorizontalDivider altMargin={5} />
    </div>
  );
};

export default SelectRecipientItemsFormQuestion;
