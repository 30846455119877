import "../../../css/Containers/ContainerStyles.css";
import { Colors } from "../../../utils/colors";
import PopupModalContainer, {
  PopupModalContainerWidth,
} from "../PopupModalContainer";
import RectangleButton from "../../Buttons/RectangleButton";
import { ThemeStyle } from "../../../hooks/useTheme";
import { ReactNode } from "react";

type ConfirmActionModalProps = {
  heading: string;
  subtext?: string | ReactNode;
  confirmButtonText: string;
  hideModal: boolean;
  setIsVisible: (isVisible: boolean) => void;
  confirmOnPress: () => void;
  cancelOnPress?: () => void;
  disableConfirm?: boolean;
  icon?: ReactNode;
  alternateConfirmBtn?: ReactNode;
  theme?: ThemeStyle;
};

// Used as a replacement to browser window.confirm to confirm an action or cancel
const ConfirmActionModal = (props: ConfirmActionModalProps) => {
  const {
    heading,
    subtext,
    confirmButtonText,
    hideModal,
    setIsVisible,
    confirmOnPress,
    cancelOnPress,
    disableConfirm,
    icon,
    alternateConfirmBtn,
    theme,
  } = props;

  return (
    <PopupModalContainer
      headerComp={
        <div className="ColumnNormal" style={{ gap: 4 }}>
          {icon}
          <span style={{ fontSize: 16, fontWeight: "500" }}>{heading}</span>
        </div>
      }
      subHeaderComp={
        <div style={{ marginTop: 7 }}>
          {subtext ? (
            (subtext as string) ? (
              <span
                style={{
                  fontSize: 14,
                  color: Colors.GRAY1,
                  whiteSpace: "none",
                }}
              >
                {subtext}
              </span>
            ) : (
              subtext
            )
          ) : null}
        </div>
      }
      valueComp={
        <div className="RowNormal" style={{ gap: 14 }}>
          <RectangleButton
            buttonLabel="Cancel"
            onPress={() => {
              if (cancelOnPress) {
                cancelOnPress();
              }
              setIsVisible(false);
            }}
            altTextColor={Colors.BLACK}
            altColor={Colors.GRAY6}
            altPaddingVert={14}
          />
          {alternateConfirmBtn ?? (
            <RectangleButton
              buttonLabel={confirmButtonText}
              onPress={() => {
                confirmOnPress();
                setIsVisible(false);
              }}
              disabled={disableConfirm}
              altTextColor={Colors.WHITE}
              altColor={Colors.BLACK}
              altPaddingVert={14}
            />
          )}
        </div>
      }
      noExit
      closeModal={() => {}}
      hideModal={hideModal}
      theme={theme}
      modalContentWidth={PopupModalContainerWidth.SMALL}
    />
  );
};

export default ConfirmActionModal;
