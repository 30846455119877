import { CSSProperties, memo, useState } from "react";
import { Colors } from "../../../utils/colors";
import StandardBorderedContainer from "../../Containers/StandardBorderedContainer";

type ConversationSidebarEmptyStateProps = {
  title: string | JSX.Element;
  subtext: string | JSX.Element;
  containerStyles?: CSSProperties;
  onPress?: () => void;
};

export const ConversationSidebarEmptyState = memo(
  function ConversationSidebarEmptyStateFn(
    props: ConversationSidebarEmptyStateProps
  ) {
    const { title, subtext, containerStyles, onPress } = props;
    const [isHovered, setIsHovered] = useState(false);

    return (
      <StandardBorderedContainer
        containerStyles={{
          alignItems: "center",
          justifyContent: "space-between",
          padding: 14,
          backgroundColor: isHovered ? Colors.WHITE1 : Colors.WHITE,
          pointerEvents: onPress !== undefined ? "all" : "none",
          cursor: "pointer",
          ...containerStyles,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginRight: 14,
            gap: 7,
          }}
          onClick={onPress}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <span
            style={{
              fontSize: 16,
              color: Colors.BLACK,
              fontWeight: "500",
              cursor: "pointer",
            }}
          >
            {title}
          </span>

          <p
            style={{
              fontSize: 14,
              color: Colors.GRAY2,
              cursor: "pointer",
              lineHeight: 1.3,
            }}
          >
            {subtext}
          </p>
        </div>
      </StandardBorderedContainer>
    );
  }
);
