import { styled, Tab, Tabs } from "@mui/material";

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  variant?: "standard" | "scrollable" | "fullWidth" | undefined;
  scrollButtons?: boolean;
}

export function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    //   "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const CustomTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 0,
    width: "100%",
  },
});

interface StyledTabProps {
  label: any;
  disabled?: boolean;
}

export const CustomTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  color: "rgba(0, 0, 0, 0.85)",
  "&.Mui-selected": {
    color: "rgba(0, 0, 0, 0.85)",
  },
  "&.Mui-focusVisible": {},
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  paddingBottom: 0,
  paddingTop: 0,
  paddingRight: 0,
  paddingLeft: 0,
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  "&:hover": {
    opacity: 1,
  },
}));
