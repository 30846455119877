import { useCallback, useContext, useMemo, useState } from "react";
import {
  Event,
  MassText,
  MassTextAttachment,
  SubSelectionItem,
} from "@markit/common.types";
import { MixpanelContext } from "../../context/AnalyticsService";
import { useTheme } from "../../hooks/useTheme";
import { Colors } from "../../utils/colors";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import {
  eventPercentSetUpChangedEventTexts,
  eventPercentSetUpFinishedInviteFollowing,
  isEventExternalLink,
  isExternalLinkNoData,
} from "@markit/common.utils";
import LargePopupModalContainer from "../Containers/LargePopupModalContainer";
import { EventPercentSetUpChecklist } from "../EventDashboard/EventPercentSetUpChecklist";
import HelpModal from "../Containers/HelpModal";
import GenericButton from "./GenericButton";
import { useOnMount } from "../../utils/useOnMount";
import { fetchNumSubscribedFollowersNotFromEvent } from "../../utils/userUtils";

type SuggestedActionButtonProps = {
  event: Event;
  loadingSuggestedActions: boolean;
  isHost: boolean;
  eventMassTexts: MassText[];
  regularMassTextsInRange: MassText[];
  massTextAttachments: MassTextAttachment[];
  cohostEventTexts: boolean;
  setMassTextsPopupPanelVisible?: (massTextsPopupPanelVisible: boolean) => void;
  setSuggestedAction?: (suggestedAction: SubSelectionItem) => void;
  openEventTexts: () => void;
  navigateEventTextBlast?: (inviteGuests: boolean) => void;
};

const SuggestedActionButton = (props: SuggestedActionButtonProps) => {
  const {
    event,
    loadingSuggestedActions,
    isHost,
    eventMassTexts,
    regularMassTextsInRange,
    massTextAttachments,
    cohostEventTexts,
    setMassTextsPopupPanelVisible,
    setSuggestedAction,
    openEventTexts,
    navigateEventTextBlast,
  } = props;
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const mixpanel = useContext(MixpanelContext);
  const { theme } = useTheme();

  const [percentSetUpVisible, setPercentSetUpVisible] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState<boolean>(false);

  const styles = {
    buttonText: {
      fontWeight: 500,
      fontSize: 14,
      color: theme.ButtonText.color,
    },
  };

  const [numFollowersNotFromEvent, setNumFollowersNotFromEvent] = useState(0);

  useOnMount(() => {
    (async () => {
      const numFollowersNotFromEvent =
        await fetchNumSubscribedFollowersNotFromEvent(
          accountData.uid,
          event.id
        );
      setNumFollowersNotFromEvent(numFollowersNotFromEvent);
    })();
  });

  const isExternalLink = useMemo(
    () => isEventExternalLink(event.eventType),
    [event.eventType]
  );

  const changedEventTexts = useMemo(() => {
    return eventPercentSetUpChangedEventTexts(eventMassTexts, event.eventType);
  }, [event, eventMassTexts]);

  const finishedInviteFollowing = useMemo(() => {
    return eventPercentSetUpFinishedInviteFollowing(
      regularMassTextsInRange,
      massTextAttachments,
      event.id
    );
  }, [event.id, massTextAttachments, regularMassTextsInRange]);

  const percentToShow = useMemo(() => {
    // If no followers, invite following is not shown, so it can only be 50 or 100%
    if (numFollowersNotFromEvent === 0) {
      if (!changedEventTexts && !isExternalLinkNoData(event.eventType)) {
        return 50;
      } else {
        return 100;
      }
    } else {
      // Has followers case
      if (!changedEventTexts && !finishedInviteFollowing) {
        // If both have not been satisfied
        return 33;
      } else if (
        !isExternalLinkNoData(event.eventType) &&
        ((changedEventTexts && !finishedInviteFollowing) ||
          (!changedEventTexts && finishedInviteFollowing))
      ) {
        // If one or the other has been satisfied
        return 66;
      } else {
        // If both are satisfied
        return 100;
      }
    }
  }, [
    changedEventTexts,
    event.eventType,
    finishedInviteFollowing,
    numFollowersNotFromEvent,
  ]);

  const numStepToShow = useMemo(
    () => (
      <span>
        {numFollowersNotFromEvent === 0
          ? percentToShow === 50
            ? 1
            : 2
          : percentToShow === 33
          ? 1
          : percentToShow === 66
          ? 2
          : 3}{" "}
        of {numFollowersNotFromEvent === 0 ? 2 : 3}
      </span>
    ),
    [numFollowersNotFromEvent, percentToShow]
  );

  const showPercentSetUp = useMemo(
    () =>
      percentToShow !== 100 &&
      ((!loadingSuggestedActions && isHost && numFollowersNotFromEvent > 0) ||
        (isHost && numFollowersNotFromEvent === 0 && !changedEventTexts)),
    [
      changedEventTexts,
      isHost,
      loadingSuggestedActions,
      numFollowersNotFromEvent,
      percentToShow,
    ]
  );

  const percentSetUpOnPress = useCallback(() => {
    if (mixpanel) {
      mixpanel.track("Pressed Event Percent Setup", {
        event_id: event.id,
        from: "in-app",
        on_publish: false,
        type: "main banner",
      });
    }
    setPercentSetUpVisible(true);
  }, [event.id, mixpanel]);

  const setUpButton = useCallback(
    (fullView: boolean) => (
      <div style={{ width: "100%" }}>
        <GenericButton
          onPress={percentSetUpOnPress}
          altColor={Colors.BLUE5}
          width={"auto"}
          altPadding={10}
          altPaddingHorizontal={14}
          altBorderRadius={14}
          disabled={fullView}
        >
          <div
            className="AlignedRowSpaced"
            style={{ cursor: fullView ? "auto" : "pointer" }}
          >
            <div
              className="PercentRedAlertInner"
              style={{ cursor: fullView ? "auto" : "pointer" }}
            >
              <div
                style={{
                  width: 25,
                  height: 25,
                  cursor: fullView ? "auto" : "pointer",
                }}
              >
                <CircularProgressbar
                  value={percentToShow}
                  strokeWidth={10}
                  styles={buildStyles({
                    strokeLinecap: "round",
                    pathColor: Colors.WHITE,
                    trailColor: Colors.BLACK,
                  })}
                />
              </div>
              <div
                className="ColumnNormal"
                style={{ cursor: fullView ? "auto" : "pointer", gap: 4 }}
              >
                <span style={styles.buttonText}>
                  {numStepToShow} steps complete
                </span>
                <div className="PercentRedAlertSubTextWrapper">
                  <span
                    style={{
                      fontSize: 12,
                      color: theme.ButtonText.color,
                      cursor: fullView ? "auto" : "pointer",
                    }}
                  >
                    {fullView
                      ? `Complete the following tasks to finish setting up your ${
                          isExternalLink ? "link" : "event"
                        }.`
                      : `Finish setting up your ${
                          isExternalLink ? "link" : "event"
                        }`}
                  </span>
                </div>
              </div>
            </div>
            {!fullView ? (
              <Icon icon="mdi:chevron-right" height={18} color={Colors.WHITE} />
            ) : null}
          </div>
        </GenericButton>
      </div>
    ),
    [
      isExternalLink,
      numStepToShow,
      percentSetUpOnPress,
      percentToShow,
      styles.buttonText,
      theme.ButtonText.color,
    ]
  );

  const textFollowingButton = useCallback(
    () => (
      <GenericButton
        onPress={() => navigateEventTextBlast && navigateEventTextBlast(true)}
        altColor={Colors.BLUE5}
        width={"377"} // TODO (jonathan): idk why this works, not gonna touch it
        altPadding={17}
        altPaddingHorizontal={14}
        altBorderRadius={16}
      >
        <div className="AlignedRowSpacedSelect">
          <div className="AlignedRowSelect" style={{ flex: 1 }}>
            <Icon
              icon="ion:chatbubble-ellipses"
              height={20}
              color={Colors.WHITE}
            />
            <div
              className="AlignedRowSelect"
              style={{ marginLeft: 10, flex: 1 }}
            >
              <span style={styles.buttonText}>Text Following</span>
            </div>
          </div>
          <Icon icon="ion:arrow-forward" height={22} color={Colors.WHITE} />
        </div>
      </GenericButton>
    ),
    [navigateEventTextBlast, styles.buttonText]
  );

  return (
    <>
      <HelpModal showModal={showHelpModal} setShowModal={setShowHelpModal} />
      <LargePopupModalContainer
        showModal={percentSetUpVisible}
        headerComp={
          <div className="AlignedRowSpaced">
            <div
              className="LargePopupPanelClose"
              style={theme.TertiaryBG}
              onClick={() => setPercentSetUpVisible(false)}
            >
              <Icon
                icon={"mdi:close"}
                height={24}
                color={theme.PrimaryText.color}
              />
            </div>
            <div
              className="HelpContainer"
              onClick={() => setShowHelpModal(true)}
            >
              Help
            </div>
          </div>
        }
        valueComp={
          <div>
            <div style={{ fontSize: 20, fontWeight: "600", marginBottom: 14 }}>
              Percent Set Up
            </div>
            {setUpButton(true)}
            <EventPercentSetUpChecklist
              event={event}
              eventTexts={eventMassTexts}
              userMassTexts={regularMassTextsInRange}
              attachments={massTextAttachments}
              fromSideModal={true}
              setPercentSetUpVisible={setPercentSetUpVisible}
              setMassTextsPopupPanelVisible={setMassTextsPopupPanelVisible}
              setSuggestedAction={setSuggestedAction}
              openEventTexts={openEventTexts}
              numFollowersNotFromEvent={numFollowersNotFromEvent}
            />
          </div>
        }
      />
      {showPercentSetUp ? (
        setUpButton(false)
      ) : cohostEventTexts ? (
        <div style={{ marginBottom: 10 }}>
          <div
            style={{
              fontSize: 12,
              fontWeight: "500",
              color: theme.SubText.color,
              marginBottom: 20,
            }}
          >
            <div>{"You can’t edit or send event texts as a co-host."}</div>
            <div>{"We suggest texting your own following instead."}</div>
          </div>
          {textFollowingButton()}
        </div>
      ) : null}
    </>
  );
};

export default SuggestedActionButton;
