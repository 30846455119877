/* Explore page related Utils */

import { query, where, getDocs, limit } from "../firebase";
import {
  Batch,
  getEventData,
  getEventRef,
  getEventsRef,
  getFeaturedEventSnap,
  getFeaturedEventsRef,
  getFeaturedUsersRef,
  getUserData,
} from "../utils/FirebaseUtils";
import {
  AccountData,
  Event,
  ExploreSubpageInfo,
  FeaturedCities,
  FeaturedEvent,
  FeaturedEventCategories,
  LocationInfo,
  Visibility,
} from "@markit/common.types";
import {
  MARKITAI,
  MARKITEVENTS,
  filterUndefinedValues,
} from "@markit/common.utils";
import { orderBy, QueryConstraint } from "firebase/firestore";

/**
 * Get events that are featured based on city/category if provided
 **/

const fetchEventsInCity = async (cityLocation: LocationInfo) => {
  const date = new Date();

  const eventsQuery = query(
    getEventsRef(),
    orderBy("end", "asc"),
    where("end", ">=", date.toISOString())
  );

  const events = (await getDocs(eventsQuery)).docs.map((snap) => snap.data());

  const eventsInCity = events.filter((event) => {
    const latitude: number[] = cityLocation.latitudeRange;
    const longitude: number[] = cityLocation.longitudeRange;
    return (
      event.lat >= latitude[0] &&
      event.lat <= latitude[1] &&
      event.lng >= longitude[0] &&
      event.lng <= longitude[1] &&
      event.visibility === Visibility.Public &&
      event.isDraft === false &&
      ((!event.externalLink.includes(MARKITAI) &&
        !event.externalLink.includes(MARKITEVENTS)) ||
        event.externalLink === "")
    );
  });

  return eventsInCity;
};

export const updateFeaturedEvent = async (
  featuredEvent: FeaturedEvent,
  isUnfeaturing: boolean
) => {
  try {
    const eventRef = getEventRef(featuredEvent.eventId);
    const batch = new Batch("creating/updating/deleting featured event");
    await batch.update(eventRef, { isFeatured: !isUnfeaturing });

    const featuredEventSnap = await getFeaturedEventSnap(featuredEvent.eventId);
    if (!isUnfeaturing) {
      if (featuredEventSnap.exists()) {
        await batch.update(featuredEventSnap.ref, featuredEvent);
      } else {
        batch.set(featuredEventSnap.ref, featuredEvent);
      }
    } else {
      await batch.delete(featuredEventSnap.ref);
    }
    await batch.commit();
  } catch (error) {
    console.error("Error setting featured event: ", error);
  }
};

type fetchFeaturedEventProps = {
  eventId: string;
};

export const fetchFeaturedEvent = async (props: fetchFeaturedEventProps) => {
  let queryConstraints: QueryConstraint[] = [];
  const { eventId } = props;

  if (eventId !== "") {
    queryConstraints.push(where("eventId", "==", eventId));
  }

  const featuredEventsQuery = query(
    getFeaturedEventsRef(),
    ...queryConstraints
  );
  const featuredEventsSnap = await getDocs(featuredEventsQuery);

  return featuredEventsSnap.docs.map((doc) => doc.data())[0];
};

type fetchFeaturedEventsProps = {
  isBasePage: boolean;
  info?: ExploreSubpageInfo;
  numDisplay?: number;
};

export const fetchFeaturedEvents = async (props: fetchFeaturedEventsProps) => {
  let queryConstraints: QueryConstraint[] = [];
  const { isBasePage, info, numDisplay } = props;

  if (info?.cityName !== undefined && info.locationInfo) {
    return fetchEventsInCity(info.locationInfo);
  }

  if (info?.eventCategory !== undefined) {
    queryConstraints.push(where("eventCategory", "==", info.eventCategory));
  }

  if (isBasePage) {
    queryConstraints.push(where("baseExploreIndex", "==", 1));
  }

  if (numDisplay !== undefined) {
    queryConstraints.push(limit(numDisplay));
  }

  const featuredEventsQuery = query(
    getFeaturedEventsRef(),
    ...queryConstraints
  );
  const featuredEventsSnap = await getDocs(featuredEventsQuery);

  const featuredEvents = await Promise.all(
    featuredEventsSnap.docs.map((doc) => {
      const eventData = getEventData(doc.data().eventId);
      return eventData;
    })
  );

  const filteredFeaturedEvents: Event[] = filterUndefinedValues(featuredEvents);
  return filteredFeaturedEvents;
};

type FetchFeaturedUsersOptions = {
  numDisplay?: number;
  city?: FeaturedCities;
  category?: FeaturedEventCategories;
  onBaseExplore?: boolean;
};

export const fetchFeaturedUsers = async (
  options: FetchFeaturedUsersOptions = {}
) => {
  let queryConstraints: QueryConstraint[] = [];
  const { numDisplay, onBaseExplore, city, category } = options;
  if (onBaseExplore === true) {
    queryConstraints.push(where("baseExploreIndex", "!=", -1));
    queryConstraints.push(orderBy("baseExploreIndex", "asc"));
  }

  if (city !== undefined) {
    queryConstraints.push(where("city", "==", city));
  }

  if (category !== undefined) {
    queryConstraints.push(where("eventCategory", "==", category));
  }

  if (numDisplay !== undefined) {
    queryConstraints.push(limit(numDisplay));
  }

  const featuredUsersQuery = query(getFeaturedUsersRef(), ...queryConstraints);
  const featuredUsersSnap = await getDocs(featuredUsersQuery);
  const featuredUsers = await Promise.all(
    featuredUsersSnap.docs.map((doc) => {
      const userData = getUserData(doc.data().uid);
      return userData;
    })
  );

  const filteredFeaturedUsers: AccountData[] =
    filterUndefinedValues(featuredUsers);
  return filteredFeaturedUsers;
};
