import { Icon } from "@iconify/react";
import { Colors } from "../../../utils/colors";
import { useState } from "react";
import PopupModalContainer from "../../Containers/PopupModalContainer";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import ShareProfileLinkModal from "./ShareProfileLinkModal";

export type ShareProfilePanelHeaderProps = {
  closeOnPress: () => void;
  showBackIcon?: boolean;
};

const ShareProfilePanelHeader = (props: ShareProfilePanelHeaderProps) => {
  const { closeOnPress, showBackIcon } = props;
  const { username } = useSelector(getAccountState).account.accountData;
  const [qrCodeModal, setQrCodeModal] = useState(false);
  const [shareLinkModal, setShareLinkModal] = useState(false);

  return (
    <>
      <div className="AlignedRowSpaced">
        <div className="LargePopupPanelClose" onClick={closeOnPress}>
          <Icon
            icon={showBackIcon ? "ion:chevron-back" : "mdi:close"}
            height={24}
          />
        </div>
        <div className="AlignedRow" style={{ gap: 10 }}>
          <div
            className="LargePopupPanelGrayButton AlignedRowSelect"
            onClick={() => setQrCodeModal(true)}
          >
            <span className="AboutSubtitle">Share QR Code</span>
          </div>
          <div
            className="LargePopupPanelBlackButton AlignedRowSelect"
            onClick={() => setShareLinkModal(true)}
            style={{ gap: 7 }}
          >
            <span className="AboutSubtitle" style={{ color: Colors.WHITE }}>
              Share Link
            </span>
          </div>
        </div>
      </div>
      {/* QR Code Modal */}
      {qrCodeModal ? (
        <PopupModalContainer
          headerComp={
            <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
              <span className="AboutSubtitle">Share QR Code</span>
            </div>
          }
          valueComp={
            <div className="Centering" style={{ paddingBlock: "30px" }}>
              <QRCode value={"https://markitai.com/u/" + username} />
            </div>
          }
          noExit
          closeOnOutsidePress
          closeModal={() => setQrCodeModal(false)}
        />
      ) : null}
      {shareLinkModal ? (
        <ShareProfileLinkModal setShareLinkModal={setShareLinkModal} />
      ) : null}
    </>
  );
};

export default ShareProfilePanelHeader;
