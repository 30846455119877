import { useSelector } from "react-redux";
import {
  AccountData,
  AudienceList,
  Event,
  Follower,
  RecentEventAnalytics,
  SavedFormQuestion,
  SavedFormResponse,
} from "@markit/common.types";
import { getAccountState } from "../../redux/slices/accountSlice";
import { FollowerProfileHeader } from "./FollowerProfileHeader";
import LargePopupModalContainer from "../Containers/LargePopupModalContainer";
import { useOnMount } from "../../utils/useOnMount";
import { useMemo, useState } from "react";
import {
  fetchMostRecentUserAttendedEvents,
  fetchMultipleUserFormResponses,
  fetchUserJoinedAudienceLists,
} from "../../utils/userUtils";
import {
  getNumEventMassTextsToUser,
  getNumMassTextsToUser,
} from "../../utils/textingUtils";
import { sortSavedQuestions } from "@markit/common.utils";
import FollowerProfileHeaderBar from "./FollowerProfileHeaderBar";

type FullFollowerProfilePopupPanelProps = {
  userData: AccountData;
  followerData: Follower | undefined;
  followerEventData: Event | undefined;
  essentialFormResponse: SavedFormResponse | undefined;

  essentialQuestion: SavedFormQuestion | undefined;
  notHost: boolean;
  fullFollowerProfilePopupPanelVisible: boolean;
  goBackToProfilePreview: () => void;
  addToListOnPress: () => void;
  conversationOnPress: () => void;
};

const FullFollowerProfilePopupPanel = (
  props: FullFollowerProfilePopupPanelProps
) => {
  const { account } = useSelector(getAccountState);
  const { accountData, savedQuestions } = account;

  const {
    userData,
    followerData,
    followerEventData,
    essentialFormResponse,
    essentialQuestion,
    notHost,
    fullFollowerProfilePopupPanelVisible,
    goBackToProfilePreview,
    addToListOnPress,
    conversationOnPress,
  } = props;

  const [openDropdown, setOpenDropdown] = useState<boolean>(false);

  const [allFormResponses, setAllFormResponses] = useState<
    (SavedFormResponse | undefined)[]
  >([]);
  const [recentEventsData, setRecentEventsData] = useState<
    RecentEventAnalytics[]
  >([]);
  const [joinedAudienceLists, setJoinedAudienceLists] = useState<
    AudienceList[]
  >([]);
  // const [revenue, setRevenue] = useState<number>(0);
  // const [checkIns, setCheckIns] = useState<number>(0);
  const [numEventTexts, setNumEventTexts] = useState<number>(0);
  const [numMassTexts, setNumMassTexts] = useState<number>(0);

  const savedQuestionsToShow = useMemo(
    () =>
      sortSavedQuestions(savedQuestions, []).filter((question) =>
        essentialQuestion ? essentialQuestion.id !== question.id : true
      ),
    [essentialQuestion, savedQuestions]
  );

  useOnMount(() => {
    (async () => {
      const [
        allResponses,
        recentEvents,
        foundAudienceLists,
        eventTextsToUser,
        massTextsToUser,
      ] = await Promise.all([
        fetchMultipleUserFormResponses(
          accountData.uid,
          userData.uid,
          savedQuestionsToShow.map((question) => question.id)
        ),
        fetchMostRecentUserAttendedEvents(accountData.uid, userData.uid),
        fetchUserJoinedAudienceLists(accountData.uid, userData.uid),
        getNumEventMassTextsToUser(accountData.uid, userData.uid),
        getNumMassTextsToUser(accountData.uid, userData.uid),
      ]);
      setAllFormResponses(allResponses); // only used on full profile
      setRecentEventsData(recentEvents);
      setJoinedAudienceLists(foundAudienceLists);
      setNumEventTexts(eventTextsToUser);
      setNumMassTexts(massTextsToUser);

      // todo (jonathan): for revenue generating logic
      // const allCreatorEvents = events.filter(
      //   event => event.createdBy === accountData.uid
      // );
      // get number of events attended + tickets purchased in tandem
      // const userTickets = await getAllUsersTickets(
      //   event.id,
      //   userData.uid
      // );
      // if (userTickets && userTickets.length > 0) {
      //   userEventsAttended += 1;
      //   userTicketsPurchased += userTickets.length;
      //   for (const ticket of userTickets) {
      //     userRevenue += ticket.amountPaid;
      //     userCheckIns += Number(ticket.scanned);
      //   }
      // }
      // setRevenue(Math.round(userRevenue * 100) / 100); // round to 2 decimals
      // setCheckIns(userCheckIns);
    })();
  });

  // MassTexsPopupPanel main content
  return (
    <LargePopupModalContainer
      showModal={fullFollowerProfilePopupPanelVisible}
      headerComp={
        <FollowerProfileHeaderBar
          userData={userData}
          followerData={followerData}
          openDropdown={openDropdown}
          setOpenDropdown={setOpenDropdown}
          fullProfile={true}
          goBackToProfilePreview={goBackToProfilePreview}
        />
      }
      valueComp={
        <FollowerProfileHeader
          fullProfile={true}
          creatorUid={accountData.uid}
          userData={userData}
          followerData={followerData}
          followerEventData={followerEventData}
          allFormResponses={allFormResponses}
          essentialFormResponse={essentialFormResponse}
          recentEventsData={recentEventsData}
          joinedAudienceLists={joinedAudienceLists}
          numEventTexts={numEventTexts}
          numMassTexts={numMassTexts}
          essentialQuestion={essentialQuestion}
          savedQuestionsToShow={savedQuestionsToShow}
          notHost={notHost}
          conversationOnPress={conversationOnPress}
          addToListOnPress={addToListOnPress}
        />
      }
    />
  );
};

export default FullFollowerProfilePopupPanel;
