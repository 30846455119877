import "../../css/GlobalStyles.css";
import { memo, useCallback } from "react";
import { Colors } from "../../utils/colors";
import { useSelector } from "react-redux";
import {
  getAccountState,
  toggleInCreatorMode,
} from "../../redux/slices/accountSlice";
import { Icon } from "@iconify/react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import SubscriptionDetailsOption from "../Subscription/SubscriptionDetailsOption";
import { DataLoaders } from "../../redux/slices/dataSlice";
import { useNavigate } from "../../hooks/useNavigate";
import { isDesktop } from "react-device-detect";
import StandardBorderedContainer from "../Containers/StandardBorderedContainer";
import { MARKIT_WEBSITE } from "@markit/common.utils";

export const SwitchToAlternateMode = memo(function SwitchToAlternateModeFn() {
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation().state || {};

  const navigateGoToMarkitOnPress = useCallback(() => {
    window.location.replace("markit://?screen=MyAccount&id=" + state);
  }, [state]);

  const navigateGoToWebsiteOnPress = useCallback(() => {
    window.location.href = MARKIT_WEBSITE;
  }, []);

  return (
    <StandardBorderedContainer
      containerStyles={{
        borderRadius: 14,
        padding: 14,
        backgroundColor: Colors.WHITE,
      }}
    >
      <div style={{ width: "100%", cursor: "pointer" }}>
        <div
          className="AlignedRowSpacedSelect"
          onClick={() => {
            if (!accountData.inCreatorMode) {
              dispatch(
                toggleInCreatorMode(accountData.uid, accountData.inCreatorMode)
              );
              navigate("/home");
            } else {
              dispatch(
                toggleInCreatorMode(accountData.uid, accountData.inCreatorMode)
              );
              dispatch(DataLoaders.user(accountData.uid));
              navigate(`/u/${accountData.username}`, {
                state: { username: accountData.username },
              });
            }
          }}
        >
          <div className="ColumnNormalSelect" style={{ gap: 4 }}>
            <span style={{ fontWeight: "600" }}>
              Switch to {accountData.inCreatorMode ? "Attending" : "Hosting"}
            </span>
            <span style={{ color: Colors.GRAY1, fontSize: 13 }}>
              Switch to{" "}
              {accountData.inCreatorMode
                ? "attendee mode to view your profile page and events"
                : "to hosting to manage events & texts"}
            </span>
          </div>
          <div>
            <Icon
              icon={"mdi:swap-horizontal-variant"}
              height={17}
              style={{ paddingRight: 7 }}
            />
          </div>
        </div>
        <hr />
        <div className="ColumnNormal" style={{ gap: "14px" }}>
          {isDesktop ? null : (
            <SubscriptionDetailsOption
              label={"Open Markit App"}
              onPress={navigateGoToMarkitOnPress}
            />
          )}
          <SubscriptionDetailsOption
            label={"Visit Website"}
            onPress={navigateGoToWebsiteOnPress}
          />
        </div>
      </div>
    </StandardBorderedContainer>
  );
});
