import { useCallback, useContext, useState } from "react";
import { AccountData, Event, TicketV2 } from "@markit/common.types";
import { Colors } from "../../utils/colors";
import { Icon } from "@iconify/react";
import { MixpanelContext } from "../../context/AnalyticsService";
import ConfirmActionPopup from "../Containers/ConfirmPopups/ConfirmActionPopup";

type ProfileTicketItemButtonProps = {
  ticketItem: TicketV2;
  profileItem: AccountData | undefined;
  organizer?: boolean;
  isRequestList: boolean;
  scanTicket: (eventId: string, ticketId: string) => void;
  unscanTicket: (eventId: string, ticketId: string) => void;
  approveRequestGuest: (
    eventId: string,
    ticketId: string,
    requester: AccountData
  ) => void;
  denyRequestGuest: (
    eventId: string,
    ticketId: string,
    requester: AccountData
  ) => void;
  liveEvent: Event;
};

export const ProfileTicketItemButton = ({
  liveEvent,
  ticketItem,
  profileItem,
  organizer,
  isRequestList,
  scanTicket,
  unscanTicket,
  approveRequestGuest,
  denyRequestGuest,
}: ProfileTicketItemButtonProps) => {
  const mixpanel = useContext(MixpanelContext);
  const [confirmCopyAlert, setConfirmCopyAlert] = useState(false);

  const styles = {
    approveButton: {
      paddingBlock: 7,
      width: 79,
      borderRadius: 8,
      backgroundColor: Colors.BLACK,
    },

    approveText: {
      fontSize: 12,
      color: Colors.WHITE,
      fontWeight: "600",
    },

    checkedInButton: {
      paddingBlock: 7,
      width: 79,
      borderRadius: 8,
      backgroundColor: Colors.GRAY6,
    },

    checkedInText: {
      fontSize: 11,
      color: Colors.GRAY1,
      fontWeight: "600",
    },

    checkInButton: {
      paddingBlock: 7,
      width: 79,
      borderRadius: 8,
      backgroundColor: Colors.BLACK,
    },

    checkInText: {
      fontSize: 12,
      color: Colors.WHITE,
      fontWeight: "600",
    },

    denyButton: {
      borderRadius: 8,
      backgroundColor: Colors.GRAY6,
      marginLeft: 7,
      width: 28,
    },

    shareRoleButton: {
      paddingBlock: 7,
      width: 106,
      borderRadius: 8,
      backgroundColor: Colors.BLACK,
    },
  };

  const sendUnassignedRoleLink = useCallback(async () => {
    try {
      mixpanel.track("Pressed Share Role For Unassigned Organizer Role", {
        event_id: liveEvent.id,
      });
      const el = document.createElement("input");
      el.value =
        "You have been invited to be a " +
        ticketItem.role.type +
        " for an event! " +
        `\nhttps://markitai.com/e/${liveEvent.id}?ticketId=${ticketItem.id}`;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      setConfirmCopyAlert(true);
    } catch (error: any) {
      alert("Issue trying to send organizer role link");
      console.error(error.message);
    }
  }, [liveEvent.id, mixpanel, ticketItem.id, ticketItem.role.type]);

  return (
    <>
      {confirmCopyAlert ? (
        <ConfirmActionPopup
          title="Copied Link!"
          onDisappear={() => setConfirmCopyAlert(false)}
        />
      ) : null}
      <div style={{ marginLeft: 10, flexDirection: "row" }}>
        {isRequestList && profileItem ? (
          <div className="RowNormal">
            <div
              className="Centering"
              style={{ ...styles.approveButton, cursor: "pointer" }}
              onClick={() =>
                approveRequestGuest(liveEvent.id, ticketItem.id, profileItem)
              }
            >
              <span style={styles.approveText}>Approve</span>
            </div>
            <div
              className="Centering"
              style={{ ...styles.denyButton, cursor: "pointer" }}
              onClick={() =>
                denyRequestGuest(liveEvent.id, ticketItem.id, profileItem)
              }
            >
              <Icon icon="ion:close" height={20} color={Colors.RED3} />
            </div>
          </div>
        ) : organizer && !profileItem ? (
          <div
            className="Centering AlignedRow"
            style={{ ...styles.shareRoleButton, cursor: "pointer" }}
            onClick={sendUnassignedRoleLink}
          >
            <Icon
              icon="material-symbols:link"
              height={15}
              color={Colors.WHITE}
              style={{ marginRight: 7 }}
            />
            <span style={styles.checkInText}>Share Role</span>
          </div>
        ) : profileItem ? (
          <>
            {ticketItem.scanned ? (
              <div
                className="Centering"
                style={{ ...styles.checkedInButton, cursor: "pointer" }}
                onClick={() => unscanTicket(liveEvent.id, ticketItem.id)}
              >
                <div style={styles.checkedInText}>Checked in</div>
              </div>
            ) : (
              <div
                className="Centering"
                style={{ ...styles.checkInButton, cursor: "pointer" }}
                onClick={() => {
                  scanTicket(liveEvent.id, ticketItem.id);
                }}
              >
                <span style={styles.checkInText}>Check In</span>
              </div>
            )}
          </>
        ) : null}
      </div>
    </>
  );
};
