import React, { useState } from "react";
import { AccountData } from "@markit/common.types";
import ProfilePreviewModal from "./ProfilePreviewModal";
import ConversationsSidebarModal from "../CreatorDashboard/Conversations/ConversationsSidebarModal";

interface FullProfilePreviewModalProps {
  profileSelected: AccountData | undefined;
  setProfileSelected: (profileSelected: AccountData | undefined) => void;
}

// Handles the ProfilePreviewModal but also the ConversationsSidebarModal in conjunction
const FullProfilePreviewModal = (props: FullProfilePreviewModalProps) => {
  const { profileSelected, setProfileSelected } = props;
  const [hideProfilePreview, setHideProfilePreview] = useState(false);
  const [fullProfileOpen, setFullProfileOpen] = useState(false);
  const [conversationUser, setConversationUser] = useState<AccountData>();

  return (
    <>
      {profileSelected ? (
        <ProfilePreviewModal
          userData={profileSelected}
          setProfileSelected={setProfileSelected}
          messageOnPress={(item) => {
            setConversationUser(item);
          }}
          hideProfilePreview={hideProfilePreview}
          setHideProfilePreview={setHideProfilePreview}
          fullProfileOpen={fullProfileOpen}
          setFullProfileOpen={setFullProfileOpen}
        />
      ) : null}
      {conversationUser ? (
        <ConversationsSidebarModal
          conversationUser={conversationUser}
          setConversationUser={setConversationUser}
          reopenProfilePreview={() =>
            hideProfilePreview && !fullProfileOpen
              ? setHideProfilePreview(false)
              : null
          }
          fullProfileOpen={fullProfileOpen}
        />
      ) : null}
    </>
  );
};

export default FullProfilePreviewModal;
