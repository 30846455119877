import { useState } from "react";
import { Colors } from "../../utils/colors";
import { Icon } from "@iconify/react";
import { CircularProgress } from "@mui/material";

type BlackHoverButtonProps = {
  title?: string;
  iconName?: string;
  iconSize?: number;
  leftIconName?: string;
  leftIconSize?: number;
  onPress: () => void;
  disabled?: boolean;
  permanentHover?: boolean;
  altTextColor?: string;
  altBackgroundColor?: string;
  containerStyles?: React.CSSProperties;
  iconLoading?: boolean;
};

const BlackHoverButton = (props: BlackHoverButtonProps) => {
  const {
    title,
    iconName,
    iconSize,
    leftIconName,
    leftIconSize,
    onPress,
    disabled,
    permanentHover,
    altTextColor,
    altBackgroundColor,
    containerStyles,
    iconLoading,
  } = props;

  const [onHover, setOnHover] = useState(false);

  const styles = {
    iconContainer: {
      marginLeft: leftIconName ? 0 : 7,
      marginRight: leftIconName && title ? 7 : 0,
      display: "flex",
      flex: 1,
      justifyContent: "center",
    },

    optionContainer: {
      borderRadius: 12,
      opacity: disabled ? 0.5 : 1,
      backgroundColor:
        permanentHover || onHover
          ? Colors.BLACK
          : altBackgroundColor || Colors.GRAY6,
      display: "flex",
      flex: 1,
    },
  };

  return (
    <div
      className="AlignedRow"
      onClick={onPress}
      onMouseEnter={() => (!disabled && !iconLoading ? setOnHover(true) : null)}
      onMouseLeave={() => setOnHover(false)}
      style={{
        ...styles.optionContainer,
        ...containerStyles,
        cursor: !iconLoading ? "pointer" : "default",
        pointerEvents: disabled ? "none" : "all",
      }}
    >
      {leftIconName && leftIconSize ? (
        <div
          style={{
            ...styles.iconContainer,
            cursor: !iconLoading ? "pointer" : "default",
          }}
        >
          <Icon
            icon={leftIconName}
            height={leftIconSize}
            color={
              permanentHover || onHover
                ? Colors.WHITE
                : altTextColor || Colors.BLACK
            }
          />
        </div>
      ) : null}
      {title ? (
        <div
          style={{
            fontSize: 14,
            fontWeight: 500,
            color:
              permanentHover || onHover
                ? Colors.WHITE
                : altTextColor || Colors.BLACK,
            display: "flex",
            flex: 1,
            cursor: !iconLoading ? "pointer" : "default",
          }}
        >
          {title}
        </div>
      ) : null}
      {iconName && iconSize ? (
        <div
          style={{
            ...styles.iconContainer,
            cursor: !iconLoading ? "pointer" : "default",
          }}
        >
          {iconLoading ? (
            <CircularProgress
              style={{
                color:
                  permanentHover || onHover
                    ? Colors.WHITE
                    : altTextColor || Colors.BLACK,
              }}
              size={17}
            />
          ) : (
            <Icon
              icon={iconName}
              height={iconSize}
              color={
                permanentHover || onHover
                  ? Colors.WHITE
                  : altTextColor || Colors.BLACK
              }
            />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default BlackHoverButton;
