import { isDesktop } from "react-device-detect";
import { useTheme } from "../../hooks/useTheme";
import { capitalizeFirstLetter } from "@markit/common.utils";
import { Colors } from "../../utils/colors";
import RectangleButton from "../Buttons/RectangleButton";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";
import { Icon } from "@iconify/react";
import { Event } from "@markit/common.types";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DataLoaders } from "../../redux/slices/dataSlice";

type FullEventExistingTicketInfoProps = {
  totalUserTickets: number;
  event: Event;
  userId: string;
  numTicketsUserInGroups: number[];
};
export const FullEventExistingTicketInfo = (
  props: FullEventExistingTicketInfoProps
) => {
  const { totalUserTickets, event, userId, numTicketsUserInGroups } = props;
  const { theme } = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const viewTickets = useCallback(() => {
    dispatch(DataLoaders.user(userId));
    navigate(`/e/${event.id}/i/${userId}`, {
      state: { eventId: event.id, userId: userId },
    });
  }, [dispatch, userId, navigate, event.id]);

  return (
    <div
      className={isDesktop ? "TicketContainerDesktop" : "TicketContainer"}
      style={theme.SecondaryBG}
    >
      <span className="NameAndNumber" style={{ ...theme.PrimaryText }}>
        <Icon icon="ion:alert-circle" height={20} style={{ marginRight: 7 }} />
        You already have tickets
      </span>
      <HorizontalDivider altMargin={14} />
      <div>
        <span style={{ ...theme.SubText, fontSize: 14 }}>
          You have{" "}
          <span style={{ color: Colors.BLUE5 }}>{`${totalUserTickets}`}</span>
          {` ticket${totalUserTickets > 1 ? "s" : ""} for this event:`}
        </span>
        <ul style={{ marginTop: 7 }}>
          {event.customTickets.map((ticket, index) => {
            return numTicketsUserInGroups[index] > 0 ? (
              <li style={{ ...theme.SubText, fontSize: 14 }}>
                {`${capitalizeFirstLetter(ticket.label)}`}{" "}
                <span
                  style={{ color: Colors.BLUE5 }}
                >{`(${numTicketsUserInGroups[index]})`}</span>
              </li>
            ) : null;
          })}
        </ul>
        <RectangleButton
          buttonLabel="View Your Tickets"
          onPress={viewTickets}
          altPaddingVert={12}
          altTextColor={theme.PrimaryText.color}
          altColor={theme.TertiaryBG.backgroundColor}
          iconRight={
            <Icon
              icon="ion:chevron-right"
              height={12}
              color={theme.PrimaryText.color}
            />
          }
        />
      </div>
    </div>
  );
};
