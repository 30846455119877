import { Route, Routes } from "react-router-dom";
import LoginOnboarding from "../screens/Login/LoginOnboarding";
import LoginWelcome from "../screens/Login/LoginWelcome";

/**
 * (TODO: Peter) For now, this route will house the login screens until we implement login throughout
 * the webapp.
 */
const LoginRoute = () => {
  return (
    <Routes>
      <Route index element={<LoginWelcome />} />
      <Route path="onboarding" element={<LoginOnboarding />} />
    </Routes>
  );
};

export default LoginRoute;
