import { useEffect, useState } from "react";
import { getUserFollowersRef } from "../../utils/FirebaseUtils";
import { onSnapshot, query, where } from "../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import { SpreadsheetStatus, FollowerStatus } from "@markit/common.types";

export const useLiveUpdatingImportAnalytics = () => {
  const dispatch = useDispatch();
  // followersData assumes we have full followersData loaded since you are in select recipients
  const { accountData } = useSelector(getAccountState).account;
  const [
    liveTotalSubscribedImportContacts,
    setLiveTotalSubscribedImportContacts,
  ] = useState<number>(0);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      if (!loaded) {
        // total import contacts
        const followersRef = getUserFollowersRef(accountData.uid);
        const subscribedImportQuery = query(
          followersRef,
          where("spreadsheetData.status", "==", SpreadsheetStatus.ACCEPTED),
          where("status", "==", FollowerStatus.SUBSCRIBED)
        );
        onSnapshot(subscribedImportQuery, (snapshot) => {
          const documentCount = snapshot.size;
          setLiveTotalSubscribedImportContacts(documentCount);

          setLoaded(true);
        });
      }
    })();
  }, [accountData.uid, dispatch, loaded]);

  return {
    liveTotalSubscribedImportContacts,
    loaded,
  };
};
