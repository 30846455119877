import {
  Event,
  MassText,
  MassTextAttachment,
  SubSelectionItem,
  SubSelectionType,
} from "@markit/common.types";
import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import { Colors } from "../../utils/colors";
import { useContext, useMemo } from "react";
import { MixpanelContext } from "../../context/AnalyticsService";
import { Icon } from "@iconify/react";
import {
  eventPercentSetUpChangedEventTexts,
  eventPercentSetUpFinishedInviteFollowing,
  generateSuggestedActionItem,
  isEventExternalLink,
  isExternalLinkNoData,
} from "@markit/common.utils";

type EventPercentSetUpChecklistProps = {
  event: Event;
  fromSideModal: boolean;
  eventTexts: MassText[];
  userMassTexts: MassText[];
  attachments: MassTextAttachment[];
  setPercentSetUpVisible?: (percentSetUpVisible: boolean) => void;
  setPublishedModalVisible?: (publishedModalVisible: boolean) => void;
  setMassTextsPopupPanelVisible?: (massTextsPopupPanelVisible: boolean) => void;
  setSuggestedAction?: (suggestedAction: SubSelectionItem) => void;
  openEventTexts?: () => void;
  numFollowersNotFromEvent: number;
};

export const EventPercentSetUpChecklist = (
  props: EventPercentSetUpChecklistProps
) => {
  const {
    event,
    fromSideModal,
    eventTexts,
    userMassTexts,
    attachments,
    setPercentSetUpVisible,
    setPublishedModalVisible,
    setMassTextsPopupPanelVisible,
    setSuggestedAction,
    openEventTexts,
    numFollowersNotFromEvent,
  } = props;

  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const mixpanel = useContext(MixpanelContext);

  const styles = {
    bodyMedium: { fontSize: 14, fontWeight: 500 },
    placeholder: { fontSize: 12, color: Colors.GRAY2 },
  };

  const isExternalLink = useMemo(
    () => isEventExternalLink(event.eventType),
    [event.eventType]
  );

  const changedEventTexts = useMemo(() => {
    return eventPercentSetUpChangedEventTexts(eventTexts, event.eventType);
  }, [event, eventTexts]);

  const finishedInviteFollowing = useMemo(() => {
    return eventPercentSetUpFinishedInviteFollowing(
      userMassTexts,
      attachments,
      event.id
    );
  }, [attachments, event.id, userMassTexts]);

  const isHost = useMemo(
    () => accountData.uid === event.createdBy,
    [accountData.uid, event.createdBy]
  );

  const showInviteFollowing = useMemo(() => {
    return isHost && numFollowersNotFromEvent > 0;
  }, [isHost, numFollowersNotFromEvent]);

  const showCustomizeReminderTexts = useMemo(
    () => !isExternalLinkNoData(event.eventType),
    [event.eventType]
  );

  return (
    <div style={{ paddingBlock: 14, paddingInline: fromSideModal ? 0 : 14 }}>
      <div
        className="ColumnNormal"
        style={{ gap: 7, marginTop: fromSideModal ? 14 : 0, opacity: 0.5 }}
      >
        <span style={styles.bodyMedium}>
          1. {isExternalLink ? "Link" : "Event"} Published
        </span>
        <span style={styles.placeholder}>
          Your {isExternalLink ? "link" : "event"} is live. Edit your{" "}
          {isExternalLink ? "link preferences" : "event details"} anytime.
        </span>
      </div>
      <hr />
      {showInviteFollowing ? (
        <>
          <div
            className="AlignedRowSpaced"
            style={{
              cursor: finishedInviteFollowing ? "auto" : "pointer",
            }}
            onClick={() => {
              if (!finishedInviteFollowing) {
                fromSideModal
                  ? setPercentSetUpVisible && setPercentSetUpVisible(false)
                  : setPublishedModalVisible && setPublishedModalVisible(false);
                setMassTextsPopupPanelVisible &&
                  setMassTextsPopupPanelVisible(true);
                setSuggestedAction &&
                  setSuggestedAction(
                    generateSuggestedActionItem(
                      SubSelectionType.ALL_FOLLOWERS,
                      ""
                    )
                  );
                mixpanel.track("Pressed Event Percent Setup", {
                  distinct_id: accountData.uid,
                  event_id: event.id,
                  from: "webapp",
                  on_publish: !fromSideModal,
                  type: "invite following",
                });
              }
            }}
          >
            <div
              className="ColumnNormal"
              style={{
                gap: 7,
                opacity: finishedInviteFollowing ? 0.5 : 1,
                cursor: finishedInviteFollowing ? "auto" : "pointer",
              }}
            >
              <span style={styles.bodyMedium}>2. Share with followers</span>
              <span style={styles.placeholder}>
                Share this {isExternalLink ? "link" : "event"} with a mass text.
              </span>
            </div>
            {!finishedInviteFollowing ? (
              <Icon
                icon="mdi:chevron-right"
                height={28}
                style={{ flexShrink: 0 }}
              />
            ) : null}
          </div>
          <hr />
        </>
      ) : null}
      {showCustomizeReminderTexts ? (
        <>
          <div
            className="AlignedRowSpaced"
            style={{ cursor: changedEventTexts ? "auto" : "pointer" }}
            onClick={() => {
              if (!changedEventTexts) {
                fromSideModal
                  ? setPercentSetUpVisible && setPercentSetUpVisible(false)
                  : setPublishedModalVisible && setPublishedModalVisible(false);
                openEventTexts && openEventTexts();
                mixpanel.track("Pressed Event Percent Setup", {
                  distinct_id: accountData.uid,
                  event_id: event.id,
                  from: "webapp",
                  on_publish: !fromSideModal,
                  type: "set up reminder texts",
                });
              }
            }}
          >
            <div
              className="AlignedRowSelect"
              style={{
                gap: 14,
                pointerEvents: changedEventTexts ? "none" : "all",
              }}
            >
              <div
                className="ColumnNormal"
                style={{
                  gap: 7,
                  opacity: changedEventTexts ? 0.5 : 1,
                  cursor: changedEventTexts ? "auto" : "pointer",
                }}
              >
                <span style={styles.bodyMedium}>
                  {showInviteFollowing ? "3." : "2."} Customize texts
                </span>
                <span style={styles.placeholder}>
                  Schedule and send custom texts.
                </span>
              </div>
            </div>
            {!changedEventTexts ? (
              <Icon
                icon="mdi:chevron-right"
                height={28}
                style={{ flexShrink: 0 }}
              />
            ) : null}
          </div>
        </>
      ) : null}
    </div>
  );
};
