import React, { useState } from "react";
import { Colors } from "../../utils/colors";
import { Icon } from "@iconify/react";
import { isDesktop } from "react-device-detect";

type exploreNavCardProps = {
  title: string;
  onPress: () => void;
  city?: boolean;
  src: string;
};

const ExploreNavCard = (props: exploreNavCardProps) => {
  const { title, onPress, city, src } = props;

  const [isHovered, setIsHovered] = useState(false);

  const styles = {
    exploreCardContainer: {
      borderRadius: 15,
      backgroundImage: `linear-gradient(to ${
        city ? "top" : "left"
      }, rgba(0, 0, 0, 0), rgba(0, 0, 0, ${city ? 0.4 : 0.7})), url(${src})`,
      backgroundPosition: isDesktop ? "" : "center 37.5%",
      transition: "background-color 0.3s",
      cursor: "pointer",

      // designed to be used in CSS grid, 100% is width of the grid in container
      width: "100%",
      // padding % is based off width, helps maintain aspect ratio better than height property
      paddingBottom: isDesktop
        ? `${city ? "100" : "68"}%`
        : `${city ? "36" : "73"}%`,
    },

    overlay: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      opacity: isHovered || !isDesktop ? 0.6 : 0,
      transition: "opacity 0.3s",
      borderRadius: 15,
      cursor: "pointer",
    },

    title: {
      fontSize: isDesktop ? 32 : 16,
      fontWeight: isDesktop ? 600 : 400,
      color: Colors.WHITE,
    },

    titleContainer: {
      paddingLeft: isDesktop ? 28 : city ? 24 : 14,
      paddingTop: isDesktop ? 28 : city ? 24 : 14,
      gap: isDesktop ? 7 : 2,
    },
  };
  return (
    <div
      style={{
        ...styles.exploreCardContainer,
        position: "relative",
        backgroundSize: "cover",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onPress}
    >
      <div style={{ ...styles.overlay, position: "absolute" }}></div>
      <div
        className="AlignedRow"
        style={{ ...styles.titleContainer, position: "absolute", cursor: "pointer" }}
      >
        <h1 style={styles.title}>{title}</h1>
        <Icon
          icon="ion:chevron-forward"
          height={isDesktop ? 30 : 15}
          color={Colors.WHITE}
          style={{ paddingTop: 5 }}
        />
      </div>
    </div>
  );
};

export default ExploreNavCard;
