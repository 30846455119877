import "../../../../css/GlobalStyles.css";
import { useCallback, useMemo, useState } from "react";
import {
  AccountData,
  FollowerStatus,
  SpreadsheetStatus,
} from "@markit/common.types";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../redux/slices/accountSlice";
import { hasSubscription } from "@markit/common.utils";
import EmptyStateButton from "../../../../components/Buttons/EmptyStateButton";
import { Icon } from "@iconify/react";
import { Colors } from "../../../../utils/colors";
import RectangleButtonCreatorPanel from "../../../../components/Buttons/RectangleButtonCreatorPanel";
import { useNavigate } from "../../../../hooks/useNavigate";
import MarkitPlusActionButton from "../../../../components/Subscription/MarkitPlusActionButton";
import CreatorModePanelWrapper from "../../../../components/CreatorDashboard/CreatorModePanelWrapper";
import ImportHistory from "../../../../components/CreatorDashboard/CreatorProfile/ImportContacts/ImportHistory";
import EventDashboardAction from "../../../../components/EventDashboard/EventDashboardAction";
import StandardListContainer from "../../../../components/Containers/StandardListContainer";
import SearchBoxContainer from "../../../../components/Containers/SearchBoxContainer";
import { useLiveUpdatingImportAnalytics } from "../../../../hooks/useLiveHooks/useLiveUpdatingImportAnalytics";
import { useLoadSpreadsheetUserList } from "../../../../hooks/useLoadSpreadsheetUserList";
import { useOnMount } from "../../../../utils/useOnMount";
import filter from "lodash.filter";
import ProfileSpreadsheetItem, {
  SPREADSHEET_USER_FLEX_SECTIONS,
} from "../../../../components/DisplayItem/ProfileSpreadsheetItem";
import { EmptyStateFlatlist } from "../../../../components/EmptyStates/EmptyStateFlatlist";
import { MarkitPlusModal } from "../../../../components/Subscription/MarkitPlusModal";
import FullProfilePreviewModal from "../../../../components/FollowerProfile/FullProfilePreviewModal";
import { CircularProgress } from "@mui/material";
import FlatList from "flatlist-react/lib";
import GridTableHeader from "../../../../components/Headers/GridTableHeader";

const ImportPanel = () => {
  const { accountData, appInitialized } = useSelector(getAccountState).account;
  const navigate = useNavigate();
  const [loadingFollowers, setLoadingFollowers] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [importHistoryVisible, setImportHistoryVisible] = useState(false);
  const [subModalVisible, setSubModalVisible] = useState(false);
  const [profilePreviewSelected, setProfilePreviewSelected] =
    useState<AccountData>();

  const styles = {
    optInHeader: {
      paddingTop: 6,
      paddingBottom: 18,
      paddingInline: 24,
      fontSize: 14,
      fontWeight: "500",
    },
  };

  const { liveTotalSubscribedImportContacts, loaded } =
    useLiveUpdatingImportAnalytics();

  const {
    fetchedUserData,
    fetchedFollowerData,
    loadMoreUsers,
    loadUsers,
    loadSearchResultsThrottled,
    isLoading: isLoadingFollowers,
    isFinished: isFinishedLoadingFollowers,
  } = useLoadSpreadsheetUserList({
    userId: accountData.uid,
    followerStatus: FollowerStatus.SUBSCRIBED,
    windowSize: 30,
    status: SpreadsheetStatus.ACCEPTED,
  });

  useOnMount(() => {
    loadUsers();
    setLoadingFollowers(false);
  });

  const navigateNewUpload = useCallback(() => {
    if (!hasSubscription(accountData)) {
      setSubModalVisible(true);
      return;
    }
    navigate("/import");
  }, [accountData, navigate]);

  const contains = useCallback((user: AccountData, query: string) => {
    return user.fullName.toLowerCase().includes(query);
  }, []);

  const handleSearch = (text: string) => {
    if (text !== "") {
      loadSearchResultsThrottled(text.toLowerCase());
    }
    setSearchTerm(text.toLowerCase());
  };

  const itemsToShow: AccountData[] = useMemo(() => {
    if (!appInitialized) {
      return [];
    }
    let userList: AccountData[] = [];
    userList = fetchedUserData;
    if (searchTerm !== "") {
      userList = filter(userList, (user: AccountData) => {
        return contains(user, searchTerm);
      });
    }
    return userList;
  }, [appInitialized, fetchedUserData, searchTerm, contains]);

  const importHistoryOnPress = useCallback(() => {
    setImportHistoryVisible(true);
  }, []);

  const renderItem = useCallback(
    (item: AccountData) => (
      <ProfileSpreadsheetItem
        item={item}
        setProfilePreviewSelected={setProfilePreviewSelected}
        fetchedFollowerData={fetchedFollowerData}
      />
    ),
    [fetchedFollowerData, setProfilePreviewSelected]
  );

  const renderEmptyView = useMemo(() => {
    return (
      <EmptyStateFlatlist
        searchTerm={searchTerm}
        isLoading={isLoadingFollowers}
        containerStyles={{ paddingTop: 150 }}
        nonSearchEmptyView={
          <EmptyStateButton
            title="Import Contacts"
            description="Import a CSV to build your audience"
            icon={
              <Icon icon="ion:cloud-upload" height={64} color={Colors.GRAY1} />
            }
            iconBox={84}
            btnText="New Import"
            onPress={navigateNewUpload}
            containerStyles={{ paddingTop: 150 }}
          />
        }
      />
    );
  }, [isLoadingFollowers, navigateNewUpload, searchTerm]);

  return (
    <CreatorModePanelWrapper
      title="Import Audience"
      headerRight={
        <div className="AlignedRow" style={{ gap: 14 }}>
          <MarkitPlusActionButton />
          <RectangleButtonCreatorPanel
            title="New Import"
            iconName="ion:add-circle"
            onPress={navigateNewUpload}
          />
        </div>
      }
    >
      <div
        className="ColumnNormal"
        style={{ padding: 30, paddingBottom: 100, gap: 30 }}
      >
        <div className="AlignedRow" style={{ gap: 30 }}>
          <EventDashboardAction
            title={"Import History"}
            iconName={"ion:hourglass"}
            iconSize={31}
            onPress={importHistoryOnPress}
            visible={true}
            containerWidth={"100%"}
          />
          <EventDashboardAction
            title={"New Import"}
            iconName={"ion:cloud-upload"}
            iconSize={31}
            onPress={navigateNewUpload}
            visible={true}
            containerWidth={"100%"}
          />
        </div>
        <StandardListContainer
          searchComp={
            <SearchBoxContainer
              placeholder="Search People"
              onChange={(e) => handleSearch(e.target.value)}
              containerStyles={{ marginTop: 0 }}
            />
          }
          subHeaderComp={
            <span style={styles.optInHeader}>
              Opted-In ({loaded ? liveTotalSubscribedImportContacts : "--"})
            </span>
          }
          gridHeaderComp={
            <GridTableHeader
              flexSections={SPREADSHEET_USER_FLEX_SECTIONS}
              sectionTitles={["Name", "Upload Name", "Phone Number", "Status"]}
            />
          }
          listComp={
            <FlatList
              list={itemsToShow}
              keyExtractor={(item: AccountData) => item.uid}
              renderItem={renderItem}
              renderWhenEmpty={() => renderEmptyView}
              hasMoreItems={!isFinishedLoadingFollowers}
              loadMoreItems={() => {
                setTimeout(() => {
                  if (searchTerm.trim() === "") {
                    loadMoreUsers();
                  }
                }, 50); // The set index has a tiny delay
              }}
              paginationLoadingIndicator={() =>
                isLoadingFollowers && !isFinishedLoadingFollowers ? (
                  <CircularProgress
                    style={{
                      color: "#929292",
                      alignSelf: "center",
                      marginTop: "24px",
                      marginLeft: "14px",
                    }}
                    size={30}
                  />
                ) : null
              }
            />
          }
          isLoading={loadingFollowers}
        />
        {importHistoryVisible ? (
          <ImportHistory
            isVisible={importHistoryVisible}
            setIsVisible={setImportHistoryVisible}
          />
        ) : null}
        {subModalVisible ? (
          <MarkitPlusModal closeModal={() => setSubModalVisible(false)} />
        ) : null}
        <FullProfilePreviewModal
          profileSelected={profilePreviewSelected}
          setProfileSelected={setProfilePreviewSelected}
        />
      </div>
    </CreatorModePanelWrapper>
  );
};

export default ImportPanel;
