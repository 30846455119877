import "../../css/GlobalStyles.css";
import { ReactNode, memo, useCallback } from "react";
import StandardBorderedContainer from "../../components/Containers/StandardBorderedContainer";
import { Colors } from "../../utils/colors";
import { HorizontalDivider } from "../../components/Dividers/HorizontalDivider";
import RectangleButton from "../../components/Buttons/RectangleButton";
import { Icon } from "@iconify/react";
import ExpandableBox from "./ExpandableBox";

type MultiStageContainerProps = {
  children: ReactNode;
  currentStageIndex: number;
  stageNames: string[];
  backOnPress: () => void;
  rightButton: ReactNode;
  hideBackButton?: boolean;
  bottomBarComp?: ReactNode;
};

const MultiStageContainer = (props: MultiStageContainerProps) => {
  const {
    children,
    currentStageIndex,
    stageNames,
    backOnPress,
    rightButton,
    hideBackButton,
    bottomBarComp,
  } = props;

  const styles = {
    progressDivider: { width: 28, backgroundColor: Colors.BLACK, height: 1 },
  };

  const importProgressItem = useCallback(
    (title: string, stageNum: number) => (
      <>
        {stageNum !== 0 ? (
          <div
            style={{
              ...styles.progressDivider,
              backgroundColor:
                stageNum > currentStageIndex ? Colors.GRAY1 : Colors.BLACK,
            }}
          />
        ) : null}
        <StandardBorderedContainer
          containerStyles={{
            borderColor:
              stageNum === currentStageIndex ? Colors.BLACK : Colors.GRAY1,
            paddingInline: 14,
            paddingBlock: 10,
            borderRadius: 100,
          }}
        >
          <div className="AlignedRow" style={{ gap: 7 }}>
            {currentStageIndex > stageNum ? (
              <Icon
                icon="ion:checkmark-circle"
                height={18}
                color={Colors.GREEN2}
              />
            ) : null}
            <span
              style={{
                color:
                  stageNum === currentStageIndex ? Colors.BLACK : Colors.GRAY1,
              }}
            >
              {title}
            </span>
          </div>
        </StandardBorderedContainer>
      </>
    ),
    [currentStageIndex, styles.progressDivider]
  );

  return (
    <StandardBorderedContainer
      containerStyles={{
        width: "100%",
        backgroundColor: Colors.WHITE,
        paddingTop: 40,
      }}
    >
      <div className="ColumnNormal">
        <div
          className="Centering ColumnNormal"
          style={{ flex: 1, flexGrow: 1 }}
        >
          <div className="AlignedRow" style={{ gap: 7 }}>
            {stageNames.map((name, index) => importProgressItem(name, index))}
          </div>
          <div
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              width: "100%",
              minHeight: "54vh",
            }}
          >
            {children}
          </div>
        </div>
        <div className="ColumnNormal" style={{ flex: 1 }}>
          <HorizontalDivider />
          <div
            className="ColumnNormal"
            style={{ gap: 14, paddingBlock: 24, alignItems: "center" }}
          >
            <div
              className="AlignedRow"
              style={{ justifyContent: "center", gap: 14 }}
            >
              {!hideBackButton ? (
                <div>
                  <RectangleButton
                    buttonLabel={
                      <span style={{ fontSize: 14, fontWeight: 500 }}>
                        {currentStageIndex === 0 ? "Exit" : "Back"}
                      </span>
                    }
                    altColor={Colors.GRAY6}
                    altTextColor={Colors.BLACK}
                    altPaddingHorz={24}
                    altPaddingVert={14}
                    onPress={backOnPress}
                    iconLeft={
                      <Icon
                        icon="mdi:chevron-left"
                        height={16}
                        color={Colors.BLACK}
                      />
                    }
                  />
                </div>
              ) : null}
              <div>{rightButton}</div>
            </div>
            {bottomBarComp ? bottomBarComp : null}
          </div>
        </div>
      </div>
    </StandardBorderedContainer>
  );
};

export default memo(MultiStageContainer);
