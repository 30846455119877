import {
  getSubscriptionReferralRef,
  getSubscriptionReferralsRef,
  getUsersRef,
} from "./FirebaseUtils";
import { generate } from "shortid";
import { SubscriptionReferral } from "@markit/common.types";
import { getDocs, query, setDoc, where } from "../firebase";

/**
 * Gets called when a user purchases a subscription with a referral link
 * Adds a referral document to the subscriptionReferrals collection
 * @param referrerUsername username of the referrer
 * @param refereeUserId uid of the referee
 */
export const createSubscriptionReferral = async (
  referrerUsername: string,
  refereeUserId: string
) => {
  const usersRef = getUsersRef();
  const referrerQuery = query(
    usersRef,
    where("username", "==", referrerUsername)
  );
  const querySnapshot = await getDocs(referrerQuery);
  const referrerUserId: string = querySnapshot.docs[0].data().uid;
  const referralId = generate();
  const subscriptionReferral: SubscriptionReferral = {
    id: referralId,
    referrer: referrerUserId,
    referee: refereeUserId,
    createdAt: new Date().toISOString(),
  };
  // check if a referral already exists for the referee
  const subscriptionReferralQuery = query(
    getSubscriptionReferralsRef(),
    where("referee", "==", refereeUserId)
  );
  const subscriptionReferralSnapshot = await getDocs(subscriptionReferralQuery);
  if (!subscriptionReferralSnapshot.empty) {
    alert(
      "You have already received a referral in the past! You will not receive a referral bonus"
    );
    return;
  }
  const subscriptionReferralRef = getSubscriptionReferralRef(referralId);
  setDoc(subscriptionReferralRef, subscriptionReferral);
};
