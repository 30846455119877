import { useCallback, useEffect, useState } from "react";
import {
  AttachmentLinkType,
  MassText,
  MassTextAttachment,
  MassTextAttachmentType,
  MassTextType,
} from "@markit/common.types";
import { useSelector } from "react-redux";
import { getAccountState } from "../redux/slices/accountSlice";
import { generate } from "shortid";
import { deepCopy, massTextPlaceholder } from "@markit/common.utils";

export type AttachmentMode = {
  icon: string;
  label: string;
};

export const attachmentModes: AttachmentMode[] = [
  {
    icon: "ion:calendar",
    label: MassTextAttachmentType.LINK,
  },
  {
    icon: "ion:gift",
    label: MassTextAttachmentType.FREE_TICKET_LINK,
  },
  {
    icon: "ion:pricetag",
    label: MassTextAttachmentType.DISCOUNTED_TICKET_LINK,
  },
  {
    icon: "ion:ticket-outline",
    label: MassTextAttachmentType.TICKET_LINK,
  },
  {
    icon: "mdi:calendar-account",
    label: MassTextAttachmentType.CALENDAR_INVITE,
  },
  {
    icon: "mdi:ticket-confirmation-outline",
    label: MassTextAttachmentType.GENERATE_TICKET_BUNDLE,
  },
];

export const makeEmptyMassText = (
  userId: string,
  fullName: string,
  eventRefId?: string // for event text ref
) => {
  const id = generate();

  const newMassText: MassText = {
    id: id,
    createdAt: new Date().toISOString(),
    message: massTextPlaceholder(fullName),
    queued: false,
    scheduled: false,
    sentAt: "",
    sentBy: userId,
    type: eventRefId ? MassTextType.EVENTTEXT : MassTextType.MASSTEXT,
    eventRefId: eventRefId ?? "",
    catchUpEnabled: false,
    subSelectionIds: [],
    excludedSubSelectionIds: [],
    mediaUrl: "",
    isDefault: false,
  };

  return newMassText;
};

export const makeEmptyTextAttachment = () => {
  const id = generate();

  const newMassTextAttachment: MassTextAttachment = {
    id: id,
    linkType: AttachmentLinkType.MARKIT_LINK,
    massTextType: MassTextAttachmentType.LINK,
    eventId: "",
    massTextId: "",
    numTickets: 1,
    discount: 20,
    promoCodeQuantity: 1,
    promoCodeId: "",
    customTicketLabel: "",
  };

  return newMassTextAttachment;
};

type useMassTextManagementProps = {
  massText?: MassText;
  isEditing: boolean;
  eventId?: string;
};

export const useMassTextManagement = (props: useMassTextManagementProps) => {
  const { massText, isEditing, eventId } = props;
  const { accountData } = useSelector(getAccountState).account;
  const [massTextSettings, setMassTextSettings] = useState<MassText>(
    massText ??
      makeEmptyMassText(accountData.uid, accountData.fullName, eventId)
  );
  const [attachmentSettings, setAttachmentSettings] =
    useState<MassTextAttachment>(makeEmptyTextAttachment());

  const updateMassTextSettings = useCallback(
    (newMassTextSettings: Partial<MassText>) => {
      setMassTextSettings((prevMassTextSettings) => {
        return { ...prevMassTextSettings, ...newMassTextSettings };
      });
    },
    []
  );

  const updateAttachmentSettings = useCallback(
    (newAttachmentSettings: Partial<MassTextAttachment>) => {
      setAttachmentSettings((prevAttachmentSettings) => {
        return { ...prevAttachmentSettings, ...newAttachmentSettings };
      });
    },
    []
  );

  const resetMassText = useCallback(() => {
    setMassTextSettings(
      makeEmptyMassText(accountData.uid, accountData.fullName, eventId)
    );
  }, [accountData.fullName, accountData.uid, eventId]);

  const resetMassTextAttachment = useCallback(() => {
    setAttachmentSettings(makeEmptyTextAttachment());
  }, []);

  // whenever a new editing mass text is selected
  useEffect(
    () =>
      setMassTextSettings(
        massText
          ? deepCopy(massText)
          : makeEmptyMassText(accountData.uid, accountData.fullName, eventId)
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isEditing]
  );

  return {
    updateMassTextSettings,
    massTextSettings,
    makeEmptyMassText,
    attachmentSettings,
    updateAttachmentSettings,
    resetMassText,
    resetMassTextAttachment,
  };
};
