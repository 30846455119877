import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import PopupModalContainer from "../Containers/PopupModalContainer";
import RectangleButton from "../Buttons/RectangleButton";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import {
  MARKIT_HOTLINE_NUMBER,
  mostRecentSubscription,
} from "@markit/common.utils";
import { MixpanelContext } from "../../context/AnalyticsService";
import { API } from "../../API";
import { formatPhoneNumber } from "../../utils/FormatPhoneNumber";
import AlertContainer from "../Containers/AlertContainer";

type PaymentFailedButtonModalProps = {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  location: string;
  button?: React.ReactNode;
  noClose?: boolean;
  addOnClose?: () => void;
};

const PaymentFailedButtonModal = (props: PaymentFailedButtonModalProps) => {
  const { isVisible, setIsVisible, location, button, noClose, addOnClose } =
    props;
  const { accountData } = useSelector(getAccountState).account;
  const mixpanel = useContext(MixpanelContext);
  const [invoiceUrl, setInvoiceUrl] = useState("");

  const [alertText, setAlertText] = useState({
    heading: "",
    subHeading: "",
  });

  const styles = {
    descriptionText: {
      fontSize: 14,
      fontWeight: "500",
      color: Colors.BLACK,
    },
  };

  const subscription = useMemo(
    () => mostRecentSubscription(accountData),
    [accountData]
  );

  useEffect(() => {
    (async () => {
      if (isVisible && subscription) {
        mixpanel.track("Show Update Payment Info Prompt", {
          location: location,
        });
        // fetch the invoice link for the user
        const { invoiceUrl } = await API.user.sendPaymentFailedInvoice({
          uid: accountData.uid,
          subscriptionId: subscription.id,
        });
        setInvoiceUrl(invoiceUrl);
      }
    })();
  }, [
    location,
    mixpanel,
    isVisible,
    accountData.uid,
    subscription.id,
    subscription,
  ]);

  const toggleModal = useCallback(() => {
    setIsVisible(!isVisible);
    if (addOnClose) {
      addOnClose();
    }
  }, [addOnClose, isVisible, setIsVisible]);

  const sendInvoiceOnPress = useCallback(async () => {
    if (invoiceUrl !== "") {
      window.open(invoiceUrl);
      setIsVisible(false);
    } else {
      setAlertText({
        heading: "Cannot view invoice at this time.",
        subHeading: `Please text our hotline (${formatPhoneNumber(
          MARKIT_HOTLINE_NUMBER
        )}) with the following message: \nUpdate Billing Info ${
          accountData.username
        }`,
      });
    }
  }, [accountData.username, invoiceUrl, setIsVisible]);

  return (
    <div>
      {button}
      {isVisible ? (
        <PopupModalContainer
          headerComp={
            <div className="ColumnNormal" style={{ gap: 14 }}>
              <Icon icon="ion:card" height={35} color={Colors.RED3} />
              <span style={{ fontSize: 16, fontWeight: "500" }}>
                Update Billing Info
              </span>
            </div>
          }
          subHeaderComp={
            <div className="ColumnNormal" style={{ marginTop: 7, gap: 10 }}>
              <span style={{ fontSize: 14 }}>
                Your payment method on file for your Markit+ subscription
                failed. You will not be able to create events, send texts, and
                more until you pay your outstanding balance.
              </span>
              <span style={styles.descriptionText}>
                To update your payment method, view your invoice below.
              </span>
            </div>
          }
          valueComp={
            <div className="ColumnNormal" style={{ gap: 10 }}>
              <RectangleButton
                buttonLabel={<span>Send Invoice</span>}
                onPress={sendInvoiceOnPress}
                altTextColor={Colors.WHITE}
                altColor={Colors.RED3}
              />
              <AlertContainer
                headerComp={alertText.heading}
                subHeaderComp={
                  alertText.subHeading !== "" ? alertText.subHeading : undefined
                }
                closeModal={() => setAlertText({ heading: "", subHeading: "" })}
                hideModal={
                  alertText.heading === "" && alertText.subHeading === ""
                }
              />
            </div>
          }
          noExit
          closeModal={noClose ? () => {} : toggleModal}
          closeOnOutsidePress
        />
      ) : null}
    </div>
  );
};

export default PaymentFailedButtonModal;
