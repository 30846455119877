import { Colors } from "../../../../utils/colors";
import {
  LessThanDate,
  formatAMPM,
  formatDate,
  roundTimeInterval,
} from "@markit/common.utils";
import ReactDatePicker from "react-datepicker";
import IOSSwitch from "../../../IOSSwitch";
import { ThemeStyle } from "../../../../hooks/useTheme";
import { useCallback, useState } from "react";
import { ScheduleTextInfo } from "../MassTextsMessaging";
import AlertContainer from "../../../Containers/AlertContainer";

type MassTextSendTimeProps = {
  scheduleTextInfo: ScheduleTextInfo;
  setScheduleTextInfo: (scheduleTextInfo: ScheduleTextInfo) => void;
  isEditing: boolean;
  theme?: ThemeStyle;
};

export const MassTextSendTime = (props: MassTextSendTimeProps) => {
  const { scheduleTextInfo, setScheduleTextInfo, isEditing, theme } = props;
  const [alertText, setAlertText] = useState("");

  // Disallow selection of earlier date for scheduled texts
  const filterPassedTime = (time: any) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const toggleSendTime = useCallback(() => {
    // round the time up to the nearest 5 minute interval, set 10 min after current date
    // Ex: 10:14 => 10:25
    let newSentAt = scheduleTextInfo.sendTime;
    if (!scheduleTextInfo.scheduleToggle) {
      const roundedTime = roundTimeInterval(new Date().toISOString(), 5);
      newSentAt = roundedTime;
    }
    setScheduleTextInfo({
      ...scheduleTextInfo,
      sendTime: newSentAt,
      scheduleToggle: !scheduleTextInfo.scheduleToggle,
    });
  }, [scheduleTextInfo, setScheduleTextInfo]);

  return (
    <div
      className="EventDashboardContainerSpaceBetween"
      style={theme?.TertiaryBG}
    >
      <div style={{ width: "100%" }}>
        <div
          className="AlignedRowSpaced"
          style={{ marginBottom: scheduleTextInfo.scheduleToggle ? 14 : 0 }}
        >
          <div className="ColumnNormal" style={{ gap: 3 }}>
            <span
              className="EventDashboardContentHeader"
              style={theme?.PrimaryText}
            >
              Schedule this text
            </span>
            <div
              className="EventDashboardContentDescription"
              style={{ color: Colors.GRAY1 }}
            >
              Send this text at a later scheduled time.
            </div>
          </div>
          <IOSSwitch
            sx={{ m: 1 }}
            checked={scheduleTextInfo.scheduleToggle}
            onChange={toggleSendTime}
            disabled={isEditing}
          />
        </div>
        {scheduleTextInfo.scheduleToggle ? (
          <div className="AlignedRowSpaced">
            <span
              className="AboutSubtitle"
              style={{
                fontSize: 14,
                fontWeight: 500,
                whiteSpace: "nowrap",
                marginRight: 20,
                ...theme?.PrimaryText,
              }}
            >
              Set Send Time
            </span>
            <ReactDatePicker
              selected={scheduleTextInfo.sendTime}
              showTimeSelect
              minDate={new Date()}
              filterTime={filterPassedTime}
              timeIntervals={5}
              onChange={(date) => {
                if (date != null) {
                  if (
                    LessThanDate(date.toISOString(), new Date().toISOString())
                  ) {
                    setAlertText(
                      "You cannot edit the send time to be prior to the current time."
                    );
                    return;
                  }
                  setScheduleTextInfo({
                    ...scheduleTextInfo,
                    sendTime: date,
                  });
                }
              }}
              customInput={
                <div
                  className="AlignedRow"
                  style={{
                    backgroundColor: Colors.WHITE,
                    cursor: "pointer",
                    border: "0.5px solid #666666",
                    borderRadius: 8,
                  }}
                >
                  <div
                    style={{
                      cursor: "pointer",
                      borderRight: "0.5px solid #666666",
                      paddingBlock: 10,
                      paddingInline: 14,
                      ...theme?.PrimaryText,
                    }}
                  >
                    {formatDate(scheduleTextInfo.sendTime, true, true, true)}
                  </div>
                  <div
                    style={{
                      cursor: "pointer",
                      paddingBlock: 10,
                      paddingInline: 14,
                      ...theme?.PrimaryText,
                    }}
                  >
                    {formatAMPM(scheduleTextInfo.sendTime)}
                  </div>
                </div>
              }
            />
          </div>
        ) : null}
      </div>
      <AlertContainer
        headerComp={alertText}
        theme={theme}
        closeModal={() => setAlertText("")}
        hideModal={alertText === ""}
      />
    </div>
  );
};
