import { Follower, FollowerStatus } from "@markit/common.types";

export const unsubscribedUserAlertText = (followerData: Follower) => {
  return `You cannot message this number ${
    followerData?.status === FollowerStatus.UNSUBSCRIBED
      ? "since they are opted out and unsubscribed"
      : followerData?.status === FollowerStatus.REMOVED
      ? "since this number was removed"
      : ""
  }.`;
};
