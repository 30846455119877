import "../../css/FullEvent/Checkout.css";
import { memo } from "react";
import {
  Event,
  CustomTicketV2,
  PromoCode,
  AccountData,
  TicketV2,
  SavedFormQuestion,
} from "@markit/common.types";
import Box from "@mui/material/Box";
import { FullEventInputForm } from "../FullEvent/FullEventInputForm";
import { isDesktop } from "react-device-detect";

type CheckoutProps = {
  event: Event;
  host: AccountData;
  setSubmitted: (submitted: boolean) => void;
  tixId: string;
  ticketData?: TicketV2;
  ticketToRedeem: CustomTicketV2 | undefined;
  promoterId: string;
  promoCode?: PromoCode;
  userReferrer: string;
  submitted: boolean;
  selectedCustomTicket: CustomTicketV2;
  setSelectedCustomTicket: (selectedTicket: CustomTicketV2 | undefined) => void;
  isEventAtCapacity: boolean;
  eventFormQuestions: SavedFormQuestion[];
};

/** Checkout screen to navigate the user to when they press Buy Now on BuyBanner.tsx **/
export const Checkout = memo(function CheckoutFn(props: CheckoutProps) {
  const {
    event,
    host,
    tixId, // redeem
    ticketData,
    ticketToRedeem,
    promoterId, // promoterId
    promoCode, // promoCode
    userReferrer,
    selectedCustomTicket,
    setSelectedCustomTicket,
    submitted,
    setSubmitted,
    isEventAtCapacity,
    eventFormQuestions,
  } = props;

  return isDesktop ? (
    <div className="CheckoutDesktop">
      <div className="CheckoutWrapperDesktop">
        <div className="CheckoutContentDesktop">
          {event ? (
            <Box
              sx={{
                alignItems: "center",
                alignSelf: "center",
                marginBottom: 1,
              }}
            >
              <FullEventInputForm
                selectedCustomTicket={selectedCustomTicket}
                setSelectedCustomTicket={setSelectedCustomTicket}
                event={event}
                host={host}
                setSubmitted={setSubmitted}
                tixId={tixId}
                ticketData={ticketData}
                ticketToRedeem={ticketToRedeem}
                promoCode={promoCode}
                promoterId={promoterId}
                userReferrer={userReferrer}
                submitted={submitted}
                isEventAtCapacity={isEventAtCapacity}
                eventFormQuestions={eventFormQuestions}
              />
            </Box>
          ) : null}
        </div>
      </div>
    </div>
  ) : (
    <div className="Checkout">
      <div className="CheckoutWrapper">
        {event ? (
          <FullEventInputForm
            selectedCustomTicket={selectedCustomTicket}
            setSelectedCustomTicket={setSelectedCustomTicket}
            event={event}
            host={host}
            setSubmitted={setSubmitted}
            tixId={tixId}
            ticketData={ticketData}
            ticketToRedeem={ticketToRedeem}
            promoCode={promoCode}
            promoterId={promoterId}
            userReferrer={userReferrer}
            submitted={submitted}
            isEventAtCapacity={isEventAtCapacity}
            eventFormQuestions={eventFormQuestions}
          />
        ) : null}
      </div>
    </div>
  );
});
