import { Route, Routes } from "react-router-dom";
import { memo } from "react";
import ProfilePage from "../screens/Profile/ProfilePage";

const ProfileRoute = memo(function ProfileRouteFn() {
  return (
    <Routes>
      <Route index element={<ProfilePage />} />
    </Routes>
  );
});

export default ProfileRoute;
