import { FormInputType, SavedFormQuestion } from "@markit/common.types";
import { Icon } from "@iconify/react";
import { formQuestionDisplayName } from "@markit/common.utils";
import { ThemeStyle } from "../../hooks/useTheme";
import { Colors } from "../../utils/colors";
import { useMemo } from "react";
import { DropdownMenu, DropdownMenuItem } from "../DropdownMenu";

type SavedQuestionItemProps = {
  item: SavedFormQuestion;
  onItemPress: () => void;
  rightIcon?: JSX.Element;
  disabled?: boolean;
  theme?: ThemeStyle;
  editOnPress?: () => void;
};

// To display the SavedQuestion details and providing the rightIcon for flexibility
const SavedQuestionItem = ({
  item,
  onItemPress,
  rightIcon,
  disabled,
  theme,
  editOnPress,
}: SavedQuestionItemProps) => {
  const dropdownItems: DropdownMenuItem[] = useMemo(
    () =>
      editOnPress
        ? [
            {
              title: "Edit Question",
              key: "edit_question",
              onPress: editOnPress,
            },
          ]
        : [],
    [editOnPress]
  );

  return (
    <div className="AlignedRowSpaced" style={{ flexGrow: 1 }}>
      <div
        onClick={onItemPress}
        className="AlignedRowSelect"
        style={{
          gap: 10,
          pointerEvents: disabled ? "none" : "all",
          opacity: disabled ? 0.4 : 1,
          flexGrow: 1,
        }}
      >
        <div className="ColumnNormalSelect" style={{ gap: 3 }}>
          <div className="AlignedRow" style={{ gap: 7, cursor: "pointer" }}>
            {item.isEssential ? (
              <div>
                <Icon
                  icon="fa:map-pin"
                  height={14}
                  color={theme?.PrimaryText.color}
                />
              </div>
            ) : item.formInput.inputType === FormInputType.EMAIL ||
              item.formInput.inputType === FormInputType.PHONE ? (
              <Icon
                icon={
                  item.formInput.inputType === FormInputType.EMAIL
                    ? "ion:mail-open-sharp"
                    : "ion:call"
                }
                height={14}
                color={theme?.PrimaryText.color}
              />
            ) : null}
            <span
              style={{
                ...theme?.PrimaryText,
                fontWeight: "500",
                fontSize: 14,
                marginRight: 20,
              }}
            >
              {item.formInput.label}
            </span>
          </div>
          <span
            style={{
              color: theme ? theme.SubText.color : Colors.GRAY1,
              fontSize: 13,
            }}
          >
            {formQuestionDisplayName(item.formInput)}
          </span>
          <span
            style={{
              color: theme ? theme.SubText.color : Colors.GRAY1,
              fontSize: 13,
            }}
          >
            Used for {item.onProfileFollow ? "Profile and " : ""}
            {item.eventIds.length} event
            {item.eventIds.length !== 1 ? "s" : ""}
          </span>
        </div>
      </div>
      <span style={{ display: "flex", alignItems: "center", gap: 5 }}>
        <div style={{ height: "100%", justifyContent: "center" }}>
          {rightIcon}
        </div>
        {editOnPress ? (
          <DropdownMenu
            dropdownItems={dropdownItems}
            trigger={["click"]}
            placement="bottomLeft"
          >
            <Icon
              icon={"mdi:dots-horizontal"}
              height={24}
              style={{ color: Colors.GRAY3, marginTop: 2 }}
            />
          </DropdownMenu>
        ) : null}
      </span>
    </div>
  );
};

export default SavedQuestionItem;
