import { Icon } from "@iconify/react";
import { Colors } from "../utils/colors";
import { CircularProgress } from "@mui/material";

type DropdownMenuLabelProps = {
  label: string;
  icon?: string;
  width?: number;
  isDestructive?: boolean;
  loading?: boolean;
};

const DropdownMenuLabel = (props: DropdownMenuLabelProps) => {
  const { label, icon, width, isDestructive = false, loading = false } = props;

  return (
    <div
      className="AlignedRowSpacedSelect"
      style={{ width: width, paddingBlock: 4 }}
    >
      <h4
        style={{
          fontWeight: 500,
          color: isDestructive ? Colors.RED1 : "3D3D3D",
        }}
      >
        {label}
      </h4>
      {icon ? (
        loading ? (
          <CircularProgress
            style={{
              color: Colors.BLACK,
              alignSelf: "center",
            }}
            size={17}
          />
        ) : (
          <Icon
            icon={icon}
            height={16}
            style={{ color: isDestructive ? Colors.RED1 : "3D3D3D" }}
          />
        )
      ) : null}
    </div>
  );
};

export default DropdownMenuLabel;
