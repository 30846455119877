import LargePopupModalContainer from "../Containers/LargePopupModalContainer";
import { useSelector } from "react-redux";
import { LoginState, getAccountState } from "../../redux/slices/accountSlice";
import { Colors } from "../../utils/colors";
import TestTextModal from "./MassTexts/TestTextModal";
import { Event, TextPluginOptions } from "@markit/common.types";
import { useCallback, useMemo, useState } from "react";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";
import isEqual from "lodash.isequal";
import { useDispatch } from "react-redux";
import { updateTicketPurchaseMessage } from "../../redux/slices/eventSlice";
import RectangleButton from "../Buttons/RectangleButton";
import { BackButton } from "../Buttons/BackButton";
import { InsertPluginTextInput } from "./MassTexts/Message/InsertPluginTextInput";
import {
  detectedCensored,
  eventHasRequestTickets,
  isEventExternalLink,
  isRequestTickets,
  ticketPurchasePlaceholder,
} from "@markit/common.utils";
import ComplianceMessageTag from "../Compliance/ComplianceMessageTag";
import ConfirmDeleteModal from "../Containers/ConfirmPopups/ConfirmDeleteModal";
import { Icon } from "@iconify/react";
import { AttachmentsActionBar } from "./MassTexts/Message/AttachmentsActionBar";
import { useTheme } from "../../hooks/useTheme";
import ContentViolationAlert from "../DeliveryScore/ContentViolationAlert";

type TicketTextPopupProps = {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  event: Event;
};

const TicketTextPopup = (props: TicketTextPopupProps) => {
  const { isVisible, setIsVisible, event } = props;
  const { accountData, loggedIn } = useSelector(getAccountState).account;
  const { theme } = useTheme();
  const dispatch = useDispatch();
  const [ticketText, setTicketText] = useState(
    event.creatorTicketPurchaseMessage ??
      ticketPurchasePlaceholder(accountData.fullName)
  );
  // States for attaching image/videos/gifs
  const [customImage, setCustomImage] = useState(
    event.creatorTicketPurchaseMediaUrl
  );
  const [isRemoveMediaModalOpen, setIsRemoveMediaModalOpen] = useState(false);
  const [testTextModal, setTestTextModal] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(ticketText.length - 1);
  const [showBackAlert, setShowBackAlert] = useState(false);
  const [contentAlert, setContentAlert] = useState(false);

  const styles = {
    headerText: { color: Colors.GRAY1, fontSize: 14, paddingInline: 14 },
  };

  const hasRequest = useMemo(() => eventHasRequestTickets(event), [event]);

  const onlyRequest = useMemo(() => isRequestTickets(event), [event]);

  const isExternalLink = useMemo(
    () => isEventExternalLink(event.eventType),
    [event.eventType]
  );

  const currentFullName = useMemo(
    () => (loggedIn === LoginState.LOGGED_IN ? accountData.fullName : ""),
    [accountData.fullName, loggedIn]
  );

  const madeChanges = useMemo(
    () =>
      !isEqual(event.creatorTicketPurchaseMessage, ticketText) ||
      event.creatorTicketPurchaseMediaUrl !== customImage,
    [
      customImage,
      event.creatorTicketPurchaseMediaUrl,
      event.creatorTicketPurchaseMessage,
      ticketText,
    ]
  );

  const backOnPress = useCallback(() => {
    if (madeChanges) {
      setShowBackAlert(true);
    } else {
      setIsVisible(false);
    }
  }, [madeChanges, setIsVisible]);

  const saveOnPress = useCallback(() => {
    if (detectedCensored(ticketText)) {
      setContentAlert(true);
      return;
    }
    dispatch(updateTicketPurchaseMessage(event, ticketText, customImage));
    setIsVisible(false);
  }, [customImage, dispatch, event, setIsVisible, ticketText]);

  const testOnPress = useCallback(() => {
    if (detectedCensored(ticketText)) {
      setContentAlert(true);
      return;
    }
    setTestTextModal(true);
  }, [ticketText]);

  return (
    <>
      <LargePopupModalContainer
        showModal={isVisible}
        containerStyles={{ padding: 0 }}
        headerComp={
          <div className="AlignedRowSpaced">
            <BackButton onPress={backOnPress} />
            <div className="AlignedRow" style={{ gap: 7 }}>
              <RectangleButton
                buttonLabel="Test"
                onPress={testOnPress}
                altColor={Colors.GRAY6}
                altTextColor={Colors.BLACK}
                altPaddingHorz={14}
                altPaddingVert={10}
                altBorderRadius={100}
              />
              <RectangleButton
                buttonLabel="Save"
                onPress={saveOnPress}
                altTextColor={Colors.WHITE}
                altColor={Colors.BLACK}
                disabled={!madeChanges || ticketText === ""}
                altPaddingHorz={14}
                altPaddingVert={10}
                altBorderRadius={100}
              />
            </div>
          </div>
        }
        valueComp={
          <div className="ColumnNormal">
            <div className="ColumnNormal" style={{ gap: 16 }}>
              <span style={styles.headerText}>Ticket Text</span>
              <HorizontalDivider altMargin={0} />
              <span style={styles.headerText}>
                {isExternalLink
                  ? "Sent After Submission (Required)"
                  : onlyRequest
                  ? "Sent Upon Initial Request (Required)"
                  : `Sent Upon RSVP${
                      hasRequest ? " or Initial Request" : ""
                    } (Required)`}
              </span>
              <HorizontalDivider altMargin={0} />
            </div>
            <div
              style={{
                height: `calc(100vh - 280px)`,
                overflow: "scroll",
                padding: 14,
              }}
            >
              <InsertPluginTextInput
                message={ticketText}
                setMessage={(message: string) => setTicketText(message)}
                placeholder={ticketPurchasePlaceholder(currentFullName)}
                cursorPosition={cursorPosition}
                setCursorPosition={setCursorPosition}
                pluginWords={
                  new Set<string>(
                    TextPluginOptions.map((option) => option.name)
                  )
                }
                image={customImage}
                removeImage={() => setIsRemoveMediaModalOpen(true)}
                fixedBottomText={<ComplianceMessageTag />}
              />
            </div>
            <div
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                padding: 14,
              }}
            >
              <AttachmentsActionBar
                message={ticketText}
                image={customImage}
                setImage={setCustomImage}
              />
            </div>
          </div>
        }
      />
      <TestTextModal
        isVisible={testTextModal}
        setIsVisible={setTestTextModal}
        message={ticketText}
        event={event}
        image={customImage}
        save={customImage !== event.creatorTicketPurchaseMediaUrl}
      />
      <ConfirmDeleteModal
        heading="Are you sure you want to exit?"
        subtext="Your changes won't be saved. This cannot be undone."
        deleteButtonText="Exit"
        hideModal={!showBackAlert}
        setIsVisible={setShowBackAlert}
        deleteOnPress={() => setIsVisible(false)}
        icon={<Icon icon="ion:exit-outline" height={40} />}
        theme={theme}
      />
      <ConfirmDeleteModal
        heading="Remove Media?"
        subtext="This cannot be undone"
        deleteButtonText={"Remove"}
        hideModal={!isRemoveMediaModalOpen}
        setIsVisible={(open: boolean) => {
          setIsRemoveMediaModalOpen(open);
        }}
        deleteOnPress={() => {
          setCustomImage("");
        }}
        theme={theme}
      />
      <ContentViolationAlert
        message={ticketText}
        isVisible={contentAlert}
        setIsVisible={setContentAlert}
      />
    </>
  );
};

export default TicketTextPopup;
