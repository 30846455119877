import { memo, useContext } from "react";
import { AccountData } from "@markit/common.types";
import Banner from "../components/Banner";
import ProfileItem from "../components/ProfileItem";
import markitIcon from "../assets/MarkitLogoBlue.png";
import { DisplayGroupMembers } from "../utils/DisplayGroupMembers";
import { getLoadedGroupChat, getLoadedUsers } from "../redux/slices/dataSlice";
import { useParametricSelector } from "../hooks/useParametricSelector";
import LoadingScreen from "./LoadingScreen";
import { useOnMount } from "../utils/useOnMount";
import { MixpanelContext } from "../context/AnalyticsService";
import { useSelector } from "react-redux";
import { getUrlParamState } from "../redux/slices/urlParamSlice";

type CommunityPageProps = {};

const CommunityPage = memo(function CommunityPageFn(props: CommunityPageProps) {
  const mixpanel = useContext(MixpanelContext);
  const { communityId } = useSelector(getUrlParamState);
  const {
    isLoading: isLoadingCommunityData,
    data: communityData,
    isError: isErrorCommunityData,
  } = useParametricSelector(getLoadedGroupChat, communityId);
  const communityMemberAccountData: AccountData[] = useParametricSelector(
    getLoadedUsers,
    communityData?.members ?? []
  )
    .map((user) => user?.data)
    .filter((user) => user !== undefined) as AccountData[];

  useOnMount(() => {
    mixpanel.track("Webapp: Viewed Community", {
      community_id: communityId,
    });
  });

  if (isLoadingCommunityData) {
    return <LoadingScreen />;
  }

  if (isErrorCommunityData || !communityData) {
    return <LoadingScreen error />;
  }

  const communityMembers =
    communityData.groupName !== ""
      ? communityData?.groupName
      : DisplayGroupMembers(communityData?.members ?? []);

  return (
    <div className="CommunityContainer">
      {navigator.userAgent.indexOf("Chrome") !== -1 ||
      navigator.userAgent.indexOf("Safari") !== -1 ? (
        <Banner />
      ) : null}
      <div className="Community">
        <div className="CommunityHeader">
          <img
            className="CommunityImage"
            src={
              communityData.groupPhotoURL
                ? communityData.groupPhotoURL
                : markitIcon
            }
            alt="CommunityPhoto"
          />
          {communityData.groupName !== "" ? (
            <h2 className="CommunityTitle">{communityData.groupName}</h2>
          ) : (
            <h2 className="CommunityTitle">{communityMembers}</h2>
          )}
          <h5 className="CommunityMessage">
            Open this link in your mobile browser, download Markit by clicking
            on the icon at the top, then click open to join this community in
            the app.
          </h5>
        </div>

        <div className="CommunityBody">
          <hr />
          <h3 className="Subtitle">Members ({communityData.members.length})</h3>
          <ul className="CommunityMembers">
            {communityMemberAccountData.map((member) => (
              <div className="CommunityMembersItem">
                <ProfileItem
                  profileItem={member}
                  onItemPress={() => {}}
                  disabled
                  hideViewIcon
                />
              </div>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
});

export default CommunityPage;
