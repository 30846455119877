import { useCallback, useEffect, useRef, useState } from "react";
import { AttachmentsActionBarHoldItem } from "../MassTexts/Message/AttachmentsActionBarHoldItem";
import MassTextsAttachmentModal from "../MassTexts/Message/MassTextsAttachmentModal";
import {
  AttachmentMode,
  attachmentModes,
  useMassTextManagement,
} from "../../../hooks/useMassTextManagement";
import { MenuProps } from "antd";
import { AttachmentSourceType } from "@markit/common.types";
import { useTheme } from "../../../hooks/useTheme";

type ConversationAttachmentButtonProps = {
  message: string;
  setMessage: (message: string) => void;
  image: string;
  setImage: (image: string) => void;
  cursorPosition: number;
  conversationMainUserId: string;
};

const ConversationAttachmentButton = (
  props: ConversationAttachmentButtonProps
) => {
  const {
    message,
    setMessage,
    image,
    setImage,
    cursorPosition,
    conversationMainUserId,
  } = props;
  const { theme } = useTheme();
  // States for attachments
  const [attachmentMode, setAttachmentMode] = useState<AttachmentMode>(
    attachmentModes[0]
  );
  const [showAttachmentModal, setShowAttachmentModal] =
    useState<boolean>(false);
  const [currAttachmentLink, setCurrAttachmentLink] = useState("");

  // Not used; only needed for passing in as props to MassTextsAttachmentModal
  const {
    attachmentSettings,
    updateAttachmentSettings,
    resetMassTextAttachment,
  } = useMassTextManagement({ isEditing: false });

  // States for image picker
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  // this is to handle case where you remove the image and then select the same one. If we remove an image, we need to make sure to clear the file input ref so that the React.ChangeEvent gets triggered again
  useEffect(() => {
    if (image === "" && fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  }, [image]);

  const generateNewMassTextMessage = useCallback(
    (eventURL: string) => {
      if (currAttachmentLink !== "") {
        setMessage(message.replace(currAttachmentLink, eventURL));
      } else {
        const messageWithEventURL =
          message.slice(0, cursorPosition) +
          " " +
          eventURL +
          " " +
          message.slice(cursorPosition);
        setMessage(messageWithEventURL);
      }
      setCurrAttachmentLink("");
    },
    [currAttachmentLink, cursorPosition, message, setMessage]
  );

  const onClick: MenuProps["onClick"] = ({ key }) => {
    setCurrAttachmentLink("");
    if (key !== "media") {
      setShowAttachmentModal(true);
    }

    switch (key) {
      case "event_link":
        setAttachmentMode(attachmentModes[0]);
        return;
      case "discount_ticket":
        setAttachmentMode(attachmentModes[2]);
        return;
      case "free_ticket":
        setAttachmentMode(attachmentModes[1]);
        return;
      case "ticket_link":
        setAttachmentMode(attachmentModes[3]);
        return;
      case "calendar_invite":
        setAttachmentMode(attachmentModes[4]);
        return;
      case "media":
        if (fileInputRef.current) {
          fileInputRef.current.click();
        }
        return;
      default:
        setAttachmentMode(attachmentModes[0]);
        return;
    }
  };

  return (
    <>
      <AttachmentsActionBarHoldItem
        onClick={onClick}
        attachmentSourceType={AttachmentSourceType.CONVERSATION}
        setImage={setImage}
        fileInputRef={fileInputRef}
      />
      {/* Mass Texts Attachment Modal */}
      {showAttachmentModal ? (
        <MassTextsAttachmentModal
          setShowAttachmentModal={setShowAttachmentModal}
          generateNewMassTextMessage={generateNewMassTextMessage}
          currAttachmentLink={currAttachmentLink}
          setCurrAttachmentLink={setCurrAttachmentLink}
          attachmentMode={attachmentMode}
          attachmentSettings={attachmentSettings}
          updateAttachmentSettings={updateAttachmentSettings}
          resetAttachmentState={resetMassTextAttachment}
          attachmentSourceType={AttachmentSourceType.CONVERSATION}
          conversationUserId={conversationMainUserId}
        />
      ) : null}
    </>
  );
};

export default ConversationAttachmentButton;
