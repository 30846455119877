import { MouseEventHandler } from "react";
import { Icon } from "@iconify/react";
import { useTheme } from "../../hooks/useTheme";

type ThreeDotsButtonProps = {
  onPress: MouseEventHandler<HTMLDivElement>;
};

const ThreeDotsButton = (props: ThreeDotsButtonProps) => {
  const { onPress } = props;
  const { theme } = useTheme();

  return (
    <div
      className="ColumnNormalSelect"
      style={{
        fontSize: 14,
        fontWeight: 600,
      }}
      onClick={onPress}
    >
      <Icon
        icon="ion:ellipsis-horizontal"
        height={20}
        color={theme.PrimaryText.color}
      />
    </div>
  );
};

export default ThreeDotsButton;
