import React, { useCallback, useContext, useState } from "react";
import { MARKIT_WEBSITE } from "@markit/common.utils";
import PopupModalContainer from "../Containers/PopupModalContainer";
import { useDispatch } from "react-redux";
import RectangleButton from "../Buttons/RectangleButton";
import { Colors } from "../../utils/colors";
import {
  getAccountState,
  setShowImportantMessage,
} from "../../redux/slices/accountSlice";
import { useSelector } from "react-redux";
import { isDesktop } from "react-device-detect";
import { MixpanelContext } from "../../context/AnalyticsService";

// Whenever we want to re-use this modal to show an important message, we can change the contents of this modal.
// Just need to use a new field on AccountData and deprecate the old one that it used to reset this for users
export const ImportantAnnouncementModal = () => {
  const { uid, showImportantMessage } =
    useSelector(getAccountState).account.accountData;
  const dispatch = useDispatch();
  const mixpanel = useContext(MixpanelContext);
  const [isVisible, setIsVisible] = useState(showImportantMessage);

  const doneOnPress = useCallback(() => {
    mixpanel.track("Pressed Done Important Announcement Modal", {
      distinct_id: uid,
      source: "webapp",
    });
    dispatch(setShowImportantMessage(uid));
    setIsVisible(false);
  }, [dispatch, mixpanel, uid]);

  const learnMoreOnPress = useCallback(() => {
    mixpanel.track("Pressed Learn More Important Announcement Modal", {
      distinct_id: uid,
      source: "webapp",
    });
    window.open(MARKIT_WEBSITE + "pricing");
  }, [mixpanel, uid]);

  return isVisible ? (
    <PopupModalContainer
      headerComp={<></>}
      noDivider
      valueComp={
        <div className="AlignedColumn" style={{ gap: 24, paddingTop: 20 }}>
          <span
            style={{
              fontSize: 32,
              fontWeight: 500,
              width: 250,
              textAlign: "center",
            }}
          >
            We are changing our pricing
          </span>
          <div className="ColumnNormal" style={{ gap: 14 }}>
            <span
              style={{ fontSize: 14, fontWeight: 500, textAlign: "center" }}
            >
              The price of Markit<span style={{ color: Colors.BLUE5 }}>+</span>{" "}
              will be changing on {isDesktop ? null : <br />} August 12th, 2024.
            </span>
            <span
              style={{ fontSize: 14, color: Colors.GRAY1, textAlign: "center" }}
            >
              We will honor existing pricing until the end of your current
              billing cycle. Your bill will change past the 12th, reach out to
              our team with questions.
            </span>
            <span
              style={{ fontSize: 14, color: Colors.GRAY1, textAlign: "center" }}
            >
              We are making these changes to provide more control and
              transparency on your spend. Visit our website or contact our team
              to see how your billing will change.
            </span>
          </div>
          <div
            className="ColumnNormal"
            style={{ gap: 14, width: "100%", paddingTop: 14 }}
          >
            <RectangleButton
              buttonLabel="I Understand"
              onPress={doneOnPress}
              altColor={Colors.BLACK}
              altTextColor={Colors.WHITE}
              altPaddingVert={14}
            />
            <RectangleButton
              buttonLabel="Learn More"
              onPress={learnMoreOnPress}
              altColor={Colors.GRAY6}
              altTextColor={Colors.BLACK}
              altPaddingVert={14}
            />
          </div>
        </div>
      }
      noExit
      closeOnOutsidePress
      closeModal={() => {
        setIsVisible(false);
      }}
    />
  ) : null;
};
