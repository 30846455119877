import { ThemeType } from "@markit/common.types";
import "../../css/EmptyStates/EmptyUserState.css";
import { Colors } from "../../utils/colors";
import TopHeader from "../TopHeader";
import Footer from "../Footer";
import DownloadAppContainer from "../Containers/DownloadAppContainer";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import { NavigationId } from "../../navigation/AppParamList";

type EmptyViewStateMobileProps = {
  eventTheme?: ThemeType;
};

const EmptyViewStateMobile = (props: EmptyViewStateMobileProps) => {
  const { eventTheme } = props;
  const { pathname } = useLocation();

  const isCreateLink = useMemo(
    () => pathname.includes(NavigationId.CREATE_LINK_EVENT),
    [pathname]
  );

  return (
    <div className="WebApp" style={{ transition: "0.5s" }}>
      <div style={{ paddingBottom: 40 }}>
        <TopHeader />
        <div
          className="ColumnNormal"
          style={{
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 14,
            height: 400,
            marginInline: 10,
            backgroundColor: Colors.WHITE,
          }}
        >
          <DownloadAppContainer
            subtext={`Manage and create ${
              isCreateLink ? "links" : "events"
            } with Markit on desktop or in the Markit app`}
            eventTheme={eventTheme}
          />
        </div>
      </div>
      <Footer eventTheme={eventTheme} />
    </div>
  );
};

export default EmptyViewStateMobile;
