import { useEffect, useMemo, useState } from "react";
import {
  Event,
  MassText,
  MassTextAttachment,
  MassTextAttachmentType,
  MassTextType,
} from "@markit/common.types";
import {
  GetDateWithTime,
  abbreviateNumber,
  isEventExternalLink,
  massTextLinkType,
  massTextMessageConverter,
  truncateString,
} from "@markit/common.utils";
import {
  getMassTextAttachments,
  getNumMassTextRecipients,
} from "../../../../utils/textingUtils";
import { Icon } from "@iconify/react";
import CustomLinkify from "../../../Links/CustomLinkify";
import { API } from "../../../../API";
import { useTheme } from "../../../../hooks/useTheme";

type MassTextPreview = {
  opens: number;
  conversions: number;
};

type MassTextItemPreviewProps = {
  userId: string;
  massTextItem: MassText;
  events: Event[];
  scheduledSent: boolean;
  onPress: () => void;
};

export const MassTextItemPreview = ({
  userId,
  massTextItem,
  events,
  scheduledSent,
  onPress,
}: MassTextItemPreviewProps) => {
  const { theme } = useTheme();
  const [massTextPreview, setMassTextPreview] = useState<MassTextPreview>({
    opens: 0,
    conversions: 0,
  });
  const [massTextAttachments, setMassTextAttachments] = useState<
    MassTextAttachment[] | null | undefined
  >(null);
  const [numRecipients, setNumRecipients] = useState(0);

  const foundEvent = useMemo(
    () => events.find((event) => event.id === massTextItem.eventRefId),
    [events, massTextItem.eventRefId]
  );

  useEffect(() => {
    (async () => {
      const [attachments, numRecipients] = await Promise.all([
        getMassTextAttachments(userId, massTextItem),
        getNumMassTextRecipients(userId, massTextItem.id),
      ]);
      setNumRecipients(numRecipients);

      // if no text attachments, return immediately
      if (attachments === undefined) {
        setMassTextAttachments(undefined);
        const massTextPreview: MassTextPreview = {
          opens: 0,
          conversions: 0,
        };

        setMassTextPreview(massTextPreview);
        return;
      }

      if (attachments.length === 1) {
        const response = await Promise.all([
          API.tracking.getAttachmentNumOpens({
            userId: userId,
            massTextId: massTextItem.id,
            attachmentId: attachments[0].id,
          }),
          API.tracking.getAttachmentNumConversions({
            userId: userId,
            massTextId: massTextItem.id,
            attachmentId: attachments[0].id,
          }),
        ]);
        const massTextPreview: MassTextPreview = {
          opens: response[0].totalNum,
          conversions: response[1].totalNum,
        };
        setMassTextPreview(massTextPreview);
      }
      setNumRecipients(numRecipients);
      setMassTextAttachments(attachments);
    })();
  }, [massTextItem, userId]);

  const textNumRecipients = useMemo(
    () =>
      massTextItem.subSelectionIds.length > 0
        ? `${massTextItem.subSelectionIds.length} ${
            massTextItem.subSelectionIds.length !== 1
              ? "Categories"
              : "Category"
          }`
        : numRecipients > 0
        ? `${abbreviateNumber(numRecipients)} Recipient${
            numRecipients !== 1 ? "s" : ""
          }`
        : "--",
    [massTextItem.subSelectionIds.length, numRecipients]
  );

  const massTextLinkName = useMemo(
    () =>
      massTextAttachments && massTextAttachments.length > 0
        ? massTextLinkType(massTextAttachments)
        : "No Links",
    [massTextAttachments]
  );

  const showTextAnalytics = useMemo(
    () =>
      massTextAttachments &&
      massTextAttachments.length === 1 &&
      massTextAttachments[0].massTextType !==
        MassTextAttachmentType.TICKET_LINK &&
      massTextAttachments[0].massTextType !==
        MassTextAttachmentType.CALENDAR_INVITE,
    [massTextAttachments]
  );

  // Look at the first attachments event type and show conversions only if it's a markit event
  // TODO (Peter): Will remove this once we start tracking conversions on external events
  const showTextConversions = useMemo(() => {
    if (massTextAttachments && massTextAttachments.length > 0) {
      const foundEvent = events.find(
        (event) => event.id === massTextAttachments[0].eventId
      );
      if (foundEvent) {
        return !isEventExternalLink(foundEvent.eventType);
      }
      return false;
    }
    return true;
  }, [events, massTextAttachments]);

  return (
    <div onClick={onPress} className="TextingPanelMassTextContainer">
      <div className="ColumnNormal" style={{ cursor: "pointer", gap: 4 }}>
        <span className="AboutSubtitle" style={{ fontSize: 14 }}>
          {massTextItem.type === MassTextType.EVENTTEXT
            ? (foundEvent && isEventExternalLink(foundEvent.eventType)
                ? "Link"
                : "Event") + " Text"
            : "Mass Text"}
        </span>
        {massTextItem.type === MassTextType.EVENTTEXT && (
          <span style={{ fontSize: 14, color: theme.SubText.color }}>
            {foundEvent ? foundEvent.title : "Markit Event"}
          </span>
        )}
      </div>
      <div style={{ cursor: "pointer" }}>
        <span
          className="AboutSubtitle"
          style={{ fontSize: 14, fontWeight: 400 }}
        >
          {GetDateWithTime(massTextItem.sentAt, true)}
        </span>
        <div className="AlignedRow" style={{ gap: 4, marginTop: 4 }}>
          <Icon
            icon={
              massTextItem.scheduled && !scheduledSent
                ? "ion:timer-outline"
                : "ion:paper-plane-outline"
            }
            height={13}
            color={theme.SubText.color}
          />
          <span style={{ color: theme.SubText.color, fontSize: 14 }}>
            {massTextItem.scheduled && !scheduledSent ? "Scheduled" : "Sent"}
          </span>
        </div>
      </div>
      <div style={{ marginLeft: -5 }}>
        <CustomLinkify>
          <span
            style={{
              display: "inline-block",
              fontSize: 14,
              color: theme.SubText.color,
              overflowWrap: "anywhere",
            }}
          >
            {massTextAttachments === null
              ? "Loading message..."
              : truncateString(
                  massTextMessageConverter(
                    massTextItem.message,
                    massTextAttachments
                  ),
                  400,
                  "..."
                )}
          </span>
        </CustomLinkify>
      </div>
      <span style={{ fontSize: 14, color: theme.SubText.color }}>
        {textNumRecipients}
      </span>
      <div className="ColumnNormal" style={{ cursor: "pointer", gap: 4 }}>
        <span style={{ fontSize: 14, color: theme.SubText.color }}>
          {massTextLinkName}
        </span>
        <span style={{ fontSize: 14, color: theme.SubText.color }}>
          {massTextAttachments === null
            ? "Loading..."
            : showTextAnalytics
            ? `${massTextPreview.opens} open${
                massTextPreview.opens !== 1 ? "s" : ""
              }` +
              (showTextConversions
                ? `, ${massTextPreview.conversions} conversion${
                    massTextPreview.conversions !== 1 ? "s" : ""
                  }`
                : "")
            : ""}
        </span>
      </div>
    </div>
  );
};
