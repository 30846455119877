import React, { useCallback } from "react";
import { Colors } from "../../utils/colors";
import StandardBorderedContainer from "../Containers/StandardBorderedContainer";
import { Icon } from "@iconify/react";
import EventbriteIcon from "../IntegrationIcons/EventbriteIcon";
import RectangleButton from "../Buttons/RectangleButton";

type EventbriteEventPanelProps = {
  eventbriteEventId: string;
};

export const EventbriteEventPanel = (props: EventbriteEventPanelProps) => {
  const { eventbriteEventId } = props;

  const styles = {
    container: { width: "100%" },

    optionsContainer: {
      backgroundColor: Colors.WHITE,
      width: "100%",
      paddingBlock: 200,
    },
  };

  const visitEventbrite = useCallback(() => {
    window.open(`https://www.eventbrite.com/myevent?eid=${eventbriteEventId}`);
  }, [eventbriteEventId]);

  return (
    <div className="RowNormal">
      <StandardBorderedContainer containerStyles={styles.optionsContainer}>
        <div className="ColumnNormal Centering" style={{ gap: 20 }}>
          <EventbriteIcon size={64} padding={20} />
          <div className="ColumnNormal" style={{ gap: 7 }}>
            <span className="sectionTitle" style={{ textAlign: "center" }}>
              Synced Eventbrite Event
            </span>
            <span className="smallBodySubtext" style={{ textAlign: "center" }}>
              This is a synced event.
              <br />
              Manage your event on Eventbrite.
            </span>
          </div>
          <div>
            <RectangleButton
              buttonLabel="Visit Eventbrite"
              onPress={visitEventbrite}
              altPaddingHorz={14}
              altPaddingVert={14}
              altBorderRadius={100}
              iconRight={
                <Icon
                  icon="ion:arrow-forward"
                  height={15}
                  color={Colors.WHITE}
                  style={{ transform: "rotateY(0deg) rotate(-45deg)" }}
                />
              }
              altColor={Colors.BLACK}
              altTextColor={Colors.WHITE}
            />
          </div>
        </div>
      </StandardBorderedContainer>
    </div>
  );
};
