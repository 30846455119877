import "../../css/FullEvent/FullEvent.css";
import "../../css/FullEvent/ShareTickets.css";
import "../../css/FullEvent/EventDashboard.css";
import "react-loading-skeleton/dist/skeleton.css";
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  LoginState,
  fetchReadyForPayment,
  getAccountState,
} from "../../redux/slices/accountSlice";
import { getEventState } from "../../redux/slices/eventSlice";
import { Colors } from "../../utils/colors";
import { getUserRoleTicketFromUserTickets } from "../../utils/eventUtils/userTicketUtils";
import {
  AccountData,
  DefaultLinkTrackerName,
  RequestStatus,
  SubSelectionItem,
  TicketV2,
} from "@markit/common.types";
import { getTotalRevenueForEvent } from "../../utils/eventUtils/eventUtils";
import { TabPanel } from "../../components/FullEventSubComponents/TabPanel";
import LoadingScreen from "../LoadingScreen";
import Footer from "../../components/Footer";
import {
  friendlyRoleName,
  isEventExternalLink,
  isExternalEventbrite,
  isExternalLinkNoData,
} from "@markit/common.utils";
import ShareEventModal from "../../components/EventDashboard/DashboardModals/ShareEventModal";
import { useLiveUpdatingEvent } from "../../hooks/useLiveUpdatingEvent";
import { useLiveUpdatingEventAnalytics } from "../../hooks/useLiveUpdatingEventAnalytics";
import { useLiveUpdatingEventTexts } from "../../hooks/useLiveUpdatingEventTexts";
import { AnalyticsPanel } from "../../components/EventDashboard/AnalyticsPanel";
import { getUserEventLinkTrackersRef } from "../../utils/FirebaseUtils";
import { onSnapshot, query, where } from "firebase/firestore";
import { TrackingLinkModal } from "../../components/EventDashboard/DashboardModals/TrackingLinkModal";
import { PromoCodePanel } from "../../components/EventDashboard/PromoCodePanel";
import { FormsPanel } from "../../components/EventDashboard/FormsPanel";
import ProfilePreviewModal from "../../components/FollowerProfile/ProfilePreviewModal";
import { useEventSelector } from "../../hooks/useParametricSelector";
import ConversationsSidebarModal from "../../components/CreatorDashboard/Conversations/ConversationsSidebarModal";
import { FormResponsesModal } from "../../components/EventDashboard/DashboardModals/FormResponsesModal";
import EventRoleModal from "../../components/EventDashboard/DashboardModals/EventRoleModal";
import EventTexts from "../../components/CreatorDashboard/MassTexts/EventTexts";
import MassTextsPopupPanel from "../../components/CreatorDashboard/MassTexts/MassTextsPopupPanel";
import HelpModal from "../../components/Containers/HelpModal";
import { EventDashboardHeader } from "../../components/EventDashboard/EventDashboardHeader";
import { EventPublishedModal } from "../../components/EventDashboard/DashboardModals/EventPublishedModal";
import { EventDashboardTabs } from "../../components/EventDashboard/EventDashboardTabs";
import { TextsPanel } from "../../components/EventDashboard/TextsPanel";
import { SettingsPanel } from "../../components/EventDashboard/SettingsPanel";
import { GuestListPanel } from "../../components/EventDashboard/GuestListPanel";
import { useLoadUserTicketList } from "../../hooks/useLoadUserTicketList";
import { MixpanelContext } from "../../context/AnalyticsService";
import EnablePaidTicketsModal from "../../components/DisplayModal/EnablePaidTicketsModal";
import { useNavigate } from "../../hooks/useNavigate";
import ConfirmActionPopup from "../../components/Containers/ConfirmPopups/ConfirmActionPopup";
import { EventbriteEventPanel } from "../../components/EventDashboard/EventbriteEventPanel";
import CreatorModeWrapper from "../../components/CreatorDashboard/CreatorModeWrapper";
import { useLoadTrackingLinksList } from "../../hooks/useLoadTrackingLinksList";
import { API } from "../../API";

export enum LinkCreationConfirmActions {
  PROMO_CREATION = "Promo Code Created",
  PROMO_DELETION = "Promo Code Deleted",
  LINK_CREATION = "Tracking Link Created",
  LINK_DELETION = "Tracking Link Deleted",
  NONE = "",
}
const EventDashboard = memo(function EventDashboardFn() {
  const { account } = useSelector(getAccountState);
  const { events } = useSelector(getEventState);
  const { accountData, loggedIn, appInitialized, readyForPayment } = account;
  const { tickets: userTickets } = events;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mixpanel = useContext(MixpanelContext);

  const eventId = useEventSelector();
  // use event and never eventId because we can also get eventId from locationState
  const { liveEvent: event, isLiveEventError } = useLiveUpdatingEvent({
    initialEventId: eventId,
  });
  const {
    liveTotalGuests,
    liveTicketsScanned,
    liveTotalRequested,
    liveTotalOrganizers,
    liveTotalLinkViews,
  } = useLiveUpdatingEventAnalytics({
    eventId: eventId,
    userId: event?.createdBy,
  });

  const {
    eventTextsLoading,
    regularTextsLoading,
    eventMassTexts,
    regularMassTextsInRange,
    massTextAttachments,
  } = useLiveUpdatingEventTexts({ eventId: eventId });

  const [loading, setLoading] = useState(true);
  const [dashboardValue, setDashboardValue] = useState(0);
  const [previousDashboardValue, setPreviousDashboardValue] = useState(0);

  const [totalRevenue, setTotalRevenue] = useState<number | null>(null);
  const [loadingAttendees, setLoadingAttendees] = useState(true);
  const [loadingPendingRequests, setLoadingPendingRequests] = useState(true);
  const [loadingOrganizers, setLoadingOrganizers] = useState(true);

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [profilePreviewSelected, setProfilePreviewSelected] =
    useState<AccountData>();
  const [hideProfilePreview, setHideProfilePreview] = useState(false);
  const [fullProfileOpen, setFullProfileOpen] = useState(false);

  const [isUserResponseModalOpen, setIsUserResponseModalOpen] =
    useState<boolean>(false);

  const [isEventRoleModalOpen, setIsEventRoleModalOpen] = useState(false);
  const [roleOrganizerTicketItem, setRoleOrganizerTicketItem] =
    useState<TicketV2>();
  const [roleOrganizerData, setRoleOrganizerData] = useState<AccountData>();

  const [conversationUser, setConversationUser] = useState<AccountData>();

  const [numTotalLinkTrackers, setNumTotalLinkTrackers] = useState(0);
  const [loadingTrackingLinks, setLoadingTrackingLinks] = useState(true);
  const [isTrackingLinksView, setIsTrackingLinksView] = useState(false);
  const [trackingLinkModalVisible, setTrackingLinkModalVisible] =
    useState(false);
  const [linkTrackerId, setLinkTrackerId] = useState<string | undefined>(
    undefined
  );

  const [confirmPopupCreationType, setConfirmPopupCreationType] =
    useState<LinkCreationConfirmActions>(LinkCreationConfirmActions.NONE);
  const [trackingLinkModalText, setTrackingLinkModalText] = useState("");
  const [eventTextsVisible, setEventTextsVisible] = useState(false);
  const [massTextsPopupPanelVisible, setMassTextsPopupPanelVisible] =
    useState<boolean>(false);
  const [suggestedAction, setSuggestedAction] = useState<SubSelectionItem>();
  const [showHelpModal, setShowHelpModal] = useState<boolean>(false);
  const [stripeVisible, setStripeVisible] = useState<boolean>(false);

  const isExternalLink = useMemo(
    () => event && isEventExternalLink(event.eventType),
    [event]
  );

  const userTicket = useMemo(
    () =>
      event
        ? getUserRoleTicketFromUserTickets(
            event.id,
            accountData.uid,
            userTickets
          )
        : undefined,
    [accountData.uid, event, userTickets]
  );

  const styles = {
    headerView: {
      padding: "30px 48px 0px 48px",
      backgroundColor: Colors.WHITE,
    },

    contentView: {
      padding: "30px 48px 100px",
      backgroundColor: Colors.WHITE1,
      minHeight: "80vh",
    },
  };

  // redirect if not an admin or organizer for the event
  useEffect(() => {
    (async () => {
      if (!event || (loggedIn === LoginState.LOGGED_IN && !appInitialized)) {
        return;
      }

      /*
       * userTicket = undefined -> if user doesn't have a ticket
       * userTicket.customTicketId !== "" -> if user has a ticket but is not an organizer
       */
      if (
        !accountData.isAdmin &&
        (!userTicket ||
          userTicket.customTicketId !== "" ||
          loggedIn === LoginState.LOGGED_OUT)
      ) {
        navigate("/e/" + event.id);
      }

      if (event.isDraft) {
        navigate("/create", {
          state: { eventId: event.id },
        });
      }
    })();
  }, [accountData.isAdmin, appInitialized, event, loggedIn, navigate, userTicket]);

  // TODO: Create a custom hook wrapper that can load all the required data, so we don't need to pass it as props throughout
  useEffect(() => {
    (async () => {
      if (!event) {
        return;
      }

      const [totalRevenue] = await Promise.all([
        getTotalRevenueForEvent(event.id),
      ]);
      setTotalRevenue(totalRevenue / 100);

      if (userTicket) {
        // for any cohost or promoter with rev share enabled
        if (userTicket && userTicket.role.revenuePercentSplit !== 0) {
          dispatch(fetchReadyForPayment(accountData.uid));
        }
      }

      setLoading(false);
    })();
  }, [accountData.uid, dispatch, event, userTicket]);

  const roleName = useMemo(() => friendlyRoleName(userTicket), [userTicket]);

  const dashboardVisibility = useMemo(
    () =>
      Math.max(
        accountData.isAdmin ? 2 : 0,
        userTicket ? userTicket.role.dashboardVisibility : 0
      ),
    [accountData.isAdmin, userTicket]
  );

  const canMassText = useMemo(
    () =>
      accountData.isAdmin || (userTicket ? userTicket.role.massTexting : false),
    [accountData.isAdmin, userTicket]
  );

  const checkInGuests = useMemo(
    () =>
      accountData.isAdmin ||
      (userTicket ? userTicket.role.checkInGuests : false),
    [accountData.isAdmin, userTicket]
  );

  const revenuePercentSplit = useMemo(
    () => (userTicket ? userTicket.role.revenuePercentSplit : 0),
    [userTicket]
  );

  const isHost = useMemo(
    () => (event ? accountData.uid === event.createdBy : false),
    [accountData.uid, event]
  );

  const openEventTexts = useCallback(() => {
    if (accountData.isAdmin || (userTicket && userTicket.role.massTexting)) {
      setEventTextsVisible(true);
      setDashboardValue(1);
    }
  }, [accountData.isAdmin, userTicket]);

  const closeEventTexts = useCallback(() => {
    if (accountData.isAdmin || (userTicket && userTicket.role.massTexting)) {
      setEventTextsVisible(false);
      setDashboardValue(previousDashboardValue);
    }
  }, [accountData.isAdmin, previousDashboardValue, userTicket]);

  const openShareModal = useCallback(
    async (source: string) => {
      mixpanel.track("Pressed Event Dashboard Share", {
        event_id: event?.id,
        type: event?.eventType,
        in_app: false,
        source: source,
      });
      if (
        userTicket &&
        userTicket.role.revenuePercentSplit !== 0 &&
        !readyForPayment
      ) {
        setStripeVisible(true);
      } else {
        setIsShareModalOpen(true);
      }
    },
    [event?.eventType, event?.id, mixpanel, readyForPayment, userTicket]
  );

  const closeShareModal = useCallback(() => {
    setIsShareModalOpen(false);
  }, []);

  const messageOnPress = useCallback((item: AccountData | undefined) => {
    setConversationUser(item);
  }, []);

  /* Start Tab viewing code */
  const isEventbrite = useMemo(
    () => (event ? isExternalEventbrite(event.eventType) : false),
    [event]
  );

  const viewTextsTab = useMemo(
    () =>
      ((userTicket && canMassText) || accountData.isAdmin) && event
        ? !isExternalLinkNoData(event.eventType)
        : false,
    [accountData.isAdmin, canMassText, event, userTicket]
  );

  const viewAnalyticsTab = useMemo(() => checkInGuests, [checkInGuests]);

  const viewFormsTab = useMemo(
    () =>
      checkInGuests && (event ? !isExternalLinkNoData(event.eventType) : false),
    [checkInGuests, event]
  );

  const viewPromosTab = useMemo(
    () =>
      (userTicket?.role.distributeTicketsAndCodes || accountData.isAdmin) &&
      !isExternalLink,
    [
      accountData.isAdmin,
      isExternalLink,
      userTicket?.role.distributeTicketsAndCodes,
    ]
  );

  const viewSettingsTab = useMemo(
    () => dashboardVisibility >= 2,
    [dashboardVisibility]
  );
  /* End Tab viewing code */

  /* Guest List code */
  const {
    isFinished: isAttendeeFinished,
    isLoading: isAttendeeLoading,
    fetchedUserData: fullAttendeeAccountData,
    fetchedTicketData: fullAttendeeTicketData,
    loadTicketUsers: loadAttendeeUsers,
    loadMoreTicketUsers: loadMoreAttendeeUsers,
    loadSearchResultsThrottled: loadAttendeeSearchResultsThrottled,
  } = useLoadUserTicketList({
    event: event,
    windowSize: 15,
    ticketType: RequestStatus.ACCEPTED,
  });

  const {
    isFinished: isPendingRequestsFinished,
    isLoading: isPendingRequestsLoading,
    fetchedUserData: fullPendingRequestsAccountData,
    fetchedTicketData: fullPendingRequestsTicketData,
    loadTicketUsers: loadPendingRequestsUsers,
    loadMoreTicketUsers: loadMorePendingRequestsUsers,
    loadSearchResultsThrottled: loadPendingRequestsSearchResultsThrottled,
  } = useLoadUserTicketList({
    event: event,
    windowSize: 15,
    ticketType: RequestStatus.PENDING,
  });

  const {
    isFinished: isOrganizerFinished,
    isLoading: isOrganizerLoading,
    fetchedUserData: fullOrganizerAccountData,
    fetchedTicketData: fullOrganizerTicketData,
    loadTicketUsers: loadOrganizerUsers,
    loadMoreTicketUsers: loadMoreOrganizerUsers,
    loadSearchResultsThrottled: loadOrganizerSearchResultsThrottled,
  } = useLoadUserTicketList({
    event: event,
    windowSize: 15,
    ticketType: "organizers",
  });

  useEffect(() => {
    if (event) {
      if (
        loadingAttendees &&
        fullAttendeeAccountData.length === 0 &&
        !isAttendeeFinished
      ) {
        loadAttendeeUsers();
        setLoadingAttendees(false);
      }
      if (
        loadingPendingRequests &&
        fullPendingRequestsAccountData.length === 0 &&
        !isPendingRequestsFinished
      ) {
        loadPendingRequestsUsers();
        setLoadingPendingRequests(false);
      }
      if (
        loadingOrganizers &&
        fullOrganizerAccountData.length === 0 &&
        !isOrganizerFinished
      ) {
        loadOrganizerUsers();
        setLoadingOrganizers(false);
      }
    }
  }, [event, fullAttendeeAccountData, fullOrganizerAccountData, fullPendingRequestsAccountData.length, isAttendeeFinished, isOrganizerFinished, isPendingRequestsFinished, loadAttendeeUsers, loadOrganizerUsers, loadPendingRequestsUsers, loadingAttendees, loadingOrganizers, loadingPendingRequests, setLoadingAttendees, setLoadingOrganizers, setLoadingPendingRequests]);
  /* End Guest List code */

  /* Start Analytics Tab code */
  const {
    isLoading: isLoadingTrackingLinks,
    isFinished: isFinishedTrackingLinks,
    fetchedLinkTrackers,
    loadSearchResultsThrottled,
    fetchMoreTrackingLinks,
    fetchTrackingLinks,
  } = useLoadTrackingLinksList({
    userId: event ? event.createdBy : accountData.uid,
    eventId: eventId,
    windowSize: 20,
  });

  useEffect(() => {
    (async () => {
      if (!event) {
        return;
      }
      if (loadingTrackingLinks) {
        await fetchTrackingLinks();

        const { numUserEventLinkTrackers } =
          await API.tracking.getNumUserEventLinkTrackers({
            eventUserId: event.createdBy,
            eventId: event.id,
          });
        setNumTotalLinkTrackers(numUserEventLinkTrackers);
        setLoadingTrackingLinks(false);

        const userEventLinkTrackersRef = getUserEventLinkTrackersRef(
          event.createdBy,
          event.id
        );
        const query_ = query(
          userEventLinkTrackersRef,
          where(
            "alias",
            "!=",
            DefaultLinkTrackerName.DEFAULT_EVENT_LINK_TRACKER
          )
        );
        const unsubscribe = onSnapshot(query_, (snapshot) => {
          snapshot.docChanges().forEach(async (change) => {
            if (change.type === "modified") {
              const { numUserEventLinkTrackers } =
                await API.tracking.getNumUserEventLinkTrackers({
                  eventUserId: event.createdBy,
                  eventId: event.id,
                });
              setNumTotalLinkTrackers(numUserEventLinkTrackers);
            }
          });
        });
        return unsubscribe;
      }
    })();
  }, [event, fetchTrackingLinks, loadingTrackingLinks]);
  /* End Analytics Tab code */

  if (
    !event ||
    eventTextsLoading ||
    regularTextsLoading ||
    loading ||
    !appInitialized
  ) {
    return (
      <LoadingScreen
        isLoadingPage={
          !event ||
          eventTextsLoading ||
          regularTextsLoading ||
          loading ||
          !appInitialized
        }
      />
    );
  }

  if (isLiveEventError) {
    return <LoadingScreen error />;
  }

  return (
    <CreatorModeWrapper>
      <div style={styles.headerView}>
        <EventDashboardHeader
          event={event}
          userTicket={userTicket}
          openShareModal={openShareModal}
        />
        <EventDashboardTabs
          dashboardValue={dashboardValue}
          setDashboardValue={setDashboardValue}
          setPreviousDashboardValue={setPreviousDashboardValue}
          viewTextsTab={viewTextsTab}
          viewAnalyticsTab={viewAnalyticsTab}
          viewFormsTab={viewFormsTab}
          viewPromosTab={viewPromosTab}
          viewSettingsTab={viewSettingsTab}
          isEventbrite={isEventbrite}
        />
      </div>
      <div style={styles.contentView}>
        {isEventbrite ? (
          <TabPanel value={dashboardValue} index={0}>
            <EventbriteEventPanel eventbriteEventId={event.externalEventId} />
          </TabPanel>
        ) : (
          <>
            <TabPanel value={dashboardValue} index={0}>
              <GuestListPanel
                event={event}
                userTicket={userTicket}
                totalGuests={liveTotalGuests}
                totalRequested={liveTotalRequested}
                totalOrganizers={liveTotalOrganizers}
                totalLinkViews={liveTotalLinkViews}
                checkInGuests={checkInGuests}
                canMassText={canMassText}
                dashboardVisiblity={dashboardVisibility}
                isHost={isHost}
                loading={loading}
                setMassTextsPopupPanelVisible={setMassTextsPopupPanelVisible}
                loadingAttendees={loadingAttendees}
                loadingOrganizers={loadingOrganizers}
                loadingPendingRequests={loadingPendingRequests}
                eventTextsLoading={eventTextsLoading}
                regularTextsLoading={regularTextsLoading}
                eventMassTexts={eventMassTexts}
                regularMassTextsInRange={regularMassTextsInRange}
                massTextAttachments={massTextAttachments}
                openEventTexts={openEventTexts}
                openShareModal={openShareModal}
                setIsEventRoleModalOpen={setIsEventRoleModalOpen}
                setRoleOrganizerData={setRoleOrganizerData}
                setRoleOrganizerTicketItem={setRoleOrganizerTicketItem}
                setProfilePreviewSelected={setProfilePreviewSelected}
                isAttendeeLoading={isAttendeeLoading}
                fullAttendeeTicketData={fullAttendeeTicketData}
                fullAttendeeAccountData={fullAttendeeAccountData}
                loadMoreAttendeeUsers={loadMoreAttendeeUsers}
                loadAttendeeSearchResultsThrottled={
                  loadAttendeeSearchResultsThrottled
                }
                isPendingRequestsLoading={isPendingRequestsLoading}
                fullPendingRequestsTicketData={fullPendingRequestsTicketData}
                fullPendingRequestsAccountData={fullPendingRequestsAccountData}
                loadMorePendingRequestsUsers={loadMorePendingRequestsUsers}
                loadPendingRequestsSearchResultsThrottled={
                  loadPendingRequestsSearchResultsThrottled
                }
                isOrganizerLoading={isOrganizerLoading}
                fullOrganizerTicketData={fullOrganizerTicketData}
                fullOrganizerAccountData={fullOrganizerAccountData}
                loadMoreOrganizerUsers={loadMoreOrganizerUsers}
                loadOrganizerSearchResultsThrottled={
                  loadOrganizerSearchResultsThrottled
                }
                isAttendeeFinished={isAttendeeFinished}
                isPendingRequestsFinished={isPendingRequestsFinished}
                isOrganizerFinished={isOrganizerFinished}
                setSuggestedAction={setSuggestedAction}
              />
            </TabPanel>
            {viewTextsTab ? (
              <TabPanel value={dashboardValue} index={1}>
                <TextsPanel openEventTexts={openEventTexts} />
              </TabPanel>
            ) : null}
            {viewAnalyticsTab ? (
              <TabPanel value={dashboardValue} index={2}>
                <AnalyticsPanel
                  event={event}
                  userTicket={userTicket}
                  totalScanned={liveTicketsScanned}
                  totalGuests={liveTotalGuests}
                  totalRevenue={totalRevenue}
                  totalLinkViews={liveTotalLinkViews}
                  canMassText={canMassText}
                  dashboardVisibility={dashboardVisibility}
                  fetchedLinkTrackers={fetchedLinkTrackers}
                  isFinishedTrackingLinks={isFinishedTrackingLinks}
                  isLoadingTrackingLinks={isLoadingTrackingLinks}
                  loadSearchResultsThrottled={loadSearchResultsThrottled}
                  fetchMoreTrackingLinks={fetchMoreTrackingLinks}
                  setTrackingLinkModalVisible={setTrackingLinkModalVisible}
                  numTotalLinkTrackers={numTotalLinkTrackers}
                  linkTrackerId={linkTrackerId}
                  setLinkTrackerId={setLinkTrackerId}
                  setTrackingLinkModalText={setTrackingLinkModalText}
                  setConfirmPopupCreationType={setConfirmPopupCreationType}
                />
              </TabPanel>
            ) : null}
            {viewFormsTab ? (
              <TabPanel value={dashboardValue} index={3}>
                <FormsPanel
                  event={event}
                  dashboardVisibility={dashboardVisibility}
                />
              </TabPanel>
            ) : null}
            {viewPromosTab ? (
              <TabPanel value={dashboardValue} index={4}>
                <PromoCodePanel
                  event={event}
                  setConfirmPopupCreationType={setConfirmPopupCreationType}
                />
              </TabPanel>
            ) : null}
            {viewSettingsTab ? (
              <TabPanel value={dashboardValue} index={5}>
                <SettingsPanel
                  event={event}
                  userTicket={userTicket}
                  totalGuests={liveTotalGuests ?? 0}
                  dashboardVisibility={dashboardVisibility}
                  isHost={isHost}
                />
              </TabPanel>
            ) : null}
          </>
        )}
      </div>
      {isShareModalOpen ? (
        <ShareEventModal
          event={event}
          userTicket={userTicket}
          revenuePercentSplit={revenuePercentSplit}
          hideTrackingLinks={
            (userTicket ? userTicket.role.dashboardVisibility < 2 : true) ||
            isEventbrite
          }
          closeShareModal={closeShareModal}
          isTrackingLinksView={isTrackingLinksView}
          setIsTrackingLinksView={setIsTrackingLinksView}
          trackingLinkData={fetchedLinkTrackers}
          fetchMoreTrackingLinks={fetchMoreTrackingLinks}
          canLoadMoreTrackingLinks={
            !isFinishedTrackingLinks && !isLoadingTrackingLinks
          }
          setTrackingLinkModalVisible={setTrackingLinkModalVisible}
        />
      ) : null}
      {trackingLinkModalVisible ? (
        <TrackingLinkModal
          event={event}
          linkTrackerId={linkTrackerId}
          setLinkTrackerId={setLinkTrackerId}
          trackingLinkModalText={trackingLinkModalText}
          setTrackingLinkModalText={setTrackingLinkModalText}
          setTrackingLinkModalVisible={setTrackingLinkModalVisible}
          openShareModal={openShareModal}
          setIsTrackingLinksView={setIsTrackingLinksView}
          setConfirmPopupCreationType={setConfirmPopupCreationType}
        />
      ) : null}
      {profilePreviewSelected ? (
        isUserResponseModalOpen ? (
          <FormResponsesModal
            event={event}
            profileData={profilePreviewSelected}
            setIsVisible={setIsUserResponseModalOpen}
          />
        ) : (
          <ProfilePreviewModal
            userData={profilePreviewSelected}
            event={event}
            userTicket={userTicket}
            setProfileSelected={setProfilePreviewSelected}
            setProfileResponseModalOpen={setIsUserResponseModalOpen}
            messageOnPress={(item) => messageOnPress(item)}
            hideProfilePreview={hideProfilePreview}
            setHideProfilePreview={setHideProfilePreview}
            fullProfileOpen={fullProfileOpen}
            setFullProfileOpen={setFullProfileOpen}
          />
        )
      ) : null}
      {conversationUser ? (
        <ConversationsSidebarModal
          conversationUser={conversationUser}
          setConversationUser={setConversationUser}
          reopenProfilePreview={() =>
            hideProfilePreview && !fullProfileOpen
              ? setHideProfilePreview(false)
              : null
          }
          fullProfileOpen={fullProfileOpen}
        />
      ) : null}
      {!accountData.inCreatorMode ? <Footer showRCA /> : null}
      {isEventRoleModalOpen ? (
        <EventRoleModal
          event={event}
          organizerTicketItem={roleOrganizerTicketItem}
          organizerData={roleOrganizerData}
          setOrganizerTicketItem={setRoleOrganizerTicketItem}
          setOrganizerData={setRoleOrganizerData}
          isEventRoleModalOpen={isEventRoleModalOpen}
          setIsEventRoleModalOpen={setIsEventRoleModalOpen}
          setProfilePreviewSelected={setProfilePreviewSelected}
        />
      ) : null}
      {eventTextsVisible ? (
        <EventTexts
          isVisible={eventTextsVisible}
          openEventTexts={openEventTexts}
          closeEventTexts={closeEventTexts}
          event={event}
          totalGuests={liveTotalGuests}
          totalRequested={liveTotalRequested}
        />
      ) : null}
      <HelpModal showModal={showHelpModal} setShowModal={setShowHelpModal} />
      {stripeVisible ? (
        <EnablePaidTicketsModal
          isVisible={stripeVisible}
          setIsVisible={setStripeVisible}
          isPromoter
        />
      ) : null}
      <EventPublishedModal
        event={event}
        roleName={roleName}
        eventMassTexts={eventMassTexts}
        regularMassTextsInRange={regularMassTextsInRange}
        massTextAttachments={massTextAttachments}
        setMassTextsPopupPanelVisible={setMassTextsPopupPanelVisible}
        openEventTexts={openEventTexts}
        setSuggestedAction={setSuggestedAction}
      />
      {massTextsPopupPanelVisible ? (
        <MassTextsPopupPanel
          isVisible={massTextsPopupPanelVisible}
          setIsVisible={setMassTextsPopupPanelVisible}
          event={event}
          suggestedAction={suggestedAction}
        />
      ) : null}
      {confirmPopupCreationType ? (
        <ConfirmActionPopup
          title={confirmPopupCreationType}
          negativeTitle={confirmPopupCreationType}
          isNegative={
            confirmPopupCreationType ===
              LinkCreationConfirmActions.LINK_DELETION ||
            confirmPopupCreationType ===
              LinkCreationConfirmActions.PROMO_DELETION
          }
          onDisappear={() =>
            setConfirmPopupCreationType(LinkCreationConfirmActions.NONE)
          }
        />
      ) : null}
    </CreatorModeWrapper>
  );
});

export default EventDashboard;
