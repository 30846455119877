import "../../css/FullEvent/FullEvent.css";
import "../../css/FullEvent/FullEventCardSummary.css";
import { memo, useCallback } from "react";
import { Event, AccountData } from "@markit/common.types";
import { CompareDates } from "../../utils/SetStartEndDate";
import { GetLongDate } from "../../utils/GetLongDate";
import { GetTime } from "../../utils/GetTime";
import { isDesktop } from "react-device-detect";
import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import { getTimezone, truncateString } from "@markit/common.utils";
import { FiChevronRight } from "react-icons/fi";
import { Colors } from "../../utils/colors";
import { Icon } from "@iconify/react";

type FullEventCardSummaryProps = {
  event: Event;
  host: AccountData | undefined;
  isMyAccount?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  darkMode?: boolean;
};

const FullEventCardSummary = memo(function FullEventCardSummaryFn(
  props: FullEventCardSummaryProps
) {
  const { event, isMyAccount, onClick, disabled, darkMode, host } = props;
  const { account } = useSelector(getAccountState);
  const { isRCA, accountData } = account;

  const onProfileClick = useCallback(() => {
    if (host) {
      window.open("https://markitai.com/u/" + host.username);
    }
  }, [host]);

  return (
    <div
      style={{ pointerEvents: disabled ? "none" : "all" }}
      onClick={onClick}
      className={isDesktop ? "CardEventSummaryDesktop" : "CardEventSummary"}
    >
      <div
        className={
          isDesktop
            ? "CardEventSummaryOnConfirmationDesktop"
            : "CardEventSummaryOnConfirmation"
        }
      >
        {event.photoURL ? (
          <img
            className={isDesktop ? "CardImageDesktop" : "CardImage"}
            src={event.photoURL}
            alt={"event fullImage"}
          />
        ) : (
          <div
            className={
              "DraftImage" + (isDesktop ? " CardImageDesktop" : " CardImage")
            }
          >
            <Icon
              icon="ion:image"
              height={isDesktop ? 70 : 50}
              style={{ color: Colors.GRAY1 }}
            />
          </div>
        )}
        <div
          style={{
            marginLeft: 10,
            paddingBlock: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            className="CardEventTitle"
            style={{
              marginBottom: 0,
              fontSize: isDesktop ? 22 : 14,
              color: darkMode ? "#FFF" : "#000",
            }}
          >
            {event.title ? truncateString(event.title, 30, "...") : "Untitled"}
          </div>
          <div style={{ marginBottom: isDesktop ? 10 : 0 }}>
            {event.start && CompareDates(event.start, event.end) ? (
              <div className="CardLongDateContainer">
                <span
                  style={
                    isRCA
                      ? {
                          fontSize: isDesktop ? 14 : 11,
                          color: "black",
                          fontWeight: "600",
                        }
                      : { fontSize: isDesktop ? 14 : 11 }
                  }
                >
                  {GetLongDate(event.start, true, true, false, false)},{" "}
                  {GetTime(event.start)} - {""}
                  {GetLongDate(event.end, true, true, false, false)},{" "}
                  {GetTime(event.end)} {getTimezone()}.
                </span>
              </div>
            ) : (
              <div className="CardDateContainer">
                <span
                  className="CardDateTextProfile"
                  style={
                    isRCA
                      ? {
                          fontSize: isDesktop ? "14px" : "12px",
                          color: "black",
                          fontWeight: "600",
                        }
                      : {
                          fontSize: isDesktop ? "14px" : "12px",
                        }
                  }
                >
                  {event.start
                    ? GetLongDate(event.start, false, true).slice(0, -6) +
                      " " +
                      GetTime(event.start) +
                      " " +
                      getTimezone() +
                      "."
                    : "Date Unselected"}
                </span>
              </div>
            )}
          </div>
          <div className="CardDateContainer">
            {event.isVirtual || (event.privateLocation && !isMyAccount) ? (
              <span
                style={{ fontSize: isDesktop ? 14 : 12 }}
                className="CardAddress"
              >
                {event.isVirtual ? "Virtual Location" : "Hidden Location"}
              </span>
            ) : (
              <span
                style={{ fontSize: isDesktop ? 14 : 12 }}
                className="CardAddress"
              >
                {event.googleDescription
                  ? truncateString(event.googleDescription, 36, "...")
                  : "Location Unselected"}
              </span>
            )}
          </div>
          {host && event.createdBy === accountData.uid ? (
            <div
              className={"CardHost"}
              style={{ marginBottom: isDesktop ? 10 : 0 }}
            >
              <span
                style={{
                  fontSize: isDesktop ? 16 : 12,
                }}
              >
                Hosted by{" "}
                <span
                  className="CardHostBlue"
                  onClick={onProfileClick}
                  style={{
                    color: isRCA ? "#FE296C" : "",
                  }}
                >
                  {truncateString(host.fullName, 26, "...")}
                </span>
              </span>
            </div>
          ) : (
            <div />
          )}
        </div>
        {!disabled ? (
          <FiChevronRight
            style={{ alignSelf: "center" }}
            size={24}
            color={Colors.GRAY1}
          />
        ) : (
          <div style={{ width: 25 }} />
        )}
      </div>
    </div>
  );
});

export default FullEventCardSummary;
