import { memo, useCallback, useEffect, useRef, useState } from "react";
import {
  MEDIA_UPLOAD_CODES,
  errorAlertText,
  errorSubAlertText,
  uploadMedia,
} from "../../../../utils/photoUtils";
import AlertContainer from "../../../Containers/AlertContainer";
import { ThemeStyle } from "../../../../hooks/useTheme";
import { Icon } from "@iconify/react";

type AttachmentsMediaButtonProps = {
  image: string;
  setImage: (image: string) => void;
  children: JSX.Element;
  theme?: ThemeStyle;
};

export const AttachmentsMediaButton = memo(function AttachmentsMediaButtonFn(
  props: AttachmentsMediaButtonProps
) {
  const { image, setImage, children, theme } = props;
  const [alertText, setAlertText] = useState("");
  const [alertSubText, setAlertSubText] = useState("");

  // States for image picker
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  // this is to handle case where you remove the image and then select the same one. If we remove an image, we need to make sure to clear the file input ref so that the React.ChangeEvent gets triggered again
  useEffect(() => {
    (async () => {
      if (image === "" && fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    })();
  }, [image]);

  /*  image selecting functions */
  const uploadImage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      return new Promise<void>(async (resolve, reject) => {
        // Check the file type to exclude GIFs
        const file = event.target.files && event.target.files[0];
        if (file && fileInputRef.current && file.type === "image/gif") {
          setAlertText("GIF files are currently not allowed.");
          fileInputRef.current.value = ""; // Reset the file input
          reject();
        }
        const uploadData = await uploadMedia(event, false, true);
        if (uploadData.uploadCode !== MEDIA_UPLOAD_CODES.SUCCESS) {
          setAlertText(errorAlertText(uploadData.uploadCode, true));
          setAlertSubText(errorSubAlertText(uploadData.uploadCode));
          reject();
        } else {
          setImage(uploadData.blobUrl);
          resolve();
        }
      });
    },
    [setImage]
  );
  /*  END image selecting functions */

  return (
    <>
      <AlertContainer
        headerComp={
          <div className="ColumnNormal" style={{ gap: 4 }}>
            <Icon icon="mdi-image-remove" height={40} />
            <span style={{ fontSize: 16, fontWeight: "500" }}>{alertText}</span>
          </div>
        }
        subHeaderComp={alertSubText}
        theme={theme}
        closeModal={() => {
          setAlertText("");
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        }}
        hideModal={alertText === ""}
      />
      <div
        className="ColumnNormalSelect"
        onClick={() => {
          if (fileInputRef.current) {
            fileInputRef.current.click();
          }
        }}
      >
        {children}
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          accept="image/*"
          onChange={uploadImage}
        />
      </div>
    </>
  );
});
