import React from "react";
import { ThemeStyle } from "../../hooks/useTheme";
import PopupModalContainer, {
  PopupModalContainerWidth,
} from "./PopupModalContainer";
import RectangleButton from "../Buttons/RectangleButton";
import { Colors } from "../../utils/colors";

type AlertContainerProps = {
  headerComp: string | React.ReactNode;
  subHeaderComp?: string | React.ReactNode;
  alternateValueComp?: React.ReactNode;
  theme?: ThemeStyle;
  closeModal: () => void;
  closeOnOutsidePress?: boolean;
  hideModal?: boolean;
  altButtonLabel?: string;
};

// Used for any action that has a response notice that needs to show up for the user to see
const AlertContainer = (props: AlertContainerProps) => {
  const {
    headerComp,
    subHeaderComp,
    alternateValueComp,
    theme,
    closeModal,
    closeOnOutsidePress,
    hideModal,
    altButtonLabel,
  } = props;
  return (
    <PopupModalContainer
      headerComp={headerComp}
      valueComp={
        alternateValueComp ?? (
          <RectangleButton
            buttonLabel={altButtonLabel ?? "Ok"}
            onPress={closeModal}
            altTextColor={Colors.WHITE}
            altColor={Colors.BLACK}
            altPaddingVert={14}
          />
        )
      }
      subHeaderComp={
        <div style={{ marginTop: 7, fontSize: 14 }}>{subHeaderComp}</div>
      }
      theme={theme}
      closeModal={closeModal}
      noExit
      closeOnOutsidePress={closeOnOutsidePress}
      hideModal={hideModal}
      modalContentWidth={PopupModalContainerWidth.SMALL}
    />
  );
};

export default AlertContainer;
