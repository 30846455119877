import { useCallback } from "react";
import {
  CustomTab,
  CustomTabs,
  a11yProps,
} from "../../components/FullEventSubComponents/CustomTabs";

type EventDashboardTabsProps = {
  dashboardValue: number;
  setDashboardValue: (dashboardValue: number) => void;
  setPreviousDashboardValue: (previousDashboardValue: number) => void;
  viewTextsTab: boolean;
  viewAnalyticsTab: boolean;
  viewFormsTab: boolean;
  viewPromosTab: boolean;
  viewSettingsTab: boolean;
  isEventbrite: boolean;
};

export const EventDashboardTabs = (props: EventDashboardTabsProps) => {
  const {
    dashboardValue,
    setDashboardValue,
    setPreviousDashboardValue,
    viewTextsTab,
    viewAnalyticsTab,
    viewFormsTab,
    viewPromosTab,
    viewSettingsTab,
    isEventbrite,
  } = props;
  const handleDashboardChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setPreviousDashboardValue(dashboardValue);
      setDashboardValue(newValue);
    },
    [dashboardValue, setDashboardValue, setPreviousDashboardValue]
  );

  return (
    <div style={{ marginTop: 28 }}>
      <CustomTabs
        value={dashboardValue}
        onChange={handleDashboardChange}
        variant="scrollable"
        scrollButtons={false}
        sx={{ minHeight: 0 }}
      >
        <CustomTab
          label={
            <div
              className={
                dashboardValue === 0
                  ? "EventTabNameRowActive"
                  : "EventTabNameRow"
              }
              style={{ marginLeft: 0 }}
            >
              <span className={"TabName"}>Dashboard</span>
            </div>
          }
          sx={{ minHeight: 0, cursor: "default" }}
          {...a11yProps(0)}
        />
        {viewTextsTab && !isEventbrite ? (
          <CustomTab
            label={
              <div
                className={
                  !viewTextsTab
                    ? "EventTabNameRowDisabled"
                    : dashboardValue === 1
                    ? "EventTabNameRowActive"
                    : "EventTabNameRow"
                }
              >
                <span className={"TabName"}>Texts</span>
              </div>
            }
            sx={{ minHeight: 0, cursor: "default" }}
            {...a11yProps(1)}
          />
        ) : (
          <div />
        )}
        {viewAnalyticsTab && !isEventbrite ? (
          <CustomTab
            label={
              <div
                className={
                  !viewAnalyticsTab
                    ? "EventTabNameRowDisabled"
                    : dashboardValue === 2
                    ? "EventTabNameRowActive"
                    : "EventTabNameRow"
                }
              >
                <span className={"TabName"}>Analytics</span>
              </div>
            }
            sx={{ minHeight: 0, cursor: "default" }}
            {...a11yProps(2)}
          />
        ) : (
          <div />
        )}
        {viewFormsTab && !isEventbrite ? (
          <CustomTab
            label={
              <div
                className={
                  !viewFormsTab
                    ? "EventTabNameRowDisabled"
                    : dashboardValue === 3
                    ? "EventTabNameRowActive"
                    : "EventTabNameRow"
                }
              >
                <span className={"TabName"}>Forms</span>
              </div>
            }
            sx={{ minHeight: 0, cursor: "default" }}
            {...a11yProps(3)}
          />
        ) : (
          <div />
        )}
        {viewPromosTab && !isEventbrite ? (
          <CustomTab
            label={
              <div
                className={
                  !viewPromosTab
                    ? "EventTabNameRowDisabled"
                    : dashboardValue === 4
                    ? "EventTabNameRowActive"
                    : "EventTabNameRow"
                }
              >
                <span className={"TabName"}>Promos</span>
              </div>
            }
            sx={{ minHeight: 0, cursor: "default" }}
            {...a11yProps(4)}
          />
        ) : (
          <div />
        )}
        {viewSettingsTab && !isEventbrite ? (
          <CustomTab
            label={
              <div
                className={
                  !viewSettingsTab
                    ? "EventTabNameRowDisabled"
                    : dashboardValue === 5
                    ? "EventTabNameRowActive"
                    : "EventTabNameRow"
                }
              >
                <span className={"TabName"}>Settings</span>
              </div>
            }
            sx={{ minHeight: 0, cursor: "default" }}
            {...a11yProps(5)}
          />
        ) : (
          <div />
        )}
      </CustomTabs>
    </div>
  );
};
