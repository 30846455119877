import React, { useCallback } from "react";
import { Colors } from "../../../utils/colors";
import { Icon } from "@iconify/react";

// Displays the Not Delivered alert notice below a conversation message to signify the status to the user
const MessageNotDeliveredAlert = () => {
  const styles = {
    notDeliveredContainer: {
      justifyContent: "flex-end",
      gap: 4,
    },

    notDeliveredText: {
      fontSize: 12,
      color: Colors.RED1,
    },
  };

  const showNotDeliveredAlert = useCallback(() => {
    alert(
      "This Text Didn't Send. This can be as a result of carrier network filtering or a another carrier issue."
    );
  }, []);

  return (
    <div
      onClick={showNotDeliveredAlert}
      className="AlignedRowSelect"
      style={styles.notDeliveredContainer}
    >
      <span style={styles.notDeliveredText}>Not Delivered</span>
      <Icon
        icon="ion:alert-circle-outline"
        height={15}
        style={{ color: Colors.RED1 }}
      />
    </div>
  );
};

export default MessageNotDeliveredAlert;
