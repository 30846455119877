import { InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import { useTheme } from "../hooks/useTheme";

type CustomTextFieldProps = {
  inputMode:
    | "search"
    | "text"
    | "email"
    | "tel"
    | "url"
    | "none"
    | "numeric"
    | "decimal"
    | "password";
  placeholder: string;
  value: string;
  onChange: (change: any) => void;
  disabled?: boolean;
  error?: boolean;
  noAutocomplete?: boolean;
  autocompletePassword?: boolean;
  backgroundColor?: string;
  textColor?: string;
  borderRadius?: number;
  rectangleTop?: boolean;
  rectangleBottom?: boolean;
  noBottomBorder?: boolean;
  maxLength?: number;
  startAdornment?: JSX.Element;
  endAdornment?: JSX.Element;
  altMarginBottom?: number;
  altPadding?: number | string;
  altFontSize?: number | string;
  width?: string;
  multiline?: boolean;
  minRows?: number;
  maxRows?: number;
};

const CustomTextField = (props: CustomTextFieldProps) => {
  const {
    inputMode,
    placeholder,
    value,
    onChange,
    disabled,
    error,
    noAutocomplete,
    autocompletePassword,
    backgroundColor,
    textColor,
    borderRadius,
    rectangleTop,
    rectangleBottom,
    noBottomBorder,
    maxLength,
    startAdornment,
    endAdornment,
    altMarginBottom,
    altPadding,
    altFontSize,
    width,
    multiline,
    minRows,
    maxRows,
  } = props;

  const { theme } = useTheme();

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <TextField
      id="custom-text-field"
      type={inputMode === "password" ? inputMode : "string"}
      value={value}
      placeholder={placeholder}
      autoComplete={
        autocompletePassword ? "new-password" : noAutocomplete ? "off" : "on"
      }
      variant="standard"
      error={error}
      onKeyDown={(ev: any) => {
        if (ev.key === "Enter") {
          ev.preventDefault();
        }
      }}
      onChange={(change: any) => onChange(change)}
      onFocus={handleFocus}
      onBlur={handleBlur}
      disabled={disabled}
      multiline={multiline}
      minRows={minRows}
      maxRows={maxRows}
      inputProps={{
        inputMode: inputMode === "password" ? "text" : inputMode,
        pattern: inputMode === "text" ? "[a-z][A-Z]-" : "",
        maxLength: maxLength ?? 1000,
        style: {
          ...theme.PrimaryText,
          fontSize: altFontSize ?? 14,
          padding: altPadding ?? "8px 14px",
        },
      }}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        disableUnderline: true,
        style: {
          color: textColor ?? "#000",
        },
        startAdornment: startAdornment && (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        ),
        endAdornment: endAdornment && (
          <InputAdornment position="end">{endAdornment}</InputAdornment>
        ),
      }}
      sx={{
        backgroundColor: backgroundColor || theme.SecondaryBG.backgroundColor,
        alignSelf: "center",
        width: width ? width : "100%",
        marginBottom: altMarginBottom ?? 0,
        borderTopLeftRadius: rectangleTop ? 0 : borderRadius || 12,
        borderTopRightRadius: rectangleTop ? 0 : borderRadius || 12,
        borderBottomLeftRadius: rectangleBottom ? 0 : borderRadius || 12,
        borderBottomRightRadius: rectangleBottom ? 0 : borderRadius || 12,
        border: "1px solid",
        borderBottomStyle: noBottomBorder && !isFocused ? "none" : "solid",
        borderColor: error
          ? theme.ErrorBorderColor.borderColor
          : isFocused
          ? theme.FocusedTextFieldBorder.borderColor
          : disabled
          ? theme.TextFieldBorder.borderColor
          : theme.EditableTextFieldBorder.borderColor,
        "& .MuiInputBase-input.Mui-disabled": {
          WebkitTextFillColor: theme.DisabledTextFieldText.color,
        },
        "& input:-webkit-autofill": {
          WebkitBoxShadow: `0 0 0 1000px ${
            backgroundColor || theme.SecondaryBG.backgroundColor
          } inset`,
          WebkitTextFillColor: theme.PrimaryText.color,
          borderRadius: borderRadius || 12,
          caretColor: theme.PrimaryText.color,
          WebkitBackgroundClip: "text !important",
        },
      }}
    />
  );
};

export default CustomTextField;
