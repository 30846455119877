import { Colors } from "../../utils/colors";
import OSPills from "../OSPills";
import MarkitLogoWhite from "../../../src/assets/MarkitLogoWhite.png";
import MarkitLogoBlack from "../../../src/assets/MarkitLogoBlack.png";
import { ThemeType } from "@markit/common.types";
import { isDesktop } from "react-device-detect";

type DownloadAppContainerProps = {
  subtext: string;
  lightLogo?: boolean;
  eventTheme?: ThemeType;
};

const DownloadAppContainer = (props: DownloadAppContainerProps) => {
  const { subtext, lightLogo, eventTheme } = props;

  return (
    <div
      className="ColumnNormal"
      style={{ justifyContent: "center", alignItems: "center", gap: 20 }}
    >
      <div
        style={{
          backgroundColor: lightLogo ? Colors.WHITE : Colors.BLACK,
          width: isDesktop ? 109 : 71,
          height: isDesktop ? 109 : 71,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 24,
        }}
      >
        <img
          style={{ height: isDesktop ? 48 : 30, width: "auto" }}
          alt="MarkitAppIcon"
          src={lightLogo ? MarkitLogoBlack : MarkitLogoWhite}
        />
      </div>
      <div className="ColumnNormal" style={{ gap: 7 }}>
        <span style={{ textAlign: "center", fontWeight: "500", fontSize: 14 }}>
          Download the Markit App
        </span>
        <span
          style={{
            textAlign: "center",
            marginInline: 60,
            fontSize: 12,
            color: Colors.GRAY1,
          }}
        >
          {subtext}
        </span>
      </div>
      <OSPills eventTheme={eventTheme} forceDark />
    </div>
  );
};

export default DownloadAppContainer;
