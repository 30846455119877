import { memo } from "react";
import { useTheme } from "../../hooks/useTheme";
import FullEventTimeCard from "./FullEventTimeCard";
import { Event } from "@markit/common.types";
import { Icon } from "@iconify/react";
import { MdLocationOn, MdOpenInNew } from "react-icons/md";
import { Colors } from "../../utils/colors";
import { truncateString } from "@markit/common.utils";
import CustomLinkify from "../Links/CustomLinkify";

type FullEventTimeLocationProps = {
  event: Event;
  isRCABool: boolean;
  numOfApprovedTickets: number;
};

const FullEventTimeLocation = memo(function FullEventTimeLocationFn(
  props: FullEventTimeLocationProps
) {
  const { event, isRCABool, numOfApprovedTickets } = props;

  const { theme } = useTheme();

  return (
    <>
      <div className="AlignedRow" style={{ paddingBottom: "10px" }}>
        <FullEventTimeCard event={event} isRCA={isRCABool} />
      </div>
      <div className="AlignedRow" style={{ gap: "14px", marginBottom: 10 }}>
        <div className="InfoContainerIcon" style={theme.TertiaryBG}>
          {event.isVirtual ? (
            <Icon icon="ion:videocam" height={24} style={theme.PrimaryText} />
          ) : event.privateLocation && numOfApprovedTickets === 0 ? (
            <Icon
              icon="ion:lock-closed"
              height={24}
              style={theme.PrimaryText}
            />
          ) : (
            <MdLocationOn size={24} color={Colors.BLUE5} />
          )}
        </div>
        <div
          onClick={
            event.isVirtual || numOfApprovedTickets === 0
              ? () => {}
              : () => {
                  window.open(
                    "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=" +
                      event.googlePlaceId
                  );
                }
          }
        >
          <p
            className="FullEventHeaderSectionTitle"
            style={{ ...theme.PrimaryText, cursor: "pointer" }}
          >
            {event.isVirtual
              ? "Virtual"
              : event.privateLocation && numOfApprovedTickets === 0
              ? "Hidden Location"
              : truncateString(event.googleDescription, 27, "...")}
          </p>
          <div className="AlignedRow" style={{ gap: "5px", marginTop: 3 }}>
            <p
              className="FullEventHeaderSectionSubtext"
              style={{ ...theme.SubText, cursor: "pointer" }}
            >
              {event.isVirtual
                ? "This is a virtual event"
                : event.privateLocation && numOfApprovedTickets === 0
                ? "Location available if accepted"
                : truncateString(event.formattedAddress, 27, "...")}
            </p>
            {!event.isVirtual ||
              (event.privateLocation && numOfApprovedTickets > 0 && (
                <MdOpenInNew
                  size={15}
                  style={{ ...theme.SubText, cursor: "pointer" }}
                />
              ))}
          </div>
        </div>
      </div>
      {event.locationDetails !== "" && numOfApprovedTickets > 0 ? (
        <>
          <hr style={theme.DividerColor} />
          <div className="ColumnNormal" style={{ marginBottom: 10 }}>
            <CustomLinkify>
              <div style={{ ...theme.SubText, whiteSpace: "pre-wrap" }}>
                {event.locationDetails}
              </div>
            </CustomLinkify>
          </div>
        </>
      ) : null}
    </>
  );
});

export default FullEventTimeLocation;
