import "../../css/Containers/ContainerStyles.css";
import React from "react";
import { ThemeStyle } from "../../hooks/useTheme";

type StandardContainerProps = {
  headerComp: React.ReactNode;
  divider?: boolean;
  valueComp?: React.ReactNode;
  theme: ThemeStyle;
};

const StandardContainer = (props: StandardContainerProps) => {
  const { headerComp, divider, valueComp, theme } = props;

  return (
    <div className="StandardContainer" style={theme.SecondaryBG}>
      <div className="HeaderStandardContainer" style={theme.PrimaryText}>
        {headerComp}
      </div>
      {divider ? <hr style={theme.DividerColor} /> : null}
      <div className="ValueStandardContainer">{valueComp}</div>
    </div>
  );
};

export default StandardContainer;
