import { useDispatch } from "react-redux";
import {
  NavigateOptions,
  useNavigate as useOriginalNavigate,
} from "react-router-dom";
import { urlParamsActions } from "../redux/slices/urlParamSlice";

// Our custom useNavigate hook that clears the urlParamSlice on navigate
export const useNavigate = () => {
  const originalNavigate = useOriginalNavigate();
  const dispatch = useDispatch();

  const navigate = async (pathname: string, params?: NavigateOptions) => {
    dispatch(urlParamsActions.clearUrlState());
    originalNavigate(pathname, params);
  };

  return navigate;
};
