import "../../css/GlobalStyles.css";
import { ReactNode, memo, useRef, useState } from "react";
import TopHeader from "../../components/TopHeader";
import { useSelector } from "react-redux";
import {
  LoginState,
  getAccountState,
  toggleInCreatorMode,
} from "../../redux/slices/accountSlice";
import CreatorModeSidebar from "../../components/CreatorDashboard/CreatorModeSidebar";
import CreatorModeTopHeader from "../../components/CreatorDashboard/CreatorModeTopHeader";
import EmptyViewStateMobile from "../../components/EmptyStates/EmptyViewStateMobile";
import { isDesktop } from "react-device-detect";
import { useNavigate } from "../../hooks/useNavigate";
import { useOnMount } from "../../utils/useOnMount";
import { useDispatch } from "react-redux";

type CreatorModeWrapperProps = {
  children: ReactNode;
};

const CreatorModeWrapper = (props: CreatorModeWrapperProps) => {
  const { children } = props;
  const { account } = useSelector(getAccountState);
  const { accountData, loggedIn } = account;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // States for sidebar
  const refContainer = useRef<HTMLDivElement>(null);
  const [morePanelVisible, setMorePanelVisible] = useState(false);

  useOnMount(() => {
    // Handle Redirecting
    if (loggedIn === LoginState.LOGGED_OUT) {
      navigate("/welcome");
    } else if (!accountData.inCreatorMode) {
      dispatch(toggleInCreatorMode(accountData.uid, accountData.inCreatorMode));
    }
  });

  if (!isDesktop) {
    return <EmptyViewStateMobile />;
  }

  return (
    <div ref={refContainer} style={{ height: "100vh", overflow: "hidden" }}>
      {accountData.inCreatorMode ? <CreatorModeTopHeader /> : <TopHeader />}
      <div
        className="RowNormal"
        style={{ height: "100%", width: "100%", overflowX: "scroll" }}
      >
        {accountData.inCreatorMode ? (
          <CreatorModeSidebar
            morePanelVisible={morePanelVisible}
            setMorePanelVisible={setMorePanelVisible}
          />
        ) : null}
        <div
          style={{
            flex: 1,
            height: "100%",
            width: "100%",
            overflowY: "auto",
            overflowX: "scroll",
            minWidth: "1000px",
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default memo(CreatorModeWrapper);
