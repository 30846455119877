import React from "react";
import CustomCheckbox from "../CustomCheckbox";
import { Colors } from "../../utils/colors";
import { MARKIT_UPLOAD_NOTICE } from "@markit/common.utils";

type ComplianceTermsContactsProps = {
  isChecked: boolean;
  setIsChecked: (isChecked: boolean) => void;
  preText: string;
};

// Compliance checkbox for a user when they first want to upload contacts form an outside source
const ComplianceTermsContacts = (props: ComplianceTermsContactsProps) => {
  const { isChecked, setIsChecked, preText } = props;

  return (
    <div className="AlignedRow" style={{ paddingBlock: 14, gap: 14 }}>
      <CustomCheckbox
        checked={isChecked}
        onChange={() => setIsChecked(!isChecked)}
        sx={{ padding: 0 }}
        altColor={Colors.BLUE5}
      />
      <span className="smallBodySubtext">
        {preText} that you have obtained explicit consent from all the
        individuals whose phone numbers you have collected, are collecting, or
        will collect, and also agree to the following{" "}
        <a
          href={MARKIT_UPLOAD_NOTICE}
          target="_blank"
          rel="noreferrer"
          style={{ textDecorationLine: "underline", color: Colors.BLACK }}
        >
          terms
        </a>
        .
      </span>
    </div>
  );
};

export default ComplianceTermsContacts;
