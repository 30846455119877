import "../../css/App.css";
import "../../css/GlobalStyles.css";
import { memo } from "react";
import { IoIosPin } from "react-icons/io";
import { Event } from "@markit/common.types";
import { MdOpenInNew, MdOutlineVideocam, MdLockOutline } from "react-icons/md";
import { truncateString } from "../../utils/stringUtils";
import { useTheme } from "../../hooks/useTheme";
import { Colors } from "../../utils/colors";
import CustomLinkify from "../Links/CustomLinkify";
import { firebaseMapsApiKey } from "../../firebase";

type FullEventLocationPinProps = {
  event: Event;
  address: string;
};

export const FullEventLocationPin = memo(function FullEventLocationPin(
  props: FullEventLocationPinProps
) {
  const { event, address } = props;

  const { theme } = useTheme();

  return (
    <div
      className="LocationRow"
      onClick={() => {
        if (!event?.privateLocation) {
          window.open(
            "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=" +
              event.googlePlaceId
          );
        }
      }}
    >
      <div className="LocationHeader">
        <div>
          {event?.privateLocation || event?.isVirtual ? (
            <div className="AlignedRow" style={{ gap: "4px" }}>
              <p style={theme.PrimaryText}>
                {event?.privateLocation ? "Location Hidden" : "Virtual"}
              </p>
              {event?.privateLocation ? (
                <MdLockOutline size={16} style={theme.PrimaryText} />
              ) : (
                <MdOutlineVideocam size={19} style={theme.PrimaryText} />
              )}
            </div>
          ) : (
            <div
              style={{ display: "flex", flexDirection: "column", gap: "3px" }}
            >
              <p style={theme.PrimaryText}>{event.googleDescription}</p>
              <div className="AlignedRow" style={{ gap: "5px" }}>
                <p
                  className="FullEventHeaderSectionSubtext"
                  style={{ ...theme.SubText, fontWeight: "400" }}
                >
                  {truncateString(address, 40, "...")}
                </p>
                <MdOpenInNew
                  size={16}
                  color={"#929292"}
                  style={theme.SubText}
                />
              </div>
            </div>
          )}
        </div>
        {event.locationDetails && !event.privateLocation && (
          <CustomLinkify>
            <div
              className="LocationDetails"
              style={{ ...theme.SubText, whiteSpace: "pre-wrap" }}
            >
              {event.locationDetails}
            </div>
          </CustomLinkify>
        )}
        <div className="LocationContainer">
          <img
            className="LocationSquare"
            src={
              // event.theme === ThemeType.Dark
              //   ? "https://maps.googleapis.com/maps/api/staticmap" +
              //     "?center=" +
              //     event.lat +
              //     "," +
              //     event.lng +
              //     +"&zoom=16" +
              //     "&sensor=true" +
              //     "&size=400x200" +
              //     "&key=AIzaSyBflgt4mVewOljVwVZYkOkLcWyUXC9zggA" +
              //     "&style=element:geometry%%7Cinvert_lightness:true" +
              //     "&style=feature:landscape.natural.terrain%%7Celement:geometry%%7Cvisibility:on" +
              //     "&style=feature:landscape%%7Celement:geometry.fill%%7Ccolor:0x303030" +
              //     "&style=feature:poi%%7Celement:geometry.fill%%7Ccolor:0x404040" +
              //     "&style=feature:poi.park%%7Celement:geometry.fill%%7Ccolor:0x0a330a" +
              //     "&style=feature:water%%7Celement:geometry%%7Ccolor:0x00003a" +
              //     "&style=feature:transit%%7Celement:geometry%%7Cvisibility:on%%7Ccolor:0x101010" +
              //     "&style=feature:road%%7Celement:geometry.stroke%%7Cvisibility:on" +
              //     "&style=feature:road.local%%7Celement:geometry.fill%%7Ccolor:0x606060" +
              //     "&style=feature:road.arterial%%7Celement:geometry.fill%%7Ccolor:0x888888"
              // :
              "https://maps.googleapis.com/maps/api/staticmap?center=" +
              event.lat +
              "," +
              event.lng +
              `&zoom=16&size=400x200&key=${firebaseMapsApiKey}`
            }
            alt="map"
            style={{
              filter:
                event.privateLocation || event.isVirtual
                  ? "blur(8px)"
                  : undefined,
              border:
                event.privateLocation || event.isVirtual
                  ? "0.5px solid black"
                  : undefined,
            }}
          />
          {!event.isVirtual && !event.privateLocation && (
            <IoIosPin
              size={40}
              color={Colors.BLACK}
              style={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%,-50%)",
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
});
