import "../../css/ProfilePage/ProfilePage.css";
import { memo, useCallback, useMemo, useState } from "react";
import { AccountData } from "@markit/common.types";
import { isDesktop } from "react-device-detect";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Colors } from "../../utils/colors";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { LoginState, getAccountState } from "../../redux/slices/accountSlice";
import {
  handleLinkPressed,
  hasSubscriptionUnlockedAdvancedData,
} from "@markit/common.utils";
import { onShareLink } from "../../utils/shareLink";
import { SocialMediaType } from "../../hooks/useProfileUpdates";
import ProfilePic from "../ProfilePic";
import BlackHoverButton from "../Buttons/BlackHoverButton";
import ProfilePageEditProfile from "./ProfilePageEditProfile";
import { CopyCustomLinkify } from "../Links/CopyCustomLinkify";
import AlertContainer from "../Containers/AlertContainer";

type ProfilePageHeaderProps = {
  alreadyFollowing: boolean;
  setShowForm: (showForm: boolean) => void;
  otherUserData?: AccountData;
};

const ProfilePageHeader = memo(function ProfilePageHeaderFn({
  alreadyFollowing,
  setShowForm,
  otherUserData,
}: ProfilePageHeaderProps) {
  const { account } = useSelector(getAccountState);
  const { accountData, loggedIn } = account;

  const styles = {
    container: {
      paddingInline: 14,
    },
    headerButton: {
      paddingInline: 18,
      paddingBlock: 12,
      whiteSpace: "nowrap",
    },
  };

  const userData = useMemo(
    () => (otherUserData ? otherUserData : accountData),
    [accountData, otherUserData]
  );
  const [isEditing, setIsEditing] = useState(false);
  const [alertText, setAlertText] = useState("");

  const isMyAccount = useMemo(
    () =>
      loggedIn === LoginState.LOGGED_IN && accountData.uid === userData?.uid,
    [accountData.uid, loggedIn, userData?.uid]
  );

  const onShareProfile = useCallback(async () => {
    if (isDesktop) {
      /* This snippet of code copies the current URL i.e. profile page of
      the user to the clipboard, so people can share it easily. */
      const el = document.createElement("input");
      el.value = window.location.href;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      setAlertText("Profile URL copied to clipboard!");
    } else {
      await onShareLink(
        "Share your profile link!",
        "https://markitai.com/" + userData.username
      );
    }
  }, [userData.username]);

  const renderSocialMedia = useCallback(
    (type: SocialMediaType, link: string, iconName: string) => {
      return (
        <div
          className="ProfileBrowserSocialMediaIcon"
          style={{ marginBottom: -3 }}
          onClick={() => window.open(handleLinkPressed(type, link), "_blank")}
        >
          <Icon icon={iconName} height={18} style={{ color: Colors.GRAY3 }} />
        </div>
      );
    },
    []
  );

  return (
    <div
      className="ColumnNormal"
      style={{ paddingInline: isDesktop ? "22vw" : 14, paddingBlock: 24 }}
    >
      {isEditing ? (
        <ProfilePageEditProfile setIsEditing={setIsEditing} />
      ) : (
        <div className="ColumnNormal" style={{ gap: 14 }}>
          <div
            className="RowNormal"
            style={{
              alignItems: "flex-end",
              justifyContent: "space-between",
            }}
          >
            <ProfilePic
              user={userData}
              size={isDesktop ? 120 : 80}
              borderStyle="1px solid #F4F5F6"
            />
            {!isMyAccount ? (
              <div className="AlignedRow" style={{ gap: 10 }}>
                {!alreadyFollowing ? (
                  <BlackHoverButton
                    title={"Subscribe"}
                    onPress={() => setShowForm(true)}
                    permanentHover
                    containerStyles={styles.headerButton}
                  />
                ) : (
                  <BlackHoverButton
                    title={"Subscribed"}
                    onPress={() => setShowForm(true)}
                    containerStyles={styles.headerButton}
                  />
                )}
              </div>
            ) : (
              <div className="AlignedRow" style={{ gap: 8 }}>
                <BlackHoverButton
                  title="Edit Profile"
                  onPress={() => setIsEditing(true)}
                  containerStyles={styles.headerButton}
                />
                <BlackHoverButton
                  leftIconName="ion:share"
                  leftIconSize={20}
                  onPress={onShareProfile}
                  containerStyles={{ width: 41, height: 41 }}
                />
              </div>
            )}
          </div>
          <span style={{ fontSize: 22, fontWeight: "600" }}>
            {userData.fullName}
          </span>
          <div className="ColumnNormal" style={{ gap: 7 }}>
            <div className="AlignedRow" style={{ gap: 5 }}>
              {userData.instagram !== ""
                ? renderSocialMedia(
                    SocialMediaType.INSTAGRAM,
                    userData.instagram,
                    "mdi:instagram"
                  )
                : null}
              {userData.twitter !== ""
                ? renderSocialMedia(
                    SocialMediaType.TWITTER,
                    userData.twitter,
                    "ri:twitter-x-fill"
                  )
                : null}
              {userData.linkedin !== ""
                ? renderSocialMedia(
                    SocialMediaType.LINKEDIN,
                    userData.linkedin,
                    "ri:linkedin-fill"
                  )
                : null}
              {userData.tiktok !== ""
                ? renderSocialMedia(
                    SocialMediaType.TIKTOK,
                    userData.tiktok,
                    "ic:baseline-tiktok"
                  )
                : null}
              {userData.spotify !== ""
                ? renderSocialMedia(
                    SocialMediaType.SPOTIFY,
                    userData.spotify,
                    "mdi:spotify"
                  )
                : null}
              <div className="AlignedRow" style={{ gap: 3 }}>
                <span style={{ fontSize: 14, color: Colors.GRAY3 }}>
                  @{userData.username}
                </span>
                {hasSubscriptionUnlockedAdvancedData(userData) ? (
                  <VerifiedIcon
                    style={{ color: Colors.BLUE5 }}
                    fontSize={"small"}
                  />
                ) : null}
              </div>
            </div>
            {userData.bio !== "" ? (
              <span
                style={{ fontSize: 14, maxWidth: isDesktop ? "80%" : "100%" }}
              >
                {userData.bio}
              </span>
            ) : null}
            {userData.profileLink !== "" ? (
              <CopyCustomLinkify
                text={userData.profileLink}
                textStyles={{ maxWidth: isDesktop ? "80%" : "100%" }}
              />
            ) : null}
          </div>
        </div>
      )}
      <AlertContainer
        headerComp={alertText}
        closeModal={() => setAlertText("")}
        hideModal={alertText === ""}
      />
    </div>
  );
});

export default ProfilePageHeader;
