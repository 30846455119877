import { useMemo } from "react";
import { MassText } from "@markit/common.types";
import { Colors } from "../utils/colors";

type useSelectRecipientsManagementProps = {
  excludingMode: boolean;
  massText: MassText | undefined;
  selectedPeople: string[];
  excludedPeople: string[];
};

export const useSelectRecipientsManagement = (
  props: useSelectRecipientsManagementProps
) => {
  const { excludingMode, massText, selectedPeople, excludedPeople } = props;

  const iconColor = useMemo(
    () => (excludingMode ? Colors.RED3 : Colors.BLUE5),
    [excludingMode]
  );

  // Shows the selected or excluded people depending on the origin type
  const newRecipientsToShow = useMemo(
    () => (excludingMode ? excludedPeople : selectedPeople),
    [excludedPeople, selectedPeople, excludingMode]
  );

  // Shows the net selected recipients or excluded recipients based on origin type
  const netRecipients = useMemo(
    () =>
      excludingMode
        ? excludedPeople
        : selectedPeople.filter((userId) => !excludedPeople.includes(userId)),
    [excludedPeople, excludingMode, selectedPeople]
  );

  // Shows the selected or excluded categories depending on the origin type
  const categoriesToShow = useMemo(
    () =>
      massText
        ? excludingMode
          ? massText.excludedSubSelectionIds
          : massText.subSelectionIds
        : [],
    [excludingMode, massText]
  );

  // Shows the net selected categories or excluded categories based on origin type
  const netCategoriesToShow = useMemo(
    () =>
      massText
        ? excludingMode
          ? massText.excludedSubSelectionIds
          : massText.subSelectionIds.filter(
              (category) =>
                !massText.excludedSubSelectionIds.some(
                  (excludedCat) => excludedCat.id === category.id
                )
            )
        : [],
    [excludingMode, massText]
  );

  return {
    iconColor,
    newRecipientsToShow,
    netRecipients,
    categoriesToShow,
    netCategoriesToShow,
  };
};
