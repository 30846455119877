import { AnimatePresence, LazyMotion, domAnimation, m } from "framer-motion";
import { Colors } from "../../../utils/colors";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { MenuItemLabel } from "../../../components/CreatorDashboard/CreatorModeSidebar";
import { useCallback, useMemo } from "react";
import { DarkTheme, ThemeStyle } from "../../../hooks/useTheme";
import { useNavigate } from "../../../hooks/useNavigate";
import {
  hasSubscription,
  MARKIT_PRIVACY_POLICY,
  MARKIT_TERMS_OF_USE,
} from "@markit/common.utils";
import { getAccountState } from "../../../redux/slices/accountSlice";

type MorePanelProps = {
  morePanelVisible: boolean;
  setMorePanelVisible: (morePanelVisible: boolean) => void;
  theme?: ThemeStyle;
};

const MorePanel = (props: MorePanelProps) => {
  const { morePanelVisible, setMorePanelVisible, theme } = props;
  const navigate = useNavigate();
  const { accountData } = useSelector(getAccountState).account;
  const isDarkTheme = useMemo(() => theme && theme === DarkTheme, [theme]);

  interface MorePanelItem {
    menuItemLabel: MenuItemLabel;
    title: string;
    iconName: string;
    description: string;
  }
  const morePanelItems: MorePanelItem[] = [
    {
      menuItemLabel: MenuItemLabel.TRANSACTIONS,
      title: "Manage Transactions",
      iconName: "mdi:cash-multiple",
      description: "Manage revenue, disputes, payouts",
    },
    {
      menuItemLabel: MenuItemLabel.BILLING,
      title: "Billing",
      iconName: "ion:bag-outline",
      description: "View and change your billing",
    },
    {
      menuItemLabel: MenuItemLabel.PLANS,
      title: "Plans",
      iconName: "ion:card-outline",
      description: "View available Markit+ plans",
    },
    {
      menuItemLabel: MenuItemLabel.TERMSOFUSE,
      title: "Terms of Use",
      iconName: "ion:document-text-outline",
      description: "",
    },
    {
      menuItemLabel: MenuItemLabel.PRIVACYPOLICY,
      title: "Privacy Policy",
      iconName: "ion:document-lock-outline",
      description: "",
    },
  ];

  const navigateItemOnPress = useCallback(
    (menuItemLabel: MenuItemLabel) => {
      switch (menuItemLabel) {
        case MenuItemLabel.TRANSACTIONS:
          navigate("/home/transactions");
          setMorePanelVisible(false);
          break;
        case MenuItemLabel.BILLING:
          navigate("/home/billing");
          setMorePanelVisible(false);
          break;
        case MenuItemLabel.PLANS:
          navigate(
            "/subscription",
            hasSubscription(accountData)
              ? { state: { changePlan: true } }
              : undefined
          );
          setMorePanelVisible(false);
          break;
        case MenuItemLabel.TERMSOFUSE:
          window.open(MARKIT_TERMS_OF_USE, "_blank");
          break;
        case MenuItemLabel.PRIVACYPOLICY:
          window.open(MARKIT_PRIVACY_POLICY, "_blank");
          break;
      }
    },
    [accountData, navigate, setMorePanelVisible]
  );

  const RenderMorePanelItem = (props: MorePanelItem) => {
    const { menuItemLabel, title, iconName, description } = props;
    return (
      <m.div
        className="AlignedRow"
        style={{
          padding: 14,
          gap: 14,
          borderRadius: 8,
          cursor: "pointer",
        }}
        whileHover={{
          backgroundColor: isDarkTheme ? Colors.GRAY7 : Colors.GRAY6,
        }}
        transition={{ duration: 0.2 }}
        onClick={() => navigateItemOnPress(menuItemLabel)}
      >
        <Icon
          icon={iconName}
          height={24}
          style={{ cursor: "pointer", ...theme?.PrimaryText }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            cursor: "pointer",
          }}
        >
          <span className="AboutSubtitle" style={theme?.PrimaryText}>
            {title}
          </span>
          <span style={{ color: Colors.GRAY1, fontSize: 13 }}>
            {description}
          </span>
        </div>
      </m.div>
    );
  };

  return (
    <AnimatePresence>
      {morePanelVisible && (
        <LazyMotion features={domAnimation}>
          <m.div
            key="morePanel"
            className="MorePanelContainer"
            initial={{ opacity: 0, x: 0 }}
            animate={{ opacity: 1, x: 209 }}
            exit={{ opacity: 0, x: -0 }}
            transition={{ duration: 0.2, type: "tween" }}
            style={{
              backgroundColor: isDarkTheme ? Colors.GRAY10 : Colors.WHITE,
            }}
          >
            <div
              className="AlignedRowSpaced"
              style={{ margin: 14, marginBottom: 28 }}
            >
              <span style={{ color: Colors.GRAY1 }}>More Options</span>
              <Icon
                icon="ion:close-outline"
                height={24}
                style={{ cursor: "pointer", ...theme?.PrimaryText }}
                onClick={() => setMorePanelVisible(false)}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: 14 }}>
              {morePanelItems.map((item) => {
                return (
                  <RenderMorePanelItem
                    menuItemLabel={item.menuItemLabel}
                    title={item.title}
                    iconName={item.iconName}
                    description={item.description}
                  />
                );
              })}
            </div>
            <div
              style={{
                position: "absolute",
                bottom: 14,
              }}
            >
              <span style={{ fontSize: 12, color: Colors.GRAY1 }}>
                © Markit Social Inc.
              </span>
            </div>
          </m.div>
        </LazyMotion>
      )}
    </AnimatePresence>
  );
};

export default MorePanel;
