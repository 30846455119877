import { useDispatch, useSelector } from "react-redux";
import { memo, useMemo, useCallback, useState, useContext } from "react";
import {
  LoginState,
  accountActions,
  getAccountState,
} from "../../../redux/slices/accountSlice";
import {
  createExternalEventLink,
  finalizeEvent,
} from "../../../utils/makeEvent";
import { usePartialEventUpdates } from "../../../hooks/usePartialEventUpdates";
import { getEventData } from "../../../utils/FirebaseUtils";
import { getEventFormQuestions } from "../../../utils/eventUtils/formUtils";
import isEqual from "lodash.isequal";
import {
  createEventData,
  updateEventData,
} from "../../../redux/slices/eventSlice";
import { addToCurrUserWishList } from "../../../redux/slices/wishMarkSlice";
import {
  GetDateWithTime,
  canPublishEvent,
  getTimezone,
  isExternalEventSpecificLink,
  isExternalGenericLink,
  isExternalLinkNoData,
  validateEvent,
} from "@markit/common.utils";
import { difference } from "../../../utils/stringUtils";
import {
  EventType,
  Event,
  SavedFormQuestion,
  MassText,
} from "@markit/common.types";
import { MixpanelContext } from "../../../context/AnalyticsService";
import { Icon } from "@iconify/react";
import { Colors } from "../../../utils/colors";
import { HorizontalDivider } from "../../Dividers/HorizontalDivider";
import FormBuilderWrapper from "../../Form/FormBuilderWrapper";
import { CircularProgress } from "@mui/material";
import CustomCheckbox from "../../CustomCheckbox";
import StandardBorderedContainer from "../../Containers/StandardBorderedContainer";
import { BackButton } from "../../Buttons/BackButton";
import ConfirmDeleteModal from "../../Containers/ConfirmPopups/ConfirmDeleteModal";
import RectangleButton from "../../Buttons/RectangleButton";
import { useLocation } from "react-router-dom";
import AlertContainer from "../../Containers/AlertContainer";
import { EventDashboardExternalLinkCopy } from "../../Links/CopyCustomLinkify";
import { generateDefaultEventTexts } from "../../../utils/textingUtils";
import { DataLoaders } from "../../../redux/slices/dataSlice";
import { useOnMount } from "../../../utils/useOnMount";
import { AnimatePresence, LazyMotion, domAnimation, m } from "framer-motion";
import { useNavigate } from "../../../hooks/useNavigate";
import { StandardSectionContainer } from "../../Containers/StandardSectionContainer";
import { onCreateNavigatePath } from "../../../utils/navigationUtils";
import ConfirmActionModal from "../../Containers/ConfirmPopups/ConfirmActionModal";

type ConfirmExternalLinkProps = {
  event: Event;
  backOnPress: () => void;
};

const ConfirmExternalLink = (props: ConfirmExternalLinkProps) => {
  const { event, backOnPress } = props;
  const { accountData, currentEventFormQuestions, savedQuestions, loggedIn } =
    useSelector(getAccountState).account;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, state, search } = useLocation();
  const { enableCustomData } = state || {};
  const mixpanel = useContext(MixpanelContext);
  const [loading, setLoading] = useState(true);
  const [customDataEnabled, setCustomDataEnabled] = useState(
    enableCustomData ?? false
  );
  const [customDataMinimized, setCustomDataMinimized] = useState(false);
  const [customDataFirstEnabled, setCustomDataFirstEnabled] = useState(
    enableCustomData ?? false
  );
  const [confirming, setConfirming] = useState(false);
  const [convertingEvent, setConvertingEvent] = useState(false);
  const [initialCurrentFormQuestions, setInitialCurrentFormQuestions] =
    useState<SavedFormQuestion[]>([]);
  const [initialEvent, setInitialEvent] = useState<Event>();
  const [backModal, setBackModal] = useState(false);
  const [alertText, setAlertText] = useState({
    header: "",
    subHeader: "",
  });
  const [confirmVisible, setConfirmVisible] = useState(-1); // 0 = convert markit event, 1 = convert event link

  const styles = {
    eventImage: { borderRadius: 14 },
    linkContainer: {
      backgroundColor: Colors.GRAY6,
      padding: 14,
      borderRadius: 12,
      fontSize: 14,
      fontWeight: "500",
      cursor: "pointer",
    },
    title: { fontSize: 17, fontWeight: "600" },
    subHeader: { fontSize: 14, color: Colors.GRAY1 },
    subDescriptionEvent: { fontSize: 14, color: Colors.GRAY2 },
    subDescription: { fontSize: 12, color: Colors.GRAY2 },
    subDescriptionHeader: { fontSize: 14, color: Colors.BLACK },
    standardContainer: {
      backgroundColor: Colors.WHITE,
      border: `1.5px solid ${Colors.GRAY11}`,
      padding: "24px 14px 14px 14px",
      width: 390,
      minHeight: 420,
    },
  };

  const { eventSettings, updateEventSettings } = usePartialEventUpdates({
    event,
    accountData,
  });

  const isEditing = useMemo(() => pathname.includes("editLink"), [pathname]);

  useOnMount(() => {
    (async () => {
      if (currentEventFormQuestions.length > 0) {
        setCustomDataEnabled(true);
        setCustomDataFirstEnabled(true);
      }
      if (isEditing) {
        const originalEventData = await getEventData(event.id);
        if (originalEventData) {
          setInitialEvent(originalEventData);
        }
        const formQuestions = await getEventFormQuestions(
          eventSettings.createdBy,
          eventSettings.formQuestions
        );
        if (formQuestions.length > 0) {
          setCustomDataEnabled(true);
          setCustomDataFirstEnabled(true);
        }
        dispatch(accountActions.updateCurrentEventFormQuestions(formQuestions));
        setInitialCurrentFormQuestions(formQuestions);
      }
      setLoading(false);
    })();
  });

  const essentialQuestion = useMemo(
    () => savedQuestions.find((question) => question.isEssential),
    [savedQuestions]
  );

  const madeChanges = useMemo(
    () =>
      !isEqual(initialEvent, eventSettings) ||
      !isEqual(initialCurrentFormQuestions, currentEventFormQuestions),
    [
      currentEventFormQuestions,
      eventSettings,
      initialCurrentFormQuestions,
      initialEvent,
    ]
  );

  // check if custom data collection is enabled and if there are no questions added
  const validateCustomData = useCallback(() => {
    if (customDataEnabled && currentEventFormQuestions.length === 0) {
      setAlertText({
        header: "Add a Question",
        subHeader: "Add at least one question to enable custom data collection",
      });
      return false;
    }
    return true;
  }, [currentEventFormQuestions.length, customDataEnabled]);

  const validateEventData = useCallback(() => {
    const validateMessage = validateEvent(eventSettings);
    if (validateMessage) {
      setAlertText({
        header: validateMessage,
        subHeader: "",
      });
      return false;
    }
    return true;
  }, [eventSettings]);

  const finishNavigation = useCallback(
    (event: Event) => {
      navigate(`/e/${event.id}/dashboard`, {
        state: { eventId: event.id, fromCreate: !isEditing },
      });
      setConfirming(false);
    },
    [isEditing, navigate]
  );

  const confirmOnPress = useCallback(async () => {
    try {
      // event details validation
      if (
        !validateEventData() ||
        !canPublishEvent(eventSettings) ||
        !validateCustomData()
      ) {
        return;
      }
      setConfirming(true);

      // If the user is not logged in/signed up, store the event locally in redux
      if (loggedIn !== LoginState.LOGGED_IN) {
        dispatch(accountActions.setCurrentEvent(eventSettings));
        navigate("/welcome");
        return;
      }
      let defaultEventTexts: MassText[] = [];
      // Don't generate the default texts if phone collection is not checked
      if (!isExternalLinkNoData(eventSettings.eventType)) {
        defaultEventTexts = generateDefaultEventTexts(
          accountData.uid,
          eventSettings
        );
      }
      const dispatchPromises = [
        dispatch(
          createEventData(
            eventSettings,
            mixpanel,
            defaultEventTexts.length > 0 ? defaultEventTexts : undefined
          )
        ),
        dispatch(addToCurrUserWishList(eventSettings.id, accountData.uid)),
      ];
      await Promise.all(dispatchPromises);
    } catch (error: any) {
      const { response } = error;
      if (response) {
        console.log(response.data);
      }
      alert("Error creating external event link, please try again later");
      return;
    }
    finishNavigation(eventSettings);
  }, [
    accountData.uid,
    dispatch,
    eventSettings,
    finishNavigation,
    loggedIn,
    mixpanel,
    navigate,
    validateCustomData,
    validateEventData,
  ]);

  const saveOnPress = useCallback(async () => {
    if (!validateEventData() || !validateCustomData()) {
      return;
    }
    setConfirming(true);

    try {
      const updatedEvent = finalizeEvent(eventSettings);
      await dispatch(updateEventData(updatedEvent));
      dispatch(addToCurrUserWishList(updatedEvent.id, accountData.uid));

      // get the difference in event object when event was edited, if any.
      const diff = difference(event, updatedEvent);
      mixpanel.identify(accountData.uid);
      mixpanel.track("Event Updated", {
        event_id: updatedEvent.id,
        event_location: updatedEvent.googleDescription,
        differences_in_event: diff,
      });
      mixpanel.people_increment({ Events: 1 });
      setTimeout(() => {
        finishNavigation(updatedEvent);
      }, 1500);
    } catch (error: any) {
      const { response } = error;
      if (response) {
        console.log(response.data);
      }
    }
  }, [
    accountData.uid,
    dispatch,
    event,
    eventSettings,
    finishNavigation,
    mixpanel,
    validateCustomData,
    validateEventData,
  ]);

  const editExternalOnPress = useCallback(() => {
    if (isEditing) {
      dispatch(DataLoaders.event(event.id));
      navigate(`/e/${event.id}/edit`, {
        state: { editEvent: event },
      });
    } else {
      navigate(onCreateNavigatePath(eventSettings.eventType), {
        state: { duplicatedEvent: eventSettings },
      });
    }
  }, [dispatch, event, eventSettings, isEditing, navigate]);

  // Change generic link to event link type
  const convertGenericLink = useCallback(async () => {
    const convertedEvent: Event = {
      ...eventSettings,
      eventType:
        eventSettings.eventType === EventType.GENERIC_LINK
          ? EventType.EXTERNAL_DATA
          : EventType.EXTERNAL_NO_DATA,
    };
    updateEventSettings(convertedEvent);
    const searchParams = new URLSearchParams(search);
    searchParams.set("event", ""); // Set the query parameter
    const newUrl = `${pathname}?${searchParams.toString()}`;
    navigate(newUrl); // Push the new URL to the history
  }, [eventSettings, navigate, pathname, search, updateEventSettings]);

  const convertMarkitEvent = useCallback(async () => {
    if (isEditing) {
      setAlertText({
        header: "Cannot convert to a Markit event.",
        subHeader:
          "We do not allow you to convert an existing external link to a Markit event. Instead, create a new Markit event.",
      });
      return;
    }
    setConvertingEvent(true);
    const { event: externalEventData, dataScraped } =
      await createExternalEventLink(
        eventSettings.externalLink,
        accountData,
        false,
        eventSettings
      );
    if (externalEventData) {
      if (mixpanel) {
        mixpanel.track("Convert External Link To Markit Event", {
          event_id: externalEventData.id,
          external_link: externalEventData.externalLink,
        });
      }
      const newEventSettings: Event = {
        ...(dataScraped ? externalEventData : eventSettings),
        eventType: EventType.MARKIT,
      };
      navigate("/create", {
        state: {
          duplicatedEvent: newEventSettings,
          isConvertingExternalEvent: true,
          externalNotScraped: !dataScraped,
        },
      });
    } else {
      setAlertText({
        header: "There was an error when trying to convert to a Markit Event.",
        subHeader: "",
      });
    }
    setConvertingEvent(false);
  }, [accountData, eventSettings, isEditing, mixpanel, navigate]);

  const selectCustomDataCollection = useCallback(() => {
    // Add essential question on first check enabled
    if (!isEditing && !customDataFirstEnabled && essentialQuestion) {
      dispatch(
        accountActions.updateCurrentEventFormQuestions([essentialQuestion])
      );
      setCustomDataFirstEnabled(true);
    }
    if (customDataEnabled && currentEventFormQuestions.length > 0) {
      dispatch(accountActions.resetCurrentEventFormQuestions());
    }
    setCustomDataEnabled(!customDataEnabled);
  }, [
    currentEventFormQuestions.length,
    customDataEnabled,
    customDataFirstEnabled,
    dispatch,
    essentialQuestion,
    isEditing,
  ]);

  const updateCollectPhoneNumbers = useCallback(() => {
    updateEventSettings({
      eventType: isExternalEventSpecificLink(eventSettings.eventType)
        ? eventSettings.eventType === EventType.EXTERNAL_DATA
          ? EventType.EXTERNAL_NO_DATA
          : EventType.EXTERNAL_DATA
        : eventSettings.eventType === EventType.GENERIC_LINK
        ? EventType.GENERIC_LINK_NO_DATA
        : EventType.GENERIC_LINK,
    });
  }, [eventSettings.eventType, updateEventSettings]);

  const headerBackOnPress = useCallback(() => {
    if (madeChanges) {
      setBackModal(true);
    } else {
      backOnPress();
    }
    dispatch(accountActions.resetCurrentEventFormQuestions());
  }, [backOnPress, dispatch, madeChanges]);

  const confirmCancelOnPress = useCallback(() => {
    navigate(`/e/${eventSettings.id}/dashboard`, {
      state: { eventId: event.id },
    });
  }, [event.id, eventSettings.id, navigate]);

  const cancelOnPress = useCallback(() => {
    if (madeChanges) {
      setBackModal(true);
    } else {
      confirmCancelOnPress();
    }
  }, [confirmCancelOnPress, madeChanges]);

  const renderConvertLoading = useMemo(
    () =>
      convertingEvent ? (
        <div>
          <CircularProgress style={{ color: Colors.GRAY1 }} size={18} />
        </div>
      ) : (
        <Icon
          icon={"ion:chevron-forward"}
          height={24}
          style={{ color: Colors.GRAY1 }}
        />
      ),
    [convertingEvent]
  );

  const renderCheckboxItem = useCallback(
    (
      title: string,
      subtext: string,
      isChecked: boolean,
      onPress: () => void,
      dropdown?: boolean,
      disabled?: boolean
    ) => (
      <div
        className="AlignedRowSpaced"
        style={{ flex: 1, opacity: disabled ? 0.5 : 1 }}
      >
        <div className="AlignedRow">
          <div
            onClick={onPress}
            style={{
              justifyContent: "center",
              height: "100%",
              paddingRight: 14,
              pointerEvents: disabled ? "none" : "all",
            }}
          >
            <CustomCheckbox
              checked={isChecked}
              onChange={onPress}
              sx={{ padding: 0 }}
              altColor={Colors.BLUE5}
            />
          </div>
          <div className="ColumnNormal" style={{ gap: 7, paddingRight: 24 }}>
            <span style={styles.subDescriptionHeader}>{title}</span>
            <span style={styles.subDescription}>{subtext}</span>
          </div>
        </div>
        {dropdown ? (
          <div onClick={() => setCustomDataMinimized(!customDataMinimized)}>
            <Icon
              icon={
                !customDataMinimized ? "ion:chevron-up" : "ion:chevron-down"
              }
              height={20}
              style={{ color: Colors.GRAY1 }}
            />
          </div>
        ) : null}
      </div>
    ),
    [customDataMinimized, styles.subDescription, styles.subDescriptionHeader]
  );

  return (
    <>
      <AnimatePresence>
        <LazyMotion features={domAnimation}>
          <m.div
            key="confirmexternallink"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3, ease: "easeIn" }}
          >
            <StandardBorderedContainer
              containerStyles={{
                ...styles.standardContainer,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="ColumnNormal" style={{ gap: 14 }}>
                {!isEditing ? <BackButton onPress={headerBackOnPress} /> : null}
                <div className="ColumnNormal" style={{ gap: 7 }}>
                  <span style={styles.title}>
                    {isEditing ? "Edit" : "Confirm"} Link Preferences
                  </span>
                  {!isEditing ? (
                    <span style={styles.subHeader}>
                      Make sure your info and preferences are correct.
                    </span>
                  ) : null}
                </div>
              </div>
              <HorizontalDivider altMargin={24} />
              {isEditing && loading ? (
                <CircularProgress
                  style={{ color: Colors.GRAY1, alignSelf: "center" }}
                  size={20}
                />
              ) : (
                <>
                  <div className="ColumnNormal" style={{ gap: 24 }}>
                    {isEditing ? (
                      <div className="ColumnNormal" style={{ gap: 7 }}>
                        <span style={styles.subDescriptionHeader}>
                          External{" "}
                          {isExternalGenericLink(event.eventType)
                            ? ""
                            : "Event"}{" "}
                          Link
                        </span>
                        <EventDashboardExternalLinkCopy
                          copyText={eventSettings.externalLink}
                          onCopyPress={() => {
                            if (mixpanel) {
                              mixpanel.track(
                                "Pressed Event Dashboard External Copy Link",
                                {
                                  event_id: eventSettings.id,
                                }
                              );
                            }
                          }}
                          containerStyles={{
                            display: "flex",
                            padding: 14,
                            backgroundColor: Colors.GRAY6,
                            borderColor: Colors.GRAY1,
                            borderWidth: 0.5,
                          }}
                        />
                      </div>
                    ) : null}
                    <div
                      className="AlignedRowSpacedSelect"
                      onClick={editExternalOnPress}
                      style={{
                        gap: 10,
                        backgroundColor: Colors.GRAY6,
                        borderRadius: 12,
                        padding: 10,
                      }}
                    >
                      <div className="AlignedRowSelect" style={{ gap: 10 }}>
                        <img
                          alt="EventItemMiniPreview"
                          src={
                            eventSettings.photoURL !== ""
                              ? eventSettings.photoURL
                              : require("../../../assets/DefaultEventPic.png")
                          }
                          width={57}
                          height={57}
                          style={styles.eventImage}
                        />
                        <div className="ColumnNormalSelect" style={{ gap: 4 }}>
                          <span
                            className="OneLineText"
                            style={styles.subDescriptionHeader}
                          >
                            {eventSettings.title}
                          </span>
                          <span
                            className="OneLineText"
                            style={styles.subDescriptionEvent}
                          >
                            {GetDateWithTime(eventSettings.start)}{" "}
                            {getTimezone()}
                          </span>
                          {!isExternalGenericLink(eventSettings.eventType) ? (
                            <span
                              className="OneLineText"
                              style={styles.subDescriptionEvent}
                            >
                              {event.isVirtual
                                ? "Virtual Event"
                                : eventSettings.formattedAddress !== ""
                                ? eventSettings.formattedAddress
                                : "No Location Found"}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div>
                        <Icon icon={"feather:edit"} height={18} />
                      </div>
                    </div>
                    {renderCheckboxItem(
                      "Collect Phone Numbers",
                      `Require entering name and phone number before redirecting to ${
                        isExternalGenericLink(eventSettings.eventType)
                          ? ""
                          : "event"
                      } link`,
                      !isExternalLinkNoData(eventSettings.eventType),
                      updateCollectPhoneNumbers
                    )}
                    {renderCheckboxItem(
                      "Custom Data Collection",
                      "Set custom questions to collect more audience data",
                      customDataEnabled,
                      selectCustomDataCollection,
                      customDataEnabled,
                      isExternalLinkNoData(eventSettings.eventType)
                    )}
                    {customDataEnabled && !customDataMinimized ? (
                      <div
                        style={{
                          backgroundColor: Colors.WHITE,
                          pointerEvents: isExternalLinkNoData(
                            eventSettings.eventType
                          )
                            ? "none"
                            : "all",
                          opacity: isExternalLinkNoData(eventSettings.eventType)
                            ? 0.5
                            : 1,
                        }}
                      >
                        <FormBuilderWrapper
                          isProfileQuestions={false}
                          hostId={eventSettings.createdBy}
                          altItemsColor={Colors.GRAY6}
                          altHeader={<></>}
                          altFooter={
                            <div
                              className="AlignedRowSpaced"
                              style={styles.linkContainer}
                            >
                              <span style={{ color: Colors.BLUE5 }}>
                                Add Question
                              </span>
                              <Icon
                                icon={"ion:add-circle-outline"}
                                height={22}
                                color={Colors.BLUE5}
                              />
                            </div>
                          }
                        />
                      </div>
                    ) : null}
                    {isExternalGenericLink(eventSettings.eventType) &&
                    !isEditing ? (
                      <StandardSectionContainer
                        title="Is this an event link?"
                        subtext="Add more details like location and time for event links"
                        icon={renderConvertLoading}
                        onPress={() => setConfirmVisible(1)}
                        containerStyles={{ paddingBlock: 7 }}
                      />
                    ) : !isExternalGenericLink(eventSettings.eventType) ? (
                      <div
                        onClick={() => setConfirmVisible(0)}
                        className="AlignedRowSpacedSelect"
                        style={{ gap: 10 }}
                      >
                        <div className="ColumnNormalSelect" style={{ gap: 4 }}>
                          <span style={styles.subDescriptionHeader}>
                            Convert to Markit Event
                          </span>
                          <span style={styles.subDescription}>
                            Zero redirects when you ticket directly through
                            Markit instead of an external platform
                          </span>
                        </div>
                        {renderConvertLoading}
                      </div>
                    ) : null}
                    <div className="RowNormal" style={{ gap: 10 }}>
                      {isEditing ? (
                        <RectangleButton
                          buttonLabel={"Cancel"}
                          onPress={cancelOnPress}
                          altColor={Colors.GRAY6}
                          altTextColor={Colors.BLACK}
                        />
                      ) : null}
                      <RectangleButton
                        buttonLabel={isEditing ? "Save" : "Confirm"}
                        onPress={isEditing ? saveOnPress : confirmOnPress}
                        loading={confirming}
                        disabled={isEditing && !madeChanges}
                        altColor={Colors.BLACK}
                        altTextColor={Colors.WHITE}
                      />
                    </div>
                  </div>
                </>
              )}
            </StandardBorderedContainer>
          </m.div>
        </LazyMotion>
      </AnimatePresence>
      <AlertContainer
        headerComp={alertText.header}
        subHeaderComp={alertText.subHeader}
        closeModal={() => setAlertText({ header: "", subHeader: "" })}
        hideModal={alertText.header === ""}
      />
      <ConfirmDeleteModal
        heading="If you go back, your changes will be discarded."
        subtext="Continue?"
        deleteButtonText="Discard Changes"
        hideModal={!backModal}
        setIsVisible={setBackModal}
        deleteOnPress={isEditing ? confirmCancelOnPress : backOnPress}
      />
      <ConfirmActionModal
        heading={
          confirmVisible === 0
            ? "Convert to Markit Event?"
            : "Convert to Event Link?"
        }
        subtext={"This cannot be undone."}
        confirmButtonText="Continue"
        hideModal={confirmVisible === -1}
        setIsVisible={() => setConfirmVisible(-1)}
        confirmOnPress={
          confirmVisible === 0 ? convertMarkitEvent : convertGenericLink
        }
      />
    </>
  );
};

export default memo(ConfirmExternalLink);
