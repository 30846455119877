import { AccountData, Event, GroupChat } from "@markit/common.types";
import { API } from "../API";
import filter from "lodash.filter";
import { getUserData } from "./FirebaseUtils";
import { uniqueVals } from "@markit/common.utils";
import { algoliaClient } from "./algoliaUtils";

export const approveRequestGoingToEvent = async (
  eventId: string,
  ticketId: string,
  requester: AccountData
) => {
  await API.rsvp
    .approveRequestGoingToEvent({
      eventId: eventId,
      ticketId: ticketId,
      requester: requester,
    })
    .catch((error) => console.error(error.message));
};

export const denyRequestGoingToEvent = async (
  eventId: string,
  ticketId: string,
  requester: AccountData
) => {
  await API.rsvp
    .denyRequestGoingToEvent({
      eventId: eventId,
      ticketId: ticketId,
      requester: requester,
    })
    .catch((error) => console.error(error.message));
};

export const scanGuestToEvent = async (eventId: string, ticketId: string) => {
  await API.modifyTicket
    .scanTicket({
      eventId: eventId,
      ticketId: ticketId,
    })
    .catch((error) => console.error(error.message));
};

export const unscanGuestToEvent = async (eventId: string, ticketId: string) => {
  await API.modifyTicket
    .unscanTicket({
      eventId: eventId,
      ticketId: ticketId,
    })
    .catch((error) => console.error(error.message));
};

export const updateEventManualSoldOut = async (event: Event) => {
  await API.events
    .updateEventManualSoldOut({ event: event })
    .catch((error) => console.error(error.message));
};

export const groupContains = (
  group: GroupChat,
  query: string,
  followingAccountData: AccountData[]
) => {
  if (
    group === undefined ||
    group.groupName === undefined ||
    group.members === undefined
  )
    return false;
  return (
    group.groupName.toLowerCase().includes(query) ||
    group.members.find((member) => {
      const userFound = followingAccountData.find(
        (user) => user.uid === member
      );
      if (userFound) {
        return (
          userFound.username.toLowerCase().includes(query) ||
          userFound.fullName.toLowerCase().includes(query)
        );
      }
      return member.toLowerCase().includes(query);
    }) !== undefined
  );
};

export const followerContains = (user: AccountData, query: string) => {
  return (
    user.username.toLowerCase().includes(query) ||
    user.fullName.toLowerCase().includes(query)
  );
};

export const resetGroupLists = (
  groups: GroupChat[],
  setGroupsToDisplay: (groupsToDisplay: GroupChat[]) => void
) => {
  setGroupsToDisplay(
    [...groups].sort((x: GroupChat, y: GroupChat) => {
      return (
        new Date(
          y.recentMessage !== undefined ? y.recentMessage.sentAt : y.createdAt
        ).getTime() -
        new Date(
          x.recentMessage !== undefined ? x.recentMessage.sentAt : x.createdAt
        ).getTime()
      );
    })
  );
};

export const handleNonFollowingUserSearch = async (
  text: string,
  uid: string,
  followingSelected: AccountData[],
  allGroups: GroupChat[],
  groupsToDisplay: GroupChat[],
  setGroupsToDisplay: (groupsToDisplay: GroupChat[]) => void,
  setFollowingToDisplay: (followingToDisplay: AccountData[]) => void,
  followingAccountData: AccountData[],
  fetchedFollowingUserData: AccountData[]
) => {
  const formattedQuery = text.toLowerCase();
  const filteredGroupData = filter(groupsToDisplay, (group: GroupChat) => {
    return groupContains(group, formattedQuery, followingAccountData);
  });
  setGroupsToDisplay(filteredGroupData);

  const index = algoliaClient.initIndex("users");
  const results = (await index.search(formattedQuery)).hits;
  const users = await Promise.all(
    results.map(async (obj) => {
      const userData = await getUserData(obj.objectID);
      if (
        userData &&
        (userData.uid === uid ||
          followingSelected.map((sel) => sel.uid).includes(userData.uid))
      ) {
        return;
      }
      return userData;
    })
  );
  const filtered = users.filter(
    (user): user is AccountData => user !== undefined
  );
  const filteredFollowingList = filter(filtered, (user: AccountData) => {
    return followerContains(user, formattedQuery);
  });
  setFollowingToDisplay(uniqueVals(filteredFollowingList, (user) => user.uid));

  if (text === "") {
    resetGroupLists(allGroups, setGroupsToDisplay);
    setFollowingToDisplay(
      fetchedFollowingUserData.filter(
        (user: AccountData) =>
          user.uid !== uid && !followingSelected.includes(user)
      )
    );
  }
};
