import { useMemo } from "react";
import { Colors } from "../../../../utils/colors";
import CustomCheckbox from "../../../CustomCheckbox";
import { Icon } from "@iconify/react";

type RecipientsAddAllItemDropdownProps = {
  mainText: string;
  isAllSelected: boolean;
  selectRecipients: string[];
  showDropdown: boolean;
  subText?: JSX.Element;
  excludingMode: boolean;
  onPress: () => void;
  onCheckPress: () => void;
  isCategories?: boolean;
  disabled?: boolean;
  hideDropdownArrow?: boolean;
};

/**
 * Example of usage: SelectRecipientsModalPeople.tsx
 */
export const RecipientsAddAllItemDropdown = ({
  mainText,
  isAllSelected,
  selectRecipients,
  showDropdown,
  subText,
  onPress,
  onCheckPress,
  excludingMode,
  isCategories,
  disabled,
  hideDropdownArrow,
}: RecipientsAddAllItemDropdownProps) => {
  const iconColor = useMemo(
    () =>
      excludingMode
        ? Colors.RED3
        : isCategories
        ? Colors.PURPLE7
        : Colors.BLUE5,
    [excludingMode, isCategories]
  );

  const isDisabled = useMemo(
    () => (selectRecipients.length === 0 && !isCategories) || disabled,
    [disabled, isCategories, selectRecipients.length]
  );

  return (
    <div
      className="AlignedRowSpacedSelect"
      style={{ paddingBlock: 10, opacity: isDisabled ? 0.5 : 1, gap: 10 }}
    >
      <div
        onClick={hideDropdownArrow ? onCheckPress : onPress}
        className="AlignedRowSpacedSelect"
        style={{ pointerEvents: isDisabled ? "none" : "all", flexGrow: 1 }}
      >
        <div className="ColumnNormal">
          <span style={{ marginRight: 20, fontWeight: 600, fontSize: 14 }}>
            {mainText}
          </span>
          {subText || null}
        </div>
        {!hideDropdownArrow ? (
          <Icon
            icon={showDropdown ? "mdi:chevron-up" : "mdi:chevron-down"}
            height={22}
            color={Colors.GRAY3}
          />
        ) : null}
      </div>
      <CustomCheckbox
        checked={isAllSelected}
        onChange={onCheckPress}
        sx={{ padding: 0 }}
        style={{
          opacity: disabled ? 0.4 : 1,
          pointerEvents: disabled ? "none" : "all",
        }}
        altColor={iconColor}
      />
    </div>
  );
};
