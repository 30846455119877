import "../../../../css/GlobalStyles.css";
import { useCallback, useMemo, useState } from "react";
import { AudienceList } from "@markit/common.types";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../redux/slices/accountSlice";
import { sortAudienceListsDisplay } from "@markit/common.utils";
import EmptyStateButton from "../../../../components/Buttons/EmptyStateButton";
import { Icon } from "@iconify/react";
import { Colors } from "../../../../utils/colors";
import RectangleButtonCreatorPanel from "../../../../components/Buttons/RectangleButtonCreatorPanel";
import MarkitPlusActionButton from "../../../../components/Subscription/MarkitPlusActionButton";
import CreatorModePanelWrapper from "../../../../components/CreatorDashboard/CreatorModePanelWrapper";
import AudienceListPopupPanel from "../../../../components/CreatorDashboard/CreatorProfile/AudienceLists/AudienceListPopupPanel";
import StandardListContainer from "../../../../components/Containers/StandardListContainer";
import SearchBoxContainer from "../../../../components/Containers/SearchBoxContainer";
import GridTableHeader from "../../../../components/Headers/GridTableHeader";
import FlatList from "flatlist-react/lib";
import AudienceListItem from "../../../../components/DisplayItem/AudienceListItem";
import filter from "lodash.filter";
import { EmptyStateFlatlist } from "../../../../components/EmptyStates/EmptyStateFlatlist";

const ListsPanel = () => {
  const { audienceLists, appInitialized } =
    useSelector(getAccountState).account;
  const [searchTerm, setSearchTerm] = useState("");
  const [listPopupVisible, setListPopupVisible] = useState(false);
  const [selectedList, setSelectedList] = useState<AudienceList>();

  const contains = useCallback((list: AudienceList, query: string) => {
    return list.name.toLowerCase().includes(query);
  }, []);

  const handleSearch = (text: string) => {
    setSearchTerm(text.toLowerCase());
  };

  const audienceListsToShow = useMemo(() => {
    if (!appInitialized) {
      return [];
    }
    let userAudienceLists: AudienceList[] =
      sortAudienceListsDisplay(audienceLists);
    if (searchTerm !== "") {
      userAudienceLists = filter(userAudienceLists, (list: AudienceList) => {
        return contains(list, searchTerm);
      });
    }
    return userAudienceLists;
  }, [appInitialized, audienceLists, contains, searchTerm]);

  const newListOnPress = useCallback(() => {
    setListPopupVisible(true);
  }, []);

  const existingListOnPress = useCallback((item: AudienceList) => {
    setSelectedList(item);
    setListPopupVisible(true);
  }, []);

  const renderEmptyView = useMemo(() => {
    return (
      <EmptyStateFlatlist
        searchTerm={searchTerm}
        isLoading={false}
        containerStyles={{ paddingTop: 150 }}
        nonSearchEmptyView={<></>}
      />
    );
  }, [searchTerm]);

  return (
    <CreatorModePanelWrapper
      title="Lists"
      headerRight={
        <div className="AlignedRow" style={{ gap: 14 }}>
          <MarkitPlusActionButton />
          <RectangleButtonCreatorPanel
            title="New"
            iconName="ion:create"
            onPress={newListOnPress}
          />
        </div>
      }
    >
      <div style={{ padding: 30, paddingBottom: 100 }}>
        <StandardListContainer
          searchComp={
            <SearchBoxContainer
              placeholder="Search"
              onChange={(e) => handleSearch(e.target.value)}
              containerStyles={{ marginTop: 0 }}
            />
          }
          gridHeaderComp={
            <GridTableHeader
              flexSections={[4, 1, 1, 2]}
              sectionTitles={["Name", "Visibility", "People", "Description"]}
            />
          }
          listComp={
            <div className="ColumnNormal" style={{ paddingTop: 20 }}>
              <FlatList
                list={audienceListsToShow}
                renderItem={(item: AudienceList) => {
                  return (
                    <AudienceListItem
                      item={item}
                      selectOnPress={() => existingListOnPress(item)}
                    />
                  );
                }}
                renderWhenEmpty={() => renderEmptyView}
              />
              {audienceLists.length === 1 && !searchTerm ? (
                <EmptyStateButton
                  title="Grow with Lists"
                  description="Segment and build your audience with lists."
                  icon={
                    <Icon icon="ion:list" height={64} color={Colors.GRAY1} />
                  }
                  iconBox={84}
                  btnText="New List"
                  onPress={newListOnPress}
                  containerStyles={{ paddingTop: 100 }}
                />
              ) : null}
            </div>
          }
          isLoading={!appInitialized}
        />
        {listPopupVisible ? (
          <AudienceListPopupPanel
            isVisible={listPopupVisible}
            setIsVisible={setListPopupVisible}
            existingList={selectedList}
            setExistingList={setSelectedList}
          />
        ) : null}
      </div>
    </CreatorModePanelWrapper>
  );
};

export default ListsPanel;
