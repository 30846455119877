import BlackHoverButton from "./BlackHoverButton";

type RectangleButtonCreatorPanelProps = {
  title: string;
  iconName: string;
  onPress: () => void;
  disabled?: boolean;
  loading?: boolean;
  addHover?: boolean;
};

// This button is used for the header of the creator mode panels such as EventsPanel, etc
const RectangleButtonCreatorPanel = (
  props: RectangleButtonCreatorPanelProps
) => {
  const { title, iconName, onPress, disabled, loading, addHover } = props;

  const styles = {
    headerRightButton: {
      paddingInline: 20,
      paddingBlock: 14,
      borderRadius: 100,
      whiteSpace: "nowrap",
    },
  };

  return (
    <div>
      <BlackHoverButton
        title={title}
        leftIconName={iconName}
        leftIconSize={16}
        permanentHover={!addHover}
        onPress={onPress}
        disabled={disabled}
        iconLoading={loading}
        containerStyles={styles.headerRightButton}
      />
    </div>
  );
};

export default RectangleButtonCreatorPanel;
