import {
  AccountData,
  Event,
  FormAnswersV2,
  FormInputType,
  FormResponseV2,
  SavedFormQuestion,
  SavedFormResponse,
} from "@markit/common.types";
import {
  getFormResponsesRef,
  getFormResponsesSnap,
  getUserData,
  getUserSavedFormQuestionData,
  getUserSavedFormResponsesRef,
} from "../FirebaseUtils";
import { getCountFromServer, getDocs, query, where } from "../../firebase";
import { filterUndefinedValues } from "@markit/common.utils";
import { API_BASE } from "../../API";
import { ErrorCode } from "@markit/common.api";

// Check if the form question inputType is one with options vs. just a single option (ie. placeholder)
export const formQuestionHasOptions = (inputType: FormInputType) => {
  return (
    inputType === FormInputType.RADIO ||
    inputType === FormInputType.CHECK_BOX ||
    inputType === FormInputType.PHONE
  );
};

// for getting responses to all questions for an event
export const getEventFormResponses = async (
  eventId: string
): Promise<FormResponseV2[]> => {
  const formResponseSnap = await getFormResponsesSnap(eventId);

  const formResponses = await Promise.all(
    formResponseSnap.docs.map((snapshot) => {
      const formResponseData = snapshot.data();
      return formResponseData;
    })
  );

  return formResponses;
};

export const getFormResponsesByUser = async (
  eventId: string,
  userId: string
): Promise<FormResponseV2 | undefined> => {
  const formResponsesRef = getFormResponsesRef(eventId);
  const query_ = query(formResponsesRef, where("uid", "==", userId));
  const querySnapshot = await getDocs(query_);
  if (querySnapshot.docs.length === 0) {
    console.log("no form responses found for user: ", userId);
    return undefined;
  }
  const formResponse = querySnapshot.docs.map((doc) => doc.data())[0];
  return formResponse;
};

// gets a specific answer from all user form responses for an event
export const getFormResponseAnswers = async (
  eventId: string,
  questionId: string
): Promise<FormAnswersV2[]> => {
  const userFormResponses = getFormResponsesRef(eventId);
  const query_ = query(
    userFormResponses,
    where(`answers.${questionId}`, "!=", "")
  );
  const querySnapshot = await getDocs(query_);

  if (querySnapshot.docs.length === 0) {
    console.log("no form responses found for question: ", questionId);
    return [];
  }
  const formAnswers = querySnapshot.docs.map((doc) => doc.data().answers);
  return formAnswers;
};

export const questionInputName = (inputType: FormInputType) =>
  inputType === FormInputType.RADIO
    ? "Multiple Choice"
    : inputType === FormInputType.CHECK_BOX
    ? "Checkbox"
    : inputType === FormInputType.TEXT_INPUT
    ? "Short Answer"
    : inputType === FormInputType.EMAIL
    ? "Collect Emails"
    : "Collect Phone #'s";

// for getting the number of responses for a specific question for an event
// Need to subtract the requested tickets (still pending) (TODO)
export const getNumFormQuestionResponses = async (
  eventId: string,
  questionId: string,
  inputType: FormInputType
): Promise<number> => {
  const userFormResponses = getFormResponsesRef(eventId);
  const query_ = query(
    userFormResponses,
    where(
      `answers.${questionId}`,
      "!=",
      inputType === FormInputType.EMAIL ||
        inputType === FormInputType.TEXT_INPUT
        ? ""
        : []
    )
  );

  // const ticketsRef = FirebaseUtils.getTicketsRef(eventId);
  // const requestedTicketsQuery = query(
  //   ticketsRef,
  //   where('requestStatus', '==', RequestStatus.PENDING)
  // );
  // const requestedSnapshot = await getCountFromServer(requestedTicketsQuery);
  // const requestedCount = requestedSnapshot.data().count;

  const snapshot = await getCountFromServer(query_);
  return snapshot.data().count;
};

// Gets the form questions that are related to the specified event
export const getEventFormQuestions = async (
  userId: string,
  questionIds: string[]
): Promise<SavedFormQuestion[]> => {
  const formQuestions = await Promise.all(
    questionIds.map(async (questionId) => {
      const savedFormQuestionData = await getUserSavedFormQuestionData(
        userId,
        questionId
      );
      if (savedFormQuestionData) {
        return savedFormQuestionData;
      }
    })
  );
  const foundFormQuestions = filterUndefinedValues(formQuestions);
  return foundFormQuestions;
};

// Gets the form responses of a specified user that is related to an event
export const getUsersFormResponses = async (
  hostId: string,
  formQuestionIds: string[],
  userId: string
): Promise<SavedFormResponse[]> => {
  const formResponsesRef = getUserSavedFormResponsesRef(hostId, userId);
  const formResponses = await Promise.all(
    formQuestionIds.map(async (questionId) => {
      const query_ = query(
        formResponsesRef,
        where("questionId", "==", questionId)
      );
      const snapshot = await getDocs(query_);
      const formResponse = snapshot.docs.map((doc) => doc.data())[0];
      return formResponse;
    })
  );
  const definedFormResponses: SavedFormResponse[] =
    filterUndefinedValues(formResponses);
  return definedFormResponses;
};

// Gets the form questions that are related to the specified profile
export const getProfileFormQuestions = async (
  userId: string
): Promise<SavedFormQuestion[]> => {
  const userData = await getUserData(userId);
  if (userData && userData.formQuestions.length > 0) {
    const profileFormQuestions = await Promise.all(
      userData.formQuestions.map(
        async (question) => await getUserSavedFormQuestionData(userId, question)
      )
    );
    const profileFormQuestionsDefined: SavedFormQuestion[] =
      filterUndefinedValues(profileFormQuestions);
    return profileFormQuestionsDefined;
  }

  return [];
};

export const validateEventForm = (
  answers: FormAnswersV2,
  eventFormQuestions: SavedFormQuestion[]
) => {
  const errorIds = eventFormQuestions
    .filter((question) => {
      const answer = answers[question.id];
      if (answer !== undefined && answer.length !== 0) {
        switch (question.formInput.inputType) {
          case FormInputType.TEXT_INPUT: {
            return false;
          }
          case FormInputType.RADIO:
            return false;
          case FormInputType.CHECK_BOX: {
            return false;
          }
          case FormInputType.EMAIL: {
            return !answer[0].includes("@");
          }
          case FormInputType.PHONE: {
            return false;
          }
          default: {
            return true;
          }
        }
      } else {
        return question.formInput.isRequired;
      }
    })
    .map((question) => question.id);

  return errorIds;
};

export const exportEventData = async (
  event: Event,
  isDownloading: boolean,
  setDownloading: (downloading: boolean) => void,
  onFinish?: (success: boolean) => void
) => {
  if (isDownloading) {
    return;
  }
  if (event) {
    setDownloading(true);

    let downloadUrl: string = "";
    downloadUrl = `${API_BASE}events/${event.id}/form-response?csv=true`;

    try {
      const response = await fetch(downloadUrl);

      if (response.status === ErrorCode.OK) {
        const blob = await response.blob();
        const anchor = document.createElement("a");
        anchor.href = window.URL.createObjectURL(blob);
        anchor.download = `form-response.csv`;
        anchor.click();
      } else {
        throw Error;
      }
      if (onFinish) {
        onFinish(true);
      }
    } catch (error) {
      console.log("Error downloading file:", error);
      if (onFinish) {
        onFinish(false);
      }
    }
  }
  setDownloading(false);
};

export const exportIndividualFollowerData = async (
  uid: string,
  follower: AccountData,
  onFinish?: (success: boolean) => void
) => {
  try {
    let downloadUrl: string = "";
    downloadUrl = `${API_BASE}user/${uid}/${follower.uid}/exportIndividualFollowerData`;
    const response = await fetch(downloadUrl, {
      method: "POST",
    });

    // replace spaces with "-"
    const followerNameWithoutSpaces = follower.fullName.replace(/\s+/g, "-");

    if (response.status === ErrorCode.OK) {
      const blob = await response.blob();
      const anchor = document.createElement("a");
      anchor.href = window.URL.createObjectURL(blob);
      anchor.download = `${followerNameWithoutSpaces}-form-response.csv`;
      anchor.click();
    } else {
      throw Error;
    }
    if (onFinish) {
      onFinish(true);
    }
  } catch (error) {
    console.log("Error downloading file:", error);
    if (onFinish) {
      onFinish(false);
    }
  }
};

export const exportAllFollowerData = async (
  uid: string,
  onExportFinish?: (success: boolean) => void
) => {
  try {
    let downloadUrl: string = "";
    downloadUrl = `${API_BASE}user/${uid}/exportAllFollowerData`;

    const response = await fetch(downloadUrl, {
      method: "POST",
    });

    if (response.status === ErrorCode.OK) {
      const blob = await response.blob();
      const anchor = document.createElement("a");
      anchor.href = window.URL.createObjectURL(blob);
      anchor.download = `all-markit-follower-data.csv`;
      anchor.click();
      if (onExportFinish) {
        onExportFinish(true);
      }
    } else {
      throw Error;
    }
  } catch (error) {
    console.log("Error downloading file:", error);
    if (onExportFinish) {
      onExportFinish(false);
    }
  }
};
