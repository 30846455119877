import { useState } from "react";
import { Event } from "@markit/common.types";
import { getEventRef } from "../utils/FirebaseUtils";
import { useOnMount } from "../utils/useOnMount";
import { onSnapshot } from "../firebase";

type useLiveUpdatingEventProps = {
  onDeletion?: () => void;
  initialEventId: string;
};

export const useLiveUpdatingEvent = (props: useLiveUpdatingEventProps) => {
  const { onDeletion, initialEventId } = props;
  const [liveUpdatingEvent, setLiveUpdatingEvent] = useState<Event>();
  const [isError, setIsError] = useState(false);

  useOnMount(() => {
    if (initialEventId) {
      onSnapshot(getEventRef(initialEventId), (doc) => {
        const eventData = doc.data();
        if (!eventData) {
          setIsError(true);
          onDeletion && onDeletion();
        } else {
          setIsError(false);
          setLiveUpdatingEvent(eventData);
        }
      });
    }
  });

  return { liveEvent: liveUpdatingEvent, isLiveEventError: isError };
};
