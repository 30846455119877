import "../../css/Buttons/RectangleButtonStyles.css";
import React from "react";
import { ThemeStyle } from "../../hooks/useTheme";

type RectangleButtonIconProps = {
  buttonLabel: React.ReactNode;
  onPress: () => void;
  icon: JSX.Element;
  theme: ThemeStyle;
  disabled?: boolean;
};

const RectangleButtonIcon = (props: RectangleButtonIconProps) => {
  const { buttonLabel, onPress, icon, theme, disabled } = props;

  return (
    <div
      onClick={onPress}
      className="RectangleButton"
      style={{
        ...theme.TertiaryBG,
        cursor: disabled ? "auto" : "pointer",
        pointerEvents: disabled ? "none" : "all",
        paddingBlock: 10,
      }}
    >
      <p
        style={{
          ...theme.PrimaryText,
          fontWeight: "600",
          fontSize: 15,
          cursor: "pointer",
        }}
      >
        {buttonLabel}
      </p>
      {icon}
    </div>
  );
};

export default RectangleButtonIcon;
