import { useCallback, useMemo, useState } from "react";
import {
  getDocs,
  onSnapshot,
  limit,
  orderBy,
  query,
  where,
  startAfter,
} from "../firebase";
import { uniqueVals } from "@markit/common.utils";
import { AccountData, Follower } from "@markit/common.types";
import { getUserAudienceListMembersRef } from "../utils/FirebaseUtils";
import { loadAlgoliaUsersSearchResults } from "../utils/algoliaUtils";
import { accountActions, getAccountState } from "../redux/slices/accountSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchSingleFollower, fetchSingleUser } from "../utils/FetchSingleData";
import useOnUnmount from "./useOnUnmount";

type useLoadAudienceListMembersProps = {
  userId: string;
  audienceListId: string;
  followerStatus: string;
  windowSize: number;
};
export const useLoadAudienceListMembers = (
  props: useLoadAudienceListMembersProps
) => {
  const { userId, audienceListId, followerStatus, windowSize } = props;

  const { followingAccountData, followersData } =
    useSelector(getAccountState).account;
  const dispatch = useDispatch();
  const [fetchedMemberUserData, setFetchedMemberUserData] = useState<
    AccountData[]
  >([]);
  const [fetchedMemberFollowerData, setFetchedMemberFollowerData] = useState<
    Follower[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [timeoutEvent, setTimeoutEvent] = useState<
    NodeJS.Timeout | undefined
  >();

  const [lastVisible, setLastVisible] = useState<string>(
    new Date().toISOString()
  );
  const [loadedAllMessages, setLoadedAllMessages] = useState(false);

  useOnUnmount(
    (latestDeps) => {
      dispatch(
        accountActions.addMultipleFollowingAccountData(
          latestDeps[0] as AccountData[]
        )
      );
      dispatch(
        accountActions.addMultipleFollowerData(latestDeps[1] as Follower[])
      );
    },
    [fetchedMemberUserData, fetchedMemberFollowerData]
  );

  const addUserData = useCallback((newUserData: AccountData[]) => {
    setFetchedMemberUserData((fetchedUserData) =>
      uniqueVals(
        fetchedUserData.concat(newUserData),
        (userData) => userData.uid
      )
    );
  }, []);

  const addFollowerData = useCallback((newFollowerData: Follower[]) => {
    setFetchedMemberFollowerData((fetchedFollowerData) =>
      uniqueVals(
        newFollowerData.concat(fetchedFollowerData),
        (followerData) => followerData.uid
      )
    );
  }, []);

  const checkIfAddUserData = useCallback(
    async (uid: string) => {
      const userData = await fetchSingleUser(uid, followingAccountData);
      if (userData) {
        const followerData = await fetchSingleFollower(
          userId,
          userData.uid,
          followersData
        );
        if (followerData) {
          if (followerData.status === followerStatus) {
            addUserData([userData]);
            addFollowerData([followerData]);
          }
        }
      }
    },
    [
      addFollowerData,
      addUserData,
      followerStatus,
      followersData,
      followingAccountData,
      userId,
    ]
  );

  const isFinished = useMemo(() => loadedAllMessages, [loadedAllMessages]);

  const loadAudienceListUsers = useCallback(async () => {
    if (!isFinished && !isLoading && audienceListId) {
      setIsLoading(true);
      const audienceListMembersRef = getUserAudienceListMembersRef(
        userId,
        audienceListId
      );

      const audienceListMembersQuery = query(
        audienceListMembersRef,
        orderBy("uid", "desc"),
        limit(windowSize)
      );

      const uploadedMembers = (
        await getDocs(audienceListMembersQuery)
      ).docs.map((document) => document.data().uid);

      setLastVisible(uploadedMembers[uploadedMembers.length - 1]);
      if (uploadedMembers.length < windowSize) {
        setLoadedAllMessages(true);
      }

      onSnapshot(audienceListMembersQuery, (snapshot) => {
        snapshot.docChanges().forEach(async (message, index) => {
          if (message.type === "added" || message.type === "modified") {
            const memberUserData = message.doc.data();
            checkIfAddUserData(memberUserData.uid);
          }
        });
      });
      // TODO (Peter): hacky way to wait for data to load before setting isLoading to false.
      // Better solution is to set isLoading to false when we know the data has actually been loaded
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  }, [
    audienceListId,
    checkIfAddUserData,
    isFinished,
    isLoading,
    userId,
    windowSize,
  ]);

  const loadMoreAudienceListUsers = useCallback(async () => {
    if (!isFinished && !isLoading && audienceListId) {
      setIsLoading(true);
      const audienceListMembersRef = getUserAudienceListMembersRef(
        userId,
        audienceListId
      );
      const audienceListMembersQuery = query(
        audienceListMembersRef,
        orderBy("uid", "desc"),
        startAfter(lastVisible),
        limit(windowSize)
      );

      const uploadedMembers = (
        await getDocs(audienceListMembersQuery)
      ).docs.map((document) => document.data().uid);

      setLastVisible(uploadedMembers[uploadedMembers.length - 1]);
      if (uploadedMembers.length < windowSize) {
        setLoadedAllMessages(true);
      }

      onSnapshot(audienceListMembersQuery, (snapshot) => {
        snapshot.docChanges().forEach(async (message, index) => {
          if (message.type === "added" || message.type === "modified") {
            const memberUserData = message.doc.data();
            checkIfAddUserData(memberUserData.uid);
          }
        });
      });
      // TODO (Peter): hacky way to wait for data to load before setting isLoading to false.
      // Better solution is to set isLoading to false when we know the data has actually been loaded
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  }, [
    audienceListId,
    checkIfAddUserData,
    isFinished,
    isLoading,
    lastVisible,
    userId,
    windowSize,
  ]);

  const loadSearchResults = useCallback(
    async (searchTerm: string) => {
      const fetchListMembers = async (userIds: string[]) => {
        setIsLoading(true);
        await Promise.all(
          userIds.map(async (uid) => {
            if (audienceListId) {
              const audienceListMembersRef = getUserAudienceListMembersRef(
                userId,
                audienceListId
              );
              const query_ = query(
                audienceListMembersRef,
                where("uid", "==", uid)
              );

              const unsubscribe = onSnapshot(query_, (snapshot) => {
                snapshot.docChanges().forEach(async (message) => {
                  if (message.type === "added" || message.type === "modified") {
                    const memberUserData = message.doc.data();
                    checkIfAddUserData(memberUserData.uid);
                  }
                });
              });
              return unsubscribe;
            }
          })
        );
        setIsLoading(false);
      };

      await loadAlgoliaUsersSearchResults(
        searchTerm,
        `following:${userId}`,
        (userIds: string[]) => fetchListMembers(userIds)
      );
    },
    [audienceListId, checkIfAddUserData, userId]
  );

  const loadSearchResultsThrottled = useCallback(
    (searchTerm: string) => {
      if (timeoutEvent) {
        clearTimeout(timeoutEvent);
      }
      setTimeoutEvent(
        setTimeout(() => {
          if (searchTerm.length > 1) {
            loadSearchResults(searchTerm);
          }
        }, 500)
      );
    },
    [loadSearchResults, timeoutEvent]
  );

  return {
    isFinished,
    isLoading,
    fetchedMemberUserData,
    loadAudienceListUsers,
    loadMoreAudienceListUsers,
    loadSearchResultsThrottled,
  };
};
