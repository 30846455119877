import {
  AccountData,
  AudienceList,
  FollowerStatus,
} from "@markit/common.types";
import { useLoadAudienceListMembers } from "../../../../../hooks/useLoadAudienceListMembers";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../../redux/slices/accountSlice";
import { useCallback, useEffect, useMemo, useState } from "react";
import { filter } from "lodash";
import { Colors } from "../../../../../utils/colors";
import FlatList from "flatlist-react/lib";
import { CircularProgress } from "@mui/material";
import { MassTextsPersonItem } from "../../Items/MassTextsPersonItem";
import { EmptySearchState } from "../../../../EmptyStates/EmptySearchState";
import SearchBoxContainer from "../../../../Containers/SearchBoxContainer";
import { MassTextsActionItem } from "../../Items/MassTextsActionItem";
import { Icon } from "@iconify/react";
import { SelectRecipientsSharedProps } from "../SelectRecipientsPanel";
import {
  addOrFilterRecipients,
  filterFollowersByStatus,
  textableContactString,
} from "@markit/common.utils";
import { useLiveUpdatingListAnalytics } from "../../../../../hooks/useLiveHooks/useLiveUpdatingListAnalytics";

type SelectRecipientsAudienceListProps = SelectRecipientsSharedProps & {
  selectedList: AudienceList;
};

const SelectRecipientsAudienceList = (
  props: SelectRecipientsAudienceListProps
) => {
  const {
    selectedList,
    unsavedRecipients,
    setUnsavedRecipients,
    excludingMode,
    setProfileSelected,
  } = props;
  const { account } = useSelector(getAccountState);
  const { accountData, followersData } = account;
  const [searchTerm, setSearchTerm] = useState("");
  const [loadingUsers, setLoadingUsers] = useState(true);

  const { liveTotalListContacts, liveListContactUids, loaded } =
    useLiveUpdatingListAnalytics({ listId: selectedList.id });

  const {
    fetchedMemberUserData,
    loadAudienceListUsers,
    loadMoreAudienceListUsers,
    isLoading: isAudienceListMembersLoading,
    isFinished: isAudienceListMembersFinished,
    loadSearchResultsThrottled,
  } = useLoadAudienceListMembers({
    userId: accountData.uid,
    audienceListId: selectedList?.id ?? "",
    followerStatus: FollowerStatus.SUBSCRIBED,
    windowSize: 20,
  });

  const subscribedFollowers = useMemo(
    () =>
      filterFollowersByStatus(
        liveListContactUids,
        followersData,
        FollowerStatus.SUBSCRIBED
      ),
    [followersData, liveListContactUids]
  );

  const iconColor = useMemo(
    () => (excludingMode ? Colors.RED3 : Colors.BLUE5),
    [excludingMode]
  );

  useEffect(() => {
    (async () => {
      if (
        loadingUsers &&
        fetchedMemberUserData.length === 0 &&
        !isAudienceListMembersFinished
      ) {
        loadAudienceListUsers();
        setLoadingUsers(false);
      }
    })();
  }, [
    fetchedMemberUserData.length,
    isAudienceListMembersFinished,
    loadAudienceListUsers,
    loadingUsers,
  ]);

  const containsUser = useCallback((item: AccountData, query: string) => {
    return item.fullName.toLowerCase().includes(query);
  }, []);

  const handleSearch = (text: string) => {
    if (text !== "") {
      loadSearchResultsThrottled(text.toLowerCase());
    }
    setSearchTerm(text);
  };

  const audienceListMembersToShow: AccountData[] = useMemo(() => {
    let searchResults: AccountData[] = fetchedMemberUserData;
    if (searchTerm !== "") {
      searchResults = filter(fetchedMemberUserData, (user: AccountData) => {
        return containsUser(user, searchTerm);
      });
    }
    return searchResults;
  }, [fetchedMemberUserData, searchTerm, containsUser]);

  const isAllSelected = useCallback(
    (totalList: string[], subList: string[]) =>
      subList.length > 0
        ? totalList.every((sel) => subList.includes(sel))
        : false,
    []
  );

  return (
    <div>
      <div
        className="ColumnCenter"
        style={{
          gap: 4,
          backgroundColor: Colors.GRAY6,
          padding: 10,
          borderRadius: 12,
        }}
      >
        <span style={{ fontWeight: 500, fontSize: 14 }}>
          {selectedList.name}
        </span>
        <span style={{ fontSize: 12, color: Colors.GRAY1 }}>
          {`${liveTotalListContacts} Contact${
            liveTotalListContacts !== 1 ? "s" : ""
          }`}
        </span>
        <span style={{ fontSize: 12, color: Colors.GRAY1 }}>
          {selectedList.visibility === 0 ? "Public List" : "Private List"}
        </span>
      </div>
      <SearchBoxContainer
        value={searchTerm}
        placeholder="Search People"
        onChange={(e) => handleSearch(e.target.value)}
        containerStyles={{ marginBottom: 10 }}
      />
      <div
        className="HideScrollbar"
        style={{
          height: "calc(100vh - 280px)",
          overflow: "scroll",
          paddingBottom: 20,
        }}
      >
        {audienceListMembersToShow.length > 0 ? (
          <div>
            <MassTextsActionItem
              title={"Select All"}
              icon={<Icon icon="ion:people" height={32} color={iconColor} />}
              onPress={() =>
                addOrFilterRecipients(
                  unsavedRecipients,
                  setUnsavedRecipients,
                  subscribedFollowers
                )
              }
              subtext={
                !loaded ? "" : textableContactString(subscribedFollowers.length)
              }
              isCheckSelected={isAllSelected(
                subscribedFollowers,
                unsavedRecipients
              )}
              checkColor={iconColor}
            />
            <hr style={{ marginBlock: 10 }} />
          </div>
        ) : null}
        <FlatList
          list={audienceListMembersToShow}
          renderItem={(user) => {
            return (
              <MassTextsPersonItem
                user={user}
                unsavedPeople={unsavedRecipients}
                setUnsavedPeople={setUnsavedRecipients}
                excludingMode={excludingMode}
                setProfileSelected={setProfileSelected}
              />
            );
          }}
          renderWhenEmpty={() =>
            isAudienceListMembersLoading ? (
              <div className="Centering">
                <CircularProgress
                  style={{ color: "#929292", alignSelf: "center" }}
                  size={20}
                />
              </div>
            ) : (
              <EmptySearchState mainText="No people to show" />
            )
          }
          hasMoreItems={!isAudienceListMembersFinished}
          loadMoreItems={() => {
            loadMoreAudienceListUsers();
          }}
          paginationLoadingIndicator={() =>
            isAudienceListMembersLoading && !isAudienceListMembersFinished ? (
              <CircularProgress
                style={{ color: "#929292", alignSelf: "center" }}
                size={20}
              />
            ) : null
          }
        />
      </div>
    </div>
  );
};

export default SelectRecipientsAudienceList;
