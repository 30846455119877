import { useCallback, useMemo, useState } from "react";
import { uniqueVals } from "@markit/common.utils";
import { AccountData, Follower, FollowerStatus } from "@markit/common.types";
import { fetchSingleFollower, fetchSingleUser } from "../utils/FetchSingleData";
import { algoliaClient } from "../utils/algoliaUtils";
import { accountActions, getAccountState } from "../redux/slices/accountSlice";
import { useDispatch, useSelector } from "react-redux";
import useOnUnmount from "./useOnUnmount";

type useLoadUserListProps = {
  userIdList: string[];
  creatorUid?: string; // not currently used, but can use this parameter to filter a userIdList by follower status of a creator
  followerStatus?: FollowerStatus; // not currently used, but can use this parameter to filter a userIdList by follower status of a creator
  windowSize: number;
  callbackFn?: (accountData: AccountData[]) => void;
};
export const useLoadUserList = (props: useLoadUserListProps) => {
  const { userIdList, creatorUid, followerStatus, windowSize, callbackFn } =
    props;

  const { followingAccountData, followersData } =
    useSelector(getAccountState).account;
  const dispatch = useDispatch();
  const [allFetchedUserData, setAllFetchedUserData] = useState<AccountData[]>(
    []
  );
  const [fetchedFollowerData, setFetchedFollowerData] = useState<Follower[]>(
    []
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [timeoutEvent, setTimeoutEvent] = useState<
    NodeJS.Timeout | undefined
  >();

  useOnUnmount(
    (latestDeps) => {
      dispatch(
        accountActions.addMultipleFollowingAccountData(
          latestDeps[0] as AccountData[]
        )
      );
      dispatch(
        accountActions.addMultipleFollowerData(latestDeps[1] as Follower[])
      );
    },
    [allFetchedUserData, fetchedFollowerData]
  );

  const fetchedUserData = useMemo(
    () =>
      allFetchedUserData.filter((userData) =>
        userIdList.includes(userData.uid)
      ),
    [allFetchedUserData, userIdList]
  );

  const addUserData = useCallback(
    (newUserData: AccountData[]) => {
      setAllFetchedUserData((allFetchedUserData) =>
        uniqueVals(
          allFetchedUserData.concat(newUserData),
          (userData) => userData.uid
        )
      );
      callbackFn && callbackFn(newUserData);
    },
    [callbackFn]
  );

  const addFollowerData = useCallback((newFollowerData: Follower[]) => {
    setFetchedFollowerData((allFetchedUserData) =>
      uniqueVals(
        newFollowerData.concat(allFetchedUserData),
        (followerData) => followerData.uid
      )
    );
  }, []);

  const checkIfAddUserData = useCallback(
    async (uid: string) => {
      const userData = await fetchSingleUser(uid, followingAccountData);
      if (userData) {
        if (followerStatus && creatorUid) {
          const followerData = await fetchSingleFollower(
            creatorUid,
            userData.uid,
            followersData
          );
          if (followerData) {
            if (followerData.status === followerStatus) {
              addUserData([userData]);
              addFollowerData([followerData]);
            }
          }
        } else {
          addUserData([userData]);
        }
      }
    },
    [
      followingAccountData,
      followerStatus,
      creatorUid,
      followersData,
      addUserData,
      addFollowerData,
    ]
  );

  const loadAndStoreUsers = useCallback(async () => {
    setIsLoading(true);
    const usersToFetch = userIdList
      .slice(0, allFetchedUserData.length + windowSize)
      .filter(
        (userId) =>
          !allFetchedUserData.some((userData) => userData.uid === userId)
      );
    await Promise.all(usersToFetch.map((userId) => checkIfAddUserData(userId)));
    if (usersToFetch.length < windowSize) {
      setIsFinished(true);
    }

    setIsLoading(false);
  }, [checkIfAddUserData, allFetchedUserData, userIdList, windowSize]);

  const loadMoreUsers = useCallback(async () => {
    if (userIdList.length === 0) return;
    if (!isFinished && !isLoading) {
      loadAndStoreUsers();
    }
  }, [userIdList.length, isFinished, isLoading, loadAndStoreUsers]);

  // When the users displayed need to be manually refetched due to a state change of userIdList
  // Ex: AudienceListPopupPanel when you add/remove new members to the list
  const reloadMoreUsers = useCallback(async () => {
    setIsFinished(false);
    setAllFetchedUserData([]);
  }, []);

  const loadSearchResults = useCallback(
    async (searchTerm: string) => {
      setIsLoading(true);
      if (!isFinished) {
        const index = algoliaClient.initIndex("users");
        const results = (await index.search(searchTerm)).hits;
        const userIds = results
          .map((obj) => obj.objectID)
          .filter(
            (uid) =>
              userIdList.includes(uid) &&
              !allFetchedUserData.some((userData) => userData.uid === uid)
          );

        await Promise.all(userIds.map((userId) => checkIfAddUserData(userId)));
      }
      setIsLoading(false);
    },
    [checkIfAddUserData, allFetchedUserData, isFinished, userIdList]
  );

  const loadSearchResultsThrottled = useCallback(
    (searchTerm: string) => {
      if (timeoutEvent) {
        clearTimeout(timeoutEvent);
      }
      setTimeoutEvent(
        setTimeout(() => {
          loadSearchResults(searchTerm);
        }, 500)
      );
    },
    [loadSearchResults, timeoutEvent]
  );

  return {
    isFinished,
    isLoading,
    fetchedUserData,
    loadMoreUsers,
    reloadMoreUsers,
    loadSearchResultsThrottled,
  };
};
