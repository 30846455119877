import { parseISO } from "date-fns";

const monthNameArray = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const shortMonthNameArray = [
  "Jan.",
  "Feb.",
  "Mar.",
  "Apr.",
  "May",
  "Jun.",
  "Jul.",
  "Aug.",
  "Sept.",
  "Oct.",
  "Nov.",
  "Dec.",
];

const dayNameArray = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const shortDayNameArray = [
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thurs",
  "Fri",
  "Sat",
];

export const getDay = (timestamp: string) => {
  if (!timestamp) return "";
  const date = parseISO(timestamp);
  return date.getDate();
};

export const getMonthName = (timestamp: string, shortMonthName?: boolean) => {
  if (!timestamp) return "";
  const date = parseISO(timestamp);
  return shortMonthName
    ? shortMonthNameArray[date.getMonth()]
    : monthNameArray[date.getMonth()];
};

export const formatDate = (
  date: Date,
  shortDayName?: boolean,
  shortMonthName?: boolean,
  showDay?: boolean,
  showYear?: boolean
) => {
  const dayNum = date.getDay();
  const monthNum = date.getMonth();

  const dayName: string = shortDayName
    ? shortDayNameArray[dayNum]
    : dayNameArray[dayNum];

  const monthName: string = shortMonthName
    ? shortMonthNameArray[monthNum]
    : monthNameArray[monthNum];

  return (
    (showDay === undefined || showDay ? dayName + ", " : "") +
    monthName +
    " " +
    date.getDate() +
    (showYear === undefined || showYear ? ", " + date.getFullYear() : "")
  );
};

export const GetLongDate = (
  timestamp: string,
  shortDayName?: boolean,
  shortMonthName?: boolean,
  showDay?: boolean,
  showYear?: boolean
): string => {
  if (timestamp) {
    const date = parseISO(timestamp);
    return formatDate(date, shortDayName, shortMonthName, showDay, showYear);
  } else {
    return "";
  }
};
