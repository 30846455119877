import {
  MARKIT_TEXTING_POLICIES,
  MARKIT_WEBSITE,
  censoredWordsDetected,
  deliveryScoreGuidelineOne,
  detectedCensored,
  detectedLink,
  detectedLinkShortener,
  detectedSemiCensored,
  guideLineOneDescription,
  guidelineOneBitlyDescription,
  guidelineOneLinkDescription,
  guidelineOneLinkSubDescription,
  guidelineOneMessageCapDescription,
} from "@markit/common.utils";
import { Colors } from "../../utils/colors";
import { Icon } from "@iconify/react";
import { useCallback, useMemo, useState } from "react";
import { LightTheme, ThemeStyle } from "../../hooks/useTheme";
import PopupModalContainer from "../Containers/PopupModalContainer";

type ContentViolationTagModalProps = {
  message: string;
  theme: ThemeStyle;
  isSemiCensored?: boolean;
  alternateTitle?: string;
};

const ContentViolationTagModal = (props: ContentViolationTagModalProps) => {
  const { message, theme, isSemiCensored, alternateTitle } = props;
  const [showModal, setShowModal] = useState(false);

  const displayCensoredSection = useMemo(
    () =>
      isSemiCensored
        ? detectedSemiCensored(message)
        : detectedCensored(message),
    [isSemiCensored, message]
  );

  const alertIcon = useCallback(
    (color: Colors) => (
      <Icon
        icon={"mdi:alert-circle-outline"}
        height={15}
        style={{
          color: color,
        }}
      />
    ),
    []
  );

  const renderCensoredSection = useMemo(
    () => (
      <div className="ColumnNormal" style={{ gap: 7 }}>
        <div className="AlignedRow" style={{ gap: 7 }}>
          <span className="bodyMedium" style={theme.PrimaryText}>
            Content Violation
          </span>
          {alertIcon(Colors.RED1)}
        </div>
        <span className="smallBodySubtext">
          You have included language that is in violation of standard texting
          policies. Flagged words detected:{" "}
          <span style={{ fontWeight: 600, ...theme.PrimaryText }}>
            {censoredWordsDetected(message)}
          </span>
          .
        </span>
        <span className="smallBodySubtext">
          Attempts to get around the rules can still be flagged and blocked by
          phone carriers. We reserve the right to ban any users caught trying to
          game the system.
        </span>
        <span className="smallBodySubtext">
          To learn more about texting policies, visit our website below:
          <br />
          <span
            onClick={() => window.open(MARKIT_TEXTING_POLICIES, "_blank")}
            style={{ color: Colors.BLUE5, cursor: "pointer" }}
          >
            {MARKIT_WEBSITE}textingpolicies
          </span>
        </span>
      </div>
    ),
    [alertIcon, message, theme.PrimaryText]
  );

  const renderLinkSection = useMemo(
    () => (
      <div className="ColumnNormal" style={{ gap: 7 }}>
        <div className="AlignedRow" style={{ gap: 7 }}>
          <span className="bodyMedium" style={theme.PrimaryText}>
            {detectedLinkShortener(message)
              ? "URL Link Shortener Detected"
              : "Non-Markit Link Detected"}
          </span>
          {alertIcon(
            detectedLinkShortener(message) ? Colors.RED1 : Colors.ORANGE1
          )}
        </div>
        <div className="ColumnNormal" style={{ gap: 7 }}>
          <span className="smallBodySubtext">
            {detectedLinkShortener(message)
              ? guidelineOneBitlyDescription
              : guidelineOneLinkDescription}
          </span>
          <span className="smallBodySubtext">
            {guidelineOneLinkSubDescription}
          </span>
        </div>
      </div>
    ),
    [alertIcon, message, theme.PrimaryText]
  );

  return (
    <div>
      <div
        className="AlignedRowSelect"
        style={{ gap: 4 }}
        onClick={() => setShowModal(!showModal)}
      >
        <Icon
          icon="ion:paper-plane"
          height={16}
          color={deliveryScoreGuidelineOne(message, isSemiCensored).color}
        />
        <span
          style={{
            fontSize: 14,
            color: deliveryScoreGuidelineOne(message, isSemiCensored).color,
          }}
        >
          {deliveryScoreGuidelineOne(message, isSemiCensored).title}
        </span>
        <Icon
          icon={"mdi:alert-circle-outline"}
          height={15}
          style={{
            color:
              theme === LightTheme ? theme.PrimaryText.color : Colors.WHITE,
          }}
        />
      </div>
      {showModal ? (
        <PopupModalContainer
          headerComp={
            <div className="ColumnNormal" style={{ gap: 10 }}>
              <Icon icon="ion:paper-plane" height={35} />
              <span style={{ fontSize: 16, fontWeight: 500 }}>
                {alternateTitle ?? "Text Deliverability"}
              </span>
            </div>
          }
          altPadding={0}
          valueComp={
            <div className="ColumnNormal" style={{ gap: 14 }}>
              <div
                className="ColumnNormal"
                style={{ gap: 14, paddingInline: 14, paddingBottom: 7 }}
              >
                <div className="ColumnNormal" style={{ gap: 7 }}>
                  <div className="AlignedRow" style={{ gap: 7 }}>
                    <span className="bodyMedium" style={theme.PrimaryText}>
                      Delivery score
                    </span>
                    <span
                      className="bodyMedium"
                      style={{
                        color: deliveryScoreGuidelineOne(
                          message,
                          isSemiCensored
                        ).color,
                      }}
                    >
                      {deliveryScoreGuidelineOne(message, isSemiCensored).title}
                    </span>
                  </div>
                  <span className="smallBodySubtext">
                    {guideLineOneDescription}
                  </span>
                </div>
                {deliveryScoreGuidelineOne(message).title === "95%" ? (
                  <div className="ColumnNormal" style={{ gap: 7 }}>
                    <div className="AlignedRow" style={{ gap: 7 }}>
                      <span className="bodyMedium" style={theme.PrimaryText}>
                        Exceeded 320 Characters
                      </span>
                      {alertIcon(Colors.BLUE5)}
                    </div>
                    <span className="smallBodySubtext">
                      Exceeding 320 characters may slightly reduce successful
                      send rates.
                    </span>
                  </div>
                ) : null}
                {displayCensoredSection ? renderCensoredSection : null}
                {detectedLinkShortener(message) || detectedLink(message)
                  ? renderLinkSection
                  : null}
                {message.length > 1500 ? (
                  <div className="ColumnNormal" style={{ gap: 7 }}>
                    <div className="AlignedRow" style={{ gap: 7 }}>
                      <span className="bodyMedium" style={theme.PrimaryText}>
                        Exceeded Max Character Count
                      </span>
                      {alertIcon(Colors.RED1)}
                    </div>
                    <span className="smallBodySubtext">
                      {guidelineOneMessageCapDescription}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          }
          noExit
          closeOnOutsidePress
          closeModal={() => setShowModal(false)}
          theme={theme}
        />
      ) : null}
    </div>
  );
};

export default ContentViolationTagModal;
