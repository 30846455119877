import "../../css/GlobalStyles.css";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  fetchAllUserFollowersData,
  getAccountState,
  LoginState,
} from "../../redux/slices/accountSlice";
import { Colors } from "../../utils/colors";
import { useNavigate } from "../../hooks/useNavigate";
import RectangleButtonCreatorPanel from "../../components/Buttons/RectangleButtonCreatorPanel";
import HelpModal from "../../components/Containers/HelpModal";
import RectangleButton from "../../components/Buttons/RectangleButton";
import { Icon } from "@iconify/react";
import ConfirmDeleteModal from "../../components/Containers/ConfirmPopups/ConfirmDeleteModal";
import { IntegrationPhoneContact } from "@markit/common.types";
import MultiStageContainer from "../../components/Containers/MultiStageContainer";
import CreatorModeWrapper from "../../components/CreatorDashboard/CreatorModeWrapper";
import { API } from "../../API";
import {
  hasSubscriptionUnlockedAdvancedData,
  MARKIT_HOTLINE_NUMBER,
} from "@markit/common.utils";
import {
  configureAutoCollectPhoneQuestion,
  EventbriteApiLimitAlert,
} from "../../utils/eventbriteUtils";
import IntegrationConnect from "../../components/Integrations/IntegrationConnect";
import IntegrationSummary from "../../components/Integrations/IntegrationSummary";
import IntegrationConfigure from "../../components/Integrations/IntegrationConfigure";
import LoadingScreen from "../LoadingScreen";
import { useSearchParams } from "react-router-dom";
import AlertContainer from "../../components/Containers/AlertContainer";
import { useDispatch } from "react-redux";
import { NavigationId } from "../../navigation/AppParamList";

export enum EventbriteIntegrationStage {
  CONNECT = "Connect",
  SUMMARY = "Summary",
  CONFIGURE = "Configure",
}

const EventbriteIntegration = () => {
  const { accountData, appInitialized, loggedIn } =
    useSelector(getAccountState).account;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [integrationStage, setIntegrationStage] = useState(
    EventbriteIntegrationStage.CONNECT
  );
  const [loadingConnection, setLoadingConnection] = useState(false);
  const [loadingInitial, setLoadingInitial] = useState(true);
  const [loadingConfirmEdit, setLoadingConfirmEdit] = useState(false);
  const [phoneContacts, setPhoneContacts] = useState<IntegrationPhoneContact[]>(
    []
  );
  const [numEvents, setNumEvents] = useState(0);
  const [autoCollectPhone, setAutoCollectPhone] = useState(true);
  const [alertText, setAlertText] = useState<{
    header: string;
    subHeader: string;
  }>({
    header: "",
    subHeader: "",
  });
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [complianceChecked, setComplianceChecked] = useState(false);

  const isEditingConnection = useMemo(
    () => accountData.eventbriteInfo.syncedOn !== "" && !complianceChecked,
    [accountData.eventbriteInfo.syncedOn, complianceChecked]
  );

  const stageNames = useMemo(
    () => [
      EventbriteIntegrationStage.CONNECT,
      EventbriteIntegrationStage.SUMMARY,
      EventbriteIntegrationStage.CONFIGURE,
    ],
    []
  );

  const disableRightButton = useMemo(
    () =>
      (isEditingConnection &&
        accountData.eventbriteInfo.autoAddPhoneQuestion === autoCollectPhone) ||
      (!complianceChecked &&
        integrationStage === EventbriteIntegrationStage.CONNECT),
    [
      accountData.eventbriteInfo.autoAddPhoneQuestion,
      autoCollectPhone,
      complianceChecked,
      integrationStage,
      isEditingConnection,
    ]
  );

  useEffect(() => {
    (async () => {
      const hasError = searchParams.get("error");
      if (hasError === "true") {
        alert(
          "There is already another Markit account connected to this Eventbrite account. Please try connecting with a different Eventbrite account. You will be redirected."
        );
        navigate(NavigationId.HOME_INTEGRATIONS);
        return;
      }
      if (loggedIn === LoginState.LOGGED_IN && !appInitialized) {
        return;
      }
      // Redirect back to integrations tab if user has synced with eventbrite, has not connected to eventbrite, or does not have advanced subscription
      if (
        !hasSubscriptionUnlockedAdvancedData(accountData) &&
        !accountData.isAdmin
      ) {
        navigate(NavigationId.HOME_INTEGRATIONS);
        return;
      }
      if (isEditingConnection) {
        setAutoCollectPhone(accountData.eventbriteInfo.autoAddPhoneQuestion);
        setIntegrationStage(EventbriteIntegrationStage.CONFIGURE);
      }
      setLoadingInitial(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountData.uid, appInitialized]);

  const continueOnPress = useCallback(async () => {
    if (integrationStage === EventbriteIntegrationStage.CONNECT) {
      setLoadingConnection(true);
      const { phoneContacts, numEvents, success, rateLimits } =
        await API.eventScraper.connectEventbriteData({
          eventbriteInfo: accountData.eventbriteInfo,
          userId: accountData.uid,
          isResync: false,
        });
      if (!success) {
        if (rateLimits) {
          setAlertText({
            header: "Cannot connect to Eventbrite at this time.",
            subHeader: EventbriteApiLimitAlert,
          });
        } else {
          setAlertText({
            header: "An error occurred while connecting to Eventbrite.",
            subHeader: `Please contact our hotline for help at ${MARKIT_HOTLINE_NUMBER}.`,
          });
        }
        setLoadingConnection(false);
        return;
      }
      // TODO (jonathan): return uids from backend in future rather than calling fetchAll again
      dispatch(fetchAllUserFollowersData(accountData.uid, true));

      setPhoneContacts(phoneContacts);
      setNumEvents(numEvents);
      setLoadingConnection(false);
      setIntegrationStage(EventbriteIntegrationStage.SUMMARY);
    } else if (integrationStage === EventbriteIntegrationStage.SUMMARY) {
      setIntegrationStage(EventbriteIntegrationStage.CONFIGURE);
    } else if (integrationStage === EventbriteIntegrationStage.CONFIGURE) {
      setLoadingConfirmEdit(true);
      await dispatch(
        configureAutoCollectPhoneQuestion(
          accountData.eventbriteInfo,
          accountData.uid,
          autoCollectPhone
        )
      );
      navigate(NavigationId.HOME_INTEGRATIONS);
      setLoadingConfirmEdit(false);
    }
  }, [
    accountData.eventbriteInfo,
    accountData.uid,
    autoCollectPhone,
    dispatch,
    integrationStage,
    navigate,
  ]);

  const backOnPress = useCallback(() => {
    if (
      integrationStage === EventbriteIntegrationStage.CONFIGURE &&
      !isEditingConnection
    ) {
      setIntegrationStage(EventbriteIntegrationStage.SUMMARY);
    } else {
      navigate(NavigationId.HOME_INTEGRATIONS);
    }
  }, [integrationStage, isEditingConnection, navigate]);

  if (loadingInitial) {
    return <LoadingScreen isLoadingPage={loadingInitial} />;
  }

  return (
    <CreatorModeWrapper>
      <div style={{ backgroundColor: Colors.WHITE1, paddingBottom: 100 }}>
        <div className="CreatorPanelContainer">
          <div className="AlignedRowSpaced">
            <h2>Connect Eventbrite</h2>
            <RectangleButtonCreatorPanel
              title="Need Help?"
              onPress={() => setShowHelpModal(true)}
              iconName=""
              addHover
            />
          </div>
        </div>
        <div className="CreatorPanelContainer">
          <MultiStageContainer
            currentStageIndex={stageNames.findIndex(
              (stage) => stage === integrationStage
            )}
            stageNames={stageNames}
            backOnPress={backOnPress}
            hideBackButton={
              integrationStage === EventbriteIntegrationStage.SUMMARY
            }
            rightButton={
              <RectangleButton
                buttonLabel={
                  <span style={{ fontSize: 14, fontWeight: 500 }}>
                    {integrationStage === EventbriteIntegrationStage.CONNECT
                      ? "Connect"
                      : integrationStage === EventbriteIntegrationStage.SUMMARY
                      ? "Continue"
                      : "Confirm "}
                  </span>
                }
                disabled={disableRightButton}
                loading={loadingConfirmEdit}
                altColor={Colors.BLACK}
                altTextColor={Colors.WHITE}
                altPaddingVert={14}
                altPaddingHorz={40}
                width={loadingConfirmEdit ? 160 : undefined}
                onPress={continueOnPress}
                iconRight={
                  integrationStage === EventbriteIntegrationStage.SUMMARY ? (
                    <Icon
                      icon="mdi:chevron-right"
                      height={16}
                      color={
                        loadingConnection || disableRightButton
                          ? Colors.GRAY1
                          : Colors.WHITE
                      }
                    />
                  ) : undefined
                }
              />
            }
          >
            {integrationStage === EventbriteIntegrationStage.CONFIGURE ||
            isEditingConnection ? (
              <IntegrationConfigure
                autoCollectPhone={autoCollectPhone}
                setAutoCollectPhone={setAutoCollectPhone}
              />
            ) : integrationStage === EventbriteIntegrationStage.CONNECT ? (
              <IntegrationConnect
                complianceChecked={complianceChecked}
                setComplianceChecked={setComplianceChecked}
                loadingConnection={loadingConnection}
              />
            ) : (
              <IntegrationSummary
                phoneContacts={phoneContacts}
                numEvents={numEvents}
              />
            )}
          </MultiStageContainer>
        </div>
      </div>
      <HelpModal showModal={showHelpModal} setShowModal={setShowHelpModal} />
      <ConfirmDeleteModal
        heading="Are you sure want to exit?"
        subtext="Your changes will be discarded."
        deleteButtonText="Exit"
        hideModal={!showConfirmDelete}
        setIsVisible={setShowConfirmDelete}
        deleteOnPress={() => {}}
        icon={<Icon icon="ion:exit" height={40} />}
      />
      <AlertContainer
        headerComp={alertText.header}
        subHeaderComp={alertText.subHeader}
        closeModal={() => setAlertText({ header: "", subHeader: "" })}
        hideModal={alertText.header === ""}
      />
    </CreatorModeWrapper>
  );
};

export default memo(EventbriteIntegration);
