import algoliasearch from "algoliasearch";

const algoliaClient = algoliasearch(
  "Y7EBZ8WABH", // algolia application id
  "dc85209ec6ba9c8e011d456ffeb46f56" // algolia search api key
);

// Looks into the users index and finds the hits using the specified filterBy, then calls the afterSearch to load the data
const loadAlgoliaUsersSearchResults = async (
  searchTerm: string,
  filterBy: string,
  afterSearch: (userIds: string[]) => void
) => {
  const index = algoliaClient.initIndex("users");
  const userIds = (
    await index.search(searchTerm, { filters: filterBy })
  ).hits.map((obj) => obj.objectID);
  if (userIds.length > 0) {
    afterSearch(userIds);
  }
};

export { algoliaClient, loadAlgoliaUsersSearchResults };
