import "../../css/FullEvent/FullEvent.css";
import "../../css/GlobalStyles.css";
import { memo, useMemo, useCallback } from "react";
import { Event, AccountData } from "@markit/common.types";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { GrInstagram, GrFacebookOption } from "react-icons/gr";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IoLogoTiktok } from "react-icons/io5";
import { isDesktop } from "react-device-detect";
import { InitialsPic } from "../InitialsPic";
import { WhiteLabelSocial } from "../../macros/WhiteLabelUsers";
import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import {
  MdOutlineLocationOn,
  MdOutlineVideocam,
  MdLockOutline,
  MdOpenInNew,
} from "react-icons/md";
import { Colors } from "../../utils/colors";
import Banner, { BannerType } from "../Banner";
import { truncateString } from "../../utils/stringUtils";
import FullEventTimeCard from "./FullEventTimeCard";
import { useTheme } from "../../hooks/useTheme";
import { BsBoxArrowUpRight } from "react-icons/bs";
import RectangleButtonIcon from "../Buttons/RectangleButtonIcon";
import CustomLinkify from "../Links/CustomLinkify";

type FullEventHeaderProps = {
  event: Event;
  host: AccountData | undefined;
  cohostsList: AccountData[];
  confirmationScreen: boolean;
  hideLocationDetails?: boolean;
};

const FullEventHeader = memo(function FullEventHeaderFn(
  props: FullEventHeaderProps
) {
  const { event, host, cohostsList, confirmationScreen, hideLocationDetails } =
    props;
  const { account } = useSelector(getAccountState);
  const { isRCA } = account;

  const { theme } = useTheme();

  const onViewEventClick = useCallback(() => {
    window.open(`https://markitai.com//e/${event.id}`, "_blank", "noreferrer");
  }, [event.id]);

  let hostAndCohosts: AccountData[] = useMemo(
    () => (cohostsList && host ? [host, ...cohostsList] : host ? [host] : []),
    [cohostsList, host]
  );

  const hostsNamesAndLinks = useMemo(() => {
    const elements = hostAndCohosts.map((organizer: any, index: number) => (
      <a
        key={index}
        style={{ ...theme.PrimaryText, fontWeight: "600" }}
        href={`/u/${organizer.username}`}
      >
        {organizer.fullName}
      </a>
    ));

    const joinedElements: React.ReactElement[] = [];

    elements.forEach((element, index) => {
      joinedElements.push(element);
      if (index !== elements.length - 1) {
        joinedElements.push(<span key={`comma-${index}`}>, </span>);
      }
    });

    return joinedElements;
  }, [hostAndCohosts, theme.PrimaryText]);

  return (
    <div
      className={isDesktop ? "PageDesktop" : "Page"}
      style={theme.SecondaryBG}
    >
      <div className="Container">
        <h2
          className={isDesktop ? "TitleDesktop" : "Title"}
          style={theme.PrimaryText}
        >
          {event.title}
        </h2>
        <div className="AlignedRowSpaced">
          <div className="AlignedRow" style={{ alignItems: "flex-start" }}>
            <div className="AlignedRow" style={{ position: "relative" }}>
              {hostAndCohosts.slice(0, 3).map((host, index) => (
                <div
                  style={{
                    position: "absolute",
                    left: index * 7,
                    top: -1,
                  }}
                  key={index}
                >
                  {host?.profilePicURL && host?.profilePicURL !== "" ? (
                    <img
                      className={`IconSquare`}
                      style={theme.PicBorder}
                      src={host?.profilePicURL}
                      alt="host profile pic"
                    />
                  ) : (
                    <div
                      className={`IconSquare InitialsPic`}
                      style={{ fontSize: 10 }}
                    >
                      <InitialsPic fullName={host?.fullName} />
                    </div>
                  )}
                </div>
              ))}
            </div>
            <span
              className="TwoLineText"
              style={{
                ...theme.SubText,
                marginLeft: 20 + hostAndCohosts.slice(0, 3).length * 7,
              }}
            >
              Hosted by {hostsNamesAndLinks}
            </span>
          </div>
          {isRCA ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingLeft: 5,
              }}
            >
              <div
                onClick={() => {
                  window.open(WhiteLabelSocial.RCA_TIKTOK);
                }}
              >
                <IoLogoTiktok
                  size={15}
                  style={{
                    ...theme.PrimaryText,
                    paddingLeft: 10,
                  }}
                />
              </div>
              <div
                onClick={() => {
                  window.open(WhiteLabelSocial.RCA_INSTA);
                }}
              >
                <GrInstagram
                  size={15}
                  style={{
                    ...theme.PrimaryText,
                    paddingLeft: 8,
                  }}
                />
              </div>
              <div
                onClick={() => {
                  window.open(WhiteLabelSocial.RCA_FB);
                }}
              >
                <GrFacebookOption
                  size={15}
                  style={{
                    ...theme.PrimaryText,
                    paddingLeft: 5,
                  }}
                />
              </div>
            </div>
          ) : null}
        </div>

        <div className={isDesktop ? "ColumnNormal" : ""}>
          <div className="InfoContainer">
            <FullEventTimeCard event={event} isRCA={isRCA} />
          </div>
          <div className="InfoContainer">
            <div className="InfoContainerIcon" style={theme.TertiaryBG}>
              {event.privateLocation && !confirmationScreen ? (
                <MdLockOutline size={24} style={theme.PrimaryText} />
              ) : event.isVirtual ? (
                <MdOutlineVideocam size={24} color={Colors.BLUE5} />
              ) : (
                <MdOutlineLocationOn size={24} color={Colors.BLUE5} />
              )}
            </div>
            <div style={{ marginLeft: "14px" }}>
              <div
                style={{ display: "flex", flexDirection: "column", gap: "3px" }}
              >
                {event.isVirtual ||
                (event.privateLocation &&
                  (!confirmationScreen ||
                    (confirmationScreen && hideLocationDetails))) ? (
                  <>
                    <p
                      className="FullEventHeaderSectionTitle"
                      style={{ ...theme.PrimaryText }}
                    >
                      {event.privateLocation
                        ? "Location hidden by host"
                        : "Virtual Location"}
                    </p>
                    <p
                      className="QuestionDescription"
                      style={{ ...theme.SubText, fontSize: "14px" }}
                    >
                      {hideLocationDetails
                        ? "Need confirmed ticket to view location"
                        : event.privateLocation
                        ? "RSVP to view location"
                        : "This is a virtual event"}
                    </p>
                  </>
                ) : (
                  <div
                    onClick={() => {
                      window.open(
                        "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=" +
                          event.googlePlaceId
                      );
                    }}
                  >
                    <p
                      className="FullEventHeaderSectionTitle"
                      style={{ ...theme.PrimaryText, cursor: "pointer" }}
                    >
                      {truncateString(
                        event.googleDescription,
                        isDesktop ? 50 : 27,
                        "..."
                      )}
                    </p>
                    <div className="AlignedRow" style={{ gap: "5px" }}>
                      <p
                        className="FullEventHeaderSectionSubtext"
                        style={{ ...theme.SubText, cursor: "pointer" }}
                      >
                        {truncateString(
                          event.formattedAddress,
                          isDesktop ? 50 : 27,
                          "..."
                        )}
                      </p>
                      <MdOpenInNew
                        size={15}
                        color={"#929292"}
                        style={{ ...theme.SubText, cursor: "pointer" }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {event.locationDetails !== "" &&
          confirmationScreen &&
          !hideLocationDetails ? (
            <>
              <hr />
              <div className="ColumnNormal" style={{ marginBottom: 10 }}>
                <CustomLinkify>
                  <div style={{ ...theme.SubText, whiteSpace: "pre-wrap" }}>
                    {event.locationDetails}
                  </div>
                </CustomLinkify>
              </div>
            </>
          ) : null}
        </div>
        {isDesktop && confirmationScreen ? (
          <div style={{ marginTop: 10 }}>
            <RectangleButtonIcon
              buttonLabel="View Event"
              onPress={onViewEventClick}
              theme={theme}
              icon={
                <BsBoxArrowUpRight size={14} style={{ ...theme.PrimaryText }} />
              }
            />
          </div>
        ) : null}

        {confirmationScreen || !isDesktop ? (
          <div>
            {host && host.customer.phone !== "" ? (
              <Banner
                type={BannerType.TEXTHOST}
                hostPhone={host.customer.phone}
              />
            ) : (
              <Banner type={BannerType.TEXTHOST_FREE} />
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
});

export default FullEventHeader;
