import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import "../../css/GlobalStyles.css";
import { Colors } from "../../utils/colors";
import PopupModalContainer from "../Containers/PopupModalContainer";
import {
  AccountData,
  Event,
  Follower,
  FollowerStatus,
  FormResponseV2,
  TicketV2,
  SavedFormResponse,
  SavedFormQuestion,
  RecentEventAnalytics,
  AudienceList,
} from "@markit/common.types";
import RectangleButton from "../Buttons/RectangleButton";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/store";
import { CircularProgress } from "@mui/material";
import {
  getUserFollowerRef,
  getUserFormResponseData,
} from "../../utils/FirebaseUtils";
import { FollowerProfileHeader } from "./FollowerProfileHeader";
import FullFollowerProfilePopupPanel from "./FullFollowerProfilePopupPanel";
import { MixpanelContext } from "../../context/AnalyticsService";
import { MarkitPlusModal } from "../Subscription/MarkitPlusModal";
import AddToListModal from "../CreatorDashboard/CreatorProfile/AudienceLists/AddToListModal";
import {
  fetchUserFollowerData,
  fetchUserEssentialFormResponse,
  fetchUserEssentialQuestion,
  fetchMostRecentUserAttendedEvents,
  fetchUserJoinedAudienceLists,
} from "../../utils/userUtils";
import FollowerProfileHeaderBar from "./FollowerProfileHeaderBar";
import { onSnapshot } from "firebase/firestore";

interface ProfilePreviewModalProps {
  userData: AccountData;
  event?: Event;
  userTicket?: TicketV2 | undefined;
  messageOnPress: (item: AccountData | undefined) => void;
  setProfileSelected: (userData: AccountData | undefined) => void;
  hideProfilePreview: boolean;
  setHideProfilePreview: (hideProfilePreview: boolean) => void;
  fullProfileOpen: boolean;
  setFullProfileOpen: (fullProfileOpen: boolean) => void;
  setProfileResponseModalOpen?: (profileResponseModalOpen: boolean) => void;
}

const ProfilePreviewModal = (props: ProfilePreviewModalProps) => {
  const {
    userData,
    event,
    userTicket,
    messageOnPress,
    setProfileSelected,
    hideProfilePreview,
    setHideProfilePreview,
    fullProfileOpen,
    setFullProfileOpen,
    setProfileResponseModalOpen,
  } = props;
  const mixpanel = useContext(MixpanelContext);
  const { accountData, savedQuestions } = useSelector(
    (state: AppState) => state.account
  );
  const [dataLoading, setDataLoading] = useState(true);
  const [followerDataloading, setFollowerDataLoading] = useState(true);
  const [markitPlusModalVisible, setMarkitPlusModalVisible] = useState(false);
  const [listModalVisible, setListModalVisible] = useState(false);
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);

  const [followerData, setFollowerData] = useState<Follower>();
  const [followerEventData, setFollowerEventData] = useState<Event>();
  const [currFormResponse, setCurrFormResponse] = useState<FormResponseV2>();

  const [hostEssentialQuestion, setHostEssentialQuestion] = useState<
    SavedFormQuestion | undefined
  >(); // used if a cohost or other event role is viewing profile preview
  const [essentialFormResponse, setEssentialFormResponse] = useState<
    SavedFormResponse | undefined
  >(undefined);
  const [recentEventsData, setRecentEventsData] = useState<
    RecentEventAnalytics[]
  >([]);
  const [joinedAudienceLists, setJoinedAudienceLists] = useState<
    AudienceList[]
  >([]);

  const styles = {
    contentContainer: {
      marginTop: 20,
    },
  };

  const loading = useMemo(
    () => dataLoading || followerDataloading,
    [followerDataloading, dataLoading]
  );

  const creatorUid = useMemo(
    () =>
      event && accountData.uid !== event.createdBy
        ? event.createdBy
        : accountData.uid,
    [accountData.uid, event]
  );

  const notHost = useMemo(
    () => creatorUid !== accountData.uid,
    [accountData.uid, creatorUid]
  );

  // need to use hostEssential question if this is a cohost or other event role viewing the profile preview on an event
  const essentialQuestion = useMemo(
    () =>
      !notHost
        ? savedQuestions.find((question) => question.isEssential)
        : hostEssentialQuestion,
    [hostEssentialQuestion, notHost, savedQuestions]
  );

  useEffect(() => {
    (async () => {
      if (userData) {
        // Get a snapshot of the followerData so it can live update
        const followerRef = getUserFollowerRef(creatorUid, userData.uid);
        const unsubscribe = onSnapshot(followerRef, (snapshot) => {
          setFollowerData(snapshot.data());
          setFollowerDataLoading(false);
        });

        // look for essential question if not host
        let currHostEssentialQuestion: SavedFormQuestion | undefined;
        if (notHost) {
          currHostEssentialQuestion = await fetchUserEssentialQuestion(
            creatorUid
          );
          setHostEssentialQuestion(currHostEssentialQuestion);
        }

        const [
          followersData,
          formResponse,
          essentialResponse,
          recentEvents,
          foundAudienceLists,
        ] = await Promise.all([
          fetchUserFollowerData(creatorUid, userData.uid),
          event
            ? await getUserFormResponseData(event.id, userData.uid)
            : undefined,
          essentialQuestion || currHostEssentialQuestion
            ? fetchUserEssentialFormResponse(
                creatorUid,
                userData.uid,
                essentialQuestion
                  ? essentialQuestion.id
                  : currHostEssentialQuestion
                  ? currHostEssentialQuestion.id
                  : ""
              )
            : undefined,
          fetchMostRecentUserAttendedEvents(creatorUid, userData.uid, 2),
          fetchUserJoinedAudienceLists(creatorUid, userData.uid, 2),
        ]);
        setFollowerData(followersData?.follower);
        setFollowerEventData(followersData?.event);
        setCurrFormResponse(formResponse);
        setEssentialFormResponse(essentialResponse);
        setRecentEventsData(recentEvents);
        setJoinedAudienceLists(foundAudienceLists);
        setDataLoading(false);

        return () => unsubscribe();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const noSubscriptionPlan = useMemo(
    () => accountData.customer.phone === "" || !userData,
    [accountData.customer.phone, userData]
  );

  const navigateFormResponse = useCallback(() => {
    if (setProfileResponseModalOpen) {
      setProfileResponseModalOpen(true);
    }
  }, [setProfileResponseModalOpen]);

  const conversationOnPress = useCallback(() => {
    if (noSubscriptionPlan) {
      mixpanel.track("Pressed Get Markit+ Lead Button", {
        button_location: "message on profile view",
      });

      setMarkitPlusModalVisible(true);
      setHideProfilePreview(true);
    } else {
      setHideProfilePreview(true);
      messageOnPress(userData);
    }
  }, [
    messageOnPress,
    mixpanel,
    noSubscriptionPlan,
    setHideProfilePreview,
    userData,
  ]);

  const fullProfileOnPress = useCallback(() => {
    setFullProfileOpen(true);
    setHideProfilePreview(true);
  }, [setFullProfileOpen, setHideProfilePreview]);

  const goBackToProfilePreview = useCallback(() => {
    setFullProfileOpen(false);
    setListModalVisible(false);
    setHideProfilePreview(false);
  }, [setFullProfileOpen, setHideProfilePreview]);

  const addToListOnPress = useCallback(() => {
    setListModalVisible(true);
    setHideProfilePreview(true);
  }, [setHideProfilePreview]);

  const closeAddToListOnPress = useCallback(() => {
    if (fullProfileOpen) {
      setListModalVisible(false);
    } else {
      goBackToProfilePreview();
    }
  }, [fullProfileOpen, goBackToProfilePreview]);

  const closeModal = useCallback(() => {
    setMarkitPlusModalVisible(false);
    if (hideProfilePreview && !fullProfileOpen) {
      setHideProfilePreview(false);
    }
  }, [fullProfileOpen, hideProfilePreview, setHideProfilePreview]);

  return (
    <>
      {markitPlusModalVisible ? (
        <MarkitPlusModal closeModal={closeModal} />
      ) : null}
      <FullFollowerProfilePopupPanel
        userData={userData}
        followerData={followerData}
        followerEventData={followerEventData}
        essentialFormResponse={essentialFormResponse}
        essentialQuestion={essentialQuestion}
        notHost={notHost}
        fullFollowerProfilePopupPanelVisible={fullProfileOpen}
        goBackToProfilePreview={goBackToProfilePreview}
        conversationOnPress={conversationOnPress}
        addToListOnPress={addToListOnPress}
      />
      {listModalVisible ? (
        <AddToListModal
          isVisible={listModalVisible}
          closeOnPress={closeAddToListOnPress}
          userId={userData.uid}
        />
      ) : null}
      <PopupModalContainer
        customHeader={
          loading ? null : (
            <FollowerProfileHeaderBar
              userData={userData}
              followerData={followerData}
              openDropdown={openDropdown}
              setOpenDropdown={setOpenDropdown}
              fullProfile={false}
            />
          )
        }
        noDivider
        valueComp={
          <div style={styles.contentContainer}>
            {loading ? (
              <div className="Centering" style={{ height: 200 }}>
                <CircularProgress style={{ color: Colors.GRAY1 }} size={24} />
              </div>
            ) : (
              <>
                <FollowerProfileHeader
                  fullProfile={false}
                  creatorUid={creatorUid}
                  userData={userData}
                  followerData={followerData}
                  followerEventData={followerEventData}
                  essentialFormResponse={essentialFormResponse}
                  recentEventsData={recentEventsData}
                  joinedAudienceLists={joinedAudienceLists}
                  essentialQuestion={essentialQuestion}
                  notHost={notHost}
                  fullProfileOnPress={fullProfileOnPress}
                  addToListOnPress={addToListOnPress}
                  conversationOnPress={() => {
                    conversationOnPress();
                  }}
                  userTicket={userTicket}
                />
                <div className="AlignedRow" style={{ gap: 10 }}>
                  {event ? (
                    <button
                      onClick={navigateFormResponse}
                      disabled={!currFormResponse}
                      className="RectangleButton"
                      style={{
                        opacity: !currFormResponse ? 0.4 : 1,
                        paddingBlock: 10,
                        backgroundColor: notHost ? Colors.BLACK : Colors.GRAY6,
                        cursor: !currFormResponse ? "auto" : "pointer",
                      }}
                    >
                      <p
                        style={{
                          color: notHost ? Colors.WHITE : Colors.BLACK,
                          fontWeight: "600",
                          fontSize: 14,
                          cursor: !currFormResponse ? "auto" : "pointer",
                        }}
                      >
                        {currFormResponse === undefined
                          ? "No Form Responses"
                          : currFormResponse === null
                          ? "Loading..."
                          : "Form Responses"}
                      </p>
                    </button>
                  ) : null}
                </div>
              </>
            )}
          </div>
        }
        fixedBottomContent={
          !notHost && !loading ? (
            <div style={{ marginInline: 14 }}>
              <RectangleButton
                buttonLabel="Chat"
                onPress={conversationOnPress}
                altColor={Colors.BLACK}
                altTextColor={Colors.WHITE}
                disabled={
                  !followerData ||
                  followerData.status !== FollowerStatus.SUBSCRIBED
                }
              />
            </div>
          ) : null
        }
        noExit
        closeOnOutsidePress={!openDropdown}
        closeModal={() => {
          setProfileSelected(undefined);
          setCurrFormResponse(undefined);
        }}
        hideModal={hideProfilePreview}
      />
    </>
  );
};

export default ProfilePreviewModal;
