import { useCallback, useState } from "react";
import { Tooltip } from "@mui/material";
import { Icon } from "@iconify/react";
import { useDispatch } from "react-redux";
import { AccountData } from "@markit/common.types";
import { formatPhoneNumber } from "../../utils/FormatPhoneNumber";
import { Colors } from "../../utils/colors";
import ProfileItem from "../ProfileItem";
import { DataLoaders } from "../../redux/slices/dataSlice";
import { useNavigate } from "../../hooks/useNavigate";
import { isDesktop } from "react-device-detect";

const FeaturedPeopleCard = (props: { person: AccountData }) => {
  const { person } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [onHover, setOnHover] = useState(false);
  const [onIconHover, setOnIconHover] = useState(false);

  const styles = {
    subtext: {
      fontSize: 14,
      color: Colors.GRAY1,
    },

    card: {
      width: "95%",
      padding: 14,
      borderRadius: 16,
      backgroundColor: onHover ? Colors.GRAY6 : Colors.WHITE1,
      transition: "background-color 0.3s ease",
    },

    mobileCard: {
      padding: 4,
    },

    rightIcon: {
      borderColor: onIconHover ? Colors.BLACK : Colors.GRAY2,
      backgroundColor: onIconHover ? Colors.BLACK : Colors.WHITE,
      borderStyle: "solid",
      padding: 4,
      paddingTop: 6,
      borderRadius: "50%",
      marginRight: 14,
      cursor: "pointer",
      transition: "background-color 0.2s ease",
    },
  };

  const navigateToUser = useCallback(
    (showForm?: boolean) => {
      dispatch(DataLoaders.user(person.uid));
      navigate(`/u/${person.username}`, {
        state: showForm
          ? { username: person.username, showForm: true }
          : { username: person.username },
      });
    },
    [dispatch, navigate, person.uid, person.username]
  );

  return (
    <div
      style={isDesktop ? styles.card : styles.mobileCard}
      key={person.uid}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
    >
      <ProfileItem
        profileItem={person}
        subtext={
          <span style={styles.subtext}>
            {formatPhoneNumber(person.customer.phone)}
          </span>
        }
        onItemPress={() => {
          navigateToUser();
        }}
        hideViewIcon
        rightIcon={
          <Tooltip
            title="Subscribe"
            onMouseEnter={() => setOnIconHover(true)}
            onMouseLeave={() => setOnIconHover(false)}
          >
            <div style={styles.rightIcon}>
              <Icon
                icon="ion:notifications"
                height={16}
                width={22}
                style={{ color: onIconHover ? Colors.WHITE : Colors.BLACK }}
                onClick={() => {
                  navigateToUser(true);
                }}
              />
            </div>
          </Tooltip>
        }
      />
    </div>
  );
};

export default FeaturedPeopleCard;
