import { useMemo, useState } from "react";
import { Colors } from "../../utils/colors";
import HeaderProfilePic from "../HeaderProfilePic";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import MainActionsDropdownMenu from "../DropdownsAndTabs/MainActionsDropdownMenu";
import MarkitHeaderLogo from "../MarkitHeaderLogo";
import RectangleButton from "../Buttons/RectangleButton";
import HelpModal from "../Containers/HelpModal";
import { DarkTheme, useTheme } from "../../hooks/useTheme";
import { ThemeType } from "@markit/common.types";

type CreatorModeTopHeaderProps = { eventTheme?: ThemeType };

const CreatorModeTopHeader = (props: CreatorModeTopHeaderProps) => {
  const { eventTheme } = props;
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [helpModal, setHelpModal] = useState(false);
  const { theme } = useTheme(eventTheme);

  const isDarkTheme = useMemo(() => theme && theme === DarkTheme, [theme]);

  const styles = {
    headerButton: {
      height: 35,
      width: 35,
      borderRadius: 35 / 2,
      backgroundColor: Colors.GRAY10,
      border: `1px solid ${Colors.BLACK1}`,
      cursor: "pointer",
    },
  };

  return (
    <>
      <div
        className="AlignedRowSpaced CreatorPanelContainer"
        style={{
          padding: 14,
          backgroundColor: Colors.BLACK,
          borderBottom: isDarkTheme
            ? `0.5px solid ${Colors.BLACK1}`
            : undefined,
        }}
      >
        <div style={{ paddingInline: 8 }}>
          <MarkitHeaderLogo forceColor="white" />
        </div>
        <div className="AlignedRow" style={{ gap: 10 }}>
          <RectangleButton
            buttonLabel="Support"
            onPress={() => setHelpModal(true)}
            altPaddingHorz={12}
            altPaddingVert={10}
            altBorderRadius={100}
            altColor={Colors.GRAY10}
            altTextColor={Colors.WHITE}
            containerStyles={{ border: `0.5px solid ${Colors.BLACK1}` }}
          />
          <MainActionsDropdownMenu
            sourceLocation="creator_top_header"
            overlayStyles={{ width: 200 }}
          >
            <div
              className="Centering"
              style={styles.headerButton}
              onClick={() => setDropdownVisible(!dropdownVisible)}
            >
              <Icon
                icon="ion:add"
                height={26}
                style={{ color: Colors.WHITE }}
              />
            </div>
          </MainActionsDropdownMenu>
          <div style={{ marginBottom: -3 }}>
            <HeaderProfilePic
              accountData={accountData}
              isOnCreatorDashboard={true}
            />
          </div>
        </div>
      </div>
      {helpModal ? (
        <HelpModal showModal={helpModal} setShowModal={setHelpModal} />
      ) : null}
    </>
  );
};

export default CreatorModeTopHeader;
