import React, { useCallback, useMemo, useState } from "react";
import "../../../css/CreateEvent.css";
import { Event } from "@markit/common.types";
import { LightTheme, useTheme } from "../../../hooks/useTheme";
import { Colors } from "../../../utils/colors";
import PlacesAutocomplete, {
  Suggestion,
  geocodeByAddress,
} from "react-places-autocomplete";
import IOSSwitch from "../../IOSSwitch";
import { getDescriptionFromPlaceId } from "../../../utils/makeEvent";
import PopupModalContainer from "../../Containers/PopupModalContainer";
import { useOnMount } from "../../../utils/useOnMount";
import { Icon } from "@iconify/react";
import { AnimatePresence, LazyMotion, domAnimation, m } from "framer-motion";
import RectangleButton from "../../Buttons/RectangleButton";
import { IoIosPin } from "react-icons/io";
import { firebaseMapsApiKey } from "../../../firebase";

type LocationSettings = {
  googleDescription: string;
  googlePlaceId: string;
  formattedAddress: string;
  latitude: number;
  longitude: number;
};

interface CreateLocationModalProps {
  eventSettings: Event;
  setLocationAddress: (locationAddress: string) => void;
  updateEventSettings: (update: Partial<Event>) => void;
  setIsLocationModalOpen: (formsModalOpen: boolean) => void;
}

function loadGoogleMapsScript(apiKey: string, callback: () => void) {
  if (!window.google) {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=Function.prototype`;
    script.async = true;
    script.onload = callback;
    document.head.appendChild(script);
  } else {
    callback();
  }
}

const CreateLocationModal = (props: CreateLocationModalProps) => {
  const {
    eventSettings,
    setLocationAddress,
    updateEventSettings,
    setIsLocationModalOpen,
  } = props;
  const { theme } = useTheme(eventSettings.theme);
  const [modalEventLocationDetails, setModalEventLocationDetails] =
    useState<string>("");
  const [modalEventPrivateLocation, setModalEventPrivateLocation] =
    useState<boolean>(false);
  const [newLocationAddress, setNewLocationAddress] = useState<string>("");
  const [isVirtual, setIsVirtual] = useState(false);
  const [locationSettings, setLocationSettings] = useState<LocationSettings>();
  const [loadingLocation, setLoadingLocation] = useState(false);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [showLocationDetails, setShowLocationDetails] = useState(false);
  const [warning, setWarning] = useState(false);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  const initialSuggestions: Suggestion[] = [
    {
      active: true,
      description: "Boston, MA, USA",
      formattedSuggestion: {
        mainText: "Boston",
        secondaryText: "MA, USA",
      },
      id: "",
      index: 0,
      matchedSubstrings: [
        {
          length: 6,
          offset: 0,
        },
      ],
      placeId: "ChIJGzE9DS1l44kRoOhiASS_fHg",
      terms: [
        {
          offset: 0,
          value: "Boston",
        },
        {
          offset: 8,
          value: "MA",
        },
        {
          offset: 12,
          value: "USA",
        },
      ],
      types: ["locality", "political", "geocode"],
    },
    {
      active: true,
      description: "New York, NY, USA",
      formattedSuggestion: {
        mainText: "New York",
        secondaryText: "NY, USA",
      },
      id: "",
      index: 0,
      matchedSubstrings: [
        {
          length: 8,
          offset: 0,
        },
      ],
      placeId: "ChIJOwg_06VPwokRYv534QaPC8g",
      terms: [
        {
          offset: 0,
          value: "New York",
        },
        {
          offset: 10,
          value: "NY",
        },
        {
          offset: 14,
          value: "USA",
        },
      ],
      types: ["locality", "political", "geocode"],
    },
    {
      active: true,
      description: "San Francisco, CA, USA",
      formattedSuggestion: {
        mainText: "San Francisco",
        secondaryText: "CA, USA",
      },
      id: "",
      index: 0,
      matchedSubstrings: [
        {
          length: 13,
          offset: 0,
        },
      ],
      placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
      terms: [
        {
          offset: 0,
          value: "San Francisco",
        },
        {
          offset: 15,
          value: "CA",
        },
        {
          offset: 19,
          value: "USA",
        },
      ],
      types: ["locality", "political", "geocode"],
    },
    {
      active: true,
      description: "Los Angeles, CA, USA",
      formattedSuggestion: {
        mainText: "Los Angeles",
        secondaryText: "CA, USA",
      },
      id: "",
      index: 0,
      matchedSubstrings: [
        {
          length: 10,
          offset: 0,
        },
      ],
      placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
      terms: [
        {
          offset: 0,
          value: "Los Angeles",
        },
        {
          offset: 13,
          value: "CA",
        },
        {
          offset: 17,
          value: "USA",
        },
      ],
      types: ["locality", "political", "geocode"],
    },
  ];

  useOnMount(() => {
    setIsVirtual(eventSettings.isVirtual);
    setNewLocationAddress(eventSettings.googleDescription);
    if (eventSettings.formattedAddress !== "") {
      const originalLocationSettings: LocationSettings = {
        googleDescription: eventSettings.googleDescription,
        googlePlaceId: eventSettings.googlePlaceId,
        formattedAddress: eventSettings.formattedAddress,
        latitude: eventSettings.lat,
        longitude: eventSettings.lng,
      };
      setLocationSettings(originalLocationSettings);
    }
    setModalEventLocationDetails(eventSettings.locationDetails);
    setModalEventPrivateLocation(eventSettings.privateLocation);
    setShowLocationDetails(eventSettings.locationDetails !== "");

    loadGoogleMapsScript("AIzaSyDhyxKp_OvPO9Gj16ZOE4D91NeI-EXWhec", () => {
      setIsScriptLoaded(true);
    });
  });

  const onLocationSelect = useCallback((address: string) => {
    setLoadingLocation(true);
    setNewLocationAddress(address);
    setIsVirtual(false);
    geocodeByAddress(address)
      .then(async (results) => {
        const description = await getDescriptionFromPlaceId(
          results[0].place_id
        );
        setLocationSettings({
          googleDescription: description,
          googlePlaceId: results[0].place_id,
          formattedAddress: results[0].formatted_address,
          latitude: results[0].geometry.location.lat(),
          longitude: results[0].geometry.location.lng(),
        });
        setLoadingLocation(false);
      })
      .catch((error) => {
        console.error("Error", error);
        setLoadingLocation(false);
      });
  }, []);

  const saveOnPress = useCallback(() => {
    if (isVirtual || locationSettings) {
      if (isVirtual) {
        updateEventSettings({
          isVirtual: isVirtual,
          locationBasedRSVP: false,
          googleDescription: "",
          googlePlaceId: "",
          lat: -1,
          lng: -1,
          formattedAddress: "",
          locationDetails: modalEventLocationDetails,
          privateLocation: modalEventPrivateLocation,
        });
      } else {
        updateEventSettings({
          isVirtual: false,
          googleDescription: locationSettings?.googleDescription,
          googlePlaceId: locationSettings?.googlePlaceId,
          formattedAddress: locationSettings?.formattedAddress,
          lat: locationSettings?.latitude,
          lng: locationSettings?.longitude,
          locationDetails: modalEventLocationDetails,
          privateLocation: modalEventPrivateLocation,
        });
      }
      setLocationAddress(newLocationAddress);
      setIsLocationModalOpen(false);
    } else {
      setWarning(true);
    }
  }, [
    isVirtual,
    locationSettings,
    modalEventLocationDetails,
    modalEventPrivateLocation,
    newLocationAddress,
    setIsLocationModalOpen,
    setLocationAddress,
    updateEventSettings,
  ]);

  const virtualOnPress = useCallback(() => {
    setNewLocationAddress("");
    setIsVirtual(!isVirtual);
  }, [isVirtual, setNewLocationAddress]);

  const getAddress = useMemo(() => {
    return !isVirtual &&
      locationSettings &&
      locationSettings.formattedAddress !== "" ? (
      <span style={{ color: Colors.GRAY2, fontSize: 12 }}>
        {locationSettings.formattedAddress}
      </span>
    ) : null;
  }, [isVirtual, locationSettings]);

  return (
    <PopupModalContainer
      headerComp={
        <div className="AlignedRow" style={{ gap: "3px" }}>
          <div>Location</div>
          <Icon icon={"mdi:map-marker-outline"} height={22} />
        </div>
      }
      valueComp={
        <>
          {isScriptLoaded ? (
            <PlacesAutocomplete
              value={newLocationAddress}
              onChange={(address) => {
                setNewLocationAddress(address);
              }}
              onSelect={(address) => {
                onLocationSelect(address);
                setIsSearchModalOpen(false);
              }}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div style={{ borderRadius: "8px" }}>
                  {(loadingLocation || !locationSettings) && !isVirtual ? (
                    <input
                      style={{
                        ...theme.TertiaryBG,
                        ...theme.PrimaryText,
                        padding: 14,
                        fontSize: 16,
                        borderRadius: 8,
                        border: isSearchModalOpen
                          ? theme === LightTheme
                            ? "1px solid black"
                            : "1px solid white"
                          : "1px solid #B9B9B9",
                        fontWeight: "500",
                        width: "100%",
                        boxSizing: "border-box",
                        marginBottom: 10,
                      }}
                      {...getInputProps({
                        placeholder: "Enter Location",
                        className: "location-search-input",
                      })}
                      onClick={() => setIsSearchModalOpen(true)}
                    />
                  ) : (
                    <div
                      className="AlignedRowSpaced"
                      style={{
                        ...theme.TertiaryBG,
                        marginBottom: 10,
                        padding: "10px 14px",
                        border:
                          warning && !locationSettings
                            ? "1px solid red"
                            : theme === LightTheme
                            ? "1px solid #f4f5f6"
                            : "1px solid #313135",
                        borderRadius: "8px",
                      }}
                    >
                      <div className="ColumnNormal" style={{ gap: 4 }}>
                        <h4 className="AboutSubtitle" style={theme.PrimaryText}>
                          {!isVirtual && locationSettings?.googleDescription
                            ? locationSettings?.googleDescription
                                .split(",")
                                .map((loc) => loc.trim())[0]
                            : "Virtual Event"}
                        </h4>
                        {getAddress}
                        {modalEventPrivateLocation ? (
                          <div className="AlignedRow">
                            <Icon
                              icon={"ion:eye-off"}
                              height={13}
                              style={{ color: Colors.GRAY2, marginRight: 7 }}
                            />
                            <span style={{ color: Colors.GRAY2, fontSize: 12 }}>
                              RSVP required to view location
                            </span>
                          </div>
                        ) : null}
                      </div>
                      {!isVirtual ? (
                        <Icon
                          icon={"ion:close-outline"}
                          height={22}
                          style={{ color: Colors.GRAY1, cursor: "pointer" }}
                          onClick={() => {
                            setNewLocationAddress("");
                            setLocationSettings(undefined);
                          }}
                        />
                      ) : (
                        <Icon
                          icon={"ion:videocam-outline"}
                          height={26}
                          style={theme.PrimaryText}
                        />
                      )}
                    </div>
                  )}
                  <AnimatePresence>
                    {isSearchModalOpen ? (
                      <>
                        <LazyMotion features={domAnimation}>
                          <m.div
                            key="searchModalBg"
                            className="SearchModalBG HideScrollbar"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 0.4 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.2 }}
                            onClick={() => setIsSearchModalOpen(false)}
                          />
                        </LazyMotion>
                        <LazyMotion features={domAnimation}>
                          <m.div
                            className="SearchModal HideScrollbar"
                            initial={{ y: -10, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -10, opacity: 0 }}
                            transition={{ duration: 0.3 }}
                          >
                            <div
                              style={{
                                width: "100%",
                                position: "absolute",
                                boxShadow:
                                  "-3px 7px 25px -1px rgba(0, 0, 0, 0.30)",
                                borderRadius: "8px",
                                paddingTop: "7px",
                                zIndex: 5,
                                ...theme.SecondaryBG,
                              }}
                              className="autocomplete-dropdown-container"
                            >
                              {loading ? (
                                <div
                                  style={{
                                    ...theme.PrimaryText,
                                    display: "flex",
                                    justifyContent: "center",
                                    padding: 10,
                                  }}
                                >
                                  Loading...
                                </div>
                              ) : suggestions.length > 0 ? (
                                suggestions.map((suggestion) => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";

                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                      })}
                                      className={
                                        "LocationItem " +
                                        (theme === LightTheme
                                          ? "LocationItemLight"
                                          : "LocationItemDark")
                                      }
                                    >
                                      <div
                                        style={{
                                          minHeight: 20,
                                          cursor: "pointer",
                                        }}
                                      >
                                        <Icon
                                          icon={"mdi:map-marker-outline"}
                                          height={20}
                                          style={{ color: Colors.GRAY2 }}
                                        />
                                      </div>
                                      <div style={{ cursor: "pointer" }}>
                                        <div
                                          style={{
                                            ...theme.PrimaryText,
                                            fontWeight: "600",
                                            cursor: "pointer",
                                          }}
                                        >
                                          {
                                            suggestion.formattedSuggestion
                                              .mainText
                                          }
                                        </div>
                                        <div
                                          className="LocationSubtext"
                                          style={{
                                            marginTop: "7px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          {
                                            suggestion.formattedSuggestion
                                              .secondaryText
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <>
                                  <span
                                    className="LocationSubtext"
                                    style={{ paddingInline: "14px" }}
                                  >
                                    Suggested
                                  </span>
                                  {initialSuggestions.map((suggestion) => {
                                    return (
                                      <div
                                        className={
                                          "LocationItem " +
                                          (theme === LightTheme
                                            ? "LocationItemLight"
                                            : "LocationItemDark")
                                        }
                                        onClick={() => {
                                          onLocationSelect(
                                            suggestion.description
                                          );
                                          setIsSearchModalOpen(false);
                                        }}
                                      >
                                        <div
                                          style={{
                                            minHeight: 20,
                                            cursor: "pointer",
                                          }}
                                        >
                                          <Icon
                                            icon={"mdi:map-marker-outline"}
                                            height={20}
                                            style={{ color: Colors.GRAY2 }}
                                          />
                                        </div>
                                        <div style={{ cursor: "pointer" }}>
                                          <div
                                            style={{
                                              ...theme.PrimaryText,
                                              fontWeight: "600",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {
                                              suggestion.formattedSuggestion
                                                .mainText
                                            }
                                          </div>
                                          <div
                                            className="LocationSubtext"
                                            style={{
                                              color: Colors.GRAY2,
                                              fontSize: "14px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {
                                              suggestion.formattedSuggestion
                                                .secondaryText
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </>
                              )}
                            </div>
                          </m.div>
                        </LazyMotion>
                      </>
                    ) : null}
                  </AnimatePresence>
                </div>
              )}
            </PlacesAutocomplete>
          ) : null}
          {!isVirtual && !loadingLocation && locationSettings ? (
            <div
              className="LocationContainer"
              style={{ margin: 0, marginBottom: 10 }}
            >
              <img
                className="LocationSquare"
                src={
                  "https://maps.googleapis.com/maps/api/staticmap?center=" +
                  locationSettings?.latitude +
                  "," +
                  locationSettings?.longitude +
                  `&zoom=16&size=400x200&key=${firebaseMapsApiKey}`
                }
                alt="map"
                style={{ borderRadius: 8 }}
              />
              <IoIosPin
                size={40}
                color="11B8FF"
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%,-50%)",
                }}
              />
            </div>
          ) : null}
          {!showLocationDetails ? (
            <div
              className="AlignedRowSpaced"
              style={{ cursor: "pointer", paddingBlock: 7 }}
              onClick={() => setShowLocationDetails(true)}
            >
              <div
                style={{
                  color: Colors.GRAY1,
                  fontWeight: "500",
                  cursor: "pointer",
                }}
              >
                {isVirtual
                  ? "Add Virtual Event Details"
                  : "Add Location Description"}
              </div>
              <Icon
                icon={"ion:add-circle-outline"}
                height={20}
                style={{ color: Colors.GRAY1 }}
              />
            </div>
          ) : (
            <div
              className="descriptionTextAreaContainer"
              style={{ ...theme.TertiaryBG, marginBottom: 10 }}
            >
              <textarea
                value={modalEventLocationDetails}
                placeholder={"Provide location details (optional)..."}
                onChange={(e) => setModalEventLocationDetails(e.target.value)}
                className="descriptionTextArea"
                maxLength={1000}
                style={{ height: "17vh", ...theme.PrimaryText }}
              />
              <div
                className="AlignedRow"
                style={{ marginTop: 14, justifyContent: "space-between" }}
              >
                <h4
                  style={{
                    fontWeight: "400",
                    fontSize: 14,
                    color: Colors.GRAY1,
                  }}
                >
                  {modalEventLocationDetails.length}/1000
                </h4>
              </div>
            </div>
          )}
          <div
            className="AlignedRowSpaced"
            style={{
              ...theme.TertiaryBG,
              marginBlock: 10,
              padding: "10px 14px",
              borderRadius: "8px",
            }}
          >
            <h4 className="AboutSubtitle" style={theme.PrimaryText}>
              Virtual Event
            </h4>
            <IOSSwitch
              sx={{ m: 1 }}
              checked={isVirtual}
              onChange={virtualOnPress}
            />
          </div>
          <div
            className="AlignedRowSpaced"
            style={{
              ...theme.TertiaryBG,
              marginBottom: 10,
              padding: "10px 14px",
              borderRadius: "8px",
            }}
          >
            <div>
              <h4 className="AboutSubtitle" style={theme.PrimaryText}>
                Hidden Location
              </h4>
              <div
                style={{ color: Colors.GRAY2, fontSize: "12px", marginTop: 4 }}
              >
                Require RSVP to view location
              </div>
            </div>
            <IOSSwitch
              sx={{ m: 1 }}
              checked={modalEventPrivateLocation}
              onChange={() =>
                setModalEventPrivateLocation(!modalEventPrivateLocation)
              }
            />
          </div>
          <RectangleButton
            buttonLabel={"Save"}
            onPress={saveOnPress}
            disabled={loadingLocation || (!isVirtual && !locationSettings)}
            altColor={theme === LightTheme ? Colors.BLACK : Colors.WHITE}
            altTextColor={theme === LightTheme ? Colors.WHITE : Colors.BLACK}
            loading={loadingLocation}
          />
        </>
      }
      theme={theme}
      closeModal={() => setIsLocationModalOpen(false)}
    />
  );
};

export default CreateLocationModal;
