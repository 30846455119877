import React from "react";
import { Colors } from "../../utils/colors";
import { isDesktop } from "react-device-detect";
import { Icon } from "@iconify/react";
import ExploreNavCard from "./ExploreNavCard";
import LosAngelesNavImage from "../../assets/ExplorePageBackgrounds/LosAngelesNavImage.png";
import BostonNavImage from "../../assets/ExplorePageBackgrounds/BostonNavImage.png";
import NewYorkNavImage from "../../assets/ExplorePageBackgrounds/NewYorkNavImage.png";
import { useNavigate } from "react-router";

const FeaturedCities = () => {
  const navigate = useNavigate();
  const styles = {
    citiesContainer: { marginTop: 40 },
    title: {
      fontSize: isDesktop ? 32 : 24,
      fontWeight: 600,
    },
    subtitle: {
      fontSize: isDesktop ? 16 : 14,
      color: Colors.GRAY1,
    },
  };
  return (
    <div style={styles.citiesContainer}>
      <div
        className="ColumnNormal"
        style={{ gap: isDesktop ? 7 : 4, marginBottom: 24 }}
      >
        <div className="AlignedRow" style={{ gap: 14 }}>
          <span style={styles.title}>Cities</span>
          <Icon
            icon="mdi:eiffel-tower"
            height={32}
            style={{ color: Colors.BLACK }}
          />
        </div>
        <p style={styles.subtitle}>Explore creators & events in your city.</p>
      </div>
      <div
        className={isDesktop ? "FeaturedCitiesContainer" : "AlignedColumn"}
        style={{ gap: 14 }}
      >
        <ExploreNavCard
          title="New York"
          onPress={() => navigate("/explore/newyork")}
          city={true}
          src={NewYorkNavImage}
        />
        <ExploreNavCard
          title="Boston"
          onPress={() => navigate("/explore/boston")}
          city={true}
          src={BostonNavImage}
        />
        <ExploreNavCard
          title="Los Angeles"
          onPress={() => navigate("/explore/losangeles")}
          city={true}
          src={LosAngelesNavImage}
        />
      </div>
    </div>
  );
};

export default FeaturedCities;
