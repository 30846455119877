import "../../css/FullEvent/GuestList.css";
import "../../css/GlobalStyles.css";
import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useLoadUserTicketList } from "../../hooks/useLoadUserTicketList";
import { TabPanel } from "../../components/FullEventSubComponents/TabPanel";
import {
  a11yProps,
  CustomTab,
  CustomTabs,
} from "../../components/FullEventSubComponents/CustomTabs";
import { AccountData, Event, RequestStatus } from "@markit/common.types";
import { isDesktop } from "react-device-detect";
import { InitialsPic } from "../../components/InitialsPic";
import { useLoadUserList } from "../../hooks/useLoadUserList";
import FlatList from "flatlist-react";
import { useTheme } from "../../hooks/useTheme";
import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import { userCurrentNumberOfTickets } from "../../utils/eventUtils/userTicketUtils";
import { handleLinkPressed } from "@markit/common.utils";
import { useLiveUpdatingEventAnalytics } from "../../hooks/useLiveUpdatingEventAnalytics";

type GuestListProps = {
  event: Event;
  setDisplayGuestList: React.Dispatch<React.SetStateAction<any>>;
};

export const GuestList = ({ setDisplayGuestList, event }: GuestListProps) => {
  const { theme } = useTheme();

  const [value, setValue] = React.useState(0);
  const [loadingAttendees, setLoadingAttendees] = useState(true);
  const [loadingInterested, setLoadingInterested] = useState(true);
  const [loadingRequested, setLoadingRequested] = useState(true);
  const [fullInterestedRequestedData, setFullInterestedRequestedData] =
    useState<AccountData[]>();
  const [oldAttendeeAccountData, setOldAttendeeAccountData] = useState<
    AccountData[]
  >([]);

  const [numAttendeeTickets, setNumAttendeeTickets] = useState<{
    [key: string]: number;
  }>({});

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const {
    liveTotalGuests,
    uniqueGuestsUids,
    liveTicketsScanned,
    liveTotalRequested,
    liveTotalOrganizers,
  } = useLiveUpdatingEventAnalytics({
    eventId: event.id,
  });

  const {
    isFinished: isAttendeeFinished,
    isLoading: isAttendeeLoading,
    fetchedUserData: fullAttendeeAccountData,
    loadTicketUsers,
    loadMoreTicketUsers: loadMoreAttendeeUsers,
  } = useLoadUserTicketList({
    event: event,
    windowSize: 20,
    ticketType: RequestStatus.ACCEPTED,
  });

  const {
    isFinished: isInterestedFinished,
    isLoading: isInterestedLoading,
    fetchedUserData: fullInterestedAccountData,
    loadMoreUsers: loadMoreInterestedUsers,
  } = useLoadUserList({
    userIdList: event?.wished ?? [],
    windowSize: 20,
  });

  const {
    isFinished: isPendingRequestsFinished,
    isLoading: isPendingRequestsLoading,
    fetchedUserData: fullPendingRequestsAccountData,
    loadMoreTicketUsers: loadMorePendingRequestsUsers,
  } = useLoadUserTicketList({
    event: event,
    windowSize: 20,
    ticketType: RequestStatus.PENDING,
  });

  useEffect(() => {
    setFullInterestedRequestedData(
      fullInterestedAccountData.concat(fullPendingRequestsAccountData)
    );
  }, [fullInterestedAccountData, fullPendingRequestsAccountData]);

  useEffect(() => {
    if (
      loadingAttendees &&
      fullAttendeeAccountData.length === 0 &&
      !isAttendeeFinished &&
      event
    ) {
      loadTicketUsers();
      setLoadingAttendees(false);
    }
    if (
      !isInterestedLoading &&
      fullInterestedAccountData.length === 0 &&
      !isInterestedFinished
    ) {
      loadMoreInterestedUsers();
      setLoadingInterested(false);
    }
    if (
      !isPendingRequestsLoading &&
      fullPendingRequestsAccountData.length === 0 &&
      !isPendingRequestsFinished
    ) {
      loadMorePendingRequestsUsers();
      setLoadingRequested(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fullAttendeeAccountData,
    fullInterestedAccountData.length,
    fullPendingRequestsAccountData,
    isAttendeeFinished,
    isInterestedFinished,
    isPendingRequestsFinished,
    // isInterestedLoading,
    loadMoreAttendeeUsers,
    // loadMoreInterestedUsers,
    loadTicketUsers,
    loadingAttendees,
    loadingInterested,
    loadingRequested,
  ]);

  useEffect(() => {
    (async () => {
      const fetchTicketNumber = async (eventId: string, userId: string) => {
        const tickets = await userCurrentNumberOfTickets(eventId, userId);
        setNumAttendeeTickets((prevNumAttendeeTickets) => ({
          ...prevNumAttendeeTickets,
          [userId]: tickets,
        }));
      };

      const newAttendeeAccountData = fullAttendeeAccountData.filter(
        (data: AccountData) => !oldAttendeeAccountData.includes(data)
      );
      await Promise.all(
        newAttendeeAccountData.map((guest) => {
          fetchTicketNumber(event.id, guest.uid);
          return true;
        })
      );

      setOldAttendeeAccountData(fullAttendeeAccountData);
    })();
  }, [event.id, fullAttendeeAccountData, oldAttendeeAccountData]);

  const renderSocialMediaIcons = (guest: AccountData) => {
    return (
      <>
        {guest.instagram !== "" && (
          <Icon
            icon="mdi:instagram"
            height={18}
            onClick={() =>
              window.open(
                handleLinkPressed("instagram", guest.instagram),
                "_blank"
              )
            }
            color={Colors.GRAY1}
            style={{ cursor: "pointer" }}
          />
        )}
        {guest.twitter !== "" && (
          <Icon
            icon="ri:twitter-x-fill"
            height={18}
            onClick={() =>
              window.open(handleLinkPressed("twitter", guest.twitter), "_blank")
            }
            color={Colors.GRAY1}
            style={{ cursor: "pointer" }}
          />
        )}
        {guest.linkedin !== "" && (
          <Icon
            icon="ri:linkedin-fill"
            height={18}
            onClick={() =>
              window.open(
                handleLinkPressed("linkedin", guest.linkedin),
                "_blank"
              )
            }
            color={Colors.GRAY1}
            style={{ cursor: "pointer" }}
          />
        )}
        {guest.tiktok !== "" && (
          <Icon
            icon="ic:baseline-tiktok"
            height={18}
            onClick={() =>
              window.open(handleLinkPressed("tiktok", guest.tiktok), "_blank")
            }
            color={Colors.GRAY1}
            style={{ cursor: "pointer" }}
          />
        )}
        {guest.spotify !== "" && (
          <Icon
            icon="mdi:spotify"
            height={18}
            onClick={() =>
              window.open(handleLinkPressed("spotify", guest.spotify), "_blank")
            }
            color={Colors.GRAY1}
            style={{ cursor: "pointer" }}
          />
        )}
      </>
    );
  };

  return (
    <div className="GuestList" style={theme.TransparentBG}>
      <div
        className={"GuestListTitle"}
        style={{ width: isDesktop ? "97%" : "90%" }}
      >
        <p className={"GuestListTitleText"} style={theme.PrimaryText}>
          Guest List
        </p>
        <Icon
          icon="gridicons:cross-circle"
          height={30}
          color={Colors.GRAY1}
          onClick={() => setDisplayGuestList(false)}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div
        className={
          isDesktop
            ? "GuestListWrapperDesktop GuestListModalView"
            : "GuestListWrapperMobile"
        }
        style={{
          display:
            (loadingAttendees || loadingInterested) && !isDesktop
              ? "none"
              : "block",
          ...theme.SecondaryBG,
        }}
      >
        <Box>
          <CustomTabs
            value={value}
            onChange={handleChange}
            sx={{ borderBottom: 0, marginLeft: 1 }}
          >
            <CustomTab
              label={
                <div
                  className={value === 0 ? "TabNameRowActive" : "TabNameRow"}
                  style={
                    value === 0 ? theme.TabRowActiveBottom : theme.TabRowBottom
                  }
                >
                  <span className={"TabName"} style={theme.PrimaryText}>
                    Going ({liveTotalGuests})
                  </span>
                </div>
              }
              {...a11yProps(0)}
            />
            <CustomTab
              label={
                <div
                  className={value === 1 ? "TabNameRowActive" : "TabNameRow"}
                  style={
                    value === 1 ? theme.TabRowActiveBottom : theme.TabRowBottom
                  }
                >
                  <span className={"TabName"} style={theme.PrimaryText}>
                    Interested ({liveTotalRequested})
                  </span>
                </div>
              }
              {...a11yProps(1)}
            />
          </CustomTabs>
        </Box>
        <TabPanel value={value} index={0}>
          {!loadingAttendees && !loadingInterested ? (
            <div className={isDesktop ? "GuestContentDesktop" : "GuestContent"}>
              <FlatList
                list={fullAttendeeAccountData}
                renderItem={(guest) => {
                  const numTickets = numAttendeeTickets[guest.uid] || 0;

                  return (
                    <div key={guest.uid} style={{ marginBottom: "10px" }}>
                      <div className="GuestLine">
                        <div className="GuestInfo">
                          <a
                            href={`/u/${guest.username}`}
                            className="GuestInfo"
                            style={{
                              textDecoration: "none",
                              color: Colors.BLACK,
                            }}
                          >
                            {guest.profilePicURL &&
                            guest.profilePicURL !== "" ? (
                              <img
                                className="GuestPic"
                                src={guest.profilePicURL}
                                alt="guest profile pic"
                                style={{ cursor: "pointer" }}
                              />
                            ) : (
                              <div
                                className="GuestPic InitialsPic"
                                style={{ cursor: "pointer" }}
                              >
                                <InitialsPic fullName={guest.fullName} isLink />
                              </div>
                            )}
                            <div
                              style={{
                                ...theme.PrimaryText,
                                cursor: "pointer",
                              }}
                            >
                              {guest.fullName}
                            </div>
                          </a>
                          {renderSocialMediaIcons(guest)}
                          {numTickets > 1 && (
                            <div style={theme.PrimaryText}>
                              (+{numTickets - 1})
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }}
                renderWhenEmpty={() => (
                  <div>
                    <span style={theme.PrimaryText}>No attendees to show</span>
                  </div>
                )}
                hasMoreItems={!isAttendeeFinished}
                loadMoreItems={() => {
                  loadMoreAttendeeUsers();
                }}
                paginationLoadingIndicator={() =>
                  isAttendeeLoading && !isAttendeeFinished ? (
                    <CircularProgress
                      style={{ color: "#929292", alignSelf: "center" }}
                      size={20}
                    />
                  ) : null
                }
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: isDesktop ? "507px" : "100vh",
              }}
            >
              <CircularProgress
                style={{ color: "#929292", alignSelf: "center" }}
                size={25}
              />
            </div>
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {!loadingAttendees && !loadingInterested && !loadingRequested ? (
            <div className={isDesktop ? "GuestContentDesktop" : "GuestContent"}>
              <FlatList
                list={fullInterestedRequestedData ?? fullInterestedAccountData}
                renderItem={(guest) => (
                  <div key={guest.uid} style={{ marginBottom: "10px" }}>
                    <div className="GuestLine">
                      <div className="GuestInfo">
                        <a
                          href={`/u/${guest.username}`}
                          className="GuestInfo"
                          style={{
                            textDecoration: "none",
                            color: Colors.BLACK,
                          }}
                        >
                          {guest.profilePicURL && guest.profilePicURL !== "" ? (
                            <img
                              className="GuestPic"
                              src={guest.profilePicURL}
                              alt="guest profile pic"
                              style={{ cursor: "pointer" }}
                            />
                          ) : (
                            <div
                              className="GuestPic InitialsPic"
                              style={{ cursor: "pointer" }}
                            >
                              <InitialsPic fullName={guest.fullName} isLink />
                            </div>
                          )}
                          <div
                            style={{ ...theme.PrimaryText, cursor: "pointer" }}
                          >
                            {guest.fullName}
                          </div>
                        </a>
                        {renderSocialMediaIcons(guest)}
                      </div>
                    </div>
                  </div>
                )}
                renderWhenEmpty={() => (
                  <div>
                    <span style={theme.PrimaryText}>No interested to show</span>
                  </div>
                )}
                hasMoreItems={
                  !isInterestedFinished || !isPendingRequestsFinished
                }
                loadMoreItems={() => {
                  loadMoreInterestedUsers();
                  loadMorePendingRequestsUsers();
                }}
                paginationLoadingIndicator={() =>
                  isInterestedLoading &&
                  !isInterestedFinished &&
                  isPendingRequestsLoading &&
                  !isPendingRequestsFinished ? (
                    <CircularProgress
                      style={{ color: "#929292", alignSelf: "center" }}
                      size={20}
                    />
                  ) : null
                }
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: isDesktop ? "507px" : "100vh",
              }}
            >
              <CircularProgress
                style={{ color: "#929292", alignSelf: "center" }}
                size={25}
              />
            </div>
          )}
        </TabPanel>
      </div>
    </div>
  );
};
