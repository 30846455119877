import { useSelector } from "react-redux";
import { ParametricSelector } from "reselect";
import { AppState } from "../redux/store";
import { useLocation } from "react-router-dom";
import { getUrlParamState } from "../redux/slices/urlParamSlice";

export function useParametricSelector<T, A>(
  selector: ParametricSelector<AppState, A, T>,
  arg: A
): T {
  return useSelector((state: AppState) => selector(state, arg));
}

// gets the defined eventId from either the navigate state props or the url params
export const useEventSelector = (): string => {
  const location = useLocation().state || {};
  const { e: eventId, id } = useSelector(getUrlParamState);

  // prioritize location state first (all navigates set location state), then grabs from UrlParamState if no location eventId (when you directly load the page)
  return location.eventId ? location.eventId : eventId ? eventId : id;
};

// gets the defined userId from either the navigate state props or the url params
export const useAccountSelector = (): string => {
  const location = useLocation().state || {};
  const { i, qrCodeId } = useSelector(getUrlParamState);

  return location.userId ? location.userId : i ? i : qrCodeId;
};

// gets the defined eventId from either the navigate state props or the url params
export const useProfileSelector = (): string => {
  const location = useLocation().state || {};
  const { u: username, username: searchUsername } =
    useSelector(getUrlParamState);

  return location.username
    ? location.username
    : username
    ? username
    : searchUsername;
};
