import { useCallback, useState } from "react";
import { AccountData, Event, Visibility } from "@markit/common.types";
import { geohashForLocation } from "geofire-common";
import { GoogleLocationData } from "./useCreateEventManagement";
import { makeEmptyEvent } from "../utils/makeEvent";

type usePartialEventUpdatesProps = {
  event: Event | undefined;
  accountData: AccountData;
  currentLocation?: GoogleLocationData;
};

export const usePartialEventUpdates = (props: usePartialEventUpdatesProps) => {
  const { event, accountData, currentLocation } = props;
  const [eventSettings, setEventSettings] = useState<Event>(
    event ??
      makeEmptyEvent(
        accountData.uid,
        accountData.fullName,
        accountData.isAdmin,
        currentLocation
      )
  );

  const updateEventSettings = useCallback(
    (newEventSettings: Partial<Event>) => {
      let otherUpdates: Partial<Event> = {};
      if (
        (newEventSettings.lat && (newEventSettings.lng ?? eventSettings.lng)) ||
        (newEventSettings.lng && (newEventSettings.lat ?? eventSettings.lat))
      ) {
        otherUpdates = {
          ...otherUpdates,
          geohash: geohashForLocation([
            newEventSettings.lat ?? eventSettings.lat,
            newEventSettings.lng ?? eventSettings.lng,
          ]),
        };
      }

      if (newEventSettings.visibility) {
        if (newEventSettings.visibility === Visibility.University) {
          otherUpdates = {
            ...otherUpdates,
            universityId: accountData.university,
          };
        } else {
          otherUpdates = { ...otherUpdates, universityId: -1 };
        }
      }

      setEventSettings((prevEventSettings) => {
        return { ...prevEventSettings, ...newEventSettings, ...otherUpdates };
      });
    },
    [accountData.university, eventSettings]
  );

  const resetEventSettings = useCallback(
    () =>
      setEventSettings(
        makeEmptyEvent(
          accountData.uid,
          accountData.fullName,
          accountData.isAdmin,
          currentLocation
        )
      ),
    [accountData, currentLocation]
  );

  return {
    eventSettings,
    updateEventSettings,
    resetEventSettings,
  };
};
