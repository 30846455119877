import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";

export function ExternalLinkText({
  href,
  children,
}: {
  href: string;
  children: React.ReactNode;
}) {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className="AlignedRow"
      style={{ whiteSpace: "nowrap" }}
    >
      <u
        style={{
          textDecoration: "none",
          color: Colors.BLACK,
          fontWeight: "500",
        }}
      >
        {children}
      </u>
      <Icon
        icon="iconoir:arrow-up-right"
        height={10}
        style={{ color: Colors.BLACK, marginLeft: 2 }}
      />
    </a>
  );
}
