import { useTheme } from "../../hooks/useTheme";
import AlertContainer from "./AlertContainer";

type AlertButtonWrapperProps = {
  buttonComp: React.ReactNode;
  alertTextHeader: string;
  alertTextSubHeader?: string;
  clearAlert: () => void;
};

// Used as a wrapper for the AlertContainer with any action button so that AlertContainer doesn't need to also be part of the file to be used
const AlertButtonWrapper = (props: AlertButtonWrapperProps) => {
  const { buttonComp, alertTextHeader, alertTextSubHeader, clearAlert } = props;
  const { theme } = useTheme();

  return (
    <div>
      {buttonComp}
      <AlertContainer
        headerComp={alertTextHeader}
        subHeaderComp={alertTextSubHeader}
        theme={theme}
        closeModal={clearAlert}
        hideModal={alertTextHeader === ""}
      />
    </div>
  );
};

export default AlertButtonWrapper;
