import "../../css/App.css";
import "../../css/FullEvent/FullEvent.css";
import "../../css/CreateEvent.css";
import "../../css/GlobalStyles.css";
import { Helmet } from "react-helmet";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import TopHeader from "../../components/TopHeader";
import Footer from "../../components/Footer";
import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import {
  isEventTicketsPaid,
  isExternalGenericLink,
  isSubscriptionPaymentFailed,
} from "@markit/common.utils";
import { useOnMount } from "../../utils/useOnMount";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Event, EventType, OrganizerType } from "@markit/common.types";
import { useDispatch, useSelector } from "react-redux";
import {
  LoginState,
  accountActions,
  getAccountState,
  fetchReadyForPayment,
} from "../../redux/slices/accountSlice";
import { firebaseStorage, listAll, ref } from "../../firebase";
import {
  MEDIA_UPLOAD_CODES,
  errorAlertText,
  errorSubAlertText,
  filteredSampleImages,
  uploadMedia,
} from "../../utils/photoUtils";
import { useCreateEventManagement } from "../../hooks/useCreateEventManagement";
import { useLocation, useSearchParams } from "react-router-dom";
import CreateEventCustomTicketOption from "../../components/CreateEvent/CreateEventCustomTicketOption";
import { LightTheme, useTheme } from "../../hooks/useTheme";
import { isDesktop } from "react-device-detect";
import EmptyViewStateMobile from "../../components/EmptyStates/EmptyViewStateMobile";
import FormBuilderWrapper from "../../components/Form/FormBuilderWrapper";
import { useEventSelector } from "../../hooks/useParametricSelector";
import { getUserTicket } from "../../utils/eventUtils/userTicketUtils";
import PopupModalContainer from "../../components/Containers/PopupModalContainer";
import { eventActions } from "../../redux/slices/eventSlice";
import { API } from "../../API";
import { getEventState } from "../../redux/slices/eventSlice";
import { DataLoaders } from "../../redux/slices/dataSlice";
import CreateEventTickets from "../../components/CreateEvent/CreateEventTickets";
import { getEventFormQuestions } from "../../utils/eventUtils/formUtils";
import LoadingScreen from "../LoadingScreen";
import CreatorModeSidebar from "../../components/CreatorDashboard/CreatorModeSidebar";
import CreatorModeTopHeader from "../../components/CreatorDashboard/CreatorModeTopHeader";
import { MixpanelContext } from "../../context/AnalyticsService";
import PaymentFailedButtonModal from "../../components/DisplayModal/UpdatePaymentInfoModal";
import EnablePaidTicketsModal from "../../components/DisplayModal/EnablePaidTicketsModal";
import AlertContainer from "../../components/Containers/AlertContainer";
import ConfirmDeleteModal from "../../components/Containers/ConfirmPopups/ConfirmDeleteModal";
import CreateEventExtraDetails from "../../components/CreateEvent/CreateEventExtraDetails";
import CreateEventTitleDescriptionForm from "../../components/CreateEvent/CreateEventTitleDescriptionForm";
import RectangleButton from "../../components/Buttons/RectangleButton";
import StandardContainer from "../../components/Containers/StandardContainer";
import { useNavigate } from "../../hooks/useNavigate";
import ConfirmActionPopup, {
  BinaryConfirmActions,
} from "../../components/Containers/ConfirmPopups/ConfirmActionPopup";
import { onCreateLinkNavigatePath } from "../../utils/navigationUtils";

export enum TicketingOptions {
  FREE = "Free",
  PAID = "Paid",
  REQUEST = "Request",
  CUSTOM = "Custom",
}

export const CreateEvent = () => {
  const { account } = useSelector(getAccountState);
  const {
    accountData,
    loggedIn,
    savedQuestions,
    readyForPayment,
    appInitialized,
  } = account;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mixpanel = useContext(MixpanelContext);
  const [searchParams] = useSearchParams();

  const {
    duplicatedEvent, // used for passing in the external event data too
    editEvent,
    externalNotScraped,
    isConvertingExternalEvent,
    cloneDetails,
  } = useLocation().state || {};

  // Only used for anyone that refreshes or goes straight to edit url
  const { events } = useSelector(getEventState);
  const eventId = useEventSelector();

  const existingEvent: Event | undefined = useMemo(
    () =>
      duplicatedEvent ??
      editEvent ??
      events.events.find((event) => event.id === eventId),
    [duplicatedEvent, editEvent, eventId, events.events]
  );

  // States for sidebar
  const refContainer = useRef<HTMLDivElement>(null);

  // States for image picker
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  // States for event details (@TODO: separate modal states from actual states)
  const [currTicketNumber, setCurrTicketNumber] = useState(-1);

  // Modals for setting some event details
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [isTicketsModalOpen, setIsTicketsModalOpen] = useState(false);
  const [isDeleteDraftModalOpen, setIsDeleteDraftModalOpen] = useState(false);
  const [confirmPopupTicketType, setConfirmPopupTicketType] =
    useState<BinaryConfirmActions>(BinaryConfirmActions.NONE);
  const [sampleEventPhotos, setSampleEventPhotos] = useState<string[]>([]);
  const [morePanelVisible, setMorePanelVisible] = useState(false);

  const [paymentFailedVisible, setPaymentFailedVisible] = useState(false);
  const [connectStripeModal, setConnectStripeModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [alertText, setAlertText] = useState("");
  const [alertSubText, setAlertSubText] = useState("");

  const {
    updateEventSettings,
    postingEvent,
    isEditing,
    eventSettings,
    showError,
    onPostEventPress,
    onSaveEventPress,
    onNextExternalPress,
    onDraftEventPress,
  } = useCreateEventManagement({
    event: existingEvent,
    isEditing: editEvent || (eventId !== "" && !duplicatedEvent),
    cloneDetails: cloneDetails,
    setAlertText,
  });

  const { theme } = useTheme(eventSettings.theme);

  const essentialQuestion = useMemo(
    () => savedQuestions.find((question) => question.isEssential),
    [savedQuestions]
  );

  const editingLiveEvent = useMemo(
    () => existingEvent && isEditing && !existingEvent.isDraft,
    [existingEvent, isEditing]
  );

  const eventReadyForPayment = useMemo(
    () =>
      eventSettings.stripePaymentCollector !== "" ? true : readyForPayment,
    [eventSettings.stripePaymentCollector, readyForPayment]
  );

  // External event that isn't being converted to a Markit event
  const isExternalLink = useMemo(
    () =>
      eventSettings.externalLink !== "" &&
      eventSettings.eventType !== EventType.MARKIT,
    [eventSettings.eventType, eventSettings.externalLink]
  );

  // Check if the subscription is Payment Failed
  useEffect(() => {
    if (isSubscriptionPaymentFailed(accountData.customer.state)) {
      setPaymentFailedVisible(true);
      return;
    }
  }, [accountData.customer.state]);

  useEffect(() => {
    (async () => {
      if (loggedIn === LoginState.LOGGED_IN && !appInitialized) {
        return;
      }

      if (isEditing && existingEvent) {
        const userTicket = await getUserTicket(
          existingEvent.id,
          accountData.uid
        );
        if (
          loggedIn !== LoginState.LOGGED_IN ||
          !userTicket ||
          (userTicket &&
            userTicket.role.type !== OrganizerType.HOST &&
            userTicket.role.type !== OrganizerType.COHOST)
        ) {
          navigate("/e/" + existingEvent.id);
        }
      }
    })();
  }, [
    accountData.uid,
    appInitialized,
    dispatch,
    existingEvent,
    isEditing,
    loggedIn,
    mixpanel,
    navigate,
  ]);

  useOnMount(() => {
    (async () => {
      const utmCampaign = searchParams.get("utm_campaign");
      const utmSource = searchParams.get("utm_source");
      const utmMedium = searchParams.get("utm_medium");
      const utmContent = searchParams.get("utm_content");
      const utmTerm = searchParams.get("utm_term");

      // trigger creation mixpanel event
      mixpanel.track("Webapp: Visited Create Event", {
        markit_utm_campaign: utmCampaign,
        markit_utm_source: utmSource,
        markit_utm_medium: utmMedium,
        markit_utm_content: utmContent,
        markit_utm_termn: utmTerm,
      });

      const photosRef = ref(firebaseStorage, "sampleEventPhotos/");
      await listAll(photosRef).then(async (listResult) => {
        const filteredImages = await filteredSampleImages(listResult);
        setSampleEventPhotos(filteredImages);
        if (!isEditing) {
          // Set the default image if:
          // 1. External event converting with no data scraped
          // 2. Markit event creation
          if (
            externalNotScraped ||
            (!isConvertingExternalEvent &&
              eventSettings.eventType === EventType.MARKIT &&
              !cloneDetails)
          ) {
            const randomImage =
              filteredImages[Math.floor(Math.random() * filteredImages.length)];
            updateEventSettings({ photoURL: randomImage });
          }
        }
      });
      setLoading(false);
    })();
  });

  useEffect(() => {
    (async () => {
      // set the form questions
      if (loggedIn === LoginState.LOGGED_IN && !isExternalLink) {
        if (existingEvent) {
          const formQuestions = await getEventFormQuestions(
            existingEvent.createdBy,
            existingEvent.formQuestions
          );
          dispatch(
            accountActions.updateCurrentEventFormQuestions(formQuestions)
          );
        }
        // add the essential question by default to event
        if (essentialQuestion && !isEditing) {
          dispatch(
            accountActions.updateCurrentEventFormQuestions([essentialQuestion])
          );
        }
      }
      setLoading(false);
    })();
  }, [
    dispatch,
    essentialQuestion,
    existingEvent,
    isEditing,
    isExternalLink,
    loggedIn,
  ]);

  /*  image selecting functions */
  const uploadImage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      return new Promise<void>(async (resolve, reject) => {
        const uploadData = await uploadMedia(event, true, false);
        if (uploadData.uploadCode !== MEDIA_UPLOAD_CODES.SUCCESS) {
          setAlertText(errorAlertText(uploadData.uploadCode, false));
          setAlertSubText(errorSubAlertText(uploadData.uploadCode));
          reject();
        } else {
          updateEventSettings({
            photoURL: uploadData.blobUrl,
          });
          setIsImageModalOpen(false);
          resolve();
        }
      });
    },
    [updateEventSettings]
  );
  /*  END image selecting functions */

  useOnMount(() => {
    (async () => {
      if (
        loggedIn === LoginState.LOGGED_IN &&
        eventSettings.stripePaymentCollector === ""
      ) {
        dispatch(fetchReadyForPayment(accountData.uid));
      }
    })();
  });

  const deleteDraftOnPress = useCallback(async () => {
    if (existingEvent) {
      dispatch(eventActions.removeEvent(existingEvent.id));
      await API.events.deleteEvent({
        eventId: existingEvent.id,
      });
      setIsDeleteDraftModalOpen(false);
      if (accountData.inCreatorMode) {
        navigate("/home/events");
      } else {
        dispatch(DataLoaders.user(accountData.uid));
        navigate(`/u/${accountData.username}`, {
          state: { username: accountData.username },
        });
      }
    }
  }, [
    accountData.inCreatorMode,
    accountData.uid,
    accountData.username,
    dispatch,
    existingEvent,
    navigate,
  ]);

  const finishOnPress = useCallback(() => {
    if (!eventReadyForPayment && isEventTicketsPaid(eventSettings)) {
      // check if readyForPayment on a paid ticketed event
      setConnectStripeModal(true);
    } else if (isExternalLink) {
      // If editing external event or filling in non-scraped external event details
      onNextExternalPress();
    } else {
      // Regular Markit event
      if (isEditing) {
        onSaveEventPress();
      } else {
        onPostEventPress();
      }
    }
  }, [
    eventReadyForPayment,
    eventSettings,
    isEditing,
    isExternalLink,
    onNextExternalPress,
    onPostEventPress,
    onSaveEventPress,
  ]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (!isDesktop) {
    return <EmptyViewStateMobile eventTheme={eventSettings.theme} />;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Helmet>
        <title>{"Create Markit Event"}</title>
        <meta name="og:title" content={"Create Markit Event"} />
        <meta name="og:description" content={"Create Markit Event"} />
      </Helmet>
      <div
        ref={refContainer}
        className="WebApp"
        style={{
          ...theme.PrimaryBG,
          transition: "0.3s",
          height: accountData.inCreatorMode ? "100vh" : "100%",
          overflow: "hidden",
        }}
      >
        {accountData.inCreatorMode ? (
          <CreatorModeTopHeader eventTheme={eventSettings.theme} />
        ) : (
          <TopHeader eventTheme={eventSettings.theme} />
        )}
        <div className="RowNormal" style={{ height: "100%" }}>
          {accountData.inCreatorMode ? (
            <CreatorModeSidebar
              morePanelVisible={morePanelVisible}
              setMorePanelVisible={setMorePanelVisible}
              eventTheme={eventSettings.theme}
            />
          ) : null}
          <div
            className="WebColumnContainer"
            style={{
              flex: 1,
              paddingTop: 48,
              paddingBottom: 100,
              overflowY: "auto",
            }}
          >
            <div className="ColumnNormal">
              <div className="WebImageContainer">
                <div
                  className="eventImageContainer"
                  onClick={() => setIsImageModalOpen(true)}
                >
                  {eventSettings.photoURL === "" && (
                    <div
                      className="eventImagePlaceholder"
                      style={{
                        border:
                          showError && eventSettings.photoURL === ""
                            ? "1px solid #DF4840"
                            : "",
                        ...theme.SecondaryBG,
                      }}
                    >
                      <div
                        style={{
                          display: "grid",
                          placeItems: "center",
                          opacity: 0.3,
                          cursor: "pointer",
                        }}
                      >
                        <Icon
                          icon={"mdi:image-outline"}
                          style={{
                            color: Colors.GRAY1,
                          }}
                          height={48}
                        />
                        <h4
                          className="AboutSubtitle"
                          style={{
                            textAlign: "center",
                            ...theme.PrimaryText,
                          }}
                        >
                          Select Event Photo
                        </h4>
                      </div>
                    </div>
                  )}
                  {/* display selected image */}
                  {eventSettings.photoURL && (
                    <img
                      className="WebImage"
                      src={eventSettings.photoURL}
                      alt={"event cover"}
                      style={{ marginBottom: "14px" }}
                    />
                  )}
                  <div
                    className="eventImageEditButton"
                    style={theme.SecondaryBG}
                  >
                    <Icon
                      icon="ion:image"
                      height={30}
                      style={{
                        color:
                          theme === LightTheme ? Colors.BLACK : Colors.WHITE,
                      }}
                    />
                  </div>
                </div>
              </div>
              <CreateEventExtraDetails
                eventSettings={eventSettings}
                updateEventSettings={updateEventSettings}
              />
              <div
                className="Container PageDesktop ColumnNormal"
                style={{ ...theme.SecondaryBG, gap: 10 }}
              >
                <RectangleButton
                  buttonLabel={
                    editingLiveEvent || (isExternalLink && !externalNotScraped)
                      ? "Save"
                      : externalNotScraped && !isConvertingExternalEvent
                      ? "Next"
                      : "Publish Event"
                  }
                  altColor={
                    editingLiveEvent || (isExternalLink && !externalNotScraped)
                      ? Colors.BLUE5
                      : Colors.PINK1
                  }
                  altBorderRadius={8}
                  altTextColor={Colors.WHITE}
                  onPress={finishOnPress}
                  loading={postingEvent}
                  disabled={postingEvent}
                />
                {!isExternalLink ? (
                  (!isEditing || (existingEvent && existingEvent.isDraft)) &&
                  loggedIn === LoginState.LOGGED_IN ? (
                    <div className="Centering" style={{ gap: "7px" }}>
                      <div
                        className="DraftButton"
                        style={{
                          ...theme.TertiaryBG,
                          color: isEditing ? Colors.RED1 : Colors.GRAY1,
                          cursor: isEditing ? "pointer" : "auto",
                        }}
                        onClick={() => {
                          isEditing && setIsDeleteDraftModalOpen(true);
                        }}
                      >
                        Delete Draft
                      </div>
                      <div
                        className="DraftButton"
                        style={{
                          ...theme.TertiaryBG,
                          ...theme.PrimaryText,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          onDraftEventPress(!isEditing);
                        }}
                      >
                        Save Draft
                      </div>
                    </div>
                  ) : null
                ) : (
                  <RectangleButton
                    buttonLabel="Cancel"
                    onPress={() =>
                      isEditing
                        ? navigate(`/e/${eventSettings.id}/editLink`, {
                            state: {
                              confirmState: true,
                              event: existingEvent,
                            },
                          })
                        : navigate(
                            onCreateLinkNavigatePath(eventSettings.eventType),
                            {
                              state: externalNotScraped
                                ? undefined
                                : {
                                    confirmState: true,
                                    event: existingEvent,
                                  },
                            }
                          )
                    }
                    altColor={Colors.GRAY6}
                    altTextColor={Colors.BLACK}
                    altBorderRadius={8}
                  />
                )}
              </div>
            </div>
            <div className="WebInfo">
              {!isEditing && eventSettings.externalLink !== "" ? (
                <StandardContainer
                  headerComp={
                    <div className="AlignedRowSpaced" style={{ width: "100%" }}>
                      <div className="ColumnNormal" style={{ gap: 4 }}>
                        <span style={{ fontSize: 14, fontWeight: "500" }}>
                          {isExternalGenericLink(eventSettings.eventType)
                            ? "Enter Link"
                            : "Event"}{" "}
                          Info
                        </span>
                        <span style={{ fontSize: 12, ...theme.SubText }}>
                          {!externalNotScraped
                            ? "We've autofilled your details based on your link."
                            : `Fill in your ${
                                isExternalGenericLink(eventSettings.eventType)
                                  ? "link details to continue."
                                  : "event details."
                              }`}
                        </span>
                      </div>
                      {!externalNotScraped ? (
                        <Icon
                          icon={"ion:checkmark-circle"}
                          height={18}
                          color={Colors.GREEN2}
                        />
                      ) : null}
                    </div>
                  }
                  theme={theme}
                />
              ) : null}
              {/* Event Title & Description */}
              <CreateEventTitleDescriptionForm
                eventSettings={eventSettings}
                updateEventSettings={updateEventSettings}
                showError={showError}
                isEditing={isEditing}
              />
              {/* Ticketing Box */}
              {eventSettings.eventType === EventType.MARKIT ? (
                <>
                  <CreateEventTickets
                    eventSettings={eventSettings}
                    updateEventSettings={updateEventSettings}
                    setIsTicketsModalOpen={setIsTicketsModalOpen}
                    setCurrTicketNumber={setCurrTicketNumber}
                    setConfirmPopupTicketType={setConfirmPopupTicketType}
                    showConnectStripeModal={() => setConnectStripeModal(true)}
                    readyForPayment={readyForPayment}
                  />
                  <div
                    className="PageDesktop Container"
                    style={theme.SecondaryBG}
                  >
                    <FormBuilderWrapper
                      isProfileQuestions={false}
                      hostId={eventSettings.createdBy}
                      theme={theme}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
        {!accountData.inCreatorMode ? (
          <Footer eventTheme={eventSettings.theme} />
        ) : null}
      </div>
      {/* Modal for setting event image */}
      {isImageModalOpen && (
        <div className="modalBackground">
          <div
            className="modalContent"
            style={{ width: "50vw", padding: 0, ...theme.TertiaryBG }}
          >
            <div
              className="AlignedRow"
              style={{
                boxSizing: "border-box",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                padding: "14px",
              }}
            >
              <div>
                <h4 className="AboutSubtitle" style={theme.PrimaryText}>
                  Choose Photo
                </h4>
                <h4
                  style={{
                    fontWeight: "400",
                    color: Colors.GRAY2,
                    fontSize: 14,
                    marginTop: 2,
                  }}
                >
                  Upload your own photo or choose from below
                </h4>
              </div>
              <Icon
                icon={"ion:close-circle"}
                height={24}
                onClick={() => setIsImageModalOpen(false)}
                style={{ color: Colors.GRAY1, cursor: "pointer" }}
              />
            </div>
            <div
              style={{
                ...theme.SecondaryBG,
                width: "100%",
                boxSizing: "border-box",
                padding: "14px",
                borderRadius: "0 0 8px 8px",
              }}
            >
              <div
                style={{
                  ...theme.TertiaryBG,
                  display: "flex",
                  flexDirection: "column",
                  padding: "14px",
                  gap: "2px",
                  borderRadius: "8px",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (fileInputRef.current) {
                    fileInputRef.current.click();
                  }
                }}
              >
                <Icon
                  icon={"mdi:image-size-select-actual"}
                  height={24}
                  style={{ color: Colors.GRAY2 }}
                />
                <h4 className="AboutSubtitle" style={theme.PrimaryText}>
                  Click here to upload
                </h4>
                <h4
                  style={{
                    fontWeight: "400",
                    color: Colors.GRAY2,
                    fontSize: 14,
                    marginTop: 2,
                  }}
                >
                  Or choose an image below. The ideal aspect ratio is 1:1.
                </h4>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={uploadImage}
                />
              </div>
              <h4
                className="AboutSubtitle"
                style={{ paddingBlock: 14, ...theme.PrimaryText }}
              >
                Photos
              </h4>
              <div className="photoGallerySample">
                {sampleEventPhotos.map((image, index) => {
                  return (
                    <img
                      alt="SampleImages"
                      src={image}
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={async () => {
                        updateEventSettings({
                          photoURL: sampleEventPhotos[index],
                        });
                        setIsImageModalOpen(false);
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Modal for setting tickets */}
      {isTicketsModalOpen ? (
        <PopupModalContainer
          theme={theme}
          headerComp={
            (currTicketNumber !== -1 ? "Edit" : "New") + " Ticket Type"
          }
          headerLeftIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingInline: "14px",
                marginBottom: "10px",
              }}
            >
              <Icon
                icon={"ion:ticket"}
                height={35}
                style={{
                  paddingTop: 1,
                  color: theme === LightTheme ? Colors.BLACK : Colors.WHITE,
                }}
              />
              <Icon
                icon={"ion:close-circle"}
                height={24}
                onClick={() => setIsTicketsModalOpen(false)}
                style={{ color: Colors.GRAY1, cursor: "pointer" }}
              />
            </div>
          }
          noExit={true}
          subHeaderComp={
            <div
              style={{
                fontWeight: "400",
                fontSize: 14,
                color: Colors.GRAY1,
                paddingTop: 10,
              }}
            >
              Full flexibility with free, paid, or request tickets.
            </div>
          }
          valueComp={
            <>
              <CreateEventCustomTicketOption
                eventSettings={eventSettings}
                updateEventSettings={updateEventSettings}
                ticketOption={currTicketNumber}
                onExit={(isAdding: boolean) => {
                  setIsTicketsModalOpen(false);
                  if (isAdding) {
                    setConfirmPopupTicketType(BinaryConfirmActions.AFFIRMATIVE);
                  }
                }}
              />
            </>
          }
          closeModal={() => setIsTicketsModalOpen(false)}
        />
      ) : null}
      <ConfirmDeleteModal
        heading="Are you sure you want to delete this event draft?"
        subtext="You cannot undo this."
        deleteButtonText="Delete"
        hideModal={!isDeleteDraftModalOpen}
        setIsVisible={setIsDeleteDraftModalOpen}
        deleteOnPress={deleteDraftOnPress}
        theme={theme}
      />
      {confirmPopupTicketType ? (
        <ConfirmActionPopup
          title="Ticket Type Added"
          negativeTitle="Ticket Type Deleted"
          isNegative={confirmPopupTicketType === BinaryConfirmActions.NEGATIVE}
          onDisappear={() =>
            setConfirmPopupTicketType(BinaryConfirmActions.NONE)
          }
        />
      ) : null}
      {paymentFailedVisible ? (
        <PaymentFailedButtonModal
          location="Create Event"
          isVisible={paymentFailedVisible}
          setIsVisible={() => {
            setPaymentFailedVisible(false);
          }}
          addOnClose={() => navigate("/home")}
        />
      ) : null}
      {connectStripeModal ? (
        <EnablePaidTicketsModal
          isVisible={connectStripeModal}
          setIsVisible={setConnectStripeModal}
          theme={theme}
        />
      ) : null}
      <AlertContainer
        headerComp={
          <div className="ColumnNormal" style={{ gap: 4 }}>
            <Icon icon="mdi-image-remove" height={40} />
            <span style={{ fontSize: 16, fontWeight: "500" }}>{alertText}</span>
          </div>
        }
        subHeaderComp={alertSubText}
        theme={theme}
        closeModal={() => {
          setAlertText("");
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        }}
        hideModal={alertText === ""}
      />
    </LocalizationProvider>
  );
};
