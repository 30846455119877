import { Icon } from "@iconify/react";
import { Colors } from "../../../utils/colors";
import { generateTrackingLinksOnPress } from "../../../utils/trackingUtils";
import { Event } from "@markit/common.types";
import { MixpanelContext } from "../../../context/AnalyticsService";
import { useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import PopupModalContainer from "../../Containers/PopupModalContainer";
import RectangleButton from "../../Buttons/RectangleButton";
import CustomTextField from "../../CustomTextField";
import { LinkCreationConfirmActions } from "../../../screens/Event/EventDashboard";

type TrackingLinkModalProps = {
  event: Event;
  linkTrackerId: string | undefined;
  setLinkTrackerId: (linkTrackerId: string | undefined) => void;
  trackingLinkModalText: string;
  setTrackingLinkModalText: (trackingLinkModalText: string) => void;
  setTrackingLinkModalVisible: (trackingLinkModalVisible: boolean) => void;
  openShareModal: (source: string) => void;
  setIsTrackingLinksView: (isTrackingLinksView: boolean) => void;
  setConfirmPopupCreationType: (
    creationType: LinkCreationConfirmActions
  ) => void;
};

export const TrackingLinkModal = (props: TrackingLinkModalProps) => {
  const {
    event,
    linkTrackerId,
    setLinkTrackerId,
    trackingLinkModalText,
    setTrackingLinkModalText,
    setTrackingLinkModalVisible,
    openShareModal,
    setIsTrackingLinksView,
    setConfirmPopupCreationType,
  } = props;
  const mixpanel = useContext(MixpanelContext);
  const { account } = useSelector(getAccountState);
  const { accountData } = account;

  const closeModal = useCallback(() => {
    setLinkTrackerId(undefined);
    setTrackingLinkModalText("");
    setTrackingLinkModalVisible(false);
  }, [setLinkTrackerId, setTrackingLinkModalText, setTrackingLinkModalVisible]);

  const generateOrSaveChanges = useCallback(async () => {
    await generateTrackingLinksOnPress(
      event,
      linkTrackerId,
      trackingLinkModalText,
      mixpanel,
      accountData.uid
    );
    if (!linkTrackerId) {
      setConfirmPopupCreationType(LinkCreationConfirmActions.LINK_CREATION);
    }
    setLinkTrackerId(undefined);
    setTrackingLinkModalText("");
    setTrackingLinkModalVisible(false);
    setIsTrackingLinksView(true);
    openShareModal("tracking link");
  }, [
    event,
    linkTrackerId,
    trackingLinkModalText,
    mixpanel,
    accountData.uid,
    setLinkTrackerId,
    setTrackingLinkModalText,
    setTrackingLinkModalVisible,
    setIsTrackingLinksView,
    openShareModal,
    setConfirmPopupCreationType,
  ]);

  return (
    <PopupModalContainer
      headerComp={linkTrackerId ? "Edit Tracking Link " : "New Tracking Link"}
      headerLeftIcon={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingInline: "14px",
            marginBottom: "10px",
          }}
        >
          <Icon
            icon={"ion:analytics"}
            height={35}
            style={{
              color: Colors.BLACK,
            }}
          />
        </div>
      }
      subHeaderComp={
        linkTrackerId ? undefined : (
          <div
            style={{
              fontWeight: "400",
              fontSize: 14,
              color: Colors.GRAY1,
              paddingTop: 10,
            }}
          >
            Tracking links allow you to collect data on link opens and
            conversions for your events.
          </div>
        )
      }
      noExit={true}
      closeOnOutsidePress
      valueComp={
        <>
          <CustomTextField
            value={trackingLinkModalText}
            placeholder="Name your tracking link..."
            inputMode="text"
            borderRadius={8}
            backgroundColor={Colors.WHITE}
            onChange={(change: any) => {
              setTrackingLinkModalText(change.target.value);
            }}
            altMarginBottom={0}
            maxLength={42}
          />
          <div className="AlignedRowRight">
            <span
              style={{
                fontWeight: 400,
                fontSize: 14,
                color: Colors.GRAY1,
                marginTop: 4,
              }}
            >
              {trackingLinkModalText.length}/42
            </span>
          </div>
          <RectangleButton
            buttonLabel={linkTrackerId ? "Save" : "Create Tracking Link"}
            onPress={generateOrSaveChanges}
            altColor={Colors.BLACK}
            disabled={trackingLinkModalText.trim().length === 0}
            altPaddingVert={14}
            containerStyles={{ marginTop: 14 }}
          />
        </>
      }
      closeModal={closeModal}
    />
  );
};
