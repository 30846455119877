import { ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";

interface ScrollToTopProps {
  children: ReactNode;
}

/**
 * Wrapper that will automatically scroll pages to top after navigation. used
 * in App.tsx to apply this logic to all routes in the app.
 * If users want to be able to stay on the same spot when hitting back button
 * then remove the scrollrestoration/window history logic.
 **/
const ScrollToTop = ({ children }: ScrollToTopProps) => {
  const { pathname } = useLocation();

  useEffect(() => {
    const canControlScrollRestoration = "scrollRestoration" in window.history;
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = "manual";
    }

    window.scrollTo(0, 0);
  }, [pathname]);

  return <>{children}</>;
};

export default ScrollToTop;
