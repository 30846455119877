import { Colors } from "../../../../utils/colors";
import { MassText } from "@markit/common.types";
import { ThemeStyle } from "../../../../hooks/useTheme";
import CustomCheckbox from "../../../CustomCheckbox";
import RectangleButton from "../../../Buttons/RectangleButton";
import { useCallback, useState } from "react";
import PopupModalContainer from "../../../Containers/PopupModalContainer";
import { Icon } from "@iconify/react";
import { API } from "../../../../API";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../redux/slices/accountSlice";
import { LessThanDate } from "@markit/common.utils";

type MassTextCatchUpProps = {
  massText: MassText;
  catchUpChecked: boolean;
  setCatchUpChecked: (catchUpChecked: boolean) => void;
  theme?: ThemeStyle;
};

export const MassTextCatchUp = (props: MassTextCatchUpProps) => {
  const { massText, catchUpChecked, setCatchUpChecked, theme } = props;
  const { uid } = useSelector(getAccountState).account.accountData;
  const [modalVisible, setModalVisible] = useState(false);

  // for an existing event text, not creating a new one
  const updateCatchUpSending = useCallback(async () => {
    const updatedMassText: MassText = {
      ...massText,
      catchUpEnabled: !massText.catchUpEnabled,
    };
    await API.text
      .updateMassTextMessage({
        userId: uid,
        massText: updatedMassText,
      })
      .then((response) => {
        if (!response.success) {
          alert(
            "There was an issue updating the catch up sending. Please try again later."
          );
          return;
        }
        setModalVisible(false);
      });
  }, [massText, uid]);

  return (
    <>
      <div
        className="EventDashboardContainerSpaceBetween"
        style={{ marginTop: "14px", ...theme?.TertiaryBG }}
      >
        <div className="AlignedRowSpaced">
          <div className="ColumnNormal" style={{ gap: 3 }}>
            <span
              className="EventDashboardContentHeader"
              style={theme?.PrimaryText}
            >
              *Catch-up sending
            </span>
            <div
              className="EventDashboardContentDescription"
              style={{ color: Colors.GRAY1 }}
            >
              Anyone that falls under the selected categories will receive this
              text when they get a ticket.
            </div>
          </div>
          <CustomCheckbox
            checked={catchUpChecked}
            onChange={() => {
              if (LessThanDate(massText.sentAt, new Date().toISOString())) {
                setModalVisible(true);
              } else {
                setCatchUpChecked(!catchUpChecked);
              }
            }}
            sx={{ padding: 0 }}
            altColor={Colors.BLUE5}
          />
        </div>
      </div>
      {modalVisible ? (
        <PopupModalContainer
          headerComp={
            <div>
              <Icon icon="ion:play-forward" height={40} />
              <h1
                className="AboutSubtitle"
                style={{ fontSize: 17, paddingTop: 4 }}
              >
                Catch-up sending
              </h1>
            </div>
          }
          subHeaderComp={
            <div style={{ marginTop: 7 }}>
              <h1
                className="AboutSubtitle"
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  color: Colors.GRAY1,
                }}
              >
                Are you sure you want to turn {catchUpChecked ? "off" : "on"}{" "}
                catch-up sending for this text?
              </h1>
            </div>
          }
          valueComp={
            <div className="ColumnNormal" style={{ gap: 14 }}>
              <RectangleButton
                buttonLabel="Yes"
                onPress={updateCatchUpSending}
                altTextColor={Colors.WHITE}
                altColor={Colors.BLACK}
              />
              <RectangleButton
                buttonLabel="Cancel"
                onPress={() => setModalVisible(false)}
                altTextColor={Colors.RED1}
                altColor={Colors.GRAY6}
              />
            </div>
          }
          noExit
          closeModal={() => {}}
          theme={theme}
        />
      ) : null}
    </>
  );
};
