import { Dropdown, DropdownProps, MenuProps } from "antd";
import { CSSProperties, useMemo } from "react";
import DropdownMenuLabel from "./DropdownMenuLabel";

export type DropdownMenuItem = {
  title: string;
  icon?: string;
  key: string;
  onPress: () => void;
  isDestructive?: boolean;
  disabled?: boolean;
  loading?: boolean;
};

type DropdownMenuProps = {
  dropdownItems: DropdownMenuItem[];
  trigger?: DropdownProps["trigger"];
  placement?: DropdownProps["placement"];
  children: React.ReactNode;
  isOpen?: boolean; // Used if we want to manage state on the dropdown child (ie. up and down arrow)
  onOpenChange?: (
    open: boolean,
    info?: {
      source: "trigger" | "menu";
    }
  ) => void;
  altWidth?: number;
  topPadding?: number; // gap between component and the dropdown menu overlay
  overlayStyle?: CSSProperties;
  disabled?: boolean;
};

export const DropdownMenu = (props: DropdownMenuProps) => {
  const {
    dropdownItems,
    trigger,
    placement,
    children,
    onOpenChange,
    isOpen,
    disabled,
    altWidth,
    topPadding,
    overlayStyle,
  } = props;

  const items: MenuProps["items"] = useMemo(
    () =>
      dropdownItems.map((item) => {
        return {
          label: (
            <DropdownMenuLabel
              label={item.title}
              icon={item.icon}
              width={altWidth}
              isDestructive={item.isDestructive}
              loading={item.loading}
            />
          ),
          key: item.key,
          disabled: item.disabled,
        };
      }),
    [dropdownItems, altWidth]
  );

  const onClick: MenuProps["onClick"] = ({ key }) => {
    const foundItem = dropdownItems.find((item) => item.key === key);
    if (foundItem) {
      foundItem.onPress();
    }
  };

  return (
    <Dropdown
      menu={{ items, onClick }}
      trigger={trigger}
      disabled={disabled}
      placement={placement}
      overlayStyle={{ paddingTop: topPadding ?? 7, ...overlayStyle }}
      onOpenChange={(isOpen: boolean, info) =>
        onOpenChange ? onOpenChange(isOpen, info) : undefined
      }
      open={isOpen}
    >
      <div style={{ cursor: "pointer" }}>{children}</div>
    </Dropdown>
  );
};
