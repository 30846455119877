import { useState } from "react";
import { Colors } from "../../utils/colors";
import { useTheme } from "../../hooks/useTheme";
import { Icon } from "@iconify/react";

type SettingsOptionProps = {
  title: string;
  subtext: string;
  iconName: string;
  onPress: () => void;
  disabled: boolean;
  altColor?: string;
};

const SettingsOption = (props: SettingsOptionProps) => {
  const { title, subtext, iconName, onPress, disabled, altColor } = props;
  const { theme } = useTheme();

  const [onHover, setOnHover] = useState(false);

  const styles = {
    iconContainer: {
      width: 45,
      height: 45,
      borderRadius: 12,
      backgroundColor:
        onHover && !disabled ? altColor || Colors.BLACK : Colors.GRAY6,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: 10,
    },

    option: {
      padding: 14,
    },
  };

  return (
    <div
      className="AlignedRowSpaced"
      style={{
        ...styles.option,
        color: disabled ? Colors.GRAY2 : altColor || Colors.BLACK,
      }}
    >
      <div>
        <span
          style={{
            fontSize: 14,
            fontWeight: 500,
            color: disabled ? Colors.GRAY2 : altColor || Colors.BLACK,
          }}
        >
          {title}
        </span>
        {subtext ? (
          <div>
            <span
              style={{
                fontSize: 12,
                color: disabled
                  ? Colors.GRAY2
                  : altColor || theme.SubText.color,
              }}
            >
              {subtext}
            </span>
          </div>
        ) : null}
      </div>
      <div
        className="AlignedRowSelect"
        style={{
          ...styles.iconContainer,
          pointerEvents: disabled ? "none" : "auto",
        }}
        onClick={onPress}
        onMouseEnter={() => setOnHover && setOnHover(true)}
        onMouseLeave={() => setOnHover && setOnHover(false)}
      >
        <Icon
          icon={iconName}
          height={25}
          color={
            disabled
              ? Colors.GRAY1
              : onHover
              ? Colors.WHITE
              : altColor || Colors.BLACK
          }
        />
      </div>
    </div>
  );
};

export default SettingsOption;
