import "../../css/Buttons/RectangleButtonStyles.css";
import { Colors } from "../../utils/colors";

type EmptyStateButtonProps = {
  title: string;
  description?: string;
  icon?: React.ReactNode;
  iconBox?: number;
  btnText?: string;
  onPress?: () => void;
  disabled?: boolean;
  secondaryAction?: React.ReactNode;
  containerStyles?: React.CSSProperties;
  iconBackgroundColor?: string;
};

const EmptyStateButton = (props: EmptyStateButtonProps) => {
  const {
    title,
    description,
    icon,
    iconBox,
    btnText,
    disabled,
    onPress,
    secondaryAction,
    containerStyles,
    iconBackgroundColor,
  } = props;

  const styles = {
    title: {
      fontWeight: "500",
      paddingBottom: 7,
      color: Colors.BLACK,
    },

    subtext: {
      color: Colors.GRAY2,
      fontSize: 11,
      paddingHorizontal: 40,
    },

    iconContainer: {
      justifyContent: "center",
      alignItems: "center",
      width: iconBox ?? 40,
      height: iconBox ?? 40,
      padding: 10,
      borderRadius: 16,
      backgroundColor: iconBackgroundColor ?? Colors.GRAY6,
    },
  };

  return (
    <div className="AlignedColumn" style={containerStyles}>
      {icon ? (
        <div className="AlignedRow" style={styles.iconContainer}>
          {icon}
        </div>
      ) : null}
      <div
        style={{ paddingBlock: 14, alignItems: "center" }}
        className="ColumnNormal"
      >
        <span style={styles.title}>{title}</span>
        {description ? (
          <span style={{ ...styles.subtext, textAlign: "center", width: 300 }}>
            {description}
          </span>
        ) : null}
      </div>
      {btnText ? (
        <div
          className="ProfilePageBlackButtonText"
          style={{
            color: disabled ? Colors.GRAY1 : "",
            cursor: "pointer",
            pointerEvents: disabled ? "none" : "all",
          }}
          onClick={onPress}
        >
          <span style={{ fontSize: 14, fontWeight: "500" }}>{btnText}</span>
        </div>
      ) : null}
      <div style={{ paddingTop: 14, cursor: "pointer" }}>{secondaryAction}</div>
    </div>
  );
};

export default EmptyStateButton;
