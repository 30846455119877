import { API } from "../API";
import { Mixpanel } from "../context/AnalyticsService";
import { DefaultLinkTrackerName, Event } from "@markit/common.types";
import { getCountFromServer, query, where } from "../firebase";
import { getUserEventLinkTrackersRef } from "./FirebaseUtils";

/**
 * Handles the link open for all types of link tracking provided the linkId.
 * Called when the user views the event link with the link id attached.
 * 1. Mass Text Link Tracking:
 *    Updates the specified user in the link data their TextRecipient open tracking.
 * 2. Event Link Tracking:
 *    Updates the EventLinkTracker related to the link and adds an open action.
 */
export const updateLinkTrackerOpens = async (
  eventId: string,
  eventUserId: string,
  linkId: string
) => {
  try {
    if (linkId !== "") {
      await API.tracking.updateLinkTrackerOpens({
        eventId: eventId,
        eventUserId: eventUserId,
        linkId: linkId,
      });
    }
  } catch (err: any) {
    console.error(err.message);
  }
};

/**
 * Increments the num opens field on the default markit event tracker used for
 * tracking an event's total link views.
 */
export const updateTotalEventLinkViews = async (
  eventId: string,
  eventUserId: string
) => {
  try {
    await API.tracking.updateTotalEventLinkViews({
      eventId: eventId,
      eventUserId: eventUserId,
    });
  } catch (err: any) {
    console.error(err.message);
  }
};

// callback function for generating / saving changes to tracking links
export const generateTrackingLinksOnPress = async (
  event: Event,
  linkTrackerId: string | undefined,
  trackingLinkModalText: string,
  mixpanel: Mixpanel,
  userId: string
) => {
  if (linkTrackerId) {
    await API.tracking
      .updateEventLinkTracker({
        userId: event.createdBy,
        eventId: event.id,
        linkTrackerId: linkTrackerId,
        newTitle: trackingLinkModalText,
      })
      .then(() => {
        mixpanel.track("Edit Event Link Tracker", {
          new_link_name: trackingLinkModalText,
          event_id: event.id,
        });
      });
  } else {
    await API.tracking
      .generateNewEventLinkTracker({
        title: trackingLinkModalText,
        eventUserId: event.createdBy,
        eventId: event.id,
        sentBy: userId,
      })
      .then(() => {
        mixpanel.track("Generate New Event Link Tracker", {
          link_name: trackingLinkModalText,
          event_id: event.id,
        });
      });
  }
};

// gets the number of event link trackers a user has for an event
export const getTotalNumUserEventLinkTrackers = async (
  eventUserId: string,
  eventId: string
): Promise<number> => {
  const userEventLinkTrackersRef = await getUserEventLinkTrackersRef(
    eventUserId,
    eventId
  );
  // filter out the default event tracker
  const query_ = query(
    userEventLinkTrackersRef,
    where("alias", "!=", DefaultLinkTrackerName.DEFAULT_EVENT_LINK_TRACKER)
  );
  const snapshot = await getCountFromServer(query_);
  return snapshot.data().count;
};
