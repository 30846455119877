import { Icon } from "@iconify/react";
import "../../css/Containers/ContainerStyles.css";
import { Colors } from "../../utils/colors";
import { useCallback, useState } from "react";
import HelpModal from "../Containers/HelpModal";

const ContactSupportButton = () => {
  const [helpModal, setHelpModal] = useState(false);

  const contactSupportOnPress = useCallback(() => {
    setHelpModal(true);
  }, []);

  return (
    <>
      <div
        className="AlignedRowSelect"
        style={{ gap: 2 }}
        onClick={contactSupportOnPress}
      >
        <span style={{ fontSize: 12, fontWeight: 500 }}>Contact Support</span>
        <Icon
          icon="ion:arrow-forward"
          height={15}
          color={Colors.BLACK}
          style={{
            transform: "rotateY(0deg) rotate(-45deg)",
            marginBottom: -1,
          }}
        />
      </div>
      {helpModal ? (
        <HelpModal showModal={helpModal} setShowModal={setHelpModal} />
      ) : null}
    </>
  );
};

export default ContactSupportButton;
