import { useEffect, useRef } from "react";
import { useOnMount } from "../utils/useOnMount";

type Callback<T> = (latestDeps: T[]) => void;

function useOnUnmount<T>(callback: Callback<T>, dependencies: T[] = []): void {
  // Create a ref to hold the latest values of dependencies
  const latestDepsRef = useRef<T[]>(dependencies);

  // Update the ref whenever dependencies change
  useEffect(() => {
    latestDepsRef.current = dependencies;
  }, [dependencies]);

  useOnMount(() => {
    // Cleanup function that uses the latest dependencies
    return () => {
      callback(latestDepsRef.current);
    };
  }); // Empty dependency array means this effect only runs once
}

export default useOnUnmount;
