import {
  FormAnswersV2,
  FormInputType,
  FormResponseV2,
  SavedFormQuestion,
} from "@markit/common.types";
import { useEffect, useState } from "react";
import {
  getFormResponseAnswers,
  questionInputName,
} from "../../utils/eventUtils/formUtils";
import { Colors } from "../../utils/colors";
import { truncateString } from "@markit/common.utils";
import CustomLinkify from "../Links/CustomLinkify";
import { useTheme } from "../../hooks/useTheme";

type FormResponseContainerProps = {
  eventId: string;
  item: SavedFormQuestion | undefined;
  clicked: boolean;
  onPress: () => void;
  individualUserResponse?: FormResponseV2;
  containerStyles?: React.CSSProperties;
};

export const FormResponseContainer = (props: FormResponseContainerProps) => {
  const {
    eventId,
    item,
    clicked,
    onPress,
    individualUserResponse,
    containerStyles,
  } = props;
  const { theme } = useTheme();

  const [responseAnswers, setResponseAnswers] = useState<FormAnswersV2[]>([]);

  const styles = {
    subText: {
      color: theme.SubText.color,
      fontSize: 12,
    },
  };

  useEffect(() => {
    (async () => {
      const formResponseAnswers = await getFormResponseAnswers(
        eventId,
        item ? item.id : ""
      );
      setResponseAnswers(formResponseAnswers);
    })();
  }, [eventId, item]);

  return (
    <div
      className="ColumnNormal"
      style={{
        padding: "12px",
        borderRadius: "8px",
        backgroundColor: clicked ? Colors.GRAY6 : Colors.WHITE,
        cursor: "auto",
        ...containerStyles,
      }}
      onClick={onPress}
    >
      <div style={{ display: "flex", cursor: "auto" }}>
        <span style={styles.subText}>
          {questionInputName(
            item ? item.formInput.inputType : FormInputType.EMAIL
          )}
        </span>
        {item && item.formInput.isRequired && (
          <span
            style={{ color: Colors.RED1, fontSize: "12px", paddingLeft: 7 }}
          >
            *Required
          </span>
        )}
      </div>
      <hr />
      <span
        style={{
          color: Colors.BLACK,
          fontSize: "14px",
          fontWeight: "600",
          marginBottom: "14px",
        }}
      >
        {item?.formInput.label}
      </span>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "14px",
          cursor: "auto",
        }}
      >
        {item?.formInput.inputType === FormInputType.TEXT_INPUT &&
          (individualUserResponse ? (
            <CustomLinkify>
              <span
                style={{
                  ...(individualUserResponse.answers[item.id] &&
                  individualUserResponse.answers[item.id].every(
                    (response) => response === ""
                  )
                    ? theme.SubText
                    : theme.PrimaryText),
                  fontSize: "12px",
                }}
              >
                {individualUserResponse.answers[item.id] &&
                individualUserResponse.answers[item.id].every(
                  (response) => response === ""
                )
                  ? "No Response Collected"
                  : individualUserResponse.answers[item.id].join(", ")}
              </span>
            </CustomLinkify>
          ) : (
            <span style={styles.subText}>
              {item.formInput.placeholder || "Answer here..."}
            </span>
          ))}
        {item?.formInput.inputType === FormInputType.CHECK_BOX ||
        item?.formInput.inputType === FormInputType.RADIO ||
        item?.formInput.inputType === FormInputType.PHONE
          ? item?.formInput.options.map((option: string, index: number) => {
              const isSelected =
                individualUserResponse !== undefined &&
                individualUserResponse.answers[item.id] &&
                individualUserResponse.answers[item.id].some(
                  (answer) => answer === option
                );
              return (
                <div
                  key={index}
                  style={{
                    marginBottom: 5,
                    display: "flex",
                    alignItems: "center",
                    cursor: "auto",
                  }}
                >
                  {isSelected ? (
                    <span
                      style={{
                        color: Colors.BLACK,
                        fontSize: "12px",
                        marginRight: 10,
                      }}
                    >
                      Selected
                    </span>
                  ) : individualUserResponse === undefined ? (
                    <span
                      style={{
                        color: Colors.BLACK,
                        fontSize: "12px",
                        marginRight: "7px",
                      }}
                    >
                      {responseAnswers.reduce(
                        (prev, curr) =>
                          prev +
                          (curr[item.id] && curr[item.id].includes(option)
                            ? 1
                            : 0),
                        0
                      )}
                    </span>
                  ) : null}
                  <span
                    style={{
                      color: isSelected ? Colors.BLACK : Colors.GRAY1,
                      fontSize: "12px",
                    }}
                  >
                    {truncateString(option, 40, "...")}
                  </span>
                </div>
              );
            })
          : null}
        {item?.formInput.inputType === FormInputType.EMAIL &&
          (individualUserResponse ? (
            <CustomLinkify>
              <span
                style={{
                  ...(individualUserResponse.answers[item.id] &&
                  individualUserResponse.answers[item.id].every(
                    (response) => response === ""
                  )
                    ? theme.SubText
                    : theme.PrimaryText),
                  fontSize: "12px",
                }}
              >
                {individualUserResponse.answers[item.id] &&
                individualUserResponse.answers[item.id].every(
                  (response) => response === ""
                )
                  ? "No Response Collected"
                  : individualUserResponse.answers[item.id].join(", ")}
              </span>
            </CustomLinkify>
          ) : (
            <div style={{ fontSize: "12px" }}>Enter email here...</div>
          ))}
      </div>
    </div>
  );
};
