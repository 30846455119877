import { parseISO } from "date-fns";

export const formatAMPM = (date: Date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  let minutesStr: string = minutes < 10 ? "0" + minutes : minutes.toString();
  var strTime = hours + ":" + minutesStr + " " + ampm;
  return strTime;
};

export const GetTime = (timestamp: string): string => {
  if (timestamp) {
    const date = parseISO(timestamp);
    return formatAMPM(date);
  } else {
    return "";
  }
};

/**
 * Given a timestamp, convert to YYYY-MM-DD format
 * Thank you for time zone fixes:
 * https://stackoverflow.com/questions/23593052/format-javascript-date-as-yyyy-mm-dd
 */
export const toISO8601 = (timestamp: string) => {
  let date = new Date(timestamp);
  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() - offset * 60 * 1000);
  return date.toISOString().split("T")[0];
};

/**
 * Given a timestamp, convert to HH:MM format
 */
export const formatHourMinute = (timestamp: string) => {
  const pad = (time: number) => String(time).padStart(2, "0");
  const date = new Date(timestamp);
  return pad(date.getHours()) + ":" + pad(date.getMinutes());
};

export const GetNumericDate = (timestamp: string): string => {
  if (!timestamp) return "";

  const date = parseISO(timestamp);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();

  return (
    (month < 10 ? "0" : "") +
    month +
    "/" +
    (day < 10 ? "0" : "") +
    day +
    "/" +
    year
  );
};
