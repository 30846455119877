import { useCallback, useEffect, useState } from "react";
import { getUserFollowersRef } from "../../utils/FirebaseUtils";
import { getCountFromServer, query, where } from "../../firebase";
import { useDispatch, useSelector } from "react-redux";
import {
  accountActions,
  getAccountState,
  LoginState,
} from "../../redux/slices/accountSlice";
import { FollowerStatus } from "@markit/common.types";

type useLiveUpdatingAudienceAnalyticsProps = {
  full: boolean; // should only call full = false once in application since it writes a snapshot to redux (currently in useInitialAppLoad)
};

export const useLiveUpdatingAudienceAnalytics = (
  props: useLiveUpdatingAudienceAnalyticsProps
) => {
  const { full } = props;
  const dispatch = useDispatch();
  const { accountData, loggedIn } = useSelector(getAccountState).account;
  const [liveTotalContacts, setLiveTotalContacts] = useState<number>(0);
  const [liveTotalSubscribed, setLiveTotalSubscribed] = useState<number>(0);
  const [liveTotalUnsubscribed, setLiveTotalUnsubscribed] = useState<number>(0);
  const [liveTotalRemoved, setLiveTotalRemoved] = useState<number>(0);
  const [loaded, setLoaded] = useState(false);

  const reloadCount = useCallback(() => setLoaded(false), []);

  useEffect(() => {
    (async () => {
      if (
        loggedIn === LoginState.LOGGED_IN &&
        accountData.uid !== "" &&
        !loaded
      ) {
        // total subscribed
        const followersRef = getUserFollowersRef(accountData.uid);
        const totalSubscribedQuery = query(
          followersRef,
          where("status", "==", FollowerStatus.SUBSCRIBED)
        );
        const snapshot = await getCountFromServer(totalSubscribedQuery);
        setLiveTotalSubscribed(snapshot.data().count); // Accurate server count
        dispatch(accountActions.setNumFollowers(snapshot.data().count));

        if (full) {
          // total contacts
          const contactsSnapshot = await getCountFromServer(followersRef);
          setLiveTotalContacts(contactsSnapshot.data().count); // Accurate server count

          // total unsubscribed
          const totalUnsubscribedQuery = query(
            followersRef,
            where("status", "==", FollowerStatus.UNSUBSCRIBED)
          );
          const unsubscribedSnapshot = await getCountFromServer(
            totalUnsubscribedQuery
          );
          setLiveTotalUnsubscribed(unsubscribedSnapshot.data().count); // Accurate server count

          // total removed
          const totalRemovedQuery = query(
            followersRef,
            where("status", "==", FollowerStatus.REMOVED)
          );
          const removedSnapshot = await getCountFromServer(totalRemovedQuery);
          setLiveTotalRemoved(removedSnapshot.data().count); // Accurate server count
        }
        setLoaded(true);
      }
      // need to reset loaded to false if you logout so that it'll get called again when you login again
      if (loggedIn === LoginState.LOGGED_OUT) {
        setLoaded(false);
      }
    })();
  }, [accountData.uid, dispatch, full, loaded, loggedIn]);

  return {
    liveTotalContacts,
    liveTotalSubscribed,
    liveTotalUnsubscribed,
    liveTotalRemoved,
    loaded,
    reloadCount,
  };
};
