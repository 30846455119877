import { MassText, MassTextType } from "@markit/common.types";
import { MassTextSendTime } from "../Message/MassTextSendTime";
import { ThemeStyle } from "../../../../hooks/useTheme";
import { MassTextCatchUp } from "../Message/MassTextCatchUp";
import { useEffect, useMemo } from "react";
import IntermediaryModalContainer from "../../../Containers/IntermediaryModalContainer";
import { ScheduleTextInfo } from "../MassTextsMessaging";

export type MassTextsSendScheduleProps = {
  massText: MassText;
  scheduleTextInfo: ScheduleTextInfo;
  setScheduleTextInfo: (scheduleTextInfo: ScheduleTextInfo) => void;
  isEditing: boolean;
  theme?: ThemeStyle;
};

const MassTextsSendSchedule = (props: MassTextsSendScheduleProps) => {
  const { massText, scheduleTextInfo, setScheduleTextInfo, isEditing, theme } =
    props;

  const isEventText = useMemo(
    () => massText.type === MassTextType.EVENTTEXT,
    [massText.type]
  );

  // update the local send state
  useEffect(() => {
    setScheduleTextInfo({
      sendTime: new Date(massText.sentAt),
      scheduleToggle: massText.scheduled,
      catchUpChecked: massText.catchUpEnabled,
    });
  }, [
    massText.catchUpEnabled,
    massText.scheduled,
    massText.sentAt,
    setScheduleTextInfo,
  ]);

  return (
    <IntermediaryModalContainer
      body={
        <div className="ColumnNormal" style={{ height: "100%" }}>
          <MassTextSendTime
            scheduleTextInfo={scheduleTextInfo}
            setScheduleTextInfo={setScheduleTextInfo}
            isEditing={isEditing}
            theme={theme}
          />
          {isEventText ? (
            <MassTextCatchUp
              massText={massText}
              catchUpChecked={scheduleTextInfo.catchUpChecked}
              setCatchUpChecked={(catchUp: boolean) =>
                setScheduleTextInfo({
                  ...scheduleTextInfo,
                  catchUpChecked: catchUp,
                })
              }
              theme={theme}
            />
          ) : null}
        </div>
      }
    />
  );
};

export default MassTextsSendSchedule;
