import { put, takeEvery } from "@redux-saga/core/effects";
import { createAction } from "@reduxjs/toolkit";
import {
  initialUrlParamState,
  urlParamsActions,
  UrlParamsState,
} from "../slices/urlParamSlice";

/**
 * On app load, calls the urlParamActions.setUrlState action to initialize the url parameters.
 */
function* initializeAppFn() {
  try {
    const params = getUrlParams();
    yield put(urlParamsActions.setUrlState(params));
  } catch (error) {
    console.error(error);
  }
}
export function* initializationSaga() {
  yield takeEvery(initializeApp.type, initializeAppFn);
}

export const initializeApp = createAction("initializeApp");

function getUrlParams() {
  const urlParams = new URLSearchParams(window.location.search);
  const pathParams = window.location.pathname.substring(1).split("/");
  const params = Object.keys(initialUrlParamState).reduce(
    (currSum, currKey) => ({
      ...currSum,
      [currKey]: urlParams.has(currKey)
        ? urlParams.get(currKey)
        : pathParams.findIndex((path) => path === currKey) !== -1
        ? pathParams[pathParams.findIndex((path) => path === currKey) + 1]
        : currSum[currKey as keyof UrlParamsState],
    }),
    initialUrlParamState
  );

  return params;
}

export function getUrlParamsFromString(pathname: string) {
  const urlParams = new URLSearchParams(window.location.search);
  const pathParams = pathname.substring(1).split("/");
  const params = Object.keys(initialUrlParamState).reduce(
    (currSum, currKey) => ({
      ...currSum,
      [currKey]: urlParams.has(currKey)
        ? urlParams.get(currKey)
        : pathParams.findIndex((path) => path === currKey) !== -1
        ? pathParams[pathParams.findIndex((path) => path === currKey) + 1]
        : currSum[currKey as keyof UrlParamsState],
    }),
    initialUrlParamState
  );

  return params;
}
