import "../../css/Containers/ContainerStyles.css";
import { Colors } from "../../utils/colors";
import { Icon } from "@iconify/react";

type GradientButtonProps = {
  title: string;
  subtext?: React.ReactNode;
  onPress: () => void;
  gradientCss: string;
};

const GradientButton = (props: GradientButtonProps) => {
  const { title, subtext, onPress, gradientCss } = props;

  return (
    <div
      style={{ borderRadius: 8, padding: 10 }}
      className={`AlignedRowSpacedSelect ${gradientCss}`}
      onClick={onPress}
    >
      <div className="ColumnNormalSelect" style={{ gap: 3 }}>
        <span
          style={{
            color: Colors.WHITE,
            fontSize: 14,
            fontWeight: "500",
          }}
        >
          {title}
        </span>
        {subtext}
      </div>
      <Icon icon="mdi:chevron-right" height={26} color={Colors.WHITE} />
    </div>
  );
};

export default GradientButton;
