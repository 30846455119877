import React, { useCallback, useMemo, useState } from "react";
import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import StandardBorderedContainer from "../Containers/StandardBorderedContainer";
import ContactSupportButton from "../Buttons/ContactSupportButton";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";
import {
  IntegrationPhoneContact,
  IntegrationPhoneContactState,
} from "@markit/common.types";
import ValidContactsPopupPanel from "../CreatorDashboard/CreatorProfile/ImportContacts/ValidContactsPopupPanel";

type IntegrationSummaryProps = {
  phoneContacts: IntegrationPhoneContact[];
  numEvents: number;
};

const IntegrationSummary = (props: IntegrationSummaryProps) => {
  const { phoneContacts, numEvents } = props;
  const [contactsView, setContactsView] = useState(0);

  const styles = {
    headerText: { fontSize: 20, fontWeight: 500 },
    container: { paddingBlock: 14, backgroundColor: Colors.WHITE1 },
  };

  const phoneContactsGroup = useCallback(
    (state: IntegrationPhoneContactState) =>
      phoneContacts.filter((contact) => contact.state === state),
    [phoneContacts]
  );

  const contactsToShow = useMemo(() => {
    const contacts: { name: string; phoneNumber: string }[] =
      phoneContactsGroup(
        contactsView === 1
          ? IntegrationPhoneContactState.NEW
          : contactsView === 2
          ? IntegrationPhoneContactState.INVALID
          : IntegrationPhoneContactState.ALREADY_EXISTING
      ).map((row) => {
        return { name: row.name, phoneNumber: row.phoneNumber };
      });
    return contacts;
  }, [contactsView, phoneContactsGroup]);

  const contactsInfoRow = useCallback(
    (contactRow: number, rowValue: number) => {
      const noNewContactsFound =
        contactRow === 1 &&
        phoneContacts.filter(
          (contact) => contact.state === IntegrationPhoneContactState.NEW
        ).length === 0;
      const rowName =
        contactRow === 1
          ? phoneContacts.length === 0
            ? "We didn't find any phone numbers in your Eventbrite audience"
            : "New Phone Number Contacts"
          : "Already Existing Contacts";
      return (
        <div
          onClick={() => setContactsView(contactRow)}
          className="AlignedRowSpacedSelect"
          style={{
            paddingInline: 14,
            pointerEvents: noNewContactsFound ? "none" : "all",
          }}
        >
          <div className="ColumnNormalSelect" style={{ gap: 7 }}>
            <span
              className="bodySubtext"
              style={{
                fontWeight: 500,
                color:
                  contactRow === 1
                    ? rowValue === 0
                      ? Colors.RED3
                      : Colors.BLUE5
                    : Colors.BLACK,
              }}
            >
              {phoneContacts.length > 0 ? `${rowValue} ` : ""}
              {rowName}
            </span>
          </div>
          {!noNewContactsFound ? (
            <Icon icon="ion:chevron-right" height={14} color={Colors.GRAY1} />
          ) : null}
        </div>
      );
    },
    [phoneContacts]
  );

  return (
    <>
      <div
        className="ColumnNormal Centering"
        style={{ gap: 40, paddingBlock: 30 }}
      >
        <div className="ColumnNormal" style={{ gap: 14, textAlign: "center" }}>
          <span style={styles.headerText}>Connection Summary</span>
          <span className="bodySubtext">
            Your Eventbrite account is now connected to Markit.
          </span>
        </div>
        <div className="ColumnNormal" style={{ gap: 24, width: 547 }}>
          <StandardBorderedContainer containerStyles={styles.container}>
            <div className="ColumnNormal">
              <span className="bodyMedium" style={{ paddingInline: 14 }}>
                Contacts Synced
              </span>
              <HorizontalDivider altMargin={14} />
              {contactsInfoRow(
                1,
                phoneContactsGroup(IntegrationPhoneContactState.NEW).length
              )}
              {phoneContactsGroup(IntegrationPhoneContactState.ALREADY_EXISTING)
                .length > 0 ? (
                <>
                  <HorizontalDivider altMargin={14} />
                  {contactsInfoRow(
                    3,
                    phoneContactsGroup(
                      IntegrationPhoneContactState.ALREADY_EXISTING
                    ).length
                  )}
                </>
              ) : null}
            </div>
          </StandardBorderedContainer>
          <StandardBorderedContainer containerStyles={styles.container}>
            <div className="ColumnNormal">
              <span className="bodyMedium" style={{ paddingInline: 14 }}>
                Events Synced
              </span>
              <HorizontalDivider altMargin={14} />
              <span
                className="bodyMedium"
                style={{
                  paddingInline: 14,
                  color: numEvents > 0 ? Colors.BLUE5 : Colors.RED3,
                }}
              >
                {numEvents > 0
                  ? numEvents + " Events"
                  : "We didn't find any Eventbrite events"}
              </span>
            </div>
          </StandardBorderedContainer>
        </div>

        <ContactSupportButton />
      </div>
      {contactsView > 0 ? (
        <ValidContactsPopupPanel
          contactsToShow={contactsToShow}
          contactsView={contactsView}
          setContactsView={setContactsView}
        />
      ) : null}
    </>
  );
};

export default IntegrationSummary;
