import {
  AccountData,
  Conversation,
  MemberIndicator,
} from "@markit/common.types";
import { useCallback, useMemo } from "react";
import { Colors } from "../../../utils/colors";
import { TimeAgo } from "../../../utils/TimeAgo";
import ProfilePic from "../../ProfilePic";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";

type ConversationsSidebarPreviewProps = {
  conversationItem: Conversation;
  fetchedConversationUserData: AccountData[];
  isSelectedItem: boolean;
  selectConversationMainView: (conversation: Conversation) => void;
};

export const isUnreadConversation = (
  chatIndicators: MemberIndicator[],
  accountDataId: string
) => {
  if (chatIndicators !== undefined) {
    return chatIndicators.find(
      (member) => member.indicator && member.uid === accountDataId
    );
  } else {
    return false;
  }
};

const ConversationsSidebarPreview = (
  props: ConversationsSidebarPreviewProps
) => {
  const {
    conversationItem,
    fetchedConversationUserData,
    isSelectedItem,
    selectConversationMainView,
  } = props;
  const { uid, customer } = useSelector(getAccountState).account.accountData;

  const participant = useMemo(
    () =>
      fetchedConversationUserData.find(
        (user) => user.uid === conversationItem.participantUids[0]
      ),
    [conversationItem, fetchedConversationUserData]
  );

  const isUnread = useCallback(() => {
    return isUnreadConversation(conversationItem.chatIndicator, uid);
  }, [conversationItem, uid]);

  return (
    <div className="ColumnNormal">
      <div
        className="AlignedRow ConversationSidebarItem"
        style={{
          backgroundColor: isSelectedItem ? Colors.BLUE5 : Colors.WHITE,
          pointerEvents:
            customer.state === "ACTIVE" || customer.state === "TRIAL"
              ? "all"
              : "none",
        }}
        onClick={() => selectConversationMainView(conversationItem)}
      >
        <div className="AlignedRow" style={{ gap: 10 }}>
          <ProfilePic
            user={participant}
            size={48}
            gradientBackground
            forcePointer
          />
          <div
            className="ColumnNormal"
            style={{ gap: 2, height: 61, width: 299 }}
          >
            <div className="AlignedRowSpaced" style={{ gap: 10 }}>
              <span
                className="sectionTitle OneLineText"
                style={{
                  color: isSelectedItem ? Colors.WHITE : Colors.BLACK,
                }}
              >
                {participant ? participant.fullName : "•••"}
              </span>
              {conversationItem.recentTimestamp !== undefined ? (
                <span
                  className="bodySubtext"
                  style={{
                    color: isSelectedItem ? Colors.WHITE : Colors.GRAY1,
                    whiteSpace: "nowrap",
                  }}
                >
                  {TimeAgo(conversationItem.recentTimestamp)}
                </span>
              ) : null}
            </div>
            <div className="AlignedRowSpaced" style={{ gap: 10 }}>
              <div style={{ flex: 1 }}>
                <span
                  className="TwoLineText bodySubtext"
                  style={{
                    color: isSelectedItem ? Colors.WHITE : Colors.GRAY1,
                  }}
                >
                  {conversationItem.recentMessage.text
                    ? conversationItem.recentMessage.text.trim() === ""
                      ? "Attachment: 1 Media" // assume if no text that it's media
                      : conversationItem.recentMessage.text
                    : "No message responses yet"}
                </span>
              </div>
              <div
                style={{
                  width: 11,
                  height: 11,
                  backgroundColor: isUnread()
                    ? Colors.BLUE5
                    : Colors.TRANSPARENT,
                  borderRadius: 64,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConversationsSidebarPreview;
