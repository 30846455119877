import { Colors } from "../../utils/colors";
import { isDesktop } from "react-device-detect";
import { Icon } from "@iconify/react";
import { AccountData, Event } from "@markit/common.types";
import { getEventDisplayedOrganizers } from "../../utils/eventUtils/eventUtils";
import {
  FeaturedEventCard,
  FeaturedEventCardMobile,
} from "../LandingPage/RenderedEvents";
import { useMemo } from "react";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";
import FeaturedEventsEmptyState from "./FeaturedEventsEmptyState";

type FeaturedEventsProps = {
  featuredEvents: Event[];
  featuredEventOrganizers: AccountData[];
  description?: string;
};

const FeaturedEvents = (props: FeaturedEventsProps) => {
  const { featuredEvents, featuredEventOrganizers, description } = props;
  const numColumns = 4;
  const numRows = useMemo(() => {
    return featuredEvents.length / numColumns;
  }, [featuredEvents.length]);

  const styles = {
    citiesContainer: { marginTop: 40 },
    title: {
      fontSize: isDesktop ? 32 : 24,
      fontWeight: 600,
    },
    subtitle: {
      fontSize: isDesktop ? 16 : 14,
      color: Colors.GRAY1,
    },
  };
  return (
    <div
      className="ColumnNormal"
      style={{ gap: isDesktop ? 16 : 0, marginTop: 40 }}
    >
      <div className="ColumnNormal">
        <div className="AlignedRow" style={{ gap: 14 }}>
          <span style={styles.title}>Trending</span>
          <Icon
            icon="ion:trending-up"
            height={32}
            style={{ color: Colors.BLACK }}
          />
        </div>
        <span style={styles.subtitle}>
          {description ?? "These events are on fire 🔥"}
        </span>
      </div>
      {
        featuredEvents.length > 0 ? (
          <div
            className={isDesktop ? "FeaturedEventList" : "ColumnNormal"}
            style={{
              gridTemplateRows: `repeat(${numRows}, auto)`,
              overflow: isDesktop ? "hidden" : "scroll",
            }}
          >
            {featuredEvents.map((item) => {
              const foundOrganizers = item.cohosts.concat(item.createdBy);
              const finalOrganizers = getEventDisplayedOrganizers(
                item.createdBy,
                featuredEventOrganizers.filter((organizer) =>
                  foundOrganizers.includes(organizer.uid)
                )
              );
              return isDesktop ? (
                <FeaturedEventCard
                  event={item}
                  eventOrganizers={finalOrganizers}
                />
              ) : (
                <>
                  <HorizontalDivider altMargin={20} />
                  <FeaturedEventCardMobile
                    event={item}
                    eventOrganizers={finalOrganizers}
                  />
                </>
              );
            })}
          </div>
        ) : (
          <FeaturedEventsEmptyState />
        ) // add emptystate here
      }
    </div>
  );
};

export default FeaturedEvents;
