import React, { memo } from "react";
import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";

type AddNewToListButtonProps = {
  onPress: () => void;
  label: string;
  containerStyles?: React.CSSProperties;
};

/**
 * Follows this format:
 *  New Item        +
 */
export const AddNewToListButton = memo(function AddNewToListButtonFn(
  props: AddNewToListButtonProps
) {
  const { onPress, label, containerStyles } = props;

  return (
    <div
      onClick={onPress}
      className="AlignedRowSpacedSelect"
      style={{ ...containerStyles, paddingBlock: 7 }}
    >
      <span style={{ fontSize: 14, fontWeight: "500", color: Colors.BLUE5 }}>
        {label}
      </span>
      <Icon
        icon={"ion:add-circle"}
        height={30}
        style={{ color: Colors.BLUE5 }}
      />
    </div>
  );
});
