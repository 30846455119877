import { Route, Routes } from "react-router-dom";
import { memo } from "react";
import AdminDashboard from "../screens/AdminDashboard/AdminDashboard";
import AdminDashboardEvents from "../screens/AdminDashboard/AdminDashboardEvents";

const AdminDashboardRoute = memo(function AdminDashboardRouteFn() {
  return (
    <Routes>
      <Route index element={<AdminDashboard />} />
      <Route path="events" element={<AdminDashboardEvents />} />
    </Routes>
  );
});

export default AdminDashboardRoute;
