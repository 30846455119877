import React, { CSSProperties, ReactNode, memo } from "react";
import { useTheme } from "../../hooks/useTheme";

type StandardSectionContainerProps = {
  title: string | ReactNode;
  subtext: string | ReactNode;
  icon?: ReactNode;
  containerStyles?: CSSProperties;
  onPress?: () => void;
  iconOnPress?: () => void;
};

// The structure of this component
/**
 *  ____________________
 * | title              |
 * | subtext       icon |
 * |____________________|
 */
export const StandardSectionContainer = memo(
  function StandardSectionContainerFn(props: StandardSectionContainerProps) {
    const { title, subtext, icon, containerStyles, onPress, iconOnPress } =
      props;
    const { theme } = useTheme();

    return (
      <div
        className="AlignedRowSpacedSelect"
        style={{ ...containerStyles, pointerEvents: !onPress ? "none" : "all" }}
        onClick={onPress}
      >
        <div className="ColumnNormalSelect" style={{ marginRight: 14, gap: 7 }}>
          <span style={{ fontSize: 14, fontWeight: 500, ...theme.PrimaryText }}>
            {title}
          </span>
          <span style={{ fontSize: 12, ...theme.LabelText }}>{subtext}</span>
        </div>
        {icon ? (
          <div style={{ cursor: "pointer" }} onClick={iconOnPress}>
            {icon}
          </div>
        ) : null}
      </div>
    );
  }
);
