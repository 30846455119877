import { EventLinkTracker, TrackingOverview } from "@markit/common.types";
import { useMemo } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import { Colors } from "../../utils/colors";
import { DropdownMenu, DropdownMenuItem } from "../DropdownMenu";
import { useTheme } from "../../hooks/useTheme";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";
import { Icon } from "@iconify/react";

export const PROMO_FLEX_SECTIONS = [10, 5, 2];

type TrackingLinkItemProps = {
  item: EventLinkTracker;
  itemTrackingOverview: TrackingOverview;
  numTotalLinkTrackers: number;
  copyTrackingLink: () => void;
  editTrackingLink: () => void;
  deleteTrackingLink: () => void;
};

export const TrackingLinkItem = (props: TrackingLinkItemProps) => {
  const {
    item,
    itemTrackingOverview,
    numTotalLinkTrackers,
    copyTrackingLink,
    editTrackingLink,
    deleteTrackingLink,
  } = props;
  const { theme } = useTheme();

  const styles = {
    bigSubText: {
      color: theme.SubText.color,
      fontSize: 14,
    },
  };

  const dropdownItems: DropdownMenuItem[] = useMemo(
    () => [
      {
        title: "Copy Link",
        icon: "feather:link-2",
        key: "copy_link",
        onPress: copyTrackingLink,
      },
      {
        title: "Edit Link Name",
        icon: "feather:edit",
        key: "edit_link",
        onPress: editTrackingLink,
      },
      {
        title: `Delete Link`,
        icon: "ion:trash-outline",
        key: "delete_link",
        onPress: deleteTrackingLink,
        isDestructive: true,
      },
    ],
    [copyTrackingLink, deleteTrackingLink, editTrackingLink]
  );

  return (
    <div className="ColumnNormal">
      <div style={{ color: Colors.BLACK, width: "100%" }}>
        <div className="AlignedRow">
          <div
            style={{
              fontSize: 14,
              fontWeight: 600,
              marginBottom: 4,
              flex: 1,
            }}
          >
            {item.alias}
          </div>
          <div style={{ ...styles.bigSubText, flex: 1, marginLeft: 34 }}>
            {itemTrackingOverview.numOpens}
          </div>
          <div className="AlignedRowSpaced" style={{ flex: 1, marginLeft: 34 }}>
            <div style={styles.bigSubText}>
              {itemTrackingOverview.numConversions}
            </div>
            <DropdownMenu
              dropdownItems={dropdownItems}
              trigger={["click"]}
              placement="bottomLeft"
              altWidth={150}
            >
              <Icon
                icon={"mdi:dots-horizontal"}
                height={24}
                style={{ color: Colors.GRAY3, marginTop: 2 }}
              />
            </DropdownMenu>
          </div>
        </div>
      </div>
      <div style={{ marginTop: 14 }}>
        <ProgressBar
          completed={itemTrackingOverview.numOpens}
          maxCompleted={numTotalLinkTrackers === 0 ? 0 : numTotalLinkTrackers}
          isLabelVisible={false}
          bgColor={Colors.BLUE5}
          baseBgColor={theme.PrimaryBG.backgroundColor}
          height="5px"
        />
      </div>
      <HorizontalDivider altMargin={24} />
    </div>
  );
};
