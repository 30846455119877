import React, { useCallback, useMemo } from "react";
import {
  Event,
  TicketType,
  ThemeType,
  Visibility,
  EventType,
} from "@markit/common.types";
import { LightTheme, useTheme } from "../../hooks/useTheme";
import { Colors } from "../../utils/colors";
import { Icon } from "@iconify/react";
import { DropdownMenu, DropdownMenuItem } from "../../components/DropdownMenu";

interface CreateEventExtraDetailsProps {
  eventSettings: Event;
  updateEventSettings: (update: Partial<Event>) => void;
}

const CreateEventExtraDetails = (props: CreateEventExtraDetailsProps) => {
  const { eventSettings, updateEventSettings } = props;
  const { theme } = useTheme(eventSettings.theme);

  const renderAdditionalDetailItem = useCallback(
    (title: string, subtext: string, dropDownMenu: React.ReactNode) => (
      <div
        className="AlignedRowSpaced"
        style={{
          ...theme.TertiaryBG,
          padding: "10px",
          borderRadius: "8px",
          marginTop: "10px",
        }}
      >
        <div className="ColumnNormal" style={{ gap: "4px" }}>
          <div style={{ ...theme.PrimaryText, fontWeight: "500" }}>{title}</div>
          <div style={{ color: Colors.GRAY2, fontSize: "13px" }}>{subtext}</div>
        </div>
        {dropDownMenu}
      </div>
    ),
    [theme.PrimaryText, theme.TertiaryBG]
  );

  // Dropdown menu item selections
  const visibilityItems: DropdownMenuItem[] = useMemo(
    () => [
      {
        title: "Private",
        key: "private",
        onPress: () =>
          updateEventSettings({
            visibility: Visibility.Private,
          }),
      },
      {
        title: "Public",
        key: "public",
        onPress: () =>
          updateEventSettings({
            visibility: Visibility.Public,
          }),
      },
    ],
    [updateEventSettings]
  );

  const guestListItems: DropdownMenuItem[] = useMemo(
    () => [
      {
        title: "Hidden",
        key: "hidden",
        onPress: () =>
          updateEventSettings({
            hideResponses: true,
          }),
      },
      {
        title: "Visible",
        key: "visible",
        onPress: () =>
          updateEventSettings({
            hideResponses: false,
          }),
      },
    ],
    [updateEventSettings]
  );

  const refundItems: DropdownMenuItem[] = useMemo(
    () => [
      {
        title: "No Refunds",
        key: "no_refunds",
        onPress: () =>
          updateEventSettings({
            canRefund: false,
          }),
      },
      {
        title: "Refunds",
        key: "can_refund",
        onPress: () =>
          updateEventSettings({
            canRefund: true,
          }),
      },
    ],
    [updateEventSettings]
  );

  const renderArrowUpDown = useMemo(
    () => (
      <div className="ColumnNormal">
        <Icon icon={"iconamoon:arrow-up-2-duotone"} height={18} />
        <Icon icon={"iconamoon:arrow-down-2-duotone"} height={18} />
      </div>
    ),
    []
  );

  return (
    <div className="PageDesktop" style={theme.SecondaryBG}>
      <div className="Container">
        <div className="eventThemesButtonContainer">
          <div
            className="containerButton"
            style={{
              backgroundColor: Colors.GRAY6,
              width: "100%",
              paddingBlock: "10px",
            }}
            onClick={() => {
              updateEventSettings({
                theme: ThemeType.Light,
              });
            }}
          >
            <h4 style={{ color: "black", fontWeight: "500" }}>Light Theme</h4>
            <Icon
              icon={
                eventSettings.theme === ThemeType.Light
                  ? "mdi:checkbox-marked-circle"
                  : "mdi:checkbox-blank-circle-outline"
              }
              height={20}
              style={{
                color:
                  eventSettings.theme === ThemeType.Light
                    ? Colors.BLACK
                    : "#929292",
              }}
            />
          </div>
          <div
            className="containerButton"
            style={{
              backgroundColor: Colors.GRAY7,
              width: "100%",
              paddingBlock: "10px",
            }}
            onClick={() => {
              updateEventSettings({
                theme: ThemeType.Dark,
              });
            }}
          >
            <h4 style={{ color: "white", fontWeight: "500" }}>Dark Theme</h4>
            <Icon
              icon={
                eventSettings.theme === ThemeType.Dark
                  ? "mdi:checkbox-marked-circle"
                  : "mdi:checkbox-blank-circle-outline"
              }
              height={20}
              style={{
                color:
                  eventSettings.theme === ThemeType.Dark
                    ? "#F4F5F6"
                    : "#929292",
              }}
            />
          </div>
        </div>
        {/* Adjust event visibility */}
        {renderAdditionalDetailItem(
          "Visibility",
          "Private or public",
          <DropdownMenu
            dropdownItems={visibilityItems}
            trigger={["click"]}
            placement="bottomRight"
            altWidth={150}
          >
            <div
              className={
                "ArrowDropdownOption " +
                (theme === LightTheme
                  ? "ArrowDropdownOptionLight"
                  : "ArrowDropdownOptionDark")
              }
            >
              <span style={{ fontWeight: "500" }}>
                {eventSettings.visibility === Visibility.Private
                  ? "Private"
                  : "Public"}
              </span>
              {renderArrowUpDown}
            </div>
          </DropdownMenu>
        )}
        {eventSettings.eventType === EventType.MARKIT ? (
          <>
            {/* Adjust guest list visibility */}
            {renderAdditionalDetailItem(
              "Guest List",
              "Let people see who is going",
              <DropdownMenu
                dropdownItems={guestListItems}
                trigger={["click"]}
                placement="bottomRight"
                altWidth={150}
              >
                <div
                  className={
                    "ArrowDropdownOption " +
                    (theme === LightTheme
                      ? "ArrowDropdownOptionLight"
                      : "ArrowDropdownOptionDark")
                  }
                >
                  <span style={{ fontWeight: "500" }}>
                    {eventSettings.hideResponses ? "Hidden" : "Visible"}
                  </span>
                  {renderArrowUpDown}
                </div>
              </DropdownMenu>
            )}
            {eventSettings.customTickets.some(
              (ticket) => ticket.type === TicketType.PAID
            )
              ? renderAdditionalDetailItem(
                  "Refund Policy",
                  "Displayed on event page",
                  <DropdownMenu
                    dropdownItems={refundItems}
                    trigger={["click"]}
                    placement="bottomRight"
                    altWidth={150}
                  >
                    <div
                      className={
                        "ArrowDropdownOption " +
                        (theme === LightTheme
                          ? "ArrowDropdownOptionLight"
                          : "ArrowDropdownOptionDark")
                      }
                    >
                      <span style={{ fontWeight: "500" }}>
                        {eventSettings.canRefund ? "Refunds" : "No Refunds"}
                      </span>
                      {renderArrowUpDown}
                    </div>
                  </DropdownMenu>
                )
              : null}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default CreateEventExtraDetails;
