import { useCallback, useMemo, useState } from "react";
import {
  getDocs,
  onSnapshot,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "../firebase";
import { uniqueVals } from "@markit/common.utils";
import {
  AccountData,
  Follower,
  Following,
  FollowerStatus,
} from "@markit/common.types";
import {
  getUserFollowersRef,
  getUserFollowingData,
  getUserFollowingsRef,
} from "../utils/FirebaseUtils";
import { fetchSingleFollower, fetchSingleUser } from "../utils/FetchSingleData";
import { loadAlgoliaUsersSearchResults } from "../utils/algoliaUtils";
import { useDispatch, useSelector } from "react-redux";
import { accountActions, getAccountState } from "../redux/slices/accountSlice";
import useOnUnmount from "./useOnUnmount";

type useLoadUserFollowListProps = {
  userId: string;
  followListType: "Followers" | "Following";
  followerStatus: FollowerStatus;
  windowSize: number;
};
export const useLoadUserFollowList = (props: useLoadUserFollowListProps) => {
  const { userId, followListType, followerStatus, windowSize } = props;

  const { followingAccountData, followersData } =
    useSelector(getAccountState).account;
  const dispatch = useDispatch();

  const [fetchedUserData, setFetchedUserData] = useState<AccountData[]>([]);
  const [fetchedFollowerData, setFetchedFollowerData] = useState<Follower[]>(
    []
  );
  const [fetchedFollowingData, setFetchedFollowingData] = useState<Following[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [timeoutEvent, setTimeoutEvent] = useState<
    NodeJS.Timeout | undefined
  >();

  const [lastVisible, setLastVisible] = useState<string>(
    new Date().toISOString()
  );
  const [loadedAllMessages, setLoadedAllMessages] = useState(false);

  useOnUnmount(
    (latestDeps) => {
      dispatch(
        accountActions.addMultipleFollowingAccountData(
          latestDeps[0] as AccountData[]
        )
      );
      dispatch(
        accountActions.addMultipleFollowerData(latestDeps[1] as Follower[])
      );
    },
    [fetchedUserData, fetchedFollowerData]
  );

  const addUserData = useCallback((newUserData: AccountData[]) => {
    setFetchedUserData((fetchedUserData) =>
      uniqueVals(
        fetchedUserData.concat(newUserData),
        (userData) => userData.uid
      )
    );
  }, []);

  const removeUserData = useCallback((newUserDataUid: string) => {
    setFetchedUserData((fetchedUserData) => {
      const index = fetchedUserData.findIndex(
        (fetched) => fetched.uid === newUserDataUid
      );
      if (index !== -1) {
        fetchedUserData.splice(index, 1);
      }
      return uniqueVals(
        fetchedUserData,
        (fetchedUserData) => fetchedUserData.uid
      );
    });
  }, []);

  const addFollowerData = useCallback((newFollowers: Follower[]) => {
    setFetchedFollowerData((fetchedFollowerData) =>
      uniqueVals(
        newFollowers.concat(fetchedFollowerData),
        (followerData) => followerData.uid
      )
    );
  }, []);

  const removeFollowerData = useCallback((newFollowerUid: string) => {
    setFetchedFollowerData((fetchedFollowerData) => {
      const index = fetchedFollowerData.findIndex(
        (fetched) => fetched.uid === newFollowerUid
      );
      if (index !== -1) {
        fetchedFollowerData.splice(index, 1);
      }
      return uniqueVals(
        fetchedFollowerData,
        (fetchedFollowerData) => fetchedFollowerData.uid
      );
    });
  }, []);

  const addFollowingData = useCallback((newFollowing: Following) => {
    setFetchedFollowingData((fetchedFollowingData) => {
      const index = fetchedFollowingData.findIndex(
        (fetched) => fetched.uid === newFollowing.uid
      );
      if (index !== -1) {
        fetchedFollowingData.splice(index, 1, newFollowing);
      } else {
        fetchedFollowingData = fetchedFollowingData.concat(newFollowing);
      }
      return uniqueVals(
        fetchedFollowingData,
        (fetchedFollowingData) => fetchedFollowingData.uid
      );
    });
  }, []);

  const removeFollowingData = useCallback((newFollowingUid: string) => {
    setFetchedFollowingData((fetchedFollowingData) => {
      const index = fetchedFollowingData.findIndex(
        (fetched) => fetched.uid === newFollowingUid
      );
      if (index !== -1) {
        fetchedFollowingData.splice(index, 1);
      }
      return uniqueVals(
        fetchedFollowingData,
        (fetchedFollowingData) => fetchedFollowingData.uid
      );
    });
  }, []);

  const checkIfAddUserData = useCallback(
    async (uid: string) => {
      const userData = await fetchSingleUser(uid, followingAccountData);
      if (userData) {
        if (followListType === "Followers") {
          const followerData = await fetchSingleFollower(
            uid,
            userData.uid,
            followersData
          );
          if (followerData) {
            if (followerData.status === followerStatus) {
              addFollowerData([followerData]);
              addUserData([userData]);
            }
          }
        } else {
          const followingData = await getUserFollowingData(userId, uid);
          if (followingData) {
            addFollowingData(followingData);
            addUserData([userData]);
          }
        }
      }
    },
    [
      addFollowerData,
      addFollowingData,
      addUserData,
      followListType,
      followerStatus,
      followersData,
      followingAccountData,
      userId,
    ]
  );

  const isFinished = useMemo(() => loadedAllMessages, [loadedAllMessages]);

  const loadUsers = useCallback(async () => {
    if (!isFinished && !isLoading) {
      setIsLoading(true);
      const followMembersRef =
        followListType === "Followers"
          ? getUserFollowersRef(userId)
          : getUserFollowingsRef(userId);
      const followMembersQuery = query(
        followMembersRef,
        where("status", "==", followerStatus),
        orderBy("createdAt", "desc"),
        limit(windowSize)
      );

      let uploadedMembers: string[] = [];
      try {
        uploadedMembers = (await getDocs(followMembersQuery)).docs.map(
          (document) => document.data().createdAt
        );
      } catch (e: any) {
        console.error(e.message);
      }

      if (uploadedMembers.length) {
        setLastVisible(uploadedMembers[uploadedMembers.length - 1]);
      }
      if (uploadedMembers.length < windowSize) {
        setLoadedAllMessages(true);
      }

      const unsubscribe = onSnapshot(followMembersQuery, (snapshot) => {
        snapshot.docChanges().forEach(async (message, index) => {
          const memberUserData = message.doc.data();
          if (message.type === "added" || message.type === "modified") {
            if (followListType === "Followers") {
              addFollowerData([memberUserData] as Follower[]);
            } else {
              addFollowingData(memberUserData);
            }
            fetchSingleUser(
              memberUserData.uid,
              followingAccountData,
              (userData) => addUserData([userData])
            );
          } else if (message.type === "removed") {
            if (followListType === "Followers") {
              removeFollowerData(memberUserData.uid);
            } else {
              removeFollowingData(memberUserData.uid);
            }
            removeUserData(memberUserData.uid);
          }
        });
      });
      // TODO (Peter): hacky way to wait for data to load before setting isLoading to false.
      // Better solution is to set isLoading to false when we know the data has actually been loaded
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
      return unsubscribe;
    }
  }, [
    isFinished,
    isLoading,
    followListType,
    userId,
    followerStatus,
    windowSize,
    followingAccountData,
    addFollowerData,
    addFollowingData,
    addUserData,
    removeUserData,
    removeFollowerData,
    removeFollowingData,
  ]);

  const loadMoreUsers = useCallback(async () => {
    if (!isFinished && !isLoading) {
      setIsLoading(true);
      const followMembersRef =
        followListType === "Followers"
          ? getUserFollowersRef(userId)
          : getUserFollowingsRef(userId);
      const followMembersQuery = query(
        followMembersRef,
        where("status", "==", followerStatus),
        orderBy("createdAt", "desc"),
        startAfter(lastVisible),
        limit(windowSize)
      );

      let uploadedMembers: string[] = [];
      try {
        uploadedMembers = (await getDocs(followMembersQuery)).docs.map(
          (document) => document.data().createdAt
        );
      } catch (e: any) {
        console.error(e.message);
      }

      if (uploadedMembers.length) {
        setLastVisible(uploadedMembers[uploadedMembers.length - 1]);
      }
      if (uploadedMembers.length < windowSize) {
        setLoadedAllMessages(true);
      }

      const unsubscribe = onSnapshot(followMembersQuery, (snapshot) => {
        snapshot.docChanges().forEach(async (message, index) => {
          const memberUserData = message.doc.data();
          if (message.type === "added" || message.type === "modified") {
            fetchSingleUser(
              memberUserData.uid,
              followingAccountData,
              (userData) => addUserData([userData])
            );
            if (followListType === "Followers") {
              addFollowerData([memberUserData] as Follower[]);
            } else {
              addFollowingData(memberUserData);
            }
          } else if (message.type === "removed") {
            if (followListType === "Followers") {
              removeFollowerData(memberUserData.uid);
            } else {
              removeFollowingData(memberUserData.uid);
            }
            removeUserData(memberUserData.uid);
          }
        });
      });
      // TODO (Peter): hacky way to wait for data to load before setting isLoading to false.
      // Better solution is to set isLoading to false when we know the data has actually been loaded
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
      return unsubscribe;
    }
  }, [
    isFinished,
    isLoading,
    followListType,
    userId,
    followerStatus,
    lastVisible,
    windowSize,
    followingAccountData,
    addUserData,
    addFollowerData,
    addFollowingData,
    removeUserData,
    removeFollowerData,
    removeFollowingData,
  ]);

  const loadSearchResults = useCallback(
    async (searchTerm: string) => {
      const fetchFollowers = async (userIds: string[]) => {
        setIsLoading(true);
        await Promise.all(
          userIds.map(async (uid) => {
            await checkIfAddUserData(uid);
          })
        );
        setIsLoading(false);
      };
      await loadAlgoliaUsersSearchResults(
        searchTerm,
        `following:${userId}`,
        (userIds: string[]) => fetchFollowers(userIds)
      );
    },
    [userId, checkIfAddUserData]
  );

  const loadSearchResultsThrottled = useCallback(
    (searchTerm: string) => {
      if (timeoutEvent) {
        clearTimeout(timeoutEvent);
      }
      setTimeoutEvent(
        setTimeout(() => {
          if (searchTerm.length > 1) {
            loadSearchResults(searchTerm);
          }
        }, 500)
      );
    },
    [loadSearchResults, timeoutEvent]
  );

  return {
    isFinished,
    isLoading,
    fetchedUserData,
    fetchedFollowerData,
    fetchedFollowingData,
    loadUsers,
    loadMoreUsers,
    loadSearchResultsThrottled,
  };
};
