export enum WhiteLabelUsers {
  RCA = "naKqqXV9ocM8pHDgOMxVlxcJMZg2",
  JULI = "743eqETQonTelNzdP3LX8gZrGfV2",
  JULIANA = "0TDQUYlaRHVTYEZBYC7z7wKAAa92",
  PETER = "QQBi07kMCdhZRQWxHINpqNMHmBh1",
}

export enum WhiteLabelColors {
  RCA_PRIMARY = "#C22026",
  RCA_SECONDARY = "black",
  RCA_TRANSPARENT = "#c2202529",
}

export enum WhiteLabelSocial {
  RCA_TIKTOK = "https://www.tiktok.com/@redcurtainaddict",
  RCA_INSTA = "https://instagram.com/redcurtainaddict",
  RCA_FB = "https://www.facebook.com/RedCurtainAddict",
  RCA_WEBSITE = "https://www.redcurtainaddict.com/",
}
