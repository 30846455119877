import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import { useTheme } from "../../hooks/useTheme";
import CustomTextField from "../CustomTextField";
import RectangleButton from "../Buttons/RectangleButton";
import { OnForgotPasswordPress } from "../../utils/ReauthenticateLogin";

type LoginForgotPasswordProps = {
  email: string;
  setEmail: (email: string) => void;
  emailError: {
    status: boolean;
    message: string;
  };
  setEmailError: (emailError: { status: boolean; message: string }) => void;
  backToEmailOnPress: () => void;
};

const LoginForgotPassword = (props: LoginForgotPasswordProps) => {
  const { email, setEmail, emailError, setEmailError, backToEmailOnPress } =
    props;
  const { theme } = useTheme();

  return (
    <div className="ColumnNormal">
      <div
        className="LargePopupPanelClose"
        onClick={backToEmailOnPress}
        style={{ marginBottom: 14 }}
      >
        <Icon
          icon="ion:chevron-back"
          height={24}
          style={{
            color: Colors.GRAY3,
          }}
        />
      </div>
      <span
        className="LoginHeaderText"
        style={{
          ...theme.PrimaryText,
        }}
      >
        Forgot Password?
      </span>
      <div style={{ paddingTop: 7, paddingBottom: 20 }}>
        <span className="LoginHeaderTextDescription">
          Enter the email address associated with your account, and we'll email
          you a link to reset your password.
        </span>
      </div>
      <div style={{ marginBottom: 14 }}>
        <CustomTextField
          value={email}
          placeholder="Email"
          inputMode="text"
          borderRadius={12}
          onChange={(change: any) => {
            setEmailError({
              status: false,
              message: "",
            });
            setEmail(change.target.value);
          }}
          error={emailError.status}
        />
      </div>
      <RectangleButton
        buttonLabel={<span>Send Reset Link</span>}
        onPress={() => OnForgotPasswordPress(email, setEmailError)}
        theme={theme}
        altPaddingVert={12}
      />
    </div>
  );
};

export default LoginForgotPassword;
