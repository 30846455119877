// Note: navigator.share only works on https and on mobile
// For desktop, need to use RWebShare
export const onShareLink = async (text: string, url: string) => {
  const shareData = {
    title: "Markit Social",
    text: text,
    url: url,
  };

  try {
    await navigator.share(shareData);
  } catch (err) {
    console.log(`Error: ${err}`);
  }
};
