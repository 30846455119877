import "../../css/GlobalStyles.css";
import { ReactNode, memo, useCallback, useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { DropdownMenu, DropdownMenuItem } from "../DropdownMenu";
import { MixpanelContext } from "../../context/AnalyticsService";
import { getAccountState } from "../../redux/slices/accountSlice";
import { useLocation } from "react-router-dom";

type CreateDropdownMenuProps = {
  children: ReactNode;
  sourceLocation: string;
  navCreateOnPress: () => void;
  navCreateLinkOnPress: () => void;
};

const CreateDropdownMenu = memo(function CreateDropdownMenuFn(
  props: CreateDropdownMenuProps
) {
  const { children, sourceLocation, navCreateOnPress, navCreateLinkOnPress } =
    props;
  const mixpanel = useContext(MixpanelContext);
  const location = useLocation();
  const { account } = useSelector(getAccountState);
  const { accountData } = account;

  const onCreate = useMemo(
    () =>
      location.pathname === "/create" ||
      location.pathname.includes("/createLink"),
    [location.pathname]
  );

  const redirectToCreate = useCallback(() => {
    mixpanel.track("Pressed Create An Event", {
      pathname: location.pathname,
      button_location: sourceLocation, // "top header",
      platform: "webapp",
    });
    navCreateOnPress();
  }, [location.pathname, mixpanel, navCreateOnPress, sourceLocation]);

  const eventLinkOnPress = useCallback(() => {
    mixpanel.track("Pressed Create External Event Link", {
      distinct_id: accountData.uid,
      platform: "webapp",
      type: "event_link",
      button_location: sourceLocation,
    });
    navCreateLinkOnPress();
  }, [accountData.uid, mixpanel, navCreateLinkOnPress, sourceLocation]);

  const dropdownItems: DropdownMenuItem[] = useMemo(
    () => [
      {
        title: "New Event",
        icon: "ion:calendar",
        key: "new_event",
        onPress: redirectToCreate,
      },
      {
        title: "Link an Event",
        icon: "ion:link",
        key: "link_event",
        onPress: eventLinkOnPress,
      },
    ],
    [eventLinkOnPress, redirectToCreate]
  );

  return (
    <DropdownMenu
      dropdownItems={dropdownItems}
      placement="bottomRight"
      altWidth={200}
      disabled={onCreate}
    >
      {children}
    </DropdownMenu>
  );
});

export default CreateDropdownMenu;
