import "../../css/ProfilePage/ProfilePage.css";
import { memo, useCallback, useMemo } from "react";
import { AccountData, Event } from "@markit/common.types";
import { Colors } from "../../utils/colors";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { LoginState, getAccountState } from "../../redux/slices/accountSlice";
import FlatList from "flatlist-react/lib";
import StandardBorderedContainer from "../Containers/StandardBorderedContainer";
import EmptyStateButton from "../Buttons/EmptyStateButton";
import { datesAreOnSameDay, formatDate } from "@markit/common.utils";
import ProfileEventTimelineCardItem from "../DisplayItem/ProfilePage/ProfileEventTimelineCardItem";
import ProfileEventTimelineGridItem from "../DisplayItem/ProfilePage/ProfileEventTimelineGridItem";
import {
  ProfileDisplayView,
  ProfileTimelineState,
} from "./ProfilePageTimelineHeader";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";
import Skeleton from "react-loading-skeleton";
import { getEventDisplayedOrganizers } from "../../utils/eventUtils/eventUtils";
import { useNavigate } from "../../hooks/useNavigate";

type ProfilePageTimelineProps = {
  displayView: ProfileDisplayView;
  timelineState: ProfileTimelineState;
  userEventsToShow: Event[];
  eventDates: string[];
  eventOrganizers: AccountData[];
  userData: AccountData | undefined;
  showMoreEvents: boolean;
  setShowMoreEvents: (showMoreEvents: boolean) => void;
  loading: boolean;
};

const ProfilePageTimeline = memo(function ProfilePageTimelineFn(
  props: ProfilePageTimelineProps
) {
  const {
    displayView,
    timelineState,
    userEventsToShow,
    eventDates,
    eventOrganizers,
    userData,
    showMoreEvents,
    setShowMoreEvents,
    loading,
  } = props;
  const { account } = useSelector(getAccountState);
  const { accountData, loggedIn } = account;
  const navigate = useNavigate();

  const isCardDisplayView = useMemo(
    () => displayView === ProfileDisplayView.CARD_VIEW,
    [displayView]
  );

  // Truncates the amount of events to show when show more is false
  // Need this to identify if there are more than three events showing based by event dates
  const validUserEventsToShow = useMemo(() => {
    const foundOverflowEvent =
      userEventsToShow.length > 3 && !showMoreEvents
        ? userEventsToShow[3]
        : undefined;
    const index = userEventsToShow.findIndex(
      (event) => foundOverflowEvent && event.id === foundOverflowEvent.id
    );
    if (index !== -1) {
      return userEventsToShow.slice(0, index);
    }
    return userEventsToShow;
  }, [showMoreEvents, userEventsToShow]);

  const styles = {
    specialDate: {
      fontSize: isCardDisplayView ? 14 : 16,
      fontWeight: "500",
    },
    timelineDot: {
      zIndex: 2,
      backgroundColor: Colors.WHITE1,
    },
  };

  const isMyAccount = useMemo(
    () =>
      loggedIn === LoginState.LOGGED_IN && accountData.uid === userData?.uid,
    [accountData.uid, loggedIn, userData?.uid]
  );

  const showTimelineBar = useMemo(
    () => isCardDisplayView && timelineState !== ProfileTimelineState.LINKS,
    [isCardDisplayView, timelineState]
  );

  const tomorrowDate = useMemo(() => {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    return date;
  }, []);

  const getEventItemUrl = useCallback(
    (item: Event) => {
      return `/e/${
        item.id +
        (isMyAccount &&
        userData &&
        (timelineState === ProfileTimelineState.ATTENDING ||
          timelineState === ProfileTimelineState.ATTENDED)
          ? `/i/${userData.uid}`
          : "")
      }`;
    },
    [isMyAccount, timelineState, userData]
  );

  const renderEmptyTimeline = useMemo(
    () =>
      loading ? (
        <div className="ColumnNormal" style={{ gap: 10 }}>
          {[0, 1, 2].map((item, index) => (
            <Skeleton
              key={index}
              height={109}
              baseColor={Colors.WHITE}
              borderRadius={12}
            />
          ))}
        </div>
      ) : (
        <StandardBorderedContainer
          containerStyles={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 270,
          }}
        >
          <EmptyStateButton
            title={
              isMyAccount ||
              timelineState === ProfileTimelineState.UPCOMING ||
              timelineState === ProfileTimelineState.PAST
                ? "No Events"
                : "No Tickets"
            }
            description={
              isMyAccount
                ? timelineState === ProfileTimelineState.UPCOMING
                  ? "Events you create will show up here"
                  : timelineState === ProfileTimelineState.PAST
                  ? "Your past events will show up here"
                  : timelineState === ProfileTimelineState.ATTENDING
                  ? "Your upcoming tickets will show up here"
                  : "Your past tickets will show up here"
                : undefined
            }
            icon={
              <Icon icon={"ion:calendar"} height={42} color={Colors.GRAY1} />
            }
            iconBox={63}
            containerStyles={{ paddingTop: 20 }}
            btnText={isMyAccount ? "Create Event" : undefined}
            onPress={() => navigate("/create")}
          />
        </StandardBorderedContainer>
      ),
    [isMyAccount, loading, navigate, timelineState]
  );

  const renderDateDisplay = useCallback(
    (date: string) => {
      const dateObject = new Date(date);
      return (
        <div className="AlignedRow" style={{ gap: 7 }}>
          {datesAreOnSameDay(new Date(), dateObject) ? (
            <span style={styles.specialDate}>Today</span>
          ) : datesAreOnSameDay(tomorrowDate, dateObject) ? (
            <span style={styles.specialDate}>Tomorrow</span>
          ) : null}
          <span style={{ fontSize: isCardDisplayView ? 14 : 16 }}>
            {formatDate(
              dateObject,
              false,
              true,
              new Date().getFullYear() !== dateObject.getFullYear()
            )}
          </span>
        </div>
      );
    },
    [isCardDisplayView, styles.specialDate, tomorrowDate]
  );

  return !loading && userEventsToShow.length > 0 && eventDates.length > 0 ? (
    <div className="HideScrollbar">
      <FlatList
        list={eventDates}
        keyExtractor={(date: string) => date}
        renderItem={(date) => {
          const foundEvents = validUserEventsToShow.filter((event) =>
            datesAreOnSameDay(new Date(event.start), new Date(date))
          );
          const isLastDate = eventDates[eventDates.length - 1] === date;
          const isFilledIn =
            eventDates[0] === date ||
            timelineState === ProfileTimelineState.PAST ||
            timelineState === ProfileTimelineState.ATTENDED;
          return foundEvents.length > 0 ? (
            <div style={{ position: "relative", paddingBottom: 10 }}>
              {showTimelineBar ? (
                <div
                  style={{
                    position: "absolute",
                    left: 6,
                    top: 15,
                    borderRadius: 8,
                    height: validUserEventsToShow.length === 1 ? "90%" : "100%",
                    width: 1,
                    zIndex: 1,
                    background: isLastDate
                      ? "linear-gradient(to bottom, #929292, rgba(0, 0, 0, 0))"
                      : Colors.GRAY1,
                  }}
                />
              ) : null}
              <div
                className="ColumnNormal"
                style={{ gap: 10, paddingLeft: showTimelineBar ? 24 : 0 }}
              >
                {timelineState !== ProfileTimelineState.LINKS ? (
                  <div className="AlignedRow">
                    {isCardDisplayView ? (
                      isFilledIn ? (
                        <Icon
                          icon={"ion:radio-button-on"}
                          height={16}
                          color={Colors.BLACK}
                          style={{
                            ...styles.timelineDot,
                            position: "absolute",
                            left: -1,
                            top: 10,
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            ...styles.timelineDot,
                            position: "absolute",
                            left: 2,
                            top: 12.5,
                            width: 7,
                            height: 7,
                            border: "1px solid #929292",
                            borderRadius: 10,
                          }}
                        />
                      )
                    ) : null}
                    {isCardDisplayView ? (
                      <StandardBorderedContainer
                        containerStyles={{
                          borderRadius: 100,
                          paddingInline: 14,
                          paddingBlock: 8,
                          display: "flex",
                        }}
                      >
                        {renderDateDisplay(date)}
                      </StandardBorderedContainer>
                    ) : (
                      <div
                        className="ColumnNormal"
                        style={{ gap: 10, width: "100%" }}
                      >
                        {renderDateDisplay(date)}
                        <HorizontalDivider altMargin={0} />
                      </div>
                    )}
                  </div>
                ) : null}
                {foundEvents.map((event) => {
                  const foundOrganizers = event.cohosts.concat(event.createdBy);
                  const finalOrganizers = getEventDisplayedOrganizers(
                    event.createdBy,
                    eventOrganizers.filter((organizer) =>
                      foundOrganizers.includes(organizer.uid)
                    )
                  );
                  return (
                    <div key={event.id}>
                      {isCardDisplayView ? (
                        <ProfileEventTimelineCardItem
                          event={event}
                          organizers={finalOrganizers}
                          url={getEventItemUrl(event)}
                          isMyAccount={isMyAccount}
                        />
                      ) : (
                        <ProfileEventTimelineGridItem
                          event={event}
                          organizers={finalOrganizers}
                          url={getEventItemUrl(event)}
                          isMyAccount={isMyAccount}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null;
        }}
        renderWhenEmpty={renderEmptyTimeline}
      />
      {userEventsToShow.length > 3 && isCardDisplayView && !loading ? (
        <StandardBorderedContainer
          containerStyles={{
            marginLeft: timelineState !== ProfileTimelineState.LINKS ? 24 : 0,
            borderRadius: 100,
            paddingInline: 14,
            paddingBlock: 8,
            display: "inline-flex",
            cursor: "pointer",
          }}
          onPress={() => setShowMoreEvents(!showMoreEvents)}
        >
          <span style={{ fontSize: 14 }}>
            Show {showMoreEvents ? "Less" : "More"}
          </span>
        </StandardBorderedContainer>
      ) : null}
    </div>
  ) : (
    renderEmptyTimeline
  );
});

export default ProfilePageTimeline;
