export enum Colors {
  // Markit blue
  BLUE1 = "#11B8FF",
  BLUE2 = "#E8F8FF",
  BLUE3 = "#009FF9", // not used anymore
  BLUE4 = "#1588FD", // text message blue
  BLUE5 = "#007AFF",
  BLUE6 = "#004FC7",

  // Purple
  PURPLE1 = "#0F0F18",
  PURPLE2 = "#111129",
  PURPLE3 = "#322459",
  PURPLE4 = "#22222D",
  PURPLE5 = "#1C1C24",
  PURPLE6 = "#CD8DFF",
  PURPLE7 = "#CD8DFF", // Light Purple (Text Categories)
  PURPLE8 = "#5956FF",
  PURPLE9 = "#0F0F18",
  PURPLE10 = "#3A3A49",

  // Orange
  ORANGE1 = "#EF953D",
  ORANGE_EB = "#FA6345",

  // Pink
  PINK1 = "#FE296C",

  // Green
  GREEN1 = "#39C16C",
  GREEN2 = "#3DDC84",
  GREEN3 = "#D9FFD9", // Trial Subscription Color
  GREEN4 = "#023020", // Dark Green

  // Red
  RED1 = "#DF4840",
  RED2 = "#FE296C",
  RED3 = "#F92D48",

  BLACK = "#000000",
  BLACK1 = "#3D3D3D",
  WHITE = "#FFFFFF",
  WHITE1 = "#FAFAFA",

  // Gray
  GRAY1 = "#929292",
  GRAY2 = "#B9B9B9",
  GRAY3 = "#666666",
  GRAY4 = "#E8E8E8",
  GRAY5 = "#F9F9F9",
  GRAY6 = "#F4F5F6",
  GRAY7 = "#313135",
  GRAY8 = "#3D3D3D",
  GRAY9 = "#202020",
  GRAY10 = "#1C1C1D",
  GRAY11 = "#EDEDED",
  GRAY12 = "#EEEEF0",
  GRAY13 = "#8D919C",

  // Transparent
  TRANSPARENT = "transparent",
}

export function addAlpha(color: string, opacity: number): string {
  // coerce values so ti is between 0 and 1.
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}
