import "../../css/Subscription.css";
import React, { memo, useCallback, useContext, useMemo, useState } from "react";
import { Button, createTheme, Theme, ThemeProvider } from "@mui/material";
import { API } from "../../API";
import { testStripe } from "../../components/FullEvent/FullEventInputForm";
import { MixpanelContext } from "../../context/AnalyticsService";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import { Colors } from "../../utils/colors";
import { isDesktop } from "react-device-detect";
import TopHeader from "../../components/TopHeader";
import AlertContainer from "../../components/Containers/AlertContainer";
import ConfirmDeleteModal from "../../components/Containers/ConfirmPopups/ConfirmDeleteModal";
import { useNavigate } from "../../hooks/useNavigate";

export const theme: Theme = createTheme({
  palette: {
    primary: { main: "#DF4840", contrastText: "#fff" },
    secondary: {
      main: "#3FDCFF",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});

type SubscriptionCancelProps = {};

const SubscriptionCancel = memo(function SubscriptionCancelFn(
  props: SubscriptionCancelProps
) {
  const location = useLocation();
  const { isCancelSubscription, isFreezeSubscription } = location.state || {};
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const navigate = useNavigate();
  const mixpanel = useContext(MixpanelContext);
  const [cancelling, setCancelling] = useState<boolean>(false);

  const [alertText, setAlertText] = useState("");
  const [subscriptionConfirm, setSubscriptionConfirm] = useState(false);

  const frozenSubscription = useMemo(
    () => accountData.customer.state === "FROZEN",
    [accountData]
  );

  const cancelSubscription = useCallback(async () => {
    if (cancelling) {
      return;
    }
    setCancelling(true);
    await (testStripe
      ? API.user.cancelTestSubscription
      : API.user.cancelSubscription)(accountData.uid, {
      uid: accountData.uid,
    })
      .then((res: any) => {
        setAlertText("Cancel Subscription Successful");
        if (mixpanel) {
          mixpanel.identify(accountData.uid);
          mixpanel.track("User Cancelling Markit+ Subscription", {
            date: new Date(),
          });
        }
      })
      .catch((err: any) => {
        console.error(err.message);
        setCancelling(false);
        return undefined;
      });
    setCancelling(false);
  }, [accountData.uid, cancelling, mixpanel]);

  const freezeSubscription = useCallback(async () => {
    if (cancelling) {
      return;
    }
    setCancelling(true);
    if (!frozenSubscription) {
      await (testStripe
        ? API.user.freezeTestSubscription
        : API.user.freezeSubscription)({
        uid: accountData.uid,
      })
        .then((res: any) => {
          setAlertText("Froze Subscription Successfully");
          if (mixpanel) {
            mixpanel.identify(accountData.uid);
            mixpanel.track("User Freezing Markit+ Subscription", {
              date: new Date(),
            });
          }
        })
        .catch((err: any) => {
          console.error(err.message);
          setCancelling(false);
          return undefined;
        });
    } else {
      await (testStripe
        ? API.user.unfreezeTestSubscription
        : API.user.unfreezeSubscription)({
        uid: accountData.uid,
      })
        .then((res: any) => {
          setAlertText("Un-Froze Subscription Successfully");
          if (mixpanel) {
            mixpanel.track("User Un-Freezing Markit+ Subscription", {
              date: new Date(),
              distinct_id: accountData.uid,
            });
          }
        })
        .catch((err: any) => {
          console.error(err.message);
          setCancelling(false);
          return undefined;
        });
    }
    setCancelling(false);
  }, [cancelling, frozenSubscription, accountData.uid, mixpanel]);

  return (
    <div className="SubscriptionCheckoutContainer">
      <TopHeader hideCreate />
      <div
        className="ColumnNormal"
        style={{
          width: isDesktop ? "40%" : "95%",
          alignSelf: "center",
          gap: 10,
        }}
      >
        <div className="AlignedColumn" style={{ gap: 14 }}>
          <span style={{ fontSize: 18, fontWeight: "500" }}>
            {isCancelSubscription
              ? "Cancel Subscription"
              : isFreezeSubscription && accountData.customer.state === "FROZEN"
              ? "Un-Freeze Subscription"
              : "Freeze Subscription"}
          </span>
          <div
            style={{
              backgroundColor: Colors.GRAY6,
              borderRadius: 10,
              padding: 15,
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            {isCancelSubscription ? (
              <p style={{ color: "#929292", marginTop: 0, marginBottom: 0 }}>
                Upon cancelling your{" "}
                <span style={{ color: Colors.BLACK }}>Markit</span>
                <span style={{ color: Colors.BLUE1 }}>+</span> subscription your
                Markit phone number will be permanently removed and all of your
                creator data erased at the end of the current billing cycle. Any
                additional texting credits that have been purchased before or
                after cancelling will be nullified at the end of the current
                billing cycle and will not be refunded.
              </p>
            ) : isFreezeSubscription ? (
              <>
                <p style={{ color: "#929292", marginTop: 0, marginBottom: 0 }}>
                  You can freeze your{" "}
                  <span style={{ color: Colors.BLACK }}>
                    Markit
                    <span style={{ color: Colors.BLUE1 }}>+</span>
                  </span>{" "}
                  subscription for up to 6 months without being billed. After 6
                  months of your subscription being frozen, your phone number
                  will be removed and your creator data erased.
                </p>
                <p
                  style={{
                    color: "#929292",
                    marginTop: 15,
                    marginBottom: 0,
                  }}
                >
                  Frozen subscriptions mean that you will be unable to use any
                  form of texting services, 2 way communication, and all other{" "}
                  <span style={{ color: Colors.BLACK }}>
                    Markit
                    <span style={{ color: Colors.BLUE1 }}>+</span>
                  </span>{" "}
                  services.
                </p>
              </>
            ) : (
              <span
                style={{
                  color: "#000",
                  padding: 5,
                }}
              >
                Cannot display subscription info at this time. Either you no
                longer have a subscription or there is an error on our end.
                Please text{" "}
                <a href="tel:6172130897" style={{ color: "#11B8FF" }}>
                  617-213-0897
                </a>{" "}
                if you have any questions.
              </span>
            )}
          </div>
          <ThemeProvider theme={theme}>
            <Button
              variant="contained"
              color="primary"
              className="RSVPButton"
              disableElevation
              sx={{
                borderRadius: 2,
                paddingTop: 1,
                paddingBottom: 1,
              }}
              onClick={() =>
                isFreezeSubscription && accountData.customer.state === "FROZEN"
                  ? freezeSubscription()
                  : setSubscriptionConfirm(true)
              }
            >
              {isCancelSubscription
                ? "Cancel Subscription"
                : isFreezeSubscription &&
                  accountData.customer.state === "FROZEN"
                ? "Un-Freeze Subscription"
                : "Freeze Subscription"}
            </Button>
          </ThemeProvider>
          {cancelling ? (
            <p
              style={{
                textAlign: "center",
                color: "#000",
                fontWeight: "600",
                marginTop: 10,
              }}
            >
              Loading... Please do not leave this page!
            </p>
          ) : null}
        </div>
      </div>
      <AlertContainer
        headerComp={alertText}
        theme={theme}
        closeModal={() => {
          setAlertText("");
          navigate("/home");
        }}
        hideModal={alertText === ""}
      />
      <ConfirmDeleteModal
        heading={
          "Are you sure you want to " +
          (isCancelSubscription ? "end " : "freeze ") +
          "your subscription?"
        }
        subtext={
          isCancelSubscription
            ? "You cannot undo this."
            : "Unfreeze at any time through your profile"
        }
        deleteButtonText={isCancelSubscription ? "End Subscription" : "Freeze"}
        hideModal={!subscriptionConfirm}
        setIsVisible={setSubscriptionConfirm}
        deleteOnPress={
          isCancelSubscription ? cancelSubscription : freezeSubscription
        }
        theme={theme}
      />
    </div>
  );
});

export default SubscriptionCancel;
