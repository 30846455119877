import { censoredWordsDetected } from "@markit/common.utils";
import AlertContainer from "../Containers/AlertContainer";

type ContentViolationAlertProps = {
  message: string;
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
};

const ContentViolationAlert = (props: ContentViolationAlertProps) => {
  const { message, isVisible, setIsVisible } = props;

  return (
    <AlertContainer
      headerComp={
        "Your message includes language that is in violation of standard texting policies."
      }
      subHeaderComp={`Flagged words detected: ${censoredWordsDetected(
        message
      )}`}
      closeModal={() => setIsVisible(false)}
      hideModal={!isVisible}
      altButtonLabel="Got It"
    />
  );
};

export default ContentViolationAlert;
