import { AccountData, IMessage } from "@markit/common.types";
import { Colors } from "../../../utils/colors";
import ProfilePic from "../../ProfilePic";
import ScrollableFeed from "react-scrollable-feed";
import { ChatMessage } from "./ChatMessage";

type ConversationsMessagesContainerProps = {
  userId: string;
  messages: IMessage[];
  conversationsMainUser: AccountData | undefined;
  fetchedAllMessages: boolean;
  fetchConversationMessages: (increment: boolean) => void;
  messageBubbleAltWidth?: string;
};

const ConversationsMessagesContainer = (
  props: ConversationsMessagesContainerProps
) => {
  const {
    userId,
    messages,
    conversationsMainUser,
    fetchedAllMessages,
    fetchConversationMessages,
    messageBubbleAltWidth,
  } = props;

  return (
    <ScrollableFeed forceScroll className="HideScrollbar">
      <div className="ColumnCenter" style={{ justifyContent: "center" }}>
        {/* Load more message button */}
        {conversationsMainUser
          ? messages.length >= 15 &&
            !fetchedAllMessages && (
              <div
                className="LoadMoreConversationsButton"
                onClick={() => fetchConversationMessages(true)}
              >
                <span style={{ color: Colors.GRAY1, fontSize: 14 }}>
                  Load earlier messages
                </span>
              </div>
            )
          : null}
        {conversationsMainUser ? (
          <>
            {/* Display profile pic if messages length is <= 2*/}
            {messages.length <= 2 ? (
              <div className="ConversationsCenteredProfilePic">
                <ProfilePic user={conversationsMainUser} size={70} />
                <span className="AboutSubtitle" style={{ fontSize: 18 }}>
                  {conversationsMainUser.fullName}
                </span>
              </div>
            ) : null}
            {/* All messages*/}
            <div className="MessagesContainer">
              {messages.length > 0
                ? messages.map((message, index) => {
                    // Single message bubble
                    return (
                      <ChatMessage
                        message={message}
                        userId={userId}
                        previousMessage={
                          index > 0 ? messages[index - 1] : undefined
                        }
                        finalMessage={messages.length - 1 === index}
                        messageBubbleAltWidth={messageBubbleAltWidth}
                      />
                    );
                  })
                : null}
            </div>
          </>
        ) : null}
      </div>
    </ScrollableFeed>
  );
};

export default ConversationsMessagesContainer;
