import {
  AccountData,
  Event,
  OrganizerType,
  TicketV2,
} from "@markit/common.types";
import { memo, useCallback, useContext, useMemo, useState } from "react";
import { MixpanelContext } from "../../context/AnalyticsService";
import {
  GetTime,
  isEventExternalLink,
  isFreeTicket,
} from "@markit/common.utils";
import { capitalizeFirstLetter } from "../../utils/stringUtils";
import { Colors } from "../../utils/colors";
import { useTheme } from "../../hooks/useTheme";
import {
  approveRequestGoingToEvent,
  denyRequestGoingToEvent,
  scanGuestToEvent,
  unscanGuestToEvent,
} from "../../utils/dashboardUtils";
import { DropdownMenu, DropdownMenuItem } from "../DropdownMenu";
import { API } from "../../API";
import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";
import ProfileItem from "../ProfileItem";
import { ProfileTicketItemButton } from "./ProfileTicketItemButton";
import { getTotalPromoterTicketsSold } from "../../utils/eventUtils/eventUtils";
import AlertContainer from "../Containers/AlertContainer";
import ConfirmDeleteModal from "../Containers/ConfirmPopups/ConfirmDeleteModal";
import { Icon } from "@iconify/react";
import ConfirmActionPopup from "../Containers/ConfirmPopups/ConfirmActionPopup";
import { GetLongDate } from "../../utils/GetLongDate";
import LabelButton from "../Buttons/LabelButton";

export const TICKET_FLEX_SECTIONS = [5, 2, 3];
export const ORGANIZER_TICKET_FLEX_SECTIONS = [7, 3];

enum TicketConfirmType {
  REMOVE_TICKET = "Remove Ticket",
  REMOVE_ROLE = "Remove Role",
  UNSCAN = "Unscan",
  DENY_REQUEST = "Deny Request",
  APPROVE_REQUEST = "Approve Request",
  DELETE_SUBMISSION = "Delete Submission",
}

type ProfileTicketItemProps = {
  event: Event;
  allGuestAccountData: AccountData[];
  ticket: TicketV2;
  listView: number;
  userTicket: TicketV2 | undefined;
  checkInGuests: boolean;
  setProfilePreviewSelected: (
    profilePreviewSelected: AccountData | undefined
  ) => void;
  setIsEventRoleModalOpen: (isEventRoleModalOpen: boolean) => void;
  setRoleOrganizerData: (roleOrganizerData: AccountData | undefined) => void;
  setRoleOrganizerTicketItem: (
    roleOrganizerTicketItem: TicketV2 | undefined
  ) => void;
};

const ProfileTicketItem = ({
  event,
  allGuestAccountData,
  ticket,
  listView,
  userTicket,
  checkInGuests,
  setProfilePreviewSelected,
  setIsEventRoleModalOpen,
  setRoleOrganizerData,
  setRoleOrganizerTicketItem,
}: ProfileTicketItemProps) => {
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const { theme } = useTheme();
  const mixpanel = useContext(MixpanelContext);

  const [alertText, setAlertText] = useState("");
  const [confirmType, setConfirmType] = useState<TicketConfirmType>();

  const organizer = useMemo(() => listView === 1, [listView]);
  const isRequestList = useMemo(() => listView === 2, [listView]);

  const [confirmAction, setConfirmAction] = useState<
    TicketConfirmType | undefined
  >(undefined);

  const isExternalLink = useMemo(
    () => isEventExternalLink(event.eventType),
    [event.eventType]
  );

  const profileItem = useMemo(
    () =>
      ticket.redeemedBy !== ""
        ? allGuestAccountData.find(
            (accountData) => accountData.uid === ticket.redeemedBy
          )
        : allGuestAccountData.find(
            (accountData) => accountData.uid === ticket.uid
          ),
    [allGuestAccountData, ticket.redeemedBy, ticket.uid]
  );

  const showViewProfile = useMemo(
    () =>
      ticket.role.type === OrganizerType.ATTENDEE &&
      ticket.uid !== accountData.uid,
    [accountData.uid, ticket.role.type, ticket.uid]
  );

  const showTicketActionButton = useMemo(
    () =>
      ((checkInGuests && profileItem && !organizer) ||
        (organizer && !profileItem)) &&
      !isExternalLink,
    [checkInGuests, isExternalLink, organizer, profileItem]
  );

  // Set as undefined if it's an organizer ticket OR if external event
  const ticketType = useMemo(
    () =>
      ticket.customTicketId === "" || isExternalLink
        ? undefined
        : event.customTickets.find(
            (ticketItem) => ticketItem.id === ticket.customTicketId
          )?.label,
    [event.customTickets, isExternalLink, ticket.customTicketId]
  );

  const unredeemedTicket = useMemo(
    () => ticket.redeemedBy === "" && !ticket.scanned,
    [ticket.redeemedBy, ticket.scanned]
  );

  const manuallyRedeemedTicket = useMemo(
    () =>
      ticket.redeemedBy === "manual redeemed" ||
      (ticket.redeemedBy === "" && ticket.scanned),
    [ticket.redeemedBy, ticket.scanned]
  );

  const originalOwnerOfTicket = useMemo(
    () => ticket.originalOwner === ticket.uid,
    [ticket.originalOwner, ticket.uid]
  );

  const hostDistributedTicket = useMemo(
    () =>
      ticket.originalOwner === event.createdBy ||
      event.cohosts.includes(ticket.originalOwner),
    [event.cohosts, event.createdBy, ticket.originalOwner]
  );

  const originalOwner = useMemo(
    () =>
      ticket.originalOwner !== ""
        ? allGuestAccountData.find(
            (accountData) => accountData.uid === ticket.originalOwner
          )
        : allGuestAccountData.find(
            (accountData) => accountData.uid === ticket.uid
          ),
    [allGuestAccountData, ticket.originalOwner, ticket.uid]
  );

  const isHost = useMemo(
    () => accountData.uid === event.createdBy,
    [accountData.uid, event.createdBy]
  );

  // ticket options shows up if
  // 1) You're looking at organizers and you're the host and the ticket item is one of the assigned roles
  // 2) You're looking at the attendees and you have dashboard access above 2
  // 3) isAdmin
  const canEdit = useMemo(
    () =>
      (organizer &&
        isHost &&
        !(!!profileItem && event.createdBy === profileItem.uid)) ||
      (!organizer && userTicket && userTicket.role.dashboardVisibility >= 2),
    [event.createdBy, isHost, organizer, profileItem, userTicket]
  );

  const removeFreeTicketOnPress = useCallback(async () => {
    if (profileItem) {
      await API.payment.removeFreeTicket({
        hostId: accountData.uid,
        userId: profileItem.uid,
        eventId: event.id,
        ticket: ticket,
        sendNotification: true,
      });
    }
  }, [accountData.uid, event.id, ticket, profileItem]);

  /* @TODO: Add handling for paid ticket once refund is implemented */
  const removeTicketPress = useCallback(() => {
    // if (!isFreeTicket) {
    //   navigation.navigate("EventRefund", {
    //     event: liveEvent,
    //     profileItem: profileItem,
    //     ticket: ticketItem,
    //   });
    // } else {
    if (isFreeTicket(event, ticket)) {
      setConfirmType(
        isExternalLink
          ? TicketConfirmType.DELETE_SUBMISSION
          : TicketConfirmType.REMOVE_TICKET
      );
    }
  }, [event, isExternalLink, ticket]);

  const editEventRole = useCallback(async () => {
    setIsEventRoleModalOpen(true);
    setRoleOrganizerData(profileItem);
    setRoleOrganizerTicketItem(ticket);
  }, [
    profileItem,
    setIsEventRoleModalOpen,
    setRoleOrganizerData,
    setRoleOrganizerTicketItem,
    ticket,
  ]);

  const deleteEventRole = useCallback(async () => {
    if (event) {
      try {
        await API.events.deleteOrganizerTickets({
          event: event,
          ticketId: ticket.id,
        });
        mixpanel.track("Delete Event Role", {
          event_id: event.id,
          selected_user_id: ticket.uid,
          is_unassigned: false,
          organizer_role: ticket.role,
          is_payment_collector: ticket.role.alternatePaymentCollector,
          revenue_percent_split: ticket.role.revenuePercentSplit,
        });
      } catch (err: any) {
        console.error(err.message);
      }
    }
  }, [event, mixpanel, ticket]);

  const deleteEventRolePrompt = useCallback(async () => {
    if (
      event &&
      ticket.role.revenuePercentSplit &&
      ticket.role.revenuePercentSplit > 0
    ) {
      // must check if they have sold tickets
      const ticketsSold = await getTotalPromoterTicketsSold(
        event.id,
        ticket.uid
      );
      if (ticketsSold > 0) {
        setAlertText(
          "You cannot delete this role since the promoter has already sold a ticket"
        );
      } else {
        deleteEventRole();
      }
    } else {
      deleteEventRole();
    }
  }, [deleteEventRole, event, ticket.role.revenuePercentSplit, ticket.uid]);

  const viewProfilePreview = useCallback(() => {
    setProfilePreviewSelected(profileItem);
  }, [setProfilePreviewSelected, profileItem]);

  const scanTicket = useCallback(() => {
    scanGuestToEvent(event.id, ticket.id);
  }, [event.id, ticket.id]);

  const approveRequestGuest = useCallback(() => {
    if (profileItem) {
      approveRequestGoingToEvent(event.id, ticket.id, profileItem);
    }
    setConfirmAction(TicketConfirmType.APPROVE_REQUEST);
  }, [event.id, profileItem, ticket.id]);

  const denyRequestGuest = useCallback(() => {
    if (profileItem) {
      denyRequestGoingToEvent(event.id, ticket.id, profileItem);
      setConfirmAction(TicketConfirmType.DENY_REQUEST);
    }
  }, [event.id, profileItem, ticket.id]);

  const dropdownItems: DropdownMenuItem[] = useMemo(() => {
    const menuItems: DropdownMenuItem[] = [];

    if (profileItem) {
      menuItems.push({
        title: "Message",
        key: "message",
        onPress: viewProfilePreview,
      });
    }
    if (organizer && profileItem) {
      menuItems.push({
        title: "Edit Role",
        key: "edit_role",
        onPress: editEventRole,
      });
    }
    if (organizer) {
      menuItems.push({
        title: "Delete Role",
        key: "delete_role",
        onPress: () => setConfirmType(TicketConfirmType.REMOVE_ROLE),
        isDestructive: true,
      });
    }
    if (!organizer && isFreeTicket(event, ticket)) {
      menuItems.push({
        title: isExternalLink ? "Delete Submission" : "Remove Ticket",
        key: isExternalLink ? "delete_submission" : "remove_ticket",
        onPress: removeTicketPress,
        isDestructive: true,
      });
    }
    if (!organizer && !isFreeTicket(event, ticket)) {
      menuItems.push({
        title: "Refund In Mobile App",
        key: "refund",
        onPress: () => {},
        disabled: true, // refund not implemeneted on webapp
      });
    }
    return menuItems;
  }, [
    editEventRole,
    event,
    isExternalLink,
    organizer,
    profileItem,
    removeTicketPress,
    ticket,
    viewProfilePreview,
  ]);

  const renderTicketButtonItem = useMemo(
    () => (
      <ProfileTicketItemButton
        ticketItem={ticket}
        profileItem={profileItem}
        organizer={organizer}
        isRequestList={isRequestList}
        scanTicket={scanTicket}
        unscanTicket={() => {
          setConfirmType(TicketConfirmType.UNSCAN);
        }}
        approveRequestGuest={approveRequestGuest}
        denyRequestGuest={() => {
          setConfirmType(TicketConfirmType.DENY_REQUEST);
        }}
        liveEvent={event}
      />
    ),
    [
      approveRequestGuest,
      event,
      isRequestList,
      organizer,
      profileItem,
      scanTicket,
      ticket,
    ]
  );

  const renderMainItem = useMemo(
    () => (
      <ProfileItem
        profileItem={profileItem}
        onItemPress={viewProfilePreview}
        organizer={organizer}
      />
    ),
    [organizer, profileItem, viewProfilePreview]
  );

  return (
    <>
      <div>
        <div className="AlignedRow" style={{ paddingBlock: 10 }}>
          <div
            style={{
              marginLeft: 14,
              flex:
                ticketType !== undefined
                  ? TICKET_FLEX_SECTIONS[0]
                  : ORGANIZER_TICKET_FLEX_SECTIONS[0],
              pointerEvents: showViewProfile ? "all" : "none",
            }}
          >
            {renderMainItem}
          </div>
          {ticketType !== undefined ? (
            <div
              style={{
                flex: TICKET_FLEX_SECTIONS[1],
                color: theme.SubText.color,
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              <div style={{ marginLeft: 26 }}>{ticketType}</div>
            </div>
          ) : null}
          <div
            className="AlignedRowSpaced"
            style={{
              flex:
                ticketType !== undefined
                  ? TICKET_FLEX_SECTIONS[2]
                  : ORGANIZER_TICKET_FLEX_SECTIONS[1],

              marginRight: 20,
            }}
          >
            <LabelButton
              color={organizer ? theme.PrimaryText.color : theme.SubText.color}
              backgroundColor={isExternalLink ? "transparent" : Colors.WHITE1}
              text={
                organizer
                  ? capitalizeFirstLetter(ticket.role.type)
                  : isExternalLink
                  ? `${GetLongDate(
                      ticket.createdAt,
                      true,
                      true,
                      false
                    )}, ${GetTime(new Date(ticket.createdAt))}`
                  : ticket.requestStatus === "pending"
                  ? "Pending"
                  : hostDistributedTicket
                  ? "Free Ticket from Host"
                  : unredeemedTicket || manuallyRedeemedTicket
                  ? "Extra Ticket"
                  : originalOwnerOfTicket
                  ? "Primary Ticket"
                  : `Redeemed, from ${originalOwner?.fullName}`
              }
              containerStyles={{ marginLeft: 18 }}
            />
            <div className="AlignedRow" style={{ gap: 10 }}>
              {showTicketActionButton ? renderTicketButtonItem : null}
              {canEdit ? (
                <DropdownMenu
                  dropdownItems={dropdownItems}
                  trigger={["click"]}
                  placement="bottomLeft"
                >
                  <Icon
                    icon="ion:ellipsis-horizontal"
                    height={20}
                    color={Colors.BLACK}
                  />
                </DropdownMenu>
              ) : null}
            </div>
          </div>
        </div>
        <HorizontalDivider />
      </div>
      <AlertContainer
        headerComp={alertText}
        theme={theme}
        closeModal={() => setAlertText("")}
        hideModal={alertText === ""}
      />
      <ConfirmDeleteModal
        heading={
          confirmType === TicketConfirmType.DELETE_SUBMISSION
            ? "Are you sure you want to delete this submission?"
            : confirmType === TicketConfirmType.REMOVE_TICKET
            ? "Are you sure you want to remove this ticket?"
            : confirmType === TicketConfirmType.REMOVE_ROLE
            ? "Are you sure you want to delete this role?"
            : confirmType === TicketConfirmType.UNSCAN
            ? "Are you sure you want to unscan this attendee?"
            : "Are you sure you want to deny this user’s request?"
        }
        subtext={
          confirmType === TicketConfirmType.REMOVE_TICKET ||
          confirmType === TicketConfirmType.REMOVE_ROLE ||
          confirmType === TicketConfirmType.DELETE_SUBMISSION
            ? "This action cannot be undone."
            : undefined
        }
        deleteButtonText={
          confirmType === TicketConfirmType.REMOVE_TICKET
            ? "Remove"
            : confirmType === TicketConfirmType.REMOVE_ROLE ||
              confirmType === TicketConfirmType.DELETE_SUBMISSION
            ? "Delete"
            : confirmType === TicketConfirmType.UNSCAN
            ? "Unscan"
            : "Deny"
        }
        hideModal={!confirmType}
        setIsVisible={() => setConfirmType(undefined)}
        deleteOnPress={() =>
          confirmType === TicketConfirmType.REMOVE_TICKET ||
          confirmType === TicketConfirmType.DELETE_SUBMISSION
            ? removeFreeTicketOnPress()
            : confirmType === TicketConfirmType.REMOVE_ROLE
            ? deleteEventRolePrompt()
            : confirmType === TicketConfirmType.UNSCAN
            ? unscanGuestToEvent(event.id, ticket.id)
            : profileItem
            ? denyRequestGuest()
            : undefined
        }
        theme={theme}
      />
      {confirmAction ? (
        <ConfirmActionPopup
          title="Approved"
          negativeTitle="Rejected"
          isNegative={confirmAction === TicketConfirmType.DENY_REQUEST}
          onDisappear={() => setConfirmAction(undefined)}
        />
      ) : null}
    </>
  );
};

export default memo(ProfileTicketItem);
