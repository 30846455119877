import "../../css/FullEvent/DisplayQRCode.css";
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FiClock } from "react-icons/fi";
import { HiOutlineTicket } from "react-icons/hi";
import { GetLongDate } from "../../utils/GetLongDate";
import { useWindowSize } from "@react-hook/window-size";
import QRCode from "react-qr-code";
import AppleWalletButton from "../../assets/AppleWalletButton.png";
import { useOnMount } from "../../utils/useOnMount";
import { API, API_BASE } from "../../API";
import { isDesktop, isIOS } from "react-device-detect";
import Calendar from "../../components/Calendar";
import { InitialsPic } from "../../components/InitialsPic";
import {
  useAccountSelector,
  useEventSelector,
  useParametricSelector,
} from "../../hooks/useParametricSelector";
import { getLoadedEvent, getLoadedUser } from "../../redux/slices/dataSlice";
import LoadingScreen from "../LoadingScreen";
import { MixpanelContext } from "../../context/AnalyticsService";
import { useSelector } from "react-redux";
import { getUrlParamState } from "../../redux/slices/urlParamSlice";
import {
  AccountData,
  OrganizerType,
  RequestStatus,
  TicketV2,
} from "@markit/common.types";
import {
  friendlyRoleName,
  isFreeTicket,
  uniqueVals,
} from "@markit/common.utils";
import { GetTime } from "../../utils/GetTime";
import { Colors } from "../../utils/colors";
import { onShareLink } from "../../utils/shareLink";
import { getTicketsRef, getUserData } from "../../utils/FirebaseUtils";
import { truncateString } from "../../utils/stringUtils";
import { RWebShare } from "react-web-share";
import { IoIosArrowDown } from "react-icons/io";
import { Icon } from "@iconify/react";
import { DropdownMenu, DropdownMenuItem } from "../../components/DropdownMenu";
import { onSnapshot, query, where } from "firebase/firestore";
import TransferMainTicket from "../../components/FullEventSubComponents/TransferMainTicket";
import EmptyStateButton from "../../components/Buttons/EmptyStateButton";
import AlertContainer from "../../components/Containers/AlertContainer";
import ConfirmDeleteModal from "../../components/Containers/ConfirmPopups/ConfirmDeleteModal";
import { useNavigate } from "react-router-dom";

const DisplayQRCode = memo(function QRCodeFn() {
  const mixpanel = useContext(MixpanelContext);
  const { openPass } = useSelector(getUrlParamState);
  const navigate = useNavigate();
  const [tickets, setTickets] = useState<TicketV2[]>([]);
  const [selectedTicket, setSelectedTicket] = useState<TicketV2>();
  const [otherUserData, setOtherUserData] = useState<AccountData[]>([]);
  const [height, width] = useWindowSize();
  const [loading, setLoading] = useState(false);
  const [ticketsLoading, setTicketsLoading] = useState(true);
  const [showTransferTicket, setShowTransferTicket] = useState(false);

  const [alertText, setAlertText] = useState("");
  const [removeTicketConfirm, setRemoveTicketConfirm] = useState(false);
  const [currTicket, setCurrTicket] = useState<TicketV2>();

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const refContainer = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (refContainer.current) {
      setDimensions({
        width: refContainer.current.offsetWidth,
        height: refContainer.current.offsetHeight,
      });
    }
  }, []);

  const eventId = useEventSelector();
  const {
    isLoading: isLoadingEventData,
    data: event,
    isError: isErrorEventData,
  } = useParametricSelector(getLoadedEvent, eventId);
  const userId = useAccountSelector();
  const {
    isLoading: isLoadingUserData,
    data: userData,
    isError: isErrorUserData,
  } = useParametricSelector(getLoadedUser, userId);

  const addTicketData = useCallback((newTicket: TicketV2) => {
    setTickets((fetchedTicketData) => {
      const index = fetchedTicketData.findIndex(
        (fetched) => fetched.id === newTicket.id
      );
      if (index !== -1) {
        fetchedTicketData.splice(index, 1, newTicket);
      } else {
        fetchedTicketData = fetchedTicketData.concat(newTicket);
      }
      return uniqueVals(
        fetchedTicketData,
        (fetchedTicketData) => fetchedTicketData.id
      );
    });
  }, []);

  const removeTicketData = useCallback((newTicket: TicketV2) => {
    setTickets((fetchedTicketData) => {
      const index = fetchedTicketData.findIndex(
        (fetched) => fetched.id === newTicket.id
      );
      if (index !== -1) {
        fetchedTicketData.splice(index, 1);
      }
      return uniqueVals(
        fetchedTicketData,
        (fetchedTicketData) => fetchedTicketData.id
      );
    });
  }, []);

  useOnMount(() => {
    (async () => {
      mixpanel.track("Webapp: Viewed QR Code", {
        event_id: eventId,
      });

      const ticketsRef = getTicketsRef(eventId);
      const querySnapshot = query(
        ticketsRef,
        where("uid", "==", userId),
        where("role.type", "==", OrganizerType.ATTENDEE)
      );
      const unsubscribe = onSnapshot(querySnapshot, async (snapshot) => {
        snapshot.docChanges().forEach(async (change) => {
          const newTicket = change.doc.data();

          if (change.type === "added" || change.type === "modified") {
            addTicketData(newTicket);
          } else if (change.type === "removed") {
            removeTicketData(newTicket);
          }
        });
      });

      const querySnapshotOtherUsers = query(
        ticketsRef,
        where("originalOwner", "==", userId),
        where("uid", "!=", userId)
      );
      const unsubscribeOtherUsers = onSnapshot(
        querySnapshotOtherUsers,
        async (snapshot) => {
          snapshot.docChanges().forEach(async (change) => {
            const newTicket = change.doc.data();
            const userData = await getUserData(newTicket.uid);

            if (change.type === "added" || change.type === "modified") {
              addTicketData(newTicket);
              if (userData) {
                setOtherUserData((prevUserData) => {
                  const index = prevUserData.findIndex(
                    (prevUser) => prevUser.uid === userData.uid
                  );
                  if (index !== -1) {
                    prevUserData.splice(index, 1, userData);
                  } else {
                    prevUserData = prevUserData.concat(userData);
                  }
                  return uniqueVals(
                    prevUserData,
                    (prevUserData) => prevUserData.uid
                  );
                });
              }
            } else if (change.type === "removed") {
              removeTicketData(newTicket);
              if (userData) {
                setOtherUserData((prevUserData) => {
                  const index = prevUserData.findIndex(
                    (prevUser) => prevUser.uid === userData.uid
                  );
                  if (index !== -1) {
                    prevUserData.splice(index, 1);
                  }
                  return uniqueVals(
                    prevUserData,
                    (prevUserData) => prevUserData.uid
                  );
                });
              }
            }
          });
        }
      );
      setTicketsLoading(false);

      return { unsubscribe, unsubscribeOtherUsers };
    })();
  });

  const mainTicketIndex = useMemo(
    () => tickets.findIndex((ticket) => ticket.redeemedBy === userId),
    [tickets, userId]
  );

  const finalTickets = useMemo(() => {
    if (mainTicketIndex !== -1) {
      [tickets[0], tickets[mainTicketIndex]] = [
        tickets[mainTicketIndex],
        tickets[0],
      ];
    }
    return tickets;
  }, [mainTicketIndex, tickets]);

  // show transfer ticket button if the user only has one ticket and it's a paid ticket
  const showTransferTicketButton = useMemo(() => {
    if (tickets.length === 1 && event) {
      const customTicket = event.customTickets.find(
        (ticket) => ticket.id === tickets[0].customTicketId
      );
      if (customTicket && customTicket.price > 0 && tickets[0].amountPaid > 0) {
        return true;
      }
    }
    return false;
  }, [event, tickets]);

  const navigateToFullEvent = useCallback(() => {
    if (event) {
      navigate(`/e/${event.id}`);
    }
  }, [event, navigate]);

  const addToAppleWallet = async () => {
    if (userData === undefined) {
      return;
    }

    setLoading(true);
    await fetch(
      API_BASE +
        "passkit/create?fullName=" +
        encodeURIComponent(userData.fullName) +
        "&uid=" +
        userData.uid,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/vnd.apple.pkpass",
        },
      }
    )
      .then((res) => res.blob())
      .then((blob: Blob) => {
        if (blob) {
          const fileURL = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = fileURL;
          link.style.display = "none";
          link.click();
        }
      });

    setLoading(false);
  };

  useOnMount(() => {
    if (openPass && isIOS) {
      addToAppleWallet();
    }
  });

  const onPressTicket = useCallback(
    async (ticketId: string) => {
      if (event) {
        await onShareLink(
          "",
          "https://markitai.com/e/" + event.id + "?ticketId=" + ticketId
        );
      }
    },
    [event]
  );

  const ticketIsRedeemed = useCallback(
    (ticket: TicketV2) => {
      if (ticket.redeemedBy && ticket.redeemedBy !== userData?.uid) {
        return true;
      }
      return false;
    },
    [userData?.uid]
  );

  const switchMainTicket = useCallback(
    async (eventId: string, newTicketId: string, uid: string) => {
      await API.modifyTicket
        .swapTicket({
          eventId,
          newTicketId,
          userId: uid,
          creatorId: event?.createdBy ?? uid,
        })
        .then((response) => {
          setAlertText("Successfully swapped tickets.");
          window.location.reload();
        })
        .catch((err: any) => {
          console.error(err.message);
        });
    },
    [event?.createdBy]
  );

  const removeFreeTicketOnPress = useCallback(
    async (ticket: TicketV2) => {
      if (event && userData) {
        await API.payment.removeFreeTicket({
          hostId: event.createdBy,
          userId: userData.uid,
          eventId: event.id,
          ticket: ticket,
          sendNotification: false,
        });
        if (tickets.length === 1) {
          navigate(`/e/${eventId}/i/${userId}`, {
            state: { eventId: eventId, userId: userId },
          });
        }
      }
    },
    [event, eventId, navigate, tickets.length, userData, userId]
  );

  /* @TODO: Add handling for paid ticket once refund is implemented */
  const removeTicketPress = useCallback((ticket: TicketV2) => {
    setRemoveTicketConfirm(true);
    setCurrTicket(ticket);
  }, []);

  const onTransferTicketClick = useCallback(() => {
    window.scrollTo(0, 0);
    setShowTransferTicket(true);
  }, [setShowTransferTicket]);

  const getTicketLabel = useCallback(
    (ticket: TicketV2) => {
      let newTicketLabel = "Not Found";
      if (event) {
        if (ticket.customTicketId === "") {
          newTicketLabel = friendlyRoleName(ticket);
        } else {
          const ticketLabel = event.customTickets.find(
            (customTicket) => customTicket.id === ticket.customTicketId
          );
          if (ticketLabel) {
            newTicketLabel = ticketLabel.label;
          }
        }
      }
      return newTicketLabel;
    },
    [event]
  );

  const renderGoingButton = (ticket: TicketV2) => {
    return event && isFreeTicket(event, ticket) ? (
      <DropdownMenu
        dropdownItems={dropdownItems}
        trigger={["click"]}
        placement="bottomLeft"
        altWidth={150}
      >
        <div
          className="QRRoundedButton"
          style={{
            backgroundColor: ticketIsRedeemed(ticket)
              ? Colors.GRAY1
              : Colors.GRAY6,
            cursor: ticketIsRedeemed(ticket) ? "auto" : "pointer",
          }}
          onClick={(e) => {
            setSelectedTicket(ticket);
          }}
        >
          <span
            style={{
              fontSize: "12px",
              color: ticketIsRedeemed(ticket) ? Colors.WHITE : Colors.BLACK,
            }}
          >
            {ticket.requestStatus === RequestStatus.ACCEPTED
              ? "Going"
              : "Pending"}
          </span>
          {!ticketIsRedeemed(ticket) && (
            <IoIosArrowDown
              size={14}
              style={{
                color: Colors.GRAY1,
                cursor: "pointer",
              }}
            />
          )}
        </div>
      </DropdownMenu>
    ) : null;
  };

  const renderShareItem = (ticket: TicketV2, index: number) => {
    return (
      <div style={{ display: "flex", flexDirection: "row", gap: "7px" }}>
        {renderGoingButton(ticket)}
        <div
          className="QRRoundedButton"
          style={{
            backgroundColor: ticketIsRedeemed(ticket)
              ? Colors.GRAY1
              : Colors.GRAY6,
            flexDirection: "column",
            cursor: ticketIsRedeemed(ticket) ? "auto" : "pointer",
          }}
          onClick={() =>
            ticketIsRedeemed(ticket)
              ? null
              : switchMainTicket(eventId, ticket.id, userId)
          }
        >
          <span
            style={{
              fontSize: "12px",
              color: ticketIsRedeemed(ticket) ? Colors.WHITE : Colors.BLACK,
            }}
          >
            Make Main Ticket
          </span>
        </div>
        {index > 0 &&
          (ticketIsRedeemed(ticket) ? (
            renderShareButton(ticket)
          ) : (
            <RWebShare
              data={{
                url:
                  "https://markitai.com/e/" +
                  event?.id +
                  "?ticketId=" +
                  ticket.id,
              }}
            >
              {renderShareButton(ticket)}
            </RWebShare>
          ))}
      </div>
    );
  };

  const renderShareButton = (ticket: TicketV2) => {
    return (
      <div
        className="QRRoundedButton"
        style={{
          pointerEvents: ticketIsRedeemed(ticket) ? "none" : "all",
          backgroundColor: ticketIsRedeemed(ticket)
            ? Colors.GRAY1
            : Colors.GRAY6,
        }}
        onClick={() => onPressTicket(ticket.id)}
        key={ticket.id}
      >
        <span
          style={{
            fontSize: 12,
            color: ticketIsRedeemed(ticket) ? Colors.WHITE : Colors.BLACK,
          }}
        >
          Share
        </span>
        <Icon
          icon="lucide:send"
          height={12}
          style={{
            cursor: "pointer",
            color: ticketIsRedeemed(ticket) ? Colors.WHITE : Colors.BLACK,
          }}
        />
      </div>
    );
  };

  const renderShareItemDesktop = (ticket: TicketV2, index: number) => {
    return (
      <div style={{ display: "flex", flexDirection: "row", gap: "7px" }}>
        {renderGoingButton(ticket)}
        {/** Comment out until a better solution is found rather than removing ownership of it **/}
        {/* {showTransferTicketButton ? (
          <div
            className="QRRoundedButton"
            style={{
              backgroundColor: Colors.GRAY6,
              cursor: "pointer",
            }}
            onClick={onTransferTicketClick}
          >
            Transfer Ticket
          </div>
        ) : null} */}
        {index > 0 && (
          <div
            className="QRRoundedButton"
            style={{
              backgroundColor: ticketIsRedeemed(ticket)
                ? Colors.GRAY1
                : Colors.GRAY6,
              cursor: ticketIsRedeemed(ticket) ? "auto" : "pointer",
            }}
            onClick={() =>
              ticketIsRedeemed(ticket)
                ? null
                : switchMainTicket(eventId, ticket.id, userId)
            }
          >
            <span
              style={{
                fontSize: 12,
                color: ticketIsRedeemed(ticket) ? Colors.WHITE : Colors.BLACK,
              }}
            >
              Make Main Ticket
            </span>
          </div>
        )}
        {index > 0 &&
          (ticketIsRedeemed(ticket) ? (
            renderShareButton(ticket)
          ) : (
            <RWebShare
              data={{
                url:
                  "https://markitai.com/e/" +
                  event?.id +
                  "?ticketId=" +
                  ticket.id,
              }}
            >
              {renderShareButton(ticket)}
            </RWebShare>
          ))}
      </div>
    );
  };

  const renderConfirmModals = useMemo(
    () => (
      <>
        <AlertContainer
          headerComp={alertText}
          closeModal={() => setAlertText("")}
          hideModal={alertText === ""}
        />
        <ConfirmDeleteModal
          heading="Are you sure you want to remove this ticket?"
          subtext="This cannot be undone."
          deleteButtonText="Remove"
          hideModal={!removeTicketConfirm}
          setIsVisible={setRemoveTicketConfirm}
          deleteOnPress={() => {
            if (event && currTicket && isFreeTicket(event, currTicket)) {
              removeFreeTicketOnPress(currTicket);
            }
          }}
        />
      </>
    ),
    [alertText, currTicket, event, removeFreeTicketOnPress, removeTicketConfirm]
  );

  const cancelTicketOnPress = useCallback(() => {
    if (selectedTicket) {
      removeTicketPress(selectedTicket);
    }
  }, [removeTicketPress, selectedTicket]);

  const dropdownItems: DropdownMenuItem[] = useMemo(
    () => [
      {
        title: "Cancel Ticket",
        icon: "ion:exit-outline",
        key: "cancel_ticket",
        onPress: cancelTicketOnPress,
        isDestructive: true,
      },
    ],
    [cancelTicketOnPress]
  );

  if (isLoadingEventData || isLoadingUserData || ticketsLoading) {
    return <LoadingScreen />;
  }
  if (
    isErrorUserData ||
    isErrorEventData ||
    event == null ||
    userData == null
  ) {
    return <LoadingScreen error />;
  }

  // Empty state if no tickets for the user
  if (!ticketsLoading && finalTickets.length === 0) {
    return (
      <div style={{ height: "100vh" }} className="ColumnCenter">
        <EmptyStateButton
          title="No Tickets to Show"
          icon={<Icon icon="ion:ticket" height={40} color={Colors.BLACK} />}
        />
      </div>
    );
  }

  if (isDesktop) {
    return (
      <div ref={refContainer} className="QRContainerDesktop">
        <div className="QRCodeDesktop">
          {finalTickets.map((ticket, index) => {
            return (
              <div className="QRTicketContainerDesktop">
                <div className="QRBodyDesktop">
                  {userData.profilePicURL && userData.profilePicURL !== "" ? (
                    <img
                      className="QRProfile"
                      src={userData.profilePicURL}
                      alt="ProfilePic"
                    />
                  ) : (
                    <div className="QRProfileInitials">
                      <InitialsPic fullName={userData.fullName} />
                    </div>
                  )}
                  <span className="QRFullName">{userData.fullName}</span>
                  <span className="QRUsername">@{userData.username}</span>
                  <div className="QRCodeImage">
                    {ticketIsRedeemed(ticket) && index !== 0 ? (
                      <div
                        className="QRCodeRedeemedItem"
                        style={{ height: height / 10, width: width / 6 }}
                      >
                        <span style={{ fontSize: 13, color: Colors.GRAY1 }}>
                          Ticket redeemed by
                        </span>
                        <span style={{ fontSize: 13, marginTop: 2 }}>
                          {truncateString(
                            otherUserData.find(
                              (user) => user.uid === ticket.uid
                            )?.fullName ?? "other user",
                            20,
                            "..."
                          )}
                        </span>
                      </div>
                    ) : (
                      <QRCode
                        size={height / 10}
                        value={
                          index !== 0
                            ? userData.uid + "+" + ticket.id + "+" + (index + 1)
                            : userData.uid
                        }
                      />
                    )}
                  </div>
                  <div className="QRCodeTicketNumberDesktop">
                    <span style={{ fontSize: 12 }}>
                      Ticket {index + 1} of {finalTickets.length}
                    </span>
                  </div>
                </div>
                {event ? (
                  <div className="QRHeaderDesktop">
                    <div className="QRSectionDesktop">
                      <span className="QRPrompt">Ticket type</span>
                      <span className="QRTitle">
                        <HiOutlineTicket
                          style={{ marginBottom: -3, marginRight: 3 }}
                        />
                        {getTicketLabel(ticket)}
                      </span>
                    </div>
                    <button
                      disabled={ticketIsRedeemed(ticket)}
                      key={ticket.id}
                      style={{
                        padding: 0,
                        marginBottom:
                          event && !isFreeTicket(event, ticket) && index === 0
                            ? 0
                            : 10,
                        backgroundColor: Colors.WHITE,
                        border: "none",
                      }}
                    >
                      {renderShareItemDesktop(ticket, index)}
                    </button>
                    <hr style={{ borderBottom: "1px dashed #929292" }} />
                    <div className="QRSectionDesktop">
                      <span className="QRPrompt">Event Name</span>
                      <span className="QRTitle">{event.title}</span>
                      <span
                        style={{
                          marginTop: 5,
                          fontSize: 12,
                          color: Colors.BLUE1,
                        }}
                        onClick={navigateToFullEvent}
                      >
                        View full event
                      </span>
                    </div>
                    <hr style={{ borderBottom: "1px dashed #929292" }} />
                    <div className="QRSectionDesktop">
                      <span className="QRPrompt">Event Date</span>
                      <span className="QRTitle">
                        <FiClock style={{ marginBottom: -2 }} />{" "}
                        {GetLongDate(event.start, false, true)} at{" "}
                        {GetTime(event.start)}
                      </span>
                      <div style={{ alignSelf: "flex-start", marginTop: 5 }}>
                        <Calendar
                          event={event}
                          loading={false}
                          numOfTickets={0}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="QRHeader">
                    <p className="QRTitle" style={{ width: "80%" }}>
                      This is your personal QR code. Show this code as your
                      ticket to Markit events.
                    </p>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        {showTransferTicket ? (
          <div
            className={"ShareTicketsHeaderMobile"}
            style={{
              // TODO (PETER): Better solution than this
              height: dimensions.height + 2000,
            }}
          >
            <TransferMainTicket
              mainUserTicket={tickets[mainTicketIndex]}
              userId={event.createdBy}
              eventId={event.id}
              fullName={userData.fullName}
              setShowTransferTicket={setShowTransferTicket}
            />
          </div>
        ) : null}
        {renderConfirmModals}
      </div>
    );
  }
  return (
    <div
      ref={refContainer}
      className="QRContainer"
      style={{
        backgroundImage: event ? "url(" + event.photoURL : "",
      }}
    >
      <div className="QRCode">
        {finalTickets.map((ticket, index) => {
          return (
            <div
              style={{
                paddingTop: 30,
                marginLeft: index === 0 ? 24 : 6,
              }}
            >
              <div className="QRBody">
                {userData.profilePicURL && userData.profilePicURL !== "" ? (
                  <img
                    className="QRProfile"
                    src={userData.profilePicURL}
                    alt="ProfilePic"
                  />
                ) : (
                  <div className="QRProfileInitials">
                    <InitialsPic fullName={userData.fullName} />
                  </div>
                )}
                <span className="QRFullName">{userData.fullName}</span>
                <span className="QRUsername">@{userData.username}</span>
                <div className="QRCodeImage">
                  {ticketIsRedeemed(ticket) && index !== 0 ? (
                    <div
                      className="QRCodeRedeemedItem"
                      style={{ height: height / 2.2, width: height / 2.2 }}
                    >
                      <span
                        style={{
                          fontSize: 13,
                          color: Colors.GRAY1,
                        }}
                      >
                        Ticket redeemed by
                      </span>
                      <span style={{ fontSize: 13, marginTop: 2 }}>
                        {truncateString(
                          otherUserData.find((user) => user.uid === ticket.uid)
                            ?.fullName ?? "other user",
                          20,
                          "..."
                        )}
                      </span>
                    </div>
                  ) : (
                    <QRCode
                      size={height / 2.2}
                      value={
                        index !== 0
                          ? userData.uid + "+" + ticket.id + "+" + (index + 1)
                          : userData.uid
                      }
                    />
                  )}
                </div>
                {index === 0 && loading && isIOS ? (
                  <h3 className="loadingText">...</h3>
                ) : index === 0 && isIOS ? (
                  <div style={{ display: "flex", gap: "7px" }}>
                    {/* {showTransferTicketButton ? (
                      <div
                        className="QRRoundedButton"
                        style={{
                          backgroundColor: Colors.GRAY6,
                          cursor: "pointer",
                        }}
                        onClick={onTransferTicketClick}
                      >
                        <span style={{ fontSize: 12 }}>Transfer Ticket</span>
                      </div>
                    ) : ( */}
                    {renderGoingButton(ticket)}
                    {/* )} */}
                    <img
                      style={{ height: 30 }}
                      alt="Add to Apple Wallet"
                      src={AppleWalletButton}
                      onClick={addToAppleWallet}
                    />
                  </div>
                ) : index > 0 ? (
                  renderShareItem(ticket, index)
                ) : null}
                <div className="QRCodeTicketNumber">
                  <span style={{ fontSize: 12 }}>
                    Ticket {index + 1} of {finalTickets.length}
                  </span>
                </div>
              </div>
              {event ? (
                <div className="QRHeader">
                  <div className="QRSection">
                    <span className="QRPrompt">Ticket type</span>
                    <span className="QRTitle">
                      <HiOutlineTicket
                        size={16}
                        style={{ marginBottom: -3, marginRight: 3 }}
                      />
                      {getTicketLabel(ticket)}
                    </span>
                  </div>
                  <hr
                    style={{
                      borderBottom: "1px dashed #929292",
                      width: "100%",
                    }}
                  />
                  <div className="QRSection">
                    <span className="QRPrompt">Event Name</span>
                    <span
                      className="QRTitle"
                      style={{ textAlign: "center", marginInline: 20 }}
                    >
                      {event.title}
                    </span>
                    <span
                      style={{
                        marginTop: 5,
                        fontSize: 12,
                        color: Colors.BLUE1,
                      }}
                      onClick={navigateToFullEvent}
                    >
                      View full event
                    </span>
                  </div>
                  <hr
                    style={{
                      borderBottom: "1px dashed #929292",
                      width: "100%",
                    }}
                  />
                  <div className="QRSection">
                    <span className="QRPrompt">Event Date</span>
                    <span className="QRTitle">
                      <FiClock style={{ marginBottom: -2 }} />{" "}
                      {GetLongDate(event.start, false, true)} at{" "}
                      {GetTime(event.start)}
                    </span>
                    <Calendar event={event} loading={false} numOfTickets={0} />
                  </div>
                </div>
              ) : (
                <div className="QRHeader">
                  <p className="QRTitle" style={{ width: "80%" }}>
                    This is your personal QR code. Show this code as your ticket
                    to Markit events.
                  </p>
                </div>
              )}
            </div>
          );
        })}
      </div>
      {showTransferTicket ? (
        <div className={"GuestList"}>
          <TransferMainTicket
            mainUserTicket={tickets[mainTicketIndex]}
            userId={event.createdBy}
            eventId={event.id}
            fullName={userData.fullName}
            setShowTransferTicket={setShowTransferTicket}
          />
        </div>
      ) : null}
      {renderConfirmModals}
    </div>
  );
});

export default DisplayQRCode;
