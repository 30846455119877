import { AccountData, EventbriteInfo } from "@markit/common.types";
import { API } from "../API";
import { AppThunk } from "../redux/store";
import {
  accountActions,
  updateAccountData,
} from "../redux/slices/accountSlice";
import { deleteEventData } from "../redux/slices/eventSlice";
import { Mixpanel } from "../context/AnalyticsService";
import {
  isExternalEventbrite,
  MARKIT_HOTLINE_NUMBER,
} from "@markit/common.utils";
import { getUserRef } from "./FirebaseUtils";
import { updateDoc } from "../firebase";
import { formatPhoneNumber } from "./FormatPhoneNumber";

export const EventbriteApiLimitAlert = `Due to the high amount of activity, we have prevented any actions at this time. Please try again in an hour or contact us at ${formatPhoneNumber(
  MARKIT_HOTLINE_NUMBER
)}.`;

// When a user edits their eventbrite integration and turns on/off the auto collect phone number
export const configureAutoCollectPhoneQuestion =
  (
    eventbriteInfo: EventbriteInfo,
    userId: string,
    newAutoAddPhoneQuestion: boolean
  ): AppThunk =>
  async (dispatch) => {
    if (newAutoAddPhoneQuestion) {
      // Add phone question to all upcoming events
      const { success, rateLimits } =
        await API.eventScraper.addEventbritePhoneQuestions({
          eventbriteInfo: eventbriteInfo,
          userId: userId,
        });
      if (!success) {
        if (rateLimits) {
          alert(EventbriteApiLimitAlert);
          return;
        }
        alert("An error occurred. Please try again later.");
        return;
      }
    }
    const userRef = getUserRef(userId);
    updateDoc(userRef, {
      "eventbriteInfo.autoAddPhoneQuestion": newAutoAddPhoneQuestion,
    });
    const newEventbriteInfo: EventbriteInfo = {
      ...eventbriteInfo,
      autoAddPhoneQuestion: newAutoAddPhoneQuestion,
    };
    dispatch(
      accountActions.updateAccount({ eventbriteInfo: newEventbriteInfo })
    );
  };

// Determines if the user has last synced their data within the past day
// If admin, always allow resyncing
export const checkLastEventbriteSyncDate = (
  syncedOn: string,
  isAdmin: boolean
) => {
  if (isAdmin) {
    return false;
  }
  const lastSyncDate = new Date(syncedOn);
  const diffInMilliseconds = Math.abs(
    new Date().getTime() - lastSyncDate.getTime()
  );
  const oneDayInMilliseconds = 24 * 3600000;
  const lastSyncWithinDay = diffInMilliseconds <= oneDayInMilliseconds;
  if (lastSyncWithinDay) {
    return true;
  }
  return false;
};

export const disconnectEventbrite =
  (userData: AccountData, mixpanel: Mixpanel): AppThunk =>
  async (dispatch, getState) => {
    const { events } = getState().events;
    const { success, rateLimits } =
      await API.eventScraper.disconnectEventbriteWebhooks({
        eventbriteInfo: userData.eventbriteInfo,
        userId: userData.uid,
      });
    if (rateLimits) {
      alert(EventbriteApiLimitAlert);
      return;
    }
    if (success) {
      const newAccountData: AccountData = {
        ...userData,
        eventbriteInfo: {
          token: "",
          userId: "",
          organizationId: "",
          autoAddPhoneQuestion: false,
          connectedOn: "",
          syncedOn: "",
        },
      };
      dispatch(updateAccountData(newAccountData));

      // Delete EB events
      const eventbriteEvents = events.filter((event) =>
        isExternalEventbrite(event.eventType)
      );
      await Promise.all(
        eventbriteEvents.map((event) =>
          dispatch(deleteEventData(event, userData.uid, mixpanel))
        )
      );
    }
  };
