import { Colors } from "../../../../utils/colors";
import { AccountData } from "@markit/common.types";
import { uniqueVals } from "@markit/common.utils";
import { useCallback } from "react";
import { SelectRecipientItem } from "../../../DisplayItem/SelectRecipientItem";

type MassTextsPersonItemProps = {
  user: AccountData;
  unsavedPeople: string[];
  setUnsavedPeople: (allSelectedPeople: string[]) => void;
  setProfileSelected: (profileSelected: AccountData) => void;
  disableCheck?: boolean;
  excludingMode?: boolean;
};

export const MassTextsPersonItem = (props: MassTextsPersonItemProps) => {
  const {
    user,
    unsavedPeople,
    setUnsavedPeople,
    setProfileSelected,
    disableCheck,
    excludingMode,
  } = props;

  const toggleUserSelection = useCallback(() => {
    if (unsavedPeople.includes(user.uid)) {
      const newAllSelectedPeople = unsavedPeople.filter((uid) => {
        return uid !== user.uid;
      });
      setUnsavedPeople(newAllSelectedPeople);
    } else {
      const newAllSelectedPeople = uniqueVals(unsavedPeople.concat(user.uid));
      setUnsavedPeople(newAllSelectedPeople);
    }
  }, [user.uid, setUnsavedPeople, unsavedPeople]);

  return (
    <SelectRecipientItem
      item={user}
      isSelected={unsavedPeople.includes(user.uid)}
      iconColor={excludingMode ? Colors.RED3 : Colors.BLUE5}
      selectOnPress={toggleUserSelection}
      selectPreviewOnPress={() => setProfileSelected(user)}
      disabled={disableCheck}
    />
  );
};
