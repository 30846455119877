import "../../css/AdminDashboard.css";
import "../../css/GlobalStyles.css";
import React, { memo, useCallback, useMemo, useState } from "react";
import {
  FeaturedCities,
  FeaturedEvent,
  FeaturedEventCategories,
} from "@markit/common.types";
import {
  updateFeaturedEvent,
  fetchFeaturedEvent,
} from "../../utils/ExploreUtils";
import RectangleButton from "../../components/Buttons/RectangleButton";
import { Colors } from "../../utils/colors";

type AdminDashboardFeatureEventProps = {};

const AdminDashboardFeatureEvent = memo(function AdminDashboardFeatureEventFn(
  props: AdminDashboardFeatureEventProps
) {
  const [featuredEventLink, setFeaturedEventLink] = useState<string>("");
  const [featuredEvent, setFeaturedEvent] = useState<FeaturedEvent>({
    eventId: "",
    eventCategory: FeaturedEventCategories.NONE,
    city: FeaturedCities.NONE,
    baseExploreIndex: -1,
  });

  const updateFeaturedEventSettings = useCallback(
    (partial: Partial<FeaturedEvent>) => {
      setFeaturedEvent((featuredEvent) => {
        return { ...featuredEvent, ...partial };
      });
    },
    []
  );

  const selectEventByLink = useCallback(async () => {
    let eventId = "";
    eventId = featuredEventLink.slice(23);
    updateFeaturedEventSettings({ eventId: eventId });

    const featuredEventFromId = await fetchFeaturedEvent({
      eventId: eventId,
    });

    if (featuredEventFromId) {
      setFeaturedEvent(featuredEventFromId);
    } else {
      updateFeaturedEventSettings({ eventId: eventId });
    }
  }, [featuredEventLink, updateFeaturedEventSettings]);

  // called by checking the feature in tech checkbox
  const onCategoryClick = useCallback(
    async (categoryType: FeaturedEventCategories) => {
      const category =
        featuredEvent.eventCategory !== categoryType
          ? categoryType
          : FeaturedEventCategories.NONE;
      const baseExploreIndex =
        featuredEvent.eventCategory !== categoryType
          ? featuredEvent.baseExploreIndex
          : -1;
      updateFeaturedEventSettings({
        eventCategory: category,
        baseExploreIndex: baseExploreIndex,
      });

      const newFeaturedEvent: FeaturedEvent = {
        ...featuredEvent,
        eventCategory: category,
        baseExploreIndex: baseExploreIndex,
      };
      updateFeaturedEvent(
        newFeaturedEvent,
        category === FeaturedEventCategories.NONE
      );
    },
    [featuredEvent, updateFeaturedEventSettings]
  );

  // called by checking the feature on base explore checkbox
  const onBaseExploreClick = useCallback(async () => {
    let baseExplore = featuredEvent.baseExploreIndex === -1 ? 1 : -1;
    updateFeaturedEventSettings({
      baseExploreIndex: baseExplore,
    });

    const newFeaturedEvent: FeaturedEvent = {
      ...featuredEvent,
      baseExploreIndex: baseExplore,
    };
    updateFeaturedEvent(newFeaturedEvent, false);
  }, [featuredEvent, updateFeaturedEventSettings]);

  const displayFeatureOptions = useMemo(() => {
    return (
      <div className="AlignedRow" style={{ gap: 14 }}>
        <div className="AlignedRow AdminFeatureEventSelectionBox">
          <span>Feature in tech</span>
          <input
            type="checkbox"
            onClick={() => onCategoryClick(FeaturedEventCategories.TECH)}
            checked={
              featuredEvent.eventCategory === FeaturedEventCategories.TECH
            }
          />
        </div>
        <div className="AlignedRowSelect AdminFeatureEventSelectionBox">
          <span>Feature in nightlife</span>
          <input
            type="checkbox"
            onClick={() => onCategoryClick(FeaturedEventCategories.NIGHTLIFE)}
            checked={
              featuredEvent.eventCategory === FeaturedEventCategories.NIGHTLIFE
            }
          />
        </div>
        <div className="AlignedRowSelect AdminFeatureEventSelectionBox">
          <span>Feature in base explore page</span>
          <input
            type="checkbox"
            onClick={onBaseExploreClick}
            checked={featuredEvent.baseExploreIndex !== -1}
          />
        </div>
      </div>
    );
  }, [
    featuredEvent.baseExploreIndex,
    featuredEvent.eventCategory,
    onBaseExploreClick,
    onCategoryClick,
  ]);

  return (
    <div className="ColumnNormal" style={{ gap: 10 }}>
      <span className="AdminDashboardSectionTitle">Feature Event</span>
      <span className="AdminDashboardSubtext">
        Enter an event link to be featured. After one has been selected, select
        the appropriate checkboxes to feature the event on.
        <br />
        *You may only select either tech or nightlife.
        <br />
        *Checking the base explore base will show it on the main page (max. 4)
      </span>
      <div className="AlignedRow" style={{ gap: 14 }}>
        <input
          type="text"
          placeholder="Insert event link (ex: 'https://markitai.com/e/DZmdxMLHAx')"
          onChange={(e) => setFeaturedEventLink(e.target.value)}
          style={{ width: 600, padding: 8, borderRadius: 8 }}
        />
        <div>
          <RectangleButton
            buttonLabel="Select Event"
            onPress={selectEventByLink}
            altColor={Colors.BLUE5}
            altTextColor={Colors.WHITE}
            altPaddingHorz={24}
            disabled={!featuredEventLink}
          />
        </div>
      </div>
      {featuredEvent.eventId ? (
        <div className="ColumnNormal" style={{ gap: 10, paddingTop: 10 }}>
          <span style={{ color: "white", fontSize: 14 }}>
            Event ID: {featuredEvent.eventId}
          </span>
          {displayFeatureOptions}
        </div>
      ) : null}
    </div>
  );
});

export default AdminDashboardFeatureEvent;
