import { API } from "../API";
import { AccountData, StripeAccountStatus } from "@markit/common.types";
import { updateDoc } from "firebase/firestore";
import { testStripe } from "../components/FullEvent/FullEventInputForm";
import { getUserRef } from "./FirebaseUtils";

export const connectToStripeAccount = async (uid: string) => {
  await (testStripe ? API.testPayment : API.payment)
    .connectToBank({
      uid: uid,
      url: "https://markitsocial.net",
    })
    .then((response) => {
      const { url } = response;
      window.open(url);
    })
    .catch((reason) => console.error(reason));
};

export const checkForPaymentEnabled = async (
  userData: AccountData
): Promise<boolean> => {
  if (
    (!testStripe && !userData.stripeAccountId) ||
    (testStripe &&
      (userData.testStripeAccountId === undefined ||
        userData.testStripeAccountId === ""))
  ) {
    return false;
  }

  const readyForPayment = await (testStripe
    ? API.testPayment.readyForPayment({
        testStripeAccountId: userData.testStripeAccountId || "",
      })
    : API.payment.readyForPayment({
        stripeAccountId: userData.stripeAccountId,
      })
  )
    .then((response) => response.readyForPayment)
    .catch((error) => {
      console.error(error.message);
      return false;
    });

  // add stripeAccountStatus field to AccountData if it doesn't exist yet
  if (!testStripe && !userData.stripeAccountStatus) {
    const userRef = getUserRef(userData.uid);
    updateDoc(userRef, {
      stripeAccountStatus: readyForPayment
        ? StripeAccountStatus.COMPLETED
        : StripeAccountStatus.INCOMPLETE,
    });
  } else if (testStripe && !userData.testStripeAccountStatus) {
    const userRef = getUserRef(userData.uid);
    updateDoc(userRef, {
      testStripeAccountStatus: readyForPayment
        ? StripeAccountStatus.COMPLETED
        : StripeAccountStatus.INCOMPLETE,
    });
  }
  return readyForPayment;
};
