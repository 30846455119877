type LabelButtonProps = {
  color: string;
  backgroundColor: string;
  text: string;
  containerStyles?: React.CSSProperties;
};

// Used for displaying labels within ticket items and profile items (subscribed status, ticket status)
const LabelButton = (props: LabelButtonProps) => {
  const { color, backgroundColor, text, containerStyles } = props;

  return (
    <div className="AlignedRow" style={containerStyles}>
      <div
        style={{
          color: color,
          backgroundColor: backgroundColor,
          borderRadius: 100,
          fontSize: 12,
          fontWeight: 500,
          paddingBlock: 7,
          paddingInline: 10,
        }}
      >
        {text}
      </div>
    </div>
  );
};

export default LabelButton;
