import {
  Event,
  MassText,
  MassTextAttachment,
  MassTextType,
  SubSelectionItem,
  SubSelectionType,
} from "@markit/common.types";
import {
  MassTextMessagingScreenType,
  MassTextScreenType,
} from "../MassTextsPopupPanel";
import { MassTextMessage } from "../Message/MassTextMessage";
import { ThemeStyle } from "../../../../hooks/useTheme";
import { getMassTextAttachments } from "../../../../utils/textingUtils";
import { useEffect, useMemo, useState } from "react";
import IntermediaryModalContainer from "../../../Containers/IntermediaryModalContainer";
import MassTextsSendDetails from "./MassTextsSendDetails";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../redux/slices/accountSlice";

export type MassTextsTextSummaryProps = {
  finalRecipients: string[];
  netIndividualRecipients: string[];
  massText: MassText;
  updateMassTextSettings: (massTextSettings: Partial<MassText>) => void;
  setMainScreen: (mainScreen: MassTextScreenType) => void;
  screen: MassTextMessagingScreenType;
  setScreen: (screen: MassTextMessagingScreenType) => void;
  isEditing: boolean;
  suggestedAction: SubSelectionItem | undefined;
  event?: Event;
  theme?: ThemeStyle;
};

const MassTextsTextSummary = (props: MassTextsTextSummaryProps) => {
  const {
    finalRecipients,
    netIndividualRecipients,
    massText,
    updateMassTextSettings,
    setMainScreen,
    screen,
    setScreen,
    isEditing,
    suggestedAction,
    event,
    theme,
  } = props;
  const { numFollowers } = useSelector(getAccountState).account;
  const [massTextAttachments, setMassTextAttachments] = useState<
    MassTextAttachment[]
  >([]);

  const isEventText = useMemo(
    () => massText.type === MassTextType.EVENTTEXT,
    [massText.type]
  );

  // Should probably move this somewhere else to one main fetching location and utilize the useLiveUpdatingEventTexts hook
  useEffect(() => {
    (async () => {
      if (
        isEditing &&
        isEventText &&
        massTextAttachments.length === 0 &&
        massText.sentBy !== ""
      ) {
        const massTextAttachments = await getMassTextAttachments(
          massText.sentBy,
          massText
        );
        setMassTextAttachments(massTextAttachments);
      }
    })();
  }, [isEditing, isEventText, massText, massTextAttachments.length]);

  return (
    <IntermediaryModalContainer
      body={
        <div className="ColumnNormal" style={{ height: "100%" }}>
          <MassTextsSendDetails
            massText={massText}
            setMainScreen={setMainScreen}
            screen={screen}
            setScreen={setScreen}
            netRecipients={
              suggestedAction?.type === SubSelectionType.ALL_FOLLOWERS
                ? numFollowers
                : finalRecipients.length
            }
            suggestedAction={suggestedAction}
            event={event}
          />
          <MassTextMessage
            massText={massText}
            updateMassTextSettings={updateMassTextSettings}
            finalRecipients={finalRecipients}
            disableEventLinks={
              netIndividualRecipients.length > 0 ||
              suggestedAction?.type === SubSelectionType.ALL_FOLLOWERS
            }
            massTextAttachments={massTextAttachments}
            event={event}
            theme={theme}
          />
        </div>
      }
    />
  );
};

export default MassTextsTextSummary;
