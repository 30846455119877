// Compare dates to determine whether the event is more than one day apart
export const CompareDates = (startDate: string, endDate: string) => {
  const startDateObject = new Date(startDate);
  const endDateObject = new Date(endDate);

  const msBetweenDates = Math.abs(
    endDateObject.getTime() - startDateObject.getTime()
  );
  const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);

  // more than a day apart
  if (hoursBetweenDates > 24) {
    return true;
  }

  // less than a day apart
  return false;
};

// Compare dates to determine whether an event takes place over multiple days or not
export const isMultipleDays = (startDate: string, endDate: string) => {
  const startDateObject = new Date(startDate);
  const endDateObject = new Date(endDate);

  // multiple days
  if (
    startDateObject.getDay() !== endDateObject.getDay() ||
    startDateObject.getMonth() !== endDateObject.getMonth() ||
    startDateObject.getFullYear() !== endDateObject.getFullYear()
  ) {
    return true;
  }

  // same day
  return false;
};
