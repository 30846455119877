import { ReactNode } from "react";
import { BackButton } from "../Buttons/BackButton";

type LargePopupModalHeaderProps = {
  title?: string;
  backOnPress: () => void;
  altHeaderLeft?: string;
  headerRight?: ReactNode;
};

const LargePopupModalHeader = (props: LargePopupModalHeaderProps) => {
  const { title, backOnPress, altHeaderLeft, headerRight } = props;

  const styles = {
    bodyMedium: { fontSize: 14, fontWeight: 500 },
    headerPosition: {
      right: 0,
      left: 0,
      display: "flex",
      justifyContent: "center",
      zIndex: -1,
    },
  };

  return (
    <div className="AlignedRowSpaced">
      <BackButton
        iconName={altHeaderLeft ?? "mdi:close"}
        onPress={backOnPress}
      />
      {title ? (
        <div style={{ ...styles.headerPosition, position: "absolute" }}>
          <span style={styles.bodyMedium}>{title}</span>
        </div>
      ) : null}
      {headerRight ? <div>{headerRight}</div> : null}
    </div>
  );
};

export default LargePopupModalHeader;
