import "../../css/Containers/ContainerStyles.css";
import React from "react";
import { useTheme } from "../../hooks/useTheme";

type StandardBorderedContainerProps = {
  children: React.ReactNode;
  containerStyles?: React.CSSProperties;
  onPress?: () => void;
};

/* This is used currently on all the bordered containers on the dashboard. New styling as of April Event Dashboard Refresh */
const StandardBorderedContainer = (props: StandardBorderedContainerProps) => {
  const { children, containerStyles, onPress } = props;
  const { theme } = useTheme();

  const styles = {
    containerView: {
      borderRadius: 12,
      border: `1.5px solid ${theme.DividerColor.borderColor}`,
      display: "inline-block",
    },
  };

  return (
    <div
      onClick={onPress}
      style={{ ...styles.containerView, ...containerStyles }}
    >
      {children}
    </div>
  );
};

export default StandardBorderedContainer;
