import { GetNumericDate } from "../../utils/GetTime";
import { Colors } from "../../utils/colors";
import { Icon } from "@iconify/react";

type SubscriptionDetailsOptionProp = {
  label: string | JSX.Element;
  onPress: () => void;
  cancelSubscriptionAtEnd?: boolean | undefined;
  mostRecentSubEnd?: string | undefined;
};

const SubscriptionDetailsOption = (props: SubscriptionDetailsOptionProp) => {
  const { cancelSubscriptionAtEnd, mostRecentSubEnd, label, onPress } = props;

  return (
    <>
      <div
        className="AlignedRowSpacedSelect"
        style={{ pointerEvents: cancelSubscriptionAtEnd ? "none" : "all" }}
        onClick={onPress}
      >
        <div className="AlignedRowSelect">
          <span
            style={{
              color: cancelSubscriptionAtEnd ? Colors.GRAY1 : Colors.BLACK,
              fontSize: 14,
              fontWeight: 500,
            }}
          >
            {label}
          </span>
          {mostRecentSubEnd &&
          cancelSubscriptionAtEnd &&
          (label === "Cancel Subscription" || label === "Cancel Trial") ? (
            <span style={{ color: "#666666", marginLeft: 10, fontSize: 14 }}>
              Ends: {GetNumericDate(mostRecentSubEnd)}
            </span>
          ) : null}
        </div>
        <div>
          <Icon icon="mdi:chevron-right" height={24} color={Colors.GRAY1} />
        </div>
      </div>
    </>
  );
};

export default SubscriptionDetailsOption;
