import { AccountData } from "@markit/common.types";
import ProfilePic from "../ProfilePic";
import { ReactNode } from "react";
import { useTheme } from "../../hooks/useTheme";

type ProfileSubItemsDisplayProps = {
  users: AccountData[];
  size: number;
  rightComp?: ReactNode;
};

// When displaying profile pic items in a row, overlapping over each other slightly
const ProfileSubItemsDisplay = (props: ProfileSubItemsDisplayProps) => {
  const { users, size, rightComp } = props;
  const { theme } = useTheme();

  return (
    <div className="AlignedRow" style={{ position: "relative" }}>
      {users.map((host, index) => (
        <div style={{ position: "relative", left: index * -10 }} key={index}>
          <ProfilePic
            user={host}
            size={size}
            borderStyle={`0.5px solid ${theme.PicBorder.borderColor}`}
          />
        </div>
      ))}
      {rightComp ? (
        <div style={{ position: "relative", left: users.length * -10 }}>
          {rightComp}
        </div>
      ) : null}
    </div>
  );
};

export default ProfileSubItemsDisplay;
