import {
  MARKIT_PRIVACY_POLICY,
  MARKIT_TERMS_OF_USE,
} from "@markit/common.utils";
import { Colors } from "../../utils/colors";
import CustomCheckbox from "../CustomCheckbox";
import { useTheme } from "../../hooks/useTheme";

type CompliancePhoneOptInProps = {
  isChecked: boolean;
  setIsChecked: (isChecked: boolean) => void;
  hostUid: string;
  hostName?: string; // if exists
  disabled?: boolean; // after verifying number
};

// Display the compliance opt in if the host is a subaccount under review
export const displaySubaccountComplianceOptIn = (hostUid: string) => {
  return false; // none
};

const CompliancePhoneOptIn = (props: CompliancePhoneOptInProps) => {
  const { isChecked, setIsChecked, hostUid, hostName, disabled } = props;
  const { theme } = useTheme();

  const styles = {
    optInText: { fontSize: 10, color: Colors.GRAY2 },
  };

  return (
    <div className="AlignedRow" style={{ gap: 10 }}>
      {displaySubaccountComplianceOptIn(hostUid) ? (
        <CustomCheckbox
          checked={isChecked}
          onChange={() => setIsChecked(!isChecked)}
          sx={{ padding: 0 }}
          altColor={Colors.BLACK}
          disabled={disabled}
        />
      ) : null}
      <div className="ColumnNormal" style={{ gap: 5 }}>
        <p style={styles.optInText}>
          {"By continuing, you agree to our "}
          <span
            style={{
              ...theme.LinkColor,
              cursor: "pointer",
            }}
            onClick={() => {
              window.open(MARKIT_TERMS_OF_USE);
            }}
          >
            Terms
          </span>
          {" and "}
          <span
            style={{ ...theme.LinkColor, cursor: "pointer" }}
            onClick={() => window.open(MARKIT_PRIVACY_POLICY)}
          >
            Privacy Policy
          </span>
        </p>
        <p style={styles.optInText}>
          By providing your phone number, you agree to receive texts from Markit
          {hostName ? " and " + hostName : ""}, and you agree to our data
          policies. Msg&Data rates may apply. Message frequency varies. Text
          HELP for help and STOP to unsubscribe.
        </p>
      </div>
    </div>
  );
};

export default CompliancePhoneOptIn;
