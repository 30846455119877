import "../css/CreateEvent.css";
import { TextField } from "@mui/material";
import { ThemeStyle } from "../hooks/useTheme";
import { DEFAULT_TICKET_PRICE } from "../utils/makeEvent";
import { boundedValue } from "@markit/common.utils";
import { useState } from "react";
import AlertContainer from "./Containers/AlertContainer";

type PriceTextFieldProps = {
  price: number;
  onChange: (newPrice: number) => void;
  theme: ThemeStyle;
  disabled?: boolean;
};

const PriceTextField = (props: PriceTextFieldProps) => {
  const { price, onChange, theme, disabled } = props;

  const [alertText, setAlertText] = useState("");

  return (
    <div style={{ width: "35%" }}>
      <TextField
        size="small"
        type="number"
        placeholder={"5"}
        disabled={disabled}
        value={price === 0 ? "" : price / 100}
        onChange={(e) => {
          let roundedPriceInCents = Math.round(
            (e.target.value as unknown as number) * 100
          );
          if (roundedPriceInCents > DEFAULT_TICKET_PRICE * 400) {
            setAlertText("The maximum price for a ticket is $2000.");
            roundedPriceInCents = boundedValue(
              roundedPriceInCents,
              1,
              DEFAULT_TICKET_PRICE * 400
            );
          }
          onChange(roundedPriceInCents);
        }}
        inputProps={{ min: 1, style: { ...theme.PrimaryText } }}
        InputProps={{ style: { borderRadius: "12px" } }}
        style={{
          ...theme.TertiaryBG,
          borderRadius: "12px",
        }}
      />
      <AlertContainer
        headerComp={alertText}
        theme={theme}
        closeModal={() => setAlertText("")}
        hideModal={alertText === ""}
      />
    </div>
  );
};

export default PriceTextField;
