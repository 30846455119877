import "../css/CreateEvent.css";
import { Event } from "@markit/common.types";
import { Button, ThemeProvider } from "@mui/material";
import {
  contactCardDefault,
  detectedCensored,
  detectedSemiCensored,
} from "@markit/common.utils";
import { useMemo } from "react";
import { useTheme } from "../hooks/useTheme";
import PopupModalContainer, {
  PopupModalContainerWidth,
} from "./Containers/PopupModalContainer";
import { Colors } from "../utils/colors";
import ContentViolationTagModal from "./DeliveryScore/ContentViolationTagModal";
import { themeMui } from "./FullEvent/FullEventInputForm";
import { useSelector } from "react-redux";
import { getAccountState } from "../redux/slices/accountSlice";

interface TextModalProps {
  eventSettings?: Event;
  title: string;
  inputText: string;
  inputPlaceholder: string;
  onChange: (e: any) => void;
  maxLength: number;
  messageLength: number;
  onSave: () => void;
  onClose: () => void;
  disabled?: boolean;
  showContactCardMessage?: boolean;
  forceLight?: boolean;
}

const TextModal = (props: TextModalProps) => {
  const {
    eventSettings,
    title,
    inputText,
    inputPlaceholder,
    onChange,
    maxLength,
    messageLength,
    onSave,
    onClose,
    disabled,
    showContactCardMessage,
    forceLight,
  } = props;
  const { theme } = useTheme(
    eventSettings && !forceLight ? eventSettings.theme : undefined
  );
  const { fullName } = useSelector(getAccountState).account.accountData;

  const isMessageCensored = useMemo(
    () =>
      title === "Event Description"
        ? detectedSemiCensored(inputText)
        : detectedCensored(inputText),
    [inputText, title]
  );

  return (
    <PopupModalContainer
      headerComp={title}
      modalContentWidth={
        title === "Event Description"
          ? PopupModalContainerWidth.WIDE
          : PopupModalContainerWidth.NORMAL
      }
      valueComp={
        <>
          <div
            className="descriptionTextAreaContainer"
            style={theme.TertiaryBG}
          >
            <>
              <textarea
                value={inputText}
                placeholder={inputPlaceholder}
                onChange={(e) => onChange(e)}
                className="descriptionTextArea"
                style={theme.PrimaryText}
                maxLength={maxLength}
              />
              {showContactCardMessage ? (
                <span style={{ color: Colors.GRAY1, fontSize: 15 }}>
                  {contactCardDefault(fullName)}
                </span>
              ) : null}
            </>
            <div
              className="AlignedRow"
              style={{ marginTop: 14, justifyContent: "space-between" }}
            >
              <h4
                style={{
                  fontWeight: "400",
                  fontSize: 14,
                  color: Colors.GRAY1,
                }}
              >
                {messageLength}/{maxLength}
              </h4>
              {(
                title === "Event Description"
                  ? detectedSemiCensored(inputText)
                  : detectedCensored(inputText)
              ) ? (
                <ContentViolationTagModal
                  message={inputText || ""}
                  theme={theme}
                  isSemiCensored={title === "Event Description"}
                />
              ) : null}
            </div>
          </div>
          <ThemeProvider theme={themeMui}>
            <Button
              style={{
                backgroundColor:
                  isMessageCensored || disabled ? Colors.GRAY1 : Colors.BLUE5,
                fontWeight: 600,
                color: "#FFFFFF",
                width: "100%",
                marginTop: 14,
              }}
              variant="contained"
              disableElevation
              sx={{ borderRadius: "8px" }}
              disabled={isMessageCensored || disabled}
              onClick={onSave}
            >
              Save
            </Button>
          </ThemeProvider>
        </>
      }
      theme={theme}
      closeModal={onClose}
    />
  );
};

export default TextModal;
