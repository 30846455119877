import { Route, Routes } from "react-router-dom";
import { memo } from "react";
import CityPage from "../screens/Explore/CityPage";
import ExplorePage from "../screens/Explore/ExplorePage";

const ExploreRoute = memo(function ExploreRouteFn() {
  return (
    <Routes>
      <Route index element={<ExplorePage />} />
      <Route path=":cityname" element={<CityPage />} />
    </Routes>
  );
});

export default ExploreRoute;
