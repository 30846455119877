import "../../css/Containers/ContainerStyles.css";
import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import PopupModalContainer from "./PopupModalContainer";
import {
  MARKIT_HOTLINE_NUMBER,
  MARKIT_IG,
  MARKIT_LINKEDIN,
  MARKIT_SUPPORT_EMAIL,
  MARKIT_X,
} from "@markit/common.utils";
import { formatPhoneNumber } from "../../utils/FormatPhoneNumber";
import { EventDashboardExternalLinkCopy } from "../Links/CopyCustomLinkify";
import { useCallback } from "react";

type HelpModalProps = {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
};

const HelpModal = (props: HelpModalProps) => {
  const { showModal, setShowModal } = props;

  const renderSupportOption = useCallback(
    (url: string, iconName: string, text: string) => (
      <div
        className="AlignedRowSelect"
        style={{ gap: 10 }}
        onClick={() => window.open(url)}
      >
        <Icon icon={iconName} height={16} />
        <span>{text}</span>
      </div>
    ),
    []
  );

  return (
    <>
      {showModal ? (
        <PopupModalContainer
          headerComp={
            <div className="ColumnNormal" style={{ gap: 14 }}>
              <Icon icon="ion:help-circle" height={40} />
              <div className="ColumnNormal" style={{ gap: 7 }}>
                <span className="sectionTitle">Contact Support</span>
                <span className="bodySubtext">
                  Ask us any questions or report an issue. Text our hotline
                  number below, email us, or DM our socials.
                </span>
              </div>
              <div className="ColumnNormal bodySubtext" style={{ gap: 7 }}>
                <div className="AlignedRow" style={{ gap: 10 }}>
                  <Icon icon="ion:mail" height={16} />
                  <span>{MARKIT_SUPPORT_EMAIL}</span>
                </div>
                {renderSupportOption(
                  MARKIT_LINKEDIN,
                  "ion:logo-linkedin",
                  "Markit AI"
                )}
                {renderSupportOption(
                  MARKIT_X,
                  "fa6-brands:square-x-twitter",
                  "@markit_ai"
                )}
                {renderSupportOption(MARKIT_IG, "mdi:instagram", "@markit_ai_")}
              </div>
            </div>
          }
          valueComp={
            <div className="ColumnNormal" style={{ gap: 7 }}>
              <div className="AlignedRow" style={{ gap: 7 }}>
                <Icon icon="ion:chatbubble-ellipses" height={16} />
                <span className="bodyMedium">Text our hotline</span>
              </div>
              <EventDashboardExternalLinkCopy
                copyText={formatPhoneNumber(MARKIT_HOTLINE_NUMBER)}
                containerStyles={{
                  display: "flex",
                  padding: 14,
                  borderColor: Colors.GRAY1,
                  borderWidth: 0.5,
                }}
                altTextColor={Colors.BLACK}
              />
            </div>
          }
          noExit
          closeOnOutsidePress
          closeModal={() => setShowModal(false)}
        />
      ) : null}
    </>
  );
};

export default HelpModal;
