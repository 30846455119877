import { useCallback } from "react";
import PopupModalContainer from "../../Containers/PopupModalContainer";
import { Icon } from "@iconify/react";
import { Colors } from "../../../utils/colors";
import RectangleButton from "../../Buttons/RectangleButton";
import { APPSTORE, GOOGLEPLAY } from "@markit/common.utils";

interface ScanningInstructionsModalProps {
  setIsScanningInstructionsModalOpen: (
    isScanningInstructionsModalOpen: boolean
  ) => void;
}

const ScanningInstructionsModal = (props: ScanningInstructionsModalProps) => {
  const { setIsScanningInstructionsModalOpen } = props;

  const onIOSClick = useCallback(async () => {
    window.open(APPSTORE);
  }, []);

  const onAndroidClick = useCallback(async () => {
    window.open(GOOGLEPLAY);
  }, []);

  return (
    <>
      <PopupModalContainer
        headerComp="Scanning Available in App"
        headerLeftIcon={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingInline: "14px",
              marginBottom: "10px",
            }}
          >
            <Icon
              icon={"ion:scan-circle"}
              height={35}
              style={{
                color: Colors.BLACK,
              }}
            />
          </div>
        }
        subHeaderComp={
          <div
            style={{
              fontWeight: "400",
              fontSize: 14,
              color: Colors.GRAY1,
              paddingTop: 10,
            }}
          >
            You can scan tickets in the Markit app.
          </div>
        }
        noExit={true}
        closeOnOutsidePress
        valueComp={
          <>
            <RectangleButton
              buttonLabel={"Download for iOS"}
              onPress={onIOSClick}
              altColor={Colors.BLACK}
              altPaddingVert={14}
              iconLeft={
                <Icon
                  icon="ion:logo-apple"
                  height={19}
                  style={{ color: Colors.WHITE }}
                />
              }
            />
            <RectangleButton
              buttonLabel="Download for Android"
              onPress={onAndroidClick}
              altColor={Colors.BLACK}
              altPaddingVert={14}
              iconLeft={
                <Icon
                  icon="ion:logo-google-playstore"
                  height={19}
                  style={{ color: Colors.WHITE }}
                />
              }
              containerStyles={{ marginTop: 14 }}
            />
          </>
        }
        closeModal={() => {
          setIsScanningInstructionsModalOpen(false);
        }}
      />
    </>
  );
};

export default ScanningInstructionsModal;
