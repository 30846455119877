import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import { useOnMount } from "../../utils/useOnMount";
import EmptyStateButton from "../Buttons/EmptyStateButton";

type TextsPanelProps = {
  openEventTexts: () => void;
};

export const TextsPanel = (props: TextsPanelProps) => {
  const { openEventTexts } = props;

  useOnMount(() => {
    (async () => {
      openEventTexts();
    })();
  });

  return (
    <EmptyStateButton
      title="Viewing Texts"
      icon={
        <Icon icon="ion:chatbubble-ellipses" height={40} color={Colors.GRAY1} />
      }
      containerStyles={{
        paddingTop: 200,
      }}
    />
  );
};
