import { GroupChat, Event, AccountData } from "@markit/common.types";
import { takeEvery } from "@redux-saga/core/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { getDocs, query, QuerySnapshot, where } from "firebase/firestore";
import { getUserEventsRef, getUsersRef } from "../../utils/FirebaseUtils";
import { DataLoaders } from "../slices/dataSlice";
import { urlParamsActions, UrlParamsState } from "../slices/urlParamSlice";
import store from "../store";

function* loadUrlParamData({
  payload: urlParams,
}: PayloadAction<UrlParamsState>) {
  const {
    e: eventId,
    u: username,
    i: userId,
    id,
    qrCodeId,
    username: profileId,
    referral,
    communityId,
    ticketId,
  } = urlParams;
  if (eventId !== "" || id !== "") {
    const finalId = eventId ? eventId : id;
    if (ticketId !== "") {
      store.dispatch(
        DataLoaders.ticket({ eventId: finalId, itemId: ticketId })
      );
    }
    store.dispatch(DataLoaders.event(finalId));
  }
  if (userId !== "" || qrCodeId !== "") {
    store.dispatch(DataLoaders.user(userId ? userId : qrCodeId));
  }
  if (username !== "" || profileId !== "") {
    loadUserByUsername(username ? username : profileId, true);
  }
  if (referral !== "") {
    loadUserByUsername(referral, false, true);
  }
  if (communityId !== "") {
    store.dispatch(DataLoaders.group(communityId));
  }
  yield;
}

export async function loadUserByUsername(
  username: string,
  loadEvents?: boolean,
  referral?: boolean
) {
  const userQuery = query(getUsersRef(), where("username", "==", username));
  const userSnap: QuerySnapshot<AccountData> = await getDocs(userQuery);
  if (userSnap && userSnap.docs.length) {
    const userData = userSnap.docs[0].data();
    if (loadEvents) {
      await loadUsersEvents(userData.uid);
    }
    // TODO (PETER): Temporary solution. For some reason when the referral screen
    // tries to use the user.fulfilled, the log for loaded user in dataslice does not print
    // but when I use .user, it gets printed and the userData loads fine on the referral screen.
    // Figure out how to use the .fulfilled and still have it load correctly.
    if (referral) {
      store.dispatch(DataLoaders.user(userData.uid));
    } else {
      store.dispatch(
        DataLoaders.user.fulfilled(userData, userData.uid, userData.uid)
      );
    }
  } else {
    console.log("Invalid username case");
    // store.dispatch(DataLoaders.user(""));
  }
}

async function loadUsersEvents(userId: string) {
  const userEventsQuery = query(
    getUserEventsRef(userId),
    where("createdBy", "==", userId)
  );
  const userEvents = await getDocs(userEventsQuery);

  if (userEvents.docs.length > 0) {
    userEvents.docs.forEach((doc: any) => {
      const event: Event = doc.data();
      if (event !== undefined) {
        store.dispatch(DataLoaders.event.fulfilled(event, event.id, event.id));
      }
    });
  }
}

/**
 * After a group is loaded, load all the members.
 * @param param0 Response from DataLoaders.group.fulfilled
 */
function* loadAdditionalGroupData({
  payload: groupChat,
}: PayloadAction<GroupChat>) {
  for (const member of groupChat.members) {
    store.dispatch(DataLoaders.user(member));
  }
  yield;
}

function* loadAdditionalEventData({
  payload: eventData,
}: PayloadAction<Event>) {
  if (eventData.createdBy !== "") {
    store.dispatch(DataLoaders.user(eventData.createdBy));
  }
  for (const cohost of eventData.cohosts) {
    if (cohost !== "") {
      store.dispatch(DataLoaders.user(cohost));
    }
  }
  for (const performer of eventData.performers) {
    if (performer !== "") {
      store.dispatch(DataLoaders.user(performer));
    }
  }
  for (const scanner of eventData.scanners) {
    if (scanner !== "") {
      store.dispatch(DataLoaders.user(scanner));
    }
  }
  for (const promoter of eventData.promoters) {
    if (promoter !== "") {
      store.dispatch(DataLoaders.user(promoter));
    }
  }
  yield;
}

/**
 *  Listens to the urlParamsActions.setUrlState action (anytime the url params get initialized).
 *  Decides what data it has to load based off of the URL params and handles side-effectful data loading.
 *  i.e. Everytime someone load a group, we want to also load all of its members.
 */
export function* loadDataSaga() {
  yield takeEvery(urlParamsActions.setUrlState.type, loadUrlParamData);
  yield takeEvery(DataLoaders.group.fulfilled, loadAdditionalGroupData);
  yield takeEvery(DataLoaders.event.fulfilled, loadAdditionalEventData);
}
