import React, { memo } from "react";
import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import { useTheme } from "../../hooks/useTheme";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";

type NewItemButtonProps = {
  onPress: () => void;
  label: string;
  subText?: string;
  disabled?: boolean;
  containerStyles?: React.CSSProperties;
};

export const NewItemButton = memo(function NewItemButtonFn(
  props: NewItemButtonProps
) {
  const { onPress, label, subText, disabled, containerStyles } = props;
  const { theme } = useTheme();

  const styles = {
    addOrganizerIcon: {
      height: 48,
      width: 48,
      borderRadius: 96,
      backgroundColor: disabled ? Colors.GRAY6 : Colors.BLACK,
      cursor: "pointer",
    },

    labelView: {
      marginLeft: 14,
      cursor: "pointer",
    },

    labelText: {
      color: disabled ? theme.SubText.color : theme.PrimaryText.color,
      fontSize: 14,
      fontWeight: 600,
    },

    sublabelText: {
      color: disabled
        ? theme.DisabledButtonText.color
        : theme.PrimaryText.color,
      fontSize: 12,
      fontWeight: 500,
      marginTop: 4,
    },
  };

  return (
    <>
      <div
        className="AlignedRowSpaced"
        style={{
          ...containerStyles,
          pointerEvents: disabled ? "none" : "auto",
          cursor: "pointer",
          marginLeft: 14,
          marginBlock: 10,
        }}
        onClick={onPress}
      >
        <div className="AlignedRow">
          <div className="Centering" style={styles.addOrganizerIcon}>
            <Icon
              icon={"ion:add"}
              height={26}
              style={{ color: disabled ? Colors.GRAY2 : Colors.WHITE }}
            />
          </div>
          <div className="ColumnNormal" style={styles.labelView}>
            <span style={styles.labelText}>{label}</span>
            {subText ? (
              <span style={styles.sublabelText}>{subText}</span>
            ) : null}
          </div>
        </div>
      </div>
      <HorizontalDivider />
    </>
  );
});
