import { Icon } from "@iconify/react";
import { Colors } from "../../../utils/colors";
import {
  AccountData,
  Event,
  FormResponseV2,
  SavedFormQuestion,
} from "@markit/common.types";
import { useEffect, useState } from "react";
import PopupModalContainer from "../../Containers/PopupModalContainer";
import FlatList from "flatlist-react/lib";
import { FormResponseContainer } from "../FormResponseContainer";
import { getUserFormResponseData } from "../../../utils/FirebaseUtils";
import { getEventFormQuestions } from "../../../utils/eventUtils/formUtils";
import { DotWave } from "@uiball/loaders";

type FormResponsesModalProps = {
  event: Event;
  profileData: AccountData;
  setIsVisible: (isVisible: boolean) => void;
};

export const FormResponsesModal = (props: FormResponsesModalProps) => {
  const { event, profileData, setIsVisible } = props;
  const [formQuestions, setFormQuestions] = useState<SavedFormQuestion[]>([]);
  const [formResponse, setFormResponse] = useState<FormResponseV2>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const [formResponse, formQuestions] = await Promise.all([
        getUserFormResponseData(event.id, profileData.uid),
        getEventFormQuestions(event.createdBy, event.formQuestions),
      ]);
      setFormResponse(formResponse);
      setFormQuestions(formQuestions);
      setLoading(false);
    })();
  }, [event.createdBy, event.formQuestions, event.id, profileData]);

  return (
    <PopupModalContainer
      headerComp={
        <div className="AlignedRow" style={{ gap: 5 }}>
          <Icon
            icon={"ion:chevron-back"}
            height={24}
            style={{
              color: Colors.GRAY1,
              cursor: "pointer",
              marginLeft: "-7px",
            }}
            onClick={() => {
              setIsVisible(false);
            }}
          />
          <div>{profileData.fullName}'s Form Responses</div>
        </div>
      }
      valueComp={
        <div
          className="ColumnNormal HideScrollbar"
          style={{ height: "70vh", overflow: "scroll", gap: 14 }}
        >
          {loading ? (
            <DotWave size={28} speed={1} color={Colors.BLACK} />
          ) : (
            <FlatList
              list={formQuestions}
              keyExtractor={(item: SavedFormQuestion) => item.id}
              renderItem={(item) => {
                return (
                  <FormResponseContainer
                    eventId={event.id}
                    item={item}
                    individualUserResponse={formResponse}
                    clicked={true}
                    onPress={() => {}}
                  />
                );
              }}
            />
          )}
        </div>
      }
      closeOnOutsidePress
      noExit
      closeModal={() => setIsVisible(false)}
    />
  );
};
